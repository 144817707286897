/* eslint-disable no-param-reassign */
import React, { FC, useState } from "react";
import { Button, Form, Input, Modal, message } from "antd";
import "./Styles.scss";

import { useConnection } from "../../Services/ApplicationContext";
import {
  Plant,
  PlantDescription,
  PlantName,
  Plants,
} from "../../definetions/Constatnts";
import "../modalPopUp.scss";
import { SERVICE_TYPE } from "../../constants/constants";

export interface EditLocationModalProps {
  visible: boolean;
  onClose: (add: "add" | "edit", val?: LocationDetails) => void;
  data: any;
  locationStatus: boolean;
  reload: () => void;
  realTimeUpdate: (val: LocationDetails) => void;
}

export interface LocationDetails {
  key?: any;
  locationName: string;
  locationID?: any;
  description?: string;
  lastUpdated?: number;
}

const UpdateLocation: FC<EditLocationModalProps> = (
  props: EditLocationModalProps,
) => {
  const {
    visible,
    onClose,
    data,
    locationStatus,
    reload,
    realTimeUpdate,
  } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { post, put } = useConnection();

  const onConfirm = async (values: any): Promise<void> => {
    console.log(values);
    const pName = values.locationName;
    const fPName = pName
      .split(" ")
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    values.locationName = fPName.trim();
    console.log(values);

    try {
      if (locationStatus) {
        const entry = { ...data, ...values };
        delete entry.key;
        if (values.description) {
          entry.description = values.description.trim();
        }
        const result = await put(
          "system-management/location/updateLocation",
          entry,
          undefined,
          SERVICE_TYPE.serviceType
        );
        if (result.statusText === "SUCCESS") {
          onClose("edit", { ...result.data, key: data.key } as LocationDetails);
          message.open({
            type: "success",
            content: result.message,
            duration: 2,
            style: { textAlign: "right", marginRight: 15, marginTop: 10 },
          });
          // reload();
        } else {
          message.open({
            type: "warning",
            content: result.message,
            duration: 2,
            style: { textAlign: "right", marginRight: 15, marginTop: 10 },
          });
        }
      } else {
        try {
          const result = await post(
            "system-management/location/add",
            values,
            undefined,
            SERVICE_TYPE.serviceType
          );
          if (result.status === 201) {
            message.open({
              type: "success",
              content: "New Location Added Successfully",
              duration: 2,
              style: { textAlign: "right", marginRight: 15, marginTop: 10 },
            });
            onClose("add", result.data);
            realTimeUpdate(result.data);
            console.log("added data ", result.data);
            // reload();
          } else {
            message.open({
              type: "warning",
              content: result.message,
              duration: 2,
              style: { textAlign: "right", marginRight: 15, marginTop: 10 },
            });
          }
          setLoading(false);
        } catch (e) {
          message.open({
            type: "error",
            content: e.message,
            duration: 3,
            style: { textAlign: "right", marginRight: 15, marginTop: 10 },
          });
        }
      }
      setLoading(false);
    } catch (e) {
      message.open({
        type: "error",
        content: e.message,
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
      setLoading(false);
    }
  };

  return (
    <div className="device-modal">
      <Modal
        title={locationStatus ? `Edit Plant` : `Add Plant`}
        centered
        visible={visible}
        onCancel={() => onClose("add")}
        width="50%"
        footer={null}
        className="general-modal-popup"
      >
        <Form
          // {...formItemLayout}
          form={form}
          name="locations"
          hideRequiredMark={false}
          initialValues={data}
          onFinish={onConfirm}
          scrollToFirstError
          autoComplete="off"
          style={{ justifyContent: "center" }}
          // className="ant-advanced-search-form"
          layout="vertical"
        >
          {/* <Col span={20}> */}
          <Form.Item
            name="locationName"
            label={PlantName}
            className="display"
            rules={[
              {
                required: true,
                message: `Please input Plant Name!`,
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (getFieldValue("locationName")?.match(/^[ ]{1,}$/gm)) {
                    return Promise.reject("Please input Plant Name!");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="Enter the Plant Name " />
          </Form.Item>
          <Form.Item
            name="locationDescription"
            label={PlantDescription}
            className="display"
            style={{ width: "100%" }}
            rules={[
              {
                whitespace: true,
                message: "Required field!",
              },
              {
                max: 255,
                message: "The maximum length of 255 characters is reached! ",
              },
            ]}
          >
            <Input.TextArea rows={5} placeholder="Enter the Description" />
          </Form.Item>
          <Form.Item className="modal-field-button">
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              style={{ marginRight: "15px" }}
            >
              {locationStatus ? `Edit ${Plant}` : `Add ${Plant}`}
            </Button>
            <Button
              loading={loading}
              danger
              type="primary"
              onClick={() => onClose("add")}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UpdateLocation;
