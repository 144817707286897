import { Button, Empty, message, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useProject } from "./NewProjextContext";
import AlteredTableModal from "./AlteredTableModal";
import "../tableStyles.scss";
import { DateTime } from "luxon";
import { useConnection } from "../../Services/ApplicationContext";
import ScheduleErrorPopUp from "../CableProduction/scheduleErrorPopUp";

const StepThree = () => {
  const {
    current,
    setCurrent,
    setTimelineGroup,
    setTimelineItem,
    setSelectedTimeline,
    timelineData,
    setStartTimeView,
    setEndTimeView,
    setMachineBookingTime,
    genScheduleLoading,
    setNoSchedules,
    noSchedules,
  } = useProject();
  const { post } = useConnection();
  const [alteredModalVisible, setAlteredModalVisible] = useState<boolean>(
    false,
  );

  const [machineIds, setMachineIds] = useState<any>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const columns: any = [
    {
      title: "Start Date",
      dataIndex: "startTime",
      key: "sourceName",
      sorter: (a: any, b: any) => a.startTime - b.startTime,
      width: 150,
      render: (text: any, record: any) => (
        <Space size="middle">
          {moment.unix(record.startTime).format("DD:MM:yyyy,  hh:mm:ss a")}
        </Space>
      ),
    },
    {
      title: "End Date",
      dataIndex: "endTime",
      key: "type",
      width: 150,
      sorter: (a: any, b: any) => a.endTime - b.endTime,
      render: (text: any, record: any) => (
        <Space size="middle">
          {moment.unix(record.endTime).format("DD:MM:yyyy,  hh:mm:ss a")}
        </Space>
      ),
    },
    {
      title: "Cost",
      dataIndex: "costCalculation",
      key: "date",
      width: 100,
      sorter: (a: any, b: any) => a.costCalculation - b.costCalculation,
      render: (text: any, record: any) => (
        <Space size="middle">{`Rs ${record.costCalculation}`}</Space>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "download",
      sorter: false,
      render: (text: any, record: any) => (
        <Space size="middle">
          <Button
            loading={loading}
            type="primary"
            onClick={() => {
              console.log(" --------------- record --------------", record);
              setLoading(true);
              arrangeTimeline(record.nodes);
              setSelectedTimeline(record);
            }}
          >
            Select
          </Button>
        </Space>
      ),
      width: 100,
    },
  ];

  const getMachineAvailability = async (machineIdArray: any[]) => {
    try {
      // const results = await post("production/machine/availability", {
      //   machineIdArray,
      //   startTime: 1616992530,
      //   endTime: 1648528530,
      // });
      // return results.data;
    } catch (error: any) {
      console.log(error);
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
    }
  };

  const arrangeTimeline = async (data: any) => {
    console.log("-----------arrangeTimeline---------------");
    console.log(data);
    let taskGroup: any = [];
    const machineArray: any = [];
    let machineIds: any = [];
    const taskArray: any = [];
    const startTimeArray: any = [];
    const endTimeArray: any = [];
    const dataArray = Object.values(data);
    dataArray.sort((a: any, b: any) =>
      a.endTime !== b.endTime
        ? parseInt(a.endTime) - parseInt(b.endTime)
        : parseInt(a.startTime) - parseInt(b.startTime),
    );
    dataArray.map((item: any, index) => {
      taskGroup.push(item.machineOption.taskGroup);
    });

    taskGroup = [...new Set(taskGroup)];

    // for machine availability
    machineIds = dataArray.map((item: any) => item.machineOption.machineID);
    setMachineIds(machineIds);

    let uniqueMachineIds = machineIds.filter((id: any, index: any) => {
      return machineIds.indexOf(id) === index;
    });

    const machineBookedData = await getMachineAvailability(uniqueMachineIds);
    dataArray.map((item: any, index) => {
      let value = {
        id: index + 10,
        title: item.machineOption.machineName,
        machineId: item.machineOption.machineID,
        rightTitle: item.machineOption.machineName,
        root: false,
        parent: taskGroup.indexOf(item.machineOption.taskGroup) + 1,
      };
      let result: boolean = machineArray?.some(
        (item: {
          id: any;
          title: any;
          machineId: any;
          rightTitle: any;
          root: boolean;
          parent: any;
        }) =>
          item.machineId === value.machineId &&
          item.root === value.root &&
          item.parent === value.parent,
      );
      if (!result) {
        machineArray.push({
          // id: index + 10,
          id:
            item.machineOption.machineName +
            String(taskGroup.indexOf(item.machineOption.taskGroup) + 1),
          title: item.machineOption.machineName,
          machineId: item.machineOption.machineID,
          rightTitle: item.machineOption.machineName,
          root: false,
          parent: taskGroup.indexOf(item.machineOption.taskGroup) + 1,
        });
      }
      taskArray.push({
        id: index + 1,
        group:
          item.machineOption.machineName +
          String(taskGroup.indexOf(item.machineOption.taskGroup) + 1),
        // group: !result ? index + 10 : machineArray[machineArray.length - 1]?.id,
        title: item.taskName,
        start_time: moment.unix(item?.startTime),
        end_time: moment.unix(item?.endTime),
        taskId: item.id.id,
        bgColor: "rgba(113, 242, 218, 0.6)",
        selectedBgColor: "rgba(113, 242, 218, 1)",
        color: "#017c66",
        tip: {
          ...item.machineOption,
          duration: item?.duration,
          startTime: moment.unix(item?.startTime),
          endTime: moment.unix(item?.endTime),
          nextNodes: item?.next,
          previousNodes: item?.pervious,
        },
      });
      startTimeArray.push(item.startTime);
      endTimeArray.push(item.endTime);
    });
    const finalArray: any = [];
    taskGroup.map((task: any, index: number) => {
      machineArray.push({
        id: index + 1,
        title: task,
        rightTitle: task,
        bgColor: "#f984ec",
        root: true,
        parent: 0,
      });
      finalArray.push(
        {
          id: index + 100,
          title: task,
          rightTitle: task,
          root: true,
          parent: 0,
        },
        ...machineArray.filter((machine: any) => machine.parent === index + 1),
      );
    });

    const machineBookingItems: any = [];
    // machineBookedData?.map(
    //   (machineBooking: any, indexmachineBookedData: any) => {
    //     machineBooking?.availability?.map((booking: any, index: any) => {
    //       if (
    //         !(
    //           dataArray.find(
    //             (item: any) => item.startTime === booking.startTime,
    //           ) &&
    //           dataArray.find((item: any) => item.endTime === booking.endTime)
    //         )
    //       ) {
    //         if (booking?.taskStatus !== true) {
    //           // machineBookingItems.push({
    //           //   id:
    //           //     String(indexmachineBookedData) +
    //           //     String(index) +
    //           //     String(Math.ceil(Math.random() * 100000000000)),
    //           //   group: finalArray.find(
    //           //     (item: any) => item.machineId === machineBooking.machineId,
    //           //   ).id,
    //           //   title: booking.projectName,
    //           //   start_time: moment.unix(booking.startTime),
    //           //   end_time: moment.unix(booking.endTime),
    //           //   bgColor: "rgba(154,153,153,0.6)",
    //           //   selectedBgColor: "rgb(101,100,100)",
    //           //   color: "#9abab4",
    //           //   canMove: false,
    //           //   canResize: false,
    //           //   canChangeGroup: false,
    //           //   canSelect: false,
    //           //   tip: {
    //           //     tastStart: booking?.startTime * 1000,
    //           //     tastEnd: booking?.endTime * 1000,
    //           //     projectName: booking?.projectName,
    //           //     duration: booking?.endTime - booking?.startTime,
    //           //     machineName: "disable",
    //           //   },
    //           // });
    //         }
    //       }
    //     });
    //   },
    // );

    setMachineBookingTime(machineBookingItems);
    setTimelineGroup(finalArray);
    setTimelineItem([...taskArray, ...machineBookingItems]);
    setStartTimeView(Math.min(...startTimeArray));
    setEndTimeView(Math.max(...endTimeArray));
    setLoading(false);
    setCurrent(current + 1);
  };

  useEffect(() => {
    console.log(
      timelineData,
      "timelineDatatimelineDatatimelineDatatimelineData",
    );
  }, []);

  const onCloseModel = () => {
    setNoSchedules(false)
  }

  return (
    <div>
      <AlteredTableModal
        visible={alteredModalVisible}
        timelineID="1"
        onClose={() => setAlteredModalVisible(false)}
      />
      <ScheduleErrorPopUp visible={noSchedules} onClose={onCloseModel}/>
      <div className="general-table">
        <Table
          className="components-table-demo-nested"
          columns={columns}
          loading={genScheduleLoading}
          dataSource={timelineData}
          pagination={{
            pageSizeOptions: ["5", "10", "20", "30", "40", "50", "100"],
            showQuickJumper: true,
            showSizeChanger: true,
          }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  noSchedules && timelineData.map((v:any)=>{
                    return v
                  })
                }
              />
            ),
          }}
        />
      </div>
      <div>
        <Button
          type="primary"
          style={{
            height: "40px",
            borderRadius: "5px",
            marginTop: "20px",
            fontFamily: "Poppins",
            fontSize: "15px",
          }}
          onClick={() => setCurrent(current - 1)}
        >
          Previous
        </Button>
      </div>
    </div>
  );
};

export default StepThree;
