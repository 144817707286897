/* eslint-disable react/require-default-props */
import React, { FC, useEffect, useRef, useState } from "react";
import { Button, Col, message, Row, Tooltip } from "antd";
import { Canvas, Label, CanvasRef, Node, NodeData, EdgeData } from "reaflow";
import {
  ExpandOutlined,
  MinusOutlined,
  PlusOutlined,
  CheckCircleFilled,
  MenuUnfoldOutlined,
  MinusCircleFilled,
} from "@ant-design/icons";
import TemplateNodeDataModal from "./TemplateNodeDataModal";
import PopupIcon from "../../assets/popupIcon.png";
import { useProject } from "./NewProjextContext";
import ContainerSelectionModal from "./ContainerSelectionModal";
import "./stepTwo.scss";
import NodeDataModal from "../CableProduction/NodeDataModal";
import StepThree from "./StepThree";
import {
  cableStateFuture,
  cableStateHistory,
  getCableStateConnections,
  getCableStateConnectionsTo,
  getCableStateLeaves,
  getCableStateSeeds,
} from "../CableProduction/cableFlowChartValidation";
import { getInputMapState } from "../CableProduction/inputOutputMap";
import ConfirmationModal from "./ConfirmModal";
import NodeDataModalProject from "../CableProduction/NodeDataModalProject";
import InputCutLengthSelectionModal from "./inputCutLengthSelectionModal";
import QuantitySuggestionModal from "./quantitySuggestion";

const StepTwo: FC<{ edit: boolean; height: number; width?: number }> = (props: {
  edit: boolean;
  height: number;
  width?: number;
}) => {
  const {
    templateData,
    nextTwo,
    setCurrent,
    current,
    setCurrentFinishedStep,
    inputCutLengthArray,
    formData,
    setFormData,
    currentFormOneData,
    setCurrentFormOneData,
    containerArray,
    previousFromStepTwo,
    setPreviousFromStepTwo,
    quantitySuggestions,
    quantitySuggestionModal,
    setContainerArray,
    RescheduleProjectData,
    setInputCutLengthArray,
  } = useProject();

  const initialEdges = [
    {
      id: "1-2",
      from: "1",
      to: "2",
    },
    {
      id: "2-3",
      from: "2",
      to: "3",
    },
  ];

  const initialNodes = [
    {
      id: "1",
      text: "Input ",
      data: {
        type: "Input",
        status: false,
      },
      height: 50,
      width: 150,
    },
    {
      id: "2",
      text: "Task",
      data: {
        type: "Task",
        status: false,
      },
      height: 50,
      width: 150,
    },
    {
      id: "3",
      text: "Output",
      data: {
        type: "Output",
        status: false,
      },
      height: 50,
      width: 150,
    },
  ];

  let initialNodeData = templateData?.nodes;
  let initialEdgeData = templateData?.edges;

  const [nodes, setNodes] = useState<NodeData[]>();
  const [edges, setEdges] = useState<EdgeData[]>();

  const [loading, setLoading] = useState<boolean>(false);
  const [nodeModalVisible, setNodeModalVisible] = useState<boolean>(false);
  const [containerModalVisible, setContainerModalVisible] = useState<boolean>(
    false,
  );
  const [
    containerModalPausedVisible,
    setContainerModalPausedVisible,
  ] = useState<boolean>(false);
  const [inputModalPausedVisible, setInputModalPausedVisible] = useState<boolean>(
    false,
  );
  const [inputModalVisible, setInputModalVisible] = useState<boolean>(false);
  const [selectedTask, setSelectedTask] = useState<any>({});
  const [selectedTaskId, setSelectedTaskId] = useState<any>({});
  const [selectedNode, setSelectedNode] = useState<any>({});
  const [containerArrayState, setContainerArrayState] = useState<any>([]);

  const [
    confirmationModalStepTwo,
    setConfirmationModalStepTwo,
  ] = useState<boolean>(false);
  const [dummyState, setDummyState] = useState<string>("");
  const [pauseStatus, setPauseStatus] = useState<boolean>(false);
  const canvasRef = useRef<CanvasRef | any>(null);
  const [cutLengthData, setCutLengthData] = useState({});
  const [disableFormItem, setDisableFormItem] = useState<boolean>(!props.edit);

  const storeStatesInLocalStorage = () => {
    localStorage.setItem("process", JSON.stringify({ nodes, edges }));
    localStorage.setItem(
      "cableState",
      JSON.stringify({
        leaves: getCableStateLeaves(),
        seeds: getCableStateSeeds(),
        connections: getCableStateConnections(),
        connections_to: getCableStateConnectionsTo(),
      }),
    );
    localStorage.setItem(
      "cableStateHistory",
      JSON.stringify({ cableStateHistory }),
    );
    localStorage.setItem(
      "cableStateFuture",
      JSON.stringify({ cableStateFuture }),
    );
    localStorage.setItem("inputMap", JSON.stringify(getInputMapState()));
  };

  const nodeColour = (node: any) => {
    //console.log('Text', node?.properties.text, 'Type', node?.properties?.data?.type);
    switch (node?.properties?.data?.type) {
      case "Task":
        return "#352f44";
      case "Input":
        return "#3a4750";
      case "Output":
        return "#414141";
      case "Block":
        return "#252A38";
      default:
        return "#252A38";
    }
  };

  const nodeDataSelect = (nodeData: NodeData) => {
    console.log("onchange node edges => ", nodeData);
    if (nodeData?.data?.props) {
      console.log("node data select    :    ", nodeData);
      setSelectedNode(nodeData);
      setNodeModalVisible(true);
    } else {
      message.open({
        type: "error",
        content: "No Properties",
        duration: 2,
      });
    }
  };

  const rescheduleProjectContainer = () => {
    setContainerArray(RescheduleProjectData.container);
    setInputCutLengthArray(RescheduleProjectData.inputs);
  };

  const StepThree = async () => {
    setCurrentFinishedStep(2);
    setLoading(true);
    await nextTwo();
    setLoading(false);
  };

  const closeConfirmationModal = (val: boolean) => {
    if (val === true) {
    } else if (val === false) {
      localStorage.removeItem("scheduleProjectSTepOneDetails");
    }
  };

  useEffect(() => {
    console.log(
      inputCutLengthArray,
      "quantitySuggestionModalquantitySuggestionModal",
      containerArray,
    );
  }, [quantitySuggestionModal]);

  useEffect(() => {
    console.log("selected task change => ", formData);
    setPauseStatus(formData.pauseStatus);
    if (formData.projectId) {
      rescheduleProjectContainer();
    }
  }, [templateData]);

  return (
    <div style={{ width: "90%" }} className="stepTwo-scheduling">
      <NodeDataModalProject
        update={false}
        visible={nodeModalVisible}
        disable
        nodeData={selectedNode}
        onClose={() => setNodeModalVisible(false)}
        notTemplateCreation={true}
      />

      <ContainerSelectionModal
        visible={containerModalVisible}
        disable={!props.edit}
        cutLengthDisable={disableFormItem}
        nodeData={selectedTask}
        onClose={() => setContainerModalVisible(false)}
        taskId={selectedTaskId}
      />

      <InputCutLengthSelectionModal
        visible={inputModalVisible}
        disable={disableFormItem}
        nodeData={selectedTask}
        onClose={() => setInputModalVisible(false)}
        inputID={selectedTaskId}
      />

      <QuantitySuggestionModal
        visible={quantitySuggestionModal}
        modalData={quantitySuggestions}
        outputNode={templateData.nodes.find(
          (val: any) => val.data.type === "Output",
        )}
      />

      <div style={{ height: 400, width: "110%", marginTop: 30 }}>
        <div
          style={{
            zIndex: 9,
            position: "absolute",
            // bottom: 10,
            // right: 15,
            background: "rgba(0, 0, 0, .5)",
            padding: 10,
            color: "white",
          }}
        >
          <Tooltip title="Zoom In" placement="left">
            <PlusOutlined
              style={{ display: "block", width: "100%", margin: "5px 0" }}
              onClick={() => canvasRef.current.zoomIn()}
            />
          </Tooltip>
          <Tooltip title="Zoom Out" placement="left">
            <MinusOutlined
              style={{ display: "block", width: "100%", margin: "5px 50" }}
              onClick={() => canvasRef.current.zoomOut()}
            />
          </Tooltip>
          <Tooltip title="Fit" placement="left">
            <ExpandOutlined
              style={{ display: "block", width: "100%", margin: "5px 0" }}
              onClick={() => canvasRef.current.fitCanvas()}
            />
          </Tooltip>
        </div>
        <Canvas
          fit
          disabled
          center
          direction="RIGHT"
          // width={props.width} // Use small canvas dimensions to make sure the nodes appear on screen immediately
          maxHeight={1000}
          height={props.height}
          ref={canvasRef}
          nodes={templateData?.nodes}
          edges={templateData?.edges}
          className="canvas-style"
          node={(n) => (
            <Node
              onClick={(_: any, data: NodeData<any>) => {
                console.log("########### Node onCLick changes #########");
                console.log(data);
              }}
              {...n}
              style={{
                borderRadius: 30,
                fill: nodeColour(n),
              }}
              label={
                <Label
                  style={{
                    visibility: "hidden",
                  }}
                />
              }
            >
              {(event) => (
                <foreignObject
                  height={event.height + 20}
                  width={event.width - 20}
                  x={15}
                  // y={5}
                >
                  <div
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      alignSelf: "center",
                      display: "flex",
                      cursor: "pointer",
                      // backgroundColor: "red",
                    }}
                  >
                    <div style={{ padding: 1, alignSelf: "flex-start" }}>
                      {event?.node?.data?.type !== "Block" && (
                        <div className="node-custom">
                          <Row>
                            <Col span={9}>
                              <img
                                alt="popup"
                                src={PopupIcon}
                                className="popup-img"
                                onClick={() => {
                                  nodeDataSelect(event.node);
                                }}
                              />
                            </Col>
                            <Col offset={6} span={9}>
                              {event?.node?.data?.type === "Task" ? (
                                <>
                                  <MenuUnfoldOutlined
                                    alt="popup"
                                    className="popup-container"
                                    onClick={() => {
                                        {containerArray.filter(
                                          (item: any) => item.taskId === event?.node?.id && item.staticCutLength
                                        ).length > 0 ? (
                                          setDisableFormItem(true)
                                        ) : (
                                          setDisableFormItem(false)
                                        )}                                       
                                      setContainerModalVisible(true);
                                      console.log(
                                        event.node.data.props,
                                        "event.node.data.props",
                                        event.node.id,
                                      );
                                      setSelectedTask(event.node.data.props);
                                      setSelectedTaskId(event.node.id);
                                    }}
                                  />
                                </>
                              ) : event?.node?.data?.type === "Input" ? (
                                <>
                                  <MenuUnfoldOutlined
                                    alt="popup"
                                    className="popup-container"
                                    onClick={() => {
                                      if (pauseStatus) {
                                        console.log('check project status', pauseStatus)
                                        setDisableFormItem(true)
                                      } else {
                                        setDisableFormItem(!props.edit)
                                      }
                                      setInputModalVisible(true);
                                      console.log(
                                        event,
                                        "event.node.data.props",
                                        event.node.id,
                                      );
                                      setSelectedTask(event.node.data.props);
                                      setSelectedTaskId(event.node.id);
                                    }}
                                  />
                                </>
                              ) : null}
                            </Col>
                          </Row>
                          <Row>
                            <Col span={5}>
                              {event?.node?.data?.props
                                ? event?.node?.data?.props?.taskName ||
                                  event?.node?.data?.props?.inputItem ||
                                  event?.node?.data?.props?.output
                                : event?.node?.data?.type}
                            </Col>
                          </Row>

                          {event?.node?.data?.type === "Task" && (
                            <div className="validate-icon">
                              {containerArray.filter(
                                (item: any) => item.taskId === event?.node?.id,
                              ).length > 0 ? (
                                <CheckCircleFilled
                                  style={{ color: "#79d70f" }}
                                />
                              ) : (
                                <MinusCircleFilled
                                  style={{ color: "#ec4646" }}
                                />
                              )}
                            </div>
                          )}
                          {event?.node?.data?.type === "Input" && (
                            <div className="validate-icon">
                              {inputCutLengthArray.filter(
                                (item: any) => item.inputId === event?.node?.id,
                              ).length > 0 ? (
                                <CheckCircleFilled
                                  style={{ color: "#79d70f" }}
                                />
                              ) : (
                                <MinusCircleFilled
                                  style={{ color: "#ec4646" }}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </foreignObject>
              )}
            </Node>
          )}
        />
      </div>
      {props.edit && (
        <div
          style={{
            marginTop: "80px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <Button
            type="primary"
            danger
            onClick={() => {
              setPreviousFromStepTwo(true);
              setCurrent(0);
            }}
            style={{ margin: "0 8px" }}
          >
            Previous
          </Button>
          <Button
            loading={loading}
            type="primary"
            onClick={() => StepThree()}
            style={{ margin: "0 8px" }}
          >
            Next
          </Button>
        </div>
      )}
    </div>
  );
};
export default StepTwo;
