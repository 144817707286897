import React, { FC, useEffect, useMemo, useState } from "react";
import { Avatar, Layout, Menu } from "antd";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import "./sideStyles.scss";
import {
  IoAnalyticsOutline,
  IoAnalyticsSharp,
  IoAppsSharp,
  IoBriefcaseOutline,
  IoCalendarOutline,
  IoCogSharp,
  IoConstructOutline,
  IoFileTrayFullOutline,
  IoGridOutline,
  IoHardwareChipOutline,
  IoLockClosedOutline,
  IoPeopleOutline,
  IoRadioOutline,
  IoReaderOutline,
  IoReceiptOutline,
  IoSettingsOutline,
  IoSwapVertical,
  IoCalendarNumberOutline,
  IoBaseball,
  IoNotificationsOutline,
  IoBuildSharp,
} from "react-icons/io5";
import { DateTime } from "luxon";
import {
  Actions,
  Authorizer,
  useAuthZ,
} from "../../Services/AuthorizationContext";

import { Plants } from "../../definetions/Constatnts";
import { BellOutlined } from "@ant-design/icons";

const { SubMenu } = Menu;

export interface ISiderIPS {
  selectedKey?: string[];
  openKeys?: string[];
}

const SiderIPS: FC<ISiderIPS> = ({ selectedKey, openKeys }: ISiderIPS) => {
  const { Sider } = Layout;
  const location = useLocation();
  const { isAuthenticated, canEdit, permissions } = useAuthZ();
  const [openKey, setOpenKey] = useState<string[]>([]);
  const history = useHistory();

  const SiderKeys = useMemo(() => {
    const list = location.pathname.split("/");
    // console.log(list[1]);
    setOpenKey([list[1]]);
    return {
      sKeys: list.length > 2 ? list[2] : list[1],
      oKeys: list.length > 2 ? list[1] : "",
    };
  }, [location]);

  useEffect(() => {
    const key = openKeys?.pop();
    setOpenKey([key ?? ""]);
  }, []);

  const onOpenChange = (keys: Array<React.Key>) => {
    const latestOpenKey = keys.find(
      (key) => openKey.indexOf(key.toString()) === -1,
    );
    if (latestOpenKey) {
      setOpenKey([latestOpenKey.toString()]);
    } else {
      setOpenKey([]);
    }
  };
  const onFinish = () => {
    history.push({
      pathname: "/report/utilization",
      state: {},
    });
  };

  return (
    <>
      <Sider
        width={260}
        breakpoint="lg"
        collapsedWidth={0}
        className="side-container"
      >
        <div className="side-nav-mainContainer">
          <Menu
            mode="inline"
            defaultSelectedKeys={selectedKey ?? [SiderKeys.sKeys]}
            defaultOpenKeys={openKeys ?? [SiderKeys.oKeys]}
            style={{ height: "100%" }}
            className="side-navigation-container"
            openKeys={openKey}
            onOpenChange={onOpenChange}
          >
            <Menu.Item
              key="iot-status"
              className="customclass"
              style={{ marginTop: "20px", left: 0, marginBottom: "20px" }}
              icon={
                <IoHardwareChipOutline
                  size={22}
                  color="#aeaeae"
                  style={{ marginRight: "10px" }}
                />
              }
            >
              <NavLink to="/iot-status" className="navigation-items">
                IoT Status
              </NavLink>
            </Menu.Item>

            <SubMenu
              key="report"
              style={{ marginBottom: "20px" }}
              icon={
                <IoReaderOutline
                  size={22}
                  color="#aeaeae"
                  style={{ marginRight: "10px" }}
                />
              }
              title="Reports"
            >
              {permissions && permissions?.features["8"] && (
                <Menu.Item
                  key="utilization"
                  className="customclass"
                  onClick={() => onFinish()}
                  icon={
                    <IoGridOutline
                      size={20}
                      color="#aeaeae"
                      style={{ marginRight: "10px" }}
                    />
                  }
                >
                  {/* <NavLink
                    to="/report/utilization"
                    className="navigation-items"
                  > */}
                  Utilization
                  {/* </NavLink> */}
                </Menu.Item>
              )}
              {permissions && permissions?.features["27"] && (
                <Menu.Item
                  key="archivedReports"
                  className="customclass"
                  icon={
                    <IoBriefcaseOutline
                      size={20}
                      color="#aeaeae"
                      style={{ marginRight: "10px" }}
                    />
                  }
                >
                  <NavLink
                    to="/report/archived-reports"
                    className="navigation-items"
                  >
                    Archives
                  </NavLink>
                </Menu.Item>
              )}
              {permissions && permissions?.features["28"] && (
                <Menu.Item
                  key="overallReport"
                  className="customclass"
                  icon={
                    <IoSwapVertical
                      size={20}
                      color="#aeaeae"
                      style={{ marginRight: "10px" }}
                    />
                  }
                >
                  <NavLink
                    to="/report/overall-report"
                    className="navigation-items"
                  >
                    Overall
                  </NavLink>
                </Menu.Item>
              )}
            </SubMenu>

            <SubMenu
              key="device"
              style={{ marginBottom: "20px" }}
              icon={
                <IoAppsSharp
                  size={22}
                  color="#aeaeae"
                  style={{ marginRight: "10px" }}
                />
              }
              title="System Management"
            >
              {permissions && permissions?.features["32"] && (
                <Menu.Item
                  key="locations"
                  className="customclass"
                  icon={
                    <IoConstructOutline
                      size={20}
                      color="#aeaeae"
                      style={{ marginRight: "10px" }}
                    />
                  }
                >
                  <NavLink to="/device/locations" className="navigation-items">
                    {Plants}
                  </NavLink>
                </Menu.Item>
              )}
              {permissions && permissions?.features["23"] && (
                <Menu.Item
                  key="machines"
                  className="customclass"
                  icon={
                    <IoCogSharp
                      size={22}
                      color="#aeaeae"
                      style={{ marginRight: "10px" }}
                    />
                  }
                >
                  <NavLink to="/device/machines" className="navigation-items">
                    Machines
                  </NavLink>
                </Menu.Item>
              )}
              {permissions && permissions?.features["24"] && (
                <Menu.Item
                  key="devices"
                  className="customclass"
                  icon={
                    <IoRadioOutline
                      size={20}
                      color="#aeaeae"
                      style={{ marginRight: "10px" }}
                    />
                  }
                >
                  <NavLink to="/device/devices" className="navigation-items">
                    IoT Devices
                  </NavLink>
                </Menu.Item>
              )}

              <Menu.Item
                key="resources"
                className="customclass"
                icon={
                  <IoFileTrayFullOutline
                    size={20}
                    color="#aeaeae"
                    style={{ marginRight: "10px" }}
                  />
                }
              >
                <NavLink to="/device/resources" className="navigation-items">
                  Resources
                </NavLink>
              </Menu.Item>

              <Menu.Item
                key="calender"
                className="customclass"
                icon={
                  <IoCalendarNumberOutline
                    size={20}
                    color="#aeaeae"
                    style={{ marginRight: "10px" }}
                  />
                }
              >
                <NavLink to="/device/calender" className="navigation-items">
                  Calendar
                </NavLink>
              </Menu.Item>
            </SubMenu>

            <SubMenu
              key="scheduling"
              className="customclass"
              style={{ marginBottom: "20px" }}
              icon={
                <IoAnalyticsOutline
                  size={22}
                  color="#aeaeae"
                  style={{ marginRight: "10px" }}
                />
              }
              title="Process Management"
            >
              <Menu.Item
                key="schedule"
                className="customclass"
                style={{ marginBottom: "20px" }}
                icon={
                  <IoCalendarOutline
                    size={22}
                    color="#aeaeae"
                    style={{ marginRight: "10px" }}
                  />
                }
              >
                <NavLink to="/scheduling/projects" className="navigation-items">
                  Scheduling
                </NavLink>
              </Menu.Item>

              <Menu.Item
                key="productionPlans"
                className="customclass"
                icon={
                  <IoReceiptOutline
                    size={20}
                    color="#aeaeae"
                    style={{ marginRight: "10px" }}
                  />
                }
              >
                <NavLink to="/scheduling/table" className="navigation-items">
                  Production Plans
                </NavLink>
              </Menu.Item>
            </SubMenu>

            <Menu.Item
              key="maintenance"
              className="customclass"
              style={{ marginTop: "20px", left: 0, marginBottom: "20px" }}
              icon={
                <IoBuildSharp
                  size={22}
                  color="#aeaeae"
                  style={{ marginRight: "10px" }}
                />
              }
            >
              <NavLink to="/maintenance/sensors" className="navigation-items">
                Maintenance
              </NavLink>
            </Menu.Item>

            <SubMenu
              key="settings"
              className="customclass"
              icon={
                <IoSettingsOutline
                  size={22}
                  color="#aeaeae"
                  style={{ marginRight: "10px" }}
                />
              }
              title="Settings"
            >
              {/* <NavLink to="#" className="navigation-items">
                Settings
              </NavLink> */}
              {permissions && permissions?.features["29"] && (
                <Menu.Item
                  key="userManagement"
                  className="customclass"
                  style={{ marginBottom: "20px" }}
                  icon={
                    <IoPeopleOutline
                      size={22}
                      color="#aeaeae"
                      style={{ marginRight: "10px" }}
                    />
                  }
                >
                  <NavLink
                    to="/settings/user-management"
                    className="navigation-items"
                  >
                    User Management
                  </NavLink>
                </Menu.Item>
              )}

              {permissions && permissions?.features["26"] && (
                <Menu.Item
                  key="authorization"
                  className="customclass"
                  style={{ marginBottom: "20px" }}
                  icon={
                    <IoLockClosedOutline
                      size={22}
                      color="#aeaeae"
                      style={{ marginRight: "10px" }}
                    />
                  }
                >
                  <NavLink
                    to="/settings/authorization"
                    className="navigation-items"
                  >
                    Access Control
                  </NavLink>
                </Menu.Item>
              )}

              {permissions && permissions?.features["85"] && (
                <Menu.Item
                  key="notifications"
                  className="customclass"
                  style={{ marginBottom: "20px" }}
                  icon={
                    <IoNotificationsOutline
                      size={22}
                      color="#aeaeae"
                      style={{ marginRight: "10px" }}
                    />
                  }
                >
                  <NavLink
                    to="/settings/notifications"
                    className="navigation-items"
                  >
                    Notifications
                  </NavLink>
                </Menu.Item>
              )}
            </SubMenu>
          </Menu>
        </div>
      </Sider>
      {/* <button type="button" onClick={() => console.log(openKey)}>
        check
      </button> */}
    </>
  );
};

export default SiderIPS;
