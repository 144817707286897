import React, { useState, FC } from "react";
import { Form, Input, Button, Checkbox, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { KeyOutline } from "react-ionicons";
import sha1 from "sha1";
import { Link, NavLink, Redirect } from "react-router-dom";
import sierraLogo from "./sierralogo.png";
import "./RecoverThroughEmail.scss";
import { useConnection } from "../../Services/ApplicationContext";
import { useAuthZ } from "../../Services/AuthorizationContext";
import { useHistory } from "react-router";

const RecoverThroughEmail: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  const onSubmit = () => {
    history.push("/EnterOTP");
  };

  return (
    <div className="main-container-rte">
      <div className="form-container-rte">
        <div className="form-header-rte">
          <div className="header-container-rte">
            <div className="detail-container-rte">
              <h2 className="h2-rte">Reset Password</h2>
              <h4 className="h4-rte">Enter you email to reset Password</h4>
            </div>
            <div className="icons">
              <KeyOutline color={"#fff"} height="80px" width="80px" />
            </div>
          </div>
        </div>
        <div className="form-footer-rte">
          <div className="form-footer-container-rte">
            <Form
              layout="vertical"
              name="basic"
              initialValues={{
                remember: true,
              }}
              hideRequiredMark
              onFinish={onSubmit}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (
                        getFieldValue("email") &&
                        !getFieldValue("email")
                          ?.trim()
                          .match(
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          )
                      ) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise.reject("Please input a valid E-mail!");
                      }
                      return Promise.resolve();
                    },
                  }),
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined style={{ color: "#808080" }} />}
                  placeholder="Email"
                  spellCheck="false"
                  type="email"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-btn-rte"
                  style={{ marginTop: "15px" }}
                >
                  Send OTP
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="login-rte">
            <NavLink to="/" className="navigation-rte">
              Return to Login
            </NavLink>
          </div>
        </div>
      </div>
      <div>
        <h5 className="powered-by-rte">Powered by Xeptagon</h5>
      </div>
    </div>
  );
};

export default RecoverThroughEmail;
