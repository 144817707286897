import React, { FC, useEffect, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  Select,
  message,
  TimePicker,
  Divider,
  Space,
  Row,
  Col,
} from "antd";
import moment from "moment";
import "../modalPopUp.scss";

export interface ConfirmationModalProps {
  visible: boolean;
  onClose: (val: boolean) => void;
}

const ConfirmationModal: FC<ConfirmationModalProps> = (
  props: ConfirmationModalProps,
) => {
  const { visible, onClose } = props;

  return (
    <Modal
      centered
      title="Incomplete Process Confirmation"
      visible={visible}
      width="62%"
      maskClosable={false}
      className="general-modal-popup modal-popup-header"
      onCancel={() => onClose(false)}
      footer={[
        <div className="modal-field-button">
          <Button type="primary" danger onClick={() => onClose(false)}>
            No
          </Button>
          <Button type="primary" onClick={() => onClose(true)}>
            Yes
          </Button>
        </div>,
      ]}
    >
      <p
        style={{
          fontFamily: "Poppins",
          fontSize: "15px",
          color: "rgba(255, 255, 255, 0.6)",
        }}
      >
        You have an incomplete project. Do you need to continue with that ?
        {/* {edit
          ? "You have a project in cache. Do you need to continue with that ?"
          : "You have an incomplete project. Do you need to continue with that ?"} */}
      </p>
    </Modal>
  );
};
export default ConfirmationModal;
