import {
  Dropdown,
  Layout,
  Menu,
  Avatar,
  Divider,
  Badge,
  message,
  Skeleton,
  List,
  Button,
} from "antd";
import React, {
  FC,
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { BellFilled, CaretDownFilled, UserOutlined } from "@ant-design/icons";
import { IoNotificationsOutline, IoSettingsOutline } from "react-icons/io5";
import { Link, NavLink } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useConnection } from "../../Services/ApplicationContext";
import { useUser } from "../../Services/UserContext";
import "./headerStyles.scss";
import logo from "../../assets/logo.png";
import profile from "../../assets/userIcon.png";
import NotificationItem, {
  NotificationItemProps,
} from "../NotificationItem/NotificationItem";
import NotificationDropdownHeader from "../NotificationItem/NotificationDropdownHeader";
import NotificationDropdownBottom from "../NotificationItem/NotificationDropdownBottom";
import NoNotifications from "../NotificationItem/NoNotifications";
import { useNotification } from "../../Services/NotificationContext";
import { SERVICE_TYPE } from "../../constants/constants";

const HeaderIPS: FC = () => {
  const { Header } = Layout;
  const { userInfo, removeUserInfo } = useUser();
  const {
    readToggleClickedCount,
    setReadToggleClickedCount,
    loadItem,
    setLoadItem,
    showUnread,
    setShowUnread,
    markAllRead,
    setMarkAllRead,
    getUnreadNotificationsCount,
    unreadNotificationCount,
    setUnreadNotificationCount,
  } = useNotification();
  const { get, delete: del, post, put } = useConnection();
  const [userFullName, setUserFullName] = useState("");

  // const [
  //   unreadNotificationCount,
  //   setUnreadNotificationCount,
  // ] = useState<number>(0);
  // const [showUnread, setShowUnread] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<NotificationItemProps[]>(
    [],
  );
  const [loading, setLoading] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<any>(1);
  const [pageSize, setPageSize] = useState<any>(5);
  const [error, setError] = useState<boolean>(true);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const [loadingList, setLoadingList] = useState(false);
  const [loadItemLists, setLoadItemLists] = useState<boolean>(false);
  const [
    filterVisibleUserProfileResources,
    setFilterVisibleUserProfileResources,
  ] = useState<any>();
  const [total, setTotal] = useState<number>(0);
  const { updateToken } = useConnection();

  document.addEventListener("mousedown", (event: any) => {
    const notificationDropDownArea1 = document.querySelector(
      ".notification-dropdown-container",
    );
    const notificationDropDownArea2 = document.querySelector(".visible-area");

    if (
      notificationDropDownArea1 !== null &&
      notificationDropDownArea2 !== null
    ) {
      console.log("not null 0 0 0 - - - - - ", {
        notificationDropDownArea1,
        notificationDropDownArea2,
      });
      if (
        notificationDropDownArea1.contains(event.target) ||
        notificationDropDownArea2.contains(event.target)
      ) {
        setFilterVisibleUserProfileResources(true);
      } else {
        setFilterVisibleUserProfileResources(false);
      }
    }
  });

  const handleNotificationDropDownVisibleChange = () => {
    setFilterVisibleUserProfileResources(true);
  };

  useEffect(() => {
    if (userInfo?.fullName) {
      setUserFullName(userInfo?.fullName);
    }
  }, [userInfo?.fullName]);

  useEffect(() => {
    getUnreadNotificationsCount();
  }, []);
  const signOut = (): void => {
    updateToken();
    removeUserInfo();
  };

  const getAllNotificationsRequestParams = () => {
    return {
      page: 0,
      size: pageSize * currentPage,
      order: "desc",
      orderBy: "createdTime",
      read: showUnread ? false : undefined,
      requestFor: "list",
    };
  };
  const loadMoreData = async () => {
    getNotificationsData().then((res: any) => {
      setCurrentPage(currentPage + 1);
      if (total > notifications?.length) {
        setLoading(true);
      }
    });
  };

  const getNotificationsData = async (pageNum?: number) => {
    try {
      const allNotificationsResult = await post(
        "notification/getAllNotifications",
        getAllNotificationsRequestParams(),
        undefined,
        SERVICE_TYPE.serviceType,
      );
      setTotal(allNotificationsResult?.data?.total);
      setNotifications(allNotificationsResult?.data?.notificationsList);
    } catch (error: any) {
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
      setError(false);
    } finally {
    }
  };

  useEffect(() => {
    getNotificationsData();
  }, [currentPage, pageSize, showUnread, markAllRead]);

  useEffect(() => {
    getNotificationsData().then((res: any) => {
      setError(false);
    });
  }, [readToggleClickedCount]);

  const userSettingsMenu = (
    <Menu mode="inline" className="header-dropdown">
      <Menu.Item className="dropdown-Items">
        <NavLink to="#">{userFullName}</NavLink>
      </Menu.Item>
      <Menu.Item key="51" className="dropdown-Items">
        <NavLink to="/update-user">Edit Profile</NavLink>
      </Menu.Item>
      <Menu.Item key="52" onClick={signOut} className="dropdown-Items">
        <NavLink to="#">Sign Out</NavLink>
      </Menu.Item>
    </Menu>
  );

  const updateAllNotifications = async () => {
    setLoadItemLists(true);
    try {
      await put(
        "notification/updateAllNotificationReadStatus",
        {
          read: true,
        },
        undefined,
        SERVICE_TYPE.serviceType,
      ).then((res: any) => {
        message.open({
          type: "success",
          content: res.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      });
    } catch (error: any) {
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
    } finally {
      setLoadItemLists(false);
      setMarkAllRead(true);
      getUnreadNotificationsCount();
    }
  };

  const notificationListMenu = (
    <div
      className="notification-list-container visible-area"
      id="scrollableDiv"
      style={{
        height: 500,
        overflow: "auto",
        border: "1px solid rgba(140, 140, 140, 0.35)",
      }}
    >
      <NotificationDropdownHeader />
      {unreadNotificationCount !== 0 && (
        <div className="mark-all">
          <Button onClick={updateAllNotifications} type="link" block>
            Mark all as read
          </Button>
        </div>
      )}
      {loadingList && !loading && (
        <>
          <div className="dropdown-Items" style={{ marginLeft: "15px" }}>
            <Skeleton paragraph={{ rows: 1 }} active />
          </div>
          <div className="dropdown-Items" style={{ marginLeft: "15px" }}>
            <Skeleton paragraph={{ rows: 1 }} active />
          </div>
          <div className="dropdown-Items" style={{ marginLeft: "15px" }}>
            <Skeleton paragraph={{ rows: 1 }} active />
          </div>
          <div className="dropdown-Items" style={{ marginLeft: "15px" }}>
            <Skeleton paragraph={{ rows: 1 }} active />
          </div>
          <div className="dropdown-Items" style={{ marginLeft: "15px" }}>
            <Skeleton paragraph={{ rows: 1 }} active />
          </div>
        </>
      )}
      <InfiniteScroll
        dataLength={total}
        next={loadMoreData}
        onScroll={() => {
          total > notifications?.length && loadMoreData();
        }}
        hasMore={true}
        loader={
          <div className="dropdown-Items" style={{ marginLeft: "15px" }}>
            <Skeleton paragraph={{ rows: 1 }} active />
          </div>
        }
        endMessage={<NotificationDropdownBottom />}
        scrollableTarget="scrollableDiv"
        scrollThreshold={0.9}
      >
        {notifications?.length === 0 && (
          <>
            <NoNotifications />
            <div style={{ marginTop: "100px" }}>
              <NotificationDropdownBottom />
            </div>
          </>
        )}
        {notifications?.length !== 0 &&
          notifications?.map((notification: any, index: any) => {
            return loadItem || loadItemLists ? (
              <div className="dropdown-Items" style={{ marginLeft: "15px" }}>
                <Skeleton paragraph={{ rows: 1 }} active />
              </div>
            ) : (
              <div className="dropdown-Items">
                <NotificationItem
                  id={notification?.id}
                  keyValue={notification?.key}
                  title={notification?.title}
                  content={notification?.content}
                  createdTime={notification?.createdTime}
                  type={notification?.type}
                  attentionLevel={notification?.attentionLevel}
                  userType={notification?.userType}
                  read={notification?.read}
                />
              </div>
            );
          })}
      </InfiniteScroll>
      {!(total > notifications?.length) && <NotificationDropdownBottom />}
    </div>
  );

  return (
    <div className="header-IPS">
      <Header className="header-container-IPS">
        <div className="header-title">
          <h3 className="header-xep">
            <NavLink to="/iot-status" className="heading">
              <img
                src={logo}
                alt="logo"
                height="50px"
                style={{ marginRight: "20px" }}
              />
            </NavLink>
          </h3>
        </div>
        <div className="icon-container">
          <div className="setting-icon-container">
            {/* <a href="#">
              <IoSettingsOutline size={25} />
            </a> */}
          </div>
          <div className="bell-icon-container">
            <Menu
              theme="dark"
              mode="horizontal"
              style={{
                float: "right",
                backgroundColor: "transparent",
              }}
            >
              <Menu.Item key="1">
                <div className="notification-dropdown-container">
                  <Dropdown
                    className="notificatio-dropdown"
                    overlay={notificationListMenu}
                    trigger={["click"]}
                    placement="bottomCenter"
                    onVisibleChange={() => {
                      handleNotificationDropDownVisibleChange();
                    }}
                    visible={filterVisibleUserProfileResources}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <Badge
                        count={unreadNotificationCount}
                        size="small"
                        dot={unreadNotificationCount > 200}
                        showZero={false}
                      >
                        <IoNotificationsOutline size={25} />
                      </Badge>
                    </a>
                  </Dropdown>
                </div>
              </Menu.Item>
            </Menu>
          </div>
          <div className="user-container">
            <Menu
              theme="dark"
              mode="horizontal"
              style={{
                float: "right",
                backgroundColor: "transparent",
              }}
            >
              <Menu.Item key="1">
                <Dropdown
                  overlay={userSettingsMenu}
                  trigger={["click"]}
                  placement="bottomCenter"
                >
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    <Avatar shape="circle" size={40} src={profile} />

                    <CaretDownFilled
                      size={50}
                      style={{ marginLeft: "15px", marginRight: "2px" }}
                    />
                  </a>
                </Dropdown>
              </Menu.Item>
            </Menu>
          </div>
        </div>
      </Header>
    </div>
  );
};
export default HeaderIPS;
