/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { FC, useEffect, useState } from "react";
import {
  Button,
  Empty,
  Input,
  message,
  Popconfirm,
  Skeleton,
  Space,
  Table,
} from "antd";
import { DeleteFilled, PlusOutlined, EditFilled } from "@ant-design/icons";
import "./Styles.scss";
import "../tableStyles.scss";
import { TablePaginationConfig } from "antd/es/table/Table";
import {
  IoCreateOutline,
  IoSearch,
  IoSettingsOutline,
  IoTrashSharp,
  IoWarning,
} from "react-icons/io5";
import { useConnection } from "../../Services/ApplicationContext";
import {
  DeviceTableData,
  UpdateDeviceMachineMapRequest,
} from "../../definetions/DeviceModel";
import AddDeviceModal from "./AddDeviceModal";
import { Actions, Authorizer } from "../../Services/AuthorizationContext";
import MainLayout from "../../components/MainLayout";
import { ILocation } from "../../definetions/MachineModel";
import EditDeviceModal from "./EditDeviceModal";
import "../pagination.scss";
import { DateTime } from "luxon";
import { SERVICE_TYPE } from "../../constants/constants";

interface Pagination {
  currentPage: number;
  pageSize: number;
}

const initialValue = {
  currentPage: 1,
  pageSize: 10,
};

const Devices: FC = () => {
  const { get, post, delete: del } = useConnection();
  const [loading, setLoading] = useState(true);

  const [deviceData, setDeviceData] = useState<UpdateDeviceMachineMapRequest[]>(
    [],
  );
  const [searchData, setSearchData] = useState<UpdateDeviceMachineMapRequest[]>(
    [],
  );
  const [addDeviceModalVisible, setAddDeviceModalVisible] = useState<boolean>(
    false,
  );
  const [locations, setLocations] = useState<ILocation[]>([]);
  const [selectedRow, setSelectedRow] = useState<DeviceTableData>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [total, setTotal] = useState<number>(0);
  const [pagination, setPagination] = useState<Pagination>(initialValue);
  const [sortOrder, setSortOrder] = useState<String>("");
  const [sortField, setSortField] = useState<String>("");
  const [machineLocation, setmachineLocation] = useState<string>("");

  const getAllDeviceRequestParams = () => {
    return {
      page: pagination.currentPage - 1,
      size: pagination.pageSize,
      searchBy: searchValue ? "deviceName" : undefined,
      searchValue: searchValue ? searchValue : undefined,
      orderBy: sortField !== "" ? sortField : undefined,
      order: sortOrder !== "" ? sortOrder : undefined,
    };
  };

  const getData = async () => {
    setLoading(true);
    try {
      const result: any = await post(
        "system-management/device/getAllDeviceDetails",
        getAllDeviceRequestParams(),
        undefined,
        SERVICE_TYPE.serviceType
      );
      console.log("get all device-1", result.data);
      setDeviceData(result.data.list);
      setTotal(result.data.total);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const getLocations = async () => {
    setLoading(true);
    try {
      const result = await get(
        "system-management/location/getAuthorizedLocations",
        undefined,
        SERVICE_TYPE.serviceType
      );
      setLocations(result.data.list);
      const locationArray: string[] = [];
      for (const i of result.data.list) {
        locationArray.push(i.locationName);
        const withSpaces = locationArray.join(", ");
        setmachineLocation(withSpaces);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  useEffect(() => {
    getData();
  }, [pagination, searchValue, sortField, sortOrder]);

  const edit = (record: any) => {
    setSelectedRow({ deviceType: "WISE-4051", ...record });
  };

  const Delete = async (val: DeviceTableData) => {
    setLoading(true);
    try {
      const result = await del(
        `system-management/device/deleteMachineMap/${val.deviceID}/${val.uid}`,
        undefined,
        SERVICE_TYPE.serviceType
      );
      if (result.statusText === "SUCCESS") {
        message.open({
          type: "success",
          content: result.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
        const temp = [...deviceData];
        const index = temp.findIndex(
          (value) => val.deviceID === value.deviceID,
        );
        if (index !== -1) {
          temp.splice(index, 1);
          setDeviceData(temp);
        }
        setLoading(false);
      } else {
        message.open({
          type: "error",
          content: "Something went wrong!",
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
        setLoading(false);
      }
    } catch (e) {
      message.open({
        type: "error",
        content:
          "Delete Terminated! Please disconnect machines from the device before deleting.",
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
      setLoading(false);
    }
  };

  const columns: any = [
    {
      title: "Device Name",
      dataIndex: "deviceName",
      key: "deviceName",
      sorter: true,
    },
    { title: "Device ID", dataIndex: "uid", key: "uid" },
    {
      title: "Last Updated On",
      key: "CreatedAt",
      sorter: true,
      render: (_: any, record: any) => {
        return DateTime.fromMillis(record.lastUpdated).toFormat(
          "dd:MM:yyyy,  hh:mm:ss a",
        );
      },
    },
    { title: "MAC", dataIndex: "mac", key: "mac" },
    { title: "No. of Slots", dataIndex: "numSlots", key: "numSlots" },
    {
      title: "Action",
      dataIndex: "operation",
      width: "120px",
      fixed: "right",
      render: (_: any, record: any) => {
        return (
          <Space size="middle">
            <Authorizer
              data={{
                feature: "33",
                action: Actions.HIDE,
              }}
            >
              <a onClick={() => edit(record)}>
                <IoCreateOutline size={22} color="#487199" />
              </a>
            </Authorizer>
            <Authorizer
              data={{
                feature: "34",
                action: Actions.HIDE,
              }}
            >
              <Popconfirm
                className="delete-pop"
                title={`Are you sure to delete ${record.deviceName}?`}
                onConfirm={() => Delete(record)}
                okText="Yes"
                okButtonProps={{ danger: true }}
                icon={<IoWarning size={30} color="#eec335" />}
                placement="topLeft"
                cancelText="No"
                cancelButtonProps={{ type: "primary" }}
              >
                <a>
                  <IoTrashSharp size={20} color="#f18b8b" />
                </a>
              </Popconfirm>
            </Authorizer>
          </Space>
        );
      },
    },
  ];

  const onAddModalClose = (val?: UpdateDeviceMachineMapRequest) => {
    if (val) {
      const temp = [...deviceData];
      const index = temp.findIndex(
        (item: UpdateDeviceMachineMapRequest) => val.deviceID === item.deviceID,
      );

      if (index === -1) {
        temp.push({ ...val });
        setDeviceData(temp);
      }
    }
    setAddDeviceModalVisible(false);
  };

  const onCloseEditModal = (val?: any) => {
    if (val) {
      const temp = [...deviceData];

      const index = temp.findIndex(
        (item: any) => val.deviceID === item.deviceID,
      );

      if (index === -1) {
        temp.push({ ...val });
        setDeviceData(temp);
      } else {
        temp[index] = val;
        setDeviceData(temp);
      }
    }
    setSelectedRow(undefined);
  };

  useEffect(() => {
    if (addDeviceModalVisible === true || selectedRow !== undefined) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [addDeviceModalVisible, selectedRow]);

  const handleChange = (val: any, sorter: any) => {
    console.log(" ------ val table machine --------", val);
    if (sorter.order === "ascend") {
      setSortOrder("asc");
    } else if (sorter.order === "descend") {
      setSortOrder("desc");
    } else if (sorter.order === undefined) {
      setSortOrder("");
    }
    if (sorter.columnKey !== undefined) {
      setSortField(sorter.columnKey);
    } else {
      setSortField("");
    }

    setPagination({ currentPage: val.current, pageSize: val.pageSize });
  };

  const searchByName = (val: string) => {
    const currValue = val;
    setSearchValue(currValue);
    // const filteredData = searchData.filter((entry) =>
    //   entry.deviceName.toLowerCase().includes(currValue.toLowerCase()),
    // );
    // currValue === "" ? getData() : setDeviceData(filteredData);
  };

  return (
    <MainLayout
      type="primary"
      // title="Manage Devices"
      OpenKey={["device"]}
      selectedKey={["devices"]}
    >
      {addDeviceModalVisible && (
        <AddDeviceModal
          visible={addDeviceModalVisible}
          onClose={onAddModalClose}
          locations={locations}
        />
      )}
      {selectedRow !== undefined && (
        <EditDeviceModal
          visible={selectedRow !== undefined}
          onClose={onCloseEditModal}
          locations={locations}
          data={selectedRow}
        />
      )}
      <div className="main-container-devices">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginBottom: "30px",
            position: "sticky",
            top: "0",
            backgroundColor: "#1b1b1b",
            zIndex: 1,
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <Authorizer data={{ feature: "11", action: Actions.HIDE }}>
            <Button
              type="primary"
              // className="add-device-btn"
              style={{
                borderRadius: "5px",
                height: "45px",
                fontSize: "15px",
                fontWeight: 300,
                fontFamily: "Poppins",
              }}
              onClick={() => setAddDeviceModalVisible(true)}
            >
              <PlusOutlined style={{ fontSize: "17px" }} />
              Add Device
            </Button>
          </Authorizer>
          <Input
            placeholder="Search by Device Name"
            className="device-search-bar"
            prefix={
              <IoSearch
                size={20}
                color="rgba(255, 255, 255, 0.6)"
                style={{
                  marginRight: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            }
            value={searchValue}
            style={{ width: "30%", height: "45px" }}
            onChange={(e) => searchByName(e.target.value)}
          />
        </div>
        <div className="general-table general-pagination">
          <Table
            columns={columns}
            dataSource={deviceData}
            loading={loading}
            pagination={{
              pageSize: pagination.pageSize,
              total,
              current: pagination.currentPage,
              showQuickJumper: true,
              showSizeChanger: true,
            }}
            onChange={(val: any, filter: any, sorter: any) =>
              handleChange(val, sorter)
            }
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    total === 0 && !searchValue
                      ? `No devices available in ${machineLocation}`
                      : "No data"
                  }
                />
              ),
            }}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default Devices;
