/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { FC, useEffect, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  Select,
  message,
  TimePicker,
  Divider,
  Space,
  Row,
  Col,
} from "antd";
import moment from "moment";
import "../modalPopUp.scss";
import { useProject } from "../Projects/NewProjextContext";

export interface ConfirmationModalProps {
  visible: boolean;
  onClose: (val: boolean) => void;
}

const ScheduleErrorPopUp: FC<ConfirmationModalProps> = (
  props: ConfirmationModalProps,
) => {
  const { visible, onClose } = props;
  const {
    current,
    setCurrent,
    timelineData,
    setNoSchedules,
    noSchedules,
    errorData, 
    setErrorData
  } = useProject();

  return (
    <Modal
      centered
      title="Failed to generate Tentative Schedules"
      visible={visible}
      width="62%"
      maskClosable={false}
      closable={false}
      className="general-modal-popup modal-popup-header"
      onCancel={() => onClose(false)}
      footer={[
        <div className="modal-field-button">
          <Button
          type="primary"
          style={{
            height: "40px",
            borderRadius: "5px",
            marginTop: "20px",
            fontFamily: "Poppins",
            fontSize: "15px",
          }}
          onClick={() => {
            setCurrent(current - 1);
            setNoSchedules(false);
            setErrorData([]);
          }}
        >
          Previous
        </Button>
        </div>,
      ]}
    >
        {errorData.map((val:any)=>{
            return (
                <p
            style={{
            fontFamily: "Poppins",
            fontSize: "15px",
            color: "rgba(255, 255, 255, 0.6)",
        }}
        >
            {val}
        </p>
            )
        })}
    </Modal>
  );
};
export default ScheduleErrorPopUp;
