export const getPreviousTaskMachineIds = (
  node: string,
  nodes: any[],
  edges: any[],
): string[] => {
  const fromNodes = edges
    .filter((edge) => edge.to === node)
    .map((edge) => edge.from);
  const machineIds = new Array<string>();
  fromNodes.forEach((fromNode) => {
    const fromNodeData = nodes.find((item) => item?.id === fromNode);
    console.log(fromNodeData, "fromNodeData");

    if (fromNodeData.data.type === "Task") {
      console.log(fromNodeData?.data?.props, "fromNodeData?.data?.props");
      if (fromNodeData?.data?.props?.machines) {
        const machines = fromNodeData?.data?.props?.machines;
        machines.forEach((machine: any) => {
          machineIds.push(machine.machineID);
        });
      }
    }
  });

  console.log("machineIds", machineIds);
  return machineIds;
};
