import moment from "moment";
import { DateTime } from "luxon";
import {
  Button,
  DatePicker,
  Form,
  Select,
  Skeleton,
  message,
  Table,
  Space,
  Popconfirm,
  Input,
  Row,
  Col,
  Tag,
} from "antd";

import React, { useState, useEffect, FC, useCallback } from "react";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useConnection } from "../../Services/ApplicationContext";

import "../tableStyles.scss";
import "./projects.scss";
import { IoCreateOutline, IoTrashSharp, IoWarning } from "react-icons/io5";
import { useUser } from "../../Services/UserContext";

import AuditTrialSchedule from "./AuditTrialSchedule";
import { SERVICE_TYPE } from "../../constants/constants";

const { Option } = Select;
const { RangePicker } = DatePicker;

const ProjectsTable: FC = () => {
  const history = useHistory();
  const { recentProject, setRecentProject } = useUser();
  const { get, delete: del, post } = useConnection();
  const [skeletonLoading, setSkeletonLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalProjects, setTotalProjects] = useState<number>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [startDate, setStartDate] = useState<any>("");
  const [projectStatus, setProjectStatus] = useState<any>("");
  const [endDate, setEndDate] = useState<any>("");
  const [networkSearch, setNetworkSearch] = useState<string>("");

  const [form] = Form.useForm();
  const { resetFields, getFieldValue } = form;

  const [sortOrder, setSortOrder] = useState<string>("");
  const [sortField, setSortField] = useState<string>("");

  const columns: any = [
    {
      title: "Project Name",
      dataIndex: "projectName",
      key: "projectName",
      width: 150,
      sorter: true,
      render: (text: any, record: any) => <span>{record?.projectName}</span>,
    },
    {
      title: "Project ID",
      dataIndex: "projectId",
      key: "sourceName",
      width: 150,
      render: (text: any, record: any) => <span>{record?.projectId}</span>,
    },
    {
      title: "Updated on",
      dataIndex: "cableType",
      key: "CreatedAt",
      width: 150,
      sorter: true,
      render: (text: any, record: any) => {
        return (
          <Space size="middle">
            {moment.unix(record.lastUpdatedTime).format("DD:MM:YYYY, hh:mm:ss a")}
          </Space>
        );
      },
    },
    {
      title: "Start Date",
      dataIndex: "startTime",
      key: "startTime",
      width: 150,
      sorter: true,
      render: (text: any, record: any) => (
        <Space size="middle">
          {moment.unix(record.projectStartTime).format("DD:MM:YYYY, hh:mm:ss a")}
        </Space>
      ),
    },
    {
      title: "End Date",
      dataIndex: "endTime",
      key: "endTime",
      width: 150,
      sorter: true,
      render: (text: any, record: any) => {
        return (
          <Space size="middle">
            {moment.unix(record.projectEndTime).format("DD:MM:YYYY, hh:mm:ss a")}
          </Space>
        );
      },
    },
    {
      title: "Project Status",
      dataIndex: "projectStatus",
      key: "date",
      width: 150,
      sorter: false,
      render: (text: any, record: any) => {
        // console.log(record);
        return (
          <Space size="middle">
            {record?.projectStatus ? (
              <Tag
                color={projectStatusTextColour(record?.projectStatus)}
                className="table-status"
                style={{
                  backgroundColor: projectStatusBackGroundColour(
                    record?.projectStatus,
                  ),
                  color: projectStatusTextColour(record?.projectStatus),
                }}
              >
                <div className="row">
                  <div>{"\u2B24"}</div>
                  <div className="status_text">
                    {record?.projectStatus.toUpperCase()}
                  </div>
                </div>
              </Tag>
            ) : null}
          </Space>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "download",
      width: 10,
      render: (text: any, record: any) => (
        <Space size="middle">
          <a
            style={{ fontFamily: "Poppins", color: "rgba(255, 255, 255, 0.5)" }}
            href={`/scheduling/project/${record?.projectId}`}
          >
            View More
          </a>
          <a href={`/scheduling/projectEdit/${record?.projectId}`}>
            <IoCreateOutline size={22} color="#487199" />
          </a>
          <Popconfirm
            title={`Are you sure to delete ${record?.projectName}?`}
            onConfirm={() => deleteProject(record)}
            okText="Yes"
            okButtonProps={{ danger: true }}
            cancelText="No"
            icon={<IoWarning size={30} color="#eec335" />}
            placement="topLeft"
            cancelButtonProps={{ type: "primary" }}
          >
            <a>
              <IoTrashSharp size={20} color="#f18b8b" />
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const projectStatusColour = (status: string) => {
    switch (status.toLocaleLowerCase()) {
      case "ongoing":
        return "#99F399";
      case "pending":
        return "#FFE99A";
      case "done":
        return "#EAF4EA";
      default:
        return "#FFE99A";
    }
  };

  const projectStatusBackGroundColour = (status: string) => {
    switch (status.toLocaleLowerCase()) {
      case "ongoing":
        return "#99F399";
      case "scheduled":
        return "#FFE99A";
      case "completed":
        return "#EAF4EA";
      case "reschedule":
        return "#FDBEBE";
      case "delayed":
        return "#96bdff";
      case "paused":
        return "#ff8566";
      case "deleted":
        return "#ff8566";  
      default:
        return "#18923A";
    }
  };

  const projectStatusTextColour = (status: string) => {
    switch (status.toLocaleLowerCase()) {
      case "ongoing":
        return "#18923A";
      case "scheduled":
        return "#C29800";
      case "completed":
        return "#636663";
      case "reschedule":
        return "#EF5828";
      case "delayed":
        return "#3277ed";
      case "paused":
        return "#cc2900";
      case "deleted":
        return "#cc2900";  
      default:
        return "#18923A";
    }
  };

  const deleteProject = async (row: any) => {
    // console.log(row);
    setSkeletonLoading(true);
    try {
      // const results = await del(`production/deleteProject/${row?.projectId}`);
      const results = await del(
        `scheduler/processScheduling/deleteProjectTemplate?id=${row?.projectId}`,
        undefined,
        SERVICE_TYPE.serviceType
      );
      // setTableData([]);
      // console.log("---------DELETING PROJECT---------", results);
      getAllProjects();
      if (results.status === 200) {
        setSearchTerm("");
        setNetworkSearch("");
        setSkeletonLoading(false);
        message.open({
          type: "success",
          content: results?.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      }
      message.open({
        type: "success",
        content: "Project deleted successfully",
        duration: 2,
      });
      setSkeletonLoading(false);
    } catch (error: any) {
      console.log(error);
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
      setSkeletonLoading(false);
    }
  };

  const handleTableChange = (val: any, sorter: any) => {
    if (sorter.order === "ascend") {
      setSortOrder("asc");
    } else if (sorter.order === "descend") {
      setSortOrder("desc");
    } else if (sorter.order === undefined) {
      setSortOrder("");
    }
    if (sorter.columnKey !== undefined) {
      setSortField(sorter.columnKey);
    } else {
      setSortField("");
    }
    if (val?.current > 1) {
      setCurrentPage(val.current - 1);
    } else if (val?.current === 1) {
      setCurrentPage(0);
    }
    setPageSize(val.pageSize);
  };

  const onChangeRange = (dateMoment: any, dateString: any) => {
    console.log(dateMoment);
    setCurrentPage(0);
    if (dateMoment !== null && dateMoment[1] !== null) {
      setStartDate(
        Date.parse(String(moment(dateMoment[0]?._d).startOf("day"))),
      );
      setEndDate(Date.parse(String(moment(dateMoment[1]?._d).endOf("day"))));
    } else {
      setStartDate("");
      setEndDate("");
    }
  };

  const onSearch = () => {
    setCurrentPage(0);
    setNetworkSearch(searchTerm);
  };

  const getAllProjectsRequestParams = () => {
    return {
      page: currentPage,
      size: pageSize,
      startTime: startDate !== "" ? parseInt(startDate) / 1000 : undefined,
      endTime: endDate !== "" ? parseInt(endDate) / 1000 : undefined,
      searchTerm: searchTerm !== "" ? searchTerm : undefined,
      status: projectStatus !== "" ? projectStatus : undefined,
      orderBy: sortField !== "" ? sortField : undefined,
      order: sortOrder !== "" ? sortOrder : undefined,
    };
  };

  const getAllProjects = async () => {
    setSkeletonLoading(true);
    setTableLoading(true);
    try {
      // const results = await post("production/getAllProjects", {
      //   ...getAllProjectsRequestParams(),
      // });
      const results = await post(
        `scheduler/processScheduling/getAllTemplates`,  
        { ...getAllProjectsRequestParams()},
        undefined,
        SERVICE_TYPE.serviceType
      );

     console.log(results,"resultsresultsresults",recentProject)
      if (
        recentProject &&
        !results?.data?.list?.find(
          (item: any) => item.projectId === recentProject?.id?.id,
        )
      ) {
        setTableData([...results?.data?.list, recentProject]);
        setTotalProjects(results?.data?.total + 1);
        setRecentProject(undefined);
      } else {
        setTableData(results?.data?.list);
        setTotalProjects(results?.data?.total);
        setRecentProject(undefined);
      }
      // console.log(
      //   recentProject,
      //   "0---------------------------ree----22----------",
      // );
      setTableLoading(false);
      setSkeletonLoading(false);
    } catch (error: any) {
      console.log(error);
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
      setSkeletonLoading(false);
    }
  };

  useEffect(() => {
    getAllProjects();
  }, [
    currentPage, 
    pageSize,
    startDate,
    endDate,
    networkSearch,
    projectStatus,
    sortField,
    sortOrder,
  ]);

  // useEffect(()=>{
  //   getAllProjects();
  // },[])

  const handleStatusChange = (value: any) => {
    if (value === "all") {
      setProjectStatus("");
    } else {
      setProjectStatus(value);
    }
  };

  return (
    <div>
      <div className="inner-dark-section">
        <div className="search-section" style={{ marginBottom: "30px" }}>
          <Row gutter={[15, 30]}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <Button
                className="add-button"
                type="primary"
                style={{
                  alignSelf: "flex-start",
                  height: "45px",
                  fontFamily: "Poppins",
                }}
                icon={<PlusOutlined />}
                onClick={() => history.push("/scheduling/newProject")}
              >
                Add New Project
              </Button>
            </Col>
            <Col lg={6} md={12} sm={12} xs={24}>
              <div className="row">
                <div className="search_filter_lables">Status: </div>
                <Select
                  // getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  // showSearch
                  // optionFilterProp="children"
                  // filterOption={(input, option: any) =>
                  //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  //   0
                  // }
                  defaultValue={"all"}
                  // placeholder="Select a Status"
                  style={{ width: "200px", marginLeft: "2%" }}
                  onChange={(value: any) => {
                    handleStatusChange(value);
                  }}
                >
                  <Option value="all">All</Option>
                  <Option value="completed">Completed</Option>
                  <Option value="delayed">Delayed</Option>
                  <Option value="ongoing">Ongoing</Option>
                  <Option value="scheduled">Scheduled</Option>
                  <Option value="reschedule">Reschedule</Option>
                  <Option value="paused">Paused</Option>
                </Select>
              </div>
            </Col>
            <Col lg={9} md={12} sm={12} xs={24}>
              <div className="clear-circle row center">
                <div className="search_filter_lables">Interval: </div>
                <RangePicker
                  format="DD:MM:YYYY"
                  allowEmpty={[true, true]}
                  style={{ width: "300px", marginLeft: "2%" }}
                  onChange={onChangeRange}
                />
              </div>
            </Col>
            <Col
              lg={9}
              md={12}
              sm={12}
              xs={24}
              style={{ justifyContent: "flex-end" }}
            >
              <div className="row end">
                <div
                  className="search_filter_lables"
                  style={{ marginRight: "7px" }}
                >
                  Search:
                </div>
                <Input
                  onPressEnter={onSearch}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search By Project Name"
                  value={searchTerm}
                  suffix={
                    <Button
                      type="primary"
                      onClick={onSearch}
                      style={{
                        borderRadius: "0 5px 5px 0",
                        marginRight: "-4px",
                      }}
                    >
                      <SearchOutlined />
                    </Button>
                  }
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="general-table general-pagination">
          <Table
            className="components-table-demo-nested"
            columns={columns}
            dataSource={tableData}
            loading={skeletonLoading}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["5", "10", "20", "30", "40", "50", "100"],
              current: currentPage + 1,
              pageSize,
              total: totalProjects,
              showQuickJumper: true,
            }}
            onChange={(val: any, filter: any, sorter: any) =>
              handleTableChange(val, sorter)
            }
          />
        </div>
      </div>
      <div style={{ marginTop: 20 }}>
        <AuditTrialSchedule type="Scheduling" />
      </div>
    </div>
  );
};

export default ProjectsTable;
