/* eslint-disable no-restricted-syntax */
/* eslint-disable no-case-declarations */
/* eslint-disable default-case */
import React, { FC, useEffect } from "react";
import Chart from "react-apexcharts";
import { DateTime, Duration } from "luxon";
import "./UtilizationCurve.scss";
import { Button } from "antd";

const UtilizationCurve = (props: any) => {
  const { prev, curr, dDur, times, prevPercent, currPercent } = props;

  const process = (hm: any, b: number, time: number) => {
    const start: DateTime = DateTime.fromMillis(time);
    // const bDur: Duration = Duration.fromISO(hm.bDur);
    // const utils: number[] = hm;
    const dur: Duration = Duration.fromISO(dDur);
    const dataArray = [];
    const tooltips = [];
    // let itr = start;

    let i = 0;
    for (const d of hm) {
      let totMin = 0;
      let cat;
      const mid = DateTime.fromMillis(d.time);
      switch (dur.toISO()) {
        case "P1D":
          const formattedTime = mid.toLocaleString(DateTime.TIME_SIMPLE);
          const isThirtyMinutes = formattedTime.includes(":30");
          cat = isThirtyMinutes ? "" : formattedTime;
          totMin = 30;
          break;
        case "P1W":
          const dayOfWeek = mid.toFormat("cccc");
          cat = dayOfWeek;
          totMin = 60 * 24;
          break;
        case "P1M":
          cat = i + 1;
          totMin = 60 * 24;
          break;
        case "P1Y":
          const month = mid.toFormat("LLLL");
          const yr = mid.toFormat("kk");
          cat = `${yr} ${month}`;
          totMin = 60 * 24 * 30;
          break;
      }
      const val = Math.round((100 * d.utilizationMin) / totMin);
      tooltips.push(val ? `${val}%` : "0");
      i += 1;
      dataArray.push({
        x: cat,
        y: val,
        prevPercent,
        currPercent,
      });
      console.log("dataArray", dataArray);
    }

    const dateGen = (d: DateTime) =>
      `${d.toFormat("dd")}.${d.toFormat("LL")}.${d.toFormat("kkkk")}`;
    let name;
    switch (dur.toISO()) {
      case "P1D":
        name = dateGen(start);
        break;
      case "P1W":
        name = `${dateGen(start.minus(dur).plus(60000 * 24 * 60))} - ${dateGen(
          start,
        )}`;
        break;
      case "P1M":
        const month = start.toFormat("LLL");
        const yr = start.toFormat("y");
        name = `${month} ${yr}`;
        break;
      case "P1Y":
        const year = start.toFormat("y");
        name = `${year}`;
        break;
    }
    // console.log("UtilizationCurve", dataArray, tooltips, name);
    return { name, dataArray, tooltips };
  };

  useEffect(() => {
    console.log(prev, curr, dDur, times, prevPercent, currPercent, "props");
  }, []);

  let b;
  let prevProc;
  let currProc;
  let series;

  if (prev && curr) {
    b = Math.max(prev?.length, curr?.length);
    prevProc = process(prev, b, times[1]);
    currProc = process(curr, b, times[0]);
    series = [
      {
        name: `${prevProc.name} - ${prevPercent}%`,
        data: prevProc.dataArray,
        type: "column",
      },
      {
        name: `${currProc.name} - ${currPercent}%`,
        data: currProc.dataArray,
        type: "column",
      },
    ];
  }

  const options: any = {
    chart: {
      // height: 120,
      type: "histogram",
      foreColor: "#ffffff",
      offsetX: 0,
      offsetY: 0,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 0,
      curve: "smooth",
      dashArray: 0,
    },
    markers: {
      size: 4,
      hover: {
        sizeOffset: 2,
      },
    },
    xaxis: {
      type: "category",
      tickAmount: dDur !== "P1M" ? undefined : 30,
      labels: {
        maxHeight: 61,
        minHeight: 60,
        offsetY: 5,
        style: {
          colors: [],
          fontSize: "10px",
          fontFamily: "Poppins",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-label",
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 5,
      title: {
        text: "Utilization %",
        rotate: 0,
        offsetX: 30,
        offsetY: -180,
        style: {
          colors: [],
          fontSize: "14px",
          fontFamily: "Poppins",
          fontWeight: 200,
        },
      },
    },
    legend: {
      position: "top",
      offsetY: 0,
      fontSize: "14px",
      fontFamily: "Poppins",
      fontWeight: 500,
      itemMargin: {
        horizontal: 10,
        vertical: 5,
      },
    },
    tooltip: {
      style: {
        fontFamily: "Poppins",
        fontSize: "12px",
      },
      theme: "dark",
      y: {
        formatter: (value: any) => {
          if (!Number.isNaN(+value)) return `${value}%`;
          return undefined;
        },
      },
      x: {
        formatter: (value: any, a: any, w: any) => {
          const count = a?.w?.globals?.labels?.length;
          const index = a?.w?.globals?.labels?.indexOf(value);
          if (index === 0) {
            const val = `${value} - ${a?.w?.globals?.labels[1]}`;
            return val;
          }
          if (index === count - 1) {
            const val = `${value} - ${a?.w?.globals?.labels[0]}`;
            return val;
          }
          const val = `${value} - ${a?.w?.globals?.labels[index + 1]}`;
          return val;
        },
      },
    },
    grid: {
      borderColor: "rgba(217, 213, 236, 0.1)",
    },
    animations: {
      enabled: true,
      easing: "easeinout",
      speed: 700,
      animateGradually: {
        enabled: true,
        delay: 200,
      },
      dynamicAnimation: {
        enabled: true,
        speed: 350,
      },
    },
    colors: ["#DC5F3A", "#E0A035", "#D4526E", "#2983FF", "#546E7A"],
  };

  return (
    <div className="daily-utilization-section">
      <div
        style={{
          marginRight: "60px",
          background: "#242424",
          borderRadius: "10px",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "35px",
          paddingBottom: "15px",
          paddingRight: "85px",
          paddingLeft: "0px",
        }}
      >
        <Chart
          options={options}
          series={series}
          type="histogram"
          height={400}
          width="105%"
        />
        {/* <Button onClick={() => console.log(series)}>check</Button> */}
      </div>
    </div>
  );
};

export default UtilizationCurve;
