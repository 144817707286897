// // holds some of the data structures and functions used to validate the graph
export const cableState = {
  leaves: new Set(), // leaves are nodes that have only connections_to
  seeds: new Set(), // seeds are nodes that have only connections
  connections: new Map(),
  connections_to: new Map(),
  print(): void {
    console.log("######");
    console.log(this.leaves);
    console.log(this.seeds);
    console.log(this.connections);
    console.log(this.connections_to, "\n");
  },
  initiate(): void {
    this.clear();
    addToCableStates("1", "2");
    addToCableStates("2", "3");
  },
  clear(): void {
    this.leaves.clear();
    this.seeds.clear();
    this.connections.clear();
    this.connections_to.clear();
  },
};

export const populateCableState = (
  leaves: string[],
  seeds: string[],
  connections: { [id: string]: string },
  connections_to: { [id: string]: string },
): void => {
  cableState.clear();
  cableState.leaves = new Set(leaves);
  cableState.seeds = new Set(seeds);
  cableState.connections = new Map(Object.entries(connections));
  cableState.connections_to = new Map(Object.entries(connections_to));
};

export const addToCableStates = (fromId: string, toId: string): void => {
  // cableState.print();
  if (!cableState.connections.has(fromId)) {
    cableState.leaves.add(fromId);
    cableState.seeds.add(fromId);
    cableState.connections.set(fromId, 0);
    cableState.connections_to.set(fromId, 0);
  }

  if (!cableState.connections_to.has(toId)) {
    cableState.leaves.add(toId);
    cableState.seeds.add(toId);
    cableState.connections.set(toId, 0);
    cableState.connections_to.set(toId, 0);
  }

  if (cableState.leaves.has(fromId)) {
    cableState.leaves.delete(fromId);
  }

  if (cableState.seeds.has(toId)) {
    cableState.seeds.delete(toId);
  }

  if (cableState.connections.get(toId) < 1) {
    cableState.leaves.add(toId);
  }

  cableState.connections.set(fromId, cableState.connections.get(fromId) + 1);
  cableState.connections_to.set(toId, cableState.connections_to.get(toId) + 1);
  // cableState.print();
};

export const removeFromCableStates = (fromId: string, toId: string): void => {
  // console.log(fromId, toId);
  // cableState.print();
  if (cableState.connections.get(fromId) <= 1) {
    // console.log(1);
    cableState.leaves.add(fromId);
  } 
  // else {
  //   console.log(2);
  //   const temp = cableState.connections.get(fromId) - 1;
  //   cableState.connections.set(fromId, temp);
  // }

  if (cableState.connections_to.get(toId) <= 1) {
    cableState.seeds.add(toId);
    // console.log(3);
  } 
  // else {
  //   console.log(4);
  //   const temp = cableState.connections_to.get(fromId) - 1;
  //   cableState.connections_to.set(toId, temp);
  // }

  cableState.connections.set(fromId, cableState.connections.get(fromId) - 1);
  cableState.connections_to.set(toId, cableState.connections_to.get(toId) - 1);
  // cableState.print();
};

export const deleteFromAllCableStates = (id: string): void => {
  cableState.leaves.delete(id);
  cableState.seeds.delete(id);
  cableState.connections.delete(id);
  cableState.connections_to.delete(id);
};

export const getCableStateLeaves = () => {
  const temp: string[] = [];
  cableState.leaves.forEach((state: any) => temp.push(state));
  return temp;
};

export const getCableStateSeeds = () => {
  const temp: string[] = [];
  cableState.seeds.forEach((state: any) => temp.push(state));
  return temp;
};

// export const getLeafTypes = () => {
//   const leafNodes = getCableStateLeaves();
//   const leafTypes = new Set();

//   leafNodes.forEach((node: string) => {
//     const temp = node.find((item: any) => item.id === node);
//     leafTypes.add(temp?.data?.type);
//   });
// }

// export const getSeedTypes = () => {
//   const seeNodes = getCableStateSeeds();
//   const seedTypes = new Set();

//   seedNodes.forEach((node: string) => {
//     const temp = node.find((item: any) => item.id === node);
//     seedTypes.add(temp?.data?.type);
//   });
// }

export const getCableStateConnections = (): { [id: string]: string } => {
  return Object.fromEntries(cableState.connections);
};

export const getCableStateConnectionsTo = (): { [id: string]: string } => {
  return Object.fromEntries(cableState.connections_to);
};

type CableState = {
  leaves: string[];
  seeds: string[];
  connections: { [id: string]: string };
  connections_to: { [id: string]: string };
};

export const cableStateHistory: CableState[] = []; // stores cableStates for undoing
export const cableStateFuture: CableState[] = []; // stores cableStates for redoing

export const populateCableStateHistory = (history: CableState[]): void => {
  cableStateHistory.splice(0, cableStateHistory.length);
  cableStateHistory.concat(history);
};

export const populateCableStateFuture = (future: CableState[]): void => {
  cableStateFuture.splice(0, cableStateFuture.length);
  cableStateFuture.concat(future);
};

export const createCableHistory = (
  leaves: string[],
  seeds: string[],
  connections: { [id: string]: string },
  connections_to: { [id: string]: string },
): CableState => {
  return {
    leaves,
    seeds,
    connections,
    connections_to,
  };
};

export const addToCableHistory = (): void => {
  cableStateHistory.push(
    createCableHistory(
      getCableStateLeaves(),
      getCableStateSeeds(),
      getCableStateConnections(),
      getCableStateConnectionsTo(),
    ),
  );
};

export const addToCableFuture = (): void => {
  cableStateFuture.push(
    createCableHistory(
      getCableStateLeaves(),
      getCableStateSeeds(),
      getCableStateConnections(),
      getCableStateConnectionsTo(),
    ),
  );
};

export const undoCableStates = (): void => {
  cableState.print();
  let lastState;
  if (cableStateHistory.length > 0 && cableStateHistory !== undefined) {
    lastState = cableStateHistory.pop();
  }
  if (
    lastState?.leaves &&
    lastState?.seeds &&
    lastState?.connections &&
    lastState?.connections_to
  ) {
    populateCableState(
      lastState.leaves,
      lastState.seeds,
      lastState.connections,
      lastState.connections_to,
    );
  }

  // cableState.print();
  // console.log(cableStateHistory, "cableHistory");
  // console.log(cableStateFuture, "cableFutures");
};

export const redoCableStates = (): void => {
  // cableState.print();
  let lastState;
  if (cableStateFuture.length > 0 && cableStateFuture !== undefined) {
    lastState = cableStateFuture.pop();
  }
  if (
    lastState?.leaves &&
    lastState?.seeds &&
    lastState?.connections &&
    lastState?.connections_to
  ) {
    populateCableState(
      lastState.leaves,
      lastState.seeds,
      lastState.connections,
      lastState.connections_to,
    );
  }

  // cableState.print();
  // console.log(cableStateHistory, "cableHistory");
  // console.log(cableStateFuture, "cableFutures");
};

export const checkEdgeValidations = (
  fromNodeType: string,
  toNodeType: string,
): string => {
  if (fromNodeType === "Input" && toNodeType === "Input") {
    return "Error! An Input cannot be mapped into another Input";
  }
  if (fromNodeType === "Input" && toNodeType === "Output") {
    return "Error! An Input cannot be mapped into another Output";
  }
  if (fromNodeType === "Task" && toNodeType === "Input") {
    return "Error! A Task cannot lead to an input";
  }
  if (fromNodeType === "Output" && toNodeType === "Input") {
    return "Error! An Output cannot be mapped into another Input";
  }
  if (fromNodeType === "Output" && toNodeType === "Task") {
    return "Error! An Output cannot lead to a task";
  }
  if (fromNodeType === "Output" && toNodeType === "Output") {
    return "Error! An Output cannot be mapped into another Output";
  }
  return "OK";
};
