import { ConsoleSqlOutlined } from "@ant-design/icons";
import React, {
  FC,
  ReactNode,
  useContext,
  useState,
  useEffect,
  createContext,
} from "react";

import { message } from "antd";

import { useConnection } from "./ApplicationContext";
import { SERVICE_TYPE } from "../constants/constants";

export enum ClientProfileTypes {
  ADMINISTRATOR = "Administrator",
  MANAGER = "Manager",
  SUPERVISOR = "Supervisor",
  ANALYST = "Analyst",
}

export type UserProps = {
  email: string;
  id?: string;
  fullName: string;
  userType?: string;
};

export type UserContextProps = {
  userInfo?: UserProps;
  setUserInfo: (val: UserProps) => void;
  removeUserInfo: () => void;
  setFullName: (val: string) => void;
  getFullName: () => string;
  recentProject: any;
  setRecentProject: (val: any) => void;
};

export const UserContext = createContext<UserContextProps>({
  setUserInfo: (val: UserProps) => {},
  removeUserInfo: () => {},
  setFullName: (val: string) => {},
  getFullName: () => "",
  recentProject: undefined,
  setRecentProject: () => undefined,
});

export type ChildrenProp = { children: ReactNode };

export const UserContextProvider: FC<ChildrenProp> = ({ children }) => {
  const initialUserProps: UserProps = {
    email: "",
    id: "",
    fullName: "",
    userType: "",
  };

  const { get } = useConnection();
  const [userInfo, setUserInfoState] = useState<UserProps>(initialUserProps);
  const [recentProject, setRecentProject] = useState<any>();
  const setUserInfo = (value: UserProps) => {
    setUserInfoState(value);
    const { email, id, fullName, userType } = value;
    // localStorage.setItem("user", JSON.stringify(value));
    if (fullName) {
      setUserInfoState((prev) => ({ ...prev, fullName }));
    }

    if (email) {
      setUserInfoState((prev) => ({ ...prev, email }));
    }

    if (id) {
      setUserInfoState((prev) => ({ ...prev, id }));
      localStorage.setItem("userId", id);
    }

    if (userType) {
      setUserInfoState((prev) => ({ ...prev, userType }));
      localStorage.setItem("userType", userType);
    }

    console.log("userInfo", userInfo);
  };

  const removeUserInfo = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("userType");
    setUserInfoState(initialUserProps);
  };

  const setFullName = (fullName: string) => {
    setUserInfoState((prev) => ({ ...prev, fullName }));
  };

  const getFullName = (): string => {
    return userInfo.fullName;
  };

  useEffect(() => {
    const getUserInfo = async () => {
      const userId = localStorage.getItem("userId");
      if (userId) {
        try {
          const data = await get(
            `settings/client/${userId}`,
            undefined,
            SERVICE_TYPE.serviceType
          );
          const { email, fullName } = data?.data;
          setUserInfo({ email, fullName });
        } catch (error: any) {
          message.open({
            type: "error",
            content: error.message,
            duration: 2,
            style: { textAlign: "right", marginRight: 15, marginTop: 10 },
          });
        }
      }
    };

    getUserInfo();
  }, []);
  return (
    <UserContext.Provider
      value={{
        userInfo,
        setUserInfo,
        removeUserInfo,
        setFullName,
        getFullName,
        recentProject,
        setRecentProject,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextProps => {
  return useContext(UserContext);
};
