import React, { FC } from "react";
import "./userTable.scss";
import UserTable from "./UserManagementTable";
import MainLayout from "../../components/MainLayout";

const UserManagement: FC = () => {
  return (
    <MainLayout
      type="primary"
      OpenKey={["settings"]}
      selectedKey={["userManagement"]}
    >
      <div className="usertable-container">
        <UserTable />
      </div>
    </MainLayout>
  );
};

export default UserManagement;
