import React, { FC, useEffect } from "react";
import MainLayout from "../../components/MainLayout";
import MaintenanceTable from "./MaintenanceTable";

const Maintenance: FC = () => {
  return (
    <MainLayout
      type="primary"
      OpenKey={["maintenance"]}
      selectedKey={["maintenance"]}
    >
      <MaintenanceTable />
    </MainLayout>
  );
};

export default Maintenance;

