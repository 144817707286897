import React, { FC, useContext, useEffect, useState } from "react";
import { Result, message } from "antd";
import moment from "moment";
import { useConnection } from "../../Services/ApplicationContext";
import { Container, SERVICE_TYPE } from "../../constants/constants";

let nextOneCountWaiting = false;
export type ProjectContextProps = {
  nextOne: (val: any) => void;
  generateSchedules: (val: any) => void;
  nextTwo: () => void;
  next: () => void;
  current: number;
  setCurrent: (val: number) => void;
  currentFinishedStep: number;
  setCurrentFinishedStep: (val: number) => void;
  setConfirmationModal: (val: boolean) => void;
  currentFormOneData: any;
  quantitySuggestionModal: any;
  userErrorStatusVal: any;
  setCurrentFormOneData: (val: any) => void;
  setQuantitySuggestionModal: (val: any) => void;
  setUserErrorStatusVal: (val: any) => void;
  processTemplate: any;
  templateData: any;
  setTemplateData: (val: any) => void;
  containerArray: any;
  quantitySuggestions: any;
  inputCutLengthArray: any;
  setContainerArray: (val: any) => void;
  setErrorData: (val: any) => void;
  setQuantitySuggestions: (val: any) => void;
  setInputCutLengthArray: (val: any) => void;
  addBobbinDataToMachine: (
    machineID: string,
    taskId: string,
    value: string,
    type: string,
  ) => void;
  setTimelineGroup: (val: any) => void;
  setTimelineItem: (val: any) => void;
  setSelectedTimeline: (val: any) => void;
  timelineData: any;
  timelineGroup: any;
  errorData: any;
  timelineItem: any;
  selectedTimeline: any;
  formData: any;
  setFormData: (val: any) => void;
  startTime: any;
  setStartTime: (val: any) => void;
  startTimeView: any;
  endTimeView: any;
  setStartTimeView: (val: any) => void;
  setEndTimeView: (val: any) => void;
  machineBookingTime: any;
  setMachineBookingTime: (val: any) => void;
  previousFromStepTwo: boolean;
  genScheduleLoading: boolean;
  noSchedules: boolean;
  setPreviousFromStepTwo: (val: boolean) => void;
  setGenScheduleLoading: (val: boolean) => void;
  setNoSchedules: (val: boolean) => void;
  setRescheduleProjectData: any;
  RescheduleProjectData: any;
};

export const ProjectContext = React.createContext<{
  project?: ProjectContextProps;
}>({});

export const ProjectContextWrapper: FC = (props) => {
  const { get, post } = useConnection();

  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);

  const [previousFromStepTwo, setPreviousFromStepTwo] = useState<boolean>(
    false,
  );

  const [current, setCurrent] = useState(0);
  const [currentFinishedStep, setCurrentFinishedStep] = useState(0);
  const [currentFormOneData, setCurrentFormOneData] = useState<any>(null);
  const [processTemplate, setProductionTemplate] = useState<any>([]);
  const [formData, setFormData] = useState<any>();
  const [templateData, setTemplateData] = useState<any>();
  const [containerArray, setContainerArray] = useState<any>([]);
  const [inputCutLengthArray, setInputCutLengthArray] = useState<any>([]);
  const [timelineData, setTimelineData] = useState<any>([]);
  const [errorData, setErrorData] = useState<any>([]);
  const [selectedTimeline, setSelectedTimeline] = useState<any>();
  const [timelineGroup, setTimelineGroup] = useState([]);
  const [timelineItem, setTimelineItem] = useState<any>([]);
  const [startTime, setStartTime] = useState<any>();
  const [startTimeView, setStartTimeView] = useState<any>();
  const [endTimeView, setEndTimeView] = useState<any>();
  const [machineBookingTime, setMachineBookingTime] = useState<any>([]);
  const [quantitySuggestions, setQuantitySuggestions] = useState<any>([]);
  const [quantitySuggestionModal, setQuantitySuggestionModal] = useState(false);
  const [userErrorStatusVal, setUserErrorStatusVal] = useState(false);
  const [genScheduleLoading, setGenScheduleLoading] = useState(false);
  const [noSchedules, setNoSchedules] = useState(false);
  const [RescheduleProjectData, setRescheduleProjectData] = useState<any>();

  const nextOne = (val: any) => {
    if (val.alter === undefined || val?.alter === null) {
      val.alter = false;
    }

    setFormData(val);
    if (val?.processTemplate) {
      getTemplateData(val?.processTemplate);
    }
    // setCurrent(current + 1)
    nextOneCountWaiting = true;
   // console.log("container data next one", val, containerArray);
  };

  useEffect(() => {
    if (nextOneCountWaiting) {
      setCurrent(current + 1);
      nextOneCountWaiting = false;
    }
  }, [templateData]);

  const next = () => {
    setCurrent(current + 1);
  };

  const nextTwo = async () => {
    // container validation
    let machineCount = 0;
    let totalInput = 0;
    templateData?.nodes.map((node: any) => {
      if (node.data.type === "Task") {
        // machineCount += 1;
        machineCount += node.data.props.machines.length;
      }
      if (node.data.type === "Input") {
        totalInput += 1;
      }
    });
    if (machineCount !== containerArray.length) {
      message.open({
        type: "error",
        content: `Error! Please complete ${Container.NAME} selection`,
        duration: 2,
      });
      return;
    }
    if (totalInput !== inputCutLengthArray.length) {
      message.open({
        type: "error",
        content: `Error! Please complete input Cut length selection`,
        duration: 2,
      });
      return;
    }

    const val = { ...formData };
    console.log(" ===== filling form =======", val);

    val.startTime =
      new Date(
        moment(val.startTime).year(),
        moment(val.startTime).month(),
        moment(val.startTime).date(),
        moment(val.startTime).hour(),
        moment(val.startTime).minute(),
      ).valueOf() / 1000;
    val.deadline =
      new Date(
        moment(val.deadline).year(),
        moment(val.deadline).month(),
        moment(val.deadline).date(),
        moment(val.deadline).hour(),
        moment(val.deadline).minute(),
      ).valueOf() / 1000;

    // val.deadline = moment(val.deadline).unix();
    // val.startTime = moment(val.startTime).unix();
    val.quantity = parseInt(val?.quantity);
    val.minimumOrderQuantity = parseInt(val?.minimumOrderQuantity);
    const outputNode = templateData.nodes.find(
      (val: any) => val.data.type === "Output",
    );
    const inputs = inputCutLengthArray.map((val: any) => {
      return {
        inputId: val.inputId,
        cutLength: val.cutLength,
      };
    });
    const body = containerArray.map((obj: any) => ({ ...obj }));

    body.map((machine: any) => {
      machine.machineId = String(machine.machineId);
      machine.cutLength = parseInt(machine.cutLength);
      machine.concurrentOutput = parseInt(machine.concurrentOutput);
      // if (machine.consider === "consider") {
      //   machine.consider = true;
      // } else if (machine.consider === "doNotConsider") {
      //   machine.consider = false;
      // }
    });

    console.log(
      body,
      "outputNodeoutputNodeoutputNodeoutputNode",
      containerArray,
    );

    try {
      const results = await post(
        "scheduler/processScheduling/getQuantitySuggestions",
        {
          ...val,
          quantities: {
            [outputNode.id]: val.quantity,
          },
          minimumOrderQuantity: {
            [outputNode.id]: val.minimumOrderQuantity,
          },
          inputs,
          container: body,
        },
        undefined,
        SERVICE_TYPE.serviceType
      );
      // console.log(
      //   results.response,
      //   "results.responseresults.responseresults.response",
      // );
      let userErrorStatusCount = 0;
      for (let val in results.response) {
        for (let i = 0; i < results.response[val].length; i++) {
          setQuantitySuggestions((v: any) => [...v, results.response[val][i]]);
          if (results.response[val][i].userErrorStatus === true) {
            userErrorStatusCount += 1;
          }
        }
      }
      setQuantitySuggestionModal(true);
      console.log(userErrorStatusCount, "userErrorStatususerErrorStatus");
      if (userErrorStatusCount > 0) {
        setUserErrorStatusVal(true);
      }
      // setTimelineData(results.data);
    } catch (error: any) {
      console.log(error);
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
    }
  };

  const generateSchedules = async (suggestions: any) => {
    setGenScheduleLoading(true);
    let machineCount = 0;
    let totalInput = 0;
    templateData?.nodes.map((node: any) => {
      if (node.data.type === "Task") {
        // machineCount += 1;
        machineCount += node.data.props.machines.length;
      }
      if (node.data.type === "Input") {
        totalInput += 1;
      }
    });
    if (machineCount !== containerArray.length) {
      message.open({
        type: "error",
        content: `Error! Please complete ${Container.NAME} selection`,
        duration: 2,
      });
      return;
    }
    if (totalInput !== inputCutLengthArray.length) {
      message.open({
        type: "error",
        content: `Error! Please complete input Cut length selection`,
        duration: 2,
      });
      return;
    }

    const val = { ...formData };
    console.log(" ===== filling form =======", val);

    val.startTime =
      new Date(
        moment(val.startTime).year(),
        moment(val.startTime).month(),
        moment(val.startTime).date(),
        moment(val.startTime).hour(),
        moment(val.startTime).minute(),
      ).valueOf() / 1000;
    val.deadline =
      new Date(
        moment(val.deadline).year(),
        moment(val.deadline).month(),
        moment(val.deadline).date(),
        moment(val.deadline).hour(),
        moment(val.deadline).minute(),
      ).valueOf() / 1000;

    val.quantity = parseInt(val?.quantity);
    val.minimumOrderQuantity = parseInt(val?.minimumOrderQuantity);
    const outputNode = templateData.nodes.find(
      (val: any) => val.data.type === "Output",
    );
    const inputs = inputCutLengthArray.map((val: any) => {
      return {
        inputId: val.inputId,
        cutLength: parseInt(val.cutLength),
      };
    });
    const body = containerArray.map((obj: any) => ({ ...obj }));

    body.map((machine: any) => {
      machine.machineId = String(machine.machineId);
      machine.cutLength = parseInt(machine.cutLength);
      machine.concurrentOutput = parseInt(machine.concurrentOutput);
    });

    let validQuantities: any = {};

    for (let i = 0; i < suggestions.length; i++) {
      if (validQuantities[outputNode.id] === undefined) {
        validQuantities[outputNode.id] = {
          [suggestions[i].machineId]: suggestions[i].suggestion,
        };
      } else {
        validQuantities[outputNode.id][suggestions[i].machineId] =
          suggestions[i].suggestion;
      }
    }

    try {
      let results: any;
      if (val.projectId) {
        console.log(RescheduleProjectData, 'reschedule project data')
        const { container, inputs, ...updatedRescheduleProjectData } = RescheduleProjectData;
        console.log(updatedRescheduleProjectData, 'reschedule project data', updatedRescheduleProjectData)
        results = await post(
          "scheduler/processScheduling/rescheduleProject",
          {
            ...val,
            quantities: {
              [outputNode.id]: val.quantity,
            },
            minimumOrderQuantity: {
              [outputNode.id]: val.minimumOrderQuantity,
            },
            validQuantities,
            inputs,
            container: body,
            ...updatedRescheduleProjectData,
          },
          undefined,
          SERVICE_TYPE.serviceType
        );
      } else {
        results = await post(
          "scheduler/processScheduling/generateSchedules",
          {
            ...val,
            quantities: {
              [outputNode.id]: val.quantity,
            },
            minimumOrderQuantity: {
              [outputNode.id]: val.minimumOrderQuantity,
            },
            validQuantities,
            inputs,
            container: body,
          },
          undefined,
          SERVICE_TYPE.serviceType
        );
      }

      console.log(
        results.response,
        "validQuantitiesvalidQuantitiesvalidQuantitiesvalidQuantities",
      );
      if (results.response.message === "no solutions") {
        setNoSchedules(true);
        setErrorData(results.response.data);
        setGenScheduleLoading(false);
      } else {
        setNoSchedules(false);
        setTimelineData(results.response.data);
        setGenScheduleLoading(false);
      }
    } catch (error: any) {
      setGenScheduleLoading(false);
      console.log(error);
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
    }
  };

  const getAllProductionTemplate = async () => {
    try {
      const results = await post(
        "scheduler/productionPlans/getAllTemplates",
        {
          page: 0,
          size: 1000,
        },
        undefined,
        SERVICE_TYPE.serviceType
      );
      console.log("production template issue => ", results);
      setProductionTemplate(results.data.list);
    } catch (error: any) {
      console.log(error);
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
    }
  };

  const getTemplateData = async (id: string) => {
    try {
      const results = await get(
        `scheduler/productionPlans/getProcessTemplate?id=${id}`,
        undefined,
        SERVICE_TYPE.serviceType
      );

      console.log("template data edges=> ", results.data?.edges);
      console.log("template data nodes => ", results.data?.nodes);
      const edges = results.data?.edges;
      const nodes = results.data?.nodes;

      setTemplateData({ nodes, edges });
    } catch (error: any) {
      console.log(error);
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
    }
  };

  const addBobbinDataToMachine = (
    machineID: string,
    taskId: string,
    value: string,
    type: string,
  ) => {
    const machineId = String(machineID);
    const existingMachine = containerArray.find(
      (item: any) => item.taskId === taskId && item.machineId === machineId,
    );
    if (existingMachine) {
      const index = containerArray.indexOf(existingMachine);
      switch (type) {
        case "Container":
          containerArray[index].container = value;
          break;
        case "CutLength":
          containerArray[index].cutLength = value;
          break;
        case "Consider":
          containerArray[index].consider = value;
          break;
        default:
          containerArray[index].container = value;
      }
    } else {
      switch (type) {
        case "Container":
          containerArray.push({
            machineId,
            taskId,
            container: value,
          });
          break;
        case "CutLength":
          containerArray.push({
            machineId,
            taskId,
            cutLength: value,
          });
          break;
        case "Consider":
          containerArray.push({
            machineId,
            taskId,
            consider: value,
          });
          break;
        default:
          containerArray.push({
            machineId,
            taskId,
            container: value,
          });
      }
    }
    setContainerArray(containerArray);
  };

  // useEffect(() => {
  //   console.log("container array    ====== > ", containerArray);
  // }, [containerArray]);

  useEffect(() => {
    let valOfFormData;
    let valOfFormDataSending;
    if (formData) {
      // console.log("######form data in context ############", formData);
      valOfFormData = { ...formData };
      valOfFormData.startTime =
        new Date(
          moment(valOfFormData?.startTime).year(),
          moment(valOfFormData?.startTime).month(),
          moment(valOfFormData?.startTime).date(),
          moment(valOfFormData?.startTime).hour(),
          moment(valOfFormData?.startTime).minute(),
        ).valueOf() / 1000;
      let startD = moment
        .unix(valOfFormData?.startTime)
        .format("YYYY-MM-DD h:mm a");
      valOfFormData.deadline =
        new Date(
          moment(valOfFormData?.deadline).year(),
          moment(valOfFormData?.deadline).month(),
          moment(valOfFormData?.deadline).date(),
          moment(valOfFormData?.deadline).hour(),
          moment(valOfFormData?.deadline).minute(),
        ).valueOf() / 1000;
      let endD = moment
        .unix(valOfFormData.deadline)
        .format("YYYY-MM-DD h:mm a");
      valOfFormDataSending = { ...formData, startTime: startD, deadline: endD };
      // console.log(
      //   "######form data in context ############",
      //   valOfFormDataSending,
      // );
    }
    const data: any = {
      current,
      currentFinishedStep,
      inputCutLengthArray,
      containerArray,
      valOfFormDataSending,
      templateData,
      processTemplate,
      selectedTimeline,
      timelineData,
      timelineGroup,
      timelineItem,
      startTimeView,
      endTimeView,
      machineBookingTime,
    };
    if (currentFinishedStep !== 0 && current !== 0) {
     // console.log('check_reschedule_project',RescheduleProjectData)
      if(RescheduleProjectData == undefined) {
        console.log('scheduleProjectProcessDetail-1')
        localStorage.setItem(
          "scheduleProjectProcessDetails",
          JSON.stringify(data),
        );
      }else {
        console.log('rescheduleProjectProcessDetail-1')
        localStorage.setItem(
          "rescheduleProjectProcessDetails",
          JSON.stringify(data),
        );
      }
      
    }
  }, [
    current,
    currentFinishedStep,
    inputCutLengthArray,
    containerArray,
    formData,
    templateData,
    processTemplate,
    timelineData,
    timelineGroup,
    timelineItem,
    startTimeView,
    endTimeView,
    machineBookingTime,
    selectedTimeline,
  ]);

  useEffect(() => {
    let scheduleProjectProcessDetails
    if(RescheduleProjectData == undefined) {
      scheduleProjectProcessDetails = localStorage.getItem(
        "scheduleProjectProcessDetails",
      );
     }else {
      scheduleProjectProcessDetails = localStorage.getItem(
        "rescheduleProjectProcessDetails",
      );
    }
  
    if (confirmationModal === true) {
      if (scheduleProjectProcessDetails) {
        const contextData = JSON.parse(scheduleProjectProcessDetails);
        // console.log(
        //   "getting processdata from local storage ############",
        //   contextData?.tFormData,
        // );
        if (contextData) {
          setCurrent(contextData?.current);
          setFormData(contextData?.valOfFormDataSending);
          setTemplateData(contextData?.templateData);
          setContainerArray(contextData?.containerArray);
          setInputCutLengthArray(contextData?.inputCutLengthArray);
          setProductionTemplate(contextData?.processTemplate);
          setSelectedTimeline(contextData?.selectedTimeline);
          setTimelineData(contextData?.timelineData);
          setTimelineGroup(contextData?.timelineGroup);
          setTimelineItem(contextData?.timelineItem);
          setStartTimeView(contextData?.startTimeView);
          setEndTimeView(contextData?.endTimeView);
          setMachineBookingTime(contextData?.machineBookingTime);
        }
      }
    }
  }, [confirmationModal]);

  // useEffect(() => {
  //   localStorage.setItem(
  //     "previousFromStepTwo",
  //     JSON.stringify(previousFromStepTwo),
  //   );
  // }, [previousFromStepTwo]);

  useEffect(() => {
    getAllProductionTemplate();
    // console.log(" ########### - current - ", current, " - #########");
  }, [current]);

  return (
    <ProjectContext.Provider
      value={{
        project: {
          setErrorData,
          errorData,
          setNoSchedules,
          noSchedules,
          setGenScheduleLoading,
          genScheduleLoading,
          generateSchedules,
          setUserErrorStatusVal,
          userErrorStatusVal,
          setQuantitySuggestionModal,
          setQuantitySuggestions,
          quantitySuggestionModal,
          quantitySuggestions,
          setInputCutLengthArray,
          inputCutLengthArray,
          nextOne,
          nextTwo,
          current,
          setCurrent,
          currentFinishedStep,
          setCurrentFinishedStep,
          setConfirmationModal,
          currentFormOneData,
          setCurrentFormOneData,
          processTemplate,
          templateData,
          setTemplateData,
          containerArray,
          addBobbinDataToMachine,
          next,
          setTimelineGroup,
          setTimelineItem,
          setSelectedTimeline,
          timelineData,
          timelineGroup,
          timelineItem,
          selectedTimeline,
          formData,
          setFormData,
          setContainerArray,
          startTime,
          setStartTime,
          startTimeView,
          endTimeView,
          setEndTimeView,
          setStartTimeView,
          machineBookingTime,
          setMachineBookingTime,
          previousFromStepTwo,
          setPreviousFromStepTwo,
          setRescheduleProjectData,
          RescheduleProjectData,
        },
      }}
    >
      {props.children}
    </ProjectContext.Provider>
  );
};
export default ProjectContext;

export const useProject = () => {
  const context = useContext(ProjectContext);

  return context?.project!;
};
