import React, { FC, useEffect, useState } from "react";
import { Modal, Button } from "antd";
import moment from "moment";
import "../../pages/modalPopUpConfirmation.scss";

export interface CommonConfirmationModalProps {
  visible: boolean;
  message: string;
  onClose: (val: boolean) => void;
}

const CommonConfirmationModal: FC<CommonConfirmationModalProps> = (
  props: CommonConfirmationModalProps,
) => {
  const { visible, onClose, message } = props;

  return (
    <Modal
      centered
      title="Update Process Confirmation"
      visible={visible}
      width="66%"
      className="general-modal-popup-confirm modal-popup-header"
      onCancel={() => onClose(false)}
      footer={[
        <div className="modal-field-button">
          <Button type="primary" onClick={() => onClose(true)}>
            Ok
          </Button>
          <Button type="primary" danger onClick={() => onClose(false)}>
            Cancel
          </Button>
        </div>,
      ]}
    >
      <div
        style={{
          fontFamily: "Poppins",
          justifyContent: "center",
          display: "flex",
          flex: 1,
          fontSize: "15px",
          color: "rgba(255, 255, 255, 0.6)",
        }}
      >
        {message}
        {/* {edit
          ? "You have a project in cache. Do you need to continue with that ?"
          : "You have an incomplete project. Do you need to continue with that ?"} */}
      </div>
    </Modal>
  );
};
export default CommonConfirmationModal;
