import React, { FC } from "react";
import "./Styles.scss";
import MainLayout from "../../components/MainLayout";
import CableProcessTable from "./CableProcessTable";

const CableProcess: FC = () => {
  return (
    <MainLayout
      type="primary"
      // title="Cable Process"
      OpenKey={["scheduling"]}
      selectedKey={["productionPlans"]}
    >
      <div className="inner-dark-section">
        <div className="cable-section">
          <CableProcessTable />
        </div>
      </div>
    </MainLayout>
  );
};

export default CableProcess;
