import React, { FC, useState } from "react";
import Timeline, { ReactCalendarTimelineProps } from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import moment from "moment";
import "./styles.css";

export type ITimelineGantt = ReactCalendarTimelineProps;
const TimelineGantt: FC<ITimelineGantt> = (props: ITimelineGantt) => {
  const { groups, items } = props;
  return (
    <div style={{ overflow: "auto" }}>
      <Timeline
        {...props}
        groups={groups}
        items={items}
        defaultTimeStart={moment().add(-12, "days")}
        defaultTimeEnd={moment().add(12, "days")}
        minResizeWidth={150}
        canResize="both"
      />
    </div>
  );
};
export default TimelineGantt;
