import {
  Dropdown,
  Layout,
  Menu,
  Avatar,
  Radio,
  Switch,
  message,
  Skeleton,
  Tooltip,
} from "antd";
import React, { FC, useState, useEffect } from "react";
import {
  BellFilled,
  CaretDownFilled,
  CheckCircleFilled,
  ExclamationCircleFilled,
  InfoCircleFilled,
  UserOutlined,
} from "@ant-design/icons";
import { IoNotificationsOutline, IoSettingsOutline } from "react-icons/io5";
import { Link, NavLink, useHistory } from "react-router-dom";
import { useConnection } from "../../Services/ApplicationContext";
import { useUser } from "../../Services/UserContext";
import "./NotificationItem.scss";
import moment from "moment";
import { id } from "date-fns/locale";
import { useNotification } from "../../Services/NotificationContext";
import { SERVICE_TYPE } from "../../constants/constants";

export interface NotificationItemProps {
  id: string;
  keyValue: string;
  title: string;
  content: string;
  createdTime: string;
  type: string;
  attentionLevel: string;
  userType: string;
  read: boolean;
  extraContent?: string;
}

const NotificationItem: FC<NotificationItemProps> = (
  props: NotificationItemProps,
) => {
  const history = useHistory();
  const {
    id,
    keyValue,
    title,
    content,
    createdTime,
    type,
    read,
    attentionLevel,
    userType,
    extraContent,
  } = props;
  const { get, delete: del, post, put } = useConnection();
  const {
    readToggleClickedCount,
    setReadToggleClickedCount,
    loadItem,
    setLoadItem,
    getUnreadNotificationsCount,
  } = useNotification();
  const [readMark, setReadMark] = useState<boolean>(false);

  const updateReadStatus = async (val: boolean, id: any, key: any) => {
    let result;
    setLoadItem(true);
    const updateReadStatusParams = () => {
      return {
        id: id,
        key: key,
        read: val,
      };
    };
    console.log("noti params --- > ", updateReadStatusParams());
    try {
      result = await put(
        "notification/updateNotificationReadStatus",
        updateReadStatusParams(),
        undefined,
        SERVICE_TYPE.serviceType,
      );
      if (result.status === 200) {
        console.log(result?.message);
      } else {
        console.log(result?.message);
      }
      setReadToggleClickedCount(readToggleClickedCount + 1);
    } catch (e: any) {
      message.open({
        type: "error",
        content: e.message,
        duration: 2,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
    } finally {
      setLoadItem(false);
      getUnreadNotificationsCount();
    }
  };

  useEffect(() => {
    console.log({ id, title, read });
  });
  console.log("NotificationItemProps", props);
  return (
    <div
      className="notification-item-main-component"
      style={{ background: read ? "#242424" : "#313131" }}
    >
      {/* <div className="img-container"></div> */}
      <div
        className="details-container"
        style={{
          borderLeft:
            attentionLevel === "Medium"
              ? "8px solid rgb(77, 148, 255, 0.9)"
              : attentionLevel === "high"
              ? "8px solid rgb(255, 102, 102, 0.9)"
              : attentionLevel === "Low"
              ? "8px solid rgb(133, 224, 133, 0.9)"
              : "8px solid rgb(77, 148, 255, 0.9)",
        }}
      >
        <div className="header">
            <a onClick={() => history.push('/settings/notifications')} className='title' style={{color: 'hsla(0,0%,100%,.6)'}} >{title}</a>
          <div className="actions">
            <Tooltip
              placement="left"
              title={read ? "Mark as unread" : "Mark as read"}
            >
              <Switch
                size="small"
                checked={read}
                onChange={(val: boolean) => {
                  updateReadStatus(val, id, keyValue);
                }}
              />
            </Tooltip>
          </div>
        </div>
        <div className="body">
          <span>{content}</span>
        </div>
        <div className="bottom">
          <span>{moment(parseInt(createdTime) * 1000).fromNow()}</span>
        </div>
      </div>
    </div>
  );
};
export default NotificationItem;
