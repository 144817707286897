/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { FC, useEffect, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  Select,
  Divider,
  Tag,
  message,
  Spin,
  Skeleton,
  Col,
  Row,
  Radio,
} from "antd";
import { useProject } from "./NewProjextContext";
import "../modalPopUp.scss";
import "./common.scss";
import "./quantitySuggestion.scss";
import "./containerSelection.scss";
import { useConnection } from "../../Services/ApplicationContext";

export interface QuantitySuggestionModalProps {
  visible: boolean;
  modalData: any;
  outputNode: any;
  onClose?: (val?: any) => void;
}

const QuantitySuggestionModal: FC<QuantitySuggestionModalProps> = (
  props: QuantitySuggestionModalProps,
) => {
  const {
    setCurrent,
    current,
    setQuantitySuggestionModal,
    setQuantitySuggestions,
    setCurrentFinishedStep,
    quantitySuggestions,
    setUserErrorStatusVal,
    userErrorStatusVal,
    generateSchedules,
  } = useProject();
  const { visible, onClose, modalData, outputNode } = props;
  const [formTwo] = Form.useForm();
  const { setInputCutLengthArray, inputCutLengthArray } = useProject();
  const [loading, setLoading] = useState(false);
  const { Option } = Select;
  const [modalLoading, setModalLoading] = useState<boolean>(false);

  useEffect(() => {
    console.log("modalData===================>>>>>>", modalData);
  }, [modalData]);

  const onSubmit = (val: any) => {};

  return (
    <Modal
      centered
      width="60%"
      title={userErrorStatusVal ? "Quantity Suggestion" : "Quantity Overview"}
      visible={visible}
      className="general-modal-popup quantitySuggestion"
      onCancel={() => {
        setQuantitySuggestionModal(false);
        setQuantitySuggestions([]);
      }}
      footer={[
        <div className="modal-field-button">
          <Button
            type="primary"
            danger
            onClick={() => {
              setQuantitySuggestionModal(false);
              setQuantitySuggestions([]);
            }}
          >
            Close
          </Button>
          <Button
            type="primary"
            onClick={() => {
              //   setQuantitySuggestionModal(false);
              //   setCurrent(current + 1);
              formTwo.submit();
              setQuantitySuggestionModal(false);
              setQuantitySuggestions([]);
            }}
          >
            Next
          </Button>
        </div>,
      ]}
    >
      <div
        style={{
          maxHeight: "70vh",
          overflowY: "auto",
          overflowX: "hidden",
          padding: 5,
        }}
      >
        <div>
          <Form
            layout="horizontal"
            form={formTwo}
            name="quantitySuggestions"
            onFinish={(values: any) => {
              console.log('containerArrayList',values)
              generateSchedules(values.suggestions);
              setCurrent(current + 1);
              setCurrentFinishedStep(2);
            }}
            scrollToFirstError
            autoComplete="off"
          >
            <Form.Item
              label="Output Name"
              name="outputName"
              initialValue={outputNode.data.props.output}
            >
              <Input
                disabled
                value={outputNode.data.props.output}
                defaultValue={outputNode.data.props.output}
              />
            </Form.Item>
            <Form.List name="suggestions">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {modalData.map((val: any, index: any) => {
                      return (
                        <div key={index}>
                          <Divider
                            orientation="center"
                            style={{ marginTop: "15px", marginBottom: "25px" }}
                          >
                            Machines : {val.machineName}
                          </Divider>
                          <Form.Item
                            label="Quantity Suggestion"
                            name={[index, "suggestion"]}
                            initialValue={val.roundDownQuantity}
                          >
                            <Radio.Group
                              defaultValue={val.roundDownQuantity}
                              buttonStyle="solid"
                              //   onChange={handleChange}
                            >
                              {val.userErrorStatus === true &&
                              val.roundDownQuantity !== val.roundUpQuantity ? (
                                <>
                                  <Radio.Button value={val.roundDownQuantity}>
                                    {val.roundDownQuantity}
                                  </Radio.Button>
                                  <Radio.Button
                                    value={val.roundUpQuantity}
                                    //   style={{ marginLeft: "10px" }}
                                  >
                                    {val.roundUpQuantity}
                                  </Radio.Button>
                                </>
                              ) : val.userErrorStatus === true &&
                                val.roundDownQuantity ===
                                  val.roundUpQuantity ? (
                                <Radio.Button value={val.roundDownQuantity}>
                                  {val.roundDownQuantity}
                                </Radio.Button>
                              ) : val.userErrorStatus === false &&
                                val.roundDownQuantity ===
                                  val.roundUpQuantity ? (
                                <Radio.Button value={val.roundDownQuantity}>
                                  {val.roundDownQuantity}
                                </Radio.Button>
                              ) : null}
                            </Radio.Group>
                          </Form.Item>
                          <Form.Item
                            name={[index, "machineId"]}
                            initialValue={val.machineId}
                            hidden
                          >
                            <Input
                              defaultValue={val.machineId}
                              value={val.machineId}
                            />
                          </Form.Item>
                        </div>
                      );
                    })}
                  </div>
                );
              }}
            </Form.List>
          </Form>
        </div>
      </div>
    </Modal>
  );
};
export default QuantitySuggestionModal;
