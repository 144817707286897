import React, { FC } from "react";
import { useParams } from "react-router";

import MainLayout from "../../components/MainLayout";
import ViewProject from "./ViewProject";

export const ViewProjectWrapper: FC = () => {
  const params: any = useParams();
  return (
    <MainLayout
      type="primary"
      OpenKey={["scheduling"]}
      selectedKey={["schedule"]}
    >
      <ViewProject id={params.id} />
    </MainLayout>
  );
};
