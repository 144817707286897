import React, { FC } from "react";
import ArchivedReportsTable from "./ArchivedReportsTable";
import MainLayout from "../../components/MainLayout";

const ArchivedReports: FC = () => {
  return (
    <MainLayout
      type="primary"
      // title="Archived Reports"
      OpenKey={["report"]}
      selectedKey={["archivedReports"]}
    >
      <ArchivedReportsTable />
    </MainLayout>
  );
};

export default ArchivedReports;
