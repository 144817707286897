/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
import React, { FC, useEffect, useRef, useState } from "react";
import {
  Button,
  Select,
  Form,
  Input,
  Modal,
  message,
  Row,
  Col,
  TimePicker,
  Divider,
  Checkbox,
} from "antd";

import "./Styles.scss";
import "../modalPopUpConfirmation.scss";
import Text from "antd/es/typography/Text";
import moment from "moment";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useConnection } from "../../Services/ApplicationContext";
import { ILocation, IMachineRow } from "../../definetions/MachineModel";
import { Plant } from "../../definetions/Constatnts";
import { MaterialType, ResourceDetails } from "../../definetions/ProjectModal";
import { Container, SERVICE_TYPE } from "../../constants/constants";

const { Option } = Select;

export type TAction = "add" | "edit";
export interface EditMachineModalProps {
  visible: boolean;
  onClose: (add: TAction, val?: IMachineRow) => void;
  data?: IMachineRow;
  locationStatus: TAction;
  locations: ILocation[];
  setSelectedRow: React.Dispatch<React.SetStateAction<IMachineRow | undefined>>;
  getData: () => void;
}

const UpdateMachines: FC<EditMachineModalProps> = (
  props: EditMachineModalProps,
) => {
  const {
    visible,
    onClose,
    data,
    locationStatus,
    locations,
    setSelectedRow,
    getData,
  } = props;
  const { put, post, get } = useConnection();
  const [form] = Form.useForm();
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalValues, setModalValues] = useState();
  const [containers, setContainers] = useState([]);
  const [productTypes, setProductType] = useState<string[]>();
  const [removedTypes, setRemovedTypes] = useState<string[]>([]);
  const inputRef = useRef();
  const children = [];
  for (let i = 10; i < 36; i++) {
    children.push(
      <Option value={i.toString(36) + i} key={i.toString(36) + i}>
        {i.toString(36) + i}
      </Option>,
    );
  }

  const machineFamilies: string[] = [
    "Drawer",
    "Strander",
    "Winder",
    "Twister",
    "Breaker",
    "Taper",
    "Armorer",
    "Chamber",
  ];

  const getContainers = async () => {
    try {
      const result = await post(
        "system-management/resources/getResources",
        {},
        undefined,
        SERVICE_TYPE.serviceType
      );
      setContainers(
        result.data.filter(
          (val: ResourceDetails) =>
            val.materialType === MaterialType.BOBBIN ||
            val.materialType === MaterialType.DRUM ||
            val.materialType === MaterialType.COIL,
        ),
      );
      console.log(
        "conatiner daata ----------> ",
        result.data.filter(
          (val: ResourceDetails) =>
            val.materialType === MaterialType.BOBBIN ||
            val.materialType === MaterialType.DRUM ||
            val.materialType === MaterialType.COIL,
        ),
      );
    } catch (error: any) {
      console.log(error);
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
    }
  };

  useEffect(() => {
    if (locationStatus === "edit") {
      setProductType(
        data?.productType === undefined
          ? undefined
          : JSON.parse(JSON.stringify(data.productType)),
      );
    }
    getContainers();
  }, []);

  const showModal = (values: any) => {
    return (
      <Modal
        centered
        title="Update Machine Confirmation"
        visible={visibleEdit}
        width="66%"
        className="general-modal-popup-confirm modal-popup-header"
        onCancel={() => {
          setVisibleEdit(false);
          // removedTypes.length !== 0 ? getData() : null;
        }}
        footer={[
          <div className="modal-field-button">
            <Button
              loading={loading}
              type="primary"
              onClick={async () => {
                setLoading(true);
                try {
                  const entry = { machineID: data?.machineID, ...values };
                  delete entry.key;
                  const result = await put(
                    "system-management/machine/updateMachine",
                    entry,
                    undefined,
                    SERVICE_TYPE.serviceType
                  );
                  console.log(result, "iiiiiiiiiii");
                  const { locationName }: any = locations.find(
                    (val) => val.locationID === entry.locationID,
                  );
                  if (result.statusText === "SUCCESS") {
                    const { CreatedAt, lastUpdated } = result.data[0].values;
                    onClose("edit", {
                      ...entry,
                      CreatedAt,
                      lastUpdated,
                      locationName,
                      key: data?.key,
                    } as IMachineRow);
                    message.open({
                      type: "success",
                      content: result.message,
                      duration: 2,
                      style: {
                        textAlign: "right",
                        marginRight: 15,
                        marginTop: 10,
                      },
                    });
                  } else {
                    message.open({
                      type: "warning",
                      content: result.message,
                      duration: 2,
                      style: {
                        textAlign: "right",
                        marginRight: 15,
                        marginTop: 10,
                      },
                    });
                    removedTypes.length !== 0 ? getData() : null;
                    setSelectedRow(undefined);
                  }
                  setVisibleEdit(false);
                  setLoading(false);
                } catch (e: any) {
                  message.open({
                    type: "error",
                    content: e.message,
                    duration: 3,
                    style: {
                      textAlign: "right",
                      marginRight: 15,
                      marginTop: 10,
                    },
                  });
                  removedTypes.length !== 0 ? getData() : null;
                  setSelectedRow(undefined);
                  setVisibleEdit(false);
                  setLoading(false);
                }
              }}
            >
              Ok
            </Button>
            <Button
              type="primary"
              danger
              onClick={() => {
                removedTypes.length !== 0 ? getData() : null;
                setVisibleEdit(false);
              }}
            >
              Cancel
            </Button>
          </div>,
        ]}
      >
        <p
          style={{
            fontFamily: "Poppins",
            fontSize: "15px",
            justifyContent: "center",
            color: "rgba(255, 255, 255, 0.6)",
          }}
        >
          Edited changes will affect the projects which will be scheduled from
          today onwards
        </p>
      </Modal>
    );
  };

  const onConfirm = async (values: any) => {
    console.log("on confirm machines add ---> ", values);
    const mName = values.machineName;
    const fMName = mName
      .split(" ")
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    values.machineName = fMName.trim();
    console.log(values, fMName);

    let valid = 0;
    const temp = JSON.parse(JSON.stringify(values));
    if (temp.setupConfiguration !== undefined) {
      for (let i = 0; i < temp.setupConfiguration.length; i++) {
        delete temp.setupConfiguration[i].setupTime;
        for (let j = i + 1; j < temp.setupConfiguration.length; j++) {
          delete temp.setupConfiguration[j].setupTime;
          if (
            JSON.stringify(temp.setupConfiguration[i]) ===
            JSON.stringify(temp.setupConfiguration[j])
          ) {
            valid += 1;
          }
        }
      }
    }
    if (valid === 0) {
      if (locationStatus === "edit") {
        setModalValues(values);
        setVisibleEdit(true);
      } else {
        setLoading(true);
        try {
          const entry = values;
          const result = await post(
            "system-management/machine/addMachine",
            entry,
            undefined,
            SERVICE_TYPE.serviceType
          );
          if (result.status === 200) {
            message.open({
              type: "success",
              content: result.message,
              duration: 2,
              style: {
                textAlign: "right",
                marginRight: 15,
                marginTop: 10,
              },
            });
            const { locationName }: any = locations.find(
              (val) => val.locationID === entry.locationID,
            );
            onClose("add", { ...result.data, locationName });
          } else {
            message.open({
              type: "warning",
              content: result.message,
              duration: 2,
              style: {
                textAlign: "right",
                marginRight: 15,
                marginTop: 10,
              },
            });
          }
          setVisibleEdit(false);
          setLoading(false);
        } catch (e: any) {
          message.open({
            type: "error",
            content: e.message,
            duration: 3,
            style: {
              textAlign: "right",
              marginRight: 15,
              marginTop: 10,
            },
          });
          setVisibleEdit(false);
          setLoading(false);
        }
      }
    } else {
      message.open({
        type: "error",
        content: "Please remove duplicate set-up time configuration!",
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
      setLoading(false);
    }
    showModal(values);
  };

  const getttingKeyfromId = (id: any) => {
    let result: string;
    const arr = id.split("_");
    result = arr[0];
    return result;
  };

  const handleChangeProductType = (value: any) => {
    setProductType(value);
    if (
      form.getFieldsValue(["setupConfiguration"]).setupConfiguration !==
      undefined
    ) {
      form.validateFields();
    }
    for (let i = 0; i < value.length; i++) {
      if (removedTypes.includes(value[i])) {
        const index = removedTypes?.findIndex((f) => f === value[i]);
        if (index !== undefined && index > -1) {
          removedTypes?.splice(index, 1);
        }
      }
    }
  };

  const handleDeselect = (val: any) => {
    console.log(val, "---------", productTypes);
    if (
      form.getFieldsValue(["setupConfiguration"]).setupConfiguration !==
      undefined
    ) {
      form.validateFields();
    }
    setRemovedTypes((v) => [...v, val]);
    const index = productTypes?.findIndex((f) => f === val);
    if (index !== undefined && index > -1) {
      productTypes?.splice(index, 1);
    }
    console.log("---------", productTypes, "---------");
  };

  return (
    <>
      <Modal
        title={locationStatus === "edit" ? "Edit Machine" : "Add Machine"}
        centered
        visible={visible}
        onCancel={() => {
          onClose("add");
          // removedTypes.length !== 0 ? getData() : null;
        }}
        width="80%"
        style={{ marginTop: "50px" }}
        footer={null}
        className="general-modal-popup"
        closable
      >
        <Form
          form={form}
          name="machines"
          hideRequiredMark={false}
          onFinish={onConfirm}
          scrollToFirstError
          initialValues={data}
          autoComplete="off"
          style={{ justifyContent: "center" }}
          className="ant-advanced-search-form"
          layout="vertical"
        >
          <Form.Item
            name="machineName"
            label="Machine Name"
            className="display"
            rules={[
              {
                required: true,
                message: "Required field!",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (getFieldValue("machineName")?.match(/^[ ]{1,}$/gm)) {
                    return Promise.reject("Required field!");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="Machine Name" />
          </Form.Item>
          <Form.Item
            name="machineFamily"
            label="Machine Family"
            className="display"
            rules={[
              {
                required: true,
                message: "Required field!",
              },
              // ({ getFieldValue }) => ({
              //   validator(rule, value) {
              //     if (getFieldValue("machineFamily")?.match(/^[ ]{1,}$/gm)) {
              //       return Promise.reject("Required field!");
              //     }
              //     return Promise.resolve();
              //   },
              // }),
            ]}
          >
            {/* <Input placeholder="Machine Family" /> */}
            <Select
              placeholder="Select the Machine Family"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
            >
              {machineFamilies.map((item: string, index: any) => (
                <Option key={index.toString()} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="locationID"
            label={Plant}
            className="display"
            rules={[
              {
                required: true,
                message: `Required field!`,
              },
            ]}
          >
            <Select
              placeholder={`Select the ${Plant}`}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
            >
              {locations.map((item, index) => (
                <Option key={index.toString()} value={item.locationID}>
                  {item.locationName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="powerConsumption"
            label="Power Consumption"
            style={{ width: "101%" }}
            className="display"
            rules={[
              {
                required: true,
                message: "Required field!",
              },
              {
                whitespace: true,
                message: "Required field!",
              },
              {
                pattern: new RegExp(/^[+]?([.]\d+|\d+[.]?\d*)$/g),
                message: "Should be a positive number",
              },
            ]}
          >
            <Input
              addonAfter="kW"
              placeholder="Power Consumption"
              type="number"
              onWheel={(e) => e.currentTarget.blur()}
            />
          </Form.Item>
          <Form.Item
            name="productType"
            label="Supporting Product Types"
            className="display-action"
            rules={[
              {
                required: true,
                message: "Required field!",
              },
            ]}
          >
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Supporting Product Types"
              maxTagTextLength={10}
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              onChange={handleChangeProductType}
              onDeselect={handleDeselect}
            />
          </Form.Item>
          <div style={{ marginBottom: "10px" }}>
            <Text style={{ fontWeight: 500, fontSize: "17px" }}>
              Supporting {Container.NAME}
            </Text>
          </div>
          <Row className="form-row">
            <Col
              span={11}
              className="form-field-archive-multiple form-field-plant-multiple form-field-archive"
            >
              <Form.Item
                label={`Input ${Container.NAME}`}
                name="inputContainer"
                rules={[
                  {
                    required: true,
                    message: `Required field!`,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (locationStatus === "edit") {
                        if (data?.inputContainer !== undefined) {
                          for (let i = 0; i < data.inputContainer.length; i++) {
                            const found = value.includes(
                              data.inputContainer[i].toString(),
                            );
                            if (!found) {
                              return Promise.reject(
                                new Error(
                                  `Can't remove the existing ${Container.NAME}`,
                                ),
                              );
                            }
                          }
                          return Promise.resolve();
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Select
                  mode="multiple"
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  maxTagCount={2}
                  maxTagTextLength={10}
                  allowClear
                  showArrow
                  style={{ width: "100%" }}
                  tokenSeparators={[","]}
                  placeholder={`Input ${Container.NAME}`}
                >
                  <Option value="N/A">N/A</Option>
                  {containers.map((item: ResourceDetails, index) => (
                    <Option
                      key={index.toString()}
                      value={getttingKeyfromId(item?.id)}
                    >
                      {item.resourceName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              span={11}
              className="form-field-archive-multiple form-field-plant-multiple form-field-archive"
            >
              <Form.Item
                label={`Output ${Container.NAME}`}
                name="outputContainer"
                rules={[
                  {
                    required: true,
                    message: `Required field!`,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (locationStatus === "edit") {
                        if (data?.outputContainer !== undefined) {
                          for (
                            let i = 0;
                            i < data.outputContainer.length;
                            i++
                          ) {
                            const found = value.includes(
                              data.outputContainer[i].toString(),
                            );
                            if (!found) {
                              return Promise.reject(
                                new Error(
                                  `Can't remove the existing ${Container.NAME}`,
                                ),
                              );
                            }
                          }
                          return Promise.resolve();
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Select
                  mode="multiple"
                  maxTagCount={2}
                  maxTagTextLength={10}
                  showArrow
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                  style={{ width: "100%" }}
                  tokenSeparators={[","]}
                  placeholder={`Output ${Container.NAME}`}
                >
                  <Option value="N/A">N/A</Option>
                  {containers.map((item: ResourceDetails, index) => (
                    <Option
                      key={index.toString()}
                      value={getttingKeyfromId(item?.id)}
                    >
                      {item.resourceName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <div style={{ marginBottom: "5px" }}>
            <Text style={{ fontWeight: 500, fontSize: "17px" }}>
              Setup Configuration
            </Text>
          </div>
          <Form.Item
            label="Default Setup Time"
            name="defaultSetupTime"
            rules={[
              {
                required: true,
                message: "Required Field!",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (
                    value !== undefined &&
                    !value
                      .trim()
                      .match(
                        /^([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/gm,
                      )
                  ) {
                    return Promise.reject(
                      "Setup Time should be 'HH:MM:SS' format",
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="HH:MM:SS" />
          </Form.Item>
          <Form.List name="setupConfiguration">
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map((field, index) => (
                    <Row style={{ justifyContent: "space-between" }}>
                      <Divider>
                        <div
                          style={{
                            display: "flex",
                            flex: "1",
                            justifyContent: "space-around",
                          }}
                        >
                          Setup Configuration {index + 1}
                          <Button
                            danger
                            type="primary"
                            className="dynamic-delete-button"
                            onClick={() => {
                              remove(field.name);
                              form.validateFields();
                            }}
                            icon={<MinusCircleOutlined />}
                          />
                        </div>
                      </Divider>
                      <Col span={7}>
                        <Form.Item
                          label="Previous Product"
                          name={[index, "previousProduct"]}
                          rules={[
                            {
                              required: true,
                              message: "Required field!",
                            },
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                // if (
                                //   (!productTypes?.includes(value) ||
                                //     removedTypes.includes(value)) &&
                                //   value !== undefined
                                // )
                                if (
                                  getFieldValue("productType") !== undefined &&
                                  !getFieldValue("productType").includes(
                                    value,
                                  ) &&
                                  value !== undefined &&
                                  getFieldValue("productType") !== undefined
                                ) {
                                  return Promise.reject(
                                    "Product Type not exists",
                                  );
                                }
                                return Promise.resolve();
                                // if (
                                //   getFieldValue([
                                //     index,
                                //     "previousProduct",
                                //   ])?.match(/^[ ]{1,}$/gm)
                                // ) {
                                //   return Promise.reject("Required field!");
                                // }
                                // return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Select
                            placeholder="Select Previous Product"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentElement
                            }
                          >
                            {productTypes?.map((item, i) => (
                              <Option key={i.toString()} value={item}>
                                {item}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col offset={1} span={7}>
                        <Form.Item
                          label="Current Product"
                          name={[index, "currentProduct"]}
                          rules={[
                            {
                              required: true,
                              message: "Required field!",
                            },
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                // if (
                                //   (!productTypes?.includes(value) ||
                                //     removedTypes.includes(value)) &&
                                //   value !== undefined
                                // )
                                if (
                                  getFieldValue("productType") !== undefined &&
                                  !getFieldValue("productType").includes(
                                    value,
                                  ) &&
                                  value !== undefined
                                ) {
                                  return Promise.reject(
                                    "Product Type not exists",
                                  );
                                }
                                return Promise.resolve();
                                // if (
                                //   getFieldValue([
                                //     index,
                                //     "previousProduct",
                                //   ])?.match(/^[ ]{1,}$/gm)
                                // ) {
                                //   return Promise.reject("Required field!");
                                // }
                                // return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Select
                            placeholder="Select Current Product"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentElement
                            }
                          >
                            {productTypes?.map((item, i) => (
                              <Option key={i.toString()} value={item}>
                                {item}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col offset={1} span={7}>
                        <Form.Item
                          label="Setup Time"
                          name={[index, "setupTime"]}
                          rules={[
                            {
                              required: true,
                              message: "Required Field!",
                            },
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                if (
                                  value !== undefined &&
                                  !value
                                    .trim()
                                    .match(
                                      /^([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/gm,
                                    )
                                ) {
                                  return Promise.reject(
                                    "Setup Time should be 'HH:MM:SS' format",
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input placeholder="HH:MM:SS" />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                  <Form.Item style={{ marginTop: "30px" }}>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Setup Configuration
                    </Button>
                  </Form.Item>
                </>
              );
            }}
          </Form.List>
          {/* <Form.Item name="displayOnDashboard" valuePropName="checked">
          <Checkbox>Display On Dashboard</Checkbox>
        </Form.Item> */}
          <Form.Item className="modal-field-button">
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              style={{ marginRight: "15px" }}
            >
              {locationStatus === "edit" ? "Edit Machine" : "Add Machine"}
            </Button>
            <Button
              loading={loading}
              danger
              type="primary"
              onClick={() => {
                onClose("add");
                removedTypes.length !== 0 ? getData() : null;
              }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {visibleEdit && locationStatus === "edit" ? showModal(modalValues) : null}
    </>
  );
};

export default UpdateMachines;
