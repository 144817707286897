import React, {
  FC,
  useContext,
  useState,
  createContext,
  useEffect,
} from "react";
import { message } from "antd";
import { TimelineGroupBase, TimelineItemBase } from "react-calendar-timeline";
import moment from "moment";
import { IProjectDetails } from "../../definetions/ProjectModal";
import { ChildrenProp } from "../../definetions/common";
import { useConnection } from "../../Services/ApplicationContext";
import { SERVICE_TYPE } from "../../constants/constants";

export interface IScheduleContext {
  projectDetails?: IProjectDetails;
  timelines: TimelineItemBase<any>[];
  groups: TimelineGroupBase[];
  setTimelines: (val: TimelineItemBase<any>[]) => void;
  setGroups: (val: TimelineGroupBase[]) => void;
  setHolidays: (val: string[]) => void;
  holidays: string[];
  fetchHolidays: () => void;
  openGroups: { [key: string]: boolean };
  setOpenGroup: (val: { [key: string]: boolean }) => void;
}
export type ScheduleContextProps = {
  current?: IScheduleContext;
};
export const ScheduledContext = createContext<ScheduleContextProps>({});

interface ScheduledContextProviderProps extends ChildrenProp {
  id: string;
}
export const ScheduledContextProvider: FC<ScheduledContextProviderProps> = ({
  children,
  id,
}: ScheduledContextProviderProps) => {
  const { post, get } = useConnection();
  const [projectDetails, setProjectDetails] = useState<IProjectDetails>();
  const [timelines, setTimelines] = useState<TimelineItemBase<any>[]>([]);
  const [groups, setGroups] = useState<TimelineGroupBase[]>([]);
  const [openGroups, setOpenGroup] = useState<{ [key: string]: boolean }>({});
  const [holidays, setHolidays] = useState<string[]>([]);

  const fetchHolidays = async () => {
    try {
      const result = await get(
        "scheduler/calendar/allHolidays",
        undefined,
        SERVICE_TYPE.serviceType
      );
      if (result.status === 200) {
        const tempHolidays = result.data.map((item: any) => item.holidays);
        setHolidays(tempHolidays.toString().split(","));
      }
    } catch (e: any) {
      message.open({
        type: "error",
        content: e.message,
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
    }
  };
  useEffect(() => {
    fetchHolidays();
    setTimelines(items);
    setGroups(groupsZ);
  }, []);

  const fetchProjectDetails = async () => {
    try {
      const result = await get(`production/getProject/${id}`);
      if (result.status === 200) {
        setProjectDetails(result.data);
        console.log("udf ", result.data);
      }
    } catch (e: any) {
      message.open({
        type: "error",
        content: e.message,
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
    }
  };
  // useEffect(() => {
  //   fetchProjectDetails();
  // }, [id]);

  return (
    <ScheduledContext.Provider
      value={{
        current: {
          projectDetails,
          timelines,
          groups,
          setGroups,
          setTimelines,
          holidays,
          setHolidays,
          openGroups,
          fetchHolidays,
          setOpenGroup,
        },
      }}
    >
      {children}
    </ScheduledContext.Provider>
  );
};

const useProjectSchedule = () => {
  const context = useContext(ScheduledContext);
  return context.current!;
};
export default useProjectSchedule;
const groupsZ: TimelineGroupBase[] = [
  { id: "1#main", title: "Drawing", stackItems: false },
  { id: "1-1#sub", title: "Rod breaker A-1", stackItems: false },
  { id: "1-2#sub", title: "Rod breaker cu" },
  { id: "2#main", title: "Stranding" },
  { id: "2-1#sub", title: "100mm Strander" },
  { id: "2-2#sub", title: "Sheathing" },
  { id: "2-3#sub", title: "Cutting" },
];
const changeAlphaInRGBA = (val: string, alpha?: number) => {
  return val.replace(/[\d\.]+\)$/g, `${alpha ?? 1})`);
};

const rgba = changeAlphaInRGBA("rgba(255, 255, 255, 0.49)");
console.log("veis ", rgba);
const hexToRgbA = (hex: string, opacity?: number) => {
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join("")}`;
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",")},${
      opacity ?? 1
    })`;
  }
  throw new Error("Bad Hex");
};
const items: TimelineItemBase<any>[] = [
  {
    id: "project_1#1-1",
    group: "1-1#sub",
    title: "P0286",
    start_time: moment("2021-04-21"),
    end_time: moment("2021-05-03"),
    itemProps: {
      "aria-hidden": true,
      onDoubleClick: () => {
        console.log("You clicked double!");
      },
      className: "timeline-item",
      style: {
        background: hexToRgbA("#13637C", 0.5),
        border: "none",
      },
    },
  },
  {
    id: "project_2#2-1",
    group: "1-2#sub",
    title: "P 123",
    start_time: moment("2021-04-10"),
    end_time: moment("2021-04-23"),
    itemProps: {
      "aria-hidden": true,
      onDoubleClick: () => {
        console.log("You clicked double!");
      },
      className: "timeline-item",
      style: {
        background: hexToRgbA("#C6339D", 0.5),
        border: "none",
      },
    },
  },
  {
    id: "project_3#2-2",
    group: "1-2#sub",
    title: "P 891",
    start_time: moment("2021-04-06"),
    end_time: moment("2021-04-09"),
    itemProps: {
      className: "timeline-item",
      "aria-hidden": true,
      style: {
        background: hexToRgbA("#4bcd62", 0.5),
        border: "none",
      },
    },
  },
  {
    id: "project_1#2-1",
    group: "2-1#sub",
    title: "P83c",
    start_time: moment("2021-04-01"),
    end_time: moment("2021-04-03"),
    itemProps: {
      "aria-hidden": true,
      onDoubleClick: () => {
        console.log("You clicked double!");
      },
      className: "timeline-item",
      style: {
        background: hexToRgbA("#F0817F", 0.5),
        border: "none",
      },
    },
  },
];
