import {
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
  Skeleton,
  Tooltip,
  Popover,
  Space,
  Collapse,
  Button,
  Spin,
} from "antd";
import {
  InfoCircleOutlined,
  ExpandOutlined,
  MinusOutlined,
  PlusOutlined,
  MenuUnfoldOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import React, { FC, useEffect, useRef, useState } from "react";
import Timeline from "react-calendar-timeline";
import moment from "moment";
import { Canvas, CanvasRef, Label, Node, NodeData } from "reaflow";
import { useHistory } from "react-router-dom";
import { useConnection } from "../../Services/ApplicationContext";
import "./stepFour.scss";
// import "./stepOne.scss";
import "./viewProject.scss";
import TemplateNodeDataModal from "./TemplateNodeDataModal";
import PopupIcon from "../../assets/popupIcon.png";
import ContainerViewModal from "./ContainerViewModal";
import NodeDataModal from "../CableProduction/NodeDataModal";
import ResourceTable from "./ResourceTable";
import TimelineGantt from "../../components/GanttChart/TimelineGantt";
import { DateTime } from "luxon";

import CommonConfirmationModal from "../../components/ConfirmModal/CommonConfirmationModal";
import { useProject } from "./NewProjextContext";
import StepTwo from "./StepTwo";
import { useUser } from "../../Services/UserContext";
import { Container, SERVICE_TYPE } from "../../constants/constants";

const EditProject: FC<{ type?: string; id?: string }> = (props: {
  type?: string;
  id?: string;
}) => {
  const { type } = props;
  const componentRef = React.createRef<any>();
  const { get, post, patch } = useConnection();
  const {
    current,
    setCurrent,
    timelineGroup,
    setTimelineGroup,
    timelineItem,
    setTimelineItem,
    selectedTimeline,
    formData,
    setSelectedTimeline,
    containerArray,
    startTimeView,
    setStartTimeView,
    endTimeView,
    setEndTimeView,
    machineBookingTime,
    setMachineBookingTime,
    inputCutLengthArray,
    RescheduleProjectData,
  } = useProject();
  const { setRecentProject } = useUser();
  const [formOne] = Form.useForm();
  const history = useHistory();
  const { Option } = Select;
  const { Panel } = Collapse;
  const [projectData, setProjectData] = useState<any>({});
  const [taskEndTime, setTaskEndTime] = useState<any>();
  const [processTemplate, setProductionTemplate] = useState([]);
  const [nodeModalVisible, setNodeModalVisible] = useState<boolean>(false);
  const [containerModalVisible, setContainerModalVisible] = useState<boolean>(
    false,
  );

  // from context too
  const [timelineGroupState, setTimelineGroupState] = useState([]);
  const [timelineItemState, setTimelineItemState] = useState<any>([]);
  const [containerArrayState, setContainerArrayState] = useState<any>([]);
  const [machineBookingTimeState, setMachineBookingTimeState] = useState<any>(
    [],
  );
  // from context too end

  const [selectedNode, setSelectedNode] = useState<any>({});
  const [skeletonLoading, setSkeletonLoading] = useState(true);
  const [startTime, setStartTime] = useState<any>();
  const [endTime, setEndTime] = useState<any>();
  const [tableData, setTableData] = useState<any>([]);
  const [tableDataResource, setTableDataResource] = useState<any>([]);
  const [resourceTableData, setResourceTableData] = useState<any>([]);
  const [holidays, setHolidays] = useState<any>([]);
  const [projectStatus, setProjectStatus] = useState<any>(["Pending"]);
  const [formDisabled, setFormDisabled] = useState<boolean>(true);
  const [timelineMove, setTimelineMove] = useState<boolean>(true);
  const [timelineData, setTimelineData] = useState<any>([]);
  const [machineIds, setMachineIds] = useState<any>(undefined);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);

  const [newGroup, setNewGroup] = useState<any>([]);

  const [loadingStepFour, setLoadingStepFour] = useState(false);

  const [
    updateDetailsWarningModal,
    setUpdateDetailsWarningModal,
  ] = useState<boolean>(false);

  const [dummyState, setDummyState] = useState<string>("");

  const [tasksList, setTasksList] = useState<any>();
  const [taskStatus, setTaskStatus] = useState<any>([]);

  const canvasRef = useRef<CanvasRef | any>(null);

  const priorityArray = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
  ];

  const getProjectData = async () => {
    try {
      const results = await get(
        `scheduler/processScheduling/getProjectTemplate?id=${props?.id}`,
        undefined,
        SERVICE_TYPE.serviceType
      );
      setProjectData(results.data);

      console.log("-----------EDITING PROJECT--------------");
      console.log(results?.data);
      getProcessTemplateData(results?.data?.processTemplate);
      formOne.setFieldsValue({
        ...results?.data,
        minimumOrderQuantity: Object.values(
          results?.data.minimumOrderQuantity,
        )[0],
        quantities: Object.values(results?.data.quantities)[0],
        deadline: moment.unix(results?.data?.deadline),
        startTime: results?.data?.userStartTime
          ? moment.unix(results?.data?.userStartTime)
          : moment.unix(results?.data?.startTime),
      });
      // setProjectStatus(results?.data?.projectStatus);
      if (results?.data?.projectStatus === "Scheduled") {
      }
      // setFormDisabled(true);
      // setTimelineMove(false);
      arrangeTimeline(results?.data);
      setTimelineData(results?.data);
      if (results?.data?.resourceDetails) {
        setTableData(Object.values(results?.data?.resourceDetails));
      } else {
        setTableData([]);
      }
    } catch (error: any) {
      console.log(error);
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
    }
  };

  const getProjectStatusData = async () => {
    try {
      // const results = await get(`production/ViewProjectStatus/${props?.id}`);
      // console.log(" ----- r ----------", results);
      // setTaskStatus(results.data);
      // const r = getActualStartTimeOrEndTime("2", "task", "startTime")
    } catch (error: any) {
      console.log(error);
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
    }
  };

  const getActualTaskStatus = (id: any) => {
    let result: any = "";
    taskStatus?.map((task: any, index: any) => {
      let val = task?.taskId?.split("::");
      if (val?.length) {
        if (val[1] === id) {
          console.log(
            " ****** receiving id and checking task id ******** - ",
            id,
            val[1],
          );
          result = task?.taskStatus;
        }
      }
    });
    return result;
  };

  const arrangeTimeline = async (values: any) => {
    let data: any = values?.nodes;
    let completeData = values?.nodesCompleted;
    try {
      console.log("editProject-chart", values);
      let taskGroup: any = [];
      const machineArray: any = [];
      let machineIds: any = [];
      const taskArray: any = [];
      const taskFinalArray: any = [];
      const startTimeArray: any = [];
      const endTimeArray: any = [];
      const dataArray = Object.values(data);
      const completeDataArray = completeData ? Object.values(completeData) : [];
      // dataArray.sort((a: any, b: any) => parseInt(a.id.id) - parseInt(b.id.id));
      dataArray.sort((a: any, b: any) =>
        a.endTime !== b.endTime
          ? parseInt(a.endTime) - parseInt(b.endTime)
          : parseInt(a.startTime) - parseInt(b.startTime),
      );

      dataArray.map((item: any, index) => {
        taskGroup.push(item.machineOption.taskGroup);
      });
      completeDataArray.map((items: any, index) => {
        items.map((item: any) => {
          taskGroup.push(item.machineOption.taskGroup);
        });
      });
      taskGroup = [...new Set(taskGroup)];

      // for machine availability
      machineIds = dataArray.map((item: any) => item.machineOption.machineID);
      setMachineIds(machineIds);

      let uniqueMachineIds = machineIds.filter((id: any, index: any) => {
        return machineIds.indexOf(id) === index;
      });

      const machineBookedData = await getMachineAvailability(uniqueMachineIds);
      console.log(" ------ task group ---------", taskGroup);

      console.log(" --------- DATA ARRAY -----------", dataArray);
      let indexNumber = 0;
      dataArray.map((item: any, index) => {
        let value = {
          id: index + 10,
          title: item.machineOption.machineName,
          machineId: item.machineOption.machineID,
          rightTitle: item.machineOption.machineName,
          root: false,
          parent: taskGroup.indexOf(item.machineOption.taskGroup) + 1,
        };
        let result: boolean = machineArray?.some(
          (item: {
            id: any;
            title: any;
            machineId: any;
            rightTitle: any;
            root: boolean;
            parent: any;
          }) =>
            item.machineId === value.machineId &&
            item.root === value.root &&
            item.parent === value.parent &&
            item.rightTitle === value.rightTitle &&
            item.title === value.title,
        );
        if (!result) {
          machineArray.push({
            // id: index + 10,
            id:
              item.machineOption.machineName +
              String(taskGroup.indexOf(item.machineOption.taskGroup) + 1),
            title: item.machineOption.machineName,
            machineId: item.machineOption.machineID,
            rightTitle: item.machineOption.machineName,
            root: false,
            parent: taskGroup.indexOf(item.machineOption.taskGroup) + 1,
          });
        }
        taskArray.push({
          id: indexNumber + 1,
          group:
            item.machineOption.machineName +
            String(taskGroup.indexOf(item.machineOption.taskGroup) + 1),
          // group: !result
          //   ? index + 10
          //   : machineArray[machineArray.length - 1]?.id,
          title: item.taskName,
          start_time: moment.unix(item?.startTime),
          end_time: moment.unix(item?.endTime),
          taskId: item.id.id,
          bgColor: "rgba(113, 242, 218, 0.6)",
          selectedBgColor: "rgba(113, 242, 218, 1)",
          color: "#017c66",
          tip: {
            ...item.machineOption,
            duration: item?.duration,
            startTime: moment.unix(item?.startTime),
            endTime: moment.unix(item?.endTime),
            nextNodes: item?.next,
            previousNodes: item?.previous,
          },
        });
        indexNumber++;
        startTimeArray.push(item.startTime);
        endTimeArray.push(item.endTime);
      });

      completeDataArray?.map((itemData: any) => {
        itemData.map((item: any) => {
          console.log("latestPausedTime-5", item);
          let value = {
            id: indexNumber + 10,

            title: item.machineOption.machineName,
            machineId: item.machineOption.machineID,
            rightTitle: item.machineOption.machineName,
            root: false,
            parent: taskGroup.indexOf(item.machineOption.taskGroup) + 1,
          };
          let result: boolean = machineArray?.some(
            (item: {
              id: any;
              title: any;
              machineId: any;
              rightTitle: any;
              root: boolean;
              parent: any;
            }) =>
              item.machineId === value.machineId &&
              item.root === value.root &&
              item.parent === value.parent,
          );
          if (!result) {
            machineArray.push({
              // id: index + 10,
              id:
                item.machineOption.machineName +
                String(taskGroup.indexOf(item.machineOption.taskGroup) + 1),
              title: item.machineOption.machineName,
              machineId: item.machineOption.machineID,
              rightTitle: item.machineOption.machineName,
              root: false,
              parent: taskGroup.indexOf(item.machineOption.taskGroup) + 1,
            });
          }

          // machineArray.push({
          //   id: index + 10,
          //   title: item.machineOption.machineName,
          //   rightTitle: item.machineOption.machineName,
          //   bgColor: "#f984ec",
          //   root: false,
          //   parent: taskGroup.indexOf(item.machineOption.taskGroup) + 1,
          // });
          taskArray.push({
            id: indexNumber + 1,
            group:
              item.machineOption.machineName +
              String(taskGroup.indexOf(item.machineOption.taskGroup) + 1),
            // group: !result
            //   ? index + 10
            //   : machineArray[machineArray.length - 1]?.id,
            title: item.taskName,
            start_time: moment.unix(item.startTime),
            end_time: moment.unix(item.endTime),
            taskId: item.id.id,
            status: "Completed",
            bgColor: "rgba(154,153,153,0.6)",
            selectedBgColor: "rgb(101,100,100)",
            color: "#9abab4",
            tip: {
              ...item.machineOption,
              duration: item?.duration,
              start_time: moment.unix(item.startTime),
              end_time: moment.unix(item.endTime),
              startTime: item?.startTime,
              endTime: item?.endTime,
              taskId: item.id.id,
              status: "Completed",
            },
          });
          indexNumber++;
          // startTimeArray.push(item.startTime);
          // endTimeArray.push(item.endTime);
        });
      });
      console.log(
        "projectData?.project?.value?.projectStatus -------- ",
        machineArray,
        taskArray,
      );
      taskArray.map((task: any, index: any) => {
        if (
          values?.projectStatus !== "Expired" &&
          values?.projectStatus !== "Delayed"
        ) {
          if (taskStatus?.length > 0) {
            let taskActualStatus: string = getActualTaskStatus(task?.taskId);
            if (taskActualStatus === "done") {
              taskFinalArray.push({
                ...task,
                canResize: false,
                bgColor: "rgba(13, 77, 224, 0.9)",
                selectedBgColor: "red",
                canMove: false,
              });
            } else {
              taskFinalArray.push(task);
            }
          } else {
            console.log(" --- else of taskStatus > 0 condition");
            if (task?.end_time < Date.now()) {
              taskFinalArray.push({
                ...task,
                canResize: false,
                bgColor: "rgba(13, 77, 224, 0.9)",
                selectedBgColor: "red",
                canMove: false,
              });
            } else {
              taskFinalArray.push(task);
            }
          }
        } else {
          taskFinalArray.push(task);
        }
      });

      const finalArray: any = [];

      taskGroup.map((task: any, index: number) => {
        console.log("task group chdskshf --------- > ", task);
        machineArray.push({
          id: index + 1,
          title: task,
          rightTitle: task,
          bgColor: "#f984ec",
          root: true,
          parent: 0,
        });
        finalArray.push(
          {
            id: index + 100,
            title: task,
            rightTitle: task,
            root: true,
            parent: 0,
          },
          ...machineArray.filter(
            (machine: any) => machine.parent === index + 1,
          ),
        );
      });

      const machineBookingItems: any = [];
      let ids: any = [];
      machineBookedData?.map(
        (machineBooking: any, indexmachineBookedData: any) => {
          machineBooking?.availability?.map((booking: any, index: any) => {
            if (
              !(
                dataArray.find(
                  (item: any) => item.startTime === booking.bookingStart,
                ) &&
                dataArray.find(
                  (item: any) => item.endTime === booking.bookingEnd,
                )
              )
            ) {
              console.log(
                "machine booked data 1",
                booking,
                finalArray,
                finalArray.find(
                  (item: any) => item.machineId === machineBooking.machineId,
                ),
              );
              // if (booking?.taskStatus !== true) {
              machineBookingItems.push({
                id:
                  String(indexmachineBookedData) +
                  String(index) +
                  String(Math.ceil(Math.random() * 100000000000)),
                group: finalArray.find(
                  (item: any) => item.machineId === machineBooking.machineId,
                ).id,
                title: booking.projectName,
                start_time: moment.unix(booking.bookingStart),
                end_time: moment.unix(booking.bookingEnd),
                bgColor: "rgba(154,153,153,0.6)",
                selectedBgColor: "rgb(101,100,100)",
                color: "#9abab4",
                canMove: false,
                canResize: false,
                canChangeGroup: false,
                canSelect: false,
                tip: {
                  tastStart: booking?.bookingStart * 1000,
                  tastEnd: booking?.bookingEnd * 1000,
                  projectName: booking?.projectName,
                  duration: booking?.bookingEnd - booking?.bookingStart,
                  machineName: "disable",
                },
              });
              // }
            }
          });
        },
      );

      const hasDuplicates = (arr: any[]) => {
        return new Set(arr).size !== arr.length;
      };
      console.log("ids duplicate key details === ", hasDuplicates(ids));

      console.log(" --------- tasks --------------", taskFinalArray);
      // console.log(" --------- timeline group --------------", finalArray);
      console.log(" --------- task group --------------", machineBookingItems);

      setNewGroup([...newGroups]);

      type === "stepFour"
        ? setMachineBookingTime(machineBookingItems)
        : setMachineBookingTimeState(machineBookingItems);
      type === "stepFour"
        ? setTimelineGroup(finalArray)
        : setTimelineGroupState(finalArray);
      type === "stepFour"
        ? setTimelineItem([...taskArray, ...machineBookingItems])
        : setTimelineItemState([...taskFinalArray, ...machineBookingItems]);
      setStartTime(Math.min(...startTimeArray));
      setEndTime(Math.max(...endTimeArray));
      setSkeletonLoading(false);
    } catch (e) {
      console.log(e, "-------error in timeline-----");
    }
  };

  useEffect(() => {
    console.log("-------->>>>>>>", timelineItem, "<<<<<<<------------");
    if (type === "stepFour") {
      let scheduleProjectProcessDetails;
      if (RescheduleProjectData == undefined) {
        console.log("scheduleProjectProcessDetail-2");
        scheduleProjectProcessDetails = localStorage.getItem(
          "scheduleProjectProcessDetails",
        );
      } else {
        console.log("rescheduleProjectProcessDetail-2");
        scheduleProjectProcessDetails = localStorage.getItem(
          "rescheduleProjectProcessDetails",
        );
      }
      if (scheduleProjectProcessDetails) {
        const contextData = JSON.parse(scheduleProjectProcessDetails);
        if (contextData?.selectedTimeline) {
          arrangeTimeline(selectedTimeline);
        }
      }
    }
  }, []);

  const getMachineAvailability = async (machineIdArray: any[]) => {
    try {
      const toDay = DateTime.now();
      const before3Month = Math.floor(toDay.minus({ months: 3 }).toSeconds());
      const after3Month = Math.floor(toDay.plus({ months: 3 }).toSeconds());
      const results = await post(
        "scheduler/processScheduling/availability",
        {
          machineIdArray,
          startTime: before3Month,
          endTime: after3Month,
        },
        undefined,
        SERVICE_TYPE.serviceType
      );
      console.log(
        "mchines array  ------- > ",
        before3Month,
        after3Month,
        machineIdArray,
        results?.response.data,
      );
      return results?.response.data;
    } catch (error: any) {
      console.log(error);
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
    }
  };

  const newGroups =
    type === "stepFour"
      ? timelineGroup
          .filter((g: any) => g.root || g.parent)
          .map((group: any) => {
            return {
              ...group,
              title: group.root ? (
                <div
                  // onClick={() => toggleGroup(group.id)}
                  style={{ fontWeight: "bold" }}
                  title={group.title}
                >
                  {/* {openGroups[group.id] ? "[-]" : "[+]"} {group.title} */}
                  {group.title}
                </div>
              ) : (
                <div style={{ paddingLeft: 20 }} title={group.title}>
                  {group.title}
                </div>
              ),
            };
          })
      : timelineGroupState
          .filter((g: any) => g.root || g.parent)
          .map((group: any) => {
            return {
              ...group,
              title: group.root ? (
                <div
                  // onClick={() => toggleGroup(group.id)}
                  style={{ fontWeight: "bold" }}
                  title={group.title}
                >
                  {/* {openGroups[group.id] ? "[-]" : "[+]"} {group.title} */}
                  {group.title}
                </div>
              ) : (
                <div style={{ paddingLeft: 20 }} title={group.title}>
                  {group.title}
                </div>
              ),
            };
          });

  const getAllProductionTemplate = async () => {
    try {
      // const results = await post("processScheduling/getAllProcess", {
      //   page: 0,
      //   size: 1000,
      // });
      // setProductionTemplate(results.data.list);
    } catch (error: any) {
      console.log(error);
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
    }
  };

  const getHolidays = async () => {
    try {
      const results = await get(
        "scheduler/calendar/allHolidays",
        undefined,
        SERVICE_TYPE.serviceType
      );
      const holidaysData = results.data.map((holiday: any) => {
        return moment(moment.unix(holiday.date_), "DD.MM.YYYY");
      });
      console.log("holidays ----------->  ", holidaysData);
      setHolidays(holidaysData);
    } catch (error: any) {
      console.log(error);
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
    }
  };

  useEffect(() => {
    getProjectStatusData();
    getAllProductionTemplate();
    getHolidays();
    console.log("check edit resource", selectedTimeline);
    type !== "stepFour" && getProjectData();
    if (type === "stepFour") {
      const resourceDetails = selectedTimeline?.resourceDetails;
      const resourceDetailsCompleted = selectedTimeline?.resourceDetailsCompleted
        ? JSON.parse(JSON.stringify(selectedTimeline.resourceDetailsCompleted))
        : {};
      console.log("resource details", selectedTimeline);
      if (selectedTimeline?.resourceDetails) {
        Object.keys(resourceDetails).forEach((key) => {
          if (resourceDetails.hasOwnProperty(key)) {
            console.log("resource details", resourceDetails[key]);
            const task = resourceDetails[key];
            const bobbin = task["Bobbin"];
            const labour = task["Labour"];
            bobbin["id"] = key;
            labour["id"] = key;
            labour["subRows"] =
              resourceDetailsCompleted && resourceDetailsCompleted[key]
                ? resourceDetailsCompleted[key]
                : [];
            bobbin["subRows"] =
              resourceDetailsCompleted && resourceDetailsCompleted[key]
                ? resourceDetailsCompleted[key]
                : [];
          }
        });
      }
      if (
        selectedTimeline?.resourceDetails ||
        selectedTimeline?.resourceDetailsCompleted
      ) {
        let completeMaterial: { [key: string]: string } = {};
        Object.keys(selectedTimeline?.machineDetails).forEach((key) => {
          console.log("resource details-5", key);
          if (resourceDetails?.hasOwnProperty(key)) {
            console.log(
              "resource details-2",
              resourceDetails[key],
              resourceDetails,
            );
            const task = resourceDetails[key];
            const bobbin = task["Bobbin"];
            const labour = task["Labour"];
            bobbin["id"] = key;
            labour["id"] = key;
            bobbin["status"] = selectedTimeline?.projectStatus;
            labour["status"] = selectedTimeline?.projectStatus;
            labour["subRows"] =
              resourceDetailsCompleted && resourceDetailsCompleted[key]
                ? resourceDetailsCompleted[key]
                : [];
            bobbin["subRows"] =
              resourceDetailsCompleted && resourceDetailsCompleted[key]
                ? resourceDetailsCompleted[key]
                : [];
          } else {
            console.log("resource details-6", key);
            if (resourceDetailsCompleted?.hasOwnProperty(key)) {
              console.log("resource details-7", resourceDetailsCompleted[key]);
              let sortRawArray = resourceDetailsCompleted[key];
              sortRawArray = sortRawArray.sort(
                (
                  a: { Bobbin: { startTime: number } },
                  b: { Bobbin: { startTime: number } },
                ) => b.Bobbin.startTime - a.Bobbin.startTime,
              );
              const task = sortRawArray.shift();
              const bobbin = task["Bobbin"];
              const labour = task["Labour"];
              console.log("resource details-4", bobbin);
              bobbin["id"] = key;
              labour["id"] = key;
              bobbin["status"] = "Completed";
              labour["status"] = "Completed";
              labour["subRows"] = sortRawArray.length > 0 ? sortRawArray : [];
              bobbin["subRows"] = sortRawArray.length > 0 ? sortRawArray : [];
              completeMaterial[key] = task;
            }
          }
        });
        completeMaterial = {
          ...completeMaterial,
          ...selectedTimeline?.data?.resourceDetails,
        };
        setTableData(Object.values(completeMaterial));
        console.log(
          "resource details result",
          selectedTimeline?.data?.resourceDetails,
          completeMaterial,
        );
      } else {
        setTableData([]);
      }
      setTableDataResource(Object.values(selectedTimeline?.resourceDetails));
      setProjectStatus(selectedTimeline?.projectStatus);
    }
  }, []);

  useEffect(() => {
    console.log(type, "task status ----------->    ", taskStatus);
    type !== "stepFour" && getProjectData();
  }, [taskStatus]);

  useEffect(() => {
    console.log("task list ----------->    ", tasksList);
  }, [tasksList]);

  const nodeDataSelect = (nodeData: NodeData) => {
    if (nodeData?.data?.props) {
      setSelectedNode(nodeData);
      setNodeModalVisible(true);
    } else {
      message.open({
        type: "error",
        content: "No Properties",
        duration: 2,
      });
    }
  };

  const selectContainers = (nodeData: NodeData) => {
    const initMachines = [...nodeData?.data.props.machines];

    const containerArraySamp = projectData?.containerArray;
    console.log(
      containerArraySamp,
      "containerArraySampcontainerArraySampcontainerArraySamp",
    );
    const initValues = initMachines.map((machine: any, index: number) => ({
      machineName: machine.machineName,
      inputContainer: machine?.machineData?.inputContainer,
      taskGroup: nodeData?.data.props.taskGroup,
      ...machine,
      ...containerArraySamp.find(
        (container: any) =>
          container.machineId.toString() === machine.machineID,
      ),
    }));
    setContainerModalVisible(true);
    setContainerArrayState(initValues);
  };

  const nodeColour = (node: any) => {
    switch (node?.properties?.data?.type) {
      case "Task":
        return "#352f44";
      case "Input":
        return "#3a4750";
      case "Output":
        return "#414141";
      case "Block":
        return "#252A38";
      default:
        return "#252A38";
    }
  };

  const showMessage = (type: any, content: string, duration: number) => {
    message.open({
      type,
      content,
      duration,
    });
  };

  const handleItemMove = (itemId: any, dragTime: any, newGroupOrder: any) => {
    let movedItem: any;
    type === "stepFour"
      ? (movedItem = timelineItem.find((item: any) => item.id === itemId))
      : (movedItem = timelineItemState.find((item: any) => item.id === itemId));
    console.log("moved item =======> ", movedItem);
    const sD =
      new Date(
        moment(formOne.getFieldsValue().startTime).year(),
        moment(formOne.getFieldsValue().startTime).month(),
        moment(formOne.getFieldsValue().startTime).date(),
        moment(formOne.getFieldsValue().startTime).hour(),
        moment(formOne.getFieldsValue().startTime).minute(),
      ).valueOf() / 1000;
    const startD =
      type === "stepFour"
        ? formData.startTime
        : moment.unix(sD).format("YYYY-MM-DD h:mm A");
    const eD =
      new Date(
        moment(formOne.getFieldsValue().deadline).year(),
        moment(formOne.getFieldsValue().deadline).month(),
        moment(formOne.getFieldsValue().deadline).date(),
        moment(formOne.getFieldsValue().deadline).hour(),
        moment(formOne.getFieldsValue().deadline).minute(),
      ).valueOf() / 1000;
    const endD =
      type === "stepFour"
        ? formData.deadline
        : moment.unix(eD).format("YYYY-MM-DD h:mm A");

    // if (edge === "left") {
    let actualTaskStatus: any;
    let checkId: any = "task::" + movedItem?.taskId;
    if (taskStatus?.length > 0) {
      taskStatus?.map((task: any, index: any) => {
        if (checkId === task?.taskId) {
          actualTaskStatus = task?.taskStatus;
        }
      });
    }
    console.log("actualStartTime ========== ", actualTaskStatus);
    if (
      type !== "stepFour" &&
      (actualTaskStatus === "ongoing" || actualTaskStatus === "done")
    ) {
      let anOra =
        actualTaskStatus === "ongoing" ? "an ongoing" : "an already ended";
      showMessage("error", `Error! Can’t drag ${anOra}  task`, 2);
      return;
    }
    // Validation for moved before current time(Mainly for ongoing projects)
    if (dragTime < Date.now()) {
      showMessage("error", "Error! End time cannot be a past time", 2);
      return;
    }

    // Validation for moved before project start time
    if (
      dragTime / 1000 <
      (type === "stepFour"
        ? moment(formData.startTime).unix()
        : moment(formOne.getFieldsValue().startTime).unix())
    ) {
      showMessage(
        "error",
        `Error! Timeline cannot be started before the project start date - ${startD}`,
        2,
      );
      return;
    }

    // Validation for moved after project deadline
    console.log("");
    if (
      (dragTime + (movedItem.end_time - movedItem.start_time)) / 1000 >
      (type === "stepFour"
        ? moment(formData.deadline)
        : moment(formOne.getFieldsValue().deadline).unix())
    ) {
      showMessage(
        "error",
        `Error! Timeline cannot be ended after the project deadline - ${endD}`,
        2,
      );
      return;
    }

    if (itemId >= 1) {
      const previousTaskIdArray = movedItem?.tip?.previousNodes;
      const previousTaskArray: any = [];
      previousTaskIdArray.map((previousNode: any) => {
        if (type === "stepFour") {
          previousTaskArray.push(
            timelineItem.find((item: any) => item.taskId === previousNode.id),
          );
        } else {
          previousTaskArray.push(
            timelineItemState.find(
              (item: any) => item.taskId === previousNode.id,
            ),
          );
        }
      });
      console.log("previous tasks ------> ", previousTaskArray);
      if (previousTaskArray?.length > 0 && previousTaskArray[0] !== undefined) {
        for (const previousTask of previousTaskArray) {
          if (
            (dragTime + (movedItem.end_time - movedItem.start_time)) / 1000 <
            moment(previousTask?.end_time).unix()
          ) {
            showMessage(
              "error",
              `Error! ${movedItem?.title} cannot be ended before ${previousTask.title}`,
              5,
            );
            return;
          }
        }
      }

      //validation for next tasks
      const nextTaskIdArray = movedItem?.tip?.nextNodes;
      const nextTaskArray: any = [];
      nextTaskIdArray.map((nextNode: any) => {
        if (type === "stepFour") {
          nextTaskArray.push(
            timelineItem.find((item: any) => item.taskId === nextNode.id),
          );
        } else {
          nextTaskArray.push(
            timelineItemState.find((item: any) => item.taskId === nextNode.id),
          );
        }
      });
      console.log("next tasks ------> ", nextTaskArray[0]);
      if (nextTaskArray?.length > 0 && nextTaskArray[0] !== undefined) {
        for (const nextTask of nextTaskArray) {
          if (
            (dragTime + (movedItem.end_time - movedItem.start_time)) / 1000 >
            moment(nextTask?.end_time).unix()
          ) {
            showMessage(
              "error",
              `Error! ${nextTask?.title} cannot be ended before ${movedItem?.title}`,
              5,
            );
            return;
          }
        }
      }
    }

    // Validation for overlap machine bookings
    const movedStartTime = dragTime/1000;
    const movedEndTime = (dragTime + (movedItem.end_time - movedItem.start_time))/1000;
    for (const machineBooking of type === "stepFour"
      ? machineBookingTime
      : machineBookingTimeState) {
      if (machineBooking.group === movedItem.group) {
        if (
          moment(movedStartTime).isBetween(
            machineBooking.start_time,
            machineBooking.end_time,
          ) ||
          moment(movedEndTime).isBetween(
            machineBooking.start_time,
            machineBooking.end_time,
          ) ||
          moment(machineBooking.start_time).isBetween(
            movedStartTime,
            movedEndTime,
          ) ||
          moment(machineBooking.end_time).isBetween(
            movedStartTime,
            movedEndTime,
          )
        ) {
          showMessage(
            "error",
            "Error! Timeline cannot be overlapped with unavailable time slots of the machines",
            2,
          );
          return;
        }
      }
    }

    const itemsTemp =
      type === "stepFour"
        ? timelineItem.map((item: any) =>
            item.id === itemId
              ? {
                  ...item,
                  start_time: dragTime,
                  end_time: dragTime + (item.end_time - item.start_time),
                  // group: group.id,
                }
              : item,
          )
        : timelineItemState.map((item: any) =>
            item.id === itemId
              ? {
                  ...item,
                  start_time: dragTime,
                  end_time: dragTime + (item.end_time - item.start_time),
                  // group: group.id,
                }
              : item,
          );

    // console.log("-----------itemsTemp------------", itemsTemp);
    type === "stepFour"
      ? setTimelineItem(itemsTemp)
      : setTimelineItemState(itemsTemp);

    // Update the  timeline data
    const timeLineDataTemp = timelineData;
    // console.log("-------- timelineData --------------", timeLineDataTemp);

    const tasId = itemsTemp.find((item: any) => item.id === itemId).taskId;
    // if(timeLineDataTemp?.)
    if (type === "stepFour") {
      selectedTimeline.nodes[`task::${tasId}`].startTime = itemsTemp.find(
        (item: any) => item.taskId === tasId,
      ).start_time;
      selectedTimeline.nodes[`task::${tasId}`].endTime = itemsTemp.find(
        (item: any) => item.taskId === tasId,
      ).end_time;

      setSelectedTimeline(selectedTimeline);
    } else {
      timelineData.nodes[`task::${tasId}`].startTime = (itemsTemp.find(
        (item: any) => item.taskId === tasId,
      ).start_time)/1000;
      timelineData.nodes[`task::${tasId}`].endTime = (itemsTemp.find(
        (item: any) => item.taskId === tasId,
      ).end_time)/1000;
      timelineData.endTime =
        timelineData?.nodes[timelineData?.nodes?.length - 1]?.endTime;
      setTableData(timelineData);
    }
  };

  const handleItemResize = (itemId: any, time: any, edge: any) => {
    let movedItem: any;
    type === "stepFour"
      ? (movedItem = timelineItem.find((item: any) => item.id === itemId))
      : (movedItem = timelineItemState.find((item: any) => item.id === itemId));
    console.log("-----------ITEM RESIZE OCCURING----------", movedItem, edge);
    const sD =
      new Date(
        moment(formOne.getFieldsValue().startTime).year(),
        moment(formOne.getFieldsValue().startTime).month(),
        moment(formOne.getFieldsValue().startTime).date(),
        moment(formOne.getFieldsValue().startTime).hour(),
        moment(formOne.getFieldsValue().startTime).minute(),
      ).valueOf() / 1000;
    const startD =
      type === "stepFour"
        ? formData.startTime
        : moment.unix(sD).format("YYYY-MM-DD h:mm A");
    const eD =
      new Date(
        moment(formOne.getFieldsValue().deadline).year(),
        moment(formOne.getFieldsValue().deadline).month(),
        moment(formOne.getFieldsValue().deadline).date(),
        moment(formOne.getFieldsValue().deadline).hour(),
        moment(formOne.getFieldsValue().deadline).minute(),
      ).valueOf() / 1000;
    const endD =
      type === "stepFour"
        ? formData.deadline
        : moment.unix(eD).format("YYYY-MM-DD h:mm A");

    let actualTaskStatus: any;
    let checkId: any = "task::" + movedItem?.taskId;
    if (taskStatus?.length > 0) {
      taskStatus?.map((task: any, index: any) => {
        if (checkId === task?.taskId) {
          actualTaskStatus = task?.taskStatus;
        }
      });
    }
    if (type !== "stepFour" && actualTaskStatus === "done") {
      showMessage("error", "Error! Can’t resize an already ended  task", 2);
      return;
    }
    // console.log("------------- tasks ------------", tasks);
    // console.log("Resized", itemId, time, edge);

    // Validation for moved before current time(Mainly for ongoing projects)
    if (edge !== "left") {
      if (time < Date.now()) {
        showMessage("error", "Error! End time cannot be a past time", 2);
        return;
      }
    }

    // Validation for moved before project start time
    if (
      time / 1000 <
      (type === "stepFour"
        ? moment(formData.startTime).unix()
        : moment(formOne.getFieldsValue().startTime).unix())
    ) {
      showMessage(
        "error",
        `Error! Timeline cannot be started before the project start date - ${startD}`,
        2,
      );
      return;
    }

    // Validation for moved after project deadline
    if (
      time / 1000 >
      (type === "stepFour"
        ? moment(formData.deadline).unix()
        : moment(formOne.getFieldsValue().deadline).unix())
    ) {
      showMessage(
        "error",
        `Error! Timeline cannot be ended after the project deadline - ${endD}`,
        2,
      );
      return;
    }

    // Validation for edit ongoing task start time
    if (edge === "left") {
      let actualStartTime: any;
      let checkId: any = "task::" + movedItem?.taskId;
      if (taskStatus?.length > 0) {
        taskStatus?.map((task: any, index: any) => {
          if (checkId === task?.taskId) {
            actualStartTime = task?.actualStartTime;
          }
        });
      }
      if (type !== "stepFour") {
        if (actualStartTime * 1000 < Date.now()) {
          showMessage(
            "error",
            "Error! Start time of an ongoing task cannot be edited",
            2,
          );
          return;
        }
      }
    }
    if (itemId >= 1) {
      if (edge === "right") {
        const previousTaskIdArray = movedItem?.tip?.previousNodes;
        const previousTaskArray: any = [];
        previousTaskIdArray.map((previousNode: any) => {
          if (type === "stepFour") {
            previousTaskArray.push(
              timelineItem.find((item: any) => item.taskId === previousNode.id),
            );
          } else {
            previousTaskArray.push(
              timelineItemState.find(
                (item: any) => item.taskId === previousNode.id,
              ),
            );
          }
        });
        console.log("previous tasks ------> ", previousTaskArray);
        if (
          previousTaskArray?.length > 0 &&
          previousTaskArray[0] !== undefined
        ) {
          for (const previousTask of previousTaskArray) {
            if (time / 1000 < moment(previousTask?.end_time).unix()) {
              showMessage(
                "error",
                `Error! ${movedItem?.title} cannot be ended before ${previousTask.title}`,
                5,
              );
              return;
            }
          }
        }
      }
    }

    if (edge === "right") {
      const nextTaskIdArray = movedItem?.tip?.nextNodes;
      const nextTaskArray: any = [];
      nextTaskIdArray.map((nextNode: any) => {
        if (type === "stepFour") {
          nextTaskArray.push(
            timelineItem.find((item: any) => item.taskId === nextNode.id),
          );
        } else {
          nextTaskArray.push(
            timelineItemState.find((item: any) => item.taskId === nextNode.id),
          );
        }
      });
      console.log("next tasks ------> ", nextTaskArray);
      if (nextTaskArray?.length > 0 && nextTaskArray[0] !== undefined) {
        for (const nextTask of nextTaskArray) {
          if (time / 1000 > moment(nextTask?.end_time).unix()) {
            showMessage(
              "error",
              `Error! ${nextTask?.title} cannot be ended before ${movedItem?.title}`,
              5,
            );
            return;
          }
        }
      }
    }

    // Validation for overlap machine bookings
    const movedStartTime = edge === "left" ? time : movedItem.start_time;
    const movedEndTime = edge === "left" ? movedItem.end_time : time;

    for (const machineBooking of type === "stepFour"
      ? machineBookingTime
      : machineBookingTimeState) {
      if (machineBooking.group === movedItem.group) {
        if (
          moment(movedStartTime).isBetween(
            machineBooking.start_time,
            machineBooking.end_time,
          ) ||
          moment(movedEndTime).isBetween(
            machineBooking.start_time,
            machineBooking.end_time,
          ) ||
          moment(machineBooking.start_time).isBetween(
            movedStartTime,
            movedEndTime,
          ) ||
          moment(machineBooking.end_time).isBetween(
            movedStartTime,
            movedEndTime,
          )
        ) {
          showMessage(
            "error",
            "Error! Timeline cannot be overlapped with unavailable time slots of the machines",
            2,
          );
          return;
        }
      }
    }

    const itemsTemp =
      type === "stepFour"
        ? timelineItem.map((item: any) =>
            item.id === itemId
              ? {
                  ...item,
                  start_time: edge === "left" ? time : item.start_time,
                  end_time: edge === "left" ? item.end_time : time,
                }
              : item,
          )
        : timelineItemState.map((item: any) =>
            item.id === itemId
              ? {
                  ...item,
                  start_time: edge === "left" ? time : item.start_time,
                  end_time: edge === "left" ? item.end_time : time,
                }
              : item,
          );
    type === "stepFour"
      ? setTimelineItem(itemsTemp)
      : setTimelineItemState(itemsTemp);

    // Update the  timeline data
    const tasId = itemsTemp.find((item: any) => item.id === itemId).taskId;
    if (type === "stepFour") {
      selectedTimeline.nodes[`task::${tasId}`].startTime = itemsTemp.find(
        (item: any) => item.taskId === tasId,
      ).start_time;
      selectedTimeline.nodes[`task::${tasId}`].endTime = itemsTemp.find(
        (item: any) => item.taskId === tasId,
      ).end_time;

      setSelectedTimeline(selectedTimeline);
    } else {
      timelineData.nodes[`task::${tasId}`].startTime = moment(
        itemsTemp.find((item: any) => item.taskId === tasId).start_time,
      ).unix();
      timelineData.nodes[`task::${tasId}`].endTime = moment(
        itemsTemp.find((item: any) => item.taskId === tasId).end_time,
      ).unix();
      setTableData(timelineData);
    }
  };

  const updateProject = async (val: any) => {
    // const formValues = formOne.getFieldsValue();
    const sendingFormValues = {
      ...val,
      projectName: val?.projectName?.trim(),
      projectIdentifier: val?.projectIdentifier?.trim(),
    };
    try {
      const results = await post(
        `scheduler/processScheduling/editProject`,
        {
          projectId: props?.id,
          selectedTimeline: {
            projectName: val?.projectName?.trim(),
            projectIdentifier: val.projectIdentifier?.trim(),
            nodes: timelineData.nodes,
            id: timelineData.id,
            bobbinDetails: timelineData.bobbinDetails,
            rawMaterial: timelineData.rawMaterial,
            resourceDetails: timelineData.resourceDetails,
            reminders: timelineData.reminders,
            projectStatus: timelineData.projectStatus,
          },
          values: sendingFormValues,
        },
        undefined,
        SERVICE_TYPE.serviceType
      );
      // const results = await patch(`production/editProject`, {
      //   projectId: props?.id,
      //   selectedTimeline: {
      //     projectName: val?.projectName?.trim(),
      //     projectIdentifier: val.projectIdentifier?.trim(),
      //     nodes: timelineData.nodes,
      //     id: timelineData.id,
      //     bobbinDetails: timelineData.bobbinDetails,
      //     rawMaterial: timelineData.rawMaterial,
      //     resourceDetails: timelineData.resourceDetails,p
      //     reminders: timelineData.reminders,
      //     projectStatus: timelineData.projectStatus,
      //   },
      //   values: sendingFormValues,
      // });
      if (results.status === 200) {
        message.open({
          type: "success",
          content: "Project updated successfully",
          duration: 3,
        });
        // window.location.reload();
      }
    } catch (error: any) {
      console.log(error);
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
    }
  };

  const getFormtattedDate = (val: any) => {
    let result: any;
    let date =
      new Date(
        moment(val).year(),
        moment(val).month(),
        moment(val).date(),
        moment(val).hour(),
        moment(val).minute(),
      ).valueOf() / 1000;
    result = moment.unix(date).format("DD:MM:YYYY, hh:mm:ss a");
    return result;
  };

  const formDuration = (startD: any, endD: any) => {
    let val = moment.duration(moment(endD).diff(moment(startD))).asSeconds();
    let result: string = "";

    let seconds = Number(val);
    let d = Math.floor(seconds / (3600 * 24));
    let h = Math.floor((seconds % (3600 * 24)) / 3600);
    let m = Math.floor((seconds % 3600) / 60);
    let s = Math.floor(seconds % 60);

    let dDisplay = d > 0 ? d + (d == 1 ? " day " : " days ") : "";
    let hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : "";
    let mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";
    let sDisplay = s > 0 ? s + (s == 1 ? " second " : " seconds ") : "";

    dDisplay !== "" || hDisplay !== "" || mDisplay !== ""
      ? (result = dDisplay + hDisplay + mDisplay)
      : (result = sDisplay);
    return result;
  };

  const itemRenderer = ({
    item,
    timelineContext,
    itemContext,
    getItemProps,
    getResizeProps,
  }: any) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    console.log("-----------item renderer -----------", item);
    setTaskEndTime(item?.end_time);
    const backgroundColor = itemContext.selected
      ? itemContext.dragging
        ? "red"
        : item.selectedBgColor
      : item.bgColor;
    if (item?.tip?.machineName === "disable") {
      return (
        <Popover
          content={
            <div className="gant-tooltip">
              <div>
                Duration :{" "}
                {formDuration(item?.tip?.tastStart, item?.tip?.tastEnd)}
                {/* {moment
                  .duration(
                    moment(item?.tip?.tastEnd).diff(
                      moment(item?.tip?.tastStart),
                    ),
                  )
                  .humanize()} */}
              </div>
              <div>Task Start : {getFormtattedDate(item?.tip?.tastStart)}</div>
              <div>Task End : {getFormtattedDate(item?.end_time)}</div>
            </div>
          }
          title={item?.tip?.projectName}
          trigger="hover"
        >
          <div
            {...getItemProps({
              style: {
                backgroundColor,
                borderStyle: "solid",
                borderRadius: 3,
                cursor: "default",
              },
            })}
          >
            {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}
            <div
              style={{
                height: itemContext.dimensions.height,
                overflow: "hidden",
                paddingLeft: 3,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {itemContext.title}
            </div>
            {/* {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null} */}
          </div>
        </Popover>
      );
    }
    return (
      <Popover
        content={
          <div className="gant-tooltip">
            <div>Machine Name : {item?.tip?.machineName}</div>
            <div>Setup Time : {item?.tip?.setupTime}</div>
            <div>
              Duration : {formDuration(item.start_time, item.end_time)}
              {/* {moment
                .duration(moment(item.end_time).diff(moment(item.start_time)))
                .humanize()} */}
            </div>
            <div>Speed : {item?.tip?.speed} m/s</div>
            {item?.status === "Completed" && (
              <div>Task Status: {item?.tip?.status}</div>
            )}
            <div>
              Task Start :{" "}
              {moment(item.start_time).format("DD:MM:YYYY, hh:mm:ss a")}
            </div>
            <div>
              Task End :{" "}
              {moment(item.end_time).format("DD:MM:YYYY, hh:mm:ss a")}
            </div>
          </div>
        }
        title={item?.tip?.taskGroup}
        trigger="hover"
      >
        <div
          {...getItemProps({
            style: {
              backgroundColor,
              borderStyle: "solid",
              borderRadius: 3,
              cursor: "pointer",
            },
          })}
        >
          {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}
          <div
            style={{
              height: itemContext.dimensions.height,
              overflow: "hidden",
              paddingLeft: 3,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {itemContext.title}
          </div>
          {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
        </div>
      </Popover>
    );
  };

  const closeCommonConfirmationModal = (val: boolean) => {
    // console.log(val);
    if (val === true) {
      formOne.submit();
      setUpdateDetailsWarningModal(false);
      history.push("/scheduling/projects");
    } else {
      setUpdateDetailsWarningModal(false);
    }
  };

  const getProcessTemplateData = async (id: any) => {
    const response = await get(
      `scheduler/productionPlans/getProcessTemplate?id=${id}`,
      undefined,
      SERVICE_TYPE.serviceType
    );
    console.log(response, "setNodessetNodessetNodessetNodessetNodessetNodes");
    setNodes(response.data.nodes);
    setEdges(response.data.edges);
    console.log(response, "setNodessetNodessetNodessetNodessetNodessetNodes");
  };

  const onTimeChange = (start: any, end: any, updateScrollCanvas: any) => {
    // third argument forces the calendar to calculate new postions on every time change
    updateScrollCanvas(start, end);
  };

  const createProject = async () => {
    setLoadingStepFour(true);
    try {
      const results = await post(
        `scheduler/processScheduling/saveProject`,
        {
          ...selectedTimeline,
          inputs: inputCutLengthArray,
          saveAsRescheduled: false,
        },
        undefined,
        SERVICE_TYPE.serviceType
      );
      console.log(results, selectedTimeline,"scheduler/processScheduling/saveProject");
      if (results.status === 200 || results.status === 201) {
        setRecentProject(selectedTimeline);
        message.open({
          type: "success",
          content: "Project created successfully",
          duration: 3,
        });
        setLoadingStepFour(false);

        history.push("/scheduling/projects");
      }
    } catch (error: any) {
      console.log(error);
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
      setLoadingStepFour(false);
    } finally {
      localStorage.removeItem("scheduleProjectProcessDetails");
      localStorage.removeItem("rescheduleProjectProcessDetails");
      localStorage.removeItem("previousFromStepTwo");
    }
  };

  if (type === "stepFour") {
    if (loadingStepFour) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin spinning />
        </div>
      );
    }
  } else {
    if (skeletonLoading) {
      return <Skeleton active paragraph={{ rows: 10 }} round />;
    }
  }
  return (
    <div className="view-container">
      <CommonConfirmationModal
        visible={updateDetailsWarningModal}
        message="Warning! Editing this project might affect the efficiency of this production process"
        onClose={(val: boolean) => {
          closeCommonConfirmationModal(val);
        }}
      />
      {type !== "stepFour" && (
        <>
          <div className="back-btn">
            <Button
              className="add-button"
              type="primary"
              style={{
                // width: 150,
                height: "45px",
                fontFamily: "Poppins",
                fontSize: "15px",
              }}
              icon={<ArrowLeftOutlined />}
              onClick={() => history.push("/scheduling/projects")}
            >
              Back
            </Button>
          </div>
          <div
            className="stepOne-scheduling view-stepOne"
            style={{ width: "100%" }}
          >
            <Form
              initialValues={projectData?.value}
              layout="vertical"
              form={formOne}
              name="task"
              scrollToFirstError
              autoComplete="off"
              onFinish={(val: any) => updateProject(val)}
            >
              <Divider orientation="center" style={{ marginTop: 0 }}>
                Project Outline
              </Divider>
              <Row className="form-row">
                <Col span={11}>
                  <Form.Item
                    label="Project Name"
                    name="projectName"
                    rules={[
                      {
                        required: true,
                        transform: (value: any) => value.trim(),
                      },
                    ]}
                  >
                    <Input
                      // disabled={formDisabled}
                      name="projectName"
                      placeholder="Enter Project Name"
                    />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    label="Project Identifier"
                    name="projectIdentifier"
                    rules={[
                      {
                        required: true,
                        transform: (value: any) => value.trim(),
                      },
                    ]}
                  >
                    <Input placeholder="Enter ID" />
                    {/* <Input disabled={formDisabled} placeholder="Enter ID" /> */}
                  </Form.Item>
                </Col>
              </Row>
              <Row className="form-row">
                <Col span={11}>
                  <Form.Item label="Production Template" name="processTemplate">
                    <Select
                      placeholder="Select the Template"
                      showSearch
                      disabled
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {processTemplate?.map(
                        (template: {
                          id: { type: string; id: string };
                          processFields: { processName: string };
                        }) => (
                          <Option
                            key={template?.id?.id}
                            value={template?.id?.id}
                          >
                            {template?.processFields?.processName}
                          </Option>
                        ),
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    label="Minimum Order Length"
                    name="minimumOrderQuantity"
                  >
                    <Input
                      type="number"
                      placeholder="Select a template"
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="form-row">
                <Col span={11}>
                  <Form.Item
                    label="Start Date"
                    name="startTime"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <DatePicker
                      use12Hours
                      format="YYYY-MM-DD h:mm a"
                      style={{ width: "100%" }}
                      disabled
                      showTime
                    />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    label="Deadline"
                    name="deadline"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <DatePicker
                      use12Hours
                      format="YYYY-MM-DD h:mm a"
                      disabled
                      style={{ width: "100%" }}
                      showTime
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="form-row">
                <Col span={11}>
                  <Form.Item
                    label={
                      <div>
                        Priority{" "}
                        <Tooltip title="1 Indicates the highest priority">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </div>
                    }
                    name="priority"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select disabled placeholder="Select the Priority">
                      {priorityArray?.map(
                        (priority: { value: number; label: number }) => (
                          <Option key={priority.value} value={priority.value}>
                            {priority.label}
                          </Option>
                        ),
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    label={
                      <div>
                        Quantity{" "}
                        <Tooltip title="Quantity cannot be less than minimum cut length!">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </div>
                    }
                    name="quantities"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      disabled
                      type="number"
                      addonAfter="m"
                      name="quantity"
                      placeholder="Enter the Quantity"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item label="Cost" name="costCalculation">
                    <Input type="number" placeholder="Cost" disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item name="alter" valuePropName="checked">
                    <Checkbox disabled>
                      Enable altering current schedule{" "}
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Divider
                orientation="center"
                style={{ color: "white", marginTop: 25 }}
              >
                Current Schedules
              </Divider>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="prioritySchedules"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Radio.Group disabled>
                      <Radio value="lowerPrioritySchedules">
                        Alter lower priority schedules
                      </Radio>
                      <Radio value="equalAndLowerPrioritySchedules">
                        Alter equal and lower priority schedules
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </>
      )}
      <div className="collapse-container">
        <Card title="Project Schedule" key="1">
          <div
            className="stepFour-cards-view-one"
            style={{ marginTop: "30px" }}
          >
            <div className="card-container">
              <div className="scheduling-timeline">
                <Timeline
                  defaultTimeStart={moment
                    .unix(type === "stepFour" ? startTimeView : startTime)
                    .add(-5, "hours")}
                  defaultTimeEnd={moment
                    .unix(type === "stepFour" ? endTimeView : endTime)
                    .add(5, "hours")}
                  itemTouchSendsClick={false}
                  stackItems
                  itemHeightRatio={0.75}
                  canMove={type === "stepFour" ? false : timelineMove}
                  canResize={
                    type === "stepFour"
                      ? false
                      : timelineMove === false
                      ? false
                      : "both"
                  }
                  // onItemDrag={handleItemDrag}
                  onItemMove={handleItemMove}
                  onItemResize={handleItemResize}
                  groups={newGroups}
                  items={type === "stepFour" ? timelineItem : timelineItemState}
                  itemRenderer={itemRenderer}
                  // canResize="both"
                  onTimeChange={onTimeChange}
                  verticalLineClassNamesForTime={(
                    timeStart,
                    timeEnd,
                  ): string[] | undefined => {
                    const currentTimeStart = moment(timeStart);
                    const currentTimeEnd = moment(timeEnd);

                    const classes = [];

                    for (const holiday of holidays) {
                      if (
                        holiday.isSame(currentTimeStart, "day") &&
                        holiday.isSame(currentTimeEnd, "day")
                      ) {
                        classes.push("holiday");
                      }
                    }
                    return classes;
                  }}
                />
              </div>
            </div>
          </div>
        </Card>
        {type === "stepFour" && (
          <Card title="Resource Requirements" key="2">
            <div className="stepFour-cards-view-three">
              <div className="stepFour-cards">
                <ResourceTable
                  projectStatus={projectStatus}
                  data={tableDataResource}
                  rawMaterial={[
                    selectedTimeline?.rawMaterial,
                    selectedTimeline?.rawMaterialCompleted,
                  ]}
                />
              </div>
            </div>
          </Card>
        )}
        {type === "stepFour" ? (
          <Card
            title={`Machine and ${Container.NAME} Selection`}
            bordered
            className="stepFour-cards"
          >
            <div
              style={{
                marginLeft: "50px",
                marginRight: "50px",
                marginBottom: "120px",
              }}
            >
              <div ref={componentRef}>
                <StepTwo edit={false} height={500} width={950} />
              </div>
            </div>
          </Card>
        ) : (
          <Card title={`Machine and ${Container.NAME} Selection`} key="3">
            <div className="stepFour-cards-view-two">
              <div
                style={{
                  marginLeft: "150px",
                  marginRight: "50px",
                  marginBottom: "120px",
                }}
              >
                <div style={{ width: "90%" }} className="stepTwo-scheduling">
                  <NodeDataModal
                    update={false}
                    visible={nodeModalVisible}
                    disable
                    nodeData={selectedNode}
                    onClose={() => setNodeModalVisible(false)}
                  />

                  <ContainerViewModal
                    visible={containerModalVisible}
                    containerArray={containerArrayState}
                    onClose={() => {
                      setContainerModalVisible(false);
                      // console.log("-----------------", containerArrayState);
                      // setContainerArrayState([]);
                    }}
                  />
                  <div style={{ height: 400, width: "100%", marginTop: 30 }}>
                    <div
                      style={{
                        zIndex: 9,
                        position: "absolute",
                        // bottom: 10,
                        // right: 15,
                        background: "rgba(0, 0, 0, .5)",
                        padding: 10,
                        color: "white",
                      }}
                    >
                      <Tooltip title="Zoom In" placement="left">
                        <PlusOutlined
                          style={{
                            display: "block",
                            width: "100%",
                            margin: "5px 0",
                          }}
                          onClick={() => canvasRef.current.zoomIn()}
                        />
                      </Tooltip>
                      <Tooltip title="Zoom Out" placement="left">
                        <MinusOutlined
                          style={{
                            display: "block",
                            width: "100%",
                            margin: "5px 50",
                          }}
                          onClick={() => canvasRef.current.zoomOut()}
                        />
                      </Tooltip>
                      <Tooltip title="Fit" placement="left">
                        <ExpandOutlined
                          style={{
                            display: "block",
                            width: "100%",
                            margin: "5px 0",
                          }}
                          onClick={() => canvasRef.current.fitCanvas()}
                        />
                      </Tooltip>
                    </div>
                    <Canvas
                      fit
                      disabled
                      center
                      direction="RIGHT"
                      // width={1000} // Use small canvas dimensions to make sure the nodes appear on screen immediately
                      maxHeight={1000}
                      height={500}
                      ref={canvasRef}
                      nodes={nodes}
                      edges={edges}
                      node={(n) => (
                        <Node
                          {...n}
                          style={{
                            borderRadius: 30,
                            fill: nodeColour(n),
                          }}
                          label={
                            <Label
                              style={{
                                visibility: "hidden",
                              }}
                            />
                          }
                        >
                          {(event) => (
                            <foreignObject
                              height={event.height - 20}
                              width={event.width - 20}
                              x={15}
                              y={5}
                            >
                              <div
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignSelf: "center",
                                  display: "flex",
                                  cursor: "pointer",
                                  // backgroundColor: "red",
                                }}
                              >
                                <div
                                  style={{
                                    padding: 1,
                                    alignSelf: "flex-start",
                                  }}
                                >
                                  {event?.node?.data?.type !== "Block" && (
                                    <div className="node-custom">
                                      <img
                                        alt="popup"
                                        src={PopupIcon}
                                        className="popup-img"
                                        onClick={() =>
                                          nodeDataSelect(event.node)
                                        }
                                      />

                                      {event?.node?.data?.type === "Task" && (
                                        <>
                                          <MenuUnfoldOutlined
                                            alt="popup"
                                            className="popup-container"
                                            onClick={() =>
                                              selectContainers(event.node)
                                            }
                                          />
                                        </>
                                      )}
                                      {event?.node?.data?.props
                                        ? event?.node?.data?.props?.taskName ||
                                          event?.node?.data?.props?.inputItem ||
                                          event?.node?.data?.props?.output
                                        : event?.node?.data?.type}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </foreignObject>
                          )}
                        </Node>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Card>
        )}
        {type !== "stepFour" && (
          <Button
            type="primary"
            style={{
              height: "40px",
              borderRadius: "5px",
              marginTop: "20px",
              fontFamily: "Poppins",
              fontSize: "15px",
            }}
            onClick={() => setUpdateDetailsWarningModal(true)}
          >
            Update
          </Button>
        )}
      </div>
      {type === "stepFour" && (
        <div className="stepFour-btns-container">
          <div className="stepFour-btns">
            <Button
              type="primary"
              danger
              style={{
                height: "40px",
                fontFamily: "Poppins",
                fontSize: "15px",
                borderRadius: "5px",
                marginTop: "20px",
                marginRight: "20px",
              }}
              onClick={() => setCurrent(current - 1)}
            >
              Previous
            </Button>
            <Button
              type="primary"
              style={{
                height: "40px",
                borderRadius: "5px",
                marginTop: "20px",
                fontFamily: "Poppins",
                fontSize: "15px",
              }}
              onClick={() => createProject()}
            >
              Create project
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditProject;
