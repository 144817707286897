export let xyCordinates = { x: 0, y: 0 };

export const renderAluminiumPlant = (aluminium_machine) => {
  var canvas = document.getElementById("canvas");
  if (canvas) {
    var context = canvas.getContext("2d");
    // window.devicePixelRatio = 2;
    // var scale = window.devicePixelRatio;
    const data = {
      main_canvas_details: { width_ratio: 0.5, height_ratio: 0.24 },
      rectangle_array: [
        {
          x_ratio: 0.52,
          y_ratio: 0.04,
          width_ratio: 0.45,
          height_ratio: 0.1,
          text: "IWD 04",
        },
        {
          x_ratio: 0.02,
          y_ratio: 0.2,
          width_ratio: 0.3,
          height_ratio: 0.1,
          text: "Rod Breaker Al - I",
        },
        {
          x_ratio: 0.02,
          y_ratio: 0.38,
          width_ratio: 0.3,
          height_ratio: 0.1,
          text: "Rod Breaker Al - 2",
        },
        {
          x_ratio: 0.4,
          y_ratio: 0.2,
          width_ratio: 0.57,
          height_ratio: 0.1,
          text: "Skip strander",
        },
        {
          x_ratio: 0.32,
          y_ratio: 0.54,
          width_ratio: 0.65,
          height_ratio: 0.1,
          text: "7 Tubular Strander",
        },
        {
          x_ratio: 0.02,
          y_ratio: 0.73,
          width_ratio: 0.95,
          height_ratio: 0.1,
          text: "12 Tubular Strander",
        },
        {
          x_ratio: 0.32,
          y_ratio: 0.89,
          width_ratio: 0.4,
          height_ratio: 0.07,
          text: "Bow Strander",
        },
      ],
    };

    function drawRect(ctx, x, y, width, height, text) {
      ctx.strokeStyle = "#FFFFFF";
      ctx.lineWidth = 0.7;
      ctx.strokeRect(x, y, width, height);
      // ctx.scale(scale, scale);
      // ctx.scale(scale, scale)
      ctx.font = "12px Barlow";
      ctx.fillStyle = "#FFFFFF";
      ctx.fillText(text, width / 2 + x, height / 2 + y);
    }

    function drawRectLightColor(ctx, x, y, width, height, text) {
      ctx.strokeStyle = "#696969";
      ctx.strokeRect(x, y, width, height);  
      ctx.font = "15px Barlow";
      ctx.fillStyle = "#FFFFFF";
      ctx.fillText(text, width / 2 + x, height / 2 + y);
    }

    function displayMarkers(ctx, x, y, x1, x2, y1, color) {
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.lineTo(x1, y1);
      ctx.lineTo(x2, y1);
      ctx.fillStyle = color;
      ctx.fill();
    }

    function circleMarks(ctx, x, y, r, color) {
      ctx.beginPath();
      ctx.arc(x, y, r, 0, 2 * Math.PI);
      ctx.fillStyle = color;
      ctx.fill();
    }

    context.canvas.width =
      document.documentElement.clientWidth *
      data.main_canvas_details.width_ratio;
    context.canvas.height =
      document.documentElement.clientWidth *
      data.main_canvas_details.height_ratio;

    // console.log("client width: ", document.documentElement.clientWidth);

    context.strokeStyle = "#ffffff";
    drawRectLightColor(
      context,
      0,
      0,
      context.canvas.width,
      context.canvas.height,
      "",
    );
    for (let index = 0; index < data.rectangle_array.length; index++) {
      drawRect(
        context,
        context.canvas.width * data.rectangle_array[index].x_ratio,
        context.canvas.height * data.rectangle_array[index].y_ratio,
        context.canvas.width * data.rectangle_array[index].width_ratio,
        context.canvas.height * data.rectangle_array[index].height_ratio,
        data.rectangle_array[index].text,
      );
    }

    if (aluminium_machine) {
      for (let index = 0; index < aluminium_machine.length; index++) {
        circleMarks(
          context,
          context.canvas.width * aluminium_machine[index].x_ratio,
          context.canvas.height * aluminium_machine[index].y_ratio,
          context.canvas.width * 0.008,
          aluminium_machine[index].color,
        );
      }
    }
  }

  // const plant = sessionStorage.getItem("displayPlant");
  // if (plant === "1") {
  //   window.addEventListener("resize", plant === "1" && renderAluminiumPlant);
  // }
};
