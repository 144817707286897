/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { FC, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import enIN from "date-fns/locale/en-IN";
import "./containerComponetStyles.scss";
import moment from "moment";
import { Card, Col, Divider, Modal, Row } from "antd";
import "../../pages/modalPopUp.scss";

const localizer = momentLocalizer(moment);
interface ICalComponent {
  allEvents: {
    title: string;
    start: any;
    end: any;
    color: string;
    heading: string;
    machine?: string;
    project?: string;
    details?: string[];
    count: number;
    type: string;
  }[];
  onNavigate: (newDate: any) => void;
}

const locales = {
  "en-IN": enIN,
};

const formats = {
  eventTimeRangeFormat: () => {
    return "";
  },
};

type ShowMore = {
  color: string;
  end: string;
  start: string;
  title: string;
  machine?: string;
  project?: string;
  details?: string[];
  heading: string;
  count: number;
  type: string;
}[];

const CalComponent: FC<ICalComponent> = ({
  allEvents,
  onNavigate,
}: ICalComponent) => {
  const [modal, setModal] = useState(false);
  const [modalDate, setModalDate] = useState("");
  const [modalData, setModalData] = useState<ShowMore>([]);

  const onModalOpen = (value: ShowMore, date: any) => {
    console.log(value, "kkk", date, "ppp");
    setModal(true);
    setModalData(value);
    setModalDate(date);
  };
  const onModalOpenSingle = (value: any) => {
    setModal(true);
    setModalData([value]);
    setModalDate(value.start);
  };

  return (
    <div className="container-calender-container">
      <div className="container-calender-view-container">
        <Calendar
          localizer={localizer}
          events={allEvents}
          formats={formats}
          views={{
            week: true,
            month: true,
            agenda: true,
          }}
          startAccessor="start"
          onSelectEvent={(e) => onModalOpenSingle(e)}
          endAccessor="end"
          style={{
            height: 600,
            minHeight: 500,
            color: "white",
          }}
          onShowMore={(e, d) => onModalOpen(e, d)}
          onNavigate={onNavigate}
        />
      </div>
      <div>
        {modalData.length !== 0 ? (
          <Modal
            title={`${moment(modalDate.valueOf()).format("DD:MM:YYYY")}`}
            centered
            visible={modal}
            onCancel={() => {
              setModal(modal);
              setModalData([]);
            }}
            width="75%"
            footer={null}
            className="general-modal-popup"
            style={{
              marginTop: "50px",
            }}
          >
            {modalData.map((val) => (
              <Card
                style={{
                  marginTop: "20px",
                }}
              >
                <div className="modalContent">
                  <div className="title-modal">
                    <Row style={{ display: "flex", flex: 1 }}>
                      <Col span={5}>
                        <span>Title</span>
                      </Col>
                      <Col span={15}>
                        <span>{val.heading}</span>
                      </Col>
                    </Row>
                  </div>
                  {val.project ? (
                    <div className="end-modal">
                      <Row style={{ display: "flex", flex: 1 }}>
                        <Col span={5}>
                          <span>Project</span>
                        </Col>
                        <Col span={15}>
                          <span>{val.project}</span>
                        </Col>
                      </Row>
                    </div>
                  ) : null}
                  {val.details ? (
                    <div className="end-modal">
                      <Row style={{ display: "flex", flex: 1 }}>
                        <Col span={5}>
                          <span>Details</span>
                        </Col>

                        <Col span={15}>
                          {val.details.map((v) => {
                            return (
                              <span style={{ color: "#ffffff69" }}>
                                {v}
                                <br />
                              </span>
                            );
                          })}
                        </Col>
                      </Row>
                    </div>
                  ) : null}
                  {val.machine ? (
                    <div className="end-modal">
                      <Row style={{ display: "flex", flex: 1 }}>
                        <Col span={5}>
                          <span>Machine</span>
                        </Col>
                        <Col span={15}>
                          <span>{val.machine}</span>
                        </Col>
                      </Row>
                    </div>
                  ) : null}
                  <div className="end-modal">
                    <Row style={{ display: "flex", flex: 1 }}>
                      <Col span={5}>
                        <span>
                          {val.type === "availability" ? "Available" : "Used"}
                        </span>
                      </Col>
                      <Col span={15}>
                        <span>{val.count}</span>
                      </Col>
                    </Row>
                  </div>
                  <div className="end-modal">
                    <Row style={{ display: "flex", flex: 1 }}>
                      <Col span={5}>
                        <span>Start Time</span>
                      </Col>
                      <Col span={15}>
                        <span>
                          {moment(val.start.valueOf()).format(
                            "YYYY-MM-DD HH:mm",
                          )}
                        </span>
                      </Col>
                    </Row>
                  </div>
                  <div className="start-modal">
                    <Row style={{ display: "flex", flex: 1 }}>
                      <Col span={5}>
                        <span>End Time</span>
                      </Col>
                      <Col span={15}>
                        <span>
                          {moment(val.end.valueOf()).format("YYYY-MM-DD HH:mm")}
                        </span>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>
            ))}
          </Modal>
        ) : null}
      </div>
    </div>
  );
};

export default CalComponent;
