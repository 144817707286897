import React, { FC } from "react";
import { Layout, Spin, Tag } from "antd";
import { SiderIPS, HeaderIPS } from "../../components";

const { Content } = Layout;

const ConfigureAlert: FC = () => {
  return (
    <Layout>
      {/* <HeaderIPS /> */}
      <Layout>
        <SiderIPS selectedKey={["26"]} openKeys={["sub1"]} />
        <Content className="site-layout-background">
          <div className="content-container">
            <div className="content-header-container">
              <h2 className="h2-user-management">Edit Profile</h2>
            </div>
            <div>
              <div className="user-content-alert"></div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default ConfigureAlert;
