import React, { FC, useEffect, useState } from "react";
import { Space, Table } from "antd";
import moment from "moment";
import { Container } from "../../constants/constants";
import {
  MinusOutlined,
  PlusOutlined,
  DownOutlined,
  UpOutlined,
  RightOutlined,
} from "@ant-design/icons";
import "./resourceTable.scss";

const ResourceTable: FC<{data: any; rawMaterial: any,projectStatus?:string  }> = (props: {
  data: any;
  rawMaterial: any;
  projectStatus?:string;
}) => {
  const [resourceTableData, setResourceTableData] = useState<any>([]);
  const [expandedKey, setExpandedKey] = useState<number[]>([]);
  const [expandedLabourKey, setExpandedLabourKey] = useState<number[]>([]);
  const [expandedBobbinKey, setExpandedBobbinKey] = useState<number[]>([]);

  const expandedRowRenderRawMaterial = (record: any) => {
    const subColumns = [
      // define columns for sub-rows if needed
      {
        title: "Raw Material",
        width: "40.6%",
        // render: (text: any, record: any) => (
        //   <Space size="middle">{record?.name}</Space>
        // ),
      },
      {
        title: "Amount",
        width: "32.1%",
        render: (text: any, record: any) => {
          return (
            <Space size="middle">
              {Math.round(record?.Amount * 100) / 100}
              {record?.unit}
            </Space>
          );
        },
      },
      {
        title: "Date",
        width: "29.7%",
        render: (text: any, record: any) => (
          <Space size="middle">
            {moment.unix(record?.Date).format("DD:MM:YYYY, hh:mm:ss a")}
          </Space>
        ),
      },
      {
        title: "Status",
        width: "28%",
        render: (text: any, record: any) => (
          <Space size="middle" style={{ color: "rgba(255, 255, 255, 0.5)" }}>
          Completed
        </Space>
        ),
      },
    ];
    return (
      <Table
        //   rowClassName={() => "rowClassName-demo"}
        columns={subColumns}
        dataSource={record.subRows}
        pagination={false}
        showHeader={false}
        // size= "middle"
      />
    );
  };

  const expandedLabour = (record: any) => {
    // console.log("---- resource requirements --------------", record);
    const subColumns = [
      {
        title: "Machine",
        width: "20.57%",
        // render: (text: any, record: any) => (
        //   <Space size="middle" style={{ color: "rgba(255, 255, 255, 0.5)" }}>
        //     Completed
        //   </Space>
        // ),
      },
      {
        title: "Operator Count",
        width: "18.11%",
        render: (text: any, record: any) => (
          <Space size="middle">{record?.Labour?.operators}</Space>
        ),
      },
      {
        title: "Helper Count",
        width: "13.53%",
        render: (text: any, record: any) => (
          <Space size="middle">
            <Space size="middle">{record?.Labour?.helpers}</Space>
          </Space>
        ),
      },
      {
        title: "From",
        width: "17.93%",
        render: (text: any, record: any) => {
          // console.log("---- resource requirements --------------");
          // console.log(record);
          return (
            <Space size="middle">
              {moment
                .unix(record?.Labour?.startTime)
                .format("DD:MM:YYYY, hh:mm:ss a")}
            </Space>
          );
        },
      },
      {
        title: "To",
        width: "27.55%",
        render: (text: any, record: any) => (
          <Space size="middle">
            {moment
              .unix(record?.Labour?.endTime)
              .format("DD:MM:YYYY, hh:mm:ss a")}
          </Space>
        ),
      },
      {
        title: "Status",
        width: "27%",
        render: (text: any, record: any) => (
          <Space size="middle" style={{ color: "rgba(255, 255, 255, 0.5)" }}>
            Completed
          </Space>
        ),
      },
    ];
    return (
      <Table
        rowClassName={() => "rowClassName-demo"}
        columns={subColumns}
        dataSource={record?.Labour?.subRows}
        pagination={false}
        showHeader={false}
      />
    );
  };

  const expandedContainer = (record: any) => {
    console.log("---- resource requirements --------------", record);
    const subColumns = [
      // define columns for sub-rows if needed
      {
        title: `${Container.NAME}s`,
        width: "41.18%",
        // render: (text: any, record: any) => (
        //   <Space size="middle" style={{ color: "rgba(255, 255, 255, 0.5)" }}>
        //     Completed
        //   </Space>
        // ),
      },
      {
        title: "Amount",
        width: "13.36%",
        render: (text: any, record: any) => (
          <Space size="middle">{record?.Bobbin?.bobbinCount}</Space>
        ),
      },
      {
        title: "From",
        width: "18.18%",
        render: (text: any, record: any) => (
          <Space size="middle">
            {moment
              .unix(record?.Bobbin?.startTime)
              .format("DD:MM:YYYY, hh:mm:ss a")}
          </Space>
        ),
      },
      {
        title: "To",
        width: "27.27%",
        render: (text: any, record: any) => (
          <Space size="middle">
            {moment
              .unix(record?.Bobbin?.endTime)
              .format("DD:MM:YYYY, hh:mm:ss a")}
          </Space>
        ),
      },
      {
        title: "Status",
        width: "28%",
        render: (text: any, record: any) => (
          <Space size="middle" style={{ color: "rgba(255, 255, 255, 0.5)" }}>
            Completed
          </Space>
        ),
      },
    ];
    return (
      <Table
        rowClassName={() => "rowClassName-demo"}
        columns={subColumns}
        dataSource={record?.Bobbin?.subRows}
        pagination={false}
        showHeader={false}
        // size= "middle"
      />
    );
  };

  const expandRawIcon = ({ expanded, onExpand, record }: any) => {
    const subRowsLength = record.subRows.length;
    return subRowsLength > 0 ? (
      <span onClick={(e) => onExpand(record, e)} style={{ float: "left" }}>
        {expanded ? <DownOutlined /> : <RightOutlined />}
      </span>
    ) : null;
  };

  const expandLabourIcon = ({ expanded, onExpand, record }: any) => {
    console.log("record.Labour.subRows.length", props.data);
    const subRowsLength = record.Labour.subRows.length;
    return subRowsLength > 0 ? (
      <span onClick={(e) => onExpand(record, e)} style={{ float: "left" }}>
        {expanded ? <DownOutlined /> : <RightOutlined />}
      </span>
    ) : null;
  };

  const expandBobbinIcon = ({ expanded, onExpand, record }: any) => {
    const subRowsLength = record.Bobbin.subRows.length;
    return subRowsLength > 0 ? (
      <span onClick={(e) => onExpand(record, e)} style={{ float: "left" }}>
        {expanded ? <DownOutlined /> : <RightOutlined />}
      </span>
    ) : null;
  };

  const columnsLabour: any = [
    {
      title: "Machine",
      width: "20.57%",
      render: (text: any, record: any) => (
        <Space size="middle">
          <Space size="middle">{record?.Labour?.machineName}</Space>
        </Space>
      ),
    },
    {
      title: "Operator Count",
      width: "18.11%",
      render: (text: any, record: any) => (
        <Space size="middle">{record?.Labour?.operators}</Space>
      ),
    },
    {
      title: "Helper Count",
      width: "13.53%",
      render: (text: any, record: any) => (
        <Space size="middle">
          <Space size="middle">{record?.Labour?.helpers}</Space>
        </Space>
      ),
    },
    {
      title: "From",
      width: "17.93%",
      render: (text: any, record: any) => {
        // console.log("---- resource requirements --------------");
        // console.log(record);
        return (
          <Space size="middle">
            {record.Labour.startTime
              ? moment
                  .unix(record?.Labour?.startTime)
                  .format("DD:MM:YYYY, hh:mm:ss a")
              : "-"}
          </Space>
        );
      },
    },
    {
      title: "To",
      width: "27.55%",
      render: (text: any, record: any) => (
        <Space size="middle">
          {record.Labour.endTime
            ? moment
                .unix(record?.Labour?.endTime)
                .format("DD:MM:YYYY, hh:mm:ss a")
            : "-"}
        </Space>
      ),
    },
    {
      title: "Status",
      width: "28%",
      render: (text: any, record: any) => (
        <Space size="middle">
          {record?.Labour?.status}
        </Space>
      ),
    },
  ];

  const columnsContainer: any = [
    {
      title: `${Container.NAME}s`,
      width: "39.18%",
      render: (text: any, record: any) => (
        <Space size="middle">{record?.Bobbin?.containerName}</Space>
      ),
    },
    {
      title: "Amount",
      width: "12.36%",
      render: (text: any, record: any) => (
        <Space size="middle">{record?.Bobbin?.bobbinCount}</Space>
      ),
    },
    {
      title: "From",
      width: "17.18%",
      render: (text: any, record: any) => (
        <Space size="middle">
          {record.Bobbin.startTime
            ? moment
                .unix(record?.Bobbin?.startTime)
                .format("DD:MM:YYYY, hh:mm:ss a")
            : "-"}
        </Space>
      ),
    },
    {
      title: "To",
      width: "27.27%",
      render: (text: any, record: any) => (
        <Space size="middle">
          {record?.Bobbin?.endTime
            ? moment
                .unix(record?.Bobbin?.endTime)
                .format("DD:MM:YYYY, hh:mm:ss a")
            : "-"}
        </Space>
      ),
    },
    {
      title: "Status",
      width: "28%",
      render: (text: any, record: any) => (
        <Space size="middle">
          {record?.Bobbin?.status}
        </Space>
      ),
    },
  ];

  const columnsRawMaterial: any = [
    //722
    {
      title: "Raw Material",
      width: "40.6%",
      render: (text: any, record: any) => (
        <Space size="middle">{record?.name}</Space>
      ),
    },
    {
      title: "Amount",
      width: "32.1%",
      render: (text: any, record: any) => {
        return (
          <Space size="middle">
            {Math.round(record?.Amount * 100) / 100}
            {record?.unit}
          </Space>
        );
      },
    },
    {
      title: "Date",
      width: "29.7%",
      render: (text: any, record: any) => (
        <Space size="middle">
          {record.Date
            ? moment.unix(record?.Date).format("DD:MM:YYYY, hh:mm:ss a")
            : "-"}
        </Space>
      ),
    },
    {
      title: "Status",
      width: "28%",
      render: (text: any, record: any) => (
        <Space size="middle">
          {record.status}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    let rawMaterial;
    console.log("check-raw-material", props.rawMaterial);
    if (Object.keys(props.rawMaterial)[0].includes("input")) {
      rawMaterial = props?.rawMaterial ? [JSON.parse(JSON.stringify(props?.rawMaterial))] : {};
      console.log("check-raw-material-1 ", Object.values(rawMaterial));
    } else {
      rawMaterial = props?.rawMaterial ?  JSON.parse(JSON.stringify(props?.rawMaterial)) : {};
      console.log("check-raw-material-2 ", rawMaterial);
    }

    let rawMaterialValues;
    if (Object.keys(rawMaterial[0]).length !== 0) {
      for (let key in rawMaterial[0]) {
        if (rawMaterial[0].hasOwnProperty(key)) {
          rawMaterial[0][key]["status"] = props.projectStatus
          rawMaterial[0][key]["subRows"] =
            rawMaterial[1] && rawMaterial[1][key] ? rawMaterial[1][key] : [];
        }
      }
      rawMaterialValues = Object.values(rawMaterial[0]);
    }
      let completeMaterial = [];
      for (let key in rawMaterial[1]) {
        if (rawMaterial[1].hasOwnProperty(key) && !rawMaterial[0].hasOwnProperty(key)) {
          console.log("check-raw-material-4", rawMaterial[1]);
          let sortRawArray = rawMaterial[1][key].sort(
            (a: { Date: number }, b: { Date: number }) => b.Date - a.Date,
          );
          let headerRaw = sortRawArray?.shift();
          headerRaw["subRows"] = sortRawArray.length > 0 ? sortRawArray : [];
          headerRaw["status"] = "Completed";
          completeMaterial.push(headerRaw);
        }
      }
      console.log("check-raw-material-4", rawMaterialValues,completeMaterial);
    setResourceTableData(completeMaterial.concat(rawMaterialValues ?? []));
    
  }, []);

  useEffect(() => {
    console.log("expandedKey", expandedKey);
  }, [expandedKey]);

  useEffect(() => {
    console.log(
      props,
      "------ resource table data --------------- ",
      resourceTableData,
    );
  }, [resourceTableData]);

  return (
    <div className="general-table">
      <Table
        className="components-table-demo-nested"
        columns={columnsRawMaterial}
        dataSource={resourceTableData}
        expandable={{
          expandedRowRender: expandedRowRenderRawMaterial,
          expandIcon: expandRawIcon,
          onExpand: (expanded, { id }) => {
            expanded ? setExpandedKey([id]) : setExpandedKey([]);
          },
          expandedRowKeys: expandedKey,
        }}
        rowKey={(record) => record.id}
        rowClassName={(record) =>
          expandedKey[0] === record.id ? "expand-parent" : "ant-table-tbody"
        }
        pagination={false}
        style={{ marginTop: 10, marginBottom: 10 }}
      />
      <Table
        className="components-table-demo-nested"
        columns={columnsLabour}
        dataSource={props.data}
        expandable={{
          expandedRowRender: expandedLabour,
          expandIcon: expandLabourIcon,
          onExpand: (expanded, { Labour }) => {
            expanded
              ? setExpandedLabourKey([Labour?.id])
              : setExpandedLabourKey([]);
          },
          expandedRowKeys: expandedLabourKey,
        }}
        rowKey={(record) => record.Labour.id}
        rowClassName={(record) =>
          expandedLabourKey[0] === record.Labour.id
            ? "expand-parent"
            : "ant-table-tbody"
        }
        pagination={false}
        style={{ marginTop: 10, marginBottom: 10 }}
      />
      <Table
        className="components-table-demo-nested"
        columns={columnsContainer}
        dataSource={props.data}
        expandable={{
          expandedRowRender: expandedContainer,
          expandIcon: expandBobbinIcon,
          onExpand: (expanded, { Bobbin }) => {
            expanded
              ? setExpandedBobbinKey([Bobbin?.id])
              : setExpandedBobbinKey([]);
          },
          expandedRowKeys: expandedBobbinKey,
        }}
        rowKey={(record) => record.Bobbin.id}
        rowClassName={(record) =>
          expandedBobbinKey[0] === record.Bobbin.id
            ? "expand-parent"
            : "ant-table-tbody"
        }
        pagination={false}
        style={{ marginTop: 10, marginBottom: 10 }}
      />
    </div>
  );
};

export default ResourceTable;
