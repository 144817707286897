import React, { FC, useEffect, useMemo, useState } from "react";
import {
  OnItemDragObjectMove,
  OnItemDragObjectResize,
  ReactCalendarGroupRendererProps,
  ReactCalendarItemRendererProps,
  TimelineGroupBase,
  TimelineItemBase,
} from "react-calendar-timeline";
import { Alert, Button, Col, message, Modal, Row, Space, Spin } from "antd";
import { CaretRightOutlined, DownOutlined } from "@ant-design/icons";
import moment from "moment";
import TimelineGantt from "../../components/GanttChart/TimelineGantt";
import useProjectSchedule from "./ScheduleContext";
import ManageHolidays from "./ManageHodidays";
import MainLayout from "../../components/MainLayout";
import { useConnection } from "../../Services/ApplicationContext";

type Id = number | string;
const GantChart: FC = () => {
  const {
    timelines,
    groups,
    setGroups,
    openGroups,
    setOpenGroup,
    setTimelines,
    holidays,
    setHolidays,
  } = useProjectSchedule();

  // const onItemDrag = (
  //   itemDragObject: OnItemDragObjectMove | OnItemDragObjectResize,
  // ): void => {
  //   console.log("on badu drag , ", itemDragObject);
  // };

  const onItemMove = (
    itemId: Id,
    dragTime: number,
    newGroupOrder: number,
  ): void => {
    console.log(
      "*** move ",
      itemId,
      " drag time ",
      dragTime,
      "new group ",
      newGroupOrder,
    );

    const group = groups[newGroupOrder];
    const tlinses = timelines?.slice();
    const temp = tlinses.map((item: TimelineItemBase<any>) =>
      item.id === itemId
        ? {
            ...item,
            start_time: dragTime,
            end_time: dragTime + (item.end_time - item.start_time),
            group: group.id,
          }
        : item,
    );
    setTimelines(temp);
  };
  const onItemResize = (
    itemId: Id,
    endTimeOrStartTime: number,
    edge: "left" | "right",
  ): void => {
    const tlinses = timelines?.slice();
    const temp = tlinses.map((item: TimelineItemBase<any>) =>
      item.id === itemId
        ? {
            ...item,
            start_time: edge === "left" ? endTimeOrStartTime : item.start_time,
            end_time: edge === "left" ? item.end_time : endTimeOrStartTime,
          }
        : item,
    );
    setTimelines(temp);
    console.log(
      "*** resize ",
      itemId,
      " end time ",
      endTimeOrStartTime,
      "edge  ",
      edge,
    );
  };
  const changeAlphaInRGBA = (val: string, alpha?: number) => {
    return val.replace(/[\d\.]+\)$/g, `${alpha ?? 1})`);
  };
  const itemRenderer = ({
    item,
    itemContext,
    getItemProps,
    getResizeProps,
  }: ReactCalendarItemRendererProps<any>) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    return (
      <div style={{ backgroundColor: "white" }}>
        <div {...getItemProps(item.itemProps)}>
          {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ""}

          <div
            className="timeline-item"
            style={{
              maxHeight: `${itemContext.dimensions.height}`,
              width: "50%",
              paddingLeft: "5px",
              backgroundColor: item.itemProps?.style?.background
                ? changeAlphaInRGBA(item.itemProps?.style?.background)
                : "",
            }}
          >
            {itemContext.title}
          </div>

          {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ""}
        </div>
      </div>
    );
  };

  const toggleParent = (id: string) => {
    setOpenGroup({ ...openGroups, [id]: !openGroups[id] });
  };

  const groupRenderer = (val: ReactCalendarGroupRendererProps<any>) => {
    const { group: grp } = val;
    const className = grp.id.includes("sub") ? "highlight" : "";
    return (
      <div className={className}>
        <Row>
          {grp.id.includes("main") ? (
            <Col onClick={() => toggleParent(grp.id)}>
              <CaretRightOutlined
                style={{ fontSize: "18px", marginRight: "8px" }}
              />
              {grp.title}
            </Col>
          ) : (
            <Col>
              <p style={{ marginLeft: "25px" }}>{grp.title}</p>
            </Col>
          )}
        </Row>
      </div>
    );
  };
  const generateNextId = (str: string, chr: string): string => {
    const index = str.indexOf("-") + 1;
    if (index > str.length - 1) {
      return str;
    }
    return `${str.substring(0, index)}${
      Number(str.charAt(index)) + 1
    }${str.substring(index + 1)}`;
  };
  const onItemContextMenu = (
    itemId: Id,
    e: React.SyntheticEvent,
    time: number,
  ): void => {
    Modal.confirm({
      type: "warning",
      cancelButtonProps: { type: "primary" },
      okButtonProps: { type: "primary", danger: true },
      title: "Warning!",
      content: "Are you sure to split the timeline?",
      onOk: () => {
        const temp = [...timelines];

        const index = temp.findIndex((val) => val.id === itemId);
        if (index > -1) {
          const dup = { ...temp[index] };
          const ori = { ...temp[index] };
          dup.id = generateNextId(itemId.toString(), "-");
          dup.start_time = moment(time + 1);
          dup.end_time = moment(ori.end_time + 1);
          ori.end_time = moment(time);
          console.log("orig ", ori, ori.id);
          console.log("dupp ", dup);
          temp[index] = ori;
          temp.push(dup);
          // temp.splice(index, 0, dup);
          setTimelines(temp);
        }
        // console.log("opoisd ", temp);
        // console.log("fusion ", itemId, " time ", moment(time));
      },
      onCancel: (args) => Modal.destroyAll(),
    });
  };
  const hdays = useMemo(() => {
    return holidays.map((val) => moment(val));
  }, [holidays]);

  const newGroups = useMemo(() => {
    const openList = Object.keys(openGroups);
    return groups?.filter((value: TimelineGroupBase) => {
      if (`${value.id}`.includes("main")) {
        return true;
      }

      let should;
      for (let i = 0; i < openList.length; i += 1) {
        if (openList[i] && openGroups[openList[i]]) {
          const grpNo = openList[i].split("#")[0];
          if (`${value.id}`.startsWith(grpNo)) {
            should = true;
            break;
          }
        }
      }
      if (should !== undefined) return true;
    });
  }, [openGroups, groups]);
  return (
    <div className="gannt-container">
      {timelines?.length > 0 && groups?.length > 0 ? (
        <TimelineGantt
          stackItems
          groups={newGroups}
          items={timelines}
          onItemMove={onItemMove}
          onItemResize={onItemResize}
          itemRenderer={itemRenderer}
          groupRenderer={groupRenderer}
          onItemContextMenu={onItemContextMenu}
          verticalLineClassNamesForTime={(
            timeStart,
            timeEnd,
          ): string[] | undefined => {
            const currentTimeStart = moment(timeStart);
            // const currentTimeEnd = moment(timeEnd);
            const yy = [];
            for (const holiday of hdays) {
              if (holiday.isSame(currentTimeStart, "day")) {
                yy.push("holiday");
              }
            }
            return yy;
          }}
          // onItemDrag={onItemDrag}
        />
      ) : (
        <Spin spinning />
      )}
    </div>
  );
};
const ProjectSchedule: FC = () => {
  const { post } = useConnection();
  const { holidays, setHolidays } = useProjectSchedule();
  const saveHolidays = async (val: string[]) => {
    const temp: { [key: string]: string[] } = {};
    for (let i = 0; i < val.length; i += 1) {
      const year = moment(val[i]).year();
      if (Object.prototype.hasOwnProperty.call(temp, year)) {
        temp[year].push(val[i]);
      } else {
        temp[year] = [val[i]];
      }
    }
    console.log("mapped holidays ", temp);
    const data = Object.keys(temp).map((item) => ({
      id: item,
      holidays: temp[item],
    }));
    try {
      const result = await post("production/createHolidays", { data });
      if (result.status === 200) {
        message.open({
          type: "success",
          content: result.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      } else {
        message.open({
          type: "warning",
          content: result.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      }
    } catch (e: any) {
      message.open({
        type: "error",
        content: e.message,
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
      // setLoading(false);
      console.log("error saving holidays ", e);
    }
  };
  const onChange = (val?: string[]) => {
    if (val && val.length > 0) {
      saveHolidays(val);
      setHolidays(val);
    }
  };
  return (
    <div>
      <ManageHolidays holidays={holidays} onClose={onChange} />
      <GantChart />
    </div>
  );
};
export default ProjectSchedule;
