import React, { FC } from "react";
import MainLayout from "../../components/MainLayout";
import ProjectsTable from "./ProjectsTable";

const Projects: FC = () => {
  return (
    <MainLayout
      type="primary"
      // title="Projects"
      OpenKey={["scheduling"]}
      selectedKey={["schedule"]}
    >
      <ProjectsTable />
    </MainLayout>
  );
};

export default Projects;
