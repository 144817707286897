import React, { useState } from "react";
import { Table } from "antd";
import "../tableStyles.scss";
import "../pagination.scss";
import "./table.scss";

function StatTable(props: any) {
  const { dataSet, onChange, paginationDetails } = props;

  const [editingKey, setEditingKey] = useState<any>("");

  const cancel = () => {
    setEditingKey("");
  };

  const columns: any = [
    {
      title: "",
      width: 100,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Running Time",
      width: 40,
      dataIndex: "totRunning",
      key: "1",
      align: "center",
    },
    {
      title: "Running Time - Office Hours",
      width: 40,
      dataIndex: "officeTime",
      key: "2",
      align: "center",
    },
    {
      title: "Running Time - Out of Office Hours",
      width: 40,
      dataIndex: "OutOfficeTime",
      key: "3",
      align: "center",
    },
    {
      title: "Idle Time",
      width: 40,
      dataIndex: "idle",
      key: "4",
      align: "center",
    },
    {
      title: "Unknown Time",
      width: 40,
      dataIndex: "unknown",
      key: "4",
      align: "center",
    },
  ];

  return (
    <div className="general-table overall-heatmap-table">
      <Table
        columns={columns}
        dataSource={dataSet}
        pagination={paginationDetails}
        onChange={onChange}
      />
    </div>
  );
}

export default StatTable;
