import React, {
  FC,
  useState,
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { Spin } from "antd";
import jwt_decode from "jwt-decode";
import { FeatureEndpointProperties } from "../definetions/UserModel";
import { useConnection } from "./ApplicationContext";
import { ChildrenProp } from "../definetions/common";
import { SERVICE_TYPE } from "../constants/constants";

export type FeatureProps = string[];
export type PermissionPerFeature = { [key: string]: boolean };
export interface AuthorizationContextProps {
  role?: string;
  roles: string[];
  canEdit: (feature: string, variableName?: string) => boolean;
  canEditMultipleCheck: (
    feature: FeatureProps,
    variableName: string,
  ) => PermissionPerFeature;
  permissions?: FeatureEndpointProperties;
  setPermissions: (val: FeatureEndpointProperties) => void;
  getRolePermissions: () => void;
  setRole: (val: string) => void;
  isAuthenticated: () => boolean;
}
type Props = { authZ?: AuthorizationContextProps };
const AuthorizationContext = createContext<Props>({});

export const AuthorizationContextProvider: FC<ChildrenProp> = ({
  children,
}: ChildrenProp) => {
  const [permissions, setPermissions] = useState<FeatureEndpointProperties>();
  const [role, setRole] = useState<string>();
  const [roles, setRoles] = useState<string[]>([]);
  const { get, token } = useConnection();

  const isAuthenticated = useCallback((): boolean => {
    let tokenX: string | null;
    if (token) {
      tokenX = token;
    } else {
      tokenX = localStorage.getItem("token");
    }
    try {
      if (tokenX) {
        const { exp } = jwt_decode(tokenX) as any;
        return Date.now() < exp * 1000;
      }
      return false;
    } catch (err) {
      return false;
    }
  }, [token]);

  const fetchAllRoles = async () => {
    try {
      const result = await get(
        "settings/authorization/getRoleList",
        undefined,
        SERVICE_TYPE.serviceType
      );
      if (result.statusText === "SUCCESS") {
        setRoles(result.data.roles);
      }
    } catch (e) {
      console.log("Error fetching roles ", e);
    }
  };
  const canEditMultipleCheck = (
    feature: FeatureProps,
    variableName: string,
  ) => {
    const result: PermissionPerFeature = {};
    if (permissions) {
      for (const x of feature) {
        const item = permissions.features.x;
        if (item) {
          if (item.allow.length > 0) {
            result.x =
              item.allow.findIndex((value) => value === variableName) > -1;
          }
          if (item.deny.length > 0) {
            result.x =
              item.deny.findIndex((value) => value === variableName) === -1;
          }
        }
      }
      return result;
    }
    return Object.fromEntries(feature.map((value) => [value, false]));
  };
  const canEdit = useCallback(
    (feature: string, variableName?: string) => {
      if (feature === "2") {
        console.log("!!~~~~~~ ", permissions?.features[`${feature}`]);
      }
      if (permissions) {
        const item = permissions.features[`${feature}`];
        if (item) {
          if (variableName) {
            if (item.allow.length > 0) {
              return (
                item.allow.findIndex((value) => value === variableName) > -1
              );
            }
            if (item.deny.length > 0) {
              return (
                item.deny.findIndex((value) => value === variableName) === -1
              );
            }
          } else {
            return true;
          }
        }
      }
      return false;
    },
    [permissions],
  );
  const getRolePermissions = async () => {
    const roleX = role ?? localStorage.getItem("userType");
    if (roleX) {
      try {
        const add = {
          uITerms: ["allow_route_dashboard"],
          featureName: "IOT Status",
          allow: [],
          deny: [],
        };
        const result = await get(
          `settings/authorization/getFeatureList/${roleX}`,
          undefined,
          SERVICE_TYPE.serviceType
        );
        const values = result.data;
        values.features["2"] = add;

        setPermissions(result.data);
      } catch (e) {
        console.log("Fetching role permissions ", e);
      }
    }
  };
  useEffect(() => {
    fetchAllRoles();
  }, []);
  useEffect(() => {
    getRolePermissions();
  }, [role]);
  return (
    <AuthorizationContext.Provider
      value={{
        authZ: {
          canEdit,
          canEditMultipleCheck,
          permissions,
          role,
          setPermissions,
          setRole,
          roles,
          isAuthenticated,
          getRolePermissions,
        },
      }}
    >
      {children}
    </AuthorizationContext.Provider>
  );
};
export const useAuthZ = (): AuthorizationContextProps => {
  return useContext<Props>(AuthorizationContext).authZ!;
};
export enum Actions {
  DISABLE = "DISABLE",
  HIDE = "HIDE",
}
export type AuthorizerDataProps = {
  feature: string;
  variableName?: string;
  action?: Actions;
  noStyle?: boolean;
};
export type AuthorizerProps = {
  children: ReactNode;
  data: AuthorizerDataProps;
};
export const Authorizer: FC<AuthorizerProps> = ({
  children,
  data,
}: AuthorizerProps) => {
  const { canEdit } = useAuthZ();
  const { feature, action, variableName, noStyle } = data;
  const auth = useMemo(() => canEdit(feature, variableName), [
    canEdit,
    feature,
    variableName,
  ]);
  switch (action) {
    case Actions.HIDE:
      if (noStyle && noStyle) {
        if (auth) {
          return <>{children}</>;
        }
        return null;
      }
      return (
        <Spin
          style={{
            display: "block",
          }}
          spinning={auth === undefined}
        >
          <div style={auth ? {} : { display: "none" }}>{children}</div>
        </Spin>
      );
    case Actions.DISABLE:
      return (
        <>
          <div
            style={
              auth
                ? {}
                : {
                    pointerEvents: "none",
                    opacity: "0.4",
                    cursor: "not-allowed",
                  }
            }
          >
            {children}
          </div>
        </>
      );
    default:
      return (
        <>
          <div
            style={
              auth
                ? {}
                : {
                    pointerEvents: "none",
                    opacity: "0.4",
                    cursor: "not-allowed",
                  }
            }
          >
            {children}
          </div>
        </>
      );
  }
};
