import React, { FC, useEffect, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  Select,
  Divider,
  Tag,
  message,
  Spin,
  Skeleton,
} from "antd";
import { useProject } from "./NewProjextContext";
import "../modalPopUp.scss";
import "./common.scss";
import "./containerSelection.scss";
import { useConnection } from "../../Services/ApplicationContext";
import { Container, SERVICE_TYPE } from "../../constants/constants";

export interface ContainerSelectionModalProps {
  visible: boolean;
  disable: boolean;
  nodeData: any;
  onClose: (val?: any) => void;
  taskId: any;
  cutLengthDisable: boolean
}

const ContainerSelectionModal: FC<ContainerSelectionModalProps> = (
  props: ContainerSelectionModalProps,
) => {
  const { put, post, get } = useConnection();

  const { visible, onClose, nodeData, disable, taskId,cutLengthDisable } = props;
  const [formTwo] = Form.useForm();
  const {
    containerArray,
    setContainerArray,
    nextOne,
    processTemplate,
    formData,
    startTime,
    setStartTime,
    currentFormOneData,
    setCurrentFormOneData,
  } = useProject();
  const { Option } = Select;
  const [machines, setMachines] = useState<any>([]);
  const [machinesUse, setMachinesUse] = useState<any>([]);
  const [containerName, setContainerName] = useState<string[]>([]);
  const [modalLoadingOne, setModalLoadingOne] = useState<boolean>(false);
  const [modalLoadingTwo, setModalLoadingTwo] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const optionsArray = [
    {
      label: "Consider",
      value: true,
    },
    {
      label: "Do not Consider",
      value: false,
    },
  ];

  useEffect(() => {
    if (nodeData.machines !== undefined) {
      arrangeMachines();
    }
  }, [nodeData, visible]);

  const arrangeMachines = () => {
    setModalLoadingOne(true);
    setModalLoadingTwo(true);
    const initMachines = nodeData?.machines.map((el: any) => {
      return {
        el,
        ...el.machineData,
        // machineName: el?.machineName,
        // taskGroup: el?.taskGroup,
      };
    });
    // const initMachines = [
    //   {
    //     taskGroup: nodeData?.taskGroup,
    //     machineName: nodeData.machineName,
    //     ...nodeData.machineData,
    //   },
    //   ...extraProcesses,
    // ];
    console.log(initMachines, "------------sss--------------");
    let temMachines = [...initMachines];

    // placing container names instead of ids in machine array

    temMachines?.map((machine: any, index: any) => {
      let tempMachinesLength: number = temMachines?.length;
      let inputContainerNames: any = [];
      let outputContainerNames: any = [];
      let sendingIdInputContainers: any[] = [];
      let sendingIdOutputContainers: any[] = [];
      let lastArrEle: boolean = false;

      if (index + 1 === tempMachinesLength) {
        lastArrEle = true;
        console.log("in the last ARr ele temMachines");
      }
      console.log(
        "machines container selection ------- > ",
        machine?.inputContainer,
        machine?.outputContainer,
      );
      machine?.inputContainer?.map((iCon: any, indexI: any) => {
        let sendingIConId: any;
        if (iCon === "N/A") {
          sendingIConId = iCon;
        } else {
          let sendingIdArr = iCon?.split("_");
          if (sendingIdArr?.length > 1) {
            sendingIConId = sendingIdArr[0];
          } else {
            sendingIConId = iCon;
          }
        }
        sendingIdInputContainers.push(sendingIConId);
      });

      getContainerNamesByIds(sendingIdInputContainers)
        .then((result: any) => {
          machine.inputContainer = result?.data?.resourceNames;
          console.log("result new api -----------> ", inputContainerNames);
        })
        .then((result: any) => {
          if (lastArrEle) {
            setModalLoadingOne(false);
          }
        });
      machine?.outputContainer?.map((oCon: any, indexO: any) => {
        let sendingOConId: any;
        if (oCon === "N/A") {
          sendingOConId = oCon;
        } else {
          let sendingIdArr = oCon?.split("_");
          if (sendingIdArr?.length > 1) {
            sendingOConId = sendingIdArr[0];
          } else {
            sendingOConId = oCon;
          }
        }
        sendingIdOutputContainers.push(sendingOConId);
      });
      getContainerNamesByIds(sendingIdOutputContainers)
        .then((result: any) => {
          machine.outputContainer = result?.data?.resourceNames;
          console.log("result new api -----------> ", outputContainerNames);
        })
        .then((result: any) => {
          if (lastArrEle) {
            setModalLoadingTwo(false);
            setModalLoading(true);
          }
        });
    });
    // end - placing container names instead of ids in machine array

    console.log(containerArray, "machines asdasd %%%% after api", temMachines);
    setMachines([...temMachines]);

    const initValues = temMachines.map((item: any, index: number) => ({
      ...item,
      key: index,
      name: index,
      consider: containerArray.find(
        (container: any) =>
          container.machineId == item.machineID && container.taskId === taskId,
      )
        ? containerArray.find(
            (container: any) =>
              container.machineId == item.machineID &&
              container.taskId === taskId,
          ).consider
        : true,
      ...containerArray.find(
        (container: any) =>
          container.machineId == item.machineID && container.taskId === taskId,
      ),
    }));
    console.log("initValues ------------------>", initValues);
    formTwo.setFieldsValue({
      field: initValues,
    });
  };

  useEffect(() => {
    console.log(nodeData, "nodeDatanodeData", taskId);
    if (modalLoading) {
      setMachinesUse([...machines]);
    }
  }, [machines, modalLoading]);

  const addContainers = (val: any) => {
    console.log("container selection logs = ", val);
    // consider validation check
    let doNotConsiderCount = 0;
    val.map((machine: any) => {
      if (machine?.cutLength) {
        machine.cutLength = machine.cutLength.trim();
      }
      if (machine?.cutLength) {
        machine.cutLength = machine.cutLength.trim();
      }
      if (machine.consider === false) {
        doNotConsiderCount += 1;
      }
    });
    if (machinesUse.length === doNotConsiderCount) {
      message.open({
        type: "error",
        content: "Please choose minimum one machine for consider",
        duration: 2,
      });
    } else {
      console.log(val, "--------------val--------------");
      val.map((machine: any) => {
        const existingMachine = containerArray.find(
          (item: any) =>
            item.taskId === taskId &&
            item.machineId.toString() === machine?.machineID.toString(),
        );
        console.log("machine ---------------- > ", machine);
        let finalObj: any = {};
        // loop elements of the array
        for (let i = 0; i < machine.el.parallelConcurrentInput.length; i++) {
          finalObj[
            machine.el.parallelConcurrentInput[i].inputItemId.toString()
          ] = machine.el.parallelConcurrentInput[i].parallelConcurrentInput;
        }
        console.log("finalObj ---------------- > ", finalObj);

        const newMachine = {
          speed: machine.el.speed,
          machineId: machine.machineID,
          taskId,
          container: machine.container,
          cutLength: machine.cutLength,
          consider: machine.consider,
          taskOrder: "0",
          concurrentOutput: machine.el.concurrentOutput,
          concurrentInput: finalObj,
          taskType: nodeData.taskType,
        };
        if (existingMachine) {
          const index = containerArray.indexOf(existingMachine);
          containerArray[index] = newMachine;
          console.log(newMachine, "--------------edit--------------");
        } else {
          containerArray.push(newMachine);
          console.log(newMachine, "--------------add--------------");
        }
      });
      console.log('containerArrayList', containerArray)
      setContainerArray([...containerArray]);
      onClose();
    }
  };

  const getContainerNamesByIds = async (ids: any[]) => {
    try {
      const result = await post("system-management/resources/getResourcesByArraysOfId", {
        resourceByArrayOfId: [...ids],
      }, undefined,
      SERVICE_TYPE.serviceType
      );
      if (result?.message === "success") {
        // console.log("getting id   ============== ", result);
        if (result?.data?.resourceNames) {
          return result;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error: any) {
      console.log("container name from id --- ", error);
      return null;
    }
  };

  return (
    <Modal
      centered
      width="60%"
      title={`${Container.NAME} Selection`}
      visible={visible}
      className="general-modal-popup"
      onCancel={() => {
        onClose(false);
        formTwo.resetFields();
      }}
      footer={[
        <div className="modal-field-button">
          <Button
            type="primary"
            danger
            onClick={() => {
              onClose(false);
              formTwo.resetFields();
            }}
          >
            Close
          </Button>
          <Button
            type="primary"
            onClick={() => {
              formTwo.submit();
            }}
          >
            Done
          </Button>
        </div>,
      ]}
    >
      <div
        style={{
          maxHeight: "70vh",
          overflowY: "auto",
          overflowX: "hidden",
          padding: 5,
        }}
      >
        {modalLoading !== true && modalLoadingTwo === true ? (
          <Skeleton active paragraph={{ rows: 5 }} round />
        ) : (
          <Form
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 15, flex: 1 }}
            layout="horizontal"
            form={formTwo}
            name="task"
            onFinish={(values: any) => {
              addContainers(values?.field);
            }}
            scrollToFirstError
            autoComplete="off"
          >
            <Form.List name="field">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {machinesUse.map((field: any, index: any) => (
                      <div key={field.key}>
                        <Divider orientation="center">
                          Machine :{field.machineName}
                        </Divider>
                        {modalLoading === true && modalLoadingOne === false ? (
                          <Form.Item label={`Input ${Container.NAME}`}>
                            <div
                              style={{
                                // marginBottom: 10,
                                color: "rgba(255, 255, 255, 0.5)",
                                fontFamily: "Poppins",
                                fontSize: "15px",
                              }}
                            >
                              {field?.inputContainer?.map((item: any) => (
                                <Tag
                                  color="green"
                                  style={{
                                    fontFamily: "Poppins",
                                    marginBottom: "15px",
                                  }}
                                >
                                  {item}
                                  {/* {getContainerNameById(String(item))} */}
                                </Tag>
                              ))}
                            </div>
                          </Form.Item>
                        ) : (
                          <Skeleton active paragraph={{ rows: 1 }} round />
                        )}
                        {field.taskGroup === "Cure" ||
                        field.taskGroup === "Annealing" ? (
                          <Form.Item
                            label={`Number of Input ${Container.NAME}s`}
                            name={[index, "cutLength"]}
                            rules={[
                              {
                                required: true,
                                message: "",
                              },
                              {
                                validator: async (rule, value) => {
                                  if (
                                    String(value).trim() === "" ||
                                    value === null ||
                                    value === undefined
                                  ) {
                                    throw new Error("Required field!");
                                  } else if (
                                    String(Number(String(value).trim())) ===
                                    "NaN"
                                  ) {
                                    throw new Error("Invalid value!");
                                  } else if (
                                    String(
                                      Number(String(value).trim()),
                                    ).includes(".")
                                  ) {
                                    throw new Error(
                                      `Number of Input ${Container.NAME}s can't be a double value!`,
                                    );
                                  } else if (value <= 0) {
                                    throw new Error(
                                      `Number of Input ${Container.NAME}s can't be less than or equal to zero`,
                                    );
                                  }
                                },
                              },
                            ]}
                          >
                            <Input
                              name="cutLength"
                              placeholder={`Number of Input ${Container.NAME}s`}
                              disabled={disable}
                            />
                          </Form.Item>
                        ) : (
                          <Form.Item
                            label="Cut Length"
                            name={[index, "cutLength"]}
                            rules={[
                              {
                                required: true,
                                message: "",
                              },
                              // {
                              //   pattern: new RegExp(/^[0-9]*$/g),
                              //   message: "Cut Length should be an integer value!",
                              // },
                              {
                                validator: async (rule, value) => {
                                  if (
                                    String(value).trim() === "" ||
                                    value === null ||
                                    value === undefined
                                  ) {
                                    throw new Error("Required field!");
                                  } else if (
                                    String(Number(String(value).trim())) ===
                                    "NaN"
                                  ) {
                                    throw new Error("Cut Length should be an integer value!");
                                  } else if (value <= 0 && String(value).trim() !== "") {
                                    throw new Error(
                                      "Cut Length can't be less than or equal to zero!",
                                    );
                                  }
                                },
                              },
                            ]}
                          >
                            <Input
                              name="cutLength"
                              addonAfter="m"
                              placeholder="Ex: 12"
                              disabled={cutLengthDisable}
                            />
                          </Form.Item>
                        )}
                        <Form.Item
                          label={`Output ${Container.NAME}`}
                          name={[index, "container"]}
                          rules={[
                            {
                              required: true,
                              message: "Required field!",
                            },
                          ]}
                        >
                          <Select
                            disabled={disable || modalLoadingTwo}
                            loading={modalLoadingTwo}
                            placeholder={`Output ${Container.NAME}`}
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentElement
                            }
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {field?.outputContainer?.map((item: any) => (
                              <Option value={item}>{item}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label="For scheduling"
                          name={[index, "consider"]}
                          rules={[
                            {
                              required: true,
                              message: "Required field!",
                            },
                          ]}
                          initialValue={true}
                        >
                          <Select
                            disabled={disable || machinesUse.length === 1}
                          >
                            {optionsArray.map((item: any) => (
                              <Option value={item.value}>{item.label}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          hidden
                          label="machineID"
                          name={[index, "machineID"]}
                        >
                          <Input />
                        </Form.Item>
                      </div>
                    ))}
                    <Divider />
                  </div>
                );
              }}
            </Form.List>
          </Form>
        )}
      </div>
    </Modal>
  );
};
export default ContainerSelectionModal;
