/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useState, useEffect, FC } from "react";
import {
  Table,
  Popconfirm,
  Space,
  Button,
  message,
  Skeleton,
  Input,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "./Styles.scss";
import "../tableStyles.scss";
import "../pagination.scss";
import { TablePaginationConfig } from "antd/es/table/Table";
import {
  IoCreateOutline,
  IoSearch,
  IoTrashSharp,
  IoWarning,
} from "react-icons/io5";
import { useConnection } from "../../Services/ApplicationContext";
import UpdateLocation, { LocationDetails } from "./UpdateLocation";
import { Actions, Authorizer } from "../../Services/AuthorizationContext";
import {
  Plant,
  PlantDescription,
  PlantName,
} from "../../definetions/Constatnts";
import { DateTime } from "luxon/src/luxon";
import { SERVICE_TYPE } from "../../constants/constants";

interface Pagination {
  currentPage: number;
  pageSize: number;
}

interface Errors {
  resourceId: number;
  resourceType: string;
  resourceName: string;
}

const LocationTable: FC = () => {
  const glbPageSize = 10;

  const { get, delete: del, post } = useConnection();

  const [selectedRow, setSelectedRow] = useState();
  const [data, setData] = useState<LocationDetails[]>([]);
  const [searchData, setSearchData] = useState<LocationDetails[]>([]);
  const [tableSource, setTableSource] = useState<LocationDetails[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const initialValue = {
    currentPage: 1,
    pageSize: 10,
  };
  const [pagination, setPagination] = useState<Pagination>(initialValue);
  const [sortOrder, setSortOrder] = useState<string>("");
  const [sortField, setSortField] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [editingKey, setEditingKey] = useState("");
  const [
    addLocationModalVisible,
    setAddLocationModalVisible,
  ] = useState<boolean>(false);

  const getAllPlantsRequestParams = () => {
    return {
      page: pagination.currentPage - 1,
      size: pagination.pageSize,
      searchValue:
        searchValue !== "" ? searchValue.replace(/^\s+|\s+$/g, "") : undefined,
      orderBy: sortField !== "" ? sortField : undefined,
      order: sortOrder !== "" ? sortOrder : undefined,
    };
  };

  const getData = async () => {
    setLoading(true);
    try {
      const result = await post(
        "system-management/location/getLocationsList",
        getAllPlantsRequestParams(),
        undefined,
        SERVICE_TYPE.serviceType
      );
      setData(result.data.list);
      setTotal(result.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [pagination, sortOrder, sortField]);

  const edit = (record: any) => {
    setSelectedRow(record);
  };

  const Delete = async (record: LocationDetails) => {
    setLoading(true);
    try {
      const result = await del(
        `system-management/location/deleteLocation/${record.locationID}`,
        undefined,
        SERVICE_TYPE.serviceType
      );
      if (result.statusText === "SUCCESS") {
        message.open({
          type: "success",
          content: result.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
        // getData();
        const temp = [...data];
        const newData = temp.filter(
          (value) => value.locationID !== record.locationID,
        );
        setData(newData);
        setLoading(false);
      }
    } catch (error: any) {
      error.errors.map((v: Errors) => {
        message.open({
          type: "error",
          content: `Delete Terminated! ${v.resourceName} added to this plant.`,
          duration: 3,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      });
      setLoading(false);
    }
  };

  const columns: any = [
    {
      title: PlantName,
      dataIndex: "locationName",
      key: "locationName",
      sorter: true,
      width: "25%",
    },
    {
      title: "Last Updated On",
      key: "CreatedAt",
      width: "30%",
      sorter: true,
      render: (_: any, record: any) => {
        return DateTime.fromMillis(record.lastUpdated).toFormat(
          "dd:MM:yyyy,  hh:mm:ss a",
        );
      },
    },
    {
      title: PlantDescription,
      dataIndex: "locationDescription",
      key: "locationDescription",
    },
    {
      title: "Action",
      dataIndex: "operation",
      // fixed: "right",
      render: (_: any, record: any) => {
        return (
          <Space size="middle">
            {/* {console.log(record, "uuuuuuuuuuuu")} */}
            <div>
              <Authorizer data={{ feature: "30", action: Actions.HIDE }}>
                <a
                  aria-disabled={editingKey !== ""}
                  onClick={() => edit(record)}
                >
                  <IoCreateOutline size={22} color="#487199" />
                </a>
              </Authorizer>
            </div>
            <div>
              <Authorizer data={{ feature: "31", action: Actions.HIDE }}>
                <Popconfirm
                  title={`Are you sure to delete ${record.locationName}?`}
                  onConfirm={() => Delete(record)}
                  okText="Yes"
                  okButtonProps={{ danger: true }}
                  icon={<IoWarning size={30} color="#eec335" />}
                  placement="topLeft"
                  cancelText="No"
                  cancelButtonProps={{ type: "primary" }}
                >
                  <a>
                    <IoTrashSharp size={20} color="#f18b8b" />
                  </a>
                </Popconfirm>
              </Authorizer>
            </div>
          </Space>
        );
      },
    },
  ];

  const onModalClose = (add: "add" | "edit", val?: LocationDetails) => {
    setLoading(true);
    if (val) {
      const temp = [...data];
      const index = temp.findIndex(
        (item: LocationDetails) => val.locationID === item.locationID,
      );
      if (add === "add") {
        temp.push({ ...val });
      }
      if (add === "edit") {
        if (index > -1) {
          temp.splice(index, 1, val);
        }
      }
      setData(temp);
    }
    setSelectedRow(undefined);
    setAddLocationModalVisible(false);
    setLoading(false);
  };

  const searchByName = (val: string) => {
    const currValue = val;
    setSearchValue(currValue);
    setPagination({
      currentPage: 1,
      pageSize: 10,
    });
  };

  const handleChange = (nav: any, sorter: any) => {
    if (sorter.order === "ascend") {
      setSortOrder("asc");
    } else if (sorter.order === "descend") {
      setSortOrder("desc");
    } else if (sorter.order === undefined) {
      setSortOrder("");
    }
    if (sorter.columnKey !== undefined) {
      setSortField(sorter.columnKey);
    } else {
      setSortField("");
    }
    setPagination({ currentPage: nav.current, pageSize: nav.pageSize });
  };
  const realTimeUpdate = (val: LocationDetails) => {
    const tempData = [...data];
    tempData.splice(9, 1);
    tempData.splice(0, 0, val);
    console.log("tempdata", tempData);
    setData(tempData);
  };

  return (
    <div>
      {selectedRow !== undefined && (
        <UpdateLocation
          data={selectedRow}
          visible={selectedRow !== undefined}
          onClose={onModalClose}
          locationStatus
          reload={() => getData()}
          realTimeUpdate={realTimeUpdate}
        />
      )}
      {addLocationModalVisible && (
        <UpdateLocation
          data={selectedRow}
          visible={addLocationModalVisible}
          onClose={onModalClose}
          locationStatus={false}
          reload={() => getData()}
          realTimeUpdate={realTimeUpdate}
        />
      )}
      <div className="main-container-location">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginBottom: "30px",
            position: "sticky",
            top: "0",
            backgroundColor: "#1b1b1b",
            zIndex: 20,
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <Authorizer
            data={{
              feature: "9",
              action: Actions.HIDE,
            }}
          >
            <Button
              type="primary"
              style={{
                borderRadius: "5px",
                height: "45px",
                fontSize: "15px",
                fontWeight: 300,
                fontFamily: "Poppins",
              }}
              onClick={() => setAddLocationModalVisible(true)}
            >
              <PlusOutlined />
              Add Plant
            </Button>
          </Authorizer>
          <Input
            placeholder="Search by Plant Name"
            className="location-search-bar"
            prefix={
              <IoSearch
                size={20}
                color="rgba(255, 255, 255, 0.6)"
                style={{
                  marginRight: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            }
            value={searchValue}
            style={{ width: "30%", height: "45px" }}
            onChange={(e) => searchByName(e.target.value)}
          />
        </div>
        <div className="general-table general-pagination">
          <Table
            // className="components-table-demo-nested"
            columns={columns}
            dataSource={data}
            loading={loading}
            pagination={{
              pageSize: pagination.pageSize,
              total,
              current: pagination.currentPage,
              showQuickJumper: true,
              showSizeChanger: true,
            }}
            onChange={(val: any, filter: any, sorter: any) =>
              handleChange(val, sorter)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default LocationTable;
