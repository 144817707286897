import React, { useState, FC } from "react";
import { Form, Input, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { KeyOutline } from "react-ionicons";
import sha1 from "sha1";
import { NavLink } from "react-router-dom";
import "./RecoverStyles.scss";
import { useConnection } from "../../Services/ApplicationContext";
import { useAuthZ } from "../../Services/AuthorizationContext";
import { useHistory } from "react-router";
import { Radio } from "antd";

const RecoverAcc: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = React.useState(1);
  const history = useHistory();

  const onSubmit = () => {
    if (value === 1) {
      history.push(`/RecoverAcc/email`);
    } else if (value === 2) {
      history.push(`/RecoverAcc/mobile`);
    }
  };

  const onChange = (e: any) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  return (
    <div className="main-container-rc">
      <div className="form-container-rc">
        <div className="form-header-rc">
          <div className="header-container-rc">
            <div className="detail-container-rc">
              <h2 className="h2-rc">Recover</h2>
              <h4 className="h4-rc">Choose how you want to recover</h4>
            </div>
            <div className="icons">
              <KeyOutline color={"#fff"} height="80px" width="80px" />
            </div>
          </div>
        </div>
        <div className="form-footer-rc">
          <div className="form-footer-container-rc">
            <span className="recover-title">Recover through</span>
            <Radio.Group
              onChange={onChange}
              value={value}
              className="radio-btn-recoverAcc"
            >
              <Radio className="radio-btn" value={1}>
                Email
              </Radio>
              <br />
              <Radio className="radio-btn" value={2}>
                Mobile
              </Radio>
              <br />
            </Radio.Group>
            <Button
              loading={loading}
              type="primary"
              onClick={onSubmit}
              className="submit-btn-rc"
              style={{ marginTop: "15px" }}
            >
              Recover
            </Button>
          </div>
          <div className="login-rc">
            <NavLink to="/" className="navigation-rc">
              Return to Login
            </NavLink>
          </div>
        </div>
      </div>
      <div>
        <h5 className="powered-by-rc">Powered by Xeptagon</h5>
      </div>
    </div>
  );
};

export default RecoverAcc;
