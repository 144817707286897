import React, { FC, useEffect, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  Select,
  Divider,
  Tag,
  message,
  Spin,
  Skeleton,
} from "antd";
import { useProject } from "./NewProjextContext";
import "../modalPopUp.scss";
import "./common.scss";
import "./containerSelection.scss";
import { useConnection } from "../../Services/ApplicationContext";
import { Container } from "../../constants/constants";

export interface ContainerSelectionModalProps {
  visible: boolean;
  nodeData: any;
  onClose: (val?: any) => void;
  inputID: any;
}

const InputCutLengthViewModal: FC<ContainerSelectionModalProps> = (
  props: ContainerSelectionModalProps,
) => {
  const { visible, onClose, nodeData, inputID } = props;
  const [formTwo] = Form.useForm();
//   const { setInputCutLengthArray, inputCutLengthArray } = useProject();
  const [loading, setLoading] = useState(false);
  const { Option } = Select;
  const [modalLoading, setModalLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    formTwo.setFieldsValue({
      cutLength: nodeData.cutLength,
    });
    setLoading(false);
  }, [nodeData]);


  return (
    <Modal
      centered
      width="40%"
      title={`Input cut length Selection`}
      visible={visible}
      className="general-modal-popup"
      onCancel={() => {
        onClose();
        formTwo.resetFields();
      }}
      footer={[
        <div className="modal-field-button">
          <Button
            type="primary"
            danger
            onClick={() => {
              onClose(false);
            }}
          >
            Close
          </Button>
        </div>,
      ]}
    >
      <div
        style={{
          maxHeight: "70vh",
          overflowY: "auto",
          overflowX: "hidden",
          padding: 5,
        }}
      >
        <div>
          <Divider orientation="center">{nodeData.inputValue}</Divider>
          {loading ? (
            <Skeleton active paragraph={{ rows: 5 }} round />
          ) : (
            <Form
              layout="horizontal"
              form={formTwo}
              name="task"
              onFinish={(values: any) => {
                onClose();
              }}
              scrollToFirstError
              autoComplete="off"
            >
              <Form.Item
                label="Cut Length"
                name="cutLength"
              >
                <Input disabled value={nodeData.cutLength}/>
              </Form.Item>
            </Form>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default InputCutLengthViewModal;
