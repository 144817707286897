import { ConsoleSqlOutlined } from "@ant-design/icons";
import React, {
  FC,
  ReactNode,
  useContext,
  useState,
  useEffect,
  createContext,
} from "react";

import { message } from "antd";
import { useConnection } from "./ApplicationContext";
import { SERVICE_TYPE } from "../constants/constants";

export type NotificationContextProps = {
  readToggleClickedCount: number;
  setReadToggleClickedCount: (val: number) => void;
  loadItem: boolean;
  setLoadItem: (val: boolean) => void;
  showUnread: boolean;
  setShowUnread: (val: boolean) => void;
  markAllRead: boolean;
  setMarkAllRead: (val: boolean) => void;
  getUnreadNotificationsCount: () => void;
  unreadNotificationCount: number;
  setUnreadNotificationCount: (val: number) => void;
};

export const NotificationContext = createContext<NotificationContextProps>({
  readToggleClickedCount: 0,
  setReadToggleClickedCount: (val: number) => {},
  loadItem: false,
  setLoadItem: (val: boolean) => {},
  showUnread: false,
  setShowUnread: (val: boolean) => {},
  markAllRead: false,
  setMarkAllRead: (val: boolean) => {},
  getUnreadNotificationsCount: () => {},
  unreadNotificationCount: 0,
  setUnreadNotificationCount: (val: number) => {},
});

// export const NotificationContext = React.createContext<{
//     readToggleClickedCount: number;
//   setReadToggleClickedCount: (val: number) => void;
//   }>();

export type ChildrenProp = { children: ReactNode };

export const NotificationContextProvider: FC<ChildrenProp> = ({ children }) => {
  const { get, delete: del, post, put } = useConnection();
  const [readToggleClickedCount, setReadToggleClickedCount] = useState<number>(
    0,
  );
  const [loadItem, setLoadItem] = useState<boolean>(false);
  const [showUnread, setShowUnread] = useState<boolean>(false);
  const [markAllRead, setMarkAllRead] = useState<boolean>(false);
  const [
    unreadNotificationCount,
    setUnreadNotificationCount,
  ] = useState<number>(0);

  const getUnreadNotificationsCount = async () => {
    try {
      const result = await get(
        "notification/getUnreadNotificationCount",
        undefined,
        SERVICE_TYPE.serviceType
      );
      if (result.message === "Success") {
        setUnreadNotificationCount(result?.data?.unReadNotificationCount);
      }
    } catch (e: any) {
      message.open({
        type: "error",
        content: e.message,
        duration: 2,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
    }
  };

  return (
    <NotificationContext.Provider
      value={{
        readToggleClickedCount,
        setReadToggleClickedCount,
        loadItem,
        setLoadItem,
        showUnread,
        setShowUnread,
        markAllRead,
        setMarkAllRead,
        getUnreadNotificationsCount,
        unreadNotificationCount,
        setUnreadNotificationCount,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = (): NotificationContextProps => {
  return useContext(NotificationContext);
};
