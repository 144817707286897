import React, { FC } from "react";
import { useHistory, useParams } from "react-router";
import { Button, Popconfirm, Row, Space, Table } from "antd";
import MainLayout from "../../components/MainLayout";

const TentativeProjectTimelines: FC = () => {
  const paramData: any = useParams();
  const history = useHistory();
  const data = [
    {
      id: 1,
      deadline: "2021-10-02",
      budget: 4000,
    },
  ];
  const columns: any = [
    { title: "Id", dataIndex: "id", key: "id" },
    {
      title: "End Date",
      dataIndex: "deadline",
      key: "deadline",
    },
    {
      title: "Budget",
      dataIndex: "budget",
      key: "budget",
    },
    {
      title: "Action",
      dataIndex: "operation",
      width: "120px",
      fixed: "right",
      render: (_: any, record: any) => {
        return (
          <Space size="middle">
            <Button
              type="primary"
              className="preview-btn-ttl"
              onClick={() =>
                history.push(`/project/schedule/${record.id}`)
              }
            >
              Preview
            </Button>
            <Popconfirm
              title="Are you sure to select this timeline?"
              // onConfirm={() => Delete(record)}
              okText="Yes"
              okButtonProps={{ danger: true }}
              cancelText="No"
              cancelButtonProps={{ type: "primary" }}
            >
              <Button type="primary" className="add-device-btn">
                Select
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  return (
    <MainLayout type="primary" title="Tentative Timelines">
      <div className="addLocations-form-container">
        <Table
          className="components-table-demo-nested"
          columns={columns}
          dataSource={data}
        />
        <Row justify="end" style={{ marginTop: "30px" }}>
          <Button
            type="primary"
            className="back-btn-ttl"
            onClick={() => history.goBack()}
          >
            Back
          </Button>
          <Button type="primary" className="cancel-btn-ttl">
            Canel
          </Button>
        </Row>
      </div>
    </MainLayout>
  );
};
export default TentativeProjectTimelines;
