/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
import React, { FC, useState, useEffect } from "react";
import { Empty, Skeleton, message } from "antd";
import "./UtilizationReport.scss";
import UtilizationDetails from "./UtilizationDetails";
import AverageOperationSection from "./AverageOperationSection";
import UtilizationCurve from "./UtilizationCurve";
import HeatMapChart from "./HeatMapChart";
import MainLayout from "../../components/MainLayout";

import { useConnection } from "../../Services/ApplicationContext";
import RepSearch, { Query } from "./RepSearch";
import { DateTime, Settings } from "luxon";
import { SERVICE_TYPE } from "../../constants/constants";

Settings.defaultZoneName = "Asia/Colombo";

const UtilizationReport: FC = (props: any) => {
  const { location } = props;
  let currQuery = location.state;
  const { post, get } = useConnection();
  const [noData, setNoData] = useState(true);
  const [loadRep, setLoadRep] = useState(true);
  const [graphReport, setGraphReport] = useState<any>({});
  const [query, setQuery] = useState<any>({});
  const [tableDates, setTableDates] = useState<any>([]);
  const [overallReport, setOverallReport] = useState<any>({});
  const [machineDetails, setMachineDetails] = useState<any>({});
  const [monthDuration, setMonthDuration] = useState<number>();
  const [errorMsg, setErrorMsg] = useState<string>("");

  const getEndDate = (startDate: number, range: string): number => {
    const dateTime = DateTime.fromMillis(startDate);
    switch (range) {
      case "P1W":
        return dateTime.startOf("week").minus({ day: 1 }).valueOf();
      case "P1M":
        return dateTime.startOf("month").minus({ day: 1 }).valueOf();
      case "P1D":
        return dateTime.minus({ day: 1 }).valueOf();
    }
    return startDate;
  };

  const processQuery = async (q: Query) => {
    // setQuery(q);
    setLoadRep(true);
    try {
      if (q && q.src && q.src.attr.machineName != undefined)
        setMachineDetails(q.src.attr);
      else if (q && q.src && machineDetails.machineID != q.src.attr.machineID) {
        const deta: any = await post(
          "system-management/device/getMachineStatusByID",
          {
            machineID: q?.src?.attr["machineID"],
          },
          undefined,
          SERVICE_TYPE.serviceType,
        );
        console.log("deta", deta);
        setMachineDetails(deta?.data);
      }
      setQuery(q);

      const dates = [q.start, getEndDate(q.start, q.duration)];

      if (q.duration === "P1M") {
        const du = DateTime.fromMillis(dates[0])
          .diff(DateTime.fromMillis(dates[1]), "days")
          .toObject().days;
        setMonthDuration(du !== undefined ? du * 60 : 30 * 60);
      }
      console.log(monthDuration, "kkkkkkkkkk");
      setTableDates(dates);
      const result = await post(
        "report/utilizationReport",
        {
          machineID: Number(q.src.attr["machineID"]),
          dates: dates,
          type: q.duration,
        },
        undefined,
        SERVICE_TYPE.serviceType
      );
      const resultOverall = await post(
        "report/overallUtilizationReport",
        {
          machineID: q.src.attr["machineID"],
          date: DateTime.fromMillis(dates[0]).toFormat("yyyyMMdd"),
        },
        undefined,
        SERVICE_TYPE.serviceType
      );

      if (
        resultOverall &&
        resultOverall.data &&
        result?.data &&
        result?.data.length > 0
      ) {
        if (q.duration == "P1W") {
          resultOverall.data["weeklyAvg"] = result?.data[0].total;
        } else if (q.duration == "P1M") {
          resultOverall.data["monthlyAvg"] = result?.data[0].total;
        }
      }
      // console.log(result.data);
      setGraphReport(result?.data);
      setOverallReport(resultOverall?.data);
      if (
        (result?.data == null ||
          result?.data.length < 0 ||
          result?.data[0].totalMin == 0) &&
        (resultOverall?.data == null ||
          (resultOverall?.data?.data == null &&
            resultOverall?.data?.daily == null))
      ) {
        setNoData(true);
        let m;
        if (
          result?.data != null &&
          result?.data.length > 0 &&
          result?.data[0].machineName != null
        ) {
          m = result?.data[0]["machineName"];
        }

        message.open({
          type: "error",
          content: `Error! No reports available ${
            m ? "for the " + m + "" : ""
          } on ${DateTime.fromMillis(q.start).toFormat("yyyy.MM.dd")}`,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
        setErrorMsg(
          `Error! No reports available ${
            m ? "for the " + m + "" : ""
          } on ${DateTime.fromMillis(q.start).toFormat("yyyy.MM.dd")}`,
        );
      } else {
        setNoData(false);
      }
    } catch (err: any) {
      message.open({
        type: "error",
        content: err.message,
        duration: 2,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
      setNoData(true);
    } finally {
      setLoadRep(false);
    }
  };

  useEffect(() => {
    console.log(currQuery, "hhhh");
    if (currQuery && Object.keys(currQuery).length !== 0) {
      processQuery(currQuery);
    }
  }, []);

  const showOutput = () => {
    if (loadRep) return <Skeleton active paragraph={{ rows: 10 }} round />;
    if (!noData)
      return (
        <div className="utilization-report-container">
          <div className="row-section-details">
            <div className="utilDetails">
              <div className="machineName">{machineDetails.machineName}</div>
              <div style={{ width: "100%" }}>
                <UtilizationDetails
                  dailyReport={graphReport ? graphReport : []}
                  overallReport={overallReport}
                  details={machineDetails}
                  query={query}
                />
              </div>
            </div>
          </div>
          {/* <div className="row-section">
            <HeatMapChart hm={report.currentHM} />
          </div> */}
          <div className="row-section">
            <AverageOperationSection
              data={overallReport}
              interval={query.duration}
            />
          </div>
          <div
            className="row-section"
            style={{ justifyContent: "center", width: "102%" }}
          >
            {graphReport.length > 0 && (
              <UtilizationCurve
                prev={
                  graphReport && graphReport.length > 1 && graphReport[1]
                    ? graphReport[1].timeList
                    : []
                }
                prevPercent={
                  graphReport && graphReport.length > 1 && graphReport[1]
                    ? query.duration === "P1D"
                      ? ((graphReport[1].totalMin / 1440) * 100).toFixed(2)
                      : query.duration === "P1W"
                      ? ((graphReport[1].totalMin / 10080) * 100).toFixed(2)
                      : query.duration === "P1M"
                      ? (
                          (graphReport[1].totalMin /
                            (graphReport[1].duration * 24 * 60)) *
                          100
                        ).toFixed(2)
                      : null
                    : null
                }
                curr={
                  graphReport && graphReport.length > 0 && graphReport[0]
                    ? graphReport[0].timeList
                    : []
                }
                currPercent={
                  graphReport && graphReport.length > 1 && graphReport[0]
                    ? query.duration === "P1D"
                      ? ((graphReport[0].totalMin / 1440) * 100).toFixed(2)
                      : query.duration === "P1W"
                      ? ((graphReport[0].totalMin / 10080) * 100).toFixed(2)
                      : query.duration === "P1M"
                      ? (
                          (graphReport[0].totalMin /
                            (graphReport[0].duration * 24 * 60)) *
                          100
                        ).toFixed(2)
                      : null
                    : null
                }
                dDur={query.duration}
                times={tableDates}
              />
            )}
          </div>
          {/* <button onClick={deviceDetails} type="button">
            check
          </button> */}
        </div>
      );
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        style={{ height: "40vh", marginTop: "25vh" }}
        description={errorMsg}
      />
    );
  };

  return (
    <MainLayout
      type="primary"
      // title="Utilization Reports"
      OpenKey={["report"]}
      selectedKey={["utilization"]}
    >
      <div className="inner-dark-section">
        <div
          className="search-section"
          style={{
            width: "100%",
            position: "sticky",
            top: "0",
            zIndex: 10,
            backgroundColor: "#1b1b1b",
            paddingTop: "25px",
          }}
        >
          <RepSearch
            queryProcessor={processQuery}
            currQuery={currQuery}
            stopLoading={setLoadRep}
          />
        </div>
        {showOutput()}
      </div>
    </MainLayout>
  );
};

export default UtilizationReport;
