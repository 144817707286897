import React, { FC } from "react";
import { useParams } from "react-router";

import MainLayout from "../../components/MainLayout";
import ViewMachineMaintenance  from './ViewMachineMaintenance'

export const ViewMachineWrapper: FC = () => {
  const params: any = useParams();
  return (
    <MainLayout
      type="primary"
      OpenKey={["maintenance"]}
      selectedKey={["maintenance"]}
    >
     <ViewMachineMaintenance id={params.id} /> 
    </MainLayout>
  );
};

export default ViewMachineWrapper;