import React, { useEffect, useState, FC, useCallback } from "react";
import locale from "antd/es/date-picker/locale/en_US";
import moment from "moment";
import { DateTime } from "luxon";
import { Button, Select, Form, DatePicker, message } from "antd";
import { useConnection } from "../../Services/ApplicationContext";
import "../../App.less";
import "./SearchBar.scss";

const { Option } = Select;

export interface DataSource {
  id: number;
  attr: "OnOff";
  type: "MACHINE" | "DEVICE";
}

export interface Query {
  src: DataSource;
  start: number;
  duration: string;
}

interface SearchInput {
  currQuery?: Query;
  queryProcessor: any;
}

const defaultProps = {
  currQuery: undefined,
};

const SearchBar: FC<SearchInput> = ({
  currQuery,
  queryProcessor,
}: SearchInput) => {
  const { get, post } = useConnection();
  const [form] = Form.useForm();
  const [calType, setCalType] = useState("P1D");
  const { setFieldsValue, getFieldValue, resetFields } = form;
  const [enableSearch, setEnableSearch] = useState(false);

  const currQueryMoment = () => {
    if (currQuery) {
      return moment(currQuery.start);
    }
    return undefined;
  };

  const sendQuery = async (reportQuery: any) => {
    setEnableSearch(false);
    try {
      let intervalStartTime: any;
      if (reportQuery.intervalType === "P1W") {
        intervalStartTime = moment(reportQuery.interval).endOf("week").startOf("day") as any;
      } else if (reportQuery.intervalType === "P1M") {
        intervalStartTime = moment(reportQuery.interval).endOf("month").startOf("day") as any;
      } else if (reportQuery.intervalType === "P1D") {
        intervalStartTime = moment(reportQuery.interval).startOf("day") as any;
      } else {
        intervalStartTime = moment(reportQuery.interval).endOf("year").startOf("day") as any;
      }

      const query: any = {
        duration: reportQuery.intervalType,
        start: intervalStartTime.valueOf(),
        page: 0,
        size: 5,
      };
      queryProcessor(query);
    } catch (error) {
      console.log("error", error);
      message.error("An error occurred!");
    }
  };

  function dayDisabledDate(current: any) {
    const start = DateTime.now().endOf("day");
    return current && (current > start || current < start.minus({ year: 1 }));
  }

  function weekDisabledDate(current: any) {
    const start = DateTime.now().endOf("week");
    return current && (current > start || current < start.minus({ year: 1 }));
  }

  function monthDisabledDate(current: any) {
    const start = DateTime.now().endOf("month");
    return current && (current > start || current < start.minus({ year: 1 }));
  }

  function yearDisabledDate(current: any) {
    const start = DateTime.now().endOf("year");
    return current && (current > start || current < start.minus({ year: 1 }));
  }

  const renderDatePicker = () => {
    if (calType !== getFieldValue("intervalType")) resetFields(["interval"]);
    if (calType === "P1M") {
      return (
        <div className="form-field-report">
          <Form.Item
            name="interval"
            label="Date"
            rules={[{ required: true, message: "Please select the date." }]}
          >
            <DatePicker
              allowClear={true}
              picker="month"
              placeholder="Select Month"
              disabledDate={monthDisabledDate}
              style={{ width: "278px", marginLeft: "2%" }}
              format="MMMM YYYY"
              onChange={() => {
                setEnableSearch(true);
              }}
            />
          </Form.Item>
        </div>
      );
    }
    if (calType === "P1W") {
      return (
        <div className="form-field-report">
          <Form.Item
            name="interval"
            label="Date"
            rules={[{ required: true, message: "Please select the date." }]}
          >
            <DatePicker
              picker="week"
              placeholder="Select Week"
              disabledDate={weekDisabledDate}
              style={{ width: "278px", marginLeft: "2%" }}
              onChange={() => {
                setEnableSearch(true);
              }}
            />
          </Form.Item>
        </div>
      );
    }
    if (calType === "P1Y") {
      return (
        <div className="form-field-report">
          <Form.Item
            name="interval"
            label="Date"
            rules={[{ required: true, message: "Please select the date." }]}
          >
            <DatePicker
              picker="year"
              disabledDate={yearDisabledDate}
              placeholder="Select Year"
              style={{ width: "278px", marginLeft: "2%" }}
              format="YYYY"
              onChange={() => {
                setEnableSearch(true);
              }}
            />
          </Form.Item>
        </div>
      );
    }
    if (calType === "P1D") {
      return (
        <div className="datepicker-form">
          <Form.Item
            name="interval"
            label="Date"
            rules={[{ required: true, message: "Please select the date." }]}
          >
            <DatePicker
              disabledDate={dayDisabledDate}
              defaultValue={moment(DateTime.now().startOf("day").valueOf())}
              style={{ width: "278px", marginLeft: "2%" }}
              format="DD.MM.YYYY"
              onChange={() => {
                setEnableSearch(true);
              }}
            />
          </Form.Item>
        </div>
      );
    }
  };

  return (
    <div className="overallReportSearch">
      <Form
        className="report-search-container"
        layout="inline"
        form={form}
        name="reports"
        hideRequiredMark
        scrollToFirstError
        autoComplete="off"
        onFinish={sendQuery}
      >
        <div className="form-field-report">
          <Form.Item
            label="Report"
            name="intervalType"
            rules={[
              {
                required: true,
                message: "Please select your access level!",
              },
            ]}
            initialValue="P1D"
          >
            <Select
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "200px", marginLeft: "2%" }}
              onChange={(value: any, opts) => {
                setCalType(value.toString());
                resetFields(["interval"]);
                setEnableSearch(true);
              }}
            >
              <Option value="P1D">Daily</Option>
              <Option value="P1W">Weekly</Option>
              <Option value="P1M">Monthly</Option>
              {/* <Option value="year">Yearly</Option> */}
            </Select>
          </Form.Item>
        </div>
        <div>
          <Form.Item
            noStyle
            // shouldUpdate={(prevValues, currentValues) =>
            //   prevValues.intervalType !== currentValues.intervalType
            // }
          >
            {renderDatePicker()}
          </Form.Item>
        </div>
        <div className="form-field-button">
          <Form.Item>
            <Button
              className="glb-form-button form-button"
              type="primary"
              htmlType="submit"
              disabled={!enableSearch}
            >
              Generate
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

SearchBar.defaultProps = defaultProps;

export default SearchBar;
