import React, { FC } from "react";
import { ProjectContextWrapper, useProject } from "./NewProjextContext";
import { NewProjectOutlineNew } from "./NewProjectOutlineNew";
import MainLayout from "../../components/MainLayout";
import { useParams } from "react-router";

export const NewProjectWrapper: FC = () => {
  const params: any = useParams();
  return (
    <ProjectContextWrapper>
      <MainLayout
        type="primary"
        OpenKey={["scheduling"]}
        selectedKey={["schedule"]}
      >
        <NewProjectOutlineNew id={params.id} />
      </MainLayout>
    </ProjectContextWrapper>
  );
};
