import React, { useState, FC } from "react";
import { Form, Input, Button } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import "./ChangePassword.scss";
import { RefreshOutline } from "react-ionicons";
import { useHistory } from "react-router";

const ChangePassword: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [pwd, setPwd] = useState<any>();
  const [changePwd, setChangePwd] = useState<any>();
  const history = useHistory();

  const onChangeTextPwd = (e: React.FormEvent<HTMLInputElement>): void => {
    setPwd(e.currentTarget.value);
    e.preventDefault();
  };

  const submitPwdChange = (): void => {
    history.push("/");
  };

  return (
    <div className="main-container-cgPwd">
      <div className="form-container-cgPwd">
        <div className="form-header-cgPwd">
          <div className="header-container-cgPwd">
            <div className="detail-container-cgPwd">
              <h2 className="h2-login-cgPwd">Change Password</h2>
              <h4 className="h4-login-cgPwd">Enter a valid password</h4>
            </div>
            <div className="icons-cgPwd">
              <RefreshOutline color="#fff" height="75px" width="75px" />
            </div>
          </div>
        </div>
        <div className="form-footer-cgPwd">
          <div className="form-footer-container-cgPwd">
            <Form
              layout="vertical"
              name="basic"
              initialValues={{
                remember: true,
              }}
              hideRequiredMark
              onFinish={submitPwdChange}
            >
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message:
                      "Password must contain At least one upper case and one lower case English letter, one digit and one special character Minimum eight in length",
                    pattern: new RegExp(
                      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                    ),
                  },
                ]}
              >
                <Input.Password
                  prefix={
                    <LockOutlined style={{ fontSize: 12, color: "#808080" }} />
                  }
                  placeholder="Password"
                  spellCheck="false"
                  type="password"
                  value={pwd}
                  onChange={(e) => onChangeTextPwd(e)}
                />
              </Form.Item>

              <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!",
                        ),
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  prefix={
                    <LockOutlined style={{ fontSize: 12, color: "#808080" }} />
                  }
                  placeholder="Confirm Password"
                  spellCheck="false"
                  type="password"
                  onChange={(e) => setChangePwd(e.currentTarget.value)}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  className="cgPwd-submit-btn"
                  style={{ marginTop: "15px" }}
                >
                  Reset Password
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="login-cgPwd">
            <NavLink to="/" className="navigation-cgPwd">
              Return to Login
            </NavLink>
          </div>
        </div>
      </div>
      {/* <div>
        <h5 className="powered-by-cgPwd">Powered by Xeptagon</h5>
      </div> */}
    </div>
  );
};

export default ChangePassword;
