import React, { FC, useEffect, useState } from "react";
import {
  Modal,
  Button,
  Divider,
  Space,
  Row,
  Col,
} from "antd";
import moment from "moment";
import "../modalPopUp.scss";

const HolidayValidationPopUp = (props: any) =>  {
  const { visible, onClose, edit, setVisible, values } = props;

  return (
    <Modal
      centered
      title="Holiday Validation"
      visible={visible}
      width="62%"
      maskClosable={false}
      className="general-modal-popup modal-popup-header"
      onCancel={() => setVisible(false)}
      footer={[
        <div className="modal-field-button">
          <Button type="primary" danger onClick={() => setVisible(false)}>
            Cancel
          </Button>
          <Button type="primary" onClick={() => onClose(true)}>
            Confirm
          </Button>
        </div>,
      ]}
    >
      <div>
        {values.length === 0 ? 
        <p>No Projects</p> : <p>{values}</p>}
      </div>
    </Modal>
  );
};
export default HolidayValidationPopUp;
