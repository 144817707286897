export enum AuthCategories {
  user = "User Management",
  machine = "Machine Management",
  device = "Device Management",
  plant = "Plant Management",
  admin = "Admin Management",
  report = "Report Management",
  project = "Project Management",
  resource = "Resource Management",
  notification = "Notification Management",
  scheduling = "Process Management",
}

export interface IAccessData {
  id: string;
  description: string;
  [key: string]: string;
}
export interface RoleCreateRequest {
  role: string;
  features: string[];
  filterByPlant: boolean;
}
