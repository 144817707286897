import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import "./HeatMapChart.css";
import { DateTime, Duration } from "luxon";

const renderUtils = (data: any, dur: Duration) => {
  let utils: any[] = [];
  for (let i = 0; i < data?.timeList?.length; i++) {
    const totMin = dur.toMillis() / (1000 * 60);
    const temp = (data?.timeList[i]?.utilizationMin / totMin) * 100;
    const time = data?.timeList[i]?.time;
    const roundedValue = Math.trunc(temp);
    utils.push({ time: time, utilizationPercentage: roundedValue });
  }
  return utils;
};

const HeatMapUtilizationReportChart = (props: any) => {
  const { hm, query } = props;

  const heatMapDefaultDataArray: any[] = [];
  const heatMapDefaultToolTips: any[] = [];

  // const utils = hm?.timeList;

  let dataArray: any = [];
  const tooltips: any[] = [];
  let dataArrayT = [];
  let tooltipsT: any[] = [];
  let tItr = DateTime.fromMillis(Number(hm?.timeList[0]?.time)).setZone(
    "Asia/Colombo",
  );

  let bDur = Duration.fromISO("P1D");
  let format = "hh:mm a";
  if (query.duration == "P1D") {
    bDur = Duration.fromISO("PT30M");
    const now = DateTime.now();
    format = "hh:mm a";
    for (let x = now.startOf("day"); x < now.endOf("day"); x = x.plus(bDur)) {
      heatMapDefaultDataArray.push({
        x: x.toFormat(format),
        y: 103,
      });
      heatMapDefaultToolTips.push(
        `N/A | ${x.toFormat(format)} - ${x
          .plus({ minutes: 30 })
          .toFormat(format)}`,
      );
    }
  } else if (query.duration == "P1W") {
    bDur = Duration.fromISO("P1D");
    format = "cccc";
    for (
      let x = DateTime.fromMillis(query.start)
        .minus(Duration.fromISO(query.duration))
        .plus({ days: 1 })
        .startOf("day");
      x <= DateTime.fromMillis(query.start);
      x = x.plus(bDur)
    ) {
      heatMapDefaultDataArray.push({
        x: x.toFormat(format),
        y: 103,
      });
      heatMapDefaultToolTips.push(`N/A | ${x.toFormat(format)}`);
    }
  } else if (query.duration == "P1M") {
    bDur = Duration.fromISO("P1D");
    for (
      let x = DateTime.fromMillis(query.start)
        .minus(Duration.fromISO(query.duration))
        .plus({ days: 1 })
        .startOf("day");
      x <= DateTime.fromMillis(query.start);
      x = x.plus(bDur)
    ) {
      format = "d";
      heatMapDefaultDataArray.push({
        x: x.toFormat(format),
        y: 103,
      });
      heatMapDefaultToolTips.push(`N/A | ${x.toFormat(format)}`);
    }
  }
  let utils = renderUtils(hm, bDur);
  console.log("utils -----> ", utils, tItr);

  for (let i = 0; i < utils?.length; i++) {
    const mid = tItr.plus(Duration.fromMillis(bDur.valueOf() / 2));
    let temp1 = DateTime.fromMillis(Number(utils[i]?.time)).setZone(
      "Asia/Colombo",
    );
    let temp2 = DateTime.fromMillis(Number(utils[i + 1]?.time)).setZone(
      "Asia/Colombo",
    );
    let toolTipVal = `${utils[i]?.utilizationPercentage}%`;
    let cat;
    let sStr;
    let eStr;
    if (i === utils?.length - 1) {
      sStr = temp1.toFormat(format);
      // const eStr = temp2.toLocaleString(DateTime.TIME_SIMPLE);
      eStr = temp1.plus(bDur).toFormat(format);
    } else {
      sStr = temp1.toFormat(format);
      eStr = temp2.toFormat(format);
    }

    if (query.duration == "P1D") {
      tooltips.push(`${toolTipVal} | ${sStr} - ${eStr}`);
      cat = `${sStr}`;
    } else if (query.duration == "P1M") {
      const dayOfMonth = mid.toFormat("d");
      cat = `${parseInt(dayOfMonth)}`;
      tooltips.push(`${toolTipVal} | ${cat}`);
    } else {
      const dayOfWeek = mid.toFormat("cccc");
      cat = dayOfWeek;
      tooltips.push(`${toolTipVal} | ${cat}`);
    }

    // const eStr = tItr.plus(bDur).toLocaleString(DateTime.TIME_SIMPLE);
    // tooltips.push(`${toolTipVal} | ${sStr} - ${eStr}`);
    // cat = `${sStr}`;
    // console.log("cat ", cat);
    //   switch (hm.interval.D) {
    //     case "P1D":
    //       const sStr = tItr.toLocaleString(DateTime.TIME_SIMPLE);
    //       const eStr = tItr.plus(bDur).toLocaleString(DateTime.TIME_SIMPLE);
    //       tooltips.push(`${toolTipVal} | ${sStr} - ${eStr}`);
    //       cat = `${sStr}`;
    //       break;
    //     case "P1W":
    //       const dayOfWeek = mid.toFormat("cccc");
    //       cat = dayOfWeek;
    //       tooltips.push(`${toolTipVal} | ${cat}`);
    //       break;
    //     case "P1M":
    //       const dayOfMonth = mid.toFormat("d");
    //       // const monthShort = mid.toFormat("LLL");
    //       cat = `${dayOfMonth}`;
    //       tooltips.push(`${toolTipVal} | ${cat}`);
    //       break;
    //     case "P1Y":
    //       const month = mid.toFormat("LLLL");
    //       const yr = mid.toFormat("kk");
    //       cat = `${yr} ${month}`;
    //       tooltips.push(`${toolTipVal} | ${month}`);
    //       break;
    //   }
    dataArray.push({ x: cat, y: utils[i]?.utilizationPercentage });
    tItr = tItr.plus(bDur);
  }

  if (dataArray?.length === heatMapDefaultDataArray.length) {
    dataArrayT = dataArray;
  } else {
    if (query.duration == "P1M") {
      dataArrayT = [
        ...dataArray,
        ...heatMapDefaultDataArray.slice(dataArray.length + 1),
      ];
    } else {
      dataArrayT = [
        ...dataArray,
        ...heatMapDefaultDataArray.slice(dataArray.length),
      ];
    }
  }

  if (tooltips?.length === heatMapDefaultDataArray.length) {
    tooltipsT = tooltips;
  } else {
    if (query.duration == "P1M") {
      tooltipsT = [
        ...tooltips,
        ...heatMapDefaultToolTips.slice(tooltips.length + 1),
      ];
    } else {
      tooltipsT = [
        ...tooltips,
        ...heatMapDefaultToolTips.slice(tooltips.length),
      ];
    }
  }

  // console.log("data array new: ", tooltipsT);

  const options: any = {
    states: {
      active: {
        filter: {
          type: "none",
        },
      },
    },
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
      // background: "#222236",
    },
    stroke: {
      colors: ["#222230"],
      width: 5,
      curve: "straight",
      lineCap: "butt",
    },
    grid: {
      borderColor: "#222236",
    },
    plotOptions: {
      heatmap: {
        radius: 8,
        enableShades: true,
        colorScale: {
          ranges: [
            {
              from: 100,
              to: 50,
              color: "#ff0000",
              foreColor: "white",
              name: "green",
            },
            {
              from: 50,
              to: 0,
              color: "#fffb00",
              foreColor: "white",
              name: "red",
            },
            {
              from: 102,
              to: 104,
              color: "#696969",
              foreColor: "white",
              name: "grey",
            },
          ],
          inverse: false,
          min: 0,
          max: 105,
        },
      },
    },
    xaxis: {
      type: "category",
      tickPlacement: "on",
      axisBorder: {
        show: false,
      },
      title: {
        offsetY: 5,
        style: {
          color: "white",
          fontSize: "14px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 400,
        },
      },
      labels: {
        maxHeight: 51,
        minHeight: 50,
        rotateAlways: false,
        style: {
          colors: "white",
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#ff0000"], // #760e20
    responsive: [
      {
        breakpoint: 1150,
        options: {
          heatmap: {
            horizontal: false,
          },
        },
      },
    ],
    tooltip: {
      followCursor: true,
      intersect: false,
      shared: true,
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
      theme: "dark",
      y: {
        formatter: (x: any, opts: any) => {
          return tooltipsT[opts.dataPointIndex];
        },
        title: {
          formatter: (seriesName: any) => seriesName,
        },
      },
      fixed: {
        enabled: true,
        position: "topLeft",
        offsetX: 0,
        offsetY: 0,
      },
    },
  };

  const series = [
    {
      name: "",
      data: dataArrayT,
    },
  ];

  // useEffect(() => {
  //   console.log("hm ----- > ", hm);
  //   console.log("query ---> ", query)
  // }, [])

  return (
    <div className="heat-map-chart">
      <Chart
        options={options}
        series={series}
        type="heatmap"
        height="100%"
        width="100%"
      />
    </div>
  );
};

export default HeatMapUtilizationReportChart;
