/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-alert */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable radix */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable global-require */
/* eslint-disable no-underscore-dangle */
import {
  Button,
  DatePicker,
  Form,
  message,
  Skeleton,
  Upload,
  Modal,
  Row,
  Col,
} from "antd";
import React, { useEffect, useState, FC } from "react";
import TextArea from "antd/lib/input/TextArea";
import format from "date-fns/format";
import { UploadOutlined } from "@ant-design/icons";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import "react-big-calendar/lib/css/react-big-calendar.css";
import enIN from "date-fns/locale/en-IN";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { IoCalendarOutline } from "react-icons/io5";
import moment from "moment";
import { useConnection } from "../../Services/ApplicationContext";
import "./calenderStyles.scss";
import "../modalPopUp.scss";
import HolidayValidationPopUp from "../CableProduction/holidayValidationPopUp";
import { SERVICE_TYPE } from "../../constants/constants";

const locales = {
  "en-IN": enIN,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const CalenderTable: FC = () => {
  const { get, delete: del, post } = useConnection();
  const [form] = Form.useForm();

  const Holidays: any = [];
  let list: {
    title: string;
    start: any;
    end: any;
    color: string;
  }[] = [];

  const formats = {
    eventTimeRangeFormat: () => {
      return "";
    },
  };

  const [newEvent, setNewEvent] = useState<any>({
    title: "",
    start: "",
    end: "",
  });

  const [allEvents, setAllEvents] = useState<any>(list);
  const [uploading, setUploading] = useState<boolean>(false);
  const [fileChange, setFileChange] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>();
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<any>([]);
  const [modalValues, setModalValues] = useState<any>([]);
  const [modalVisibility, setModalVisibility] = useState(false);

  const [date, setDate] = useState<any>(new Date());

  const props = {
    name: "file",
    multiple: false,
    showUploadList: {
      showDownloadIcon: false,
    },
    onRemove: () => {
      setFileList([]);
    },
    beforeUpload: (file: any) => {
      const txt = file.type === "text/plain";
      if (txt) {
        setFileList([file]);
        return false;
      }
      message.error("only Text files are accepted");
      setFileList([]);
    },
    previewFile(file: any) {
      return file.type;
    },
    fileList,
  };

  const getData = async () => {
    setLoading(true);
    const resultLocations = await get(
      "scheduler/calendar/allHolidays",
      undefined,
      SERVICE_TYPE.serviceType,
    );
    console.log(resultLocations.data, "uuuuuuuuuuuuuu");
    resultLocations.data.map((val: any) => {
      console.log(val);
      list.push({
        title: val.description,
        start: new Date(parseInt(val.id), parseInt(val.month_) - 1, val.day_),
        end: new Date(parseInt(val.id), parseInt(val.month_) - 1, val.day_),
        color: "#ff0000",
        // day: parseInt(resultLocations.data[x].id),
      });
    });
    // for (const x in resultLocations.data) {
    //   console.log(x);
    // }
    list = list.sort((a, b) => Date.parse(a.start) - Date.parse(b.start));
    setAllEvents(list);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [fileChange]);

  async function handleAddEvent(val: any) {
    setUploading(true);
    // Changed date for local timezone
    let date: any =
      new Date(
        moment(val.calender).year(),
        moment(val.calender).month(),
        moment(val.calender).date(),
      ).valueOf() / 1000;
    date = date.toString();
    const { description } = val; // to do

    const holiday: any = [
      {
        date,
        description,
      },
    ];

    const results = await post(
      "scheduler/calendar/validateHolidays",
      { holidays: holiday },
      undefined,
      SERVICE_TYPE.serviceType,
    );

    console.log(results.response, "]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]");

    // await post("ProcessScheduling/addHoliday", {
    //   holidays: [
    //     {
    //       date,
    //       description,
    //     },
    //   ],
    // })
    //   .then((response) => {
    //     message.open({
    //       type: "success",
    //       content: "Holiday added Successfully",
    //       duration: 3,
    //       style: { textAlign: "right", marginRight: 15, marginTop: 10 },
    //     });
    //     getData();
    //     setAllEvents([...allEvents, newEvent]);
    //     form.resetFields();
    //     setUploading(false);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     message.open({
    //       type: "error",
    //       content: `Error in adding Holiday ${err}`,
    //       duration: 3,
    //       style: { textAlign: "right", marginRight: 15, marginTop: 10 },
    //     });
    //     setUploading(false);
    //   });
    setModalVisibility(true);
  }

  const handleChange = async (e: any) => {
    setUploading(true);

    if (fileList.length === 0) {
      setUploading(false);
      return;
    }

    const file = fileList[0];

    let text;
    const dates: any = [];

    if (file) {
      const data = new FormData();
      const reader = new FileReader();

      data.append("file", file);

      reader.onload = async function (evt) {
        text = evt.target?.result;
        text = text?.toString();
        Holidays.push(text?.replace(/\r\n/g, "\n").split("\n"));

        for (const x in Holidays[0]) {
          const [date1, des] = Holidays[0][x].split(",");
          // Changed date for local timezone
          let date: any =
            new Date(
              moment(date1).year(),
              moment(date1).month(),
              moment(date1).date(),
            ).valueOf() / 1000;

          date = date.toString();
          dates.push({ date, description: des });
        }

        await post("ProcessScheduling/addHoliday", {
          holidays: dates,
        })
          .then((response) => {
            setFileList([]);
            message.open({
              type: "success",
              content: "Holidays added Successfully",
              duration: 3,
              style: { textAlign: "right", marginRight: 15, marginTop: 10 },
            });
            setFileChange(true);
            setUploading(false);
          })
          .catch((err) => {
            message.open({
              type: "error",
              content: `Error in adding Holidays. Try again | ${err}`,
              duration: 3,
              style: { textAlign: "right", marginRight: 15, marginTop: 10 },
            });
            console.log(err);
            setFileList([]);
            setUploading(false);
          });
      };
      reader.readAsText(file);
      // getData();
    }
  };

  // delete holiday
  const DeleteHandleChange = async (val: string) => {
    // Changed date for local timezone
    let dateT: any;
    let date: any =
      new Date(
        moment(val).year(),
        moment(val).month(),
        moment(val).date(),
      ).valueOf() / 1000;
    dateT = date;
    date = date.toString();
    console.log(dateT);
    await del(`processScheduling/deleteHoliday/${date}`)
      .then((response) => {
        //  setFileList([]);
        message.open({
          type: "success",
          content: "Holiday deleted Successfully",
          duration: 3,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
        setVisible(false);
        setDate(dateT * 1000);
        getData();
      })
      .catch((err) => {
        message.open({
          type: "error",
          content: `Error in deleted Holiday. Try again | ${err}`,
          duration: 3,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
        console.log(err);
      });
  };

  const clickOnEvent = (val: any) => {
    setModalData(val);
    setVisible(true);
  };

  const navigateMonth = (val: any) => {
    console.log(val);
    setDate(val);
  };

  return (
    <div className="calender-container">
      <div className="holiday-update">
        <div className="title-txt-one">
          <h2>Choose Holidays</h2>
        </div>
        <div className="form-fields">
          <Form
            className="calender-search-container"
            layout="inline"
            form={form}
            name="calender"
            hideRequiredMark
            scrollToFirstError
            autoComplete="off"
            onFinish={handleAddEvent}
          >
            <div className="datepicker-field">
              <Form.Item
                name="calender"
                label="Choose Date"
                rules={[{ required: true, message: "Please select the date." }]}
              >
                <DatePicker
                  picker="date"
                  // disabledDate={monthDisabledDate}
                  style={{ width: "278px" }}
                  placeholder="Select Month"
                  format="DD.MM.YYYY"
                  suffixIcon={<IoCalendarOutline size={17} />}
                  onChange={(val: any) => {
                    const t = moment(val).startOf("day").toDate();
                    setNewEvent({ ...newEvent, start: t, end: t });
                  }}
                />
              </Form.Item>
            </div>

            <div className="calender-text-input">
              <Form.Item
                name="description"
                label="Add Description"
                // rules={[{ required: true, message: "Please select the date." }]}
              >
                <TextArea
                  maxLength={200}
                  value={newEvent.title}
                  placeholder="Add Description"
                  style={{ paddingTop: "12px" }}
                  onChange={(e) =>
                    setNewEvent({ ...newEvent, title: e.target.value })
                  }
                />
              </Form.Item>
            </div>

            <div className="calender-field-button">
              <Form.Item>
                <Button htmlType="submit" type="primary">
                  Add Date
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
        <div className="title-txt-two">
          <h2>Upload Holidays as Text File</h2>
        </div>
        <div className="upload-container">
          <Upload {...props} listType="picture">
            <Button
              type="primary"
              icon={<UploadOutlined />}
              style={{ marginRight: "10px" }}
            >
              Choose
            </Button>
          </Upload>
          <Button
            type="primary"
            className="upload-btn"
            style={
              fileList.length !== 0
                ? { backgroundColor: "#218e54" }
                : { backgroundColor: "#923a38" }
            }
            hidden={fileList.length === 0}
            onClick={(e) => handleChange(e)}
            disabled={fileList.length === 0}
            loading={uploading}
          >
            {uploading ? "Uploading" : "Upload"}
          </Button>
        </div>
      </div>
      <div className="calender-view-container">
        {uploading === true || loading === true ? (
          <Skeleton active paragraph={{ rows: 10 }} round />
        ) : (
          <Calendar
            localizer={localizer}
            events={allEvents}
            formats={formats}
            views={{
              week: true,
              month: true,
              agenda: true,
            }}
            date={date}
            // date={new Date(1647600563000)}
            onNavigate={(date: any) => {
              navigateMonth(date);
            }}
            startAccessor="start"
            onSelectEvent={(e) => clickOnEvent(e)}
            endAccessor="end"
            style={{ height: 650, margin: "50px", color: "white" }}
          />
        )}
      </div>
      <div>
        <Modal
          // title="Add User"
          centered
          visible={visible}
          onCancel={() => setVisible(false)}
          width="55%"
          className="general-modal-popup"
          footer={null}
        >
          {visible ? (
            <div>
              <div className="modal-content-calender">
                <Row>
                  <Col span={6}>
                    <div className="title-calender-label">Description:</div>
                  </Col>
                  <Col span={15}>
                    <div className="title-calender">{modalData.title}</div>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <div className="date-calender-label">Date:</div>
                  </Col>
                  <Col span={15}>
                    <div className="date-calender">
                      {moment(modalData.start).format("DD/MM/YYYY")}
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="btn-calender-container">
                <Button
                  type="primary"
                  danger
                  onClick={(e) =>
                    DeleteHandleChange(
                      moment(modalData.start).format("MM/DD/YYYY"),
                    )
                  }
                >
                  Delete
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    setVisible(false);
                  }}
                >
                  Close
                </Button>
              </div>
            </div>
          ) : null}
        </Modal>
      </div>
      <div>
        <HolidayValidationPopUp
          visible={modalVisibility}
          setVisible={setModalVisibility}
          values={modalValues}
        />
      </div>
    </div>
  );
};

export default CalenderTable;
