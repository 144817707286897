import React, { useState, useEffect, FC } from "react";
import { Layout, Menu, Button, Avatar, Select } from "antd";
import CableProductionFlowChart from "./CableProductionFlowChart";
import { CableFlowNodeContextWrapper } from "./CableFlowNodeContextWrapper";
import "./Styles.scss";
import MainLayout from "../../components/MainLayout";
import { useParams } from "react-router";

const UpdateCableProcess: FC = (props) => {
  let id: any = {};
  id = useParams();
  return (
    <MainLayout
      type="primary"
      // title="Update Cable Process"
      OpenKey={["scheduling"]}
      selectedKey={["productionPlans"]}
    >
      <div className="inner-dark-section">
        <div className="cable-section">
          <CableFlowNodeContextWrapper
            processId={id?.id}
            edit
            newProcess={false}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default UpdateCableProcess;
