/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Input,
  message,
  Modal,
  Progress,
  Row,
  Select,
  Skeleton,
  Spin,
  Table,
  Tag,
} from "antd";
import "./MachinesStatusTable.scss";
import {
  IoChevronDown,
  IoChevronDownCircleOutline,
  IoChevronUpCircleOutline,
  IoFilter,
  IoInformationCircleSharp,
  IoRadioButtonOnSharp,
  IoSearch,
} from "react-icons/io5";
import { DateTime, Duration } from "luxon";
import { useHistory } from "react-router";
import { useConnection } from "../../Services/ApplicationContext";
import { Query } from "../UtilizationReport/RepSearch";
import "../colors.scss";
import { SearchOutlined } from "@ant-design/icons";
import { SERVICE_TYPE } from "../../constants/constants";

type MachineStatus = {
  machineID: number;
  machineName: string;
  locationID: string;
  status: number;
  deviceStatus: number;
  deviceName: string;
};

type MachineEvents = {
  eventValue: number;
  firstEvent: number;
  periodInMilliSecs: number;
  machineAttribute: string;
  machineID: number;
  attributeName: string;
  eventTime: number;
  machineName: string;
  deviceName: string;
  status: string;
};

interface MachineUtilization {
  mid?: number;
  total: string;
  week_avg: string;
  month_avg: string;
  overall_avg: string;
  percentage: number;
  totalH: number;
}

interface Source {
  id: number;
  attr: string;
  type: string;
  s: number;
  timeout: number;
  tErr: number;
}

interface Interval {
  s: number;
  D: string;
  z: string;
}

interface CurrentHM {
  src: Source;
  type: string;
  bDur: string;
  interval: Interval;
  pct: number[];
  labels: string[];
}

interface Pagination {
  currentPage: number;
}

interface IMyProps {
  machinesStatusData: any;
  statusTableLoading: boolean;
}

const { Option } = Select;

const MachinesStatusTable: FC<IMyProps> = (props: IMyProps) => {
  const { machinesStatusData, statusTableLoading } = props;
  const { get } = useConnection();
  const [deviceDetails, setDeviceDetails] = useState<MachineStatus[]>([]);
  const [machineDetailsFilter, setMachineDetailsFilter] = useState<
    MachineStatus[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [expandedKey, setExpandedKey] = useState<number[]>([]);
  const [utilizationDetails, setUtilizationDetails] = useState<
    MachineUtilization | undefined
  >();
  const { post } = useConnection();
  const history = useHistory();
  const [pagination, setPagination] = useState<Pagination>();
  const [value, setValue] = useState("");
  const [checkAllStatus, setCheckAllStatus] = useState(true);
  const [checkAllIOT, setCheckAllIOT] = useState(true);
  const [modal, setModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  // const machineStatus = ["ON", "OFF", "UNKNOWN"];
  const machineStatus = ["RUNNING", "NOT RUNNING", "UNKNOWN"];
  const iotDevices = [
    "CP - WISE - 1",
    "CP - WISE - 2",
    "CP - WISE - 3",
    "AP - WISE - 1",
  ];
  const [checkedListStatus, setCheckedListStatus] = useState(machineStatus);
  const [checkedListIOT, setCheckedListIOT] = useState(iotDevices);

  const children: React.ReactNode[] = [];
  for (let i = 10; i < 36; i++) {
    children.push(
      <Option value={i} key={i.toString(36) + i}>
        {i.toString(36) + i}
      </Option>,
    );
  }

  useEffect(() => {
    console.log("machine status table data => ", machinesStatusData);
    doFiltering(machinesStatusData);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      doFiltering(machinesStatusData);
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const formatTime = (time: number) => {
    const hrs = Math.floor(time / 60);
    const minites = Math.floor(time % 60);
    return `${hrs}h ${minites}m`;
  };

  const checkExistance = (el: any, activeTab: any, key: any) => {
    if (el && el[activeTab]) {
      return formatTime(el[activeTab][key]);
    } else {
      return "-";
    }
  };

  const machineUtilization = async (val: number) => {
    setLoading(true);
    try {
      const data: Query = {
        src: {
          id: val,
          attr: "OnOff",
          type: "MACHINE",
        },
        start: DateTime.now().startOf("day").valueOf(),
        duration: "P1D",
      };
      const resultOverall = await post(
        "report/overallUtilizationReport",
        {
          machineID: val,
          date: DateTime.fromMillis(data.start).toFormat("yyyyMMdd"),
        },
        undefined,
        SERVICE_TYPE.serviceType
      );

      let totalInMilli =
        !resultOverall ||
        !resultOverall.data ||
        !resultOverall?.data["daily"] ||
        !resultOverall?.data["daily"]["total"]
          ? 0
          : resultOverall?.data["daily"].total.totalMin * 60 * 1000;
      // .reduce((a: any, b: any) => +a + +b.totalMin, 0) * 60 * 1000

      const totalH = Math.round(Duration.fromMillis(totalInMilli).as("hour"));

      const elapsed =
        Math.min(new Date().getTime(), data.start + 86400000) - data.start;
      const percentage = Math.min((100 * totalInMilli) / elapsed, 100);

      setUtilizationDetails({
        total: checkExistance(
          resultOverall?.data["daily"],
          "total",
          "totalMin",
        ),
        week_avg: checkExistance(
          resultOverall?.data["weeklyAvg"],
          "total",
          "totalMin",
        ),
        month_avg: checkExistance(
          resultOverall?.data["monthlyAvg"],
          "total",
          "totalMin",
        ),
        overall_avg: checkExistance(
          resultOverall?.data["overallAvg"],
          "total",
          "totalMin",
        ),
        percentage: percentage,
        totalH: totalH,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Machine",
      dataIndex: "machineName",
      key: "machineName",
      render: (text: string, record: MachineStatus) => (
        <div>
          <span>{record.machineName}</span>
          <br />
          {/* {LocationLoading === true ? (
            <Skeleton active />
          ) : ( */}
          <span className="machineLocation">
            {/* Location: {findMachineLocation(record.machineID)} */}
            {/* Location */}
          </span>
          {/* )} */}
        </div>
      ),
    },
    {
      title: "Machine Status",
      dataIndex: "machineStatus",
      key: "machineStatus",
      render: (text: string, record: MachineStatus) => (
        <>
          <div style={{ marginRight: "40px" }}>
            <span>
              <Tag
                color={
                  record.status === 1
                    ? "green"
                    : record.status === 0
                    ? "red"
                    : record.status === -1
                    ? "gold"
                    : undefined
                }
                style={{
                  fontFamily: "Poppins",
                  display: "inline-flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "5px",
                  fontWeight: 600,
                  backgroundColor:
                    record.status === 1
                      ? "#99F399"
                      : record.status === 0
                      ? "#FAA0A0"
                      : record.status === -1
                      ? "#FFE99A"
                      : undefined,
                }}
              >
                <IoRadioButtonOnSharp
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "5px",
                  }}
                />
                {record.status === 1
                  ? "RUNNING"
                  : record.status === 0
                  ? "NOT RUNNING"
                  : record.status === -1
                  ? "UNKNOWN"
                  : undefined}
              </Tag>
            </span>
            <br />
            {/* <span className="utilization">Utilization:</span> */}
          </div>
        </>
      ),
    },
    {
      title: "IOT Device",
      dataIndex: "iotDevice",
      key: "iotDevice",
      render: (text: string, record: MachineStatus) => (
        <>
          <div style={{ marginRight: "30px" }}>
            <span className="machineStatus">
              <Tag
                color={
                  record.deviceStatus === 1
                    ? "green"
                    : record.deviceStatus === 0
                    ? "red"
                    : undefined
                }
                style={{
                  fontFamily: "Poppins",
                  display: "inline-flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "5px",
                  fontWeight: 600,
                  backgroundColor:
                    record.deviceStatus === 1
                      ? "#99F399"
                      : record.deviceStatus === 0
                      ? "#FAA0A0"
                      : undefined,
                }}
              >
                <IoRadioButtonOnSharp
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "5px",
                  }}
                />
                {record.deviceStatus === 1
                  ? "ON"
                  : record.deviceStatus === 0
                  ? "OFF"
                  : undefined}
              </Tag>
            </span>
            <br />
            <span className="iotDevice">{record.deviceName}</span>
          </div>
        </>
      ),
    },
  ];

  const progressColor = (perc: number) => {
    perc = 100 - perc;
    let r;
    let g;
    const b = 0;
    if (perc < 50) {
      r = 255;
      g = Math.round(5.1 * perc);
    } else {
      g = 255;
      r = Math.round(510 - 5.1 * perc);
    }
    const h = r * 0x10000 + g * 0x100 + b * 0x1;
    return `#${`000000${h.toString(16)}`.slice(-6)}`;
  };

  const onFinish = (values: any) => {
    console.log("view more values", values);
    history.push({
      pathname: "/report/utilization",
      state: {
        src: {
          id: values.machineID,
          attr: values,
          type: "MACHINE",
        },
        start: DateTime.now().startOf("day").valueOf(),
        duration: "P1D",
      },
    });
  };

  const handleChange = (nav: any) => {
    setPagination({ currentPage: nav.current });
    setExpandedKey([]);
  };

  const onChangeStatus = (e: any) => {
    setCheckedListStatus(e);
    setCheckAllStatus(e.length === machineStatus.length);
  };

  const onChangeStatusCheckAll = (e: any) => {
    setCheckAllStatus(e.target.checked);
    // const mStatus = ["ON", "OFF", "UNKNOWN"];
    setCheckedListStatus(e.target.checked ? machineStatus : []);
  };

  const onChangeIOT = (e: any) => {
    setCheckedListIOT(e);
    setCheckAllIOT(e.length === iotDevices.length);
  };

  const onChangeIOTCheckAll = (e: any) => {
    setCheckAllIOT(e.target.checked);
    setCheckedListIOT(e.target.checked ? iotDevices : []);
  };

  const showModal = () => {
    setModal(true);
  };

  const doFiltering = (details: MachineStatus[]) => {
    setConfirmLoading(true);
    if (checkAllIOT && checkAllStatus) {
      setMachineDetailsFilter(details);
      setConfirmLoading(false);
      setModal(false);
    } else if (checkedListIOT.length === 0 && checkedListStatus.length === 0) {
      message.open({
        type: "error",
        content: "You should select atleast one Option",
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
      setConfirmLoading(false);
    } else if (checkedListIOT.length === 0 || checkedListStatus.length === 0) {
      const temp: number[] = checkedListStatus.map((v: string) =>
        v === "RUNNING" ? 1 : v === "NOT RUNNING" ? 0 : -1,
      );
      const filtered = details.filter(
        (d) => checkedListIOT.includes(d.deviceName) || temp.includes(d.status),
      );
      setMachineDetailsFilter(filtered);
      setConfirmLoading(false);
      setModal(false);
    } else {
      const temp: number[] = checkedListStatus.map((v: string) =>
        v === "RUNNING" ? 1 : v === "NOT RUNNING" ? 0 : -1,
      );
      const filtered = details.filter(
        (d) => checkedListIOT.includes(d.deviceName) && temp.includes(d.status),
      );
      setMachineDetailsFilter(filtered);
      setConfirmLoading(false);
      setModal(false);
    }
  };

  const handleOk = () => {
    doFiltering(machinesStatusData);
  };

  const handleCancel = () => {
    setModal(false);
  };

  const searchByName = (val: string) => {
    const currValue = val;
    setValue(currValue);
    // const filteredData = deviceDetails.filter((entry) =>
    //   entry.machineName.toLowerCase().includes(currValue.toLowerCase()),
    // );
    // currValue === "" ? getData(false) : setMachineDetailsFilter(filteredData);
  };

  const selectAll = () => {
    setCheckAllStatus(true);
    setCheckAllIOT(true);
    setCheckedListIOT(iotDevices);
    setCheckedListStatus(machineStatus);
  };

  return (
    <div className="machineTableContainer">
      {!statusTableLoading ? (
        <>
          <div>
            <Table
              showHeader
              loading={statusTableLoading}
              expandIconColumnIndex={3}
              columns={columns}
              pagination={{
                current: pagination?.currentPage,
                showSizeChanger: true,
                showQuickJumper: true,
              }}
              onChange={handleChange}
              expandable={{
                expandedRowRender: () =>
                  loading ? (
                    <div className="spinner">
                      <p className="paragraph">
                        <IoInformationCircleSharp
                          size={30}
                          color="rgba(7, 61, 116, 0.5)"
                          style={{ marginRight: "10px" }}
                        />
                        Gathering Information
                        <Spin style={{ marginLeft: "20px" }} />
                      </p>
                    </div>
                  ) : (
                    <div className="expandedDetails">
                      <div className="utilizationProgress">
                        Utilization
                        <Progress
                          format={() => `${utilizationDetails?.totalH} Hours`}
                          width={125}
                          type="circle"
                          percent={utilizationDetails?.percentage}
                          strokeColor={progressColor(
                            utilizationDetails
                              ? utilizationDetails?.percentage
                              : 0,
                          )}
                          strokeWidth={10}
                          trailColor="#3F3F3F"
                          style={{ marginTop: "5px" }}
                        />
                      </div>
                      <div className="details-container-expanded">
                        <div className="util-detail-header">
                          <div className="utilizationDetails">
                            <span>Total Running Time</span>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Row
                            style={{
                              display: "flex",
                              flex: 1,
                            }}
                          >
                            <Col span={40}>
                              <Row>
                                <Col span={10}>
                                  <div className="details1">
                                    <div className="details2">
                                      <span>Hours :</span>
                                    </div>
                                  </div>
                                </Col>
                                <Col span={10}>
                                  <div className="details1">
                                    <div className="details2">
                                      <div>{utilizationDetails?.total}</div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={40}>
                              <Row>
                                <Col span={10}>
                                  <div className="details1">
                                    <div className="details2">
                                      <span>Overall Average :</span>
                                    </div>
                                  </div>
                                </Col>
                                <Col span={10}>
                                  <div className="details1">
                                    <div className="details2">
                                      <div>
                                        {utilizationDetails?.overall_avg}
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row
                            style={{
                              display: "flex",
                              flex: 1,
                            }}
                          >
                            <Col span={40}>
                              <Row>
                                <Col span={10}>
                                  <div className="details1">
                                    <div className="details2">
                                      <span>Weekly Average :</span>
                                    </div>
                                  </div>
                                </Col>
                                <Col span={10}>
                                  <div className="details1">
                                    <div className="details2">
                                      <div>{utilizationDetails?.week_avg}</div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={40}>
                              <Row>
                                <Col span={10}>
                                  <div className="details1">
                                    <div className="details2">
                                      <span>Monthly Average :</span>
                                    </div>
                                  </div>
                                </Col>
                                <Col span={10}>
                                  <div className="details1">
                                    <div className="details2">
                                      <div>{utilizationDetails?.month_avg}</div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  ),
                expandIcon: ({ expanded, onExpand, record }) =>
                  expanded ? (
                    <div className="viewMore-container">
                      <div
                        onClick={(e) => {
                          console.log("dsdaa", record, e);
                          onExpand(record, e);
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        <span>
                          <a className="viewMore">
                            <IoChevronUpCircleOutline
                              size={17}
                              color="#C54444"
                              style={{ marginRight: "10px" }}
                            />
                            Summary
                          </a>
                        </span>
                      </div>
                      <div>
                        <span>
                          <a
                            className="viewMore"
                            onClick={() => onFinish(record)}
                          >
                            View More
                          </a>
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="viewMore-container">
                      <div
                        onClick={(e) => {
                          console.log("dsdaa", record, e);
                          onExpand(record, e);
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        <span>
                          <a className="viewMore">
                            <IoChevronDownCircleOutline
                              size={17}
                              color="#aeaeae"
                              style={{ marginRight: "10px" }}
                            />
                            Summary
                          </a>
                        </span>
                      </div>
                      <div>
                        <span>
                          <a
                            className="viewMore"
                            onClick={() => onFinish(record)}
                          >
                            View More
                          </a>
                        </span>
                      </div>
                    </div>
                  ),
                onExpand: (expanded, { machineID }) => {
                  expanded
                    ? (setExpandedKey([machineID]),
                      machineUtilization(machineID))
                    : (setExpandedKey([]), setUtilizationDetails(undefined));
                },
                expandedRowKeys: expandedKey,
              }}
              dataSource={machinesStatusData}
              rowKey={(record) => record.machineID}
              rowClassName={(record) =>
                expandedKey[0] === record.machineID
                  ? "expand-parent"
                  : "ant-table-tbody"
              }
            />
            <Modal
              title="Sort By"
              visible={modal}
              onOk={handleOk}
              confirmLoading={confirmLoading}
              onCancel={handleCancel}
              className="filter-modal"
            >
              <div className="filters">
                <div className="machineStatus">
                  <span>By Machine Status :</span> <br />
                  <Checkbox
                    checked={checkAllStatus}
                    onChange={onChangeStatusCheckAll}
                  >
                    All
                  </Checkbox>
                  <Checkbox.Group
                    options={machineStatus}
                    onChange={onChangeStatus}
                    value={checkedListStatus}
                    style={{
                      display: "flex",
                      flex: "1",
                      flexDirection: "column",
                    }}
                  />
                </div>
                <div className="iotDevices">
                  <span>By IOT Device :</span> <br />
                  <Checkbox
                    checked={checkAllIOT}
                    onChange={onChangeIOTCheckAll}
                  >
                    All
                  </Checkbox>
                  <Checkbox.Group
                    options={iotDevices}
                    onChange={onChangeIOT}
                    value={checkedListIOT}
                    style={{
                      display: "flex",
                      flex: "1",
                      flexDirection: "column",
                    }}
                  />
                </div>
                <div className="reset-Filter">
                  <span role="button" tabIndex={0} onClick={selectAll}>
                    Reset
                  </span>
                </div>
              </div>
            </Modal>
          </div>
        </>
      ) : (
        <Skeleton active paragraph={{ rows: 10 }} round />
      )}
    </div>
  );
};

export default MachinesStatusTable;
