import { Dropdown, Layout, Menu, Avatar, Switch } from "antd";
import React, { FC, useState, useEffect } from "react";
import {
  BellFilled,
  CaretDownFilled,
  FlagFilled,
  UserOutlined,
} from "@ant-design/icons";
import { IoNotificationsOutline, IoSettingsOutline } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { useConnection } from "../../Services/ApplicationContext";
import { useUser } from "../../Services/UserContext";
import "./NotificationDropdownBottom.scss";

const NotificationDropdownBottom: FC = () => {
  const onChange = (checked: boolean) => {
    console.log(`switch to ${checked}`);
  };

  return (
    <div className="notification-bottom-component">
      <div className="overflow-notification">
        <div className="icon">
          <FlagFilled
            style={{
              color: "#3086eb",
              fontSize: "30px",
            }}
          />
        </div>
        <div className="text">
          <span>That's all your notifications from the last 30 days.</span>
        </div>
      </div>
      <div className="view-all">
        <a href="/settings/notifications">
          <span>View all notifications...</span>
        </a>
      </div>
    </div>
  );
};
export default NotificationDropdownBottom;
