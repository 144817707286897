/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
import React, { useState, useEffect, FC } from "react";
import {
  Table,
  Button,
  Popconfirm,
  Form,
  Space,
  message,
  Skeleton,
  Empty,
  Input,
  Row,
  Col,
  Select,
  Dropdown,
  Menu,
  Radio,
  Checkbox,
} from "antd";
import { TablePaginationConfig } from "antd/es/table/Table";
import {
  EditFilled,
  DeleteFilled,
  PlusOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import {
  IoChevronDown,
  IoCreateOutline,
  IoSearch,
  IoSettingsOutline,
  IoTrashSharp,
  IoWarning,
} from "react-icons/io5";
import { DateTime } from "luxon";
import fil from "date-fns/esm/locale/fil/index.js";
import { useConnection } from "../../Services/ApplicationContext";
import { Actions, Authorizer } from "../../Services/AuthorizationContext";
import UpdateMachines from "./UpdateMachines";
import {
  ILocation,
  IMachine,
  IMachineDetails,
  IMachineRow,
} from "../../definetions/MachineModel";
import "./Styles.scss";
import { Plant } from "../../definetions/Constatnts";
import "../tableStyles.scss";
import "../pagination.scss";
import { checkEdgeValidations } from "../CableProduction/cableFlowChartValidation";
import { SERVICE_TYPE } from "../../constants/constants";

interface Pagination {
  currentPage: number;
  pageSize: number;
}

const { Option } = Select;

const MachineTable: FC = () => {
  const [form] = Form.useForm();
  const { get, delete: del, post } = useConnection();
  const [loading, setLoading] = useState<boolean>(true);
  const [machineData, setMachineData] = useState<IMachineDetails[]>([]);
  const [searchData, setSearchData] = useState<IMachineDetails[]>([]);
  const [locationData, setLocationData] = useState<ILocation[]>([]);
  const [selectedRow, setSelectedRow] = useState<IMachineRow>();
  const [total, setTotal] = useState<number>(0);
  const [
    addMachinesModalVisible,
    setAddMachinesModalVisible,
  ] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState("");
  const initialValue = {
    currentPage: 1,
    pageSize: 10,
  };
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [pageSize, setPageSize] = useState<any>(10);
  const [sortOrder, setSortOrder] = useState<string>("");
  const [sortField, setSortField] = useState<string>("");
  const [machineLocation, setmachineLocation] = useState<string>("");

  const [plants, setPlants] = useState<any[]>([]);
  const [filterByPlantsLists, setFilterByPlantsLists] = useState<any[]>([]);
  const [filterByMachineFamily, setFilterByMachineFamily] = useState<any[]>([]);
  const [plantsLoading, setPlantsLoading] = useState<boolean>(false);

  const mapMachineData = (
    machines: IMachine[],
    locations: ILocation[],
  ): IMachineDetails[] => {
    return machines.map((val) => ({
      ...val,
      locationName: locations.find(
        (value) => value.locationID === val.locationID,
      )?.locationName,
    }));
  };

  const getAllMachinesRequestParams = () => {
    return {
      page: currentPage - 1,
      size: pageSize,
      name:
        searchValue !== "" ? searchValue.replace(/^\s+|\s+$/g, "") : undefined,
      orderBy: sortField !== "" ? sortField : undefined,
      order: sortOrder !== "" ? sortOrder : undefined,
      locationIDs:
        filterByPlantsLists?.length > 0 ? filterByPlantsLists : undefined,
      family:
        filterByMachineFamily?.length > 0 ? filterByMachineFamily : undefined,
    };
  };

  const getLocationList = async () => {
    setPlantsLoading(true);
    try {
      const allLocationsResult: any = await post(
        "system-management/location/getLocationsList",
        {
          page: 0,
          size: 10000,
        },
        undefined,
        SERVICE_TYPE.serviceType
      );
      setPlants(allLocationsResult?.data?.list);
    } catch (error: any) {
      console.log("error in getting location lists - ", error);
    } finally {
      setPlantsLoading(false);
    }
  };

  const getData = async () => {
    setLoading(true);
    try {
      const resultLocations: any = await get(
        "system-management/location/getAuthorizedLocations",
        undefined,
        SERVICE_TYPE.serviceType
      );
      const resultMachines: any = await post(
        "system-management/machine/getMachines",
        getAllMachinesRequestParams(),
        undefined,
        SERVICE_TYPE.serviceType
      );
      // const allMachinesForSearch = await post("machine/getMachines", {
      //   page: 0,
      //   size: 10000,
      // });
      setLocationData(resultLocations.data.list);
      // eslint-disable-next-line no-restricted-syntax
      const locationArray: string[] = [];
      for (const i of resultLocations.data.list) {
        locationArray.push(i.locationName);
        const withSpaces = locationArray.join(", ");
        setmachineLocation(withSpaces);
      }
      const list: IMachineDetails[] = mapMachineData(
        resultMachines.data.list,
        resultLocations.data.list,
      );
      // const searchList: IMachineDetails[] = mapMachineData(
      //   allMachinesForSearch.data.list,
      //   allMachinesForSearch.data.list,
      // );
      setTotal(resultMachines.data.total);
      setMachineData(list);
      // setSearchData(searchList);
      setLoading(false);
    } catch (error: any) {
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [
    currentPage,
    pageSize,
    searchValue,
    sortField,
    sortOrder,
    filterByPlantsLists,
    filterByMachineFamily,
  ]);

  useEffect(() => {
    getLocationList();
  }, []);

  useEffect(() => {
    console.log("plants list ------ > ", plants);
  }, [plants]);

  const edit = (record: any) => {
    setSelectedRow(record);
  };

  const Delete = async (record: IMachineRow) => {
    setLoading(true);
    try {
      const result = await del(
        `system-management/machine/deleteMachine/${record.machineID}`,
        undefined,
        SERVICE_TYPE.serviceType
      );
      if (result.status === 200 || result.status === 201) {
        message.open({
          type: "success",
          content: result.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
        const temp = [...machineData];
        const index = temp.findIndex(
          (value) => value.machineID === record.machineID,
        );
        if (index > -1) {
          temp.splice(index, 1);
          setMachineData(temp);
        }
      }
      setLoading(false);
    } catch (error: any) {
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
    }
    setLoading(false);
  };

  const onModalClose = (add: "add" | "edit", val?: IMachineRow) => {
    setSelectedRow(undefined);
    if (val) {
      const temp = [...machineData];
      const index = temp.findIndex(
        (item: IMachineDetails) => val.machineID === item.machineID,
      );
      if (add === "add") {
        temp.push({ ...val });
      }
      if (add === "edit") {
        if (index > -1) {
          temp.splice(index, 1, val);
        }
      }
      setMachineData(temp);
    }

    setAddMachinesModalVisible(false);
  };

  const columns: any = [
    {
      title: "Machine Name",
      dataIndex: "machineName",
      key: "machineName",
      sorter: true,
    },
    {
      title: "Last Updated On",
      key: "CreatedAt",
      sorter: true,
      render: (_: any, record: any) => {
        if (record.lastUpdated !== undefined) {
          return DateTime.fromMillis(record.lastUpdated).toFormat(
            "dd:MM:yyyy,  hh:mm:ss a",
          );
        }
        return "-";
      },
    },
    {
      title: "Machine Family",
      dataIndex: "machineFamily",
      key: "machineFamily",
    },
    { title: Plant, dataIndex: "locationName", key: "locationName" },
    {
      title: "Action",
      dataIndex: "operation",
      fixed: "right",
      render: (_: any, record: any) => {
        return (
          <Space size="middle">
            <a onClick={() => edit(record)}>
              <IoCreateOutline size={22} color="#487199" />
            </a>
            <Popconfirm
              title={`Are you sure to delete ${record.machineName}?`}
              onConfirm={() => Delete(record)}
              okText="Yes"
              okButtonProps={{ danger: true }}
              cancelText="No"
              icon={<IoWarning size={30} color="#eec335" />}
              placement="topLeft"
              cancelButtonProps={{ type: "primary" }}
            >
              <a>
                <IoTrashSharp size={20} color="#f18b8b" />
              </a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const mapData = (): IMachineDetails[] => {
    return machineData.map((val, index) => ({
      ...val,
      key: index,
    }));
  };

  const searchByName = (val: string) => {
    const currValue = val;
    setSearchValue(currValue);
    setCurrentPage(1);
    setPageSize(10);
  };

  const onFilterChangeActionPlants = (checkedValues: any) => {
    console.log("checked = ", checkedValues);
    setFilterByPlantsLists(checkedValues);
    setCurrentPage(1);
    setPageSize(10);
  };

  const onFilterChangeActionMachineFamily = (checkedValues: any) => {
    console.log("checked = ", checkedValues);
    setFilterByMachineFamily(checkedValues);
    setCurrentPage(1);
    setPageSize(10);
  };

  const machineFamilies: string[] = [
    "Drawer",
    "Strander",
    "Winder",
    "Twister",
    "Breaker",
    "Taper",
    "Armorer",
    "Chamber",
  ];

  const childrenPlants: React.ReactNode[] = [];
  if (plants?.length > 0) {
    plants?.map((plant: any, index: any) => {
      childrenPlants.push(
        <Option value={plant?.locationID} key={plant?.locationID}>
          {plant?.locationName}
        </Option>,
      );
    });
  }

  const childrenMachineFamily: React.ReactNode[] = [];
  if (machineFamilies?.length > 0) {
    machineFamilies?.map((machineFamily: any, index: any) => {
      childrenMachineFamily.push(
        <Option value={machineFamily} key={index.toString()}>
          {machineFamily}
        </Option>,
      );
    });
  }

  const handleChange = (val: any, sorter: any) => {
    console.log(
      " ------ val table machine --------",
      val,
      "------sorter------",
      sorter,
    );
    if (sorter.order === "ascend") {
      setSortOrder("asc");
    } else if (sorter.order === "descend") {
      setSortOrder("desc");
    } else if (sorter.order === undefined) {
      setSortOrder("");
    }
    if (sorter.columnKey !== undefined) {
      setSortField(sorter.columnKey);
    } else {
      setSortField("");
    }
    val?.current && setCurrentPage(val?.current);
    val?.pageSize && setPageSize(val?.pageSize);
  };

  useEffect(() => {
    if (addMachinesModalVisible === true || selectedRow !== undefined) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [addMachinesModalVisible, selectedRow]);

  return (
    <div>
      {selectedRow !== undefined && (
        <UpdateMachines
          locations={locationData}
          data={selectedRow}
          visible={selectedRow !== undefined}
          onClose={onModalClose}
          locationStatus="edit"
          setSelectedRow={setSelectedRow}
          getData={getData}
        />
      )}

      {addMachinesModalVisible && (
        <UpdateMachines
          locations={locationData}
          visible={addMachinesModalVisible}
          onClose={onModalClose}
          locationStatus="add"
          setSelectedRow={setSelectedRow}
          getData={getData}
        />
      )}
      <div className="main-container-machines">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // justifyContent: "space-between",
            // alignItems: "center",
            width: "100%",
            marginBottom: "30px",
            position: "sticky",
            top: "0",
            backgroundColor: "#1b1b1b",
            zIndex: 20,
            paddingTop: "30px",
            paddingBottom: "30px",
          }}
        >
          <Row className="actions_row">
            <Col xl={5} lg={6} md={6} sm={24} style={{ width: "100%" }}>
              <div className="btn_section">
                <Authorizer
                  data={{
                    feature: "10",
                    action: Actions.HIDE,
                  }}
                >
                  <Button
                    type="primary"
                    // className="add-device-btn"
                    style={{
                      borderRadius: "5px",
                      height: "45px",
                      fontSize: "15px",
                      fontWeight: 300,
                      fontFamily: "Poppins",
                    }}
                    onClick={() => setAddMachinesModalVisible(true)}
                  >
                    <PlusOutlined style={{ fontSize: "17px" }} />
                    Add Machine
                  </Button>
                </Authorizer>
              </div>
            </Col>
            <Col xl={19} lg={18} md={18} sm={24} style={{ width: "100%" }}>
              <Row className="actions_filter_row">
                <Col xl={8} lg={8} md={9} sm={8} style={{ width: "100%" }}>
                  <div className="searchby_filter">
                    <Select
                      className="select-color"
                      mode="multiple"
                      allowClear
                      maxTagCount={2}
                      maxTagTextLength={10}
                      style={{ width: "95%", height: 45 }}
                      placeholder="Filter by Machine family"
                      defaultValue={filterByMachineFamily}
                      onChange={onFilterChangeActionMachineFamily}
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {childrenMachineFamily}
                    </Select>
                  </div>
                </Col>
                <Col xl={8} lg={8} md={9} sm={8} style={{ width: "100%" }}>
                  <div className="searchby_filter">
                    <Select
                      className="select-color"
                      mode="multiple"
                      allowClear
                      maxTagCount={2}
                      maxTagTextLength={10}
                      style={{ width: "95%", height: 45 }}
                      placeholder="Filter by Plants"
                      defaultValue={filterByPlantsLists}
                      onChange={onFilterChangeActionPlants}
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {childrenPlants}
                    </Select>
                  </div>
                </Col>
                <Col xl={8} lg={8} md={6} sm={8} style={{ width: "100%" }}>
                  <div className="filter_section">
                    <Input
                      placeholder="Search by Machine Name"
                      className="machine-search-bar"
                      prefix={
                        <IoSearch
                          size={20}
                          color="rgba(255, 255, 255, 0.6)"
                          style={{
                            marginRight: "5px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        />
                      }
                      value={searchValue}
                      style={{ height: "45px" }}
                      onChange={(e) => searchByName(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Form form={form} component={false}>
          <div
            className="general-table general-pagination"
            style={{ marginTop: "20px" }}
          >
            <Table
              // className="components-table-demo-nested"
              columns={columns}
              dataSource={machineData}
              loading={loading}
              pagination={{
                current: currentPage,
                pageSize,
                total,
                showQuickJumper: true,
                showSizeChanger: true,
              }}
              onChange={(val: any, filter: any, sorter: any) => {
                handleChange(val, sorter);
                console.log(sorter, "mmmmmmmm");
              }}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                      total === 0 &&
                      !searchValue &&
                      filterByPlantsLists.length === 0
                        ? `No machines available in ${machineLocation}`
                        : "No data"
                    }
                  />
                ),
              }}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default MachineTable;
