/* eslint-disable no-param-reassign */
import React, { FC, useEffect, useState } from "react";
import { Button, Form, Input, Modal, message } from "antd";
import { useConnection } from "../../Services/ApplicationContext";
import "../modalPopUp.scss";
import { MaterialType } from "../../definetions/ProjectModal";
import { SERVICE_TYPE } from "../../constants/constants";

const formItemLayout = {
  labelCol: {
    span: 6,
  },
};
export interface LabourModalProps {
  visible: boolean;
  onClose: (val?: any) => void;
  type: "Add" | "Edit";
  data?: any;
  title: string;
  onUpdate: () => void;
}
const LabourModal: FC<LabourModalProps> = (props: LabourModalProps) => {
  const [form] = Form.useForm();
  const { visible, onClose, type, data, title, onUpdate } = props;
  const [loading, setLoading] = useState(false);

  const { post, put } = useConnection();

  const onConfirm = async (values: any) => {
    setLoading(true);
    console.log(values);
    const lName = values.resourceName;
    const fLName = lName
      .split(" ")
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    values.resourceName = fLName.trim();
    console.log(values, fLName);
    try {
      let result;
      let { materialType } = values;
      materialType = MaterialType.LABOUR;

      if (type === "Add") {
        result = await post(
          "system-management/resources/createResource",
          {
            ...values,
            materialType,
          },
          undefined,
          SERVICE_TYPE.serviceType
        );
      } else {
        console.log(" ------ update values ---------", values);
        result = { statusText: "SUCCESS" };
        result = await put(
          "system-management/resources/updateResource",
          {
            ...values,
            id: data.id,
            materialType
          },
          undefined,
          SERVICE_TYPE.serviceType
        );
      }
      if (result.statusText === "SUCCESS") {
        message.open({
          type: "success",
          content: result.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
        onUpdate();
        form.resetFields();
        onClose(type);
      } else {
        message.open({
          type: "warning",
          content: result.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      }
      setLoading(false);
    } catch (e: any) {
      message.open({
        type: "error",
        content: e.message,
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   console.log(form.getFieldsValue());
  //   // form.setFieldsValue(data);
  // });
  return (
    <>
      <Modal
        title={`${type} ${title}`}
        centered
        visible={visible}
        onCancel={() => {
          form.resetFields();
          onClose();
        }}
        width="50%"
        footer={null}
        className="general-modal-popup"
      >
        <Form
          // eslint-disable-next-line react/jsx-props-no-spreading
          // {...formItemLayout}
          form={form}
          //   name="bobbins"
          hideRequiredMark={false}
          initialValues={data || null}
          onFinish={onConfirm}
          scrollToFirstError
          autoComplete="off"
          style={{ justifyContent: "center" }}
          className="ant-advanced-search-form"
          layout="vertical"
        >
          <Form.Item
            name="resourceName"
            label="Labour Type"
            className="display"
            initialValue={data?.resourceName}
            rules={[
              {
                required: true,
                message: "Required field!",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value) {
                    return Promise.resolve();
                  }

                  if (String(value).match(/^\s*$/)) {
                    return Promise.reject(new Error("Required field!"));
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="Enter Labour Type" />
          </Form.Item>

          <Form.Item
            name="hourlyCost"
            label="Hourly Cost"
            initialValue={data?.hourlyCost}
            className="display"
            rules={[
              {
                required: true,
                message: "Required field!",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value) {
                    return Promise.resolve();
                  }

                  if (String(value).match(/^\s*$/)) {
                    return Promise.reject(new Error("Required field!"));
                  }

                  // eslint-disable-next-line no-restricted-globals
                  if (isNaN(value)) {
                    return Promise.reject(new Error("Invalid value!"));
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="Enter the Hourly Cost" addonAfter="Rs" />
          </Form.Item>

          <Form.Item
            name="overTimeHourlyCost"
            label="Overtime Hourly Cost"
            className="display"
            initialValue={data?.overTimeHourlyCost}
            rules={[
              {
                required: true,
                message: "Required field!",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value) {
                    return Promise.resolve();
                  }

                  if (String(value).match(/^\s*$/)) {
                    return Promise.reject(new Error("Required field!"));
                  }

                  // eslint-disable-next-line no-restricted-globals
                  if (isNaN(value)) {
                    return Promise.reject(new Error("Invalid value!"));
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="Enter the OT Cost" addonAfter="Rs" />
          </Form.Item>

          <Form.Item className="modal-field-button">
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              style={{ marginRight: "15px" }}
            >
              {`${type === "Add" ? "Add Labour" : "Edit Labour"}`}
            </Button>
            <Button
              loading={loading}
              danger
              type="primary"
              onClick={() => {
                form.resetFields();
                onClose();
              }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default LabourModal;
