/* eslint-disable no-underscore-dangle */
import React, { FC, useEffect, useState } from "react";
import {
  Button,
  Empty,
  Input,
  message,
  Popconfirm,
  Skeleton,
  Space,
  Table,
  Tooltip,
} from "antd";
import {
  PlusOutlined,
  MinusOutlined,
  SearchOutlined,
} from "@ant-design/icons/lib";
import {
  IoCreateOutline,
  IoSettingsOutline,
  IoTrashSharp,
  IoWarning,
} from "react-icons/io5";
import { DateTime } from "luxon";
import { useConnection } from "../../Services/ApplicationContext";
import RawMaterialModal from "./RawMaterialModal";
import EditRawMaterialAvailableModal from "./EditRawMaterialAvailableModal";
import { ResourceDetails } from "../../definetions/ProjectModal";
import "../tableStyles.scss";
import "../pagination.scss";
import "./rawMaterial.scss";
import { SERVICE_TYPE } from "../../constants/constants";

export interface RawMaterialManagementProps {
  data: ResourceDetails[];
  onUpdateData: () => void;
  title: string;
  total: number;
  pageSize: number;
  currentPage: number;
  handleChange: (val: any, sorter: any) => void;
  setSearchValue: (val: string) => void;
  searchValue: string;
  setNetworkSearchValue: (val: string) => void;
  onSearch: () => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
}
const RawMaterialManagement: FC<RawMaterialManagementProps> = (
  props: RawMaterialManagementProps,
) => {
  const {
    data,
    onUpdateData,
    title,
    total,
    currentPage,
    pageSize,
    handleChange,
    setSearchValue,
    searchValue,
    setNetworkSearchValue,
    onSearch,
    loading,
    setLoading,
  } = props;
  const { delete: del } = useConnection();

  const [selectedRow, setSelectedRow] = useState();
  // const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);

  const [editAvailable, setEditAvailable] = useState<boolean>(false);
  const [editAvailableAction, setEditAvailableAction] = useState<
    "plus" | "minus"
  >("plus");
  const [recordToEditAvailable, setRecordToEditAvailable] = useState();
  // const [pageSize, setPageSize] = useState<number>(5);

  const handleEditAvaliableCount = (record: any, type: "plus" | "minus") => {
    setRecordToEditAvailable(record);
    setEditAvailable(true);
    setEditAvailableAction(type);
  };

  const handleEditAvailableModal = (value: string) => {
    if (value) {
      onUpdateData();
    }
    setEditAvailable(false);
    setRecordToEditAvailable(undefined);
  };

  const edit = (record: any) => {
    setSelectedRow(record);
  };
  const Delete = async (record: any) => {
    setLoading(true);
    // console.log(" ------ delete values ---------", record);
    try {
      const result = await del(
        `system-management/resources/deleteResource/${record.key?.toString()}`,
        undefined,
        SERVICE_TYPE.serviceType,
      );
      onUpdateData();
      setLoading(false);
      if (result.status === 200) {
        setNetworkSearchValue("");
        setSearchValue("");
        message.open({
          type: "success",
          content: result.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      }
    } catch (e: any) {
      setLoading(false);
      message.open({
        type: "error",
        content: e.message,
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
    }
  };
  const columns: any[] = [
    {
      title: "Raw Material",
      dataIndex: "resourceName",
      key: "resourceName",
      width: "20%",
      sorter: true,
    },
    {
      title: "Last Updated On",
      key: "CreatedAt",
      sorter: true,
      render: (_: any, record: any) => {
        return DateTime.fromMillis(record.CreatedAt).toFormat(
          "dd:MM:yyyy,  hh:mm:ss a",
        );
      },
    },
    {
      title: "Unit Of Measurement",
      dataIndex: "unitOfMeasurement",
      key: "unitOfMeasurement",
      width: "10%",
    },
    { title: "Allocated", dataIndex: "allocated", key: "allocated" },
    {
      title: "Available",
      dataIndex: "available",
      key: "available",
      fixed: "right",
      render: (_: any, record: any) => {
        const availableStr = record.available.toString();
        const isLong = availableStr.length > 5;
        const truncatedValue = isLong
          ? `${availableStr.slice(0, 4)}...`
          : availableStr;

        return (
          <div
            style={{
              width: 100,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: 50,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                type="primary"
                icon={<PlusOutlined />}
                size="small"
                onClick={() => {
                  handleEditAvaliableCount(record, "plus");
                }}
              />
              <Button
                type="primary"
                danger
                icon={<MinusOutlined />}
                size="small"
                onClick={() => {
                  handleEditAvaliableCount(record, "minus");
                }}
              />
            </div>
            {isLong ? (
              <Tooltip title={availableStr}>
                <span>{truncatedValue}</span>
              </Tooltip>
            ) : (
              <span>{availableStr}</span>
            )}
          </div>
        );
      },
    },
    {
      title: "Total",
      dataIndex: "totalAmount",
      key: "total",
      render: (_: any, record: any) => {
        const totalStr = record.totalAmount.toString();
        const isLong = totalStr.length > 5;
        const truncatedValue = isLong ? `${totalStr.slice(0, 4)}...` : totalStr;

        return isLong ? (
          <Tooltip title={totalStr}>
            <span>{truncatedValue}</span>
          </Tooltip>
        ) : (
          <span>{totalStr}</span>
        );
      },
    },
    {
      title: "Reorder Quantity",
      dataIndex: "reorderQuantity",
      key: "reorderQuantity",
      render: (_: any, record: any) => {
        if (!record?.reorderQuantity) return "-";

        const reorderStr = record.reorderQuantity.toString();
        const isLong = reorderStr.length > 5;
        const truncatedValue = isLong
          ? `${reorderStr.slice(0, 4)}...`
          : reorderStr;

        return isLong ? (
          <Tooltip title={reorderStr}>
            <span>{truncatedValue}</span>
          </Tooltip>
        ) : (
          <span>{reorderStr}</span>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "operation",
      width: "120px",
      fixed: "right",
      render: (_: any, record: any) => {
        return (
          <Space size="middle">
            <a onClick={() => edit(record)}>
              <IoCreateOutline size={22} color="#487199" />
            </a>
            <Popconfirm
              title={`Are you sure to delete ${record?.resourceName}?`}
              onConfirm={() => Delete(record)}
              okText="Yes"
              okButtonProps={{ danger: true }}
              cancelText="No"
              icon={<IoWarning size={30} color="#eec335" />}
              placement="topLeft"
              cancelButtonProps={{ type: "primary" }}
            >
              <a>
                <IoTrashSharp size={20} color="#f18b8b" />
              </a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  const onCloseModal = (val?: any) => {
    if (val) {
      onUpdateData();
    }
    setVisible(false);
    setSelectedRow(undefined);
  };
  return (
    <div>
      <RawMaterialModal
        onClose={onCloseModal}
        visible={visible}
        type="Add"
        title={title}
        data={undefined}
        onUpdate={onUpdateData}
      />
      {selectedRow !== undefined && (
        <RawMaterialModal
          title={title}
          onClose={onCloseModal}
          visible={selectedRow !== undefined}
          type="Edit"
          data={selectedRow}
          onUpdate={onUpdateData}
        />
      )}

      <EditRawMaterialAvailableModal
        onClose={handleEditAvailableModal}
        visible={editAvailable}
        type={editAvailableAction}
        data={undefined}
      />

      {recordToEditAvailable !== undefined && (
        <EditRawMaterialAvailableModal
          onClose={handleEditAvailableModal}
          visible={editAvailable}
          type={editAvailableAction}
          data={recordToEditAvailable}
        />
      )}

      <div className="raw-subHeading-container">
        <p className="raw-sub-text">{title}</p>
      </div>

      <div>
        <div className="row-space-between">
          <div style={{ marginBottom: "30px" }}>
            <Button
              type="primary"
              style={{
                borderRadius: "5px",
                height: "45px",
                fontSize: "15px",
                fontWeight: 300,
                fontFamily: "Poppins",
              }}
              onClick={() => setVisible(true)}
            >
              <PlusOutlined style={{ fontSize: "17px", fontWeight: "bold" }} />
              Add Item
            </Button>
          </div>
          <div style={{ marginBottom: "30px" }}>
            <div className="row end">
              <div
                className="search_filter_lables"
                style={{ marginRight: "7px" }}
              >
                Search:
              </div>
              <Input
                onPressEnter={onSearch}
                onChange={(e: any) => {
                  setSearchValue(e.target.value);
                }}
                value={searchValue}
                placeholder="Search by Raw Material"
                suffix={
                  <Button
                    type="primary"
                    onClick={onSearch}
                    style={{
                      borderRadius: "0 5px 5px 0",
                      marginRight: "-4px",
                    }}
                  >
                    <SearchOutlined />
                  </Button>
                }
              />
            </div>
          </div>
        </div>
        <div className="general-table general-pagination">
          <Table
            className="components-table-demo-nested"
            // columns={columns.filter(
            //   (val: any) =>
            //     val.dataIndex !== "materialType" || title === "Bobbin",
            // )}
            columns={columns}
            dataSource={data}
            loading={loading}
            pagination={{
              current: currentPage,
              pageSize,
              total,
              showQuickJumper: true,
              showSizeChanger: true,
              pageSizeOptions: ["5", "10", "20", "30", "40", "50", "100"],
            }}
            onChange={(val: any, filter: any, sorter: any, extra: any) => {
              handleChange(val, sorter);
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default RawMaterialManagement;
