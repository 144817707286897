import React, { FC } from "react";
import { useParams } from "react-router";
import { ScheduledContextProvider } from "./ScheduleContext";
import MainLayout from "../../components/MainLayout";
import ProjectSchedule from "./ProjectSchedule";

const BaseSchedulePage: FC = () => {
  const params: any = useParams();
  return (
    <ScheduledContextProvider id={params.id}>
      <MainLayout type="primary" title="Project Schedule">
        <ProjectSchedule />
      </MainLayout>
    </ScheduledContextProvider>
  );
};
export default BaseSchedulePage;
