import { Button, Modal, Space, Table } from "antd";
import React, { FC } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export interface AlteredTableModalProps {
  visible: boolean;
  timelineID: string;
  onClose: () => void;
}

const AlteredTableModal: FC<AlteredTableModalProps> = (
  props: AlteredTableModalProps,
) => {
  const { visible, timelineID, onClose } = props;
  const columns: any = [
    {
      title: "Project Name",
      dataIndex: "projectName",
      key: "sourceName",
      width: 150,
    },
    {
      title: "Project Name",
      dataIndex: "startDate",
      key: "type",
      width: 100,
      sorter: false,
    },
    {
      title: "Current End Date",
      dataIndex: "endDate",
      key: "date",
      width: 100,
      sorter: false,
    },
  ];

  const dummyData = [
    {
      projectName: "test",
      startDate: "test",
      endDate: "test",
      status: "test",
    },
    {
      projectName: "test",
      startDate: "test",
      endDate: "test",
      status: "test",
    },
    {
      projectName: "test",
      startDate: "test",
      endDate: "test",
      status: "test",
    },
    {
      projectName: "test",
      startDate: "test",
      endDate: "test",
      status: "test",
    },
    {
      projectName: "test",
      startDate: "test",
      endDate: "test",
      status: "test",
    },
  ];

  return (
    <Modal
      centered
      title="Projects Altered"
      visible={visible}
      onCancel={() => onClose()}
    >
      <Table
        className="components-table-demo-nested"
        columns={columns}
        dataSource={dummyData}
        pagination={false}
      />
    </Modal>
  );
};
export default AlteredTableModal;
