const data = {
  main_canvas_details: {
    width_ratio: 0.7,
    height_ratio: 0.36,
  },
  rectangle_array: [
    {
      x_ratio: 0.005,
      y_ratio: 0.01,
      width_ratio: 0.2,
      height_ratio: 0.08,
      text: "Mini Armouring",
      font_size: "10",
    },
    {
      x_ratio: 0.225,
      y_ratio: 0.01,
      width_ratio: 0.15,
      height_ratio: 0.06,
      text: "Realing Winder",
      font_size: "10",
    },
    {
      x_ratio: 0.375,
      y_ratio: 0.01,
      width_ratio: 0.1,
      height_ratio: 0.04,
      text: "Pannel Board",
      font_size: "8",
    },
    {
      x_ratio: 0.62,
      y_ratio: 0.01,
      width_ratio: 0.1,
      height_ratio: 0.04,
      text: "Pannel Board",
      font_size: "8",
    },
    {
      x_ratio: 0.85,
      y_ratio: 0.02,
      width_ratio: 0.04,
      height_ratio: 0.05,
      text: "",
      font_size: "10",
    },
    {
      x_ratio: 0.22,
      y_ratio: 0.1,
      width_ratio: 0.68,
      height_ratio: 0.1,
      text: "Drum Twister",
      font_size: "10",
    },
    {
      x_ratio: 0.105,
      y_ratio: 0.25,
      width_ratio: 0.55,
      height_ratio: 0.07,
      text: "Extruder (100mm)",
      font_size: "10",
    },
    {
      x_ratio: 0.125,
      y_ratio: 0.35,
      width_ratio: 0.68,
      height_ratio: 0.06,
      text: "Extruder (120mm)",
      font_size: "10",
    },
    {
      x_ratio: 0.34,
      y_ratio: 0.43,
      width_ratio: 0.48,
      height_ratio: 0.06,
      text: "Extruder (60mm)",
      font_size: "10",
    },
    {
      x_ratio: 0.105,
      y_ratio: 0.55,
      width_ratio: 0.06,
      height_ratio: 0.04,
      text: "",
      font_size: "10",
    },
    {
      x_ratio: 0.21,
      y_ratio: 0.46,
      width_ratio: 0.07,
      height_ratio: 0.03,
      text: "Pannel Board",
      font_size: "8",
    },
    {
      x_ratio: 0.18,
      y_ratio: 0.55,
      width_ratio: 0.07,
      height_ratio: 0.05,
      text: "MWD",
      font_size: "10",
    },
    {
      x_ratio: 0.27,
      y_ratio: 0.51,
      width_ratio: 0.5,
      height_ratio: 0.06,
      text: "Extruder (90mm)",
      font_size: "10",
    },
    {
      x_ratio: 0.39,
      y_ratio: 0.59,
      width_ratio: 0.43,
      height_ratio: 0.06,
      text: "Extruder (80mm)",
      font_size: "10",
    },
    {
      x_ratio: 0.945,
      y_ratio: 0.27,
      width_ratio: 0.04,
      height_ratio: 0.03,
      text: "",
      font_size: "10",
    },
    {
      x_ratio: 0.895,
      y_ratio: 0.27,
      width_ratio: 0.04,
      height_ratio: 0.03,
      text: "",
      font_size: "10",
    },
    {
      x_ratio: 0.97,
      y_ratio: 0.35,
      width_ratio: 0.015,
      height_ratio: 0.24,
      text: "",
      font_size: "10",
    },
    {
      x_ratio: 0.945,
      y_ratio: 0.35,
      width_ratio: 0.015,
      height_ratio: 0.24,
      text: "",
      font_size: "10",
    },
    {
      x_ratio: 0.9,
      y_ratio: 0.35,
      width_ratio: 0.015,
      height_ratio: 0.24,
      text: "",
      font_size: "10",
    },
    {
      x_ratio: 0.875,
      y_ratio: 0.35,
      width_ratio: 0.015,
      height_ratio: 0.24,
      text: "",
      font_size: "10",
    },
    {
      x_ratio: 0.105,
      y_ratio: 0.68,
      width_ratio: 0.08,
      height_ratio: 0.04,
      text: "IWD 05",
      font_size: "10",
    },
    {
      x_ratio: 0.105,
      y_ratio: 0.725,
      width_ratio: 0.08,
      height_ratio: 0.037,
      text: "1250 Buncher",
      font_size: "10",
    },
    {
      x_ratio: 0.195,
      y_ratio: 0.66,
      width_ratio: 0.07,
      height_ratio: 0.06,
      text: "Br Machine",
      font_size: "10",
    },
    {
      x_ratio: 0.3,
      y_ratio: 0.675,
      width_ratio: 0.06,
      height_ratio: 0.029,
      text: "Setic 1",
      font_size: "10",
    },

    {
      x_ratio: 0.3,
      y_ratio: 0.71,
      width_ratio: 0.06,
      height_ratio: 0.029,
      text: "Setic 2",
      font_size: "10",
    },
    {
      x_ratio: 0.42,
      y_ratio: 0.675,
      width_ratio: 0.1,
      height_ratio: 0.029,
      text: "Strander 6",
      font_size: "10",
    },
    {
      x_ratio: 0.42,
      y_ratio: 0.71,
      width_ratio: 0.1,
      height_ratio: 0.029,
      text: "Strander 7",
      font_size: "10",
    },
    {
      x_ratio: 0.595,
      y_ratio: 0.7,
      width_ratio: 0.1,
      height_ratio: 0.04,
      text: "Strander 8",
      font_size: "10",
    },
    {
      x_ratio: 0.715,
      y_ratio: 0.7,
      width_ratio: 0.12,
      height_ratio: 0.04,
      text: "Mica Taping",
      font_size: "10",
    },
    {
      x_ratio: 0.835,
      y_ratio: 0.7,
      width_ratio: 0.03,
      height_ratio: 0.02,
      text: "",
      font_size: "10",
    },
    {
      x_ratio: 0.115,
      y_ratio: 0.79,
      width_ratio: 0.04,
      height_ratio: 0.04,
      text: "",
      font_size: "8",
    },
    {
      x_ratio: 0.185,
      y_ratio: 0.77,
      width_ratio: 0.05,
      height_ratio: 0.04,
      text: "Fine-03",
      font_size: "10",
    },
    {
      x_ratio: 0.255,
      y_ratio: 0.77,
      width_ratio: 0.65,
      height_ratio: 0.06,
      text: "61 Strander",
      font_size: "10",
    },
    {
      x_ratio: 0.115,
      y_ratio: 0.884,
      width_ratio: 0.15,
      height_ratio: 0.029,
      text: "Rod Breaker Cu",
      font_size: "10",
    },
    {
      x_ratio: 0.265,
      y_ratio: 0.86,
      width_ratio: 0.035,
      height_ratio: 0.08,
      text: "",
      font_size: "10",
    },
    {
      x_ratio: 0.33,
      y_ratio: 0.88,
      width_ratio: 0.04,
      height_ratio: 0.035,
      text: "Loader 1",
      font_size: "8",
    },
    {
      x_ratio: 0.41,
      y_ratio: 0.88,
      width_ratio: 0.045,
      height_ratio: 0.035,
      text: "Loader 2",
      font_size: "8",
    },
    {
      x_ratio: 0.5,
      y_ratio: 0.88,
      width_ratio: 0.053,
      height_ratio: 0.035,
      text: "Loader 3",
      font_size: "8",
    },
    {
      x_ratio: 0.6,
      y_ratio: 0.88,
      width_ratio: 0.06,
      height_ratio: 0.035,
      text: "Loader 4",
      font_size: "8",
    },
    {
      x_ratio: 0.67,
      y_ratio: 0.97,
      width_ratio: 0.06,
      height_ratio: 0.03,
      text: "Pannel Room",
      font_size: "8",
    },
    {
      x_ratio: 0.74,
      y_ratio: 0.95,
      width_ratio: 0.06,
      height_ratio: 0.05,
      text: "Die Shop",
      font_size: "10",
    },
    {
      x_ratio: 0.8,
      y_ratio: 0.95,
      width_ratio: 0.06,
      height_ratio: 0.05,
      text: "Pro Office",
      font_size: "10",
    },
    {
      x_ratio: 0.86,
      y_ratio: 0.952,
      width_ratio: 0.06,
      height_ratio: 0.02,
      text: "Stair Case",
      font_size: "8",
    },
    {
      x_ratio: 0.86,
      y_ratio: 0.972,
      width_ratio: 0.02,
      height_ratio: 0.028,
      text: "",
      font_size: "10",
    },
  ],
  random_texts_array: [
    {
      x_ratio: 0.5,
      y_ratio: 0.02,
      text: "Boundary wall",
      font_size: "10",
    },
    {
      x_ratio: 0.5,
      y_ratio: 0.99,
      text: "Boundary wall",
      font_size: "10",
    },
    {
      x_ratio: 0.9,
      y_ratio: 0.33,
      text: "Cutting Section",
      font_size: "10",
    },
    {
      x_ratio: 0.125,
      y_ratio: 0.808,
      text: "DST",
      font_size: "8",
    },
    {
      x_ratio: 0.125,
      y_ratio: 0.83,
      text: "M.W.D",
      font_size: "8",
    },
  ],
  vertical_texts: [
    {
      x_ratio: 0.942,
      y_ratio: 0.35,
      text: "Auto Coiler",
    },
    {
      x_ratio: 0.873,
      y_ratio: 0.35,
      text: "Cutting Winder 01",
    },
    {
      x_ratio: 0.898,
      y_ratio: 0.35,
      text: "Cutting Winder 02",
    },
    {
      x_ratio: 0.968,
      y_ratio: 0.35,
      text: "Cutting Winder 03",
    },
    {
      x_ratio: 0.005,
      y_ratio: 0.55,
      text: "Boundary Wall",
    },
    {
      x_ratio: 0.985,
      y_ratio: 0.55,
      text: "Boundary Wall",
    },
  ],
  clear_recs_array: [
    {
      x_ratio: 0.861,
      y_ratio: 0.968,
      width_ratio: 0.018,
      height_ratio: 0.01,
    },
  ],
};


export const renderCopperPlant = (copper_machine) => {
  var canvas = document.getElementById("canvas");
  if (canvas) {

    var context = canvas.getContext("2d");

    function drawRect(ctx, x, y, width, height, text, fontSize) {
      ctx.strokeStyle = "#FFFFFF";
      ctx.lineWidth = 0.7;
      ctx.strokeRect(x, y, width, height);
      if (fontSize === "10") {
        ctx.font = "10px Barlow";
      }
      if (fontSize === "8") {
        ctx.font = "8px Barlow";
      }
      ctx.fillStyle = "#FFFFFF";
      ctx.fillText(text, width / 4 + x, height / 2 + y);
    }

    function drawRectLightColor(ctx, x, y, width, height, text) {
      ctx.strokeStyle = "#696969";
      ctx.strokeRect(x, y, width, height);
      ctx.font = "10px Barlow";
      ctx.fillStyle = "#FFFFFF";
      ctx.fillText(text, width / 4 + x, height / 2 + y);
    }

    function displayMarkers(ctx, x, y, x1, x2, y1, color) {
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.lineTo(x1, y1);
      ctx.lineTo(x2, y1);
      ctx.fillStyle = color;
      ctx.fill();
    }

    function renderText(ctx, x, y, text, fontSize) {
      if (fontSize === "10") {
        ctx.font = "10px Barlow";
        ctx.fillStyle = "#FFFFFF";
        ctx.fillText(text, x, y);
      }
      if (fontSize === "8") {
        ctx.font = "10px Barlow";
        ctx.fillStyle = "#FFFFFF";
        ctx.fillText(text, x, y);
      }
    }

    function drawVerticalText(ctx, x, y, text, maxWidth) {
      //metric will receive the measures of the text
      var metric = ctx.measureText(text);
      // console.log(metric.width); /*from   ww w  .d e m o 2s.c  o  m*/
      ctx.save(); // this will "save" the normal canvas to return to
      if (maxWidth != null && metric.width > maxWidth) {
        // These two methods will change EVERYTHING
        // drawn on the canvas from this point forward
        // Since we only want them to apply to this one fillText,
        // we use save and restore before and after
        // We want to find the center of the text (or whatever point you want) and rotate about it
        var tx = x;
        var ty = y + 5;
        // Translate to near the center to rotate about the center
        ctx.translate(tx, ty);
        // Then rotate...
        ctx.rotate(Math.PI / 2);
        // Then translate back to draw in the right place!
        ctx.translate(-tx, -ty);
      }
      ctx.font = "10px Barlow";
      ctx.fillText(text, x, y);
      ctx.restore(); // This will un-translate and un-rotate the canvas
    }

    function circleMarks(ctx, x, y, r, color) {
      ctx.beginPath();
      ctx.arc(x, y, r, 0, 2 * Math.PI);
      ctx.fillStyle = color;
      ctx.fill();
    }

    function hideRecy(ctx, x, y, width, height) {
      ctx.fillStyle = "#202830";
      ctx.fillRect(x, y, width, height);
    }
    context.canvas.width =
      document.documentElement.clientWidth *
      data.main_canvas_details.width_ratio;
    context.canvas.height =
      document.documentElement.clientWidth *
      data.main_canvas_details.height_ratio;

    // context.strokeStyle = "#ffffff";
    // legend
    // 1 -> MINI ARMOURING
    // 2 -> REALING WINDER
    // 3 -> PANNEL BOARD
    // 4 -> DRUM TWISTER
    // 5 -> 100mm EXTRUDER
    // 6 -> 120mm EXTRUDER
    // 7 -> 60mm EXTRUDER
    // 8 -> MULTI WIRE DRAWING
    // 9 -> 90mm EXTRUDER
    // 10 -> PANNEL BOARD
    // 11 -> 80mm EXTRUDER
    // 12 -> I.W.D.05
    // 13 -> BREADING MACHINE
    // 14 -> SETIC BUNCHERS
    // 15 -> STRANDER 06/07
    // 16 -> STRANDER 08
    // 17 -> TAPPING MACHINE
    // 18 -> DRAWING SOLUTION TANK M.W.D.
    // 19 -> FINE WIRE DRAWING
    // 20 -> 61 STRANDER
    // 21 -> CUTTING WINDER 03
    // 22 -> AUTO COILER
    // 23 -> CUTTING WINDER 02
    // 24 -> CUTTING WINDER 01
    // 25 -> ROD BREAKER 03
    // 26 -> LOADER 01
    // 27 -> LOADER 02
    // 28 -> LOADER 03
    // 29 -> LOADER 04
    // 30 -> PANNEL ROOM
    // 31 -> DIE SHOP
    // 32 -> PRODUCTION OFFICE
    // 33 -> STAIR CASE
    // legend end

    // first row
    drawRectLightColor(
      context,
      0,
      0,
      context.canvas.width,
      context.canvas.height,
      "",
    );
    for (let index = 0; index < data.rectangle_array.length; index++) {
      drawRect(
        context,
        context.canvas.width * data.rectangle_array[index].x_ratio,
        context.canvas.height * data.rectangle_array[index].y_ratio,
        context.canvas.width * data.rectangle_array[index].width_ratio,
        context.canvas.height * data.rectangle_array[index].height_ratio,
        data.rectangle_array[index].text,
        data.rectangle_array[index].font_size,
      );
    }

    if (data.random_texts_array) {
      for (let index = 0; index < data.random_texts_array.length; index++) {
        renderText(
          context,
          context.canvas.width * data.random_texts_array[index].x_ratio,
          context.canvas.height * data.random_texts_array[index].y_ratio,
          data.random_texts_array[index].text,
          data.random_texts_array[index].font_size,
        );
      }
    }

    if (data.vertical_texts) {
      for (let index = 0; index < data.vertical_texts.length; index++) {
        drawVerticalText(
          context,
          context.canvas.width * data.vertical_texts[index].x_ratio,
          context.canvas.height * data.vertical_texts[index].y_ratio,
          data.vertical_texts[index].text,
          1,
        );
      }
    }

    if (data.clear_recs_array) {
      for (let index = 0; index < data.clear_recs_array.length; index++) {
        hideRecy(
          context,
          context.canvas.width * data.clear_recs_array[index].x_ratio,
          context.canvas.height * data.clear_recs_array[index].y_ratio,
          context.canvas.width * data.clear_recs_array[index].width_ratio,
          context.canvas.height * data.clear_recs_array[index].height_ratio,
        );
      }
    }

    if (copper_machine) {
      for (let index = 0; index < copper_machine.length; index++) {
        circleMarks(
          context,
          context.canvas.width * copper_machine[index].x_ratio,
          context.canvas.height * copper_machine[index].y_ratio,
          context.canvas.width * 0.006,
          copper_machine[index].color,
        );
      }
    }
  }
  // const plant = sessionStorage.getItem("displayPlant");
  // if (plant === "2") {
  //   window.addEventListener("resize", plant === "2" && renderCopperPlant);
  // }
};
