/* eslint-disable no-param-reassign */
import React, { FC, useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Skeleton,
  Spin,
} from "antd";
// import { MinusCircleOutlined } from "@ant-design/icons";
import { useConnection } from "../../Services/ApplicationContext";
import { DeviceTableData, MachineMap } from "../../definetions/DeviceModel";
import {
  IMachine,
  IMachineUpdateMap,
  UnattachedMachinesResponse,
} from "../../definetions/MachineModel";
import { AddDeviceModalProps } from "./AddDeviceModal";
import { Authorizer } from "../../Services/AuthorizationContext";
import { Plant, Plants } from "../../definetions/Constatnts";
import "../modalPopUp.scss";
import { SERVICE_TYPE } from "../../constants/constants";

const { Option } = Select;
export interface IEditDeviceModal extends AddDeviceModalProps {
  data?: DeviceTableData;
}
const EditDeviceModal: FC<IEditDeviceModal> = (props: IEditDeviceModal) => {
  const { locations, visible, onClose, data } = props;
  const maxSlots = data?.numSlots ? data?.numSlots : 1;
  const [loading, setLoading] = useState<boolean>(false);
  const [numSlotFields, setNumSlotFields] = useState<number>(maxSlots);
  const [unattachedMachines, setUnattachedMachines] = useState<
    UnattachedMachinesResponse[]
  >([]);
  const [attachableMachines, setAttachableMachines] = useState<IMachine[]>([]);
  const [maxSlotCount, setMaxSlotCount] = useState<number>(maxSlots);
  const { get, put } = useConnection();
  const [form] = Form.useForm();
  // const deviceTypeDetails = ["WISE"];
  const deviceTypeDetails = [{ deviceType: "WISE-4051", slots: 8 }];

  const slotCount = useMemo(() => {
    if (
      unattachedMachines.length > 0 &&
      unattachedMachines.length <= maxSlots
    ) {
      if (
        numSlotFields !== undefined &&
        numSlotFields <= unattachedMachines.length
      ) {
        return numSlotFields;
      }
      return unattachedMachines.length;
    }
    return maxSlots;
  }, [unattachedMachines, numSlotFields]);

  const checkForDuplicates = (array: any[]) => {
    return new Set(array).size !== array.length;
  };

  const onSubmit = async (values: any): Promise<void> => {
    console.log(values);
    const dName = values.deviceName;
    const fDName = dName
      .split(" ")
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    values.deviceName = fDName.trim();
    console.log(values, fDName);
    setLoading(true);
    const entry = {
      ...values,
      numSlots: numSlotFields,
    };
    entry.maps = entry.maps.filter((item: any) => item.machineID);
    try {
      entry.numAttributes = 4;
      entry.deviceID = data?.deviceID;

      // To fill small gaps in raw data stream, IoT device's periodic time is multiplied by this value
      // if periodic time is 60000ms, effective duration of one data Point is 60000 * alpha
      // delete entry.key;

      if (
        !checkForDuplicates(entry.maps.map((val: MachineMap) => val.machineID))
      ) {
        const result = await put(
          "system-management/device/updateMachineMap",
          entry,
          undefined,
          SERVICE_TYPE.serviceType,
        );
        if (result.status === 200) {
          message.open({
            type: "success",
            content: result.message,
            duration: 2,
            style: { textAlign: "right", marginRight: 15, marginTop: 10 },
          });
          entry.lastUpdated = result.data.lastUpdated;
          onClose(entry);
        } else {
          message.open({
            type: "warning",
            content: result.message,
            duration: 2,
            style: { textAlign: "right", marginRight: 15, marginTop: 10 },
          });
        }
      } else {
        message.open({
          type: "error",
          content: "Please remove the duplicate machines!",
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      }
      setLoading(false);
    } catch (e: any) {
      console.log(e);
      message.open({
        type: "error",
        content: `${e.message}`,
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
      setLoading(false);
    }
  };

  const generateMachineFields = (numSlots: number): IMachineUpdateMap[] => {
    const machines: IMachineUpdateMap[] = [];
    for (let i = 0; i < numSlots; i += 1) {
      machines.push({
        slotIndex: i,
        machineID: null,
        attributeIndex: 3,
        attributeName: "OnOff",
      });
    }
    return machines;
  };

  const addMachineFields = (numSlots: number) => {
    form.setFieldsValue({ maps: [] });
    const machines = [];
    for (let i = 0; i < numSlots; i += 1) {
      machines.push({
        slotIndex: i,
        machineID: null,
        attributeIndex: 3,
        attributeName: "OnOff",
      });
    }
    return machines;
  };

  const arrangeInitialMachineMap = (
    machineList: UnattachedMachinesResponse[],
  ) => {
    const slotList: IMachineUpdateMap[] = generateMachineFields(
      Number(numSlotFields),
    );

    for (let i = 0; i < machineList.length; i += 1) {
      if (
        machineList[i].attachedToDevice &&
        machineList[i].slotIndex !== undefined
      ) {
        slotList[Number(machineList[i].slotIndex)] = {
          machineID: machineList[i].machineID,
          attributeIndex: machineList[i].attributeIndex,
          attributeName: machineList[i].attributeName,
          slotIndex: machineList[i].slotIndex,
        };
      }
    }
    form.setFieldsValue({ maps: slotList });
  };

  const getUnattachedMachineList = async () => {
    setLoading(true);
    try {
      const result = await get(
        `system-management/machine/unattached?deviceID= ${data?.deviceID}`,
        undefined,
        SERVICE_TYPE.serviceType,
      );
      if (result.status === 200) {
        setMaxSlotCount(
          result.data.length > maxSlots ? maxSlots : result.data.length,
        );
        setUnattachedMachines(result.data);
        arrangeInitialMachineMap(result.data);
        // setNumSlotFields(result.data.length);
        form.setFieldsValue({ numSlots: result.data.length });
        setAttachableMachines(
          result.data.map((val: any) => ({
            machineID: val.machineID,
            machineName: val.machineName,
          })),
        );
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log("exception get unattached machines ", e);
    }
  };

  const onNumSlotsChange = (value: any) => {
    const maps = addMachineFields(value);
    form.setFieldsValue({ maps });
    setNumSlotFields(value);
  };

  useEffect(() => {
    getUnattachedMachineList();
  }, []);

  const checkSlotValidation = (_: any, value: number) => {
    if (value > 0 && value <= Number(attachableMachines.length)) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error(
        `Slot count must be between 0 and ${
          maxSlotCount
          // unattachedMachines.length > maxSlots ? maxSlots : unattachedMachines.length
        }!`,
      ),
    );
  };

  const handleSelectChange = (value: number) => {
    // console.log(value);
    onNumSlotsChange(value);
  };

  return (
    <Modal
      title="Edit Device"
      centered
      visible={visible}
      onCancel={() => onClose()}
      width="80%"
      footer={null}
      className="general-modal-popup"
    >
      (
      <Form
        form={form}
        layout="vertical"
        name="add-device"
        hideRequiredMark={false}
        onFinish={onSubmit}
        scrollToFirstError
        autoComplete="off"
        initialValues={data}
      >
        <Authorizer data={{ variableName: "deviceName", feature: "12" }}>
          <Form.Item
            name="deviceName"
            label="Device Name"
            className="display"
            rules={[
              {
                required: true,
                message: "Please input the Device Name!",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (getFieldValue("deviceName")?.match(/^[ ]{1,}$/gm)) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject("Please input the Device Name!");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>
        </Authorizer>
        <Form.Item
          name="uid"
          label="Universal Device ID"
          className="display"
          rules={[
            {
              required: true,
              message: "Please input the Device ID!",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (getFieldValue("uid")?.match(/^[ ]{1,}$/gm)) {
                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise.reject("Please input the Device ID!");
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="locationID"
          label={Plants}
          rules={[
            {
              required: true,
              message: `Please select the ${Plant} where Device located!`,
            },
          ]}
        >
          <Select
            placeholder={`Select the ${Plant}`}
            allowClear
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {locations.map((item) => (
              <Option key={item.locationID} value={item.locationID}>
                {item.locationName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Authorizer data={{ variableName: "deviceType", feature: "14" }}>
          <Form.Item
            name="deviceType"
            label="Device Type"
            rules={[
              {
                required: true,
                message: "Missing device type!",
              },
            ]}
          >
            <Select
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Select Device Type"
              onChange={handleSelectChange}
              allowClear
            >
              {deviceTypeDetails.map((item) => (
                <Option key={item.deviceType} value={item.slots}>
                  {item.deviceType}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Authorizer>
        <Form.Item
          name="mac"
          label="MAC Address"
          className="display"
          rules={[
            {
              required: true,
              message: "Please input the MAC Address!",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (getFieldValue("mac")?.match(/^[ ]{1,}$/gm)) {
                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise.reject("Please input the MAC Address!");
                }
                return Promise.resolve();
              },
            }),
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (
                  getFieldValue("mac")?.trim() &&
                  !getFieldValue("mac")
                    ?.trim()
                    .match(/^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/i)
                ) {
                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise.reject("Please input a valid MAC Address!");
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input disabled placeholder="Ex: 00:1B:44:11:3A:B7" />
        </Form.Item>
        <Authorizer data={{ variableName: "periodInMilliSecs", feature: "20" }}>
          <Col span={4}>
            <Form.Item
              name="periodInMilliSecs"
              label="Data Fetch Interval"
              style={{ width: "150%" }}
              className="display"
              normalize={(value, prevVal, prevVals) => value.trim()}
              rules={[
                {
                  required: true,
                  message: "Required field!",
                },
                {
                  validator: (_, value) =>
                    !`${value}`.includes(" ")
                      ? Promise.resolve()
                      : Promise.reject(new Error("Required field!")),
                },
                {
                  pattern: /[0-9.]/g,
                  message: "Invalid value!",
                },
              ]}
            >
              <Input addonAfter="ms" />
            </Form.Item>
          </Col>
        </Authorizer>
        <Authorizer data={{ variableName: "numSlots", feature: "16" }}>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              color: "#ffffff",
            }}
          >
            <h3 className="slot-details">Slot Details</h3>
            {numSlotFields === 0 ? (
              <p className="slot-fields">Select device to get slot fields</p>
            ) : (
              <div>
                <Button
                  type="primary"
                  style={{
                    margin: "10px 0px",
                    fontFamily: "Poppins",
                    fontSize: "15",
                    borderRadius: "5px",
                    height: "45px",
                  }}
                  onClick={() => {
                    // change in numSlotFields state will reset the slot fields
                    const tempNumSlotFields = numSlotFields;
                    onNumSlotsChange(tempNumSlotFields);
                  }}
                >
                  Reset
                </Button>
              </div>
            )}
          </div>
        </Authorizer>
        {attachableMachines.length > 0 && (
          <div
            className="slot-container"
            style={{ borderRadius: "10px", height: "auto" }}
          >
            <Authorizer data={{ variableName: "numSlots", feature: "16" }}>
              <Form.List name="maps">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      {fields.map((field, index) => (
                        <React.Fragment key={field.key}>
                          <Row
                            style={{
                              display: "flex",
                              alignItems: "base-line",
                              minHeight: index === 0 ? "90px" : "60px",
                            }}
                          >
                            <Form.Item
                              style={{ marginRight: "10px" }}
                              name={[field.name, "slotIndex"]}
                              label={index === 0 ? "Slot Index" : ""}
                            >
                              <Input
                                style={{ width: "200px" }}
                                disabled
                                value={field.key}
                              />
                            </Form.Item>
                            <Form.Item
                              name={[field.name, "machineID"]}
                              label={index === 0 ? "Machine ID" : ""}
                              rules={[
                                {
                                  required: false,
                                  message: "machineId cannot be empty",
                                },
                              ]}
                            >
                              <Select
                                getPopupContainer={(triggerNode) =>
                                  triggerNode.parentElement
                                }
                                placeholder="Select Machine Name"
                                style={{ width: "200px" }}
                                allowClear
                              >
                                {attachableMachines.map((value, indx) => (
                                  <Option
                                    key={indx.toString()}
                                    value={value.machineID}
                                  >
                                    {value.machineName}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                            {/* <MinusCircleOutlined
                                className={`minus-icon ${
                                  index === 0 ? "top-minus" : ""
                                }`}
                                onClick={() => {
                                  remove(field.name);
                                  form.setFieldsValue({
                                    numSlots: numSlotFields - 1,
                                  });
                                  setNumSlotFields(numSlotFields - 1);
                                }}
                              /> */}
                          </Row>
                        </React.Fragment>
                      ))}
                    </>
                  );
                }}
              </Form.List>
            </Authorizer>
          </div>
        )}
        <Form.Item className="modal-button ant-form-item-control-input-content modal-field-button">
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            style={{ margin: "15px", marginLeft: "0px" }}
          >
            Edit Device
          </Button>
          <Button
            loading={loading}
            danger
            type="primary"
            style={{ margin: "15px", marginLeft: "0px" }}
            onClick={() => onClose()}
          >
            Cancel
          </Button>
        </Form.Item>
        <Divider type="horizontal" />
      </Form>
    </Modal>
  );
};
export default EditDeviceModal;
