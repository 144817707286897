import React, { FC, useCallback } from "react";
import { Checkbox, Col, Divider, Row, Table } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { RolePropertyProps, useAC } from "./AccessControlContext";
import { AuthCategories, IAccessData } from "../../definetions/Authz";
import "../checkbox.scss";
import "./style1.scss";

export type AccessControlItemProps = {
  description: string;
  property: RolePropertyProps;
};
export const AccessControlItem: FC<AccessControlItemProps> = (
  props: AccessControlItemProps,
) => {
  const { description, property } = props;
  const { roleList, updateRoleFilterCriteria, allRoles } = useAC();
  const onChange = (checkedValue: Array<CheckboxValueType>) => {
    updateRoleFilterCriteria(property, checkedValue as string[]);
  };

  const renderCheckboxes = () => {
    return roleList
      .filter((val) => val !== "Administrator")
      .map((val, index) => (
        <Col span={8} key={index.toString()} className="checkbox-access">
          <Checkbox value={val}>{val}</Checkbox>
        </Col>
      ));
  };

  const initialValues = useCallback(() => {
    if (allRoles) {
      return Object.entries(allRoles)
        .filter((value) => value[1][property])
        .map((value) => value[0]);
    }
    return [];
  }, [allRoles]);

  return (
    <div className="access-row generalCheckbox">
      <div
        style={{
          alignItems: "center",
          flexDirection: "row",
          display: "flex",
          padding: "15px",
        }}
      >
        <div className="description-container">{description}</div>
        <div>
          <Checkbox.Group
            style={{
              marginLeft: "50px",
              alignItems: "center",
              width: "130%",
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
            }}
            onChange={onChange}
            defaultValue={initialValues()}
          >
            {renderCheckboxes()}
          </Checkbox.Group>
        </div>
      </div>
    </div>
  );
};

export type AccessControlTableProps = {
  allFeatures: IAccessData[];
  roleList: string[];
  category: string;
};

export const AccessControlTable: FC<AccessControlTableProps> = (
  props: AccessControlTableProps,
) => {
  const { roleList, allFeatures, category } = props;
  const { updateRoles } = useAC();
  const columns = [
    {
      title: "",
      dataIndex: "description",
      width: 500,
    },
    ...roleList.map((val: any) => {
      // console.log(val, "llllllllllllll");
      return {
        title: val,
        dataIndex: val,
        minWidth: 40,
        className: "drag-visible",
        render: (_: any, record: any) => {
          return (
            <Checkbox
              value={record[val]}
              checked={record[val]}
              onChange={(e) => {
                updateRoles(val, record, e.target.checked, category);
              }}
            />
          );
        },
      };
    }),
  ];

  return (
    <div className="generalCheckbox table-container-accessControl">
      <Table
        pagination={false}
        dataSource={allFeatures}
        columns={columns}
        rowKey="index"
      />
    </div>
  );
};
