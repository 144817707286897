import "../tableStyles.scss";
import "./viewmachinemaintenance.scss";
import "../pagination.scss";
import React, { FC, useEffect, useRef, useState } from "react";
import { FilterOutlined, ZoomOutOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Menu,
  message,
  Modal,
  Row,
  Skeleton,
  Space,
  Tag,
  Spin,
  Divider,
  Card,
} from "antd";

import moment from "moment";
import { DateTime, Duration } from "luxon";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ScatterDataPoint,
  CoreChartOptions,
  DatasetChartOptions,
  ElementChartOptions,
  LineControllerChartOptions,
  PluginChartOptions,
  ScaleChartOptions,
  Plugin,
  registerables,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import annotationPlugin from "chartjs-plugin-annotation";
import "chartjs-adapter-moment";
import { Line } from "react-chartjs-2";
import { _DeepPartialObject } from "chart.js/types/utils";
import {
  IoCalendarOutline,
  IoExpandOutline,
  IoRadioButtonOnSharp,
} from "react-icons/io5";
import { useConnection } from "../../Services/ApplicationContext";
import { SERVICE_TYPE } from "../../constants/constants";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin,
  annotationPlugin,
  {
    id: 'uniqueid5', //typescript crashes without id
    afterDraw: function (chart: any, easing: any) {
      chart.canvas.onclick = function() {
        chart.update();
      };
      if (chart.tooltip._active && chart.tooltip._active.length) {
        const activePoint = chart.tooltip._active[0];
        const ctx = chart.ctx;
        const x = activePoint.element.x;
        const topY = chart.scales.y.top;
        const bottomY = chart.scales.y.bottom;
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 2;
        ctx.setLineDash([5, 3]);
        ctx.strokeStyle = '#ffffff';
        ctx.stroke();
        ctx.restore();
      }
    }
  },
  ...registerables
);
const { RangePicker } = DatePicker;


export const uintOfFeature: any = {
  "1": { unit: "Velocity (0.01 mm/sec)" , threshold: 280 },
  "2": { unit: "Grams (0.001G)", threshold: 402 },
  "3": { unit: "Grams (0.001G)", threshold: 448 },
  "4": { unit: "Probability density (0.001)" , threshold: 600},
  "5": { unit: "Decibels (0.01dB)" , threshold: 1414},
  "6": { unit: "Density (0.01)" , threshold: 9},
  "7": { unit: "Grams (0.01G)" ,threshold: 200 },
  "8": { unit: "Inch (0.01)" ,threshold: 45 },
  "temp": {unit: "Celsius (°C)", threshold: 49},
  "voltage": {unit: "volts (V)", threshold: 3.44}
};

export const ViewMachineMaintenance: FC<{ id: number }> = (props: {
  id: number;
}) => {
  const chartRefs = useRef<any>([]);
  let cTime = DateTime.now().setZone("Asia/Colombo").toMillis();
  const [sensorType, setSensorType] = useState<any>([]);
  const [sensorDetails, setSenorDetails] = useState<{
    machineName: string;
    overallStatus: number;
  }>();
  const [product, setProduct] = useState<any>([]);
  const [crestFactor, setCrestFactor] = useState<any>([]);
  const [standardDeviation, setStandardDeviation] = useState<any>([]);
  const [rms, setRms] = useState<any>([]);
  const [skewness, setSkewness] = useState<any>([]);
  const [peakValue, setPeakValue] = useState<any>([]);
  const [kurtosis, setKurtosis] = useState<any>([]);
  const [peak_to_peak, setPeak_to_Peak] = useState<any>([]);
  const [temperature, setTemperature] = useState<any>([]);
  const [voltage, setVoltage] = useState<any>([]);
  const [enableSearch, setEnableSearch] = useState(false);
  const [rangePickerSearch, setRangePickerSearch] = useState(true)
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(true);
  const [checkModal,setCheckModal] = useState(false)
  const [filterByonFilterMenuAxis, setFilterByonFilterMenuAxis] = useState<any>(
    ["x", "y", "z"],
  );
  const [sensorFeatureDetails, setSensorFeatureDetails] = useState<any>([]);
  const [filterBymenuSensorType, setFiltermenuSensorType] = useState<any>([]);
  const [sensorSpecification, setSensorSpecification] = useState<any>(["temp", "voltage"])
  const [
    filterByonFiltersensorReceivedDetails,
    setFilterByonFiltersensorReceivedDetails,
  ] = useState<any>([1, 2, 3, 4, 5, 6, 7, 8]);

  const [
    filterVisibleUserFilterResources,
    setFilterVisibleFilterResources,
  ] = useState<any>();
  const [form] = Form.useForm();
  const { delete: del, get, post } = useConnection();

  const statusTextColour = (status: string) => {
    switch (status.toLocaleLowerCase()) {
      case "x":
        return "#e0a035";
      case "y":
        return "#dc5f3a";
      case "z":
        return "#38c795";
      case "value":
        return "#dc5f3a";   
    }
  };

  const getAllMachine = async () => {
    try {
      const response = await get(
        `predictive-maintenance/getIotMachineDetails?timeStamp=${cTime}&machineId=${props.id}`,undefined,
        SERVICE_TYPE.serviceType,
      );
      if (response.status === 200) {
        const data = response.data;
        let sensorArray: any[] = [];
        let sensorIds: any[] = [];
        for (let machineId in data) {
          setSenorDetails({
            overallStatus: data[machineId].overall.status,
            machineName: data[machineId].machineName,
          });
          data[machineId].sensors.map((sensors: any) => {
            sensorArray.push({ id: sensors.sensorId, name: sensors.sName});
            sensorIds.push(sensors.sensorId);
          });
        }
        setSensorType(sensorArray);
        setFiltermenuSensorType(sensorIds);
      }
    } catch (error) {
      console.log(error);
    }
  };

  document.addEventListener("mousedown", (event: any) => {
    const userProfileArea1 = document.querySelector(".search_menu");
    const userProfileArea2 = document.querySelector(".visibleArea");

    if (userProfileArea1 !== null && userProfileArea2 !== null) {
      if (
        userProfileArea1.contains(event.target) ||
        userProfileArea2.contains(event.target)
      ) {
        setFilterVisibleFilterResources(true);
      } else {
        setFilterVisibleFilterResources(false);
      }
    }
  });

  const handleSelectAllSensorType = (e: any) => {
    setFiltermenuSensorType(
      e.target.checked ? sensorType.map((data: any) => data.id) : [],
    );
  };
  const onFilterChangeSensorType = (checkedValues: any) => {
    console.log("checked = ", checkedValues);
    setFiltermenuSensorType(checkedValues);
  };
  const onFiltersensorReceivedDetails = (checkedValues: any) => {
    console.log("checked = ", checkedValues);
    setSensorFeatureDetails(checkedValues);
    let array: number[] = [];
    checkedValues.map((arr: string) => array.push(parseInt(arr)));
    setFilterByonFiltersensorReceivedDetails(array);
  };

  const handleSelectAllFiltersensorReceivedDetails = (e: any) => {
    setSensorFeatureDetails(
      e.target.checked ? ["1", "2", "3", "4", "5", "6", "7", "8"] : [],
    );
    setFilterByonFiltersensorReceivedDetails(
      e.target.checked ? [1, 2, 3, 4, 5, 6, 7, 8] : [],
    );
  };

  const [visible, setVisible] = useState(false);
  const [selectedData, setSelectedData] = useState<any>({
    data: {},
    options: {},
  });
  const [selectedDate, setSelectedDate] = useState<any>([
    moment().subtract(7, "d"),
    moment(),
  ]);

  const getChartData = async (data: any) => {
    setLoading(true);
    // free all mapping data
    setPeakValue([]);
    setProduct([]);
    setRms([]);
    setKurtosis([]);
    setCrestFactor([]);
    setSkewness([]);
    setStandardDeviation([]);
    setPeak_to_Peak([]);
    setVoltage([]);
    setTemperature([])
    await post("predictive-maintenance/drawChart", {
      machineIds: [Number(props.id)],
      timeStamp: data.endTime,
      timeRange: data?.startTime,
      featureIds: data.featureIds,
      axis: data.axis,
      sensorIds: data.sensorIds,
      sensorDataIds: data.sensorDataIds
    },
    undefined,
    SERVICE_TYPE.serviceType,
    )
      .then((responseJson: any) => {
        if (responseJson.status === 200) {
          for (let key in responseJson.data[props.id]) {
            let data = [];
            for (let axis of responseJson.data[props.id][key]) {
              let dataArray = [];
              let itemtype:string = '';
              let yAxisMaxValue:number = 0;
              let yAxisMinValue:number = 0;
              for (let i in axis.dataset) {
                itemtype = i;
                let max = axis.dataset[i].reduce((prev:number, curr:number) => Math.max(prev, curr));
                let min = Math.min(...axis.dataset[i].filter((num: null) => num !== null));
                console.log('get max', key , max, min);
                if(max > yAxisMaxValue) yAxisMaxValue = max;
                if(min < yAxisMinValue) yAxisMinValue = min;
                dataArray.push({
                  label: ['x','y','z'].some(x => x == i ) ? `${i}-axis`: '',
                  data: axis.dataset[i],
                  fill: false,
                  spanGaps: false,
                  borderColor: statusTextColour(i),
                  borderWidth: 1.5,
                  pointRadius: 0,
                });
              }
              let checkAverage = (axis?.average?.x || axis?.average?.y || axis?.average?.z) ? `average: x-axis: ${
                !axis?.average?.x ? 0 : axis?.average?.x
              }  y-axis: ${
                !axis?.average?.y ? 0 : axis?.average?.y
              }  z-axis: ${!axis?.average?.z ? 0 : axis?.average?.z}` : `average: ${!axis?.average?.total ? 0: axis?.average?.total}`
              const options = {
                responsive: true,
                animation: {
                  duration: 0,
                },
                plugins: {
                  legend: {
                    display: itemtype == 'value' ? false : true,
                    fullSize: false,
                    position: "bottom" as const,
                    labels: {
                      usePointStyle: true,
                    },
                  },
                  title: {
                    display: true,
                    color: "white",
                    font: {
                      size: 14,
                      family: "tahoma",
                      weight: "normal",
                    },
                    text: axis.thresholdValues.sensorName,
                  },
                  subtitle: {
                    display: true,
                    text: checkAverage,
                    color: "white",
                    align: "end",
                    font: {
                      size: 12,
                      family: "tahoma",
                      weight: "normal",
                      style: "italic",
                    },
                  },
                  zoom: {
                    pan: {
                      // pan options and/or events
                      enabled: true,
                      speed: 0.001,
                      mode: 'xy',
                    },
                    limits: {
                      // axis limits
                      y: {min: Math.min(yAxisMinValue,0).toFixed(1), max: (yAxisMaxValue*1.1).toFixed(1)},
                      x: {min: axis.label[0], max: axis.label[axis.label.length -1]}
                    },
                    zoom: {
                      rangeLimits: {
                        y: {minRange:yAxisMinValue.toFixed(1), maxRange: Math.max(yAxisMaxValue,axis.thresholdValues.max).toFixed(1)},
                      //  x: { minRange: axis.label[0], maxRange: axis.label[axis.label.length -1] }
                      },
                      // zoom options and/or events
                      wheel: {
                        enabled: true,
                        mode: 'x',
                        speed: 0.04
                      },
                      pinch: {
                        enabled: true,
                        mode: 'x',
                        speed: 0.04,
                        threshold: 8
                      },
                    },
                  },
                  annotation: {
                    annotations: {
                      // min: {
                      //   //  type: 'line',
                      //   yMin: axis.thresholdValues.min ? axis.thresholdValues.min: 0,
                      //   yMax: axis.thresholdValues.min ? axis.thresholdValues.min: 0,
                      //   borderColor: "green",
                      //   //  borderWidth: 2,
                      // },
                      
                    },
                  },
                  tooltip: {
                    mode: "index",
                    intersect: false,
                  },
                  hover: {
                    mode: "nearest",
                    intersect: true,
                  },
                },
                datasets: {
                  line: {
                    pointRadius: 0, // disable for all `'line'` datasets
                  },
                },
                elements: {
                  point: {
                    radius: 0, // default to disabled in all datasets
                  },
                },
                transitions: {
                  zoom: {
                    animation: {
                      duration: 0,
                    },
                  },
                },
                scales: {
                  x: {
                    title: { display: true, text: "Time", align: "end" },
                    type: "time",
                    time: {
                      //   unit: "day",
                      displayFormats: {
                        quarter: "HH MMM YYYY",
                      },
                    },
                    grid: {
                      display: true,
                      drawOnChartArea: false,
                      color: "#FFFFFF",
                    },
                    ticks: {
                      stepSize: 2,
                    },
                    autoScale: true,
                    min: axis.label[0],
                    max: axis.label[axis.label.length -1]
                  },
                  y: {
                    title: {
                      display: true,
                      text: `${uintOfFeature[key].unit}`,
                      align: "top",
                    },
                    ticks: {
                      beginAtZero: true,
                    },
                    grid: {
                      display: false,
                    },
                    min: parseFloat(yAxisMinValue.toFixed(2)),
                    max: Math.max(parseFloat(yAxisMaxValue.toFixed(2)), axis.thresholdValues.max)
                  },
                },
                events: ["click"],
                interaction: {
                  mode: "index",
                  intersect: false,
                }
              };
              if(axis.thresholdValues.max !== null) {
                options.plugins.annotation.annotations = {
                  max: {
                    type: 'line',
                    yMin: axis.thresholdValues.max ? axis.thresholdValues.max: uintOfFeature[key].threshold,
                    yMax: axis.thresholdValues.max ? axis.thresholdValues.max : uintOfFeature[key].threshold,
                    borderColor: "red",
                    borderWidth: 2,
                  },
                } 
              }
              data.push({
                data: {
                  labels: axis.label,
                  datasets: dataArray,
                },
                option: options,
              });
            }
            switch (key) {
              case "1":
                setProduct(data);
                break;
              case "2":
                setPeakValue(data);
                break;
              case "3":
                setRms(data);
                break;
              case "4":
                setKurtosis(data);
                break;
              case "5":
                setCrestFactor(data);
                break;
              case "6":
                setSkewness(data);
                break;
              case "7":
                setStandardDeviation(data);
                break;
              case "8":
                setPeak_to_Peak(data);
                break;
              case "temp":
                setTemperature(data);
                break
              case "voltage":
                setVoltage(data);
                break    
            }
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
    setLoading(false);
  };

  useEffect(() => {
    getAllMachine();
  }, []);

  useEffect(() => {
    setFilterByonFiltersensorReceivedDetails([1, 2, 3, 4, 5, 6, 7, 8]);
    setSensorFeatureDetails(["1", "2", "3", "4", "5", "6", "7", "8"]);
    setFilterByonFilterMenuAxis(["x", "y", "z"]);
    setSensorSpecification(["temp", "voltage"]);
    let startTime = moment(selectedDate[0]).unix() * 1000;
    let endTime = moment(selectedDate[1]).unix() * 1000;
    let data = {
      endTime: endTime,
      startTime: startTime,
      featureIds: filterByonFiltersensorReceivedDetails,
      axis: filterByonFilterMenuAxis,
      sensorIds: filterBymenuSensorType,
      sensorDataIds: sensorSpecification
    };
    getChartData(data);
  }, [!setEnableSearch]);

  const onFilterMenuAxis = (checkedValues: any) => {
    setFilterByonFilterMenuAxis(checkedValues);
  };

  const onFilterSensorSpecification = (checkedValues: any) => {
    setSensorSpecification(checkedValues)
  };

  const handleSelectAllFilterMenuAxis = (e: any) => {
    setFilterByonFilterMenuAxis(e.target.checked ? ["x", "y", "z"] : []);
  };

  const handleSelectAllFilterSensorSpecification = (e: any) => {
    setSensorSpecification(e.target.checked ? ["temp", "voltage"] : []);
  };

  const menuType = (
    <Menu className="visibleArea">
      <Menu.ItemGroup title="Sensor Name">
        <Menu.Item className="bg_col">
          <Space direction="vertical">
            <Checkbox
              onChange={handleSelectAllSensorType}
              checked={filterBymenuSensorType.length === sensorType.length}
            >
              Select All
            </Checkbox>
            <Checkbox.Group
              onChange={onFilterChangeSensorType}
              value={filterBymenuSensorType}
            >
              <Space direction="vertical">
                {sensorType.map((data: any, index: number) => (
                  <Checkbox key={data.id} value={data.id}>
                    {data.name}
                  </Checkbox>
                ))}
              </Space>
            </Checkbox.Group>
          </Space>
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup title="Features">
        <Menu.Item className="bg_col">
          <Space direction="vertical">
            <Checkbox
              onChange={handleSelectAllFiltersensorReceivedDetails}
              checked={sensorFeatureDetails.length === 8}
            >
              Select All
            </Checkbox>
            <Checkbox.Group
              onChange={onFiltersensorReceivedDetails}
              value={sensorFeatureDetails}
            >
              <Space direction="vertical">
                <Checkbox value="1">Velocity RMS</Checkbox>
                <Checkbox value="6">Skewness</Checkbox>
                <Checkbox value="7">Standard Deviation</Checkbox>
                <Checkbox value="2">Acceleration Peak</Checkbox>
                <Checkbox value="3">
                    Acceleration RMS
                </Checkbox>
                <Checkbox value="4">Kurtosis</Checkbox>
                <Checkbox value="5">Crest Factor</Checkbox>
                <Checkbox value="8">Peak To Peak Displacement</Checkbox>
              </Space>
            </Checkbox.Group>
          </Space>
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup title="Axes">
        <Menu.Item className="bg_col">
          <Space direction="vertical">
            <Checkbox
              onChange={handleSelectAllFilterMenuAxis}
              checked={filterByonFilterMenuAxis.length === 3}
            >
              Select All
            </Checkbox>
            <Checkbox.Group
              onChange={onFilterMenuAxis}
              value={filterByonFilterMenuAxis}
            >
              <Space direction="vertical">
                <Checkbox value="x">X-axis</Checkbox>
                <Checkbox value="y">Y-axis</Checkbox>
                <Checkbox value="z">Z-axis</Checkbox>
              </Space>
            </Checkbox.Group>
          </Space>
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup title="Specification">
        <Menu.Item className="bg_col">
          <Space direction="vertical">
            <Checkbox
              onChange={handleSelectAllFilterSensorSpecification}
              checked={sensorSpecification.length === 2}
            >
              Select All
            </Checkbox>
            <Checkbox.Group
              onChange={onFilterSensorSpecification}
              value={sensorSpecification}
            >
              <Space direction="vertical">
                <Checkbox value="temp"> Temperature </Checkbox>
                <Checkbox value="voltage">Sensor Voltage</Checkbox>
              </Space>
            </Checkbox.Group>
          </Space>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  const handlemenuType = ()=>  {
    if(sensorSpecification.length > 0 || (filterByonFiltersensorReceivedDetails.length > 0 ) ) setEnableSearch(true);
    else setEnableSearch(false)
    if(selectedDate.length > 0) setRangePickerSearch(true)
    setFilterVisibleFilterResources(true);
  };

  function dayDisabledDate(current: any) {
    const start = DateTime.now().endOf("day");
    return current && (current > start || current < start.minus({ year: 1 }));
  }

  const sendQuery = async (reportQuery: any) => {
    setEnableSearch(false);
    setRangePickerSearch(false)
    try {
      console.log(
        "data",
        reportQuery,
        filterByonFilterMenuAxis,
        filterBymenuSensorType,
        filterByonFiltersensorReceivedDetails,
        selectedDate,
      );
      let startTime = moment(selectedDate[0].startOf("day")).unix() * 1000;
      let endTime = moment(selectedDate[1].endOf("day")).unix() * 1000;
      let data = {
        startTime: startTime,
        endTime: endTime,
        featureIds: filterByonFiltersensorReceivedDetails,
        axis: filterByonFilterMenuAxis,
        sensorIds: filterBymenuSensorType,
        sensorDataIds: sensorSpecification
      };
      getChartData(data);
    } catch (error) {
      console.log("error", error);
      message.error("An error occurred!");
    }
  };

  // Store chart refs as they are created
  const handleChartRef = (chartRef: any,index?:any) => {
    if (chartRef && !chartRefs.current[index]) {
      chartRefs.current[index] = chartRef;
    }else {
      chartRefs.current[index] = undefined;
    }
  };

  // Reset zoom and pan for all charts
  const handleResetZoom = (index:any) => {
    const chartRef = chartRefs.current[index];
    if (chartRef) {
      chartRef.resetZoom();
    }
  };
  
  useEffect(() => {
    setModalLoading(true);
    setCheckModal(false);
    setTimeout(() => {
      setModalLoading(false);
    }, 5000);
  }, [checkModal]);

  const popupModal = () => { 
    return (
      <Modal
        title={selectedData.name}
        visible={visible}
        onCancel={() => {
          setVisible(false);
          setSelectedData({ data: {}, options: {} });
        }}
        footer={null}
        width={1000}
        bodyStyle={{ height: "500px" }}
      > {
        modalLoading ?(
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
          <Spin size="large" />
          </div>
        ) :(
          <Line
          data={selectedData.data}
          options={{
            maintainAspectRatio: false,
            ...selectedData.options,
          }}
          ref={(ref) => {
            setModalLoading(false);
          }}
        />
        )
        
      }
        
      </Modal>
    );
  };

  return (
    <div className="machine-maintenance">
      <span className="maintenance-title">IOT Monitoring</span>
      <div className="view-stepOne-header" style={{marginTop:'30px'}}>
      <div
        className="search-section"
        style={{ marginBottom: "30px", marginRight: "50px" , marginTop:'-20px' }}
      >
        {!loading ? (
            <Form
            className="rep-search-container"
            layout="inline"
            form={form}
            name="reports"
            hideRequiredMark
            scrollToFirstError
            autoComplete="off"
            onFinish={sendQuery}
          >
            <Row gutter={[20, 30]} style={{ justifyContent: "space-around" }}>
              <Col lg={5} md={12} sm={12} xs={24}>
                <div className="form-field-maintenance">
                  <Form.Item style={{ color: "white" }}>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        marginTop: "-10px",
                      }}
                    >
                      {sensorDetails?.machineName}
                    </div>
                  </Form.Item>
                </div>
              </Col>
              <Col lg={5} md={12} sm={12} xs={24}>
                <div className="form-field-maintenance">
                  <Form.Item label="Overall Status" style={{ color: "white" }}>
                    {
                      <Tag
                        color={
                          sensorDetails?.overallStatus === 1
                            ? "green"
                            : sensorDetails?.overallStatus === 0
                            ? "red"
                            : "gold"
                        }
                        // key={onOff}
                        style={{
                          fontFamily: "Poppins",
                          display: "inline-flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: "5px",
                          fontWeight: 600,
                          backgroundColor:
                            sensorDetails?.overallStatus === 1
                              ? "#99F399"
                              : sensorDetails?.overallStatus === 0
                              ? "#FAA0A0"
                              : "#FFE99A",
                        }}
                      >
                        <IoRadioButtonOnSharp
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: "5px",
                          }}
                        />
                        {sensorDetails?.overallStatus === 1
                          ? "OK"
                          : sensorDetails?.overallStatus === 0
                          ? "ERROR"
                          : "UNKNOWN"}
                      </Tag>
                    }
                  </Form.Item>
                </div>
              </Col>
              <Col lg={2} md={12} sm={24} xs={24}>
                <div style={{marginRight: '-15px'}} className="search_menu">
                  <div className="form-field-maintenance">
                    <Form.Item>
                      <Dropdown
                        overlay={menuType}
                        onVisibleChange={handlemenuType}
                        visible={filterVisibleUserFilterResources}
                      >
                        <a
                          className="ant-dropdown-link"
                          onClick={(e) => console.log(e)}
                        >
                          <FilterOutlined
                            style={{
                              color: "#073D74",
                              fontSize: "20px",
                            }}
                          />
                          Filter
                        </a>
                      </Dropdown>
                    </Form.Item>
                  </div>
                </div>
              </Col>
              <Col lg={9} md={12} sm={12} xs={24}>
                <div className="form-field-maintenance">
                  <div className="clear-circle row center">
                    <Form.Item
                      name="Interval"
                      label="Interval"
                      rules={[
                        { required: false, message: "Please select the date." },
                      ]}
                    >
                      <RangePicker
                        allowEmpty={[false, false]}
                        disabledDate={dayDisabledDate}
                        defaultValue={selectedDate}
                        // value={selectedDate}
                        format="DD:MM:YYYY"
                        suffixIcon={<IoCalendarOutline size={17} />}
                        style={{
                          width: "300px",
                        }}
                        onChange={(date, dateString) => {
                          console.log('check date', date)
                          if (date == null) {
                            setEnableSearch(false);
                            setRangePickerSearch(false)
                            setSelectedDate([]);
                          } else {
                            setEnableSearch(true);
                            setRangePickerSearch(true)
                            setSelectedDate(date);
                          }
                          
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Col>
              <Col lg={1} md={12} sm={12} xs={24}>
                <div style={{marginRight: '30px'}} className="form-field-maintenance">
                  <div className="form-field-button">
                    <Form.Item>
                      <Button
                        className="ant-btn ant-btn-primary"
                        type="primary"
                        htmlType="submit"
                        disabled={!enableSearch || !rangePickerSearch}
                      >
                        Generate
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        ):(
          <></>
        )     
        }
      </div>  
        <div style={{marginLeft:'40px', marginRight:'40px'}}>
          {!loading ? (
            <>
              {product.length > 0 ? (
                <>
                  <Divider orientation="center" style={{ marginTop: 0 }}>
                     Velocity RMS
                  </Divider>
                  
                  <div
                    className="search-section card-header"
                  >
                    <Row gutter={[15, 20]}>
                      {product.map(
                        (
                          item: {
                            data: ChartData<
                              "line",
                              (number | ScatterDataPoint | null)[],
                              unknown
                            >;
                            option:
                              | _DeepPartialObject<
                                  CoreChartOptions<"line"> &
                                    ElementChartOptions<"line"> &
                                    PluginChartOptions<"line"> &
                                    DatasetChartOptions<"line"> &
                                    ScaleChartOptions<"line"> &
                                    LineControllerChartOptions
                                >
                              | undefined;
                          },
                          index: React.Key | null | undefined,
                        ) => (
                          <Col key={index} lg={12} md={12} sm={24} xs={24}>
                            <Card bordered={false} className="card-overview">
                            <div
                              style={{
                                marginTop: "40px",
                              }}
                            >
                              <button                                
                               className="ant-IoExpandOutline button-style"
                                onClick={() => {
                                  setSelectedData({
                                    data: item.data,
                                    options: item.option,
                                    name: "Velocity RMS",
                                  });
                                  setVisible(true);
                                  setCheckModal(true)
                                }}
                              >
                                <IoExpandOutline />
                              </button>
                              <button
                               className="ant-IoExpandOutline button-style-zoom"
                              onClick={() => handleResetZoom(`product#${index}`)}
                              >
                                <ZoomOutOutlined />
                              </button>
                              <Line
                                data={item.data}
                                options={item.option}
                                ref={(chartRef) => handleChartRef(chartRef, `product#${index}`)} 
                              />
                              {popupModal()}
                            </div>
                            </Card>
                          </Col>
                        ),
                      )}
                    </Row>
                  </div>
                 
                </>
              ) : (
                <></>
              )}
              
              {skewness.length > 0 ? (
                <>
                  <Divider orientation="center" style={{ marginTop: 0 }}>
                    Skewness
                  </Divider>
                  <div
                    className="search-section card-header"
                  >
                    <Row gutter={[15, 20]}>
                      {skewness.map(
                        (
                          item: {
                            data: ChartData<
                              "line",
                              (number | ScatterDataPoint | null)[],
                              unknown
                            >;
                            option:
                              | _DeepPartialObject<
                                  CoreChartOptions<"line"> &
                                    ElementChartOptions<"line"> &
                                    PluginChartOptions<"line"> &
                                    DatasetChartOptions<"line"> &
                                    ScaleChartOptions<"line"> &
                                    LineControllerChartOptions
                                >
                              | undefined;
                          },
                          index: React.Key | null | undefined,
                        ) => (
                          <Col key={index} lg={12} md={12} sm={24} xs={24}>
                            <Card bordered={false} className="card-overview">
                            <div style={{ marginTop: "50px" }}>
                              <button
                                className="ant-IoExpandOutline button-style"
                                onClick={() => {
                                  setSelectedData({
                                    data: item.data,
                                    options: item.option,
                                    name: "Skewness",
                                  });
                                  setVisible(true);
                                  setCheckModal(true)
                                }}
                              >
                                <IoExpandOutline />
                              </button>
                              <button
                               className="ant-IoExpandOutline button-style-zoom"
                               onClick={() => handleResetZoom(`skewness#${index}`)}
                              >
                                <ZoomOutOutlined />
                              </button>
                              <Line data={item.data} options={item.option}  ref={(chartRef) => handleChartRef(chartRef, `skewness#${index}`)} />
                              {popupModal()}
                            </div>
                            </Card>
                          </Col>
                        ),
                      )}
                    </Row>
                  </div>
                </>
              ) : (
                <></>
              )}

              {standardDeviation.length > 0 ? (
                <>
                  <Divider orientation="center" style={{ marginTop: 0 }}>
                    Standard Deviation
                  </Divider>
                  <div
                    className="search-section card-header"
                  >
                    <Row gutter={[15, 20]}>
                      {standardDeviation.map(
                        (
                          item: {
                            data: ChartData<
                              "line",
                              (number | ScatterDataPoint | null)[],
                              unknown
                            >;
                            option:
                              | _DeepPartialObject<
                                  CoreChartOptions<"line"> &
                                    ElementChartOptions<"line"> &
                                    PluginChartOptions<"line"> &
                                    DatasetChartOptions<"line"> &
                                    ScaleChartOptions<"line"> &
                                    LineControllerChartOptions
                                >
                              | undefined;
                          },
                          index: React.Key | null | undefined,
                        ) => (
                          <Col key={index} lg={12} md={12} sm={24} xs={24}>
                            <Card bordered={false} className="card-overview">
                            <div style={{ marginTop: "50px" }}>
                              <button
                                className="ant-IoExpandOutline button-style"
                                onClick={() => {
                                  setSelectedData({
                                    data: item.data,
                                    options: item.option,
                                    name: "Standard Deviation",
                                  });
                                  setVisible(true);
                                  setCheckModal(true)
                                }}
                              >
                                <IoExpandOutline />
                              </button>
                              <button
                               className="ant-IoExpandOutline button-style-zoom"
                               onClick={() => handleResetZoom(`standardDeviation#${index}`)}
                              >
                                <ZoomOutOutlined />
                              </button>
                              <Line data={item.data} options={item.option} ref={(chartRef) => handleChartRef(chartRef, `standardDeviation#${index}`)} />
                              {popupModal()}
                            </div>
                            </Card>
                          </Col>
                        ),
                      )}
                    </Row>
                  </div>
                </>
              ) : (
                <></>
              )}

              {peakValue.length > 0 ? (
                <>
                  <Divider orientation="center" style={{ marginTop: 0 }}>
                    Acceleration Peak 
                  </Divider>
                  <div
                    className="search-section card-header"
                  >
                    <Row gutter={[15, 20]}>
                      {peakValue.map(
                        (
                          item: {
                            data: ChartData<
                              "line",
                              (number | ScatterDataPoint | null)[],
                              unknown
                            >;
                            option:
                              | _DeepPartialObject<
                                  CoreChartOptions<"line"> &
                                    ElementChartOptions<"line"> &
                                    PluginChartOptions<"line"> &
                                    DatasetChartOptions<"line"> &
                                    ScaleChartOptions<"line"> &
                                    LineControllerChartOptions
                                >
                              | undefined;
                          },
                          index: React.Key | null | undefined,
                        ) => (
                          <Col key={index} lg={12} md={12} sm={24} xs={24}>
                            <Card bordered={false} className="card-overview">
                            <div style={{ marginTop: "50px" }}>
                              <button
                              className="ant-IoExpandOutline button-style"
                                onClick={() => {
                                  setSelectedData({
                                    data: item.data,
                                    options: item.option,
                                    name: "Acceleration Peak",
                                  });
                                  setVisible(true);
                                  setCheckModal(true)
                                }}
                              >
                                <IoExpandOutline />
                              </button>
                              <button
                               className="ant-IoExpandOutline button-style-zoom"
                              onClick={() => handleResetZoom(`peakValue#${index}`)}
                              >
                                <ZoomOutOutlined />
                              </button>
                              <Line data={item.data} options={item.option}  ref={(chartRef) => handleChartRef(chartRef, `peakValue#${index}`)} />
                              {popupModal()}
                            </div>
                            </Card>
                          </Col>
                        ),
                      )}
                    </Row>
                  </div>
                </>
              ) : (
                <></>
              )}

              {rms.length > 0 ? (
                <>
                  <Divider orientation="center" style={{ marginTop: 0 }}>
                     Acceleration RMS
                  </Divider>
                  <div
                    className="search-section card-header"
                  >
                    <Row gutter={[15, 20]}>
                      {rms.map(
                        (
                          item: {
                            data: ChartData<
                              "line",
                              (number | ScatterDataPoint | null)[],
                              unknown
                            >;
                            option:
                              | _DeepPartialObject<
                                  CoreChartOptions<"line"> &
                                    ElementChartOptions<"line"> &
                                    PluginChartOptions<"line"> &
                                    DatasetChartOptions<"line"> &
                                    ScaleChartOptions<"line"> &
                                    LineControllerChartOptions
                                >
                              | undefined;
                          },
                          index: React.Key | null | undefined,
                        ) => (
                          <Col key={index} lg={12} md={12} sm={24} xs={24}>
                            <Card bordered={false} className="card-overview">
                            <div style={{ marginTop: "50px" }}>
                              <button
                                className="ant-IoExpandOutline button-style"
                                onClick={() => {
                                  setSelectedData({
                                    data: item.data,
                                    options: item.option,
                                    name: "Acceleration RMS",
                                  });
                                  setVisible(true);
                                  setCheckModal(true)
                                }}
                              >
                                <IoExpandOutline />
                              </button>
                              <button
                               className="ant-IoExpandOutline button-style-zoom"
                              onClick={() => handleResetZoom(`rms#${index}`)}
                              >
                                <ZoomOutOutlined />
                              </button>
                              <Line data={item.data} options={item.option} ref={(chartRef) => handleChartRef(chartRef, `rms#${index}`)} />
                              {popupModal()}
                            </div>
                            </Card>
                          </Col>
                        ),
                      )}
                    </Row>
                  </div>
                </>
              ) : (
                <></>
              )}

              {kurtosis.length > 0 ? (
                <>
                  <Divider orientation="center" style={{ marginTop: 0 }}>
                    Kurtosis
                  </Divider>
                  <div
                    className="search-section card-header"
                  >
                    <Row gutter={[15, 20]}>
                      {kurtosis.map(
                        (
                          item: {
                            data: ChartData<
                              "line",
                              (number | ScatterDataPoint | null)[],
                              unknown
                            >;
                            option:
                              | _DeepPartialObject<
                                  CoreChartOptions<"line"> &
                                    ElementChartOptions<"line"> &
                                    PluginChartOptions<"line"> &
                                    DatasetChartOptions<"line"> &
                                    ScaleChartOptions<"line"> &
                                    LineControllerChartOptions
                                >
                              | undefined;
                          },
                          index: React.Key | null | undefined,
                        ) => (
                          <Col key={index} lg={12} md={12} sm={24} xs={24}>
                            <Card bordered={false} className="card-overview">
                            <div style={{ marginTop: "50px" }}>
                              <button
                                className="ant-IoExpandOutline button-style"
                                onClick={() => {
                                  setSelectedData({
                                    data: item.data,
                                    options: item.option,
                                    name: "Kurtosis",
                                  });
                                  setVisible(true);
                                  setCheckModal(true)
                                }}
                              >
                                <IoExpandOutline />
                              </button>
                              <button
                               className="ant-IoExpandOutline button-style-zoom"
                              onClick={() => handleResetZoom(`kurtosis#${index}`)}
                              >
                                <ZoomOutOutlined />
                              </button>
                              <Line data={item.data} options={item.option} ref={(chartRef) => handleChartRef(chartRef, `kurtosis#${index}`) } />
                              {popupModal()}
                            </div>
                            </Card>
                          </Col>
                        ),
                      )}
                    </Row>
                  </div>
                </>
              ) : (
                <></>
              )}

              {crestFactor.length > 0 ? (
                <>
                  {/* <div className="chart-section">Crest factor</div> */}
                  <Divider orientation="center" style={{ marginTop: 0 }}>
                    Crest Factor
                  </Divider>
                  <div
                    className="search-section card-header"
                  >
                    <Row gutter={[15, 20]}>
                      {crestFactor.map(
                        (
                          item: {
                            data: ChartData<
                              "line",
                              (number | ScatterDataPoint | null)[],
                              unknown
                            >;
                            option:
                              | _DeepPartialObject<
                                  CoreChartOptions<"line"> &
                                    ElementChartOptions<"line"> &
                                    PluginChartOptions<"line"> &
                                    DatasetChartOptions<"line"> &
                                    ScaleChartOptions<"line"> &
                                    LineControllerChartOptions
                                >
                              | undefined;
                          },
                          index: React.Key | null | undefined,
                        ) => (
                          <Col key={index} lg={12} md={12} sm={24} xs={24}>
                            <Card bordered={false} className="card-overview">
                            <div style={{ marginTop: "50px" }}>
                              <button
                                className="ant-IoExpandOutline button-style"
                                onClick={() => {
                                  setSelectedData({
                                    data: item.data,
                                    options: item.option,
                                    name: "Crest Factor",
                                  });
                                  setVisible(true);
                                  setCheckModal(true)
                                }}
                              >
                                <IoExpandOutline />
                              </button>
                              <button
                               className="ant-IoExpandOutline button-style-zoom"
                               onClick={() => handleResetZoom(`crestFactor#${index}`)}
                              >
                                <ZoomOutOutlined />
                              </button>
                              <Line data={item.data} options={item.option} ref={(chartRef) => handleChartRef(chartRef, `crestFactor#${index}`)} />
                              {popupModal()}
                            </div>
                            </Card>
                          </Col>
                        ),
                      )}
                    </Row>
                  </div>
                </>
              ) : (
                <></>
              )}

              {peak_to_peak.length > 0 ? (
                <>
                  <Divider orientation="center" style={{ marginTop: 0 }}>
                    Peak To Peak Displacement
                  </Divider>
                  <div
                    className="search-section card-header"
                  >
                    <Row gutter={[15, 20]}>
                      {peak_to_peak.map(
                        (
                          item: {
                            data: ChartData<
                              "line",
                              (number | ScatterDataPoint | null)[],
                              unknown
                            >;
                            option:
                              | _DeepPartialObject<
                                  CoreChartOptions<"line"> &
                                    ElementChartOptions<"line"> &
                                    PluginChartOptions<"line"> &
                                    DatasetChartOptions<"line"> &
                                    ScaleChartOptions<"line"> &
                                    LineControllerChartOptions
                                >
                              | undefined;
                          },
                          index: React.Key | null | undefined,
                        ) => (
                          <Col key={index} lg={12} md={12} sm={24} xs={24}>
                            <Card bordered={false} className="card-overview">
                            <div style={{  marginTop: "50px" }}>
                              <button
                                className="ant-IoExpandOutline button-style"
                                onClick={() => {
                                  setSelectedData({
                                    data: item.data,
                                    options: item.option,
                                    name: "Peak To Peak Displacement",
                                  });
                                  setVisible(true);
                                  setCheckModal(true)
                                }}
                              >
                                <IoExpandOutline />
                              </button>
                              <button
                               className="ant-IoExpandOutline button-style-zoom"
                               onClick={() => handleResetZoom(`peak_to_peak#${index}`)}
                              >
                                <ZoomOutOutlined />
                              </button>
                              <Line data={item.data} options={item.option} ref={(chartRef) => handleChartRef(chartRef, `peak_to_peak#${index}`)} />
                              {popupModal()}
                            </div>
                            </Card>
                          </Col>
                        ),
                      )}
                    </Row>
                  </div>
                </>
              ) : (
                <></>
              )}


              {temperature.length > 0 ? (
                <>
                  <Divider orientation="center" style={{ marginTop: 0 }}>
                   Temperature
                  </Divider>
                  <div
                    className="search-section card-header"
                  >
                    <Row gutter={[15, 20]}>
                      {temperature.map(
                        (
                          item: {
                            data: ChartData<
                              "line",
                              (number | ScatterDataPoint | null)[],
                              unknown
                            >;
                            option:
                              | _DeepPartialObject<
                                  CoreChartOptions<"line"> &
                                    ElementChartOptions<"line"> &
                                    PluginChartOptions<"line"> &
                                    DatasetChartOptions<"line"> &
                                    ScaleChartOptions<"line"> &
                                    LineControllerChartOptions
                                >
                              | undefined;
                          },
                          index: React.Key | null | undefined,
                        ) => (
                          <Col key={index} lg={12} md={12} sm={24} xs={24}>
                            <Card bordered={false} className="card-overview">
                            <div style={{  marginTop: "50px" }}>
                              <button
                                className="ant-IoExpandOutline button-style"
                                onClick={() => {
                                  setSelectedData({
                                    data: item.data,
                                    options: item.option,
                                    name: "Temperature",
                                  });
                                  setVisible(true);
                                  setCheckModal(true)
                                }}
                              >
                                <IoExpandOutline />
                              </button>
                              <button
                               className="ant-IoExpandOutline button-style-zoom"
                               onClick={() => handleResetZoom(`temperature#${index}`)}
                              >
                                <ZoomOutOutlined />
                              </button>
                              <Line data={item.data} options={item.option}  ref={(chartRef) => handleChartRef(chartRef, `temperature#${index}`)} />
                              {popupModal()}
                            </div>
                            </Card>
                          </Col>
                        ),
                      )}
                    </Row>
                  </div>
                </>
              ) : (
                <></>
              )}

              {voltage.length > 0 ? (
                <>
                  <Divider orientation="center" style={{ marginTop: 0 }}>
                   Voltage
                  </Divider>
                  <div
                    className="search-section card-header"
                  >
                    <Row gutter={[15, 20]}>
                      {voltage.map(
                        (
                          item: {
                            data: ChartData<
                              "line",
                              (number | ScatterDataPoint | null)[],
                              unknown
                            >;
                            option:
                              | _DeepPartialObject<
                                  CoreChartOptions<"line"> &
                                    ElementChartOptions<"line"> &
                                    PluginChartOptions<"line"> &
                                    DatasetChartOptions<"line"> &
                                    ScaleChartOptions<"line"> &
                                    LineControllerChartOptions
                                >
                              | undefined;
                          },
                          index: React.Key | null | undefined,
                        ) => (
                          <Col key={index} lg={12} md={12} sm={24} xs={24}>
                            <Card bordered={false} className="card-overview">
                            <div style={{  marginTop: "50px" }}>
                              <button
                                className="ant-IoExpandOutline button-style"
                                onClick={() => {
                                  setSelectedData({
                                    data: item.data,
                                    options: item.option,
                                    name: "Voltage",
                                  });
                                  setVisible(true);
                                  setCheckModal(true)
                                }}
                              >
                                <IoExpandOutline />
                              </button>
                              <button
                               className="ant-IoExpandOutline button-style-zoom"
                               onClick={() => handleResetZoom(`voltage#${index}`)}
                              >
                                <ZoomOutOutlined />
                              </button>
                              <Line data={item.data} options={item.option} ref={(chartRef) => handleChartRef(chartRef, `voltage#${index}`)} />
                              {popupModal()}
                            </div>
                            </Card>
                          </Col>
                        ),
                      )}
                    </Row>
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <Skeleton active paragraph={{ rows: 10 }} round />
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewMachineMaintenance;