import React, { useEffect, useState, FC, CSSProperties } from "react";
import moment from "moment";
import { DateTime } from "luxon";
import { Button, Select, Form, DatePicker, message, Row, Col } from "antd";
import { useConnection } from "../../Services/ApplicationContext";
import "../../App.less";
import "./RepSearch.scss";
import {
  IoAddOutline,
  IoCalendarOutline,
  IoChevronDown,
} from "react-icons/io5";
import { SERVICE_TYPE } from "../../constants/constants";

const { Option } = Select;

export interface DataSource {
  id: number;
  attr: any;
  type: "MACHINE" | "DEVICE";
}

export interface Query {
  src: DataSource;
  start: number;
  duration: string;
}

interface SearchInput {
  currQuery?: Query;
  queryProcessor: any;
  stopLoading: any;
}

const defaultProps = {
  currQuery: undefined,
};

const RepSearch: FC<SearchInput> = ({
  currQuery,
  queryProcessor,
  stopLoading,
}: SearchInput) => {
  const { post } = useConnection();
  const [form] = Form.useForm();
  const [machinesLoaded, setMachinesLoaded] = useState(false);
  const [machineDetails, setMachineDetails]: any = useState([]);
  const [calType, setCalType] = useState("P1D");
  const { setFieldsValue, getFieldValue, resetFields } = form;
  const [enableSearch, setEnableSearch] = useState(false);

  const currQueryMoment = () => {
    if (currQuery) {
      return moment(currQuery.start);
    }
    return undefined;
  };

  const sendQuery = async (reportQuery: any) => {
    setEnableSearch(false);
    try {
      let intervalStartTime: any;
      if (reportQuery.intervalType === "P1W") {
        intervalStartTime = moment(reportQuery.interval)
          .endOf("week")
          .startOf("day");
      } else if (reportQuery.intervalType === "P1M") {
        intervalStartTime = moment(reportQuery.interval)
          .endOf("month")
          .startOf("day");
      } else if (reportQuery.intervalType === "P1D") {
        intervalStartTime = moment(reportQuery.interval).startOf("day");
      } else {
        intervalStartTime = moment(reportQuery.interval)
          .endOf("year")
          .startOf("day");
      }

      const query: Query = {
        src: {
          id: +reportQuery.machine,
          type: "MACHINE",
          attr: { machineID: reportQuery.machine },
        },
        duration: reportQuery.intervalType,
        start: intervalStartTime.valueOf(),
      };
      queryProcessor(query);
    } catch (error) {
      console.log("error", error);
      message.error("An error occurred!");
    }
  };

  const loadMachines = async () => {
    try {
      const result: any = await post(
        "system-management/machine/getMachines",
        {
          page: 0,
          size: 10000,
          order: "asc",
          orderBy: "machineName",
        },
        undefined,
        SERVICE_TYPE.serviceType,
      );
      const machineOptions: any = [];
      result.data.list.forEach((m: any) =>
        machineOptions.push({
          label: m.machineName,
          value: m.machineID.toString(),
          attachedToDevice: m.attachedToDevice,
        }),
      );
      setMachineDetails(machineOptions);
      setMachinesLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  function dayDisabledDate(current: any) {
    const start = DateTime.now().endOf("day");
    return current && (current > start || current < start.minus({ year: 1 }));
  }
  function weekDisabledDate(current: any) {
    const start = DateTime.now().endOf("week");
    return current && (current > start || current < start.minus({ year: 1 }));
  }
  function monthDisabledDate(current: any) {
    const start = DateTime.now().endOf("month");
    return current && (current > start || current < start.minus({ year: 1 }));
  }
  function yearDisabledDate(current: any) {
    const start = DateTime.now().endOf("year");
    return current && (current > start || current < start.minus({ year: 1 }));
  }

  useEffect(() => {
    loadMachines();
  }, [machinesLoaded]);

  useEffect(() => {
    if (!getFieldValue("machine") && machinesLoaded) {
      if (machineDetails.length > 0) {
        setFieldsValue({ machine: machineDetails[0].value });
        if (!currQuery || !currQuery.src) {
          setFieldsValue({
            machine: machineDetails[0].value.toString(),
            intervalType: "P1D",
            interval: moment().startOf("day"),
          });
          const query: Query = {
            src: {
              id: +machineDetails[0].value,
              type: "MACHINE",
              attr: { machineID: machineDetails[0].value },
            },
            duration: "P1D",
            start: moment().startOf("day").valueOf(),
          };
          queryProcessor(query);
        }
      } else {
        stopLoading(false);
      }
    }
  }, [machinesLoaded]);

  useEffect(() => {
    if (currQuery && currQuery.src && machinesLoaded) {
      setFieldsValue({
        machine: currQuery.src.id.toString(),
        intervalType: currQuery.duration,
        interval: currQueryMoment(),
      });
    }
  }, [machinesLoaded]);

  const renderDatePicker = () => {
    if (calType !== getFieldValue("intervalType")) resetFields(["interval"]);
    if (calType === "P1M") {
      return (
        <div className="form-field">
          <Form.Item
            name="interval"
            label="Date"
            rules={[{ required: true, message: "Please select the date." }]}
          >
            <DatePicker
              picker="month"
              disabledDate={monthDisabledDate}
              style={{ width: "250px" }}
              placeholder="Select Month"
              format="MMMM YYYY"
              suffixIcon={<IoCalendarOutline size={17} />}
              onChange={() => {
                setEnableSearch(true);
              }}
            />
          </Form.Item>
        </div>
      );
    }
    if (calType === "P1W") {
      return (
        <div className="form-field">
          <Form.Item
            name="interval"
            label="Date"
            rules={[{ required: true, message: "Please select the date." }]}
          >
            <DatePicker
              picker="week"
              disabledDate={weekDisabledDate}
              placeholder="Select Week"
              suffixIcon={<IoCalendarOutline size={17} />}
              style={{ width: "250px" }}
              onChange={() => {
                setEnableSearch(true);
              }}
            />
          </Form.Item>
        </div>
      );
    }
    if (calType === "P1Y") {
      return (
        <div className="form-field">
          <Form.Item
            name="interval"
            label="Date"
            rules={[{ required: true, message: "Please select the date." }]}
          >
            <DatePicker
              picker="year"
              disabledDate={yearDisabledDate}
              style={{ width: "250px" }}
              suffixIcon={<IoCalendarOutline size={17} />}
              format="YYYY"
              placeholder="Select Year"
              onChange={() => {
                setEnableSearch(true);
              }}
            />
          </Form.Item>
        </div>
      );
    }
    return (
      <div className="form-field">
        <Form.Item
          name="interval"
          label="Date"
          rules={[{ required: true, message: "Please select the date." }]}
        >
          <DatePicker
            disabledDate={dayDisabledDate}
            style={{ width: "250px" }}
            format="DD.MM.YYYY"
            suffixIcon={<IoCalendarOutline size={17} />}
            placeholder="Select Date"
            onChange={() => {
              setEnableSearch(true);
            }}
          />
        </Form.Item>
      </div>
    );
  };

  const childrenMachines: React.ReactNode[] = [];
  if (machineDetails?.length > 0) {
    machineDetails?.map((machine: any, index: any) => {
      childrenMachines.push(
        <Option
          key={machine.value + machine.label}
          value={machine.value}
          className={machine.attachedToDevice ? "" : "not-attached"}
        >
          {machine.label}
        </Option>,
      );
    });
  }

  return (
    <Form
      className="rep-search-container"
      layout="inline"
      form={form}
      name="reports"
      hideRequiredMark
      scrollToFirstError
      autoComplete="off"
      onFinish={sendQuery}
    >
      <Row style={{ width: "100%", justifyContent: "space-between" }}>
        <Col span={8}>
          <div className="form-field-machine">
            <Form.Item
              name="machine"
              label="Machine Name"
              labelAlign="left"
              rules={[{ required: true, message: "Please select a machine." }]}
            >
              <Select
                showSearch
                getPopupContainer={(trigger) => trigger.parentNode}
                optionFilterProp="children"
                filterOption={(input, option: any) => {
                  return (
                    typeof option.children === "string" &&
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
                onChange={() => {
                  setEnableSearch(true);
                }}
                style={{
                  width: "200px",
                }}
                suffixIcon={<IoChevronDown size={15} />}
              >
                {childrenMachines}
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col span={6}>
          <div className="form-field-report">
            <Form.Item
              label="Report Type"
              name="intervalType"
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: "Please select your access level!",
                },
              ]}
              initialValue="P1D"
            >
              <Select
                getPopupContainer={(trigger) => trigger.parentNode}
                style={{ width: "150px" }}
                suffixIcon={<IoChevronDown size={15} />}
                onChange={(value: any, opts) => {
                  setCalType(value.toString());
                  resetFields(["interval"]);
                  setEnableSearch(true);
                }}
              >
                <Option value="P1D">Daily</Option>
                <Option value="P1W">Weekly</Option>
                <Option value="P1M">Monthly</Option>
                {/* <Option value="year">Yearly</Option> */}
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col span={7}>
          <div className="form-field-date">
            <Form.Item
              // noStyle
              style={{
                width: "200px",
              }}
            >
              {renderDatePicker()}
            </Form.Item>
          </div>
        </Col>
        <Col span={3}>
          <div className="form-field-button">
            <Form.Item>
              <Button
                // className="glb-form-button form-button"
                type="primary"
                htmlType="submit"
                disabled={!machinesLoaded || !enableSearch}
              >
                Generate
              </Button>
            </Form.Item>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

RepSearch.defaultProps = defaultProps;

export default RepSearch;
