/* eslint-disable no-param-reassign */
import React, { FC, useState } from "react";
import { Button, Form, Input, Modal, message, InputNumber } from "antd";
import { useConnection } from "../../Services/ApplicationContext";
import "../modalPopUp.scss";
import { SERVICE_TYPE } from "../../constants/constants";

const formItemLayout = {
  labelCol: {
    span: 6,
  },
};
export interface BobbiModalProps {
  visible: boolean;
  onClose: (val?: any) => void;
  type: "Add" | "Edit";
  data?: any;
  title: string;
  onUpdate: () => void;
}
const RawMaterialModal: FC<BobbiModalProps> = (props: BobbiModalProps) => {
  const [form] = Form.useForm();
  const { visible, onClose, type, data, title, onUpdate } = props;
  const [loading, setLoading] = useState(false);

  const { post, put } = useConnection();

  const onConfirm = async (values: any) => {
    setLoading(true);
    console.log(values);
    const rName = values.resourceName;
    const fRName = rName
      .split(" ")
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    values.resourceName = fRName.trim();
    console.log(values, fRName);
    try {
      let result;
      let { materialType } = values;
      materialType = "other";

      if (type === "Add") {
        result = await post(
          "system-management/resources/createResource",
          {
            ...values,
            totalAmount: values.available,
            allocated: 0,
            materialType,
          },
          undefined,
          SERVICE_TYPE.serviceType
        );
      } else {
        console.log(values);
        result = { statusText: "SUCCESS" };
        result = await put(
          "system-management/resources/updateResource",
          {
            id: data.id,
            available: data.available,
            totalAmount: data.totalAmount,
            allocated: data.allocated,
            description: data.description,
            materialType: data.materialType,
            reorderQuantity: Number(data.reorderQuantity),
            ...values,
          },
          undefined,
          SERVICE_TYPE.serviceType
        );
      }
      if (result.statusText === "SUCCESS") {
        message.open({
          type: "success",
          content: result.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
        onUpdate();
        form.resetFields();
        onClose(type);
      } else {
        message.open({
          type: "warning",
          content: result.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      }
      setLoading(false);
    } catch (e: any) {
      message.open({
        type: "error",
        content: e.message,
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
      setLoading(false);
    }
  };
  return (
    <>
      <Modal
        title={`${type} Item`}
        centered
        visible={visible}
        onCancel={() => {
          form.resetFields();
          onClose();
        }}
        width="50%"
        footer={null}
        className="general-modal-popup"
      >
        <Form
          // eslint-disable-next-line react/jsx-props-no-spreading
          // {...formItemLayout}
          form={form}
          name="bobbins"
          hideRequiredMark={false}
          initialValues={data}
          onFinish={onConfirm}
          scrollToFirstError
          autoComplete="off"
          style={{ justifyContent: "center" }}
          className="ant-advanced-search-form"
          layout="vertical"
        >
          <Form.Item
            name="resourceName"
            label="Name"
            className="display"
            rules={[
              {
                required: true,
                message: "Required field!",
              },
              {
                whitespace: true,
                message: "Required field!",
              },
            ]}
          >
            <Input placeholder="Enter the item" />
          </Form.Item>

          <Form.Item
            name="unitOfMeasurement"
            label="Unit of Measurement"
            className="display"
            rules={[
              {
                required: true,
                message: "Required field!",
              },
              {
                whitespace: true,
                message: "Required field!",
              },
              {
                max: 10,
                message: "The maximum length of 10 characters is reached! ",
              },
            ]}
          >
            <Input
              disabled={!(type === "Add")}
              placeholder="Enter the unit of Measurement"
            />
          </Form.Item>

          {type === "Add" && (
            <Form.Item
              name="available"
              label="Available Quantity"
              className="display"
              style={{ width: "100%" }}
              normalize={(value) => value.trim()}
              rules={[
                {
                  required: true,
                  message: "",
                },
                {
                  validator: async (rule, value) => {
                    if (
                      String(value).trim() === "" ||
                      value === null ||
                      value === undefined
                    ) {
                      throw new Error("Required field!");
                    } else if (String(Number(String(value).trim())) === "NaN") {
                      throw new Error("Invalid value!");
                    } else if (value <= 0) {
                      throw new Error(
                        "Available Quantity can't be less than or equal to zero!",
                      );
                    }
                  },
                },
              ]}
            >
              <Input placeholder="Enter the available quantity" />
            </Form.Item>
          )}
          <Form.Item
            name="reorderQuantity"
            label="Reorder Quantity"
            className="display"
            style={{ width: "100%" }}
            normalize={(value) => value.trim()}
            rules={[
              {
                required: true,
                message: "",
              },
              {
                validator: async (rule, value) => {
                  if (
                    String(value).trim() === "" ||
                    value === null ||
                    value === undefined
                  ) {
                    throw new Error("Required field!");
                  } else if (String(Number(String(value).trim())) === "NaN") {
                    throw new Error("Invalid value!");
                  } else if (value < 0) {
                    throw new Error("Reorder Quantity can't be less zero!");
                  }
                },
              },
            ]}
          >
            <Input placeholder="Enter the available quantity" />
          </Form.Item>

          {type === "Add" && (
            <Form.Item
              name="description"
              label="Description"
              className="display"
              rules={[
                {
                  max: 255,
                  message: "The maximum length of 255 characters is reached! ",
                },
                {
                  whitespace: true,
                  message: "Required field!",
                },
              ]}
            >
              <Input.TextArea rows={3} placeholder="Description" />
            </Form.Item>
          )}

          <Form.Item className="modal-field-button">
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              style={{ marginRight: "15px" }}
            >
              {`${type === "Add" ? "Add Raw Material" : "Edit Raw Material"}`}
            </Button>
            <Button
              loading={loading}
              danger
              type="primary"
              onClick={() => {
                form.resetFields();
                onClose();
              }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default RawMaterialModal;
