/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
  Skeleton,
  Tooltip,
  Popover,
  Space,
  Collapse,
  Tag,
  Button,
  Popconfirm,
  Progress,
} from "antd";
import {
  InfoCircleOutlined,
  ExpandOutlined,
  MinusOutlined,
  PlusOutlined,
  MenuUnfoldOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import React, { FC, useEffect, useRef, useState } from "react";
import Timeline, {
  CursorMarker,
  CustomMarker,
  TimelineMarkers,
  TodayMarker,
} from "react-calendar-timeline";
import moment, { unix } from "moment";
import { Canvas, CanvasRef, EdgeData, Label, Node, NodeData } from "reaflow";
import { useHistory } from "react-router-dom";
import { useConnection } from "../../Services/ApplicationContext";
// import "./stepFour.scss";
// import "./stepOne.scss";
import "./viewProject.scss";
import TemplateNodeDataModal from "./TemplateNodeDataModal";
import PopupIcon from "../../assets/popupIcon.png";
import ContainerViewModal from "./ContainerViewModal";
import NodeDataModal from "../CableProduction/NodeDataModal";
import ResourceTable from "./ResourceTable";
import TimelineGantt from "../../components/GanttChart/TimelineGantt";
import { Container, SERVICE_TYPE } from "../../constants/constants";
import { DateTime } from "luxon";
import NodeDataModalProject from "../CableProduction/NodeDataModalProject";
import InputCutLengthSelectionModal from "./inputCutLengthSelectionModal";
import InputCutLengthViewModal from "./inputCutLengthViewModal";

const ViewProject: FC<{ id: string }> = (props: { id: string }) => {
  const { get, post, delete: del } = useConnection();
  const [formOne] = Form.useForm();
  const history = useHistory();
  const { Option } = Select;
  const { Panel } = Collapse;
  const [projectData, setProjectData] = useState<any>({});
  const [processTemplate, setProductionTemplate] = useState([]);
  const [nodeModalVisible, setNodeModalVisible] = useState<boolean>(false);
  const [containerModalVisible, setContainerModalVisible] = useState<boolean>(
    false,
  );
  const [timelineGroup, setTimelineGroup] = useState([]);
  const [timelineItem, setTimelineItem] = useState<any>([]);
  const [openGroups, setOpenGroups] = useState<any>({});
  const [selectedNode, setSelectedNode] = useState<any>({});
  const [containerArray, setContainerArray] = useState<any>([]);
  const [skeletonLoading, setSkeletonLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState<any>();
  const [endTime, setEndTime] = useState<any>();
  const [tableData, setTableData] = useState<any>([]);
  const [holidays, setHolidays] = useState<any>([]);
  const [deadLine, setDeadLine] = useState<any>();
  const [taskStatus, setTaskStatus] = useState<any>([]);
  const [taskEndTime, setTaskEndTime] = useState<any>([]);
  const [projectStatus, setProjectStatus] = useState<any>([]);

  const [cutLengthData, setCutLengthData] = useState({});

  const [dummyState, setDummyState] = useState<string>("");

  const [viewProjectTimeLineItems, setViewProjectTimeLineItems] = useState<any>(
    [],
  );

  const [tasksList, setTasksList] = useState<any>();
  const [viewPageLoading, setViewPageLoading] = useState<boolean>(false);
  const [inputModalVisible, setInputModalVisible] = useState<boolean>(false);
  const [selectedTaskId, setSelectedTaskId] = useState<any>({});
  const [projectStarted, setProjectStarted] = useState<boolean>(false);
  const [pauseProjectStatus, setPauseProjectStatus] = useState<boolean>(false);
  const [machineIds, setMachineIds] = useState<any>(undefined);
  const [currentlyRunningTasks, setCurrentlyRunningTasks] = useState<any>([]);
  const canvasRef = useRef<CanvasRef | any>(null);
  const [nodes, setNodes] = useState<NodeData[]>([]);
  const [edges, setEdges] = useState<EdgeData[]>([]);
  const [processName, setProcessName] = useState("");
  const [templateName, setTempalteName] = useState();
  const [latestPausedTime, setLatestPausedTime] = useState<number>(0);
  const [pausedTimeRange, setPausedTimeRange] = useState<any>([]);
  const [isTimelineEmpty, setIsTimelineEmpty] = useState<boolean>(false)

  const priorityArray = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
  ];

  const getProcessTemplateData = async (id: any) => {
    const response = await get(
      `scheduler/productionPlans/getProcessTemplate?id=${id}`,
      undefined,
      SERVICE_TYPE.serviceType
    );
    console.log(response, "setNodessetNodessetNodessetNodessetNodessetNodes");
    setNodes(response.data.nodes);
    setEdges(response.data.edges);
    setProcessName(response?.data?.processFields?.processName);
    console.log(response, "setNodessetNodessetNodessetNodessetNodessetNodes");
  };

  const getProjectData = async () => {
    try {
      setSkeletonLoading(true);
      const results = await get(
        `scheduler/processScheduling/getProjectTemplate?id=${props?.id}`,
        undefined,
        SERVICE_TYPE.serviceType
      );
      const taskStatusList = [];
      setProjectStatus(results?.data.projectStatus);
      console.log("view project", results.data);
      setProjectData(results?.data);
      setDeadLine(results?.data?.deadline);
      getAllProductionTemplate(results?.data?.processTemplate).then(
        (val: any) => {
          setLoading(true);
          formOne.setFieldsValue({
            ...results?.data,
            processTemplate: val,
            minimumOrderQuantity: Object.values(
              results?.data.minimumOrderQuantity,
            )[0],
            quantities: Object.values(results?.data.quantities)[0],
            deadline: moment.unix(results?.data?.deadline),
            startTime: results?.data?.userStartTime
              ? moment.unix(results?.data?.userStartTime)
              : moment.unix(results?.data?.startTime),
          });
          setLoading(false);
        },
      );
      const maxPausedTime = Math.max.apply(null, results?.data?.pausedTimes);
      setLatestPausedTime(maxPausedTime);
      console.log(
        "latestPausedTime array - 2",
        maxPausedTime,
        latestPausedTime,
      );
      getProcessTemplateData(results?.data.processTemplate);
      arrangeTimeline(
        results?.data?.nodes,
        results?.data.projectStatus,
        results?.data?.startTime,
        results?.data?.endTime,
        results?.data?.nodesCompleted,
        maxPausedTime,
      );
      const resourceDetails = results?.data?.resourceDetails;
      const resourceDetailsCompleted = results.data.resourceDetailsCompleted
        ? JSON.parse(JSON.stringify(results?.data?.resourceDetailsCompleted))
        : {};
      console.log(
        "resource details",
        resourceDetailsCompleted,
        resourceDetails,
        results?.data?.machineDetails,
      );
      if (
        results?.data?.resourceDetails ||
        results?.data?.resourceDetailsCompleted
      ) {
        let completeMaterial: { [key: string]: string } = {};
        Object.keys(results?.data?.machineDetails).forEach((key) => {
          console.log("resource details-5", key);
          if (resourceDetails?.hasOwnProperty(key)) {
            console.log(
              "resource details-2",
              resourceDetails[key],
              resourceDetails,
            );
            const task = resourceDetails[key];
            const bobbin = task["Bobbin"];
            const labour = task["Labour"];
            bobbin["id"] = key;
            labour["id"] = key;
            bobbin["status"] = results?.data.projectStatus;
            labour["status"] = results?.data.projectStatus;
            labour["subRows"] =
              resourceDetailsCompleted && resourceDetailsCompleted[key]
                ? resourceDetailsCompleted[key]
                : [];
            bobbin["subRows"] =
              resourceDetailsCompleted && resourceDetailsCompleted[key]
                ? resourceDetailsCompleted[key]
                : [];
          } else {
            console.log("resource details-6", key);
            if (
              resourceDetailsCompleted?.hasOwnProperty(key) &&
              resourceDetailsCompleted[key].length > 0
            ) {
              console.log("resource details-7", resourceDetailsCompleted[key]);
              let sortRawArray = resourceDetailsCompleted[key];
              sortRawArray = sortRawArray.sort(
                (
                  a: { Bobbin: { startTime: number } },
                  b: { Bobbin: { startTime: number } },
                ) => b.Bobbin.startTime - a.Bobbin.startTime,
              );
              const task = sortRawArray.shift();
              const bobbin = task["Bobbin"];
              const labour = task["Labour"];
              console.log("resource details-4", bobbin);
              bobbin["id"] = key;
              labour["id"] = key;
              bobbin["status"] = "Completed";
              labour["status"] = "Completed";
              labour["subRows"] = sortRawArray.length > 0 ? sortRawArray : [];
              bobbin["subRows"] = sortRawArray.length > 0 ? sortRawArray : [];
              completeMaterial[key] = task;
            }
          }
        });
        completeMaterial = {
          ...completeMaterial,
          ...results?.data?.resourceDetails,
        };
        setTableData(Object.values(completeMaterial));
        console.log(
          "resource details result - 100",
          completeMaterial
        );
      } else {
        setTableData([]);
      }
      const nodes = results?.data?.nodes;
      for (let node in nodes) {
        if (
          nodes[node].startTime > moment().unix() &&
          nodes[node].endTime > moment().unix() &&
          results?.data.projectStatus != "Paused"
        ) {
          taskStatusList.push({
            startTime: nodes[node].startTime,
            endTime: nodes[node].endTime,
            taskStatus: "pending",
            taskId: nodes[node].id.id,
          });
        } else if (
          nodes[node].startTime < moment().unix() &&
          nodes[node].endTime >= moment().unix() &&
          results?.data.projectStatus != "Paused"
        ) {
          taskStatusList.push({
            startTime: nodes[node].startTime,
            endTime: nodes[node].endTime,
            taskStatus: "ongoing",
            taskId: nodes[node].id.id,
          });
        } else if (
          // nodes[node].startTime < moment().unix() &&
          // nodes[node].endTime < moment().unix() &&
          results?.data.projectStatus != "Paused"
        ) {
          taskStatusList.push({
            startTime: nodes[node].startTime,
            endTime: nodes[node].endTime,
            taskStatus: "done",
            taskId: nodes[node].id.id,
          });
        }
      }
      console.log("node list - 10", taskStatusList, pauseProjectStatus);
      setTaskStatus(taskStatusList);
      // const pausedTimeArray: any = [];
      // for (const index in results?.data?.pausedTimes) {
      //   pausedTimeArray.push({
      //     startTime: results?.data?.pausedTimes[index]*1000,
      //     endTime:
      //       (results.data.resumedTimes[index + 1] ?? results.data.startTime)*1000
      //   });
      // }
      // setPausedTimeRange(pausedTimeArray);
      setSkeletonLoading(false);
    } catch (error: any) {
      console.log(error);
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
      setSkeletonLoading(false);
    }
  };

  const getOngoingTasks = async () => {
    const results = await get(
      `scheduler/processScheduling/getOngoingTasks?id=${props?.id}`,
      undefined,
      SERVICE_TYPE.serviceType
    );
    setCurrentlyRunningTasks(results?.data);
  };

  useEffect(() => {
    getOngoingTasks();
    console.log("view more project data -------> ", projectData);
  }, [projectData]);

  useEffect(()=>{
    if(timelineItem.length > 0) setIsTimelineEmpty(true)
    console.log("timelineItem-1", timelineItem.length ,isTimelineEmpty ,timelineItem);
  },[timelineItem])

  const getProjectStatusData = async () => {
    try {
      // const results = await get(`production/ViewProjectStatus/${props?.id}`);
      // console.log(" ----- r ----------", results);
      // setTasksList([...results.data]);
      // const r = getActualStartTimeOrEndTime("2", "task", "startTime")
    } catch (error: any) {
      console.log(error);
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
    }
  };

  // const getActualStartTimeOrEndTime = (
  //   id: any,
  //   type: any,
  //   timeType: string,
  // ) => {
  //   // let idConcat: any;
  //   let result: any;
  //   tasksList?.map((task: any, index: any) => {
  //     let val = task?.taskId?.split("::");
  //     // console.log("------------ shrovvvvvvvvvvvvv ---------- ", val)
  //     if (val?.length) {
  //       if (val[1] === id) {
  //         if (timeType === "startTime") {
  //           result = task?.actualStartTime;
  //         } else if (timeType === "endTime") {
  //           result = task?.actualEndTime;
  //         }
  //       }
  //     }
  //   });
  //   return result;
  // };

  useEffect(() => {
    if (
      projectStatus === "Ongoing"
      //  projectStatus === "Scheduled"
      // projectStatus === "Expired"
    ) {
      setProjectStarted(true);
    }

    if (projectStatus === "Paused" || projectStatus === "Completed") {
      setPauseProjectStatus(true);
    }
  }, [projectStatus]);

  const getMachineAvailability = async (machineIdArray: any[]) => {
    try {
      const toDay = DateTime.now();
      const before3Month = Math.floor(toDay.minus({ months: 3 }).toSeconds());
      const after3Month = Math.floor(toDay.plus({ months: 3 }).toSeconds());
      const results = await post(
        "scheduler/processScheduling/availability",
        {
          machineIdArray,
          startTime: before3Month,
          endTime: after3Month,
        },
        undefined,
        SERVICE_TYPE.serviceType
      );
      console.log(
        "mchines array  ------- > ",
        before3Month,
        after3Month,
        machineIdArray,
        results?.response.data,
      );
      return results?.response.data;
    } catch (error: any) {
      console.log(error);
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
    }
  };

  const arrangeTimeline = async (
    data: any,
    projectStatus: string,
    projectStartTime: number,
    projectEndTime: number,
    completeData?: any,
    pausedTime?: number,
  ) => {
    try {
      setViewPageLoading(true);
      let taskGroup: any = [];
      let machineIds: any = [];
      const machineArray: any = [];
      const taskArray: any = [];
      const taskFinalArray: any = [];
      const startTimeArray: any = [];
      const endTimeArray: any = [];
      const dataArray = Object.values(data);
      const completeDataArray = completeData ? Object.values(completeData) : [];
      dataArray.sort((a: any, b: any) =>
        a.endTime !== b.endTime
          ? parseInt(a.endTime) - parseInt(b.endTime)
          : parseInt(a.startTime) - parseInt(b.startTime),
      );
      dataArray.map((item: any, index) => {
        taskGroup.push(item.machineOption.taskGroup);
      });
      completeDataArray.map((items: any, index) => {
        items.map((item: any) => {
          taskGroup.push(item.machineOption.taskGroup);
        });
      });
      console.log("task group", taskGroup);
      taskGroup = [...new Set(taskGroup)];
      setViewProjectTimeLineItems(dataArray);

      machineIds = dataArray.map((item: any) => item.machineOption.machineID);
      setMachineIds(machineIds);

      let uniqueMachineIds = machineIds.filter((id: any, index: any) => {
        return machineIds.indexOf(id) === index;
      });

      const machineBookedData = await getMachineAvailability(uniqueMachineIds);
      console.log(" ------ task group ---------", taskGroup);
      let indexNumber = 0;
      dataArray.map((item: any, index) => {
        let value = {
          id: index + 10,

          title: item.machineOption.machineName,
          machineId: item.machineOption.machineID,
          rightTitle: item.machineOption.machineName,
          root: false,
          parent: taskGroup.indexOf(item.machineOption.taskGroup) + 1,
        };
        let result: boolean = machineArray?.some(
          (item: {
            id: any;
            title: any;
            machineId: any;
            rightTitle: any;
            root: boolean;
            parent: any;
          }) =>
            item.machineId === value.machineId &&
            item.root === value.root &&
            item.parent === value.parent,
        );
        if (!result) {
          machineArray.push({
            // id: index + 10,
            id:
              item.machineOption.machineName +
              String(taskGroup.indexOf(item.machineOption.taskGroup) + 1),
            title: item.machineOption.machineName,
            machineId: item.machineOption.machineID,
            rightTitle: item.machineOption.machineName,
            root: false,
            parent: taskGroup.indexOf(item.machineOption.taskGroup) + 1,
          });
        }
        // machineArray.push({
        //   id: index + 10,
        //   title: item.machineOption.machineName,
        //   rightTitle: item.machineOption.machineName,
        //   bgColor: "#f984ec",
        //   root: false,
        //   parent: taskGroup.indexOf(item.machineOption.taskGroup) + 1,
        // });
        if (projectStatus !== "Paused") {
          let taskStatus;
          if (
            item.startTime > moment().unix() &&
            item.endTime > moment().unix()
          ) {
            taskStatus = "pending";
          } else if (
            item.startTime < moment().unix() &&
            item.endTime >= moment().unix()
          ) {
            taskStatus = "ongoing";
          } else if (
            item.startTime < moment().unix() &&
            item.endTime < moment().unix()
          ) {
            taskStatus = "done";
          }
          taskArray.push({
            id: indexNumber + 1,
            group:
              item.machineOption.machineName +
              String(taskGroup.indexOf(item.machineOption.taskGroup) + 1),
            // group: !result
            //   ? index + 10
            //   : machineArray[machineArray.length - 1]?.id,
            title: item.taskName,
            start_time: moment.unix(item.startTime),
            end_time: moment.unix(item.endTime),
            taskId: item.id.id,
            status: taskStatus,
            tip: {
              ...item.machineOption,
              duration: item?.duration,
              start_time: moment.unix(item.startTime),
              end_time: moment.unix(item.endTime),
              startTime: item?.startTime,
              endTime: item?.endTime,
              taskId: item.id.id,
            },
          });
          indexNumber++;
        } else {
          console.log(
            "latestPausedTime-1",
            item.id.id,
            projectStatus,
            pausedTime,
            item.startTime,
            item.endTime,
            projectStartTime,
            projectEndTime,
          );
          if (item.lastRunCompletedPercentage < 1) {
            pausedTime = pausedTime ?? 0;
            if (projectStartTime <= pausedTime && pausedTime < projectEndTime) {
              let pausedStartTime = 0;
              if (item?.startTime <= pausedTime && pausedTime < item?.endTime)
                pausedStartTime = pausedTime;
              else pausedStartTime = item?.startTime;
              console.log(
                `latestPausedTime-1-1`,
                pausedTime,
                item.id.id,
                pausedStartTime,
              );
              taskArray.push({
                id: indexNumber + 1,
                group:
                  item.machineOption.machineName +
                  String(taskGroup.indexOf(item.machineOption.taskGroup) + 1),
                // group: !result
                //   ? index + 10
                //   : machineArray[machineArray.length - 1]?.id,
                title: item.taskName,
                start_time: moment.unix(pausedStartTime),
                end_time: moment.unix(item.endTime),
                taskId: item.id.id,
                status: "pause",
                tip: {
                  ...item.machineOption,
                  duration: item?.duration,
                  start_time: moment.unix(pausedStartTime),
                  end_time: moment.unix(item.endTime),
                  pauseTime: pausedTime,
                  startTime: item?.startTime,
                  endTime: item?.endTime,
                  taskId: item.id.id,
                },
              });
              indexNumber++;
            }
          }
        }
        startTimeArray.push(item.startTime);
        endTimeArray.push(item.endTime);
      });

      completeDataArray?.map((itemData: any) => {
        itemData.map((item: any) => {
          console.log("latestPausedTime-5", item);
          let value = {
            id: indexNumber + 10,

            title: item.machineOption.machineName,
            machineId: item.machineOption.machineID,
            rightTitle: item.machineOption.machineName,
            root: false,
            parent: taskGroup.indexOf(item.machineOption.taskGroup) + 1,
          };
          let result: boolean = machineArray?.some(
            (item: {
              id: any;
              title: any;
              machineId: any;
              rightTitle: any;
              root: boolean;
              parent: any;
            }) =>
              item.machineId === value.machineId &&
              item.root === value.root &&
              item.parent === value.parent,
          );
          if (!result) {
            machineArray.push({
              // id: index + 10,
              id:
                item.machineOption.machineName +
                String(taskGroup.indexOf(item.machineOption.taskGroup) + 1),
              title: item.machineOption.machineName,
              machineId: item.machineOption.machineID,
              rightTitle: item.machineOption.machineName,
              root: false,
              parent: taskGroup.indexOf(item.machineOption.taskGroup) + 1,
            });
          }

          // machineArray.push({
          //   id: index + 10,
          //   title: item.machineOption.machineName,
          //   rightTitle: item.machineOption.machineName,
          //   bgColor: "#f984ec",
          //   root: false,
          //   parent: taskGroup.indexOf(item.machineOption.taskGroup) + 1,
          // });
          taskArray.push({
            id: indexNumber + 1,
            group:
              item.machineOption.machineName +
              String(taskGroup.indexOf(item.machineOption.taskGroup) + 1),
            // group: !result
            //   ? index + 10
            //   : machineArray[machineArray.length - 1]?.id,
            title: item.taskName,
            start_time: moment.unix(item.startTime),
            end_time: moment.unix(item.endTime),
            taskId: item.id.id,
            status: "done",
            tip: {
              ...item.machineOption,
              duration: item?.duration,
              start_time: moment.unix(item.startTime),
              end_time: moment.unix(item.endTime),
              startTime: item?.startTime,
              endTime: item?.endTime,
              taskId: item.id.id,
            },
          });
          indexNumber++;
          // startTimeArray.push(item.startTime);
          // endTimeArray.push(item.endTime);
        });
      });

      taskArray.map((task: any, index: any) => {
        if (task?.end_time < Date.now()) {
          console.log(" ----------- finished -----------", task);
          taskFinalArray.push({
            ...task,
            bgColor: "rgba(156, 77, 224, 0.9)",
            selectedBgColor: "red",
          });
        } else {
          taskFinalArray.push(task);
        }
      });

      const finalArray: any = [];
      console.log("competedTaskArray 10", taskArray, taskGroup);
      taskGroup.map((task: any, index: number) => {
        machineArray.push({
          id: index + 1,
          title: task,
          rightTitle: task,
          bgColor: "#f984ec",
          root: true,
          parent: 0,
        });
        console.log("competedTaskArray", machineArray);
        finalArray.push(
          {
            id: index + 100,
            title: task,
            rightTitle: task,
            bgColor: "#f984ec",
            root: true,
            parent: 0,
          },
          ...machineArray.filter(
            (machine: any) => machine.parent === index + 1,
          ),
          // ...competedMachineArray.filter(
          //   (machine: any) => machine.parent === index + 1,
          // ),
        );
      });

      const machineBookingItems: any = [];
      let ids: any = [];
      console.log("machine booked data ============ ", machineBookedData);
      machineBookedData?.map(
        (machineBooking: any, indexmachineBookedData: any) => {
          machineBooking?.availability?.map((booking: any, index: any) => {
            if (
              !(
                dataArray.find(
                  (item: any) => item.startTime === booking.bookingStart,
                ) &&
                dataArray.find(
                  (item: any) => item.endTime === booking.bookingEnd,
                )
              )
            ) {
              console.log(
                "machine booked data 1",
                booking,
                finalArray,
                finalArray.find(
                  (item: any) => item.machineId === machineBooking.machineId,
                ),
              );
              // if (booking?.taskStatus !== true) {
              machineBookingItems.push({
                id:
                  String(indexmachineBookedData) +
                  String(index) +
                  String(Math.ceil(Math.random() * 100000000000)),
                group: finalArray.find(
                  (item: any) => item.machineId === machineBooking.machineId,
                ).id,
                title: booking.projectName,
                start_time: moment.unix(booking.bookingStart),
                end_time: moment.unix(booking.bookingEnd),
                bgColor: "rgba(154,153,153,0.6)",
                selectedBgColor: "rgb(101,100,100)",
                color: "#9abab4",
                canMove: false,
                canResize: false,
                canChangeGroup: false,
                canSelect: false,
                tip: {
                  tastStart: booking?.bookingStart * 1000,
                  tastEnd: booking?.bookingEnd * 1000,
                  projectName: booking?.projectName,
                  duration: booking?.bookingEnd - booking?.bookingStart,
                  machineName: "disable",
                },
              });
              // }
            }
          });
        },
      );

      console.log(taskArray, "competedTaskArray12");
      setTimelineGroup(finalArray);
      setTimelineItem([...taskArray, ...machineBookingItems]);
      setStartTime(Math.min(...startTimeArray));
      setEndTime(Math.max(...endTimeArray));
      setSkeletonLoading(false);
      setViewPageLoading(false);
    } catch (e) {
      console.log(e, "-------error in timeline-----");
    }
  };

  const newGroups = timelineGroup
    .filter((g: any) => g.root || g.parent)
    .map((group: any) => {
      return {
        ...group,
        title: group.root ? (
          <div
            // onClick={() => toggleGroup(group.id)}
            style={{ fontWeight: "bold" }}
            title={group.title}
          >
            {/* {openGroups[group.id] ? "[-]" : "[+]"} {group.title} */}
            {group.title}
          </div>
        ) : (
          <div style={{ paddingLeft: 20 }} title={group.title}>
            {group.title}
          </div>
        ),
      };
    });

  const getAllProductionTemplate = async (processId: any) => {
    try {
      const response = await get(
        `scheduler/productionPlans/getProcessTemplate?id=${processId}`,
        undefined,
        SERVICE_TYPE.serviceType
      );
      console.log(
        typeof response.data.processFields.processName,
        "response.data.processFields.processNameresponse.data.processFields.processName",
      );
      return response.data.processFields.processName;
    } catch (error: any) {
      console.log(error);
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
      return "";
    }
  };

  const getHolidays = async () => {
    try {
      const results = await get(
        "scheduler/calendar/allHolidays",
        undefined,
        SERVICE_TYPE.serviceType
      );
      const holidaysData = results.data.map((holiday: any) => {
        return moment(moment.unix(holiday.date_), "DD.MM.YYYY");
      });
      setHolidays(holidaysData);
      console.log("getHolidays", results, holidaysData);
    } catch (error: any) {
      console.log(error);
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
    }
  };

  const getProjectTaskStatus = async () => {
    try {
      // const results = await get(`production/ViewProjectStatus/${props?.id}`);
      // setTaskStatus(results.data);
      // console.log(results);
    } catch (error) {
      console.log(error);
      // message.open({
      //   type: "error",
      //   content: error.message,
      //   duration: 2,
      // });
    }
  };

  // const datesBeforeDeadline = (startDate: any, endDate: any) => {
  //   console.log("11111111", startDate);
  //   console.log("22222222", endDate);
  //   const startMoment = moment(startDate);
  //   const endMoment = moment(endDate);
  //   console.log("33333333", startMoment);
  //   console.log("44444444", endMoment);
  //   console.log(moment(startMoment).valueOf());

  //   if (endDate < startDate) {
  //     const now = startMoment.clone();
  //     const dates = [];

  //     while (now.isSameOrBefore(endMoment)) {
  //       dates.push({ date: moment(now).valueOf() / 1000 });
  //       now.add(1, "days");
  //     }
  //     console.log(dates);
  //     return dates;
  //   }
  //   return [];
  // };

  useEffect(() => {
    getProjectTaskStatus();
    getProjectData();
    getHolidays();
  }, []);

  const nodeDataSelect = (nodeData: NodeData) => {
    if (nodeData?.data?.props) {
      setSelectedNode(nodeData);
      setNodeModalVisible(true);
    } else {
      message.open({
        type: "error",
        content: "No Properties",
        duration: 2,
      });
    }
  };

  const selectContainers = (nodeData: NodeData) => {
    const initMachines = [...nodeData?.data.props.machines];

    const containerArraySamp = projectData?.container;
    console.log(
      containerArraySamp,
      "containerArraySampcontainerArraySampcontainerArraySamp",
    );
    const initValues = initMachines.map((machine: any, index: number) => ({
      machineName: machine.machineName,
      inputContainer: machine?.machineData?.inputContainer,
      taskGroup: nodeData?.data.props.taskGroup,
      ...machine,
      ...containerArraySamp.find(
        (container: any) =>
          container.machineId.toString() === machine.machineID,
      ),
    }));
    setContainerModalVisible(true);
    console.log(initValues, "initValuesinitValuesinitValuesinitValues");
    setContainerArray(initValues);
  };

  const nodeColour = (node: any) => {
    switch (node?.properties?.data?.type) {
      case "Task":
        return "#352f44";
      case "Input":
        return "#3a4750";
      case "Output":
        return "#414141";
      case "Block":
        return "#252A38";
      default:
        return "#252A38";
    }
  };

  const rescheduleProject = async () => {
    setViewPageLoading(true);
    try {
      const results = await post(`production/startProject`, {
        projectId: props?.id,
      });
      console.log("======= results =============", results);
      getProjectTaskStatus();
      getProjectData();
      let tData: any = projectData;
      tData.project.value.projectStatus = "Ongoing";
      tData.projectMap.actualStartTime = moment().unix();
      setProjectData(tData);
      setProjectStarted(true);
      if (results?.statusText === "SUCCESS") {
        const res = Array.isArray(results?.message);
        if (res === true) {
          for (let index = 0; index < results?.message?.length; index++) {
            message.open({
              type: "success",
              content: results?.message[index],
              duration: 2,
            });
          }
        } else {
          message.open({
            type: "success",
            content: results?.message,
            duration: 2,
          });
        }
      }
    } catch (error: any) {
      console.log(error);
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
    } finally {
      // window.location.reload();
      setViewPageLoading(false);
    }
  };

  const pauseProject = async () => {
    setViewPageLoading(true);
    try {
      const results = await get(
        `scheduler/processScheduling/pauseProject?id=${props?.id}`,
        undefined,
        SERVICE_TYPE.serviceType
      );
      console.log("======= results =============", results);
      if (results?.statusText === "SUCCESS") {
        message.open({
          type: "success",
          content: results?.message,
          duration: 2,
        });
        history.push("/scheduling/projects");
      } else {
        message.open({
          type: "success",
          content: results?.message,
          duration: 2,
        });
      }
    } catch (error: any) {
      console.log(error);
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
    } finally {
      // window.location.reload();
      setViewPageLoading(false);
    }
  };

  const deleteProject = async () => {
    setViewPageLoading(true);
    try {
      const results = await del(
        `scheduler/processScheduling/deleteProjectTemplate?id=${props?.id}`,
        undefined,
        SERVICE_TYPE.serviceType
      );
      console.log("=========== result cancel project ========", results);
      getProjectTaskStatus();
      getProjectData()
        .then(() => {
          if (results?.statusText === "SUCCESS") {
            history.goBack();
          }
        })
        .catch((error: any) => {
          console.log(error);
          message.open({
            type: "error",
            content: error.message,
            duration: 2,
          });
        });
    } catch (error: any) {
      console.log(error);
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
    } finally {
      setViewPageLoading(false);
    }
  };

  const startTask = async (taskId: any) => {
    setSkeletonLoading(true);
    try {
      const results = await post(`production/startTask`, {
        projectId: props?.id,
        taskId: `task::${taskId}`,
      });
      // console.log(" ========== result start task ================", results);
      getProjectData()
        .then(() => {
          getProjectTaskStatus();
          if (results?.statusText === "SUCCESS") {
            const res = Array.isArray(results?.message);
            if (res === true) {
              for (let index = 0; index < results?.message?.length; index++) {
                message.open({
                  type: "success",
                  content: results?.message[index],
                  duration: 2,
                });
              }
            } else {
              message.open({
                type: "success",
                content: results?.message,
                duration: 2,
              });
            }
          }
        })
        .catch((error: any) => {
          console.log(error);
          message.open({
            type: "error",
            content: error.message,
            duration: 2,
          });
        });
    } catch (error: any) {
      console.log(error);
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
    } finally {
      setSkeletonLoading(false);
    }
  };

  const endTask = async (taskId: any) => {
    setSkeletonLoading(true);
    try {
      const results = await post(`production/EndTask`, {
        projectId: props?.id,
        taskId: `task::${taskId}`,
      });
      console.log("========== result while end task ==========", results);
      getProjectData();
      getProjectTaskStatus();
      if (results?.statusText === "SUCCESS") {
        const res = Array.isArray(results?.message);
        if (res === true) {
          for (let index = 0; index < results?.message?.length; index++) {
            message.open({
              type: "success",
              content: results?.message[index],
              duration: 2,
            });
          }
        } else {
          message.open({
            type: "success",
            content: results?.message,
            duration: 2,
          });
        }
      }
    } catch (error: any) {
      console.log(error);
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
    } finally {
      setSkeletonLoading(false);
    }
  };

  const taskProgress = (taskId: any, startTime: number, endTime: number) => {
    // const task = taskStatus.find((el: any) => el.taskId === taskId);
    const currentTime =
      projectStatus == "Paused" ? latestPausedTime : moment().unix();
    const fullDuration = endTime - startTime;
    if (currentTime > startTime) {
      const currentlyCompleted = currentTime - startTime;
      const progress = Math.round((currentlyCompleted / fullDuration) * 100);
      return progress;
    }
    return 0;
  };

  const projectStatusColour = (status: string) => {
    switch (status.toLowerCase()) {
      case "ongoing":
        return "#99F399";
      case "scheduled":
        return "#FFE99A";
      case "paused":
        return "#FDBEBE";
      case "done":
        return "#EAF4EA";
      case "pending":
        return "#FFE99A";  
      default:
        return "#FFE99A";
    }
  };

  const projectStatusTextColour = (status: string) => {
    switch (status.toLowerCase()) {
      case "ongoing":
        return "#18923A";
      case "scheduled":
        return "#C29800";
      case "done":
        return "#636663";
      case "paused":
        return "#FDBEBE";
      case "pending":
        return "#C29800";        
      default:
        return "#18923A";
    }
  };

  const projectStatusBackGroundColour = (status: string) => {
    switch (status?.toLocaleLowerCase()) {
      case "ongoing":
        return "#99F399";
      case "scheduled":
        return "#FFE99A";
      case "completed":
        return "#EAF4EA";
      case "paused":
        return "#FDBEBE";
      case "delayed":
        return "#96bdff";
      case "pending":
        return "#FFE99A";
      case "reschedule":
        return "#FDBEBE";
      default:
        return "#18923A";
    }
  };

  const projectStatusTextColourD = (status: string) => {
    switch (status?.toLocaleLowerCase()) {
      case "ongoing":
        return "#18923A";
      case "scheduled":
        return "#C29800";
      case "completed":
        return "#636663";
      case "paused":
        return "#EF5828";
      case "delayed":
        return "#3277ed";
      case "pending":
        return "#C29800";
      case "reschedule":
        return "#EF5828";
      default:
        return "#18923A";
    }
  };

  const taskProgressWidth = (
    taskId: string,
    width: any,
    status: string,
    startTime: number,
    endTime: number,
  ) => {
    let progressWidth: any;
    console.log(
      "taskProgressWidth",
      taskId,
      taskStatus,
      taskStatus?.find((el: any) => el.taskId === taskId),
    );
    if (status == "pause") return progressWidth;
    else if(status == "ongoing") {
    const fullDuration = endTime - startTime;
    if (moment().unix() > startTime) {
      const currentlyCompleted = moment().unix() - startTime;
      const progress = currentlyCompleted / fullDuration;
      progressWidth = progress * width;
      console.log("progress going ------- > ");
    }
  }
    return progressWidth;
  };

  const getBackGroundColor = (status: any, id?: number) => {
    let result: string;
    // if (
    //   projectData?.project?.value?.projectStatus === "Pending" ||
    //   projectData?.project?.value?.projectStatus === "Delayed" ||
    //   projectData?.project?.value?.projectStatus === "Expired"
    // ) {
    //   result = "#44A5FF";
    // } else

    if (
      taskStatus?.find((el: any) => el.taskId === id)?.taskStatus ==
        "pending" &&
      status == "pending"
    ) {
      result = "#44A5FF";
    } else if (
      taskStatus?.find((el: any) => el.taskId === id)?.taskStatus == "done" &&
      status == "done"
    ) {
      result = "#3CD37C";
    } else if (
      taskStatus?.find((el: any) => el.taskId === id)?.taskStatus ==
        "ongoing" &&
      status == "ongoing"
    ) {
      result = "#3CD37C";
    } else if (status === "pending") {
      result = "#44A5FF";
    } else if (status === "done") {
      result = "#3CD37C";
    } else if (status === "pause") {
      result = "rgba(154,153,153,0.6)";
    } else {
      result = "#44A5FF";
    }
    console.log("getBackGroundColor123", status, result, taskStatus, id);
    return result;
  };

  const getFormtattedDate = (val: any) => {
    let result: any;
    let date =
      new Date(
        moment(val).year(),
        moment(val).month(),
        moment(val).date(),
        moment(val).hour(),
        moment(val).minute(),
      ).valueOf() / 1000;
    result = moment.unix(date).format("DD:MM:YYYY, hh:mm:ss a");
    return result;
  };

  const formDuration = (startD: any, endD: any) => {
    let val = moment.duration(moment(endD).diff(moment(startD))).asSeconds();
    let result: string = "";

    let seconds = Number(val);
    let d = Math.floor(seconds / (3600 * 24));
    let h = Math.floor((seconds % (3600 * 24)) / 3600);
    let m = Math.floor((seconds % 3600) / 60);
    let s = Math.floor(seconds % 60);

    let dDisplay = d > 0 ? d + (d == 1 ? " day " : " days ") : "";
    let hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : "";
    let mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";
    let sDisplay = s > 0 ? s + (s == 1 ? " second " : " seconds ") : "";

    dDisplay !== "" || hDisplay !== "" || mDisplay !== ""
      ? (result = dDisplay + hDisplay + mDisplay)
      : (result = sDisplay);
    return result;
  };

  const itemRenderer = ({
    item,
    timelineContext,
    itemContext,
    getItemProps,
    getResizeProps,
  }: any) => {
    console.log("getBackGroundColor1", item);
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    let backgroundColor = "#44A5FF";
    const backgroundColorx = "#686868";
    const realStartTime =
      item.status === "Pause" ? item?.tip?.pauseTime : item?.tip?.startTime;
    // const backgroundColor = "#44A5FF";
    if (item?.tip?.machineName === "disable") {
      return (
        <Popover
          content={
            <div className="gant-tooltip">
              <div>
                Duration :{" "}
                {formDuration(item?.tip?.tastStart, item?.tip?.tastEnd)}
                {/* {moment
                  .duration(
                    moment(item?.tip?.tastEnd).diff(
                      moment(item?.tip?.tastStart),
                    ),
                  )
                  .humanize()} */}
              </div>
              <div>Task Start : {getFormtattedDate(item?.tip?.tastStart)}</div>
              <div>Task End : {getFormtattedDate(item?.end_time)}</div>
            </div>
          }
          title={item?.tip?.projectName}
          trigger="hover"
        >
          <div
            {...getItemProps({
              style: {
                backgroundColor: backgroundColorx,
                borderStyle: "solid",
                borderRadius: 3,
                cursor: "default",
              },
            })}
          >
            {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}
            <div
              style={{
                height: itemContext.dimensions.height,
                overflow: "hidden",
                paddingLeft: 3,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {itemContext.title}
            </div>
            {/* {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null} */}
          </div>
        </Popover>
      );
    }
    return (
      <Popover
        content={
          <div className="gant-tooltip">
            <div>Machine Name : {item?.tip?.machineName}</div>
            <div>Setup Time : {item?.tip?.setupTime}</div>
            <div>
              Duration : {formDuration(item?.start_time, item?.end_time)}
              {/* {moment.duration({ seconds: item?.tip?.duration }).humanize()} */}
            </div>
            <div>Speed : {item?.tip?.speed} m/s</div>
            <div>
              Task Start :{" "}
              {moment
                .unix(item?.tip?.startTime)
                .format("DD:MM:YYYY, hh:mm:ss a")}
            </div>
            <div>
              Task End :{" "}
              {moment.unix(item?.tip?.endTime).format("DD:MM:YYYY, hh:mm:ss a")}
            </div>
            {item?.status === "pause" && (
              <div>
                Pause Time:{" "}
                {moment
                  .unix(item?.tip?.pauseTime)
                  .format("DD:MM:YYYY, hh:mm:ss a")}
              </div>
            )}
            {
              // taskStatus.find((el: any) => el.taskId === item.taskId)
              // ?.taskStatus ==
              item.status && (
                <div>
                  Task Status :{" "}
                  <Tag
                    color={projectStatusColour(item.status)}
                    style={{
                      color: projectStatusTextColour(item.status),
                    }}
                  >
                    {"\u2B24"} {item.status}
                  </Tag>
                </div>
              )
            }
            {(projectData?.projectStatus === "Ongoing" ||
              projectData?.projectStatus == "Completed" || projectData?.projectStatus == "Paused" ) &&
              item.status && (
                <div>
                  Progress :{" "}
                  <Progress
                    percent={taskProgress(
                      item.taskId,
                      item?.tip?.startTime,
                      item?.tip?.endTime,
                    )}
                    status="active"
                  />
                </div>
              )}
            {/* {projectData?.project?.projectStatus === "Ongoing" &&
            taskStatus.find((el: any) => el.taskId === `task::${item.taskId}`)
              ?.taskStatus !== "done" ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {item.id !== 1 && (
                  <Popconfirm
                    icon={false}
                    title={`Are you sure to start the task -  ${itemContext.title}?`}
                    onConfirm={() => startTask(item.taskId)}
                    okText="Yes"
                    cancelText="No"
                  >
                    {taskStatus.find(
                      (el: any) => el.taskId === `task::${item.taskId}`,
                    )?.taskStatus === "pending" && (
                      <Button
                        type="primary"
                        style={{
                          height: "25px",
                          borderRadius: "5px",
                          marginTop: "10px",
                          fontFamily: "Poppins",
                          fontSize: "13px",
                        }}
                      >
                        Start Task
                      </Button>
                    )}
                  </Popconfirm>
                )}
                <Popconfirm
                  icon={false}
                  title={`Are you sure to end the task ${itemContext.title} early?`}
                  onConfirm={() => endTask(item.taskId)}
                  okText="Yes"
                  cancelText="No"
                >
                  {taskStatus.find(
                    (el: any) => el.taskId === `task::${item.taskId}`,
                  )?.taskStatus !== "done" && (
                    <Button
                      danger
                      type="primary"
                      style={{
                        height: "25px",
                        borderRadius: "5px",
                        marginTop: "10px",
                        fontFamily: "Poppins",
                        fontSize: "13px",
                      }}
                    >
                      End Task
                    </Button>
                  )}
                </Popconfirm>
              </div>
            ) : null} */}
          </div>
        }
        title={item?.tip?.taskGroup}
        trigger="hover"
      >
        <div
          {...getItemProps({
            style: {
              backgroundColor: backgroundColor,
              borderRadius: 3,
              border: 0,
              overflow: "hidden",
              borderColor: backgroundColor,
            },
          })}
        >
          {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}
          <div
            style={{
              height: itemContext.dimensions.height,
              textOverflow: "ellipsis",
              position: "relative",
            }}
          >
            <div
              style={{
                color: "#ffffff",
                fontWeight: "bold",
                position: "absolute",
                marginLeft: "5px",
              }}
            >
              {itemContext.title}
            </div>
            <div
              style={{
                backgroundColor: getBackGroundColor(item.status, item.taskId),
                height: itemContext.dimensions.height,
                zIndex: 9,
                borderRadius: 3,
                borderColor: getBackGroundColor(item.status, item.taskId),
                width: taskProgressWidth(
                  item.taskId,
                  itemContext.dimensions.width,
                  item?.status,
                  realStartTime,
                  item?.tip?.endTime,
                ),
              }}
            />
          </div>
          {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
        </div>
      </Popover>
    );
  };

  const projectProgress = () => {
    // console.log("latestPausedTime 4", latestPausedTime);
    const pEndTime = projectData.endTime;
    const pStartTime = projectData.startTime;
    const currentTime =
      projectStatus == "Paused" ? latestPausedTime : moment().unix();
    if (currentTime < pStartTime) {
      return {
        completed: 0,
        needToDone: 100,
      };
    }
    if (currentTime > pEndTime) {
      return {
        completed: 100,
        needToDone: 0,
      };
    }
    const diff = currentTime - pStartTime;
    const fDiff = pEndTime - pStartTime;
    const percentCompleted = (diff / fDiff) * 100;
    return {
      completed: Math.floor(percentCompleted),
      needToDone: Math.ceil(100 - percentCompleted),
    };
  };

  const isTimeOfficeHour = (date: Date): boolean => {
    const hours = date.getHours();
    const mins = date.getMinutes();
    const total = hours * 60 + mins;

    if (total > 510 && total < 1050) {
      return true;
    }
    return false;
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 15 },
  };
  if (viewPageLoading) {
    return <Skeleton active paragraph={{ rows: 10 }} round />;
  }
  return skeletonLoading === true || loading === true ? (
    <Skeleton active paragraph={{ rows: 10 }} round />
  ) : (
    <div className="view-container">
      <div className="back-btn">
        <Button
          className="add-button"
          type="primary"
          style={{
            // width: 150,
            height: "45px",
            fontFamily: "Poppins",
            fontSize: "15px",
          }}
          icon={<ArrowLeftOutlined />}
          onClick={() => history.push("/scheduling/projects")}
        >
          Back
        </Button>
      </div>
      {/* header section */}
      <div className="view-stepOne-header" style={{ width: "100%" }}>
        <Form {...layout}>
          <Row className="form-row-header">
            <Col span={11}>
              <Form.Item label="Project">
                <div
                  style={{
                    backgroundColor: "#2856da",
                    color: "#ffffff",
                    padding: "10px",
                    borderRadius: "5px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  {projectData?.projectName}
                </div>
              </Form.Item>
            </Col>
            <Col span={11} style={{ display: "flex", flexDirection: "row" }}>
              {projectData.projectStatus == "Scheduled" ||
              projectData.projectStatus == "Reschedule" ||
              projectData.projectStatus == "Paused" ||
              projectData.projectStatus == "Delayed" ? (
                <Col span={7}>
                  <Popconfirm
                    disabled={false}
                    icon={false}
                    title="Are you sure to reschedule the project ?"
                    // onConfirm={() => rescheduleProject()}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      className="start_project"
                      disabled={false}
                      type="primary"
                      style={{
                        height: "40px",
                        borderRadius: "5px",
                        fontFamily: "Poppins",
                        fontSize: "15px",
                      }}
                      onClick={() =>
                        history.push(
                          `/scheduling/rescheduleProject/${props?.id}`,
                        )
                      }
                    >
                      Reschedule
                    </Button>
                  </Popconfirm>
                </Col>
              ) : (
                <Col span={7}>
                  <Popconfirm
                    disabled={pauseProjectStatus}
                    icon={false}
                    title="Are you sure to start the project ?"
                    onConfirm={() => pauseProject()}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      className="start_project"
                      disabled={pauseProjectStatus}
                      type="primary"
                      style={{
                        height: "40px",
                        borderRadius: "5px",
                        fontFamily: "Poppins",
                        fontSize: "15px",
                      }}
                    >
                      Pause
                    </Button>
                  </Popconfirm>
                </Col>
              )}

              <Col span={10}>
                <Popconfirm
                  icon={false}
                  title="Are you sure to cancel the project ?"
                  onConfirm={() => deleteProject()}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    danger
                    type="primary"
                    style={{
                      height: "40px",
                      borderRadius: "5px",
                      fontFamily: "Poppins",
                      fontSize: "15px",
                    }}
                    disabled={projectStarted}
                  >
                    Delete Project
                  </Button>
                </Popconfirm>
              </Col>
            </Col>
          </Row>
          <Row className="form-row-header">
            <Col span={11}>
              <Form.Item label="Product Name" className="label-text">
                {processName}
              </Form.Item>
            </Col>
            <Col span={11}>
              {projectData.projectStatus == "Scheduled" ||
              projectData.projectStatus == "Delayed" ||
              projectData.projectStatus == "Reschedule" ? (
                <Form.Item label="Project Progress">
                  <Tag
                    color={projectStatusBackGroundColour(
                      projectData?.projectStatus,
                    )}
                    className="status-tag"
                    style={{
                      color: projectStatusTextColourD(
                        projectData?.projectStatus,
                      ),
                    }}
                  >
                    Project is {projectData?.projectStatus}
                  </Tag>
                </Form.Item>
              ) : (
                <Form.Item label="Project Progress">
                  {projectData?.startTime !== "" ? (
                    <Tooltip
                      title={`${projectProgress().completed}% completed and ${
                        projectProgress().needToDone
                      }%  need to be completed`}
                    >
                      <Progress
                        percent={100}
                        showInfo={false}
                        strokeColor="#F64D4F"
                        success={{
                          percent: projectProgress().completed,
                        }}
                      />
                      <div style={{ color: "#ffffff" }}>
                        {projectProgress().completed === 100
                          ? `${projectProgress().completed}% completed`
                          : `${projectProgress().completed}% completed and ${
                              projectProgress().needToDone
                            }% in progress
                        `}
                      </div>
                    </Tooltip>
                  ) : null}
                </Form.Item>
              )}
            </Col>
          </Row>
          <Row className="form-row-header">
            <Col span={11}>
              <Form.Item label="Scheduled Start Date" className="label-text">
                {moment
                  .unix(projectData?.startTime)
                  .format("DD:MM:YYYY, hh:mm:ss a")}
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item label="Project status">
                <Tag
                  color={projectStatusBackGroundColour(
                    projectData?.projectStatus,
                  )}
                  className="status-tag"
                  style={{
                    color: projectStatusTextColourD(projectData?.projectStatus),
                  }}
                >
                  {"\u2B24"} {projectData?.projectStatus?.toUpperCase()}
                </Tag>
              </Form.Item>
            </Col>
          </Row>
          <Row className="form-row-header">
            <Col span={11}>
              <Form.Item label="Scheduled End Date" className="label-text">
                {moment
                  .unix(projectData?.endTime)
                  .format("DD:MM:YYYY, hh:mm:ss a")}
              </Form.Item>
            </Col>
          </Row>
          <Row className="form-row-header">
            <Col span={11}>
              <Form.Item label="Current Running Task">
                {currentlyRunningTasks?.length !== 0 ? (
                  currentlyRunningTasks?.map((val: any) => {
                    return (
                      <>
                        <Tag
                          color="#ffffff"
                          style={{
                            backgroundColor: "#2856da",
                            padding: "5px",
                            borderRadius: "5px",
                            margin: "5px",
                          }}
                        >
                          {"\u2B24 "}
                          {val?.taskName?.toUpperCase()}
                        </Tag>
                      </>
                    );
                  })
                ) : (
                  <Tag
                    color="#ffffff"
                    style={{
                      backgroundColor: "#F64D4F",
                      padding: "5px",
                      borderRadius: "5px",
                      margin: "5px",
                    }}
                  >
                    {"\u2B24"}
                    {" NONE"}
                  </Tag>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row className="form-row-header">
            <Col span={11}>
              {/* <Form.Item label="Created At:" className="label-text">
                {projectData?.project?.value.projectCreatedAt !== undefined
                  ? DateTime.fromMillis(
                      projectData.project.value.projectCreatedAt,
                    ).toFormat("dd:MM:yyyy,  hh:mm:ss a")
                  : "-"}
              </Form.Item> */}
              <Form.Item label="Last Updated At:" className="label-text">
                {projectData?.updateTime !== undefined
                  ? moment
                      .unix(projectData?.updateTime)
                      .format("DD:MM:YYYY, hh:mm:ss a")
                  : "-"}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      {/* header section */}
      <div
        className="stepOne-scheduling view-stepOne"
        style={{ width: "100%" }}
      >
        <Form
          initialValues={projectData?.value}
          layout="vertical"
          form={formOne}
          name="task"
          scrollToFirstError
          autoComplete="off"
        >
          <Divider orientation="center" style={{ marginTop: 0 }}>
            Project Outline
          </Divider>
          <Row className="form-row">
            <Col span={11}>
              <Form.Item label="Project Name" required name="projectName">
                <Input
                  name="projectName"
                  placeholder="Enter Project Name"
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item label="Project Identifier" name="projectIdentifier">
                <Input placeholder="Enter ID" disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row className="form-row">
            <Col span={11}>
              <Form.Item label="Production Template" name="processTemplate">
                {/* <Select
                  placeholder="Select the Template"
                  showSearch
                  disabled
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {processTemplate?.map(
                    (template: {
                      id: { type: string; id: string };
                      processFields: { processName: string };
                    }) => (
                      <Option key={template?.id?.id} value={template?.id?.id}>
                        {template?.processFields?.processName}
                      </Option>
                    ),
                  )}
                </Select> */}
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item label="Minimum Cut Length" name="minimumOrderQuantity">
                <Input type="number" placeholder="Select a template" disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row className="form-row">
            <Col span={11}>
              <Form.Item
                label="Start Date"
                name="startTime"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker
                  use12Hours
                  format="YYYY-MM-DD h:mm a"
                  showTime
                  style={{ width: "100%" }}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                label="Deadline"
                name="deadline"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker
                  use12Hours
                  format="YYYY-MM-DD h:mm a"
                  showTime
                  style={{ width: "100%" }}
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="form-row">
            <Col span={11}>
              <Form.Item
                label={
                  <div>
                    Priority{" "}
                    <Tooltip title="1 Indicates the highest priority">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                }
                name="priority"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select placeholder="Select the Priority" disabled>
                  {priorityArray?.map(
                    (priority: { value: number; label: number }) => (
                      <Option key={priority.value} value={priority.value}>
                        {priority.label}
                      </Option>
                    ),
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                label={
                  <div>
                    Quantity{" "}
                    <Tooltip title="Quantity cannot be less than minimum cut length!">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                }
                name="quantities"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  type="number"
                  addonAfter="m"
                  name="quantity"
                  placeholder="Enter the Quantity"
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label="Cost" name="costCalculation">
                <Input disabled type="number" placeholder="Cost" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name="alter" valuePropName="checked">
                <Checkbox disabled>Enable altering current schedule </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Divider
            orientation="center"
            style={{ color: "white", marginTop: 25 }}
          >
            Current Schedules
          </Divider>
          <Row>
            <Col span={24}>
              <Form.Item
                name="prioritySchedules"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Radio.Group disabled>
                  <Radio value="lowerPrioritySchedules">
                    Alter lower priority schedules
                  </Radio>
                  <Radio value="equalAndLowerPrioritySchedules">
                    Alter equal and lower priority schedules
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="collapse-container">
        <Collapse defaultActiveKey={["1"]} className="collapse-parent">
          <Panel header="Project Schedule" key="1">
            <div
              className="stepFour-cards-view-one"
              style={{ marginTop: "30px" }}
            >
              <div className="card-container">
                <div className="scheduling-timeline">
                  { isTimelineEmpty ? (
                  <Timeline
                    defaultTimeStart={moment.unix(startTime).add(-5, "hours")}
                    defaultTimeEnd={moment.unix(endTime).add(5, "hours")}
                    itemTouchSendsClick={false}
                    stackItems
                    itemHeightRatio={0.75}
                    canMove={false}
                    // onItemMove={handleItemMove}
                    // onItemResize={handleItemResize}
                    groups={newGroups}
                    items={timelineItem}
                    itemRenderer={itemRenderer}
                    canResize={false}
                    verticalLineClassNamesForTime={(
                      timeStart,
                      timeEnd,
                    ): string[] | undefined => {
                      console.log("project", {
                        timeStart,
                        timeEnd,
                        pausedTimeRange,
                      });
                      const currentTimeStart = moment(timeStart);
                      const currentTimeEnd = moment(timeEnd);
                      const classes = [];

                      for (const holiday of holidays) {
                        if (
                          holiday.isSame(currentTimeStart, "day") &&
                          holiday.isSame(currentTimeEnd, "day")
                        ) {
                          classes.push("holiday");
                        }
                      }
                      return classes;
                    }}
                  >
                    <TimelineMarkers>
                      <CustomMarker
                        date={new Date()}
                        key={new Date().valueOf()}
                      >
                        {({ styles }) => {
                          const newStyles = {
                            ...styles,
                            backgroundColor: isTimeOfficeHour(new Date())
                              ? "#DA498E"
                              : "orange",
                            padding: "2px",
                          };
                          return (
                            <div
                              style={{
                                ...newStyles,
                                zIndex: 100,
                                opacity: "50%",
                              }}
                            />
                          );
                        }}
                      </CustomMarker>
                      <CustomMarker
                        key={deadLine}
                        date={moment.unix(deadLine).valueOf()}
                      >
                        {({ styles }) => {
                          const newStyles = {
                            ...styles,
                            backgroundColor: "red",
                            width: "1%",
                            opacity: "30%",
                          };
                          return <div style={newStyles} />;
                        }}
                      </CustomMarker>
                    </TimelineMarkers>
                  </Timeline>
                   ):(
                    <Skeleton active paragraph={{ rows: 10 }} round />
                  )               
                 } 
                </div>
              </div>
            </div>
          </Panel>
          <Panel
            header="Resource Requirements"
            key="2"
            style={{ color: "red !important" }}
          >
            <div className="stepFour-cards-view-three">
              <div className="stepFour-cards">
                <ResourceTable
                  projectStatus={projectStatus}
                  data={tableData}
                  rawMaterial={[
                    projectData.rawMaterial,
                    projectData.rawMaterialCompleted,
                  ]}
                />
              </div>
            </div>
          </Panel>
          <Panel header={`Machine and ${Container.NAME} Selection`} key="3">
            <div className="stepFour-cards-view-two">
              <div
                style={{
                  marginLeft: "150px",
                  marginRight: "50px",
                  marginBottom: "120px",
                }}
              >
                <div style={{ width: "90%" }} className="stepTwo-scheduling">
                  {/* <NodeDataModal
                    update={false}
                    visible={nodeModalVisible}
                    disable
                    nodeData={selectedNode}
                    onClose={() => setNodeModalVisible(false)}
                    notTemplateCreation={true}
                  /> */}
                  <NodeDataModalProject
                    update={false}
                    visible={nodeModalVisible}
                    disable
                    nodeData={selectedNode}
                    onClose={() => setNodeModalVisible(false)}
                    notTemplateCreation={true}
                  />

                  {/* <InputCutLengthSelectionModal
                    visible={inputModalVisible}
                    disable={false}
                    nodeData={selectedNode}
                    onClose={() => setInputModalVisible(false)}
                    inputID={selectedTaskId}
                  /> */}

                  <ContainerViewModal
                    visible={containerModalVisible}
                    containerArray={containerArray}
                    onClose={() => {
                      setContainerModalVisible(false);
                      console.log("-----------------", containerArray);
                      // setContainerArray([]);
                    }}
                  />

                  <InputCutLengthViewModal
                    visible={inputModalVisible}
                    nodeData={cutLengthData}
                    onClose={() => setInputModalVisible(false)}
                    inputID={selectedTaskId}
                  />

                  <div style={{ height: 400, width: "100%", marginTop: 30 }}>
                    <div
                      style={{
                        zIndex: 9,
                        position: "absolute",
                        // bottom: 10,
                        // right: 15,
                        background: "rgba(0, 0, 0, .5)",
                        padding: 10,
                        color: "white",
                      }}
                    >
                      <Tooltip title="Zoom In" placement="left">
                        <PlusOutlined
                          style={{
                            display: "block",
                            width: "100%",
                            margin: "5px 0",
                          }}
                          onClick={() => canvasRef.current.zoomIn()}
                        />
                      </Tooltip>
                      <Tooltip title="Zoom Out" placement="left">
                        <MinusOutlined
                          style={{
                            display: "block",
                            width: "100%",
                            margin: "5px 50",
                          }}
                          onClick={() => canvasRef.current.zoomOut()}
                        />
                      </Tooltip>
                      <Tooltip title="Fit" placement="left">
                        <ExpandOutlined
                          style={{
                            display: "block",
                            width: "100%",
                            margin: "5px 0",
                          }}
                          onClick={() => canvasRef.current.fitCanvas()}
                        />
                      </Tooltip>
                    </div>
                    <Canvas
                      fit
                      disabled
                      center
                      direction="RIGHT"
                      // width={1000} // Use small canvas dimensions to make sure the nodes appear on screen immediately
                      maxHeight={1000}
                      height={500}
                      ref={canvasRef}
                      nodes={nodes}
                      edges={edges}
                      node={(n) => (
                        <Node
                          {...n}
                          style={{
                            borderRadius: 30,
                            fill: nodeColour(n),
                          }}
                          label={
                            <Label
                              style={{
                                visibility: "hidden",
                              }}
                            />
                          }
                        >
                          {(event) => (
                            <foreignObject
                              height={event.height - 20}
                              width={event.width - 20}
                              x={15}
                              y={5}
                            >
                              <div
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignSelf: "center",
                                  display: "flex",
                                  cursor: "pointer",
                                  // backgroundColor: "red",
                                }}
                              >
                                <div
                                  style={{
                                    padding: 1,
                                    alignSelf: "flex-start",
                                  }}
                                >
                                  {event?.node?.data?.type !== "Block" && (
                                    <div className="node-custom">
                                      <img
                                        alt="popup"
                                        src={PopupIcon}
                                        className="popup-img"
                                        onClick={() =>
                                          nodeDataSelect(event.node)
                                        }
                                      />

                                      {event?.node?.data?.type === "Task" ? (
                                        <>
                                          <MenuUnfoldOutlined
                                            alt="popup"
                                            className="popup-container"
                                            onClick={() =>
                                              selectContainers(event.node)
                                            }
                                          />
                                        </>
                                      ) : event?.node?.data?.type ===
                                        "Input" ? (
                                        <>
                                          <MenuUnfoldOutlined
                                            alt="popup"
                                            className="popup-container"
                                            onClick={() => {
                                              setInputModalVisible(true);
                                              setSelectedTaskId(event.node.id);
                                              setCutLengthData(
                                                projectData.inputs.find(
                                                  (val: any) =>
                                                    val.inputId ===
                                                    event.node.id,
                                                ),
                                              );
                                              console.log(
                                                projectData.inputs.find(
                                                  (val: any) =>
                                                    val.inputId ===
                                                    event.node.id,
                                                ),
                                              );
                                            }}
                                          />
                                        </>
                                      ) : null}
                                      {event?.node?.data?.props
                                        ? event?.node?.data?.props?.taskName ||
                                          event?.node?.data?.props?.inputItem ||
                                          event?.node?.data?.props?.output
                                        : event?.node?.data?.type}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </foreignObject>
                          )}
                        </Node>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default ViewProject;

// <div className="view-stepOne-header" style={{ width: "100%" }}>
//         <Form {...layout}>
//           <Row className="form-row-header">
//             <Col span={11}>
//               <Form.Item label="Project">
//                 <div
//                   style={{
//                     backgroundColor: "#2856da",
//                     color: "#ffffff",
//                     padding: "10px",
//                     borderRadius: "5px",
//                     display: "flex",
//                     flexDirection: "row",
//                     justifyContent: "center",
//                   }}
//                 >
//                   {projectData?.project?.value?.projectName}
//                 </div>
//               </Form.Item>
//             </Col>
//             {/* <Col span={11} style={{ display: "flex", flexDirection: "row" }}>
//               <Col span={7}>
//                 <Popconfirm
//                   disabled={projectStarted}
//                   icon={false}
//                   title="Are you sure to start the project ?"
//                   onConfirm={() => startProject()}
//                   okText="Yes"
//                   cancelText="No"
//                 >
//                   <Button
//                     className="start_project"
//                     disabled={projectStarted}
//                     type="primary"
//                     style={{
//                       height: "40px",
//                       borderRadius: "5px",
//                       fontFamily: "Poppins",
//                       fontSize: "15px",
//                     }}
//                   >
//                     Start Project
//                   </Button>
//                 </Popconfirm>
//               </Col>
//               <Col span={10}>
//                 <Popconfirm
//                   icon={false}
//                   title="Are you sure to cancel the project ?"
//                   onConfirm={() => cancelProject()}
//                   okText="Yes"
//                   cancelText="No"
//                 >
//                   <Button
//                     danger
//                     type="primary"
//                     style={{
//                       height: "40px",
//                       borderRadius: "5px",
//                       fontFamily: "Poppins",
//                       fontSize: "15px",
//                     }}
//                   >
//                     Cancel Project
//                   </Button>
//                 </Popconfirm>
//               </Col>
//             </Col> */}
//           </Row>
//           <Row className="form-row-header">
//             {/* <Col span={11}>
//               <Form.Item label="Product Name" className="label-text">
//                 {projectData?.templateDetails?.processFields?.processName}
//               </Form.Item>
//             </Col> */}
//             {/* <Col span={11}>
//               {projectData.project.value.projectStatus === "Pending" ||
//               projectData.project.value.projectStatus === "Expired" ? (
//                 <Form.Item label="Project Progress">
//                   <Tag
//                     color={projectStatusBackGroundColour(
//                       projectData?.project?.value?.projectStatus,
//                     )}
//                     className="status-tag"
//                     style={{
//                       color: projectStatusTextColourD(
//                         projectData?.project?.value?.projectStatus,
//                       ),
//                     }}
//                   >
//                     Project is {projectData?.project?.value?.projectStatus}
//                   </Tag>
//                 </Form.Item>
//               ) : (
//                 <Form.Item label="Project Progress">
//                   {projectData?.projectMap?.actualStartTime !== "" ? (
//                     <Tooltip
//                       title={`${projectProgress().completed}% completed and ${
//                         projectProgress().needToDone
//                       }%  need to be completed`}
//                     >
//                       <Progress
//                         percent={100}
//                         showInfo={false}
//                         strokeColor="#F64D4F"
//                         success={{
//                           percent: projectProgress().completed,
//                         }}
//                       />
//                       <div style={{ color: "#ffffff" }}>
//                         {projectProgress().completed === 100
//                           ? `${projectProgress().completed}% completed`
//                           : `${projectProgress().completed}% completed and ${
//                               projectProgress().needToDone
//                             }% in progress
//                         `}
//                       </div>
//                     </Tooltip>
//                   ) : null}
//                 </Form.Item>
//               )}
//             </Col> */}
//           </Row>
//           <Row className="form-row-header">
//             <Col span={11}>
//               <Form.Item label="Scheduled Start Date" className="label-text">
//                 {moment
//                   .unix(projectData?.startTime)
//                   .format("DD:MM:YYYY, hh:mm:ss a")}
//               </Form.Item>
//             </Col>
//             <Col span={11}>
//               <Form.Item label="Project status">
//                 <Tag
//                   color={projectStatusBackGroundColour(
//                     projectData?.projectStatus,
//                   )}
//                   className="status-tag"
//                   style={{
//                     color: projectStatusTextColourD(
//                       projectData?.projectStatus,
//                     ),
//                   }}
//                 >
//                   {"\u2B24"}{" "}
//                   {projectData?.projectStatus?.toUpperCase()}
//                 </Tag>
//               </Form.Item>
//             </Col>
//           </Row>
//           <Row className="form-row-header">
//             {/* <Col span={11}>
//               <Form.Item label="Actual Start Date" className="label-text">
//                 {projectData?.projectMap?.actualStartTime !== ""
//                   ? moment
//                       .unix(projectData?.projectMap?.actualStartTime)
//                       .format("DD:MM:YYYY, hh:mm:ss a")
//                   : "-"}
//               </Form.Item>
//             </Col> */}
//             <Col span={11}>
//               <Form.Item label="Scheduled End Date" className="label-text">
//                 {moment
//                   .unix(projectData?.endTime)
//                   .format("DD:MM:YYYY, hh:mm:ss a")}
//               </Form.Item>
//             </Col>
//           </Row>
//           {/* <Row className="form-row-header">
//             <Col span={11}>
//               <Form.Item label="Current Running Task">
//                 {currentlyRunningTasks?.length !== 0 ? (
//                   currentlyRunningTasks?.map((val: string) => {
//                     return (
//                       <>
//                         <Tag
//                           color="#ffffff"
//                           style={{
//                             backgroundColor: "#2856da",
//                             padding: "5px",
//                             borderRadius: "5px",
//                             margin: "5px",
//                           }}
//                         >
//                           {"\u2B24 "}
//                           {val.toUpperCase()}
//                         </Tag>
//                       </>
//                     );
//                   })
//                 ) : (
//                   <Tag
//                     color="#ffffff"
//                     style={{
//                       backgroundColor: "#F64D4F",
//                       padding: "5px",
//                       borderRadius: "5px",
//                       margin: "5px",
//                     }}
//                   >
//                     {"\u2B24"}
//                     {" NONE"}
//                   </Tag>
//                 )}
//               </Form.Item>
//             </Col>
//             <Col span={11}>
//               <Form.Item label="Actual End Date" className="label-text">
//                 {projectData?.projectMap?.actualEndTime !== ""
//                   ? moment
//                       .unix(projectData?.projectMap?.actualEndTime)
//                       .format("DD:MM:YYYY, hh:mm:ss a")
//                   : "-"}
//               </Form.Item>
//             </Col>
//           </Row> */}
//           <Row className="form-row-header">
//             <Col span={11}>
//               <Form.Item label="Created At:" className="label-text">
//                 {projectData?.project?.value.projectCreatedAt !== undefined
//                   ? DateTime.fromMillis(
//                       projectData.project.value.projectCreatedAt,
//                     ).toFormat("dd:MM:yyyy,  hh:mm:ss a")
//                   : "-"}
//               </Form.Item>
//               <Form.Item label="Last Updated At:" className="label-text">
//                 {projectData?.project?.value.lastUpdated !== undefined
//                   ? DateTime.fromMillis(
//                       projectData.project.value.lastUpdated,
//                     ).toFormat("dd:MM:yyyy,  hh:mm:ss a")
//                   : "-"}
//               </Form.Item>
//             </Col>
//           </Row>
//         </Form>
//       </div>
