/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable import/order */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable array-callback-return */
import React, { FC, useEffect, useState } from "react";
import {
  Button,
  Input,
  message,
  Modal,
  Popconfirm,
  Skeleton,
  Space,
  Table,
} from "antd";
import {
  DeleteFilled,
  EditFilled,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons/lib";
import { DateTime } from "luxon";
import { useConnection } from "../../Services/ApplicationContext";
import BobbinModal from "./BobbinModal";
import { ResourceDetails } from "../../definetions/ProjectModal";
import "../tableStyles.scss";
import "../pagination.scss";
import {
  IoCalendar,
  IoCreateOutline,
  IoTrashSharp,
  IoWarning,
} from "react-icons/io5";
import "./bobbinManagement.scss";
import CalComponent from "../../components/CalComponent/CalComponent";
import BobbinManagementCal from "./BobbinManagementCal";
import { Container, SERVICE_TYPE } from "../../constants/constants";

export interface BobbinManagementProps {
  bobbins: ResourceDetails[];
  onUpdateData: () => void;
  title: string;
  total: number;
  pageSize: number;
  currentPage: number;
  handleChange: (val: any, sorter: any) => void;
  setSearchValue: (val: string) => void;
  searchValue: string;
  setNetworkSearchValue: (val: string) => void;
  onSearch: () => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
}
const BobbinManagement: FC<BobbinManagementProps> = (
  props: BobbinManagementProps,
) => {
  const {
    bobbins,
    onUpdateData,
    title,
    total,
    currentPage,
    pageSize,
    handleChange,
    setSearchValue,
    searchValue,
    setNetworkSearchValue,
    onSearch,
    loading,
    setLoading,
  } = props;
  const { delete: del } = useConnection();

  const [selectedRow, setSelectedRow] = useState();

  const [visible, setVisible] = useState<boolean>(false);
  const [showCal, setShowCal] = useState<boolean>(false);

  const [resourceEvents, setResourceEvents] = useState<any>([]);
  const [resourceAvailability, setResourceAvailability] = useState<any>([]);
  const [currentLoadedDates, setCurrentLoadedDates] = useState<number[]>([]);
  const { get } = useConnection();

  const edit = (record: any) => {
    setSelectedRow(record);
  };

  const getAllEvents: any = async (current: any, date: DateTime) => {
    // console.log(current);
    setLoading(true);
    if (current === undefined) {
      return [];
    }

    if (date === undefined) {
      date = DateTime.now().startOf("year");
    }
    const events = await get(
      `production/BobbingScheduling/${current.id}/${date.valueOf() / 1000}/${
        date.plus({ year: 1 }).valueOf() / 1000
      }`,
    );

    setCurrentLoadedDates((oldArray: any[]) => [...oldArray, date.year]);

    let list: {
      time: number;
      count: number;
      desc: string;
      type: string;
      id: string;
    }[] = [];

    for (const el of events.data) {
      if (el.taskStatus === false || el.taskStatus === undefined) {
        list.push({
          type: "start",
          time: el.startTime,
          count: el.bobbinCount,
          desc: `Project:${el.projectName} Machine:${el.machineName}`,
          id: el._range,
        });
        list.push({
          type: "end",
          time: el.endTime,
          count: el.bobbinCount,
          desc: `Project:${el.projectName} Machine:${el.machineName}`,
          id: el._range,
        });
      }
    }

    list = list.sort((a: any, b: any) => a.time - b.time);
    const availableList: any[] = [];
    const unEndedStarts: any = {};
    let prvStartTime = 0;
    for (let i = 0; i < list.length; i++) {
      const c = list[i];

      const pendingCount: number = Object.values<any>(unEndedStarts).reduce(
        (a: any, b: any) => a + (b.count || 0),
        0,
      );
      // Project:${e.projectName}\nMachine:${e.machineName}\n
      if (Object.keys(unEndedStarts).length > 0) {
        availableList.push({
          title: `Available Amount:${current.totalAmount - pendingCount}`,
          heading: `Availability Details of ${current.resourceName}`,
          count: current.totalAmount - pendingCount,
          start: new Date(prvStartTime * 1000),
          end: new Date(c.time * 1000),
          color: "white",
          type: "availability",
        });
      }
      if (c.type === "start") {
        unEndedStarts[c.id] = c;
      } else if (c.type === "end" && unEndedStarts[c.id]) {
        delete unEndedStarts[c.id];
      } else {
        console.log("Unexpected node:", c);
      }
      prvStartTime = c.time;
    }
    setResourceAvailability((oldArray: any[]) =>
      oldArray.concat(availableList),
    );
    console.log("\n\n\n\nhhhhhhh", unEndedStarts, "\n\n\n\nhhhhhhhhh");
    const x = events.data.map((e: any) => {
      if (e.taskStatus === false || e.taskStatus === undefined) {
        return {
          title: `Project: ${e.projectName}    |    Machine: ${e.machineName}    |    Used Amount: ${e.bobbinCount}`,
          heading: `Usage Details of ${current.resourceName}`,
          machine: e.machineName,
          project: e.projectName,
          count: e.bobbinCount,
          start: new Date(e.startTime * 1000),
          end: new Date(e.endTime * 1000),
          color: "white",
          type: "usage",
        };
      }
    });
    // console.log(x, "mapped");
    setResourceEvents((oldArray: any[]) => oldArray.concat(x));
    setLoading(false);
  };

  const showCalender = (record: any) => {
    if (!currentLoadedDates.includes(DateTime.now().startOf("year").year)) {
      getAllEvents(record);
      setSelectedRow(record);
    }
  };

  const Delete = async (record: any) => {
    setLoading(true);
    try {
      // console.log(record._key);
      const result = await del(
        `system-management/resources/deleteResource/${record?.key?.toString()}/${
          record?.resourceName
        }`,
        undefined,
        SERVICE_TYPE.serviceType
      );
      onUpdateData();
      setLoading(false);
      if (result.status === 200) {
        setNetworkSearchValue("");
        setSearchValue("");
        message.open({
          type: "success",
          content: result.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      }
    } catch (error: any) {
      message.open({
        type: "error",
        content: `${error.message}`,
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
      setLoading(false);
    }
  };

  const columns: any[] = [
    {
      title: `${Container.NAME}`,
      dataIndex: "resourceName",
      key: "resourceName",
      width: "20%",
      sorter: true,
    },
    {
      title: "Last Updated On",
      width: "30%",
      key: "CreatedAt",
      sorter: true,
      render: (_: any, record: any) => {
        return DateTime.fromMillis(record.CreatedAt).toFormat(
          "dd:MM:yyyy,  hh:mm:ss a",
        );
      },
    },
    { title: "Type", dataIndex: "materialType", key: "materialType" },
    // { title: "Available", dataIndex: "available", key: "available" },
    // { title: "Allocated", dataIndex: "allocated", key: "allocated" },
    {
      title: "Total",
      dataIndex: "totalAmount",
      key: "total",
      render: (_: any, record: any) => {
        if (record?.materialType === "coil") {
          return <Space size="middle">-</Space>;
        }
        return <Space size="middle">{record?.totalAmount}</Space>;
      },
    },
    {
      title: "Action",
      dataIndex: "operation",
      width: "120px",
      fixed: "right",
      render: (_: any, record: any) => {
        return (
          <Space size="middle">
            <a
              onClick={() => {
                setShowCal(true);
                showCalender(record);
              }}
            >
              <IoCalendar size={22} color="#487199" />
            </a>
            <a onClick={() => edit(record)}>
              <IoCreateOutline size={22} color="#487199" />
            </a>
            <Popconfirm
              title={`Are you sure to delete ${record.resourceName}?`}
              onConfirm={() => Delete(record)}
              okText="Yes"
              okButtonProps={{ danger: true }}
              cancelText="No"
              icon={<IoWarning size={30} color="#eec335" />}
              placement="topLeft"
              cancelButtonProps={{ type: "primary" }}
            >
              <a>
                <IoTrashSharp size={20} color="#f18b8b" />
              </a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const onCloseModal = (val?: any) => {
    if (val) {
      onUpdateData();
    }
    setVisible(false);
    setSelectedRow(undefined);
  };

  const onNavigate = (newDate: any) => {
    // console.log("\n\n\n\n", newDate, "\n\n\n\n");
    const d = DateTime.fromMillis(newDate.valueOf()).startOf("year");

    if (!currentLoadedDates.includes(d.year)) {
      getAllEvents(selectedRow, d);
    }
  };

  return (
    <div>
      <BobbinModal
        onClose={onCloseModal}
        visible={visible}
        type="Add"
        title={title}
        data={undefined}
        onUpdate={onUpdateData}
      />
      {selectedRow !== undefined && !showCal && (
        <BobbinModal
          title={title}
          onClose={onCloseModal}
          visible={selectedRow !== undefined}
          type="Edit"
          data={selectedRow}
          onUpdate={onUpdateData}
        />
      )}
      {selectedRow !== undefined && showCal && (
        <div>
          <Modal
            title={`Usage Calendar - ${Container.NAME} ${
              selectedRow!["resourceName"]
            }`}
            centered
            visible={showCal}
            onCancel={(v) => {
              setShowCal(false);
              setSelectedRow(undefined);
              setCurrentLoadedDates([]);
              setResourceEvents([]);
              setResourceAvailability([]);
            }}
            width="90%"
            footer={null}
            className="general-modal-popup"
            style={{ marginTop: "50px", height: 800, minHeight: 800 }}
          >
            <BobbinManagementCal
              usageEvents={resourceEvents}
              availableEvents={resourceAvailability}
              loading={loading}
              onNavigate={onNavigate}
            />
          </Modal>
        </div>
      )}
      <div className="bobbin-subHeading-container">
        <p className="bobbin-sub-text">{title}</p>
      </div>

      <div>
        <div className="row-space-between">
          <div style={{ marginBottom: "30px" }}>
            <Button
              type="primary"
              style={{
                borderRadius: "5px",
                height: "45px",
                fontSize: "15px",
                fontWeight: 300,
                fontFamily: "Poppins",
              }}
              onClick={() => setVisible(true)}
            >
              <PlusOutlined style={{ fontSize: "17px", fontWeight: "bold" }} />
              Add Item
            </Button>
          </div>
          <div style={{ marginBottom: "30px" }}>
            <div className="row end">
              <div
                className="search_filter_lables"
                style={{ marginRight: "7px" }}
              >
                Search:
              </div>
              <Input
                onPressEnter={onSearch}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                style={{
                  fontFamily: "Poppins",
                  color: "red",
                }}
                value={searchValue}
                placeholder={`Search by ${Container.NAME}`}
                suffix={
                  <Button
                    type="primary"
                    onClick={onSearch}
                    style={{
                      borderRadius: "0 5px 5px 0",
                      marginRight: "-4px",
                    }}
                  >
                    <SearchOutlined />
                  </Button>
                }
              />
            </div>
          </div>
        </div>
        <div className="general-table general-pagination">
          <Table
            // className="components-table-demo-nested"
            columns={columns}
            dataSource={bobbins}
            loading={loading}
            pagination={{
              current: currentPage,
              pageSize,
              total,
              showQuickJumper: true,
              showSizeChanger: true,
              pageSizeOptions: ["5", "10", "20", "30", "40", "50", "100"],
            }}
            onChange={(val: any, filter: any, sorter: any) =>
              handleChange(val, sorter)
            }
          />
        </div>
      </div>
    </div>
  );
};
export default BobbinManagement;
