import React, { useState, FC } from "react";
import { Form, Input, Button, Checkbox, message } from "antd";
import { UserOutlined, LockOutlined, LeftOutlined } from "@ant-design/icons";
import { MailUnreadOutline, MailOutline } from "react-ionicons";

import sha1 from "sha1";
import { NavLink, Redirect } from "react-router-dom";
import { useHistory } from "react-router";
import sierraLogo from "./sierralogo.png";
import "./EnterOTP.scss";
import { useConnection } from "../../Services/ApplicationContext";
import { useAuthZ } from "../../Services/AuthorizationContext";

const EnterOTP: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  const onSubmit = () => {
    history.push("/ChangePassword");
  };
  return (
    <div className="enter-OTP-main-container">
      <div className="form-container-enter-OTP">
        <div className="form-header-enter-OTP">
          <div className="header-container-enter-OTP">
            <div className="detail-container-enter-OTP">
              <h2 className="h2-enter-OTP">Enter OTP</h2>
              <h4 className="h4-enter-OTP">
                Check your mail inbox for OTP code
              </h4>
            </div>
            <div className="icons-OTP">
              <MailOutline color="#fff" height="75px" width="75px" />
            </div>
          </div>
        </div>
        <div className="form-footer-enter-OTP">
          <div className="form-footer-container-enter-OTP">
            <Form
              layout="vertical"
              name="basic"
              initialValues={{
                remember: true,
              }}
              hideRequiredMark
              onFinish={onSubmit}
            >
              <Form.Item
                label="OTP"
                name="OTP"
                rules={[
                  {
                    required: true,
                    message: "Enter Valid OTP",
                    pattern: new RegExp(/^(\d{5})?$/),
                  },
                ]}
              >
                <Input
                  prefix={<MailUnreadOutline color="#808080" />}
                  placeholder="OTP"
                  spellCheck="false"
                  type="text"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  className="submit-btn-OTP"
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  style={{ marginTop: "15px" }}
                >
                  Send OTP
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="login-enter-OTP">
            <NavLink to="/" className="navigation-enter-OTP">
              Return to Login
            </NavLink>
          </div>
        </div>
      </div>
      <div>
        <h5 className="powered-by-enter-OTP">Powered by Xeptagon</h5>
      </div>
    </div>
  );
};

export default EnterOTP;
