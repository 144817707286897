/* eslint-disable no-param-reassign */
import { NodeData } from "reaflow";
import { getCableStateConnections } from "./cableFlowChartValidation";

export interface Input {
  value: string;
  id?: string;
  disable: boolean;
}

export interface InputMap {
  [id: string]: {
    inputs: { [id: string]: Input[] } | Record<string, Input[]>;
    outputsOfTheNode: Input[];
    toNodes: number;
  };
}

export let inputMap: InputMap;

export const initializeInputMaps = (): void => {
  inputMap = {
    "1": {
      inputs: {},
      outputsOfTheNode: new Array<Input>(),
      toNodes: 1,
    },
    "2": {
      inputs: {},
      outputsOfTheNode: new Array<Input>(),
      toNodes: 1,
    },
    "3": {
      inputs: {},
      outputsOfTheNode: new Array<Input>(),
      toNodes: 0,
    },
  };
};

initializeInputMaps();
// let inputMap = initializeInputMaps();

const printInputMap = (): void => {
  console.log(inputMap);
};

export const clearInputMap = (): void => {
  const keys = Object.keys(inputMap);
  keys.forEach((key: string) => {
    delete inputMap[key];
  });
};

export const getInputsUsingFromId = (
  nodeId: string,
  fromId: string,
): Input[] => {
  // return JSON.parse(JSON.stringify(inputMap[nodeId].inputs[fromId]));
  return inputMap[nodeId].inputs[fromId];
};

export const addToMap = (id: string): void => {
  inputMap[id] = {
    // fromNodeTypes: nodeTypes,
    inputs: {},
    outputsOfTheNode: new Array<Input>(),
    toNodes: 0,
  };
  // printInputMap();
};

export const addOutputs = (fromId: string, outputs: string[]): void => {
  // const tempOutputs = inputMap[fromId].outputsOfTheNode.map(
  //   (output) => output.value,
  // );

  const tempOutputs = outputs.map((output) => ({
    value: output,
    disable: false,
  }));

  inputMap[fromId].outputsOfTheNode = tempOutputs;
  // outputs.forEach((output) => {
  //   // if (!tempOutputs.includes(output)) {
  //   inputMap[fromId].outputsOfTheNode.push({
  //     value: output,
  //     disable: false,
  //   });
  // }
  // });
  // printInputMap();
};

export const addOutputsOnCloseModal = (
  fromId: string,
  outputs: any[],
  // nameChanged?: boolean,
): void => {
  // checked in which index the values have selected only those values are
  // disabled
  const previousOutputs = JSON.parse(
    JSON.stringify(inputMap[fromId].outputsOfTheNode),
  );
  const tempOutputStatus = previousOutputs.map((item: any) => item?.disable);

  // clearing the outputsOfTheNode
  inputMap[fromId].outputsOfTheNode.splice(
    0,
    inputMap[fromId].outputsOfTheNode.length,
  );
  outputs.forEach((output, index) => {
    if (tempOutputStatus[index]) {
      inputMap[fromId].outputsOfTheNode.push({
        value: output.inputItem,
        id: output.inputId,
        disable: true,
      });
    } else {
      inputMap[fromId].outputsOfTheNode.push({
        value: output.inputItem,
        id: output.inputId,
        disable: false,
      });
    }
  });
  console.log(previousOutputs, "previousOutputskkkkkk", inputMap[fromId]);
};

export const addOutputsOnUpdate = (fromId: string, outputs: string[]): void => {
  const tempOutputs = outputs.map((output) => ({
    value: output,
    disable: true,
  }));

  inputMap[fromId].outputsOfTheNode = tempOutputs;
  // printInputMap();
};

export const changeToNodeTasksInputs = (
  data: any,
  previousName: string,
  currentName: string,
  nodeType: string, // previous node type
): any => {
  console.log(data, "datadatadatadatadatadata");
  if (
    data?.type === "Task" &&
    data?.props &&
    (nodeType === "Task" || nodeType === "Input")
  ) {
    if (data?.props?.inputOutputMap !== undefined) {
      const inputOutputMap = data?.props?.inputOutputMap;
      const tempTaskInputs = inputOutputMap.map((taskInput: any) => ({
        inputValues: taskInput.inputValues.replace(previousName, currentName),
      }));
      data.props.inputOutputMap = tempTaskInputs;

      return data;
    }
    // if (data?.props?.concurrentInputParallel !== undefined) {
    //   const concurrentInputParallel = data?.props?.concurrentInputParallel;
    //   const tempTaskInputs = concurrentInputParallel.map((taskInput: any) => ({
    //     inputValues: taskInput.inputValues.replace(previousName, currentName),
    //   }));
    //   data.props.concurrentInputParallel = tempTaskInputs;

    //   return data;
    // }
    if (data?.props?.taskInputs !== undefined) {
      const taskInputs = data?.props?.taskInputs;
      const tempTaskInputs = taskInputs.map((taskInput: any) => ({
        inputValues: taskInput.inputValues.replace(previousName, currentName),
      }));
      console.log(tempTaskInputs, "tempTaskInputs");
      data.props.taskInputs = tempTaskInputs;

      return data;
    }
    return data;
  }
  // if (data?.type === "Task" && data?.props && nodeType === "Input") {
  //   console.log("Input");
  //   if (data?.props?.seriesInput !== undefined) {
  //     const seriesInput = data?.props?.seriesInput;
  //     const tempTaskInputs = seriesInput.map((taskInput: any) => ({
  //       seriesInput: taskInput.seriesInput.replace(previousName, currentName),
  //     }));
  //     console.log(tempTaskInputs, "tempTaskInputs");
  //     data.props.seriesInput = tempTaskInputs;

  //     return data;
  //   }
  //   if (data?.props?.taskInputs !== undefined) {
  //     const taskInputs = data?.props?.taskInputs;
  //     const tempTaskInputs = taskInputs.map((taskInput: any) => ({
  //       inputValues: taskInput.inputValues.replace(previousName, currentName),
  //     }));
  //     console.log(tempTaskInputs, "tempTaskInputs");
  //     data.props.taskInputs = tempTaskInputs;

  //     return data;
  //   }
  // }
};

export const addOutputsOnUpdateWithOutputTypes = (
  fromId: string,
  outputs: Input[],
): void => {
  inputMap[fromId].outputsOfTheNode = outputs;
  // printInputMap();
};

export const addInput = (
  id: string,
  fromId: string,
  // inputList?: string[],
): void => {
  if (inputMap[id] && inputMap[fromId]) {
    inputMap[id].inputs[fromId] = inputMap[fromId].outputsOfTheNode;
  }
  printInputMap();
  console.log(printInputMap(), "qqqqqqqqqq", inputMap);
};

// export const addInputOnUpdate = (
//   id: string,
//   fromId: string,
//   inputList: string[],
// ): void => {
//   console.log("update");
//   const temp = inputMap[id].inputs;
//   const inputs = {
//     [fromId]: inputList.map((inputItem: string) => ({
//       value: inputItem,
//       disable: true,
//     })),
//   };

//   inputMap[id].inputs = { ...temp, ...inputs };
//   printInputMap();
// };

export const populateInputMapOnUndoRedo = (nodes: any, edges: any): void => {
  clearInputMap();
  console.log("undo redo");
  nodes.forEach((node: any) => {
    addToMap(node?.id);
    if (node?.data?.props) {
      if (node?.data?.type === "Input") {
        if (node?.data?.props?.taskRatios?.length > 0) {
          const outPuts: any[] = [];
          node?.data?.props?.taskRatios?.map(
            (outPut: { name: any; ratio: any }, index: any) => {
              outPuts.push(outPut?.name);
            },
          );
          addOutputs(node?.id, [...outPuts]);
        } else {
          addOutputs(node?.id, [node?.data?.props?.inputItem]);
        }
      } else if (node?.data?.type === "Task") {
        const toNodeIds = edges
          .filter((edge: any) => edge.from === node?.id)
          .map((item: any) => item?.to);

        const toNodeTypes = new Set();
        const toNodes: any[] = [];

        toNodeIds.forEach((nodeId: any) => {
          const toNode = nodes.find((tempNode: any) => tempNode.id === nodeId);
          toNodes.push(toNode);
          toNodeTypes.add(toNode?.data?.type);
        });

        const fromNodeOutputs = node?.data?.props?.taskOutputs.map(
          (taskOutput: any) => taskOutput.name,
        );

        const toNodeInputs: string[] = new Array<string>();

        if (toNodeTypes.has("Output")) {
          toNodes.forEach((toNode: any) => {
            if (toNode?.data?.type !== "Output") {
              const tempInputs = toNode?.data?.props?.taskInputs.map(
                (taskInputs: any) => taskInputs.name,
              );
              toNodeInputs.push(...tempInputs);
            }
          });

          const outputsToAdd: Input[] = new Array<Input>();
          fromNodeOutputs.forEach((fromNodeOutput: any) => {
            if (toNodeInputs.includes(fromNodeOutput)) {
              outputsToAdd.push({
                value: fromNodeOutput,
                disable: true,
              });
            } else {
              outputsToAdd.push({
                value: fromNodeOutput,
                disable: false,
              });
            }
          });
          addOutputsOnUpdateWithOutputTypes(node?.id, outputsToAdd);
        } else {
          addOutputs(node?.id, fromNodeOutputs);
        }
      }
    }
    printInputMap();
  });

  edges.forEach((edge: any) => {
    const toNodeId = edge?.to;
    const fromNodeId = edge?.from;

    addInput(toNodeId, fromNodeId);
  });
  // printInputMap();
};

export const populateInputMapOnUpdate = (nodes: any, edges: any): void => {
  clearInputMap();
  nodes.forEach((node: any) => {
    addToMap(node?.id);
    if (node?.data?.props) {
      if (node?.data?.type === "Input") {
        // if (node?.data?.props?.taskRatios?.length > 0) {
        //   const outPuts: any[] = [];
        //   node?.data?.props?.taskRatios?.map(
        //     (outPut: { name: any; ratio: any }, index: any) => {
        //       outPuts.push(outPut?.name);
        //     },
        //   );
        //   addOutputsOnUpdate(node?.id, [...outPuts]);
        // } else {
        addOutputsOnUpdate(node?.id, [node?.data?.props?.inputItem]);
        // }
      } else if (node?.data?.type === "Task") {
        const toNodeIds = edges
          .filter((edge: any) => edge.from === node?.id)
          .map((item: any) => item?.to);

        const toNodeTypes = new Set();
        const toNodes: any[] = [];

        toNodeIds.forEach((nodeId: any) => {
          const toNode = nodes.find((tempNode: any) => tempNode.id === nodeId);
          toNodes.push(toNode);
          toNodeTypes.add(toNode?.data?.type);
        });

        const fromNodeOutputs = node?.data?.props?.taskOutputs.map(
          (taskOutput: any) => taskOutput.name,
        );

        const toNodeInputs: string[] = new Array<string>();

        if (toNodeTypes.has("Output")) {
          toNodes.forEach((toNode: any) => {
            if (toNode?.data?.type !== "Output") {
              const tempInputs = toNode?.data?.props?.taskInputs.map(
                (taskInputs: any) => taskInputs.name,
              );
              toNodeInputs.push(...tempInputs);
            }
          });

          const outputsToAdd: Input[] = new Array<Input>();
          fromNodeOutputs.forEach((fromNodeOutput: any) => {
            if (toNodeInputs.includes(fromNodeOutput)) {
              outputsToAdd.push({
                value: fromNodeOutput,
                disable: true,
              });
            } else {
              outputsToAdd.push({
                value: fromNodeOutput,
                disable: false,
              });
            }
          });
          addOutputsOnUpdateWithOutputTypes(node?.id, outputsToAdd);
        } else {
          addOutputsOnUpdate(node?.id, fromNodeOutputs);
        }
      }
    }
    printInputMap();
  });

  edges.forEach((edge: any) => {
    const toNodeId = edge?.to;
    const fromNodeId = edge?.from;

    addInput(toNodeId, fromNodeId);
  });
  // printInputMap();
};

export const selectInput = (
  nodeId: string,
  selectedInput: { id: string; value: string },
): void => {
  let nodeInputs = inputMap[nodeId].inputs[selectedInput.id];
  // diable the input of the node.
  // console.log(selectedInput);

  nodeInputs = nodeInputs.map((nodeInput: Input) => {
    if (nodeInput.value === selectedInput.value) {
      // eslint-disable-next-line no-param-reassign
      nodeInput.disable = true;
    }

    return nodeInput;
  });

  inputMap[selectedInput.id].outputsOfTheNode.forEach((output) => {
    if (output.value === selectedInput.value) {
      output.disable = true;
    }
  });

  // printInputMap();
};
// checks whether the nodes have outputs that are not mapped
export const getAllNodeOutputStatus = (): {
  [fromId: string]: number;
} => {
  console.log(inputMap, "inputMapinputMap");
  const keys = Object.keys(inputMap);
  const inputs = new Map<string, Input[]>();

  keys.forEach((key: string) => {
    Object.keys(inputMap[key].inputs).forEach((id: string) => {
      inputs.set(id, inputMap[key].inputs[id]);
    });
  });
  console.log(inputs, "inputsinputsinputs");

  const outputsObject = Object.fromEntries(inputs);
  console.log(outputsObject, "outputsObjectoutputsObjectoutputsObject");

  const outputStatusMap = new Map<string, number>();

  Object.keys(outputsObject).forEach((id: string) => {
    const notUsedOutputs = outputsObject[id].filter(
      (item: any) => item?.disable === false,
    );

    outputStatusMap.set(id, notUsedOutputs.length);
  });
  console.log(
    outputStatusMap,
    "outputStatusMapoutputStatusMapoutputStatusMapoutputStatusMap",
  );
  console.log(
    Object.fromEntries(outputStatusMap),
    "Object.fromEntries(outputStatusMap)Object.fromEntries(outputStatusMap)",
  );

  return Object.fromEntries(outputStatusMap);
};

export const deselect = (input: string): void => {
  const keys = Object.keys(inputMap);
  keys.forEach((key: string) => {
    const inputKeys = Object.keys(inputMap[key].inputs);
    inputKeys.forEach((inputKey: string) => {
      let temp = inputMap[key].inputs[inputKey];
      temp = temp.map((item: any) => {
        if (item.value === input && item.disable === true) {
          // eslint-disable-next-line no-param-reassign
          item.disable = false;
        }
        return item;
      });
    });
  });

  keys.forEach((key: string) => {
    inputMap[key].outputsOfTheNode.forEach((output) => {
      if (output.value === input && output.disable === true) {
        console.log("deselect", output.value);
        // eslint-disable-next-line no-param-reassign
        output.disable = false;
      }
    });
  });

  // printInputMap();
};

// changes other inputs disable to false if they are not in inputs array
export const changeDisableInNodeInputs = (
  node: string,
  inputs: string[],
): void => {
  const keys = Object.keys(inputMap[node].inputs);
  keys.forEach((key: string) => {
    inputMap[node].inputs[key].forEach((item: Input) => {
      if (!inputs.includes(item.value) && item.disable === true) {
        // eslint-disable-next-line no-param-reassign
        item.disable = false;
      }
    });
  });

  printInputMap();
};

// removes the inputs that are comming from the prev node
export const removeInputOnPrevNodeDelete = (id: string): void => {
  const nodes = Object.keys(inputMap);
  nodes.forEach((node: string) => {
    if (Object.keys(inputMap[node].inputs).includes(id)) {
      delete inputMap[node].inputs[id];
    }
  });
  // printInputMap();
};

// id is the node id which inputs are needed
export const getInputs = (
  id: string,
  edit?: boolean,
): { id: string; value: string }[] => {
  // console.log(id, "kkkkkuuuuuuuu", inputMap, "mnmnmnmnmnmmn");
  if (inputMap[id]) {
    console.log("idididididid", inputMap[id]);
    const keys = Object.keys(inputMap[id].inputs);
    const inputs: { id: string; value: string; inputId: string }[] = [];

    keys.forEach((key: string) => {
      const temp = inputMap[id].inputs[key]
        // .filter((item: any) => !item.disable)
        .map((item: any) => ({ id: key, value: item.value, inputId: item.id }));

      // const temp1 = inputMap[id].inputs[key].map((item: any) => ({
      //   id: key,
      //   value: item.value,
      // }));

      console.log("inputs", temp);
      inputs.push(...temp);
      // inputs.push(...temp1);
    });
    return inputs;
  }

  return [{ id: "", value: "" }];
};

// export const getInputs = (id: string): { id: string; value: string }[] => {
//   if (inputMap[id]) {
//     let isInputFromAnyInputNodeWithMoreOutput: boolean = false;
//     let outPutNumberFromInputNode: number = 0;
//     let inputNodeNameWithMoreOutput: string = "";
//     const keys = Object.keys(inputMap[id].inputs);
//     let inputs: { id: string; value: string }[] = [];
//     const sessionStorageProcessData = sessionStorage.getItem("process");
//     let parsedDataProcess: any;
//     let connections: any = getCableStateConnections();
//     let connectionstoArray: any[] = Object.entries(connections);
//     // console.log("oooooooo wdwd wdwdhu yyyyyyyy iiiiiiiiiiiii - ", keys);
//     if (sessionStorageProcessData !== undefined && sessionStorageProcessData) {
//       parsedDataProcess = JSON.parse(sessionStorageProcessData);
//     }
//     if (parsedDataProcess) {
//       parsedDataProcess?.edges?.map((edge: any, indexEdge: any) => {
//         if (id === edge?.to) {
//           parsedDataProcess?.nodes?.map((node: any, indexNode: any) => {
//             if (edge?.from === node?.id && node?.data?.type === "Input") {
//               connectionstoArray?.map((connections: any[], indexConn: any) => {
//                 if (edge?.from === connections[0] && connections[1] > 1) {
//                   isInputFromAnyInputNodeWithMoreOutput = true;
//                   outPutNumberFromInputNode =
//                     node?.data?.props?.taskRatios?.length;
//                   inputNodeNameWithMoreOutput = node?.data?.props?.inputItem;
//                 }
//               });
//             }
//           });
//         }
//       });
//     }

//     keys.forEach((key: string) => {
//       const temp = inputMap[id].inputs[key]
//         .filter((item: any) => !item.disable)
//         .map((item: any) => ({ id: key, value: item.value }));

//       console.log("inputMap[id].input[key] ----- ", inputMap[id].inputs[key]);

//       const temp1 = inputMap[id].inputs[key].map((item: any) => {
//         return {
//           id: key,
//           value: item.value,
//         };
//       });
//       // console.log("inputs getting data   =====  , ", inputMap[id].inputs[key]);

//       // console.log("inputs", temp1);
//       inputs.push(...temp1);
//     });
//     let temp1Temp: any[] = [];
//     if (isInputFromAnyInputNodeWithMoreOutput) {
//       inputs?.map((input: { id: any; value: any }, index: any) => {
//         if (
//           isInputFromAnyInputNodeWithMoreOutput &&
//           inputNodeNameWithMoreOutput === input?.value
//         ) {
//           for (let index = 0; index < outPutNumberFromInputNode; index++) {
//             temp1Temp.push({
//               id: input?.id,
//               value: input?.value + " Output " + String(Number(index) + 1),
//             });
//           }
//           inputs = inputs.filter(function (item) {
//             return item !== input;
//           });
//         }
//       });
//       inputs = [...temp1Temp, ...inputs];
//     }
//     // console.log("oooooooo wdwd wdwdhu yyyyyyyy iiiiiiiiiiiii   ----   ", inputs)
//     return inputs;
//   }

//   return [{ id: "", value: "" }];
// };

const getFromNodeSelectedInputs = (
  nodeId: string,
  fromId: string,
): string[] => {
  const inputs = new Array<string>();

  inputMap[nodeId].inputs[fromId].forEach((item: Input) => {
    console.log("itemitemitem", item);
    if (item.disable) inputs.push(item.value);
  });

  return inputs;
};

export const removeFromMap = (id: string): void => {
  delete inputMap[id];
  // printInputMap();
};

export const removeSingleInputNode = (
  nodeId: string,
  inputNodeId: string,
  inputList?: string[],
): void => {
  const tempInputs = inputMap[nodeId];
  const fromNodeSelectedInputs = getFromNodeSelectedInputs(nodeId, inputNodeId);
  console.log(fromNodeSelectedInputs, "fromNodeSelectedInputs");
  if (inputList) {
    inputList.forEach((inputItem: string) => {
      if (fromNodeSelectedInputs.includes(inputItem)) {
        deselect(inputItem);
      }
    });
  }
  delete tempInputs.inputs[inputNodeId];
  inputMap[nodeId] = tempInputs;
  printInputMap();
};

export const removeInputsOnNoProps = (toId: string, fromId: string) => {
  delete inputMap[toId].inputs[fromId];
  // printInputMap();
};

export const removeTaskInputsOnEdgeRemove = (
  fromNodeId: string,
  toNodeId: string,
  toNode: NodeData,
): void => {
  const inputs = getInputsUsingFromId(fromNodeId, toNodeId);
  const inputsList = inputs.map((input) => input.value);
  const inputsListS = inputs.map((input) => input.id);

  const {
    inputOutputMap,
    concurrentInputParallel,
    lengthFactors,
  } = toNode.data.props;
  console.log(
    lengthFactors,
    "ppppuuuuuuuuuuuuuuu",
    inputs,
    "llllllllll",
    inputsList,
  );
  if (inputOutputMap !== undefined) {
    inputOutputMap.forEach((taskInput: any, index: any) => {
      if (inputsList.includes(taskInput.inputValues)) {
        // eslint-disable-next-line no-param-reassign
        inputOutputMap.splice(index, 1);
      }
    });
  } else if (
    // concurrentInputParallel !== undefined &&
    lengthFactors !== undefined
  ) {
    // concurrentInputParallel.forEach((taskInput: any, index: any) => {
    //   if (inputsList.includes(taskInput.inputValues)) {
    //     // eslint-disable-next-line no-param-reassign
    //     concurrentInputParallel.splice(index, 1);
    //   }
    // });
    lengthFactors.forEach((taskInput: any, index: any) => {
      if (inputsListS.includes(taskInput.inputValues)) {
        lengthFactors.splice(index, 1);
      }
    });
    console.log(lengthFactors, "lengthFactorslengthFactors");
  }

  // delete inputMap[toNodeId].inputs[fromNodeId];
};

export const getInputMapState = (): InputMap => {
  // deep copy
  return JSON.parse(JSON.stringify(inputMap));
};

export const restoreInputMap = (newInputMap: InputMap | undefined): void => {
  if (newInputMap) {
    inputMap = newInputMap;
  }
  // printInputMap();
};

export const getDeletedInputs = (id: string): string[] => {
  const keys = Object.keys(inputMap[id].inputs);
  const inputs: string[] = [];

  keys.forEach((key: string) => {
    const temp = inputMap[id].inputs[key]
      .filter((item: any) => item.disable)
      .map((item: any) => item.value);

    inputs.push(...temp);
  });
  return inputs;
};

export const extractInputs = (
  inputs: { id: string; value: string }[],
): string[] => {
  return inputs.map((input: any) => input.value);
};

export const getNumberOfInputs = (id: string): number => {
  return Object.keys(inputMap[id].inputs).length;
};

export const addToNodes = (id: string): void => {
  if (inputMap[id]) {
    inputMap[id].toNodes += 1;
  }
};

export const removeToNodes = (id: string): void => {
  if (inputMap[id]) {
    inputMap[id].toNodes -= 1;
  }
};
