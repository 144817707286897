import React, { FC, useState } from "react";
import {
  Form,
  Modal,
  Input,
  Button,
  Row,
  Col,
  InputNumber,
  message,
} from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons/lib";
import { useConnection } from "../../Services/ApplicationContext";
import "../modalPopUp.scss";
import { SERVICE_TYPE } from "../../constants/constants";

export interface EditRawMaterialAllocatedModalProps {
  visible: boolean;
  onClose: (val?: any) => void;
  type: "plus" | "minus";
  data?: any;
  // title: string;
}

const formItemLayout = {
  labelCol: {
    span: 10,
  },
};

const EditRawMaterialAvailableModal: FC<EditRawMaterialAllocatedModalProps> = (
  props: EditRawMaterialAllocatedModalProps,
) => {
  const { visible, onClose, type, data } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { put } = useConnection();

  const validateMessages = {
    required: "Required field!",
    types: {
      number: "Required field!",
    },
    number: {
      range:
        type === "plus"
          ? `Value should be greater than ${data?.available}`
          : `Value should be less than ${data?.available} and greater than 0`,
    },
  };

  const onConfirm = async (values: any) => {
    setLoading(true);
    let result;
    try {
      if (data.available === values.available) {
        message.open({
          type: "error",
          content: "Nothing has changed",
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
        setLoading(false);
        form.resetFields();
        onClose(type);
        return;
      }
     console.log('check reorder', data, values)
      if (type === "plus") {
        result = await put("system-management/resources/updateRawMaterialAvailableQty", {
          ...data,
          ...values,
          reorderQuantity: Number(data.reorderQuantity) ,
          totalAmount:
            Number(data.totalAmount) +
            (Number(values.available) - Number(data.available)),
        },
        undefined,
        SERVICE_TYPE.serviceType
        );
      } else {
        result = await put("system-management/resources/updateRawMaterialAvailableQty", {
          ...data,
          ...values,
          reorderQuantity: Number(data.reorderQuantity) ,
          totalAmount:
            Number(data.totalAmount) -
            (Number(data.available) - Number(values.available)),
        },
        undefined,
        SERVICE_TYPE.serviceType
        );
      }

      if (result.status === 200) {
        message.open({
          type: "success",
          content: result.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
        setLoading(false);
        form.resetFields();
        onClose(type);
      } else {
        message.open({
          type: "error",
          content: result.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
        onClose();
        setLoading(false);
      }
    } catch (error: any) {
      message.open({
        type: "error",
        content: error.message,
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
    }
  };

  return (
    <div>
      {/* <h1>Edit available </h1> */}
      <Modal
        title="Edit Available Count"
        centered
        visible={visible}
        width="40%"
        onCancel={() => {
          form.resetFields();
          onClose();
        }}
        footer={null}
        className="general-modal-popup"
        // bodyStyle={{ width: "fit-content" }}
      >
        <Form
          // eslint-disable-next-line react/jsx-props-no-spreading
          // {...formItemLayout}
          form={form}
          name="bobbins"
          hideRequiredMark={false}
          initialValues={data}
          onFinish={onConfirm}
          scrollToFirstError
          autoComplete="off"
          // style={{ justifyContent: "center" }}
          // className="ant-advanced-search-form"
          layout="vertical"
          validateMessages={validateMessages}
        >
          <Form.Item
            name="available"
            label="Available Count"
            className="display"
            rules={[
              {
                type: "number",
                min: type === "plus" ? data?.available : 0,
                max: type === "plus" ? Infinity : data?.available,
              },
              {
                required: true,
                message: "Required field!",
              },
            ]}
          >
            <InputNumber
              // type="number"
              style={{ width: "100%" }}
              placeholder="Enter the item"
              // min={type === "plus" ? data?.available : 0}
              // max={type === "plus" ? Infinity : data?.available}
            />
          </Form.Item>
          <Form.Item
            name="changeDescription"
            label="Description"
            rules={[
              {
                required: type === "minus",
                // message: "Required field!",
              },
              {
                whitespace: type === "minus",
                message: "Required field!",
              },
              {
                max: 255,
                message: "The maximum length of 255 characters is reached! ",
              },
            ]}
          >
            <Input.TextArea placeholder="Description" />
          </Form.Item>
          <Form.Item className="modal-button ant-form-item-control-input-content modal-field-button">
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              style={{ margin: "15px", borderRadius: "5px" }}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditRawMaterialAvailableModal;
