import React, { FC } from "react";
import { Button, Collapse, Row, Skeleton } from "antd";
import MainLayout from "../../components/MainLayout";
import {
  AccessControlItem,
  AccessControlTable,
} from "./AccessControlComponents";
import "./style.scss";
import { FeatureProp, useAC } from "./AccessControlContext";
import { AuthCategories, IAccessData } from "../../definetions/Authz";
import { CaretRightOutlined } from "@ant-design/icons";
import { IoPlaySharp } from "react-icons/io5";

const AuthorizationDashboard: FC = () => {
  const { roleList, allRoles, allFeatures, loading, saveRoleData } = useAC();
  const { Panel } = Collapse;

  const mapFeaturesForTable = (section: string): IAccessData[] => {
    if (allFeatures && allRoles && roleList) {
      // remove this section once the dashboard is designed line (22 to 35)
      if (section === "report") {
        const temp = allFeatures?.report?.slice(1);
        return temp.map((val) => {
          return {
            ...val,
            ...Object.fromEntries(
              roleList.map((rl: any) => [
                rl,
                allRoles[rl].features.findIndex((vl) => vl === val.id) > -1,
              ]),
            ),
          };
        });
      }
      return allFeatures[section].map((val) => {
        return {
          ...val,
          ...Object.fromEntries(
            roleList.map((rl: any) => [
              rl,
              allRoles[rl].features.findIndex((vl) => vl === val.id) > -1,
            ]),
          ),
        };
      });
    }

    return [];
  };

  const findFeatures = (val: string): string => {
    if (val === "bobbins") {
      return AuthCategories["admin" as keyof typeof AuthCategories];
    }
    return AuthCategories[val as keyof typeof AuthCategories];
  };

  const renderSections = (funcs: FeatureProp) => {
    return Object.keys(funcs)
      .filter((value) => value !== "only-admin")
      .map((item, index) => (
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => (
            <IoPlaySharp rotate={isActive ? 10 : 0} />
          )}
        >
          <Panel
            header={
              <div className="auth-section-name">{findFeatures(item)}</div>
            }
            key={index.toString()}
          >
            <div style={{ padding: "0px 30px 15px" }}>
              <AccessControlTable
                roleList={roleList}
                allFeatures={mapFeaturesForTable(item)}
                category={item}
              />
            </div>
          </Panel>
        </Collapse>
      ));
  };

  return (
    <MainLayout
      type="primary"
      OpenKey={["settings"]}
      selectedKey={["authorization"]}
    >
      {allFeatures && roleList.length > 0 && allRoles ? (
        <div className="usertable-container">
          <AccessControlItem
            description="Restrict Information By Plant"
            property="filterByPlant"
          />
          <Collapse defaultActiveKey={["0"]}>
            {renderSections(allFeatures)}
          </Collapse>
          <Row justify="end">
            <Button
              loading={loading}
              type="primary"
              style={{
                fontFamily: "Poppins",
                padding: "20px",
                marginTop: "20px",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={saveRoleData}
            >
              Save
            </Button>
          </Row>
        </div>
      ) : (
        <Skeleton active paragraph={{ rows: 10 }} round />
      )}
    </MainLayout>
  );
};
export default AuthorizationDashboard;
