import React, { FC } from "react";
import { Spin } from "antd";

const LoadingPage: FC = () => {
  return (
    <div className="App-header">
      <Spin spinning />
    </div>
  );
};
export default LoadingPage;
