import React, {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export interface Response<T = any> {
  statusCode: number;
  data: T;
  statusText: string;
  status?: number;
  headers: any;
  config?: AxiosRequestConfig;
  request?: any;
  message: string;
  response?: any;
}

export type Methods = "get" | "post" | "delete" | "put" | "patch";

export type ConnectionProps = {
  post<T = any, R = Response<T>>(
    path: string,
    data?: any,
    config?: AxiosRequestConfig,
    type?: EndpointType,
  ): Promise<R>;
  get<T = any, R = Response<T>>(
    path: string,
    // data?: any,
    config?: AxiosRequestConfig,
    type?: EndpointType,
  ): Promise<R>;
  delete<T = any, R = Response<T>>(
    path: string,
    config?: AxiosRequestConfig,
    type?: EndpointType,
  ): Promise<R>;
  put<T = any, R = Response<T>>(
    path: string,
    data?: any,
    config?: AxiosRequestConfig,
    type?: EndpointType,
  ): Promise<R>;
  patch<T = any, R = Response<T>>(
    path: string,
    data?: any,
    config?: AxiosRequestConfig,
    type?: EndpointType,
  ): Promise<R>;
  updateToken: (token?: string) => void;
  token?: string;
};
const ApplicationContext = React.createContext<{
  connection?: ConnectionProps;
}>({});

type ApplicationContextProviderProps = {
  serverSideUrl: string;
  serverlessSideUrl: string;
  serverlessLoadBalancer: string;
  children: ReactNode;
};

type EndpointType = "ServerSide" | "Serverless" | "LoadBalancer" |undefined | null | "";

export const ApplicationContextProvider: FC<ApplicationContextProviderProps> = (
  props: ApplicationContextProviderProps,
) => {
  const [token, setToken] = useState<string>();
  const { serverSideUrl, serverlessSideUrl, serverlessLoadBalancer , children } = props;

  useEffect(() => {
    const timer = setInterval(async () => {
      const newToken: any = await localStorage.getItem("token");
      if (token !== newToken) {
        setToken(newToken);
      }
    }, 3000);
    if (token) {
      clearTimeout(timer);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [token]);

  const send = useCallback(
    (
      method: Methods,
      path: string,
      data?: any,
      config?: AxiosRequestConfig,
    ) => {
      return new Promise((resolve, reject) => {
        const url = `${serverSideUrl}/${path}`;
        let headers: any;
        if (token) {
          headers = { authorization: `Bearer ${token.toString()}` };
        } else {
          localStorage.getItem("token");
          headers = {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          };
        }
        axios({
          method,
          url,
          data,
          headers,
          ...config,
        })
          .then((response: AxiosResponse) => {
            if (response.status) {
              if (response.status === 200) {
                resolve({
                  status: response.status,
                  data: response.data.data ?? response.data,
                  statusText: "SUCCESS",
                  message: response.data.message,
                });
              } else if (response.status === 201) {
                resolve({
                  status: response.status,
                  data: response.data.message,
                  statusText: "CREATED",
                  message: response.data.message
                });
              } else if (response.status === 422) {
                reject({
                  status: response.status,
                  data: response.data?.data,
                  statusText: "ERROR",
                  message: response.data.message,
                  errors: response.data?.errors,
                });
              }
            } else {
              reject({
                status: 500,
                statusText: "UNKNOWN",
                message: "Something went wrong",
              });
            }
          })
          .catch((e: any) => {
            if (e.response) {
              if (e.response.status) {
                if (e.response.data.message === "jwt expired") {
                  localStorage.removeItem("token");
                  window.location.reload();
                }

                reject({
                  status: e.response.status,
                  statusText: "ERROR",
                  errors: e.response.data?.errors,
                  message: e.response.data.message,
                });
              } else {
                reject({
                  statusText: "ERROR",
                  message: "Something went wrong",
                });
              }
            } else {
              reject({
                statusText: "ERROR",
                message: "Network error",
              });
            }
          });
      }) as any;
    },

    [token, serverSideUrl],
  );

  const sendServerless = useCallback(
    (
      method: Methods,
      path: string,
      data?: any,
      config?: AxiosRequestConfig,
    ) => {
      return new Promise((resolve, reject) => {
        const url = `${serverlessSideUrl}/${path}`;
        let headers: any;
        if (token) {
          headers = { authorization: `Bearer ${token.toString()}` };
        } else {
          localStorage.getItem("token");
          headers = {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          };
        }
        axios({
          method,
          url,
          data,
          headers,
          ...config,
        })
          .then((response: AxiosResponse) => {
            if (response.data.status || response.status) {
              if (response.data.status === 422 || response.status === 422) {
                reject({
                  status: response.status ?? response.data.status,
                  data: response.data.data ?? response.data,
                  statusText: "ERROR",
                  message: response.data.message,
                  errors: response.data?.errors,
                });
              } else if (response.data.status === 200 || response.status === 200) {
                console.log(response, "response");
                resolve({
                  status: response.data.status ?? response.status,
                  data: response.data.data ?? response.data,
                  statusText: "SUCCESS",
                  message: response.data.message ?? "SUCCESS",
                });
              } else if (response.data.status === 201 || response.status === 201) {
                console.log({
                  status: response.status ?? response.data.status,
                  data: response.data.message,
                  response: response.data ?? response.data.data,
                  statusText: "CREATED",
                  message: response.data.message ?? "CREATED",
                  dataList: response.data.data ?? response.data ,
                }, "response-sendServerless");    
                resolve({
                  status: response.status ?? response.data.status,
                  data: response.data.message,
                  response: response.data ?? response.data.data,
                  statusText: "CREATED",
                  message: response.data.message ?? "CREATED",
                  dataList: response.data.data ?? response.data ,
                });
              } 
            } else {
              reject({
                status: 500,
                statusText: "UNKNOWN",
                message: "Something went wrong",
              });
            }
          })
          .catch((e: any) => {
            if (e.response) {
              if (e.response.status) {
                if (e.response.data.message === "jwt expired") {
                  localStorage.removeItem("token");
                  window.location.reload();
                }

                reject({
                  status: e.response.status,
                  statusText: "ERROR",
                  errors: e.response.data?.errors,
                  message: e.response.data.message,
                });
              } else {
                reject({
                  statusText: "ERROR",
                  message: "Something went wrong",
                });
              }
            } else {
              reject({
                statusText: "ERROR",
                message: "Network error",
              });
            }
          });
      }) as any;
    },

    [token, serverlessSideUrl],
  );


  // const Get = useCallback(
  //   (path: string, config?: AxiosRequestConfig, type?: ) => {
  //     return sendServerless("get", path, undefined, config);
  // const sendLoadBalancer = useCallback(
  //   (
  //     method: Methods,
  //     path: string,
  //     data?: any,
  //     config?: AxiosRequestConfig,
  //   ) => {
  //     return new Promise((resolve, reject) => {
  //       const url = `${serverlessLoadBalancer}/${path}`;
  //       let headers: any;
  //       if (token) {
  //         headers = { authorization: `Bearer ${token.toString()}` };
  //       } else {
  //         localStorage.getItem("token");
  //         headers = {
  //           authorization: `Bearer ${localStorage.getItem("token")}`,
  //         };
  //       }
  //       axios({
  //         method,
  //         url,
  //         data,
  //         headers,
  //         ...config,
  //       })
  //         .then((response: AxiosResponse) => {
  //           console.log(response, "responseresponseresponseresponseresponse");
  //           if (response.status) {
  //             if (response.status === 200) {
  //               resolve({
  //                 status: response.status,
  //                 data: response.data.data ?? response.data,
  //                 statusText: "SUCCESS",
  //                 message: response.data.message,
  //               });
  //             } else if (response.status === 201) {
  //               resolve({
  //                 status: response.status,
  //                 data: response.data.message,
  //                 response: response.data,
  //                 statusText: "CREATED",
  //                 message: response.data.message,
  //                 dataList: response.data?.data,
  //               });
  //             } else if (response.status === 422) {
  //               reject({
  //                 status: response.status,
  //                 data: response.data?.data,
  //                 statusText: "ERROR",
  //                 message: response.data.message,
  //                 errors: response.data?.errors,
  //               });
  //             }
  //           } else {
  //             reject({
  //               status: 500,
  //               statusText: "UNKNOWN",
  //               message: "Something went wrong",
  //             });
  //           }
  //         })
  //         .catch((e: any) => {
  //           if (e.response) {
  //             if (e.response.status) {
  //               if (e.response.data.message === "jwt expired") {
  //                 localStorage.removeItem("token");
  //                 window.location.reload();
  //               }

  //               reject({
  //                 status: e.response.status,
  //                 statusText: "ERROR",
  //                 errors: e.response.data?.errors,
  //                 message: e.response.data.message,
  //               });
  //             } else {
  //               reject({
  //                 statusText: "ERROR",
  //                 message: "Something went wrong",
  //               });
  //             }
  //           } else {
  //             reject({
  //               statusText: "ERROR",
  //               message: "Network error",
  //             });
  //           }
  //         });
  //     }) as any;
  //   },

  //   [token, serverlessLoadBalancer],
  // );

  const sendLoadBalancer = useCallback(
    (
      method: Methods,
      path: string,
      data?: any,
      config?: AxiosRequestConfig,
    ) => {
      return new Promise((resolve, reject) => {
        const url = `${serverlessLoadBalancer}/${path}`;
        let headers: any;
        if (token) {
          headers = { authorization: `Bearer ${token.toString()}` };
        } else {
          localStorage.getItem("token");
          headers = {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          };
        }
        axios({
          method,
          url,
          data,
          headers,
          ...config,
        })
          .then((response: AxiosResponse) => {
            if (response.data.status || response.status) {
              if (response.data.status === 422 || response.status === 422) {
                reject({
                  status: response.status ?? response.data.status,
                  data: response.data.data ?? response.data,
                  statusText: "ERROR",
                  message: response.data.message,
                  errors: response.data?.errors,
                });
              } else if (response.data.status === 200 || response.status === 200) {
                console.log(response, "response");
                resolve({
                  status: response.data.status ?? response.status,
                  data: response.data.data ?? response.data,
                  statusText: "SUCCESS",
                  message: response.data.message ?? "SUCCESS",
                });
              } else if (response.data.status === 201 || response.status === 201) {
                console.log({
                  status: response.status ?? response.data.status,
                  data: response.data.message,
                  response: response.data ?? response.data.data,
                  statusText: "CREATED",
                  message: response.data.message ?? "CREATED",
                  dataList: response.data.data ?? response.data ,
                }, "response-sendServerless");    
                resolve({
                  status: response.status ?? response.data.status,
                  data: response.data.message,
                  response: response.data ?? response.data.data,
                  statusText: "CREATED",
                  message: response.data.message ?? "CREATED",
                  dataList: response.data.data ?? response.data ,
                });
              } 
            } else {
              reject({
                status: 500,
                statusText: "UNKNOWN",
                message: "Something went wrong",
              });
            }
          })
          .catch((e: any) => {
            if (e.response) {
              if (e.response.status) {
                if (e.response.data.message === "jwt expired") {
                  localStorage.removeItem("token");
                  window.location.reload();
                }

                reject({
                  status: e.response.status,
                  statusText: "ERROR",
                  errors: e.response.data?.errors,
                  message: e.response.data.message,
                });
              } else {
                reject({
                  statusText: "ERROR",
                  message: "Something went wrong",
                });
              }
            } else {
              reject({
                statusText: "ERROR",
                message: "Network error",
              });
            }
          });
      }) as any;
    },

    [token, serverlessLoadBalancer],
  );
  const post = useCallback(
    (
      path: string,
      data?: any,
      config?: AxiosRequestConfig,
      type?: EndpointType,
    ) => {
      if (
        type === undefined ||
        type === null ||
        !type ||
        type === "ServerSide"
      ) {
        return send("post", path, data, config);
      } else if(type == "LoadBalancer") {
        return sendLoadBalancer("post", path, data, config);
      } else {
        return sendServerless("post", path, data, config);
      }
    },
    [send, sendServerless,sendLoadBalancer],
  );
  const put = useCallback(
    (
      path: string,
      data?: any,
      config?: AxiosRequestConfig,
      type?: EndpointType,
    ) => {
      if (
        type === undefined ||
        type === null ||
        !type ||
        type === "ServerSide"
      ) {
        return send("put", path, data, config);
      } else if(type === "LoadBalancer") {
        return sendLoadBalancer("put", path, data, config);
      } {
        return sendServerless("put", path, data, config);
      }
    },
    [send, sendServerless,sendLoadBalancer],
  );
  const get = useCallback(
    (path: string, config?: AxiosRequestConfig, type?: EndpointType) => {
      if (
        type === undefined ||
        type === null ||
        !type ||
        type === "ServerSide"
      ) {
        return send("get", path, undefined, config);
      } else if(type === "LoadBalancer") {
        return sendLoadBalancer("get", path, config);
      } {
        return sendServerless("get", path, undefined, config);
      }
    },
    [send, sendServerless,sendLoadBalancer],
  );
  const patch = useCallback(
    (
      path: string,
      data?: any,
      config?: AxiosRequestConfig,
      type?: EndpointType,
    ) => {
      if (
        type === undefined ||
        type === null ||
        !type ||
        type === "ServerSide"
      ) {
        return send("patch", path, data, config);
      } else if(type === "LoadBalancer") {
        return sendLoadBalancer("patch", path, data, config);
      } {
        return sendServerless("patch", path, data, config);
      }
    },
    [send, sendServerless,sendLoadBalancer],
  );
  const del = useCallback(
    (path: string, config?: AxiosRequestConfig, type?: EndpointType) => {
      if (
        type === undefined ||
        type === null ||
        !type ||
        type === "ServerSide"
      ) {
        return send("delete", path, config);
      } else if(type === "LoadBalancer") {
        return sendLoadBalancer("delete", path, config);
      } {
        return sendServerless("delete", path, config);
      }
    },
    [send, sendServerless,sendLoadBalancer],
  );
  

  const updateToken = useCallback(async (tokenx?: string) => {
    if (tokenx) {
      localStorage.setItem("token", tokenx);
      setToken(tokenx);
    } else {
      localStorage.removeItem("token");
      setToken(undefined);
    }
  }, []);

  return (
    <ApplicationContext.Provider
      value={{
        connection: {
          post,
          put,
          get,
          patch,
          delete: del,
          updateToken,
          token
        },
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export default ApplicationContext;
export const useConnection = () => {
  const context = useContext(ApplicationContext);

  return context.connection!;
};
