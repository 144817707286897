import React, { FC, useContext, useEffect, useState } from "react";
import { message } from "antd";
import moment from "moment";
import { useConnection } from "../../Services/ApplicationContext";
import { Container } from "../../constants/constants";

export type NodeModalContextProps = {
  errorsFromNodeModal?: Array<any>;
  setErrorsFromNodeModal?: (val: any[]) => void;
};

export const NodeModalContext = React.createContext<{
  process?: NodeModalContextProps;
}>({});

export const NodeModalContextWrapper: FC = (props) => {
  const { get, post } = useConnection();

  const [current, setCurrent] = useState(0);
  const [prevOutputContainers, setPrevOutputContainers] = useState<any>([]);
  const [currentInputContainers, setCurrentInputContainers] = useState<{
    [id: number]: string[];
  }>({});

  const [errorsFromNodeModal, setErrorsFromNodeModal] = useState<any>([]);

  const checkContainerValidation = (): boolean => {
    const noOfMachinesInPrevTask = prevOutputContainers?.length;
    const validity: boolean[] = [...Array(noOfMachinesInPrevTask)].map(
      (x) => false,
    );
    const tempInputContainers = new Array<string>();

    Object.keys(currentInputContainers).forEach((key: any) => {
      if (currentInputContainers[key]) {
        currentInputContainers[key].forEach((value: string) => {
          tempInputContainers.push(value);
        });
      }
    });

    if (noOfMachinesInPrevTask) {
      for (let i = 0; i < noOfMachinesInPrevTask; i += 1) {
        if (prevOutputContainers?.length) {
          const containers = prevOutputContainers[i];
          containers.forEach((container: any) => {
            if (tempInputContainers.includes(container)) {
              validity[i] = true;
            }
          });
        }
      }
    }

    const validitySet = new Set(validity);

    if (validitySet.has(false)) {
      message.open({
        type: "error",
        content: `Input ${Container.NAME} doesn't support the machine in the previous task!`,
        duration: 8,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });

      return false;
    }

    return true;
  };

  return (
    <NodeModalContext.Provider
      value={{
        process: {
          errorsFromNodeModal,
          setErrorsFromNodeModal,
        },
      }}
    >
      {props.children}
    </NodeModalContext.Provider>
  );
};
export default NodeModalContext;
export const useProcess = () => {
  const context = useContext(NodeModalContext);

  return context?.process!;
};
