import React, { FC, useEffect, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  Select,
  Divider,
  Tag,
  message,
  Row,
  Col,
  Spin,
  Skeleton,
} from "antd";
import { useProject } from "./NewProjextContext";
import "../modalPopUp.scss";
import "./common.scss";
import { useConnection } from "../../Services/ApplicationContext";
import { Container, SERVICE_TYPE } from "../../constants/constants";

export interface ContainerSelectionModalProps {
  visible: boolean;
  onClose: (val?: any) => void;
  containerArray: any;
}

const ContainerViewModal: FC<ContainerSelectionModalProps> = (
  props: ContainerSelectionModalProps,
) => {
  const { visible, onClose, containerArray } = props;
  const { put, post, get } = useConnection();
  const [form] = Form.useForm();
  const [containerArrayData, setContainerArrayData] = useState([]);
  const [modalLoadingOne, setModalLoadingOne] = useState<boolean>(false);

  const getContainerNamesByIds = async (ids: any[]) => {
    try {
      const result = await post("system-management/resources/getResourcesByArraysOfId", {
        resourceByArrayOfId: [...ids],
      },
      undefined,
      SERVICE_TYPE.serviceType
      );
      if (result?.message === "success") {
        // console.log("getting id   ============== ", result);
        if (result?.data?.resourceNames) {
          return result;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error: any) {
      console.log("container name from id --- ", error);
      return null;
    }
  };

  useEffect(() => {
    console.log(containerArray,"containerArraycontainerArraycontainerArraycontainerArray")
    if (containerArray) {
      let tempContainerArray: any = [...containerArray];
      setModalLoadingOne(true);
      tempContainerArray?.map((machine: any, index: any) => {
        let inputContainerNames: any = [];
        let inputContainerArrayLength: number = machine?.inputContainer?.length;
        let sendingIdInputContainers: any[] = [];
        let lastArrEle: boolean = false;
        if (index + 1 === tempContainerArray?.length) {
          lastArrEle = true;
          console.log("in the last ARr ele temMachines");
        }
        console.log(
          "input container length ------------- ",
          inputContainerArrayLength,
        );
        machine?.inputContainer?.map((iCon: any, indexI: any) => {
          let sendingIConId: any;
          if (iCon === "N/A") {
            sendingIConId = iCon;
          } else {
            let sendingIdArr = iCon?.split("_");
            if (sendingIdArr?.length > 1) {
              sendingIConId = sendingIdArr[0];
            } else {
              sendingIConId = iCon;
            }
          }
          sendingIdInputContainers.push(sendingIConId);
        });
        getContainerNamesByIds(sendingIdInputContainers)
          .then((result: any) => {
            machine.inputContainer = result?.data?.resourceNames;
            console.log("result new api -----------> ", inputContainerNames);
          })
          .then((result: any) => {
            if (lastArrEle) {
              setModalLoadingOne(false);
            }
          });
        // machine.inputContainer = inputContainerNames;
      });
      console.log(
        "container view modal array --------- > ",
        tempContainerArray,
      );

      setContainerArrayData(tempContainerArray);
    }
  }, [containerArray]);

  return (
    <Modal
      centered
      width="80%"
      title={`${Container.NAME} Selection`}
      visible={visible}
      className="general-modal-popup"
      onCancel={() => {
        onClose(false);
        setContainerArrayData([]);
      }}
      footer={[
        <div className="modal-field-button">
          <Button
            type="primary"
            danger
            onClick={() => {
              onClose(false);
              setContainerArrayData([]);
            }}
          >
            Close
          </Button>
        </div>,
      ]}
    >
      <div
        style={{
          maxHeight: "70vh",
          overflowY: "auto",
          overflowX: "hidden",
          padding: 5,
          justifyContent: "center",
        }}
      >
        {modalLoadingOne ? (
          <Skeleton active paragraph={{ rows: 5 }} round />
        ) : (
          containerArrayData.map((field: any, index: any) => (
            <div key={field.taskId}>
              <Divider orientation="center" style={{ marginBottom: 10 }}>
                Machine :{field.machineName}
              </Divider>
              <div style={{ justifyContent: "center" }}>
                <Row justify="center">
                  <Col span={10}>
                    <div
                      style={{
                        // marginBottom: 10,
                        color: "rgba(255, 255, 255, 0.5)",
                        fontFamily: "Poppins",
                        fontSize: "15px",
                      }}
                    >
                      <span>Input {Container.NAME}s</span>
                    </div>
                  </Col>
                  <Col span={3}>
                    <div
                      style={{
                        // marginBottom: 10,
                        color: "rgba(255, 255, 255, 0.5)",
                        fontFamily: "Poppins",
                        fontSize: "15px",
                        display: "inline-block",
                        width: 250,
                      }}
                    >
                      {field?.inputContainer?.map((item: any) => (
                        <Tag
                          color="green"
                          style={{
                            fontFamily: "Poppins",
                            marginBottom: "15px",
                          }}
                        >
                          {item}
                        </Tag>
                      ))}
                    </div>
                  </Col>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    flex: "1",
                    margin: 10,
                    justifyContent: "center",
                  }}
                >
                  <Col span={10}>
                    <div
                      style={{
                        // marginBottom: 10,
                        color: "rgba(255, 255, 255, 0.5)",
                        fontFamily: "Poppins",
                        fontSize: "15px",
                      }}
                    >
                      <span>Output {Container.NAME}</span>
                    </div>
                  </Col>
                  <Col span={3}>
                    <div
                      style={{
                        // marginBottom: 10,
                        color: "rgba(255, 255, 255, 0.5)",
                        fontFamily: "Poppins",
                        fontSize: "15px",
                      }}
                    >
                      <span>{field.container}</span>
                    </div>
                  </Col>
                </Row>

                <Row
                  style={{
                    display: "flex",
                    flex: "1",
                    margin: 10,
                    justifyContent: "center",
                  }}
                >
                  <Col span={10}>
                    <div
                      style={{
                        // marginBottom: 10,
                        color: "rgba(255, 255, 255, 0.5)",
                        fontFamily: "Poppins",
                        fontSize: "15px",
                      }}
                    >
                      <span>
                        {field.taskGroup === "Cure" ||
                        field.taskGroup === "Annealing"
                          ? `Number of Input ${Container.NAME}s`
                          : `${Container.NAME} Cut Length`}
                      </span>
                    </div>
                  </Col>
                  <Col span={3}>
                    <div
                      style={{
                        // marginBottom: 10,
                        color: "rgba(255, 255, 255, 0.5)",
                        fontFamily: "Poppins",
                        fontSize: "15px",
                      }}
                    >
                      <span>
                        {field.cutLength}
                        {field.taskGroup === "Cure" ||
                        field.taskGroup === "Annealing"
                          ? undefined
                          : "m"}
                      </span>
                    </div>
                  </Col>
                </Row>

                <Row
                  style={{
                    display: "flex",
                    flex: "1",
                    margin: 10,
                    justifyContent: "center",
                  }}
                >
                  <Col span={10}>
                    <div
                      style={{
                        // marginBottom: 10,
                        color: "rgba(255, 255, 255, 0.5)",
                        fontFamily: "Poppins",
                        fontSize: "15px",
                      }}
                    >
                      <span>For scheduling</span>
                    </div>
                  </Col>
                  <Col span={3}>
                    <div
                      style={{
                        // marginBottom: 10,
                        color: "rgba(255, 255, 255, 0.5)",
                        fontFamily: "Poppins",
                        fontSize: "15px",
                      }}
                    >
                      <span>{field.consider ? "Consider" : "Not Consider"}</span>
                    </div>
                  </Col>
                </Row>

                <Row
                  style={{
                    display: "flex",
                    flex: "1",
                    margin: 10,
                    justifyContent: "center",
                  }}
                >
                  <Col span={10}>
                    <div
                      style={{
                        // marginBottom: 10,
                        color: "rgba(255, 255, 255, 0.5)",
                        fontFamily: "Poppins",
                        fontSize: "15px",
                      }}
                    >
                      <span>machine ID</span>
                    </div>
                  </Col>
                  <Col span={3}>
                    <div
                      style={{
                        // marginBottom: 10,
                        color: "rgba(255, 255, 255, 0.5)",
                        fontFamily: "Poppins",
                        fontSize: "15px",
                      }}
                    >
                      <span>{field.machineID}</span>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          ))
        )}
      </div>
    </Modal>
  );
};
export default ContainerViewModal;
