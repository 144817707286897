import { Card, Col, Descriptions, Form, Row, Skeleton, Table, Tag } from "antd";
import { DateTime } from "luxon";
import moment from "moment";
import React, { useState, useEffect, FC, useCallback } from "react";
import { IoRadioButtonOnSharp } from "react-icons/io5";
import { useConnection } from "../../Services/ApplicationContext";
import "./maintenance.scss";
import { SERVICE_TYPE } from "../../constants/constants";

const MaintenanceTable: FC = () => {
  let cTime = DateTime.now().setZone("Asia/Colombo").toMillis();
  const {post, delete:del, get } = useConnection();
  const [machineData, setMachineData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const getAllMachine = async () => {
    try {
      setLoading(true);
      let machineStatus: any = {};
      const responseJson = await get(
        `predictive-maintenance/getIotMachineDetails?timeStamp=${cTime}`,
        undefined,
        SERVICE_TYPE.serviceType
      );
      await post(
        "system-management/device/getMachineStatus",
        undefined,
        undefined,
        SERVICE_TYPE.serviceType
      ).then((result: { data: { list: { status: any; machineID: string | number; deviceName: any; deviceStatus: any; }[]; }; }) => {
        result.data.list.map(
          (value: {
            status: any;
            machineID: string | number;
            deviceName: any;
            deviceStatus: any;
          }) => {
            machineStatus[value.machineID] = {
              deviceName: value.deviceName,
              deviceStatus: value.deviceStatus,
              status: value?.status
            };
          },
        );
      });

      if ((responseJson.status == 200)) {
        let arrayData: {
          id: string;
          machineId: any;
          location: any;
          overallStatus: any;
          iotDevice: string;
          iotStatus: number;
          machineName: any;
          sensors: any;
          status:any;
        }[] = [];
        for (let key in responseJson.data) {
          arrayData = [
            {
              id: key,
              machineId: responseJson.data[key].machineId,
              location: responseJson.data[key].location,
              overallStatus: responseJson.data[key].overall.status,
              iotDevice: machineStatus[responseJson.data[key].machineId].deviceName,
              iotStatus: machineStatus[responseJson.data[key].machineId].deviceStatus,
              machineName: responseJson.data[key].machineName,
              sensors: responseJson.data[key].sensors,
              status: machineStatus[responseJson.data[key].machineId].status
            },
          ];
        }
        setMachineData(arrayData);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllMachine();
  }, []);

  const gridStyle: React.CSSProperties = {
    width: "85%",
    left: "5%",
    top: "40px",
  };

  return (
    <>
      <span className="maintenance-title">Predictive Maintenance</span>
      {!loading ? ( 
          machineData.map((data: any) => (
            <div >
              <a href={`/maintenance/sensor/${data.machineId}`}>
                <Card className="general-card" key={data.id} style={gridStyle} hoverable={true}>
                  <div >
                  <Form 
                   labelCol={{ span: 7 }}
                   wrapperCol={{ span: 8 }}                 
                  // layout="horizontal"
                  >          
                  <div className="card-align">
                      <Row className="form-row-header">
                        <Col span={18}>
                          <Form.Item label="Machine Name" className="label-text">
                          {data.machineName}   
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row className="form-row-header">
                        <Col span={18}>
                          <Form.Item
                            label="Machine Running Status"
                            className="label-text"
                          >
                            {
                              <Tag
                                color={
                                  data.status === 1
                                    ? "green"
                                    : data.status === 0
                                    ? "red"
                                    : "gold"
                                }
                                // key={onOff}
                                style={{
                                  fontFamily: "Poppins",
                                  display: "inline-flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginBottom: "5px",
                                  fontWeight: 600,
                                  backgroundColor:
                                    data.status === 1
                                      ? "#99F399"
                                      : data.status === 0
                                      ? "#FAA0A0"
                                      : "#FFE99A"
                                }}
                              >
                                <IoRadioButtonOnSharp
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginRight: "5px",
                                  }}
                                />
                                {data.status === 1
                                  ? "RUNNING"
                                  : data.status === 0
                                  ? "NOT RUNNING"
                                  : "UNKNOWN"}
                              </Tag>
                            }
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row className="form-row-header">
                        <Col span={18} >
                          <Form.Item label="IOT Device" className="label-text">
                            {data.iotDevice}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row className="form-row-header">
                        <Col span={18}>
                          <Form.Item label="Location" className="label-text">
                            {data.location
      .split(" ")
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")}s
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row className="form-row-header">
                        <Col span={18}>
                          <Form.Item
                            label="IOT Status"
                            className="label-text"
                          >
                            {
                              <Tag
                              color={
                                data.iotStatus === 1
                                  ? "green"
                                  : data.iotStatus === 0
                                  ? "red"
                                  : "gold"
                              }
                              style={{
                                fontFamily: "Poppins",
                                display: "inline-flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: "5px",
                                fontWeight: 600,
                                backgroundColor:
                                data.iotStatus === 1
                                    ? "#99F399"
                                    : data.iotStatus === 0
                                    ? "#FAA0A0"
                                    : "#FFE99A"
                              }}
                            >
                              <IoRadioButtonOnSharp
                                style={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginRight: "5px",
                                }}
                              />
                              {data.iotStatus === 1
                                ? "OK"
                                : data.iotStatus === 0
                                ? "ERROR"
                                : "UNKNOWN"}
                            </Tag>
                            }
                          </Form.Item>
                        </Col>
                      </Row>
                      <Form
                        colon={false}
                      >
                  <div className="cart-right">
                      <Row>
                        <Col style={{ width: "100%", textAlign: "center" }}>
                          <Form.Item
                          style={{fontWeight: 'bold', display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                          }}
                          ><div style={{color:'white', marginRight: "30px"}}>Sensor Status</div>
                          </Form.Item>
                        </Col>
                        </Row>
                        {data.sensors.map(
                          (sensor: {
                            sName: string;
                            sensorId: string | number;
                            status: number;
                          }) => (
                        <div>
                          <Row className="form-row-header">
                            <Col>
                              <Form.Item
                                label={<span className="label-text-1">{sensor?.sName}</span>} colon
                                className="label-text"
                              >
                                <Tag
                                  color={
                                    sensor?.status === 1
                                      ? "green"
                                      : sensor?.status === 0
                                      ? "red"
                                      : "gold"
                                  }
                                  style={{
                                    fontFamily: "Poppins",
                                    display: "inline-flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "5px",
                                    fontWeight: 600,
                                    backgroundColor:
                                    sensor?.status === 1
                                        ? "#99F399"
                                        : sensor?.status === 0
                                        ? "#FAA0A0"
                                        : "#FFE99A"
                                  }}
                                > 
                                  <IoRadioButtonOnSharp
                                style={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginRight: "5px",
                                }}
                              />
                                  {sensor?.status === 1
                                    ? "OK"
                                    : sensor?.status === 0
                                    ? "ERROR"
                                    : "UNKNOWN"}
                                </Tag>
                              </Form.Item>
                            </Col>
                          </Row>  
                            </div>
                          ),
                        )}
                        
                    </div>  
                      </Form>  
                      </div>      
                  </Form> 
                  </div> 
                </Card>
                <br />
              </a>
            </div>
          ))
      ):(
        <Skeleton active paragraph={{ rows: 10 }} round />
      )}
     
    </>
  );
};

export default MaintenanceTable;
