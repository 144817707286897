import React, { useEffect, useState, useRef } from "react";
import AverageTable from "./AverageTable";
import "./AverageOperationSection.scss";
import AverageRadioButton from "./AverageRadioButton";
import { Duration } from "luxon";

export let selectedValue: any;

export default function AverageOperationSection(props: any) {
  const { data, interval } = props;

  const mounted: any = useRef();

  const [activeTab, setActiveTab] = useState<string>("total");
  const [dataList, setDataList] = useState<any>([]);

  const columnsDay: any = [
    {
      title: "",
      width: 100,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Total",
      width: 40,
      dataIndex: "daily",
      key: "1",
      align: "center",
    },
    {
      title: (
        <>
          <span>Weekly Average</span>
          <br />
          <span>(for the past 7 days)</span>
        </>
      ),
      width: 40,
      dataIndex: "weeklyAvg",
      key: "2",
      align: "center",
    },
    {
      title: (
        <>
          <span>Monthly Average</span>
          <br />
          <span>(for the past 30 days)</span>
        </>
      ),
      width: 40,
      dataIndex: "monthlyAvg",
      key: "3",
      align: "center",
    },
    {
      title: (
        <>
          <span>Overall Average</span>
          <br />
          <span>(for the past 365 days)</span>
        </>
      ),
      width: 40,
      dataIndex: "overallAvg",
      key: "4",
      align: "center",
    },
  ];

  const columnsWeek: any = [
    {
      title: "",
      width: 100,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Total",
      width: 40,
      dataIndex: "weeklyAvg",
      key: "1",
      align: "center",
    },
    {
      title: (
        <>
          <span>Monthly Average</span>
          <br />
          <span>(for the past 30 days)</span>
        </>
      ),
      width: 40,
      dataIndex: "monthlyAvg",
      key: "3",
      align: "center",
    },
    {
      title: (
        <>
          <span>Overall Average</span>
          <br />
          <span>(for the past 365 days)</span>
        </>
      ),
      width: 40,
      dataIndex: "overallAvg",
      key: "4",
      align: "center",
    },
  ];

  const columnsMonth: any = [
    {
      title: "",
      width: 100,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Total",
      width: 40,
      dataIndex: "monthlyAvg",
      key: "1",
      align: "center",
    },
    {
      title: (
        <>
          <span>Overall Average</span>
          <br />
          <span>(for the past 365 days)</span>
        </>
      ),
      width: 40,
      dataIndex: "overallAvg",
      key: "4",
      align: "center",
    },
  ];

  const [columnX, setColumnX] = useState(columnsDay);

  const tableColumn = () => {
    if (interval === "P1D") {
      setColumnX(columnsDay);
    }
    if (interval === "P1W") {
      setColumnX(columnsWeek);
    }
    if (interval === "P1M") {
      setColumnX(columnsMonth);
    }
  };

  const dataValChange = (num: number) => {
    if (num === 1) {
      setActiveTab("total");
    }
    if (num === 2) {
      setActiveTab("weekday");
    }
    if (num === 3) {
      setActiveTab("holiday");
    }
  };

  useEffect(() => {
    if (!mounted.current) {
      tableColumn();
      mounted.current = true;
    }
  });

  // totalMin: number;
  // officeMin: number;
  // nonOfficeMin: number;
  // unknowMin: number;
  // idleMin: number;

  const rowList = [
    {
      name: "Running Time",
      key: "totalMin",
    },
    {
      name: "Running Time - Office Hours",
      key: "officeMin",
    },
    {
      name: "Running Time - Out of Office Hours",
      key: "nonOfficeMin",
    },
    {
      name: "Idle Time",
      key: "idleMin",
    },
    {
      name: "Unknown Time",
      key: "unknowMin",
    },
  ];

  const formatTime = (time: number) => {
    const hrs = Math.floor(time / 60);
    const minites = Math.round(time % 60);
    console.log("check-machine-status-0", time, `${hrs}h ${minites}m`);
    return `${hrs}h ${minites}m`;
  };

  const checkExistance = (el: any, activeTab: any, key: any, type: string) => {
    let factor = 1;
    if (interval == "P1W" && type != "weeklyAvg") {
      factor = 7;
    } else if (interval == "P1M" && type == "overallAvg") {
      factor = 30;
    }

    if (el && el[activeTab]) {
      console.log(
        "check-machine-status",
        el[activeTab][key],
        el,
        activeTab,
        key,
        factor,
      );
      return formatTime(el[activeTab][key] * factor);
    } else {
      return "-";
    }
  };

  const fillTable = () => {
    const array = [];
    for (const row of rowList) {
      array.push({
        name: row.name,
        daily: checkExistance(data?.daily, activeTab, row.key, "daily"),
        weeklyAvg: checkExistance(
          data?.weeklyAvg,
          activeTab,
          row.key,
          "weeklyAvg",
        ),
        monthlyAvg: checkExistance(
          data?.monthlyAvg,
          activeTab,
          row.key,
          "monthlyAvg",
        ),
        overallAvg: checkExistance(
          data?.overallAvg,
          activeTab,
          row.key,
          "overallAvg",
        ),
      });
    }
    setDataList(array);
  };

  useEffect(() => {
    if (activeTab) {
      fillTable();
    }
  }, [activeTab]);

  return (
    <div className="AverageTable-section">
      <div className="filter-section">
        <AverageRadioButton onIdChange={dataValChange} />
      </div>
      <div className="table-section">
        <AverageTable data={dataList} columns={columnX} />
      </div>
    </div>
  );
}
