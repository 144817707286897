import React, { FC, useState } from "react";
import { Form, Input, Button, Select, Space, Row, Divider, Col } from "antd";
import { SelectProps } from "antd/lib/select";
import { IoChevronDown } from "react-icons/io5";
import { useAuthZ } from "../../Services/AuthorizationContext";
import { Plant } from "../../definetions/Constatnts";
import { ItemProps } from "../../definetions/common";
import "./userSearchBar.scss";
import { useHistory } from "react-router";
import UserTable from "./UserManagementTable";

const { Option } = Select;
export interface ISearchTerm {
  term: string;
  value: string;
}
export interface IUserSearchBar {
  onSearch: (val: ISearchTerm) => void;
  locations: ItemProps[];
}
const UserSearchBar: FC<IUserSearchBar> = (props: IUserSearchBar) => {
  const [enableSearch, setEnableSearch] = useState(false);
  const [form] = Form.useForm();
  const { roles } = useAuthZ();
  const size = 8;
  const { onSearch, locations } = props;
  const onFormLayoutChange = (val: any) => {
    if (val.term) {
      form.resetFields(["value"]);
    }
  };
  const onSubmit = (val: any) => {
    setEnableSearch(false);
    console.log(val, "pppppp");
    onSearch(val);
  };
  const onClear = () => {
    form.resetFields();
    window.location.reload();
  };
  // const selectProps: SelectProps<any> = {
  //   mode: "multiple" as const,
  //   style: { minWidth: "200px", maxWidth: "500px" },
  //   options: locations,
  //   placeholder: `Select  ${Plant}!`,
  //   maxTagCount: "responsive" as any,
  // };
  return (
    <div className="general-table-container">
      <Form
        layout="inline"
        form={form}
        onFinish={onSubmit}
        initialValues={{ term: "fullName" }}
        onValuesChange={onFormLayoutChange}
        style={{ marginLeft: "50px" }}
      >
        <Form.Item
          name="term"
          label="Search by"
          style={{ marginRight: "40px" }}
        >
          <Select
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={() => {
              setEnableSearch(true);
            }}
            style={{ width: "200px" }}
            suffixIcon={<IoChevronDown size={15} />}
          >
            <Option key="fullName" value="fullName">
              Full Name
            </Option>
            <Option key="email" value="email">
              Email
            </Option>
            <Option key="clientProfileType" value="clientProfileType">
              User Type
            </Option>
            <Option key="contactNumber" value="contactNumber">
              Contact Number
            </Option>
            <Option key="locationIDs" value="locationIDs">
              Location
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Required field!" }]}
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.term !== currentValues.term
          }
        >
          {({ getFieldValue }) => {
            if (getFieldValue("term") === "clientProfileType") {
              return (
                <Form.Item
                  label="Search value"
                  name="value"
                  // style={{ alignItems: "center" }}
                  rules={[{ required: true, message: "Required field!" }]}
                >
                  <Select
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                    onChange={() => {
                      setEnableSearch(true);
                    }}
                    placeholder="Select Type"
                    style={{ width: "200px" }}
                  >
                    {roles.map((value, index) => (
                      <Option key={index.toString()} value={value}>
                        {value}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            }
            if (getFieldValue("term") === "locationIDs") {
              return (
                <Form.Item
                  label="Search value"
                  style={{ alignItems: "center" }}
                  name="value"
                  rules={[{ required: true, message: "Required field!" }]}
                >
                  <Select
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                    placeholder="Select Plant"
                    mode="multiple"
                    style={{ minWidth: "200px", maxWidth: "500px" }}
                    maxTagCount="responsive"
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={() => {
                      setEnableSearch(true);
                    }}
                    //    options={locations1}
                  >
                    {locations.map((item, index) => (
                      <Option value={item.value} key={index.toString()}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            }
            return (
              <Form.Item
                label="Search value"
                name="value"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "*Please input a search value",
                  },
                ]}
              >
                <Input
                  // onPressEnter={onSubmit}
                  type="values"
                  onChange={() => {
                    setEnableSearch(true);
                  }}
                  placeholder="Enter Search Value"
                />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item>
          <Button
            className="user-search-btn"
            type="primary"
            onClick={() => {
              onClear();
            }}
          >
            Clear
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            disabled={!enableSearch}
            htmlType="submit"
            className="user-search-btn"
            type="primary"
          >
            Search
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UserSearchBar;
