import React, { FC, useEffect, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  Select,
  Divider,
  Tag,
  message,
  Spin,
  Skeleton,
} from "antd";
import { useProject } from "./NewProjextContext";
import "../modalPopUp.scss";
import "./common.scss";
import "./containerSelection.scss";
import { useConnection } from "../../Services/ApplicationContext";
import { Container } from "../../constants/constants";

export interface ContainerSelectionModalProps {
  visible: boolean;
  disable: boolean;
  nodeData: any;
  onClose: (val?: any) => void;
  inputID: any;
}

const InputCutLengthSelectionModal: FC<ContainerSelectionModalProps> = (
  props: ContainerSelectionModalProps,
) => {

  const { visible, onClose, nodeData, disable, inputID } = props;
  const [formTwo] = Form.useForm();
  const { setInputCutLengthArray, inputCutLengthArray } = useProject();
  const [loading, setLoading] = useState(false);
  const { Option } = Select;
  const [modalLoading, setModalLoading] = useState<boolean>(false);

  useEffect(() => {
    console.log(
      inputCutLengthArray, props,
      "nodeData===================>>>>>>",
      nodeData,
    );
    setLoading(true);
    const exist = inputCutLengthArray.find(
      (v: any) =>
        v.inputId === inputID &&
        v.inputValueId.toString() === nodeData.inputItemId,
    );
    formTwo.setFieldsValue({
      orderCutLength: exist !== undefined ? exist.cutLength : null,
    });
    setLoading(false);
  }, [nodeData]);

  const onSubmit = (val: any) => {
    const existingInput = inputCutLengthArray.find(
      (item: any) =>
        item.inputId === inputID &&
        item.inputValueId.toString() === nodeData.inputItemId,
    );
    const sendingValue = {
      inputValueId: nodeData.inputItemId,
      inputValue: nodeData.inputItem,
      cutLength: val.orderCutLength,
      inputId: inputID,
    };
    if (existingInput) {
      const index = inputCutLengthArray.indexOf(existingInput);
      inputCutLengthArray[index] = sendingValue;
      console.log(index, "--------------edit--------------");
    } else {
      setInputCutLengthArray((v: any) => [...v, sendingValue]);
      console.log(sendingValue, "containerArrayList");
    }
    console.log(
      inputCutLengthArray,
      "===============",
      inputID,
      "==============",
    );
  };

  const formValidation = (rule:any, value:any, callback:any) => {
    const regex = /^[0-9]*$/g;

    if (value && value.charAt(0) === ' ') {
      callback('Required field!');
    } else if (!value || value === undefined || value === ""){
      callback('Required field!')
    } else if(value && (parseInt(value) === 0 || parseInt(value) < 0)){
      callback("Cut Length can't be less than or equal to zero!")
    } else if(!regex.test(value)){
      callback("Cut Length should be an integer value!")
    } else {
      callback();
    }
  };  

  return (
    <Modal
      centered
      width="40%"
      title={`Input cut length Selection`}
      visible={visible}
      className="general-modal-popup"
      onCancel={() => {
        onClose();
        formTwo.resetFields();
      }}
      footer={[
        <div className="modal-field-button">
          <Button
            type="primary"
            danger
            onClick={()=>{
              onClose();
              formTwo.resetFields();
            }}
          >
            Close
          </Button>
          <Button
            type="primary"
            onClick={() => {
              formTwo.submit();
            }}
          >
            Done
          </Button>
        </div>,
      ]}
    >
      <div
        style={{
          maxHeight: "70vh",
          overflowY: "auto",
          overflowX: "hidden",
          padding: 5,
        }}
      >
        <div>
          <Divider orientation="center">{nodeData.inputItem}</Divider>
          {loading ? (
            <Skeleton active paragraph={{ rows: 5 }} round />
          ) : (
            <Form
              layout="horizontal"
              form={formTwo}
              name="task"
              onFinish={(values: any) => {
                console.log(values);
                onSubmit(values);
                onClose();
              }}
              scrollToFirstError
              autoComplete="off"
            >
              <Form.Item
                label="Cut Length"
                name="orderCutLength"
                rules={[
                  // {
                  //   pattern: new RegExp(/^[0-9]*$/g),
                  //   message: "Cut Length should be an integer value!",
                  // },
                  // {
                  //   // validator: async (rule, value) => {
                  //   //   if (value <= 0) {
                  //   //     throw new Error(
                  //   //       "Cut Length can't be null or less or equal to zero!",
                  //   //     );
                  //   //   }
                  //   //   if (
                  //   //     String(value).trim() === "" ||
                  //   //     value === null ||
                  //   //     value === undefined
                  //   //   ) {
                  //   //     throw new Error("Cut Length can't be null or less or equal to zero!",);
                  //   //   }
                  //   // },
                  //   required: true,
                  //   message: "Required Field!"
                  // },
                  {
                    validator: formValidation
                  }
                ]}
              >
                <Input disabled={disable} placeholder="Ex: 12" />
              </Form.Item>
            </Form>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default InputCutLengthSelectionModal;
