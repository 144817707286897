/* eslint-disable no-param-reassign */
import React, { FC, useEffect, useState } from "react";
import { Button, Form, Input, Modal, message, Select } from "antd";
import { useConnection } from "../../Services/ApplicationContext";
import "../modalPopUp.scss";
import { Container, SERVICE_TYPE } from "../../constants/constants";

const formItemLayout = {
  labelCol: {
    span: 6,
  },
};
export interface BobbiModalProps {
  visible: boolean;
  onClose: (val?: any) => void;
  type: "Add" | "Edit";
  data?: any;
  title: string;
  onUpdate: () => void;
}
const BobbinModal: FC<BobbiModalProps> = (props: BobbiModalProps) => {
  const [form] = Form.useForm();
  const { visible, onClose, type, data, title, onUpdate } = props;
  const [loading, setLoading] = useState(false);
  const [containerItemType, setContainerItemType] = useState("");

  const { post, put } = useConnection();

  // if (typeof data?.totalAmount) {
  //   alert(typeof data?.totalAmount);
  // }

  const onConfirm = async (values: any) => {
    setLoading(true);
    console.log("add item values : ", values);
    const bName = values.resourceName;
    const fBName = bName
      .split(" ")
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    values.resourceName = fBName.trim();
    console.log(values, fBName);
    let sendingValues;
    try {
      let result;
      // sendingValues = {
      //   materialType: (values?.materialType).trim(),
      //   resourceName: (values?.resourceName).trim(),
      //   totalAmount: Number(String(values?.totalAmount).trim()),
      // };
      // const totalAmount = Number(values.allocated) + Number(values.available);

      if (type === "Add") {
        result = await post(
          "system-management/resources/createResource",
          {
            materialType: (values?.materialType).trim(),
            resourceName: (values?.resourceName).trim(),
            totalAmount: values?.totalAmount
              ? parseInt(String(values?.totalAmount).trim())
              : null,
          },
          undefined,
          SERVICE_TYPE.serviceType
        );
        console.log("result bobbin", result);
        if (result.statusText === "SUCCESS") {
          message.open({
            type: "success",
            content: `Success! ${Container.NAME} added successfully.`,
            duration: 2,
            style: { textAlign: "right", marginRight: 15, marginTop: 10 },
          });
          onUpdate();
          form.resetFields();
          onClose(type);
        }
      } else {
        result = await put(
          "system-management/resources/updateResource",
          {
            id: data.id,
            materialType: (values?.materialType).trim(),
            resourceName: (values?.resourceName).trim(),
            totalAmount: values?.totalAmount
              ? parseInt(String(values?.totalAmount).trim())
              : null,
          },
          undefined,
          SERVICE_TYPE.serviceType
        );
        if (result.statusText === "SUCCESS") {
          message.open({
            type: "success",
            content: `Success! ${Container.NAME} updated successfully.`,
            duration: 2,
            style: { textAlign: "right", marginRight: 15, marginTop: 10 },
          });
          onUpdate();
          form.resetFields();
          onClose(type);
        }
      }
      setLoading(false);
    } catch (e: any) {
      message.open({
        type: "error",
        content: e.message,
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
      form.resetFields();
      setLoading(false);
    }
  };

  const onCancel = () => {
    form.resetFields();
    onClose("Add");
  };

  const handleTypeChanger = (val: any) => {
    console.log("type value : ", val);
    setContainerItemType(String(val));
  };

  const showTotalOnEdit = () => {
    // return (
    //   if()
    // )
    if (data?.materialType !== "coil" && containerItemType !== "coil") {
      return (
        <Form.Item
          name="totalAmount"
          label="Total"
          className="display"
          rules={[
            {
              required: true,
              message: "",
            },
            {
              validator: async (rule, value) => {
                if (
                  String(value).trim() === "" ||
                  value === null ||
                  value === undefined
                ) {
                  throw new Error("Required field!");
                } else if (String(value).includes(".")) {
                  throw new Error("Invalid value!");
                } else if (String(Number(String(value).trim())) === "NaN") {
                  throw new Error("Invalid value!");
                } else if (value < 0) {
                  throw new Error("Invalid value!");
                }
              },
            },
          ]}
        >
          <Input placeholder="Enter total count" />
        </Form.Item>
      );
    }
    if (containerItemType === "drum" || containerItemType === "bobbin") {
      return (
        <Form.Item
          name="totalAmount"
          label="Total"
          className="display"
          rules={[
            {
              validator: async (rule, value) => {
                if (
                  String(value).trim() === "" ||
                  value === null ||
                  value === undefined
                ) {
                  throw new Error("Required field!");
                } else if (String(value).includes(".")) {
                  throw new Error("Invalid value!");
                } else if (String(Number(String(value).trim())) === "NaN") {
                  throw new Error("Invalid value!");
                } else if (value < 0) {
                  throw new Error("Invalid value!");
                }
              },
            },
            {
              required: true,
              message: "",
            },
          ]}
        >
          <Input placeholder="Enter total count" />
        </Form.Item>
      );
    }
  };

  // console.log(form.getFieldValue(materialType));

  useEffect(() => {
    console.log("data for edit modal => ", data);
  }, []);

  return (
    <div>
      <Modal
        title={`${type} Item`}
        centered
        visible={visible}
        width="50%"
        footer={null}
        onCancel={onCancel}
        className="general-modal-popup"
      >
        <Form
          // eslint-disable-next-line react/jsx-props-no-spreading
          // {...formItemLayout}
          form={form}
          name="bobbins"
          hideRequiredMark={false}
          initialValues={data}
          onFinish={onConfirm}
          scrollToFirstError
          autoComplete="off"
          style={{ justifyContent: "center" }}
          className="ant-advanced-search-form"
          layout="vertical"
        >
          <Form.Item
            name="resourceName"
            label="Name"
            className="display"
            rules={[
              {
                whitespace: true,
                required: true,
                message: "Required field!",
              },
            ]}
          >
            <Input
              placeholder={`Enter the ${Container.NAME} name`}
              disabled={type === "Edit"}
            />
          </Form.Item>

          <Form.Item
            name="materialType"
            label="Type"
            className="display"
            rules={[
              {
                required: true,
                message: "Required field!",
              },
            ]}
          >
            <Select
              onChange={handleTypeChanger}
              placeholder={`Select the ${Container.NAME} type`}
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Select.Option value="bobbin">Bobbin</Select.Option>
              <Select.Option value="drum">Drum</Select.Option>
              <Select.Option value="coil">Coil</Select.Option>
            </Select>
          </Form.Item>

          {/* <Form.Item
            name="allocated"
            label="Allocated"
            className="display"
            rules={[
              {
                whitespace: true,
                required: true,
                message: "Required field!",
              },
            ]}
          >
            <Input disabled />
          </Form.Item> */}
          {showTotalOnEdit()}

          <Form.Item
            className="modal-field-button"
            style={{ marginTop: "30px" }}
          >
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              style={{ marginRight: "15px" }}
            >
              {`${
                type === "Add"
                  ? `Add ${Container.NAME}`
                  : `Edit ${Container.NAME}`
              }`}
            </Button>
            <Button
              loading={loading}
              danger
              type="primary"
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
export default BobbinModal;
