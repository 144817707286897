import React, { FC } from "react";
import "./Styles.scss";
import LocationTable from "./LocationTable";
import MainLayout from "../../components/MainLayout";
import { Plant, Plants } from "../../definetions/Constatnts";

const Locations: FC = () => {
  return (
    <MainLayout
      type="primary"
      // title={`Manage ${Plants}`}
      OpenKey={["device"]}
      selectedKey={["locations"]}
    >
      <div className="addLocations-form-container">
        <LocationTable />
      </div>
    </MainLayout>
  );
};

export default Locations;
