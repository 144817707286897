/* eslint-disable no-param-reassign */
import React, { FC, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Tooltip,
} from "antd";
import sha1 from "sha1";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { SelectProps } from "antd/lib/select";
import { DateTime } from "luxon";
import { ClientDetails } from "../../definetions/UserModel";
import { useAuthZ } from "../../Services/AuthorizationContext";
import { useConnection } from "../../Services/ApplicationContext";
import { Plant, Plants } from "../../definetions/Constatnts";
import { ItemProps } from "../../definetions/common";
import "../modalPopUp.scss";
import "../selectMultiple.scss";
import { SERVICE_TYPE } from "../../constants/constants";

const { Option } = Select;

export interface AddUserModalProps {
  visible: boolean;
  onClose: (val?: ClientDetails) => void;
  locations: ItemProps[];
}
const AddUserModal: FC<AddUserModalProps> = (props: AddUserModalProps) => {
  const { visible, onClose, locations } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState<string>();
  const [allPlantsEnabled, setAllPlantsEnabled] = useState<boolean>(false);
  const { post } = useConnection();
  const { roles } = useAuthZ();
  const [form] = Form.useForm();

  const onSubmit = async (values: any): Promise<void> => {
    setLoading(true);
    console.log(values);
    const fName = values.fullName;
    const fFName = fName
      .split(" ")
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    values.fullName = fFName.trim();
    console.log(values, fFName);
    const entry = values;
    try {
      if (allPlantsEnabled || entry.clientProfileType === "Administrator") {
        entry.locationIDs = [];
      }
      entry.password = sha1(`${values.email}${values.password}`);
      delete entry.confirm;
      const result = await post(
        "settings/client/registration",
        entry,
        undefined,
        SERVICE_TYPE.serviceType
      );
      if (result.status === 201) {
        message.open({
          type: "success",
          content: result.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
        entry.lastLogin = DateTime.now().valueOf();
        onClose(entry);
      } else {
        message.open({
          type: "warning",
          content: result.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
        onClose();
      }
      setLoading(false);
    } catch (e: any) {
      message.open({
        type: "error",
        content: e.message,
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
      setLoading(false);
      onClose();
    }
  };

  const selectProps: SelectProps<any> = {
    mode: "multiple" as const,
    style: { width: "100%" },
    options: locations,
  };

  const onRoleChange = (vl: string) => {
    setSelectedRole(vl);
    setAllPlantsEnabled(vl === "Administrator");
    if (vl === "Administrator") {
      form.setFieldsValue({ locationIDs: [] });
    }
  };

  const onAllPlantsSelect = (val: any) => {
    setAllPlantsEnabled(!allPlantsEnabled);
    if (val.target.checked === true) {
      form.setFieldsValue({ locationIDs: [] });
    }
  };
  return (
    <Modal
      title="Add User"
      centered
      visible={visible}
      onCancel={() => onClose()}
      width="80%"
      className="general-modal-popup"
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        name="register"
        onFinish={onSubmit}
        scrollToFirstError
        autoComplete="off"
      >
        <Form.Item
          name="fullName"
          label={
            <span>
              Full Name&nbsp;
              <Tooltip title="This name will be displayed">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          rules={[
            {
              required: true,
              message: "Required Field!",
              whitespace: true,
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (
                  getFieldValue("fullName")?.trim() &&
                  !getFieldValue("fullName")
                    ?.trim()
                    .match(/^[a-zA-Z][a-zA-Z\. ]*$/)
                ) {
                  return Promise.reject(
                    "Full name should contain letters only!",
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input spellCheck="false"  placeholder="Enter the Full Name "  autoComplete="new-password" />
        </Form.Item>
        <Form.Item
          name="contactNumber"
          label="Contact Number"
          rules={[
            {
              required: true,
              message: "Required Field!",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (
                  getFieldValue("contactNumber") &&
                  getFieldValue("contactNumber").match(/^[ ]*$/)
                ) {
                  return Promise.reject("Please input your Contact Number!");
                }
                if (
                  getFieldValue("contactNumber") &&
                  getFieldValue("contactNumber")?.trim().match(/[\D]/)
                ) {
                  return Promise.reject("Please input a valid Contact Number!");
                }
                if (
                  getFieldValue("contactNumber") &&
                  !getFieldValue("contactNumber")
                    ?.trim()
                    .match(/^\d{10}$/)
                ) {
                  return Promise.reject(
                    "Contact Number should consist of 10 digits!",
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            style={{
              width: "100%",
            }}
            spellCheck="false"
            placeholder="Enter the Contact Number "
            autoComplete="new-password"
          />
        </Form.Item>
        <Form.Item
          label="Select the access level"
          name="clientProfileType"
          rules={[
            {
              required: true,
              message: "Required Field!",
            },
          ]}
        >
          <Select
            onChange={onRoleChange}
            placeholder="Select the Access Level"
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {roles.map((value, index) => (
              <Option key={index.toString()} value={value}>
                {value}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Row gutter={8}>
          <Col flex="auto">
            <div className="general-select-multiple">
              <Form.Item
                label="Select the plant"
                name="locationIDs"
                rules={[
                  {
                    required:
                      selectedRole !== "Administrator" && !allPlantsEnabled,
                    message: `Required Field!`,
                  },
                ]}
              >
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  showSearch
                  placeholder="Select the plant"
                  mode="multiple"
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={
                    selectedRole === "Administrator" || allPlantsEnabled
                  }
                >
                  {locations.map((value, index) => (
                    <Option key={index.toString()} value={value.value}>
                      {value.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col
            flex="150px"
            style={{
              alignItems: "center",
              display: "flex",
              marginLeft: "10px",
            }}
          >
            <Form.Item>
              <Checkbox
                value={allPlantsEnabled}
                checked={allPlantsEnabled}
                disabled={selectedRole === "Administrator"}
                onChange={onAllPlantsSelect}
              >
                {`Select all ${Plants}`}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (
                  getFieldValue("email") &&
                  getFieldValue("email").match(/^[ ]*$/)
                ) {
                  return Promise.reject("Please input a valid E-mail!");
                }
                if (
                  getFieldValue("email") &&
                  !getFieldValue("email")
                    ?.trim()
                    .match(
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    )
                ) {
                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise.reject("Please input a valid E-mail!");
                }
                return Promise.resolve();
              },
            }),
            {
              required: true,
              message: "Required Field!",
            },
          ]}
        >
          <Input spellCheck="false" placeholder="Enter the Email" autoComplete="new-password"/>
        </Form.Item>
        <Form.Item className="modal-field-button">
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            style={{ marginRight: "15px" }}
          >
            Add User
          </Button>
          <Button
            loading={loading}
            danger
            type="primary"
            onClick={() => onClose()}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default AddUserModal;
