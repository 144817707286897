import React, { FC, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Select,
} from "antd";
import moment from "moment";
import { useConnection } from "../../Services/ApplicationContext";
import { IProjectTemplate } from "../../definetions/ProjectModal";
import { ItemProps } from "../../definetions/common";
import { useProject } from "../Projects/NewProjextContext";

const { Option } = Select;
export interface NewProjectModalProps {
  visible: boolean;
  onClose: () => void;
  templates: ItemProps[];
}
const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
};
const NewProjectModal: FC<NewProjectModalProps> = (
  props: NewProjectModalProps,
) => {
  const { visible, onClose, templates } = props;
  const { post } = useConnection();
  const [form] = Form.useForm();
  const priority = Array.from(Array(6).keys());
  priority.shift();
  const [loading, setLoading] = useState(false);

  const saveProject = async (values: any) => {
    setLoading(true);
    const entry = values;
    entry.scheduledStartDate = moment(values.scheduledStartDate)
      .format("YYYY-MM-DD")
      .toString();
    entry.deadline = moment(values.deadline).format("YYYY-MM-DD").toString();
    console.log("projec  dataa ", entry);
    try {
      const result = await post("production/createProject", values);
      if (result.status === 200) {
        message.open({
          type: "success",
          content: result.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      } else {
        message.open({
          type: "warning",
          content: result.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      }
      setLoading(false);
    } catch (e: any) {
      message.open({
        type: "error",
        content: e.message,
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
    }
  };

  return (
    <>
      <Modal
        title="Add New Project"
        centered
        visible={visible}
        onCancel={() => onClose()}
        width="80%"
        footer={null}
      >
        <Form
          form={form}
          name="newProject"
          hideRequiredMark={false}
          initialValues={{
            priority: 3,
            outOfOfficeTime: true,
            alterCurrent: false,
          }}
          onFinish={saveProject}
          scrollToFirstError
          autoComplete="off"
          style={{ justifyContent: "center" }}
          className="ant-advanced-search-form"
          layout="vertical"
        >
          <Form.Item
            label="Select Production Template"
            name="processId"
            rules={[
              {
                required: true,
                message: "Please select a project template!",
              },
            ]}
          >
            <Select
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {templates.map((value, index) => (
                <Option key={index.toString()} value={value.value}>
                  {value.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Select Quantity"
            name="quantity"
            rules={[
              {
                required: true,
                message: "Please select a quantity!",
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Select Scheduled Start Date"
            name="scheduledStartDate"
            rules={[
              {
                required: true,
                message: "Please select a scheduled start date!",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Select Deadline"
            name="deadline"
            rules={[
              {
                required: true,
                message: "Please select a deadline!",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Select Priority"
            name="priority"
            rules={[
              {
                required: true,
                message: "Please select a priority!",
              },
            ]}
          >
            <Select
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {priority.map((value, index) => (
                <Option key={index.toString()} value={value}>
                  {value}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Alter Current Schedules"
            name="alter"
            rules={[
              {
                required: true,
                message: "Please select an schedule alteration criteria",
              },
            ]}
          >
            <Radio.Group>
              <Radio style={radioStyle} value={1}>
                Do not alter
              </Radio>
              <Radio style={radioStyle} value={2}>
                Alter lower priority schedules
              </Radio>
              <Radio style={radioStyle} value={3}>
                Alter equal priority schedules
              </Radio>
              <Radio style={radioStyle} value={3}>
                Alter equal and lower priority schedules
              </Radio>
              <Radio style={radioStyle} value={3}>
                Alter all schedules
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            // label="Alter Current production stages"
            valuePropName="checked"
            name="alterCurrent"
          >
            <Checkbox>Alter Current production stages</Checkbox>
          </Form.Item>
          <Form.Item
            // label="Alter Current production stages"
            valuePropName="checked"
            name="outOfOfficeTime"
          >
            <Checkbox>Use out of office time</Checkbox>
          </Form.Item>
          <Form.Item className="modal-button ant-form-item-control-input-content">
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              style={{ margin: "15px" }}
            >
              Add
            </Button>
            <Button
              loading={loading}
              danger
              type="primary"
              style={{ margin: "15px" }}
              onClick={() => onClose()}
            >
              Cancel
            </Button>
          </Form.Item>
          <Divider type="horizontal" />
        </Form>
      </Modal>
    </>
  );
};
export default NewProjectModal;
