import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import "./AverageTable.scss";

function AverageTable(props: any) {
  const propData = props.data;
  const columnData = props.columns;

  return (
    <div className="average-table-view">
      <Table columns={columnData} dataSource={propData} pagination={false} />
    </div>
  );
}

export default AverageTable;
