import React, { FC, useState } from "react";
import { Radio } from "antd";
import "./AverageRadioButton.scss";

function AverageRadioButton(props: any) {
  const [value, setValue] = useState(1);

  const onChange = (e: any) => {
    setValue(e.target.value);
    props.onIdChange(e.target.value);
  };
  return (
    <Radio.Group
      onChange={onChange}
      value={value}
      className="radio-group-style"
      size="large"
    >
      <Radio value={1}>All</Radio>
      <Radio value={2}>Week Day</Radio>
      <Radio value={3}>Holiday</Radio>
    </Radio.Group>
  );
}

export default AverageRadioButton;
