import { Dropdown, Layout, Menu, Avatar, Switch, Empty } from "antd";
import React, { FC, useState, useEffect } from "react";
import { BellFilled, CaretDownFilled, UserOutlined } from "@ant-design/icons";
import { IoNotificationsOutline, IoSettingsOutline } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { useConnection } from "../../Services/ApplicationContext";
import { useUser } from "../../Services/UserContext";
import "./NotificationDropdownHeader.scss";
import { useNotification } from "../../Services/NotificationContext";

export interface NoNotificationsProps {
}

const NoNotifications: FC<NoNotificationsProps> = (
  props: NoNotificationsProps,
) => {
  const {
    readToggleClickedCount,
    setReadToggleClickedCount,
    loadItem,
    setLoadItem,
    showUnread,
    setShowUnread
  } = useNotification();

  const onChangeSwitch = (checked: boolean) => {
    console.log(`switch to ${checked}`);
    setShowUnread(checked)
  };

  return (
    <div className="no-notification">
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Notifications"/>
    </div>
  );
};
export default NoNotifications;
