import React, { useState, useEffect, FC, useCallback, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Table, Popconfirm, Space, Button, message, Skeleton } from "antd";
import { TablePaginationConfig } from "antd/es/table/Table";
import "./userTable.scss";
import "../tableStyles.scss";
import "../popConfirm.scss";
import {
  IoCreateOutline,
  IoPersonAddSharp,
  IoPersonRemove,
  IoSettingsOutline,
  IoWarning,
} from "react-icons/io5";
import { useConnection } from "../../Services/ApplicationContext";
import { ClientDetails } from "../../definetions/UserModel";
import EditUserModal from "./EditUserModal";
import { Actions, Authorizer } from "../../Services/AuthorizationContext";
import AddUserModal from "./AddUserModal";
import UserSearchBar, { ISearchTerm } from "./UserSearchBar";
import { ILocation } from "../../definetions/MachineModel";
import { Plant } from "../../definetions/Constatnts";
import { PlusOutlined } from "@ant-design/icons";
import { ItemProps } from "../../definetions/common";
import { DateTime } from "luxon/src/luxon";
import { SERVICE_TYPE } from "../../constants/constants";

export interface IClientDetailRow extends ClientDetails {
  key: number;
}

interface Pagination {
  currentPage: number;
  pageSize: number;
}

const UserTable: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState<ClientDetails[]>([]);
  const [selectedRow, setSelectedRow] = useState<IClientDetailRow>();
  const [addUserModalVisible, setAddUserModalVisible] = useState<boolean>(
    false,
  );
  const [editingKey, setEditingKey] = useState("");
  const [mappedUserData, setMappedUserData] = useState<IClientDetailRow[]>([]);
  const [loading, setLoading] = useState(false);
  const [skeletonLoading, setSkeletonLoading] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [searchTerms, setSearchTerms] = useState<ISearchTerm>();
  const [locations, setLocations] = useState<ILocation[]>([]);
  const { delete: del, post, get } = useConnection();

  const [sortOrder, setSortOrder] = useState<string>("");
  const [sortField, setSortField] = useState<string>("");

  const initialValue = {
    currentPage: 1,
    pageSize: 10,
  };
  const [pagination, setPagination] = useState<Pagination>(initialValue);

  const locationOptions: ItemProps[] | any[] = useMemo(() => {
    if (locations.length > 0) {
      return locations.map((val) => ({
        value: val.locationID,
        label: val.locationName,
      }));
    }
    return [];
  }, [locations]);

  const mapMachineData = (clients: ClientDetails[]): ClientDetails[] => {
    return clients.map((val) => {
      const locationNames: string[] = [];
      if (val.locationIDs !== undefined) {
        if (val.locationIDs.length > 0) {
          for (let i = 0; i < val.locationIDs?.length; i += 1) {
            if (val.locationIDs[i] !== undefined) {
              const x = val.locationIDs[i];
              const temp = locations.find(
                (value) => value.locationID === Number(x),
              );
              if (temp) {
                locationNames.push(temp.locationName as string);
              }
            }
          }
        } else {
          locationNames.push("All(*)");
        }
      }
      return {
        ...val,
        locationName: locationNames.join(", "),
      };
    });
  };

  const mapData = (): IClientDetailRow[] => {
    return data.map((vl: ClientDetails, index: number) => ({
      ...vl,
      key: index,
    }));
  };

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      const result: any = await post(
        "settings/client/client_details",
        {
          page: pagination.currentPage - 1,
          size: pagination.pageSize,
          searchTerms,
          order: sortOrder !== "" ? sortOrder : undefined,
          orderBy: sortField !== "" ? sortField : undefined,
        },
        undefined,
        SERVICE_TYPE.serviceType,
      );
      if (result.statusText === "SUCCESS") {
        setSkeletonLoading(true);
        const list: ClientDetails[] = mapMachineData(
          result.data.clientDetailsList,
        );
        setData(list); // TODO  implement
        console.log("list", list);
        setTotal(result.data.total);
        const tempMappedUserData = mapData();
        setMappedUserData(tempMappedUserData);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
    }
  }, [post, pagination, searchTerms, locations, sortOrder, sortField]);

  const fetchLocations = async () => {
    try {
      const results: any = await post(
        "system-management/location/getLocationsList",
        {
          size: 1000,
          page: 0,
        },
        undefined,
        SERVICE_TYPE.serviceType
      );
      console.log('location data 1', results);
      setLocations(results.data.list);
    } catch (e) {
      console.log("error getting locations ", e);
    }
  };

  const handleTableChange = (nav: any, sorter: any) => {
    if (sorter.order === "ascend") {
      setSortOrder("asc");
    } else if (sorter.order === "descend") {
      setSortOrder("desc");
    } else if (sorter.order === undefined) {
      setSortOrder("");
    }
    if (sorter.columnKey !== undefined) {
      setSortField(sorter.columnKey);
    } else {
      setSortField("");
    }
    setPagination({ currentPage: nav.current, pageSize: nav.pageSize });
  };

  useEffect(() => {
    fetchLocations();
    // console.log("location = ", location);
  }, []);

  useEffect(() => {
    if (locations.length > 0) {
      getData();
    }
  }, [pagination, searchTerms, locations, sortOrder, sortField]);

  const edit = (record: any) => {
    setSelectedRow(record);
    setEditingKey(record.key);
  };

  const updateUser = (val: IClientDetailRow) => {
    let userList = data;
    userList = userList.map((user: ClientDetails) =>
      user.email === val.email ? val : user,
    );
    setData(userList);
  };

  const addUser = (val: ClientDetails) => {
    const userList = data;
    userList.unshift(val);
    setData(userList);
    setTotal((prev) => prev + 1);
    setPage(0);
  };

  const deleteUser = (val: ClientDetails) => {
    let userList = data;
    userList = userList.filter(
      (user: ClientDetails) => user.email !== val.email,
    );
    setData(userList);
    setTotal((prev) => prev - 1);
    // getData();
  };

  const Delete = async (record: ClientDetails) => {
    setLoading(true);
    try {
      const result = await del(
        `settings/client/delete/${record.email}`,
        undefined,
        SERVICE_TYPE.serviceType
      );
      if (result.statusText === "SUCCESS") {
        setPage(0);
        message.open({
          type: "success",
          content: result.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });

        deleteUser(record);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
      sorter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Contact Number",
      dataIndex: "contactNumber",
      key: "contactNumber",
      // width: "10%",
    },
    {
      title: "User Type",
      dataIndex: "clientProfileType",
      key: "clientProfileType",
      // width: "10%",
    },
    {
      title: Plant,
      dataIndex: "locationName",
      key: "locationName",
      width: "30%",
    },
    {
      title: "Last Login",
      key: "userLastLogin",
      sorter: true,
      width: "30%",
      render: (_: any, record: any) => {
        if (record.lastLogin !== "") {
          return DateTime.fromMillis(Number(record.lastLogin)).toFormat(
            "dd:MM:yyyy,  hh:mm a",
          );
        }
        return "-";
      },
    },
    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      // width: "10%",
      render: (_: any, record: ClientDetails) => {
        return (
          <Space size="middle">
            <Authorizer
              data={{
                feature: "35",
                action: Actions.HIDE,
              }}
            >
              {/* <a aria-disabled={editingKey !== ""} onClick={() => edit(record)}> */}
              {/* <EditFilled /> */}
              <Button
                htmlType="submit"
                aria-disabled={editingKey !== ""}
                onClick={() => edit(record)}
                className="user-edit-btn"
                type="primary"
              >
                <IoCreateOutline size={22} />
              </Button>
              {/* </a> */}
            </Authorizer>
            <Authorizer
              data={{
                feature: "1",
                action: Actions.HIDE,
              }}
            >
              <Popconfirm
                title={`Are you sure to delete ${record.fullName}?`}
                placement="topLeft"
                onConfirm={() => Delete(record)}
                okText="Yes"
                icon={<IoWarning size={30} color="#eec335" />}
                okButtonProps={{ danger: true }}
                cancelText="No"
                cancelButtonProps={{ type: "primary" }}
              >
                <Button
                  htmlType="submit"
                  className="user-delete-btn"
                  type="primary"
                >
                  {/* Delete */}
                  <IoPersonRemove size={20} />
                </Button>
              </Popconfirm>
            </Authorizer>
          </Space>
        );
      },
    },
  ];

  const onModalClose = (val?: IClientDetailRow) => {
    if (val) {
      // setTimeout(getData, 1000);
      updateUser(val);
      // history.push(location.pathname);
      getData();
    }
    setSelectedRow(undefined);
    // history.push(location.pathname);
  };

  const onAddUserModalClose = (val?: ClientDetails) => {
    if (val) {
      // getData();
      addUser(val);
    }
    setAddUserModalVisible(false);
  };

  useEffect(() => {
    const tempMappedUserData = mapData();
    setMappedUserData(tempMappedUserData);
  }, [data]);

  const onSearch = (val: ISearchTerm) => {
    setSearchTerms(val);
    setPage(0);
  };

  useEffect(() => {
    if (addUserModalVisible === true || selectedRow !== undefined) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [addUserModalVisible, selectedRow]);

  return (
    <div className="userManagementContainer">
      {selectedRow !== undefined && (
        <EditUserModal
          data={selectedRow}
          visible={selectedRow !== undefined}
          onClose={onModalClose}
          locations={locationOptions}
        />
      )}
      {addUserModalVisible && (
        <AddUserModal
          visible={addUserModalVisible}
          onClose={onAddUserModalClose}
          locations={locationOptions}
        />
      )}
      {skeletonLoading ? (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              paddingTop: "20px",
              paddingBottom: "20px",
              position: "sticky",
              top: "0",
              backgroundColor: "#1b1b1b",
              zIndex: 20,
            }}
          >
            <Authorizer
              data={{
                feature: "0",
                action: Actions.HIDE,
              }}
            >
              <Button
                type="primary"
                onClick={() => setAddUserModalVisible(true)}
              >
                <PlusOutlined
                  style={{ fontSize: "17px", fontWeight: "bold" }}
                />
                Add User
              </Button>
              {/* <Divider type="vertical" className="searchDivider" /> */}
            </Authorizer>
            <UserSearchBar onSearch={onSearch} locations={locationOptions} />
          </div>
          <div style={{ marginTop: "10px" }} className="general-table">
            <Table
              loading={loading}
              dataSource={data}
              columns={columns}
              rowClassName="editable-row"
              pagination={{
                current: pagination.currentPage,
                pageSize: pagination.pageSize,
                total,
                showQuickJumper: true,
                showSizeChanger: true,
              }}
              onChange={(val: any, filter: any, sorter: any) =>
                handleTableChange(val, sorter)
              }
            />
          </div>
        </div>
      ) : (
        <Skeleton active paragraph={{ rows: 10 }} round />
      )}
    </div>
  );
};
export default UserTable;
