import React, { FC, useEffect, useState } from "react";
import { Button, Form, Input, Modal, message, Select, Radio, Spin } from "antd";
import { useConnection } from "../../Services/ApplicationContext";
import "../modalPopUp.scss";
import CalComponent from "../../components/CalComponent/CalComponent";
import { DateTime } from "luxon";

import "./bobbinManagement.scss";

export interface BobbinManagementCalProps {
  usageEvents: any;
  availableEvents: any;
  loading: boolean;
  onNavigate: (newDate: any) => void;
}
const BobbinModal: FC<BobbinManagementCalProps> = (
  props: BobbinManagementCalProps,
) => {
  const { usageEvents, availableEvents, loading, onNavigate } = props;
  const [events, setEvents] = useState([]);

  const change: any = (e: any) => {
    if (e.target.value === "availability") setEvents(availableEvents);
    else setEvents(usageEvents);
  };

  const radioStyle = {
    // display: "block",
    height: "30px",
    lineHeight: "30px",
    paddingRight: "20px",
    color: "white",
  };

  useEffect(() => {
    if (!loading) {
      setEvents(availableEvents);
    }
  }, [loading]);

  return (
    <div>
      {loading ? (
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 580,
            minHeight: 580,
          }}
        >
          <Spin />
        </div>
      ) : (
        <div className={loading ? "loading-show" : "loading-hide"}>
          <Radio.Group onChange={change} defaultValue="availability">
            <Radio style={radioStyle} value="availability">
              Availability
            </Radio>
            <Radio style={radioStyle} value="Usage">
              Usage
            </Radio>
          </Radio.Group>
          <CalComponent allEvents={events} onNavigate={onNavigate} />
        </div>
      )}
    </div>
  );
};
export default BobbinModal;
