import React, { FC, useEffect, useRef, useState } from "react";
import { Button, DatePicker, Input, Modal, Tag } from "antd";
import { TweenOneGroup } from "rc-tween-one";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons/lib";
import { DatePickerProps } from "antd/es/date-picker";
import useProjectSchedule from "./ScheduleContext";

type HolidayProps = {
  onClose: (val?: string[]) => void;
  visible: boolean;
  holidays: string[];
};
const HolidayModal: FC<HolidayProps> = (props: HolidayProps) => {
  const { visible, onClose } = props;
  const [inputVisible, setInputVisible] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const input = useRef<DatePickerProps>(null);

  const { holidays: hdays } = useProjectSchedule();
  const [holidays, setHolidays] = useState<string[]>([]);

  useEffect(() => {
    setHolidays(hdays);
  }, [hdays]);

  const handleClose = (removedTag: string) => {
    const tags = holidays.filter((tag) => tag !== removedTag);
    setHolidays(tags);
  };

  const showInput = () => {
    setInputVisible(!inputVisible);
  };

  const handleInputConfirm = () => {
    if (inputValue && holidays.indexOf(inputValue) === -1) {
      const temp = [...holidays, inputValue];
      setHolidays(temp);
    }
    setInputValue("");
  };

  const saveInputRef = (inputX: any) => {
    // input.current = inputx;
  };

  const forMap = (tag: string) => {
    const CustomTag = (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#8994B042",
          padding: "5px 10px",
        }}
      >
        {tag}{" "}
        <CloseOutlined
          style={{
            fontSize: "10px",
            marginTop: "3px",
          }}
          onClick={() => {
            handleClose(tag);
          }}
        />
      </div>
    );
    return (
      <span
        key={tag}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "30%",
        }}
      >
        {CustomTag}
      </span>
    );
  };
  const onChangeDate = (val: any, dateString: string) => {
    if (dateString && holidays.indexOf(dateString) === -1) {
      const temp = [dateString, ...holidays ];
      // temp.unshift(dateString);
      setHolidays(temp);
    }
    // setInputVisible(false);
    setInputValue("");
  };
  
  const HolidayTags = holidays.map(forMap);

  const saveAndExit = () => {
    onClose(holidays);
  };

  const modalClose = () => {
    if (inputVisible === true) {
      setInputVisible(false);
    }
    onClose();
  };

  return (
    <>
      <Modal
        visible={visible}
        okButtonProps={{ title: "Save" }}
        onOk={saveAndExit}
        onCancel={() => modalClose()}
      >
        <h1>Manage Holidays</h1>
        <div
          style={{
            width: "80%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Tag onClick={showInput} className="site-tag-plus">
            <PlusOutlined /> New Holiday
          </Tag>
          {inputVisible && (
            <div
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <DatePicker
                autoFocus
                onBlur={handleInputConfirm}
                size="small"
                format="YYYY-MM-DD"
                onChange={onChangeDate}
                // value={inputValue}
                ref={saveInputRef}
                style={{ width: 148 }}
              />
            </div>
          )}

        </div>
        <div style={{ marginBottom: 16, marginTop: 20 }}>
          <TweenOneGroup
            enter={{
              scale: 0.8,
              opacity: 0.4,
              type: "from",
              duration: 100,
              onComplete: (e) => {
                // e.target.style = "";
              },
            }}
            leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
            appear={false}
          >
            {HolidayTags}
          </TweenOneGroup>
        </div>
      </Modal>
    </>
  );
};
export interface ManageHolidaysProps {
  onClose: (val?: string[]) => void;
  holidays: string[];
}
const ManageHolidays: FC<ManageHolidaysProps> = (
  props: ManageHolidaysProps,
) => {
  const [visible, setVisible] = useState<boolean>(false);
  const { onClose, holidays } = props;
  const onCloseHM = (val?: string[]) => {
    if (val && val.length > 0) {
      onClose(val);
    }
    setVisible(false);
  };
  return (
    <div className="mng-holi-container">
      <HolidayModal visible={visible} onClose={onCloseHM} holidays={holidays} />
      <Button
        type="primary"
        className="add-device-btn"
        onClick={() => setVisible(true)}
      >
        Manage Holidays
      </Button>
    </div>
  );
};
export default ManageHolidays;
