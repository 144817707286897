/* eslint-disable no-restricted-syntax */
import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from "react";
import { message } from "antd";
import { ChildrenProp } from "../../definetions/common";
import { useConnection } from "../../Services/ApplicationContext";
import { useAuthZ } from "../../Services/AuthorizationContext";
import { SERVICE_TYPE } from "../../constants/constants";

export type FeatureItemProp = {
  parent?: string;
  id: string;
  description: string;
  childList?: string[];
};
export type FeatureProp = {
  [key: string]: FeatureItemProp[];
};
export type RoleProp = {
  [key: string]: {
    features: string[];
    filterByPlant: boolean;
  };
};
export interface IAccessControl {
  updateRoles: (
    role: string,
    feature: FeatureItemProp,
    check: boolean,
    category: string,
  ) => void;
  updateRoleFilterCriteria: (
    property: RolePropertyProps,
    value: string[],
  ) => void;
  saveRoleData: () => void;
  allRoles?: RoleProp;
  allFeatures?: FeatureProp;
  roleList: string[];
  loading: boolean;
}
export type RolePropertyProps = "filterByPlant";
export type AccessControlContextProps = { current?: IAccessControl };
export const AccessControlContext = createContext<AccessControlContextProps>(
  {},
);

export const AccessControlContextProvider: FC<ChildrenProp> = ({
  children,
}: ChildrenProp) => {
  const { patch, get } = useConnection();
  const { getRolePermissions } = useAuthZ();
  const [roleList, setRoleList] = useState<string[]>([]);
  const [allFeatures, setAllFeatures] = useState<FeatureProp>();
  const [allRoles, setAllRoles] = useState<RoleProp>();
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = async () => {
    try {
      const result = await get(
        "settings/authorization/getAccessControlList",
        undefined,
        SERVICE_TYPE.serviceType
      );
      if (result.status === 200) {
        setAllFeatures(result.data.features);
        setAllRoles(result.data.roles);
        setRoleList(Object.keys(result.data.roles));
      }
    } catch (e) {
      console.log("Exception in getting access data");
    }
  };
  const updateRoles = (
    role: string,
    feature: FeatureItemProp,
    check: boolean,
    category: string,
  ) => {
    const temp = { ...allRoles };
    let { features } = temp[role];
    const fIndex = features.findIndex((vl) => vl === feature.id);
    const currentFtr = { ...allFeatures };
    if (feature.parent) {
      const x = currentFtr[category].find((vv) => vv.id === feature.parent);
      if (x?.childList && x.childList.length > 0) {
        if (
          fIndex === -1 &&
          check &&
          x.childList
            .filter((c) => c !== feature.id)
            .every((vl) => features.includes(vl))
        ) {
          features.push(feature.parent);
          // currentFtr[category]
        } else {
          const pIndex = features.findIndex((f) => f === feature.parent);
          if (pIndex > -1) {
            features.splice(pIndex, 1);
          }
        }
      }
    }
    if (feature.childList && feature.childList.length > 0) {
      if (check && fIndex === -1) {
        features.push(feature.id);
        for (let i = 0; i < feature.childList.length; i += 1) {
          features.push(feature.childList[i]);
        }
      } else {
        features = features.filter((vl) => vl !== feature.id);
        for (let i = 0; i < feature.childList.length; i += 1) {
          // @ts-ignore
          features = features.filter((vl) => vl !== feature.childList[i]);
        }
      }
    } else if (check && fIndex === -1) {
      features.push(feature.id);
    } else {
      features = features.filter((vl) => vl !== feature.id);
    }

    temp[role].features = features;
    setAllRoles(temp);
  };
  const updateRoleFilterCriteria = (
    property: RolePropertyProps,
    value: string[],
  ) => {
    const temp = { ...allRoles };
    for (const item of roleList) {
      temp[item][property] = value.findIndex((val) => val === item) > -1;
    }
  };
  const saveRoleData = async () => {
    setLoading(true);
    const temp = { ...allRoles };
    const entry = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const item of Object.keys(temp)) {
      entry.push({ role: item, ...temp[item] });
    }
    try {
      console.log(entry, "mmmmmmmmmmmmmmmmmmmmmm");
      const result = await patch(
        "settings/authorization/update-all-roles",
        entry,
        undefined,
        SERVICE_TYPE.serviceType,
      );
      setLoading(false);
      if (result.status === 200) {
        getRolePermissions();
        message.open({
          type: "success",
          content: result.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      } else {
        message.open({
          type: "error",
          content: result.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      }
    } catch (e) {
      setLoading(false);
      message.open({
        type: "error",
        content: e.message,
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <AccessControlContext.Provider
      value={{
        current: {
          allFeatures,
          allRoles,
          roleList,
          loading,
          updateRoles,
          updateRoleFilterCriteria,
          saveRoleData,
        },
      }}
    >
      {children}
    </AccessControlContext.Provider>
  );
};

export const useAC = () => {
  return useContext<AccessControlContextProps>(AccessControlContext).current!;
};
