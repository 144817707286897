import React, { FC, useEffect, useState } from "react";
import { Button, message, Popconfirm, Skeleton, Space, Table } from "antd";
import {
  DeleteFilled,
  EditFilled,
  PlusOutlined, 
  SelectOutlined,
} from "@ant-design/icons/lib";
import { useHistory } from "react-router";
import MainLayout from "../../components/MainLayout";
import NewProjectModal from "./NewPojectModal";
import { useConnection } from "../../Services/ApplicationContext";
import { INewProject, IProjectTemplate } from "../../definetions/ProjectModal";
import { ItemProps } from "../../definetions/common";

const NewProject: FC = () => {
  const { get } = useConnection();
  const history = useHistory();
  const [showNewProjectModal, setShowNewProjectModal] = useState<boolean>(
    false,
  );
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState<ItemProps[]>([]);
  const [data, setData] = useState<INewProject[]>([]);
  const fetchProjects = async () => {
    try {
      const proj = await get("production/getAllProjects");
      const proc = await get("production/getAllProcesses");

      const list = proj.data.map((val: INewProject) => ({
        ...val,
        processName: proc.data.find(
          (vl: IProjectTemplate) => vl.id === val.processId,
        )?.processName,
      }));
      setData(list);
      setTemplates(
        proc.data.map((val: IProjectTemplate) => ({
          label: val.processName,
          value: val.id,
        })),
      );
    } catch (e) {
      message.open({
        type: "error",
        content: e.message,
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
    }
  };
  useEffect(() => {
    fetchProjects();
  }, []);
  const columns: any = [
    { title: "Id", dataIndex: "id", key: "id" },
    {
      title: "Template",
      dataIndex: "processName",
      key: "processName",
    },
    {
      title: "Action",
      dataIndex: "operation",
      width: "120px",
      fixed: "right",
      render: (_: any, record: any) => {
        return (
          <Space size="middle">
            <a
            // onClick={() => edit(record)}
            >
              <EditFilled />
            </a>
            <Popconfirm
              title="Are you sure to delete this project?"
              // onConfirm={() => Delete(record)}
              okText="Yes"
              okButtonProps={{ danger: true }}
              cancelText="No"
              cancelButtonProps={{ type: "primary" }}
            >
              <a>
                <DeleteFilled />
              </a>
            </Popconfirm>
            <a onClick={() => history.push(`/project/tentative-timelines/${record.id}`)}>
              <SelectOutlined />
            </a>
          </Space>
        );
      },
    },
  ];
  return (
    <MainLayout type="primary" title="New Project">
      {showNewProjectModal && (
        <NewProjectModal
          visible={showNewProjectModal}
          onClose={() => setShowNewProjectModal(false)}
          templates={templates}
        />
      )}
      <div className="addLocations-form-container">
        {data.length > 0 ? (
          <>
            <Button
              type="primary"
              className="add-device-btn"
              onClick={() => setShowNewProjectModal(true)}
            >
              <PlusOutlined style={{ fontSize: "17px", fontWeight: "bold" }} />
              Add Project
            </Button>
            <Table
              className="components-table-demo-nested"
              columns={columns}
              dataSource={data}
              loading={loading}
            />
          </>
        ) : (
          <Skeleton active paragraph={{ rows: 10 }} round />
        )}
      </div>
    </MainLayout>
  );
};
export default NewProject;
