import React, { FC } from "react";

const UnAuthorized: FC = () => {
  return (
    <div className="App-header">
      <div className="txt404">404</div>
      <img
        src={require("./noPermission.png")}
        style={{ margin: "30px" }}
        height={463}
        width={400}
      />
      <h1 className="txt-title-auth">Access Denied</h1>
      <div className="txt-desc-auth">
        Your account (email) doesn't have permission to access this page.
      </div>
    </div>
  );
};
export default UnAuthorized;
