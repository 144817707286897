export interface IProjectTemplate {
  processName: string;
  id: string;
}
export interface NewProjectBase {
  id: string;
}
export interface CreateNewProjectRequest {
  processId: string;
  quantity: number;
  deadline: string;
  scheduledStartDate: string;
  priority: number;
  alter: AlterCriteria;
  alterCurrent: boolean;
  outOfOfficeTime: boolean;
}
export interface INewProject extends CreateNewProjectRequest, NewProjectBase {}
export interface IProjectDetails extends INewProject {
  processName: string;
}
export enum AlterCriteria {
  DO_NOT_ALTER,
  ALTER_LOWER,
  ALTER_EQUAL,
  ALTER_BOTH,
  ALTER_ALL,
}
export enum MaterialType {
  BOBBIN = "bobbin",
  DRUM = "drum",
  OTHER = "other",
  COIL = "coil",
  LABOUR = "labour",
}
export interface IResourceBase {
  id: string;
}
export interface CreateBobbinRequest {
  materialType: MaterialType;
  resourceName: string;
  totalAmount: number;
  allocated: number;
  available: number;
}

export interface CreateLabourRequest {
  materialType: MaterialType;
  resourceName: string;
  hourlyCost: number;
  overTimeHourlyCost: number;
}

export interface CreateAuditLogRequest {
  addedTime: any;
  projectName?: any;
  description?: any;
  resourceName: any;
  action: any;
  quantity: any;
  performedBy: any;
}

export interface ResourceDetails extends IResourceBase, CreateBobbinRequest {}

export interface LabourDetails extends IResourceBase, CreateLabourRequest {}

export interface AuditLogDetails extends IResourceBase, CreateAuditLogRequest {}
