import React, { FC } from "react";
import { useParams } from "react-router";

import MainLayout from "../../components/MainLayout";
import EditProject from "./EditProject";
import { ProjectContextWrapper } from "./NewProjextContext";

export const EditProjectWrapper: FC<{ type?: string }> = (props: {
  type?: string;
}) => {
  const { type } = props;
  const params: any = useParams();
  return (
    <ProjectContextWrapper>
      <MainLayout
        type="primary"
        OpenKey={["scheduling"]}
        selectedKey={["schedule"]}
      >
        <EditProject id={params.id} type={type} />
      </MainLayout>
    </ProjectContextWrapper>
  );
};
