import React, { useState, useEffect } from "react";
import { Progress, Skeleton, Tag } from "antd";
import "./UtilizationDetails.scss";
import { DateTime, Duration } from "luxon";
import { useConnection } from "../../Services/ApplicationContext";
import {
  ILocation,
  IMachine,
  IMachineDetails,
} from "../../definetions/MachineModel";
import { SERVICE_TYPE } from "../../constants/constants";

type MachineEvents = {
  eventValue: number;
  firstEvent: number;
  periodInMilliSecs: number;
  machineAttribute: string;
  machineID: number;
  attributeName: string;
  eventTime: number;
  machineName: string;
  deviceName: string;
  status: string;
};

interface DataType {
  uid: string;
  eventTime: string;
  mac: string;
  status: string;
  machineEvents: MachineEvents[];
}

interface DeviceData {
  deviceName: string;
  deviceStatus: string;
}

const UtilizationDetails = (props: any) => {
  const { dailyReport, details, overallReport, query } = props;
  const [loadingMdata, setLoadingMdata] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [progressLoading, setProgressLoading] = useState<boolean>(false);
  const [machineData, setMachineData] = useState<IMachineDetails[]>([]);
  const [locationData, setLocationData] = useState<ILocation[]>([]);
  const [locationName, setLocationName] = useState<boolean>(true);
  const { get, post } = useConnection();

  useEffect(() => {
    getLocationDetails();
  }, []);

  const mapMachineData = (
    machines: IMachine[],
    locations: ILocation[],
  ): IMachineDetails[] => {
    return machines.map((val) => ({
      ...val,
      locationName: locations.find(
        (value) => value.locationID === val.locationID,
      )?.locationName,
    }));
  };

  let totalInMilli =
    !dailyReport || dailyReport.length == 0 || !dailyReport[0]
      ? 0
      : dailyReport[0]?.totalMin * 60 * 1000;
  // .reduce((a: any, b: any) => +a + +b.totalMin, 0) * 60 * 1000

  const totalH = Math.round(Duration.fromMillis(totalInMilli).as("hour"));

  const getEndDate = (startDate: number, range: string): number => {
    const dateTime = DateTime.fromMillis(startDate);
    switch (range) {
      case "P1W":
        return dateTime.startOf("week").minus({ day: 1 }).valueOf();
      case "P1M":
        return dateTime.startOf("month").minus({ day: 1 }).valueOf();
      case "P1D":
        return dateTime.minus({ day: 1 }).valueOf();
    }
    return startDate;
  };

  const elapsed =
    Math.min(new Date().getTime(), query.start + 86400000) -
    getEndDate(query.start, query.duration);
  const percentage = Math.min((100 * totalInMilli) / elapsed, 100);

  function perc2color(perc: number) {
    perc = 100 - perc;
    let r;
    let g;
    const b = 0;
    if (perc < 50) {
      r = 255;
      g = Math.round(5.1 * perc);
    } else {
      g = 255;
      r = Math.round(510 - 5.1 * perc);
    }
    const h = r * 0x10000 + g * 0x100 + b * 0x1;
    return `#${`000000${h.toString(16)}`.slice(-6)}`;
  }

  const getLocationDetails = async () => {
    setProgressLoading(true);
    try {
      const resultLocations = await get(
        "system-management/location/getAuthorizedLocations",
        undefined,
        SERVICE_TYPE.serviceType
      );
      setLocationData(resultLocations.data.list);
    } catch (e: any) {
      console.log("get location name Error :", e);
    } finally {
      setProgressLoading(false);
    }
  };

  const renderLocationName = (id: number) => {
    // console.log("location for = ", resultLocations);
    for (let index = 0; index < locationData?.length; index++) {
      let locationName: string;
      if (id === locationData[index].locationID) {
        locationName = String(locationData[index].locationName);

        return locationName;
      }
    }
  };

  return (
    <div className="utilProgress">
      <div className="utilization-progress">
        <div className="utilName">Utilization</div>
        <div className="progress-circle">
          <Progress
            format={(x) => `${totalH} Hours`}
            width={160}
            type="circle"
            percent={percentage}
            strokeColor={perc2color(percentage)}
            strokeWidth={12}
            trailColor="#3F3F3F"
          />
        </div>
      </div>
      <div className="machine-details-section">
        <div className="machineDetails">Machine Details</div>
        {loading === true || loadingMdata === true ? (
          <Skeleton active />
        ) : (
          <div>
            <div className="attr-val-container">
              <span className="machine-details-attr">
                Machine Name <div>:</div>
              </span>
              <span className="machine-details-val">
                {details?.machineName}
              </span>
            </div>
            <div className="attr-val-container">
              <span className="machine-details-attr">
                IoT Device
                <div>:</div>
              </span>
              <span className="machine-details-val">{details?.deviceName}</span>
            </div>
            <div className="attr-val-container">
              <span className="machine-details-attr">
                Location<div>:</div>
              </span>
              <span className="machine-details-val">
                {renderLocationName(details?.locationID)}
              </span>
            </div>
            <div className="attr-val-container">
              <span className="machine-details-attr">
                Machine Status<div>:</div>
              </span>
              <span className="machine-status-tag">
                <Tag
                  color={
                    details?.status === 1
                      ? "green"
                      : details?.status === 0
                      ? "red"
                      : "gold"
                  }
                  // key={onOff}
                  style={{
                    backgroundColor:
                      details?.status === 1
                        ? "rgb(153, 243, 153)"
                        : details?.status === 0
                        ? "rgb(250, 160, 160)"
                        : "rgb(255, 233, 154)",
                  }}
                >
                  {details?.status === 1
                    ? "RUNNING"
                    : details?.status === 0
                    ? "NOT RUNNING"
                    : "UNKNOWN"}
                </Tag>
              </span>
            </div>
            <div className="attr-val-container">
              <span className="machine-details-attr">
                IoT Status<div>:</div>
              </span>
              <span className="machine-status-tag">
                <Tag
                  color={
                    details.deviceStatus === 1
                      ? "green"
                      : details.deviceStatus === 0
                      ? "red"
                      : "gold"
                  }
                  style={{
                    backgroundColor:
                      details.deviceStatus === 1
                        ? "rgb(153, 243, 153)"
                        : details.deviceStatus === 0
                        ? "rgb(250, 160, 160)"
                        : "rgb(255, 233, 154)",
                  }}
                >
                  {details?.deviceStatus === 1
                    ? "ON"
                    : details?.deviceStatus === 0
                    ? "OFF"
                    : "UNKNOWN"}
                </Tag>
              </span>
            </div>
            {/* <button onClick={() => console.log(iotData)} type="button">
            check
          </button> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default UtilizationDetails;
