import { Dropdown, Layout, Menu, Avatar, Switch } from "antd";
import React, { FC, useState, useEffect } from "react";
import { BellFilled, CaretDownFilled, UserOutlined } from "@ant-design/icons";
import { IoNotificationsOutline, IoSettingsOutline } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { useConnection } from "../../Services/ApplicationContext";
import { useUser } from "../../Services/UserContext";
import "./NotificationDropdownHeader.scss";
import { useNotification } from "../../Services/NotificationContext";

export interface NotificationDropdownHeaderProps {
}

const NotificationDropdownHeader: FC<NotificationDropdownHeaderProps> = (
  props: NotificationDropdownHeaderProps,
) => {
  const {
    readToggleClickedCount,
    setReadToggleClickedCount,
    loadItem,
    setLoadItem,
    showUnread,
    setShowUnread
  } = useNotification();

  const onChangeSwitch = (checked: boolean) => {
    console.log(`switch to ${checked}`);
    setShowUnread(checked)
  };

  return (
    <div className="notification-title-component">
      <div className="title">Notifications</div>
      <div className="actions">
        <div className="label">Only show unread</div>
        <div className="elements">
          <Switch checked={showUnread} onChange={onChangeSwitch} />
        </div>
      </div>
    </div>
  );
};
export default NotificationDropdownHeader;
