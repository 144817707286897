/* eslint-disable no-underscore-dangle */
/* eslint-disable import/newline-after-import */
import React, { FC, useState } from "react";
import {
  Button,
  Input,
  message,
  Popconfirm,
  Skeleton,
  Space,
  Table,
} from "antd";
import {
  PlusOutlined,
  MinusOutlined,
  SearchOutlined,
} from "@ant-design/icons/lib";
import {
  IoCreateOutline,
  IoSettingsOutline,
  IoTrashSharp,
  IoWarning,
} from "react-icons/io5";
// eslint-disable-next-line import/no-extraneous-dependencies

import { useConnection } from "../../Services/ApplicationContext";
import LabourModal from "./LabourModal";
import "../tableStyles.scss";
import "../pagination.scss";
import "./rawMaterial.scss";
import { DateTime } from "luxon";
import { SERVICE_TYPE } from "../../constants/constants";

export interface LabourManagementProps {
  data: any; // need create interface to it.
  onUpdateData: () => void;
  title: string;
  total: number;
  pageSize: number;
  currentPage: number;
  handleChange: (val: any, sorter: any) => void;
  setSearchValue: (val: string) => void;
  searchValue: string;
  setNetworkSearchValue: (val: string) => void;
  onSearch: () => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
}

const LabourManagement: FC<LabourManagementProps> = (
  props: LabourManagementProps,
) => {
  const {
    data,
    onUpdateData,
    title,
    total,
    currentPage,
    pageSize,
    handleChange,
    setSearchValue,
    searchValue,
    setNetworkSearchValue,
    onSearch,
    loading,
    setLoading,
  } = props;
  const { delete: del } = useConnection();

  const [selectedRow, setSelectedRow] = useState();
  const [visible, setVisible] = useState<boolean>(false);

  const edit = (record: any) => {
    setSelectedRow(record);
  };

  const Delete = async (record: any) => {
    setLoading(true);
     console.log(" ------ delete values ---------", record);
    try {
      const result = await del(
        `system-management/resources/deleteResource/${record.key?.toString()}`,
        undefined,
        SERVICE_TYPE.serviceType
      );
      onUpdateData();
      setLoading(false);
      if (result.status === 200) {
        setNetworkSearchValue("");
        setSearchValue("");
        message.open({
          type: "success",
          content: result.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      }
    } catch (error: any) {
      setLoading(false);
      message.open({
        type: "error",
        content: `${error.message}`,
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
    }
  };

  const columns: any[] = [
    {
      title: "Labour",
      dataIndex: "resourceName",
      key: "resourceName",
      sorter: true,
    },
    {
      title: "Last Updated On",
      key: "CreatedAt",
      sorter: true,
      render: (_: any, record: any) => {
        return DateTime.fromMillis(record.CreatedAt).toFormat(
          "dd:MM:yyyy,  hh:mm:ss a",
        );
      },
    },
    {
      title: "Hourly Cost",
      dataIndex: "hourlyCost",
      key: "hourlyCost",
      render: (_: any, record: any) => {
        return <span>Rs {record.hourlyCost}</span>;
      },
    },
    {
      title: "Overtime Hourly Cost",
      dataIndex: "overTimeHourlyCost",
      key: "overTimeHourlyCost",
      render: (_: any, record: any) => {
        return <span>Rs {record.overTimeHourlyCost}</span>;
      },
    },
    {
      title: "Action",
      dataIndex: "operation",
      width: "120px",
      fixed: "right",
      render: (_: any, record: any) => {
        return (
          <Space size="middle">
            <a onClick={() => edit(record)}>
              <IoCreateOutline size={22} color="#487199" />
            </a>
            <Popconfirm
              title={`Are you sure to delete ${record?.resourceName} ?`}
              onConfirm={() => Delete(record)}
              okText="Yes"
              okButtonProps={{ danger: true }}
              cancelText="No"
              icon={<IoWarning size={30} color="#eec335" />}
              placement="topLeft"
              cancelButtonProps={{ type: "primary" }}
            >
              <a>
                <IoTrashSharp size={20} color="#f18b8b" />
              </a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const onCloseModal = (val?: any) => {
    if (val) {
      onUpdateData();
    }
    setVisible(false);
    setSelectedRow(undefined);
  };

  return (
    <div>
      <LabourModal
        onClose={onCloseModal}
        visible={visible}
        type="Add"
        title={title}
        data={undefined}
        onUpdate={onUpdateData}
      />

      {selectedRow !== undefined && (
        <LabourModal
          onClose={onCloseModal}
          visible={selectedRow !== undefined}
          type="Edit"
          data={selectedRow}
          title={title}
          onUpdate={onUpdateData}
        />
      )}
      <div className="raw-subHeading-container">
        <p className="raw-sub-text">{title}</p>
      </div>

      <div>
        <div className="row-space-between">
          <div style={{ marginBottom: "30px" }}>
            <Button
              type="primary"
              style={{
                borderRadius: "5px",
                height: "45px",
                fontSize: "15px",
                fontWeight: 300,
                fontFamily: "Poppins",
              }}
              onClick={() => setVisible(true)}
            >
              <PlusOutlined style={{ fontSize: "17px", fontWeight: "bold" }} />
              Add {title}
            </Button>
          </div>
          <div style={{ marginBottom: "30px" }}>
            <div className="row end">
              <div
                className="search_filter_lables"
                style={{ marginRight: "7px" }}
              >
                Search:{" "}
              </div>
              <Input
                onPressEnter={onSearch}
                onChange={(e: any) => {
                  setSearchValue(e.target.value);
                }}
                value={searchValue}
                placeholder="Search by Labour"
                suffix={
                  <Button
                    type="primary"
                    onClick={onSearch}
                    style={{
                      borderRadius: "0 5px 5px 0",
                      marginRight: "-4px",
                    }}
                  >
                    <SearchOutlined />
                  </Button>
                }
              />
            </div>
          </div>
        </div>
        <div className="general-table general-pagination">
          <Table
            className="components-table-demo-nested"
            columns={columns}
            dataSource={data}
            loading={loading}
            pagination={{
              current: currentPage,
              pageSize,
              total,
              pageSizeOptions: ["5", "10", "20", "30", "40", "50", "100"],
              showQuickJumper: true,
              showSizeChanger: true,
            }}
            onChange={(val: any, filter: any, sorter: any) =>
              handleChange(val, sorter)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default LabourManagement;
