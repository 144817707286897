import React, { FC, useEffect, useState } from "react";
import WiseNestedTable from "./WiseNestedTable";
import MainLayout from "../../components/MainLayout";
import MachinesStatusTable from "./MachinesStatusTable";
import { useConnection } from "../../Services/ApplicationContext";
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Row,
  Select,
  Skeleton,
  Space,
  Tag,
} from "antd";
import { IoChevronDown } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { renderAluminiumPlant } from "./MapGenerator/AluminiumPlant";
import { renderCopperPlant } from "./MapGenerator/CopperPlant";
import { DateTime } from "luxon";
import { aluminiumData, copperPlantData } from "./MapGenerator/Data";

import "./IoTLocationStyles/Styles.scss";
import "./MachinesStatusTable.scss";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { SERVICE_TYPE } from "../../constants/constants";

const { Option } = Select;

type MachineEvents = {
  eventValue: number;
  firstEvent: number;
  periodInMilliSecs: number;
  machineAttribute: string;
  machineID: number;
  attributeName: string;
  eventTime: number;
  machineName: string;
  deviceName: string;
  status: string;
};

interface DataType {
  uid: string;
  eventTime: string;
  mac: string;
  status: string;
  machineEvents: MachineEvents[];
}

interface IMainCanvasDetails {
  width_ratio: number;
  height_ratio: number;
}

interface IReactangleObjects {
  x_ratio: number;
  y_ratio: number;
  width_ratio: number;
  height_ratio: number;
  text: string;
  font_size?: string;
  vertical?: boolean;
}

interface IRandomTextObject {
  x_ratio: number;
  y_ratio: number;
  text: string;
  font_size: string;
}

interface IVerticalTextsObject {
  x_ratio: number;
  y_ratio: number;
  text: string;
}

interface IClearReacsObject {
  x_ratio: number;
  y_ratio: number;
  width_ratio: number;
  height_ratio: number;
}

export interface IPlantDataType {
  main_canvas_details: IMainCanvasDetails;
  rectangle_array: Array<IReactangleObjects>;
  random_texts_array?: IRandomTextObject[];
  vertical_texts?: IVerticalTextsObject[];
  clear_recs_array?: IClearReacsObject[];
}

interface IAxisCordinates {
  x: number;
  y: number;
}

type MachineStatusT = {
  machineID: number;
  machineName: string;
  locationID: string;
  status: number;
  deviceStatus: number;
  deviceName: string;
};

let aluminium_machine = [
  {
    name: "Inter Mediate - 4",
    x_ratio: 0.53,
    y_ratio: 0.09,
    color: "",
    // color: "#18923A",
  },
  {
    name: "Rod Breaker Al - I",
    x_ratio: 0.03,
    y_ratio: 0.25,
    color: "",
    // color: "#C43B3B",
  },
  {
    name: "Rod Breaker Al - 2",
    x_ratio: 0.03,
    y_ratio: 0.43,
    color: "",
    // color: "#C43B3B",
  },
  {
    name: "Skip Strander",
    x_ratio: 0.41,
    y_ratio: 0.25,
    color: "",
    // color: "#C43B3B",
  },
  {
    name: "7 Tubular Strander",
    x_ratio: 0.33,
    y_ratio: 0.59,
    color: "",
    // color: "#C43B3B",
  },
  {
    name: "12 Tubular Strander",
    x_ratio: 0.03,
    y_ratio: 0.78,
    color: "",
    // color: "#C43B3B",
  },
  {
    name: "Bow Strander",
    x_ratio: 0.33,
    y_ratio: 0.92,
    color: "",
    // color: "#C43B3B",
  },
];

let copper_machine = [
  {
    name: "Mini Armouring",
    x_ratio: 0.015,
    y_ratio: 0.05,
    // color: "#C43B3B",
    color: "",
  },
  {
    name: "Drum Twister",
    x_ratio: 0.23,
    y_ratio: 0.15,
    color: "",
  },
  {
    name: "Extruder (100mm)",
    x_ratio: 0.115,
    y_ratio: 0.28,
    color: "",
  },
  {
    name: "Extruder (120mm)",
    x_ratio: 0.135,
    y_ratio: 0.38,
    color: "",
  },
  {
    name: "Extruder (60mm)",
    x_ratio: 0.35,
    y_ratio: 0.46,
    color: "",
  },
  {
    name: "Extruder (90mm)",
    x_ratio: 0.28,
    y_ratio: 0.54,
    color: "",
  },
  {
    name: "Extruder (80mm)",
    x_ratio: 0.4,
    y_ratio: 0.62,
    color: "",
  },
  {
    name: "Auto Coiler - 2",
    x_ratio: 0.95,
    y_ratio: 0.47,
    color: "",
  },
  {
    name: "MWD",
    x_ratio: 0.188,
    y_ratio: 0.575,
    color: "",
  },
  {
    name: "Inter mediate - 5",
    x_ratio: 0.115,
    y_ratio: 0.7,
    color: "",
  },
  {
    name: "1250 Buncher",
    x_ratio: 0.115,
    y_ratio: 0.74,
    color: "",
  },
  {
    name: "Breading Machine",
    x_ratio: 0.205,
    y_ratio: 0.69,
    color: "",
  },
  {
    name: "Settic 1",
    x_ratio: 0.31,
    y_ratio: 0.69,
    color: "",
  },
  {
    name: "Settic 2",
    x_ratio: 0.31,
    y_ratio: 0.724,
    color: "",
  },
  {
    name: "Strander 6",
    x_ratio: 0.432,
    y_ratio: 0.69,
    color: "",
  },
  {
    name: "Strander 7",
    x_ratio: 0.432,
    y_ratio: 0.723,
    color: "",
  },
  {
    name: "Strander 8",
    x_ratio: 0.606,
    y_ratio: 0.718,
    color: "",
  },
  {
    name: "Mica Taping",
    x_ratio: 0.726,
    y_ratio: 0.719,
    color: "",
  },
  {
    name: "Rod Breaker Cu",
    x_ratio: 0.125,
    y_ratio: 0.898,
    color: "",
  },
  {
    name: "Fine-03",
    x_ratio: 0.195,
    y_ratio: 0.79,
    color: "",
  },
  {
    name: "61 Strander",
    x_ratio: 0.265,
    y_ratio: 0.8,
    color: "",
  },
];

const IoTStatus: FC = (props: any) => {
  const { get, post } = useConnection();
  const history = useHistory();
  const [form] = Form.useForm();
  const [deviceDetails, setDeviceDetails] = useState<MachineStatusT[]>([]);
  const [plantValue, setPlantValue] = useState<string>("2");
  const [mapLoading, setMapLoading] = useState(false);
  const [count, setCount] = useState<number>(1);
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [selectedMachine, setSelectedMachine] = useState<string>();

  const [machineStatusTable, setMachineStatusTable] = useState<any>([]);

  const [searchValueResources, setSearchValueResources] = useState<string>("");
  const [networkSearchResources, setNetworkSearchResources] = useState<string>(
    "",
  );

  const [
    filterVisibleUserProfileResources,
    setFilterVisibleUserProfileResources,
  ] = useState<any>();

  const [devicesDetailsList, setDevicesDetailsList] = useState<any>([]);
  const [selectedDeviceList, setSelectedDeviceList] = useState<any>([]);

  const [
    clickedPlantsXYCordinates,
    setClickedPlantsXYCordinates,
  ] = useState<IAxisCordinates>();

  const children: React.ReactNode[] = [];
  if (devicesDetailsList?.length > 0) {
    devicesDetailsList?.map((deviceDetailItem: any, index: any) => {
      children.push(
        <Option
          value={deviceDetailItem?.deviceName}
          key={deviceDetailItem?.deviceName}
        >
          {deviceDetailItem?.deviceName}
        </Option>,
      );
    });
  }
  // for (let i = 10; i < 36; i++) {

  // }

  let plantMapValue = "2";
  let interval: any;

  const handleCanvasClick = (event: any) => {
    const canvas: any = document.getElementById("canvas");
    let rect = canvas.getBoundingClientRect();
    let x = event.clientX - rect.left;
    let y = event.clientY - rect.top;
    if (x && y) {
      setClickedPlantsXYCordinates({ x: x, y: y });
    }
  };

  const changeCursorType = (event: any) => {
    const canvas: any = document.getElementById("canvas");
    if (canvas) {
      canvas.onmousemove = () => {
        canvas.style.cursor = "pointer";
      };
    }
  };

  const iotStatusFilterFormHandler = (val: any) => {
    if (val === "1") {
      setPlantValue("1");
      plantMapValue = "1";
      sessionStorage.setItem("displayPlant", "1");
    } else if (val === "2") {
      setPlantValue("2");
      plantMapValue = "2";
      sessionStorage.setItem("displayPlant", "2");
    }
  };

  const getMachineNameForXyCordinates = (data: IPlantDataType) => {
    let xyC = clickedPlantsXYCordinates;
    let clientWIdth = document.documentElement.clientWidth;
    if (xyC) {
      let x_ratio_clicked =
        xyC?.x / (clientWIdth * data?.main_canvas_details?.width_ratio);
      let y_ratio_clicked =
        xyC?.y / (clientWIdth * data?.main_canvas_details?.height_ratio);
      data?.rectangle_array?.map((element: IReactangleObjects) => {
        let maxValX = element?.width_ratio + element?.x_ratio;
        let maxValY = element?.height_ratio + element?.y_ratio;
        if (
          x_ratio_clicked >= element?.x_ratio &&
          x_ratio_clicked <= maxValX &&
          y_ratio_clicked >= element?.y_ratio &&
          y_ratio_clicked <= maxValY
        ) {
          setSelectedMachine(element?.text);
          console.log(element);
        }
      });
    }
  };

  const handleStatus = (data: any) => {
    data.map((data: any) => {
      // eslint-disable-next-line array-callback-return
      for (let index = 0; index < aluminium_machine.length; index++) {
        if (data.machineName === aluminium_machine[index].name) {
          aluminium_machine[index] = {
            ...aluminium_machine[index],
            color:
              data?.status === 0
                ? "#C43B3B"
                : data.status === 1
                ? "#18923A"
                : "#CA9E01",
          };
        }
      }
      for (let index = 0; index < copper_machine.length; index++) {
        if (data.machineName === copper_machine[index].name) {
          copper_machine[index] = {
            ...copper_machine[index],
            color:
              data?.status === 0
                ? "#C43B3B"
                : data.status === 1
                ? "#18923A"
                : "#CA9E01",
          };
        }
      }
    });
  };

  const getIotDataParams = () => {
    return {
      machineStatus: machineStatusTable,
      iotDevice: selectedDeviceList,
      searchValue:
        networkSearchResources !== "" ? networkSearchResources : undefined,
    };
  };

  const getDeviceData = async () => {
    try {
      const result:any = await post(
        "system-management/device/getAllDeviceDetails",
        {
          page: 0,
          size: 10000,
        },
        undefined,
        SERVICE_TYPE.serviceType
      );
      console.log('get all device', result)
      setDevicesDetailsList(result?.data?.list);
    } catch (error) {
      console.log(error);
    }
  };

  async function getData(spinValue: boolean) {
    setTableLoading(true);
    try {
      if (spinValue) {
        setLoading(true);
      }
      const result:any = await post(
        "system-management/device/getMachineStatus",
        getIotDataParams(),
        undefined,
        SERVICE_TYPE.serviceType
      );
      setCount(count + 1);
      console.log('machine IOT Status', result.status , result.data);
      const tempArray: MachineEvents[] = [];
      setDeviceDetails(result?.data.list);
      handleStatus(result?.data.list);
    } catch (error) {
      console.log(error);
    } finally {
      setTableLoading(false);
      if (spinValue) {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    const plant = sessionStorage.getItem("displayPlant");
    getDeviceData();
    if (plant) {
      window.addEventListener("resize", () => {
        if (plant === "1") {
          getData(false).then(() => {
            console.log("1");
            renderAluminiumPlant(aluminium_machine);
          });
        } else if (plant === "2") {
          getData(false).then(() => {
            console.log("1 - 2");
            renderCopperPlant(copper_machine);
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    console.log("selected machine : ", selectedMachine);
    if (deviceDetails) {
      deviceDetails?.map((element: any) => {
        if (element?.machineName === selectedMachine) {
          history.push({
            pathname: "/report/utilization",
            state: {
              src: {
                id: element?.machineID,
                attr: element,
                type: "MACHINE",
              },
              start: DateTime.now().startOf("day").valueOf(),
              duration: "P1D",
            },
          });
        }
      });
    }
  }, [selectedMachine]);

  useEffect(() => {
    if (plantValue === "1") {
      getMachineNameForXyCordinates(aluminiumData);
    } else if (plantValue === "2") {
      getMachineNameForXyCordinates(copperPlantData);
    }
  }, [clickedPlantsXYCordinates]);

  // useEffect(() => {
  //   interval = setInterval(() => {
  //     getData(false);
  //     console.log("2");
  //   }, 60000);
  //   return () => clearInterval(interval);
  // }, []);

  // useEffect(() => {
  //   if (plantValue === "1") {
  //     getData(false).then(() => renderAluminiumPlant(aluminium_machine));
  //     console.log("3");
  //   } else if (plantValue === "2") {
  //     getData(false).then(() => renderCopperPlant(copper_machine));
  //     console.log("3 - 2");
  //   }
  // }, [count]);

  useEffect(() => {
    if (plantValue === "1") {
      getData(true).then(() => {
        setMapLoading(true);
        renderAluminiumPlant(aluminium_machine);
        console.log("4");
        setMapLoading(false);
      });
    } else if (plantValue === "2") {
      getData(true).then(() => {
        setMapLoading(true);
        renderCopperPlant(copper_machine);
        console.log("4 - 2");
        setMapLoading(false);
      });
    }
  }, [plantValue]);

  const onChangeMachineStatus = (values: any) => {
    setMachineStatusTable(values);
  };

  const handleUserProfileVisibleChange = () => {
    setFilterVisibleUserProfileResources(true);
  };

  document.addEventListener("mousedown", (event: any) => {
    const userProfileArea1 = document.querySelector(
      ".search_menu_payment_icon",
    );
    const userProfileArea2 = document.querySelector(".visibleArea");

    if (userProfileArea1 !== null && userProfileArea2 !== null) {
      if (
        userProfileArea1.contains(event.target) ||
        userProfileArea2.contains(event.target)
      ) {
        setFilterVisibleUserProfileResources(true);
      } else {
        setFilterVisibleUserProfileResources(false);
      }
    }
  });

  useEffect(() => {
    getData(false);
  }, [machineStatusTable, selectedDeviceList, networkSearchResources]);

  const menuResources = (
    <Menu className="visibleArea">
      <Menu.ItemGroup>
        <Menu.Item className="bg_col">
          <Checkbox.Group
            onChange={onChangeMachineStatus}
            value={machineStatusTable}
          >
            <Space direction="vertical">
              <Checkbox value={1}>Running</Checkbox>
              <Checkbox value={0}>Not Running</Checkbox>
              <Checkbox value={-1}>Unknown</Checkbox>
            </Space>
          </Checkbox.Group>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  const handleDeviceSelection = (values: any) => {
    console.log("device selection    ", values);
    setSelectedDeviceList(values);
  };

  const onSearch = () => {
    setNetworkSearchResources(searchValueResources);
  };

  return (
    <MainLayout type="primary">
      <div className="iot_location_main_container">
        <span className="iot_status_title">IoT Status</span>
        <div className="dropdown_set_row">
          <div className="iot_filter_container">
            <div className="form-field-report">
              <span>Plant :</span>
              <Select
                style={{ width: "250px" }}
                suffixIcon={<IoChevronDown size={15} />}
                defaultValue={"2"}
                value={plantValue}
                onChange={iotStatusFilterFormHandler}
              >
                <Option value="1">Aluminium Plant</Option>
                <Option value="2">Copper Plant</Option>
              </Select>
            </div>
          </div>
        </div>
        {loading ? (
          <Skeleton active paragraph={{ rows: 10 }} round />
        ) : (
          <div className="location_container">
            <canvas
              id="canvas"
              onClick={handleCanvasClick}
              onMouseOver={changeCursorType}
            />
          </div>
        )}
      </div>
      <div>
        <div className="search-section-iot_table">
          <Row gutter={[15, 30]}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <div className="h2-title-iot">
                <span>Machine Status</span>
              </div>
            </Col>
            <Col lg={4} md={12} sm={12} xs={24}>
              <div className="search_menu_payment_icon">
                <Dropdown
                  overlay={menuResources}
                  onVisibleChange={handleUserProfileVisibleChange}
                  visible={filterVisibleUserProfileResources}
                >
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => console.log(e)}
                  >
                    <FilterOutlined
                      style={{
                        color: "#073D74",
                        fontSize: "20px",
                      }}
                    />
                    {"  "} Filter
                  </a>
                </Dropdown>
              </div>
            </Col>
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={24}
              style={{ justifyContent: "center" }}
            >
              <Select
                className="select-color"
                mode="multiple"
                allowClear
                maxTagCount={3}
                maxTagTextLength={10}
                style={{ width: "95%", height: 45 }}
                placeholder="Filter by Iot devices"
                defaultValue={selectedDeviceList}
                onChange={handleDeviceSelection}
              >
                {children}
              </Select>
            </Col>
            <Col lg={8} md={12} sm={12} xs={24}>
              <div className="row end">
                <div
                  className="search_filter_lables"
                  style={{ marginRight: "7px" }}
                >
                  Search:{" "}
                </div>
                <Input
                  onPressEnter={onSearch}
                  onChange={(e) => setSearchValueResources(e.target.value)}
                  // placeholder="By Project Name"
                  suffix={
                    <Button
                      type="primary"
                      onClick={onSearch}
                      style={{
                        borderRadius: "0 5px 5px 0",
                        marginRight: "-4px",
                      }}
                    >
                      <SearchOutlined />
                    </Button>
                  }
                />
              </div>
            </Col>
          </Row>
          {/* <div className="h2-title-iot">
              <span>Machine Status</span>
            </div>
            <div className="search-filter-iot">
              <IoFilter
                size={25}
                onClick={showModal}
                color="rgba(255, 255, 255, 0.6)"
                style={{
                  marginRight: "30px",
                }}
              />
              <Input
                placeholder="Search by Machine Name"
                prefix={
                  <IoSearch
                    size={20}
                    color="rgba(255, 255, 255, 0.6)"
                    style={{
                      marginRight: "5px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                }
                value={value}
                style={{ width: "100%" }}
                onChange={(e) => searchByName(e.target.value)}
              />
            </div> */}
        </div>
        <MachinesStatusTable
          machinesStatusData={deviceDetails}
          statusTableLoading={tableLoading}
        />
      </div>
      {/* <WiseNestedTable /> */}
    </MainLayout>
  );
};

export default IoTStatus;
