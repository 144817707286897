import React, { FC } from "react";
import {
  NodeModalContextWrapper, useProcess
} from "./NodeDatasAndFunctionsContext";
import CableProductionFlowChart from "./CableProductionFlowChart";
import MainLayout from "../../components/MainLayout";

export interface CableFlowNodeContextWrapperProps {
  newProcess: boolean;
  edit: boolean;
  processId?: any;
  copy?: boolean
}

export const CableFlowNodeContextWrapper: FC<CableFlowNodeContextWrapperProps> = (
  props: CableFlowNodeContextWrapperProps,
) => {
  console.log('CableFlowNodeContextWrapper',props)
  const { newProcess, edit, processId, copy } = props;
  return (
    <NodeModalContextWrapper>
      <CableProductionFlowChart
        newProcess={newProcess}
        edit={edit}
        processId={processId && processId}
        copy={copy}
      />
    </NodeModalContextWrapper>
  );
};
