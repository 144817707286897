import React, { useState, FC } from "react";
import { Form, Input, Button, Checkbox, message } from "antd";
import { KeyOutline, CallOutline } from "react-ionicons";
import sha1 from "sha1";
import { Link, NavLink, Redirect } from "react-router-dom";
import sierraLogo from "./sierralogo.png";
import "./RecoverThroughMobile.scss";
import { useConnection } from "../../Services/ApplicationContext";
import { useAuthZ } from "../../Services/AuthorizationContext";
import { useHistory } from "react-router";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const RecoverThroughMobile: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<string>();
  const history = useHistory();

  const onSubmit = () => {
    if (value === undefined || value === "") {
      message.open({
        type: "error",
        content: "Enter mobile number",
        duration: 2,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
    } else {
      // history.push("/ChangePassword");
      history.push("/EnterOTP");
    }
  };

  return (
    <div className="main-container-rtm">
      <div className="form-container-rtm">
        <div className="form-header-rtm">
          <div className="header-container-rtm">
            <div className="detail-container-rtm">
              <h2 className="h2-rtm">Reset Password</h2>
              <h4 className="h4-rtm">
                Enter you mobile number to reset your password
              </h4>
            </div>
            <div className="icons">
              <KeyOutline color={"#fff"} height="80px" width="80px" />
            </div>
          </div>
        </div>
        <div className="form-footer-rtm">
          <div className="form-footer-container-rtm">
            <PhoneInput
              country={"lk"}
              placeholder="+9477123456"
              value={value}
              onChange={setValue}
              enableSearch={true}
              disableSearchIcon={true}
              inputProps={{
                name: "phone",
                required: true,
              }}
            />
            <div className="submit-rtm">
              <Button
                type="primary"
                htmlType="submit"
                className="submit-btn-rtm"
                onClick={onSubmit}
                style={{ marginTop: "15px" }}
              >
                Send OTP
              </Button>
            </div>
          </div>
          <div className="login-rtm">
            <NavLink to="/" className="navigation-rtm">
              Return to Login
            </NavLink>
          </div>
        </div>
      </div>
      <div>
        <h5 className="powered-by-rtm">Powered by Xeptagon</h5>
      </div>
    </div>
  );
};

export default RecoverThroughMobile;
