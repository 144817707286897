/* eslint-disable no-param-reassign */
import React, { FC, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Tooltip,
} from "antd";
import sha1 from "sha1";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { SelectProps } from "antd/lib/select";
import { Authorizer, useAuthZ } from "../../Services/AuthorizationContext";
import { useConnection } from "../../Services/ApplicationContext";
import { IClientDetailRow } from "./UserManagementTable";
import { ILocation } from "../../definetions/MachineModel";
import { Plant, Plants } from "../../definetions/Constatnts";
import { ItemProps } from "../../definetions/common";
import "../modalPopUp.scss";
import { useUser } from "../../Services/UserContext";
import { SERVICE_TYPE } from "../../constants/constants";

const { Option } = Select;

export interface EditUserModalProps {
  visible: boolean;
  onClose: (val?: IClientDetailRow) => void;
  data?: IClientDetailRow;
  locations: ItemProps[];
}
const EditUserModal: FC<EditUserModalProps> = (props: EditUserModalProps) => {
  const { visible, onClose, data, locations } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState<string>();
  const [allPlantsEnabled, setAllPlantsEnabled] = useState<boolean>(false);
  const { post } = useConnection();
  const { roles } = useAuthZ();
  const [form] = Form.useForm();

  const { setFullName, userInfo } = useUser();

  useEffect(() => {
    if (
      data?.locationIDs?.length === 0 ||
      data?.clientProfileType === "Administrator"
    ) {
      setAllPlantsEnabled(true);
      setSelectedRole(data?.clientProfileType);
    }
  }, [data]);

  const changeUserNameInNavbar = (values: any) => {
    const tempfullName = data?.fullName;
    const tempfullNameFromContext = userInfo?.fullName;
    console.log(tempfullName, tempfullNameFromContext);
    if (tempfullName === tempfullNameFromContext) {
      // console.log(values?.fullName, "change userName");
      // localStorage.setItem("fullName", values.fullName);
      // window.dispatchEvent(new Event("storage"));
      setFullName(values.fullName);
    }
  };
  const onSubmit = async (values: any): Promise<void> => {
    console.log(values);
    const fName = values.fullName;
    const fFName = fName
      .split(" ")
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    values.fullName = fFName.trim();
    console.log(values, fFName);
    const entry = values;
    setLoading(true);
    try {
      if (values.password) {
        // TODO change if email change feature implemented
        entry.password = sha1(`${values.email}${values.password}`);
      } else {
        delete entry.password;
      }
      delete entry.confirm;
      if (allPlantsEnabled || entry.clientProfileType === "Administrator") {
        entry.locationIDs = [];
      }
      entry.id = data?.id;
      const result:any = await post("settings/client/edit", entry, undefined, SERVICE_TYPE.serviceType);
      if (result.statusText === "SUCCESS") {
        message.open({
          type: "success",
          content: result.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
        changeUserNameInNavbar(entry);
        onClose({ ...data, ...entry } as IClientDetailRow);
      } else {
        message.open({
          type: "warning",
          content: result.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      }
      setLoading(false);
    } catch (e) {
      message.open({
        type: "error",
        content: e.message,
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
      setLoading(false);
    }
  };
  const selectProps: SelectProps<any> = {
    mode: "multiple" as const,
    style: { width: "100%" },
    options: locations,
    placeholder: `Select  ${Plant}!`,
    // maxTagCount: "responsive" as const,
  };
  const onRoleChange = (vl: string) => {
    setSelectedRole(vl);
    setAllPlantsEnabled(vl === "Administrator");
    if (vl === "Administrator") {
      form.setFieldsValue({ locationIDs: [] });
    }
  };
  const onAllPlantsSelect = (val: any) => {
    setAllPlantsEnabled(!allPlantsEnabled);
    if (val.target.checked === true) {
      form.setFieldsValue({ locationIDs: [] });
    }
  };
  return (
    <Modal
      title="Edit User"
      centered
      visible={visible}
      onCancel={() => onClose()}
      width="80%"
      footer={null}
      className="general-modal-popup"
    >
      <Form
        form={form}
        layout="vertical"
        name="register"
        hideRequiredMark={false}
        onFinish={onSubmit}
        initialValues={data}
        scrollToFirstError
        autoComplete="off"
      >
        <Authorizer data={{ variableName: "fullName", feature: "3" }}>
          <Form.Item
            name="fullName"
            label={
              <span>
                Full Name&nbsp;
                <Tooltip title="This name will be displayed">
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            rules={[
              {
                required: true,
                message: "Please input your Full Name!",
                whitespace: true,
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (
                    getFieldValue("fullName")?.trim() &&
                    !getFieldValue("fullName")
                      ?.trim()
                      .match(/^[a-zA-Z][a-zA-Z\. ]*$/)
                  ) {
                    return Promise.reject(
                      "Full name should contain letters only!",
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input spellCheck="false" />
          </Form.Item>
        </Authorizer>
        <Authorizer
          data={{
            variableName: "contactNumber",
            feature: "4",
          }}
        >
          <Form.Item
            name="contactNumber"
            label="Contact Number"
            rules={[
              {
                required: true,
                message: "Please input your Contact Number!",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (
                    getFieldValue("contactNumber") &&
                    getFieldValue("contactNumber").match(/^[ ]*$/)
                  ) {
                    return Promise.reject("Please input your Contact Number!");
                  }
                  if (
                    getFieldValue("contactNumber") &&
                    getFieldValue("contactNumber")?.trim().match(/[\D]/)
                  ) {
                    return Promise.reject(
                      "Please input a valid Contact Number! ",
                    );
                  }
                  if (
                    getFieldValue("contactNumber") &&
                    !getFieldValue("contactNumber")
                      ?.trim()
                      .match(/^\d{10}$/)
                  ) {
                    return Promise.reject(
                      "Contact number should consist of 10 digits!",
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              style={{
                width: "100%",
              }}
              spellCheck="false"
            />
          </Form.Item>
        </Authorizer>
        <Authorizer data={{ variableName: "clientProfileType", feature: "5" }}>
          <Form.Item
            label="Access Level"
            name="clientProfileType"
            rules={[
              {
                required: true,
                message: "Please select your access level!",
              },
            ]}
          >
            <Select
              onChange={onRoleChange}
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {roles.map((value, index) => (
                <Option key={index.toString()} value={value}>
                  {value}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Authorizer>
        <Authorizer data={{ variableName: "locationIDs", feature: "22" }}>
          <Row gutter={8}>
            <Col flex="auto">
              <Form.Item
                label={`User ${Plant}`}
                name="locationIDs"
                className="form-field-plant-multiple"
                rules={[
                  {
                    required:
                      selectedRole !== "Administrator" && !allPlantsEnabled,
                    message: `Please select ${Plant}/s!`,
                  },
                ]}
              >
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  {...selectProps}
                  disabled={
                    selectedRole === "Administrator" || allPlantsEnabled
                  }
                />
              </Form.Item>
            </Col>
            <Col flex="150px">
              <Form.Item label=" ">
                <Checkbox
                  value={allPlantsEnabled}
                  checked={allPlantsEnabled}
                  disabled={selectedRole === "Administrator"}
                  onChange={onAllPlantsSelect}
                >
                  {`Select all ${Plants}`}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Authorizer>
        <Authorizer data={{ variableName: "email", feature: "6" }}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (
                    getFieldValue("email") &&
                    getFieldValue("email").match(/^[ ]*$/)
                  ) {
                    return Promise.reject("Please input your E-mail!");
                  }
                  if (
                    getFieldValue("email") &&
                    !getFieldValue("email")
                      ?.trim()
                      .match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      )
                  ) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject("Please input a valid E-mail!");
                  }
                  return Promise.resolve();
                },
              }),
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input spellCheck="false" disabled />
          </Form.Item>
        </Authorizer>
        <Form.Item className="modal-field-button">
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            style={{ marginRight: "15px" }}
          >
            Edit User
          </Button>
          <Button
            loading={loading}
            danger
            type="primary"
            onClick={() => onClose()}
          >
            Cancel
          </Button>
        </Form.Item>
        <Divider type="horizontal" />
      </Form>
    </Modal>
  );
};
export default EditUserModal;
