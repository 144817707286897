import React, { FC, useEffect, useState } from "react";
import { message, Skeleton } from "antd";
import MainLayout from "../../components/MainLayout";
import BobbinManagement from "./BobbinManagement";
import RawMaterialManagement from "./RawMaterialMangement";
import LabourManagement from "./LabourManagement";
import AuditLogTable from "./AuditLogTable";
import { useConnection } from "../../Services/ApplicationContext";
import {
  LabourDetails,
  MaterialType,
  ResourceDetails,
  AuditLogDetails,
} from "../../definetions/ProjectModal";
import { Container, SERVICE_TYPE } from "../../constants/constants";

const ResourcesManagement: FC = () => {
  const { get, post } = useConnection();

  // ----------- container states ----------
  const [bobbins, setBobbins] = useState<ResourceDetails[]>([]);
  const [bobbinsTotal, setBobbinsTotal] = useState<number>(0);
  const [loadingBobbins, setLoadingBobbins] = useState<boolean>(true);
  const [pageSizeBobbins, setPageSizeBobbins] = useState<number>(5);
  const [currentPageBobbins, setCurrentPageBobbins] = useState<number>(1);
  const [searchValueBobbin, setSearchValueBobbin] = useState<string>("");
  const [
    searchNetworkValueBobbin,
    setSearchNetworkValueBobbin,
  ] = useState<string>("");

  // ----------- raw materials states ----------
  const [other, setOther] = useState<ResourceDetails[]>([]);
  const [otherTotal, setOtherTotal] = useState<number>(0);
  const [loadingOther, setLoadingOther] = useState<boolean>(true);
  const [pageSizeOther, setPageSizeOther] = useState<number>(5);
  const [currentPageOther, setCurrentPageOther] = useState<number>(1);
  const [searchValueOther, setSearchValueOther] = useState<string>("");
  const [
    searchNetworkValueOther,
    setSearchNetworkValueOther,
  ] = useState<string>("");

  // ----------- labour states ----------
  const [labour, setLabour] = useState<LabourDetails[]>([]);
  const [labourTotal, setLabourTotal] = useState<number>(0);
  const [loadingLabour, setLoadingLabour] = useState<boolean>(true);
  const [pageSizeLabour, setPageSizeLabour] = useState<number>(5);
  const [currentPageLabour, setCurrentPageLabour] = useState<number>(1);
  const [searchValueLabour, setSearchValueLabour] = useState<string>("");
  const [
    searchNetworkValueLabour,
    setSearchNetworkValueLabour,
  ] = useState<string>("");
  const [sortOrderBobbin, setSortOrderBobbin] = useState<string>("");
  const [sortOrderRaw, setSortOrderRaw] = useState<string>("");
  const [sortOrderLab, setSortOrderLab] = useState<string>("");

  const [sortFieldBobbin, setSortFieldBobbin] = useState<string>("");
  const [sortFieldRaw, setSortFieldRaw] = useState<string>("");
  const [sortFieldLab, setSortFieldLab] = useState<string>("");

  // ############### FETCHING CONTAINERS DETAILS ##################
  const getAllContainersRequestParams = () => {
    return {
      page: currentPageBobbins - 1,
      size: pageSizeBobbins,
      name:
        searchNetworkValueBobbin !== "" ? searchNetworkValueBobbin : undefined,
      order: sortOrderBobbin !== "" ? sortOrderBobbin : undefined,
      orderBy: sortFieldBobbin !== "" ? sortFieldBobbin : undefined,
    //  type: "bobbin" || "drum" || "coil",
    };
  };

  const getAllRawMaterialsRequestParams = () => {
    return {
      page: currentPageOther - 1,
      size: pageSizeOther,
      name:
        searchNetworkValueOther !== "" ? searchNetworkValueOther : undefined,
      order: sortOrderRaw !== "" ? sortOrderRaw : undefined,
      orderBy: sortFieldRaw !== "" ? sortFieldRaw : undefined,
      type: "other",
    };
  };

  const getAllLabourRequestParams = () => {
    return {
      page: currentPageLabour - 1,
      size: pageSizeLabour,
      name:
        searchNetworkValueLabour !== "" ? searchNetworkValueLabour : undefined,
      order: sortOrderLab !== "" ? sortOrderLab : undefined,
      orderBy: sortFieldLab !== "" ? sortFieldLab : undefined,
      type: "labour",
    };
  };

  const handleBobbinTableChanges = (val: any, sorter: any) => {
    if (sorter.order === "ascend") {
      setSortOrderBobbin("asc");
    } else if (sorter.order === "descend") {
      setSortOrderBobbin("desc");
    } else if (sorter.order === undefined) {
      setSortOrderBobbin("");
    }
    if (sorter.columnKey !== undefined) {
      setSortFieldBobbin(sorter.columnKey);
    } else {
      setSortFieldBobbin("");
    }
    setPageSizeBobbins(val?.pageSize);
    setCurrentPageBobbins(val?.current);
  };

  const handleRawMaterialsTableChanges = (val: any, sorter: any) => {
    if (sorter.order === "ascend") {
      setSortOrderRaw("asc");
    } else if (sorter.order === "descend") {
      setSortOrderRaw("desc");
    } else if (sorter.order === undefined) {
      setSortOrderRaw("");
    }
    if (sorter.columnKey !== undefined) {
      setSortFieldRaw(sorter.columnKey);
    } else {
      setSortFieldRaw("");
    }
    setPageSizeOther(val?.pageSize);
    setCurrentPageOther(val?.current);
  };

  const handleLabourTableChanges = (val: any, sorter: any) => {
    if (sorter.order === "ascend") {
      setSortOrderLab("asc");
    } else if (sorter.order === "descend") {
      setSortOrderLab("desc");
    } else if (sorter.order === undefined) {
      setSortOrderLab("");
    }
    if (sorter.columnKey !== undefined) {
      setSortFieldLab(sorter.columnKey);
    } else {
      setSortFieldLab("");
    }
    setPageSizeLabour(val?.pageSize);
    setCurrentPageLabour(val?.current);
  };

  const getContainerDatas = async () => {
    setLoadingBobbins(true);
    setBobbins([]);
    try {
      const result = await post(
        "system-management/resources/getResources",
        getAllContainersRequestParams(),
        undefined,
        SERVICE_TYPE.serviceType
      );
      console.log(result?.data?.list, "kkkkkkkk");
      setBobbins(result?.data?.list);
      setBobbinsTotal(result?.data?.total);
    } catch (error: any) {
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
      setLoadingBobbins(false);
    } finally {
      setLoadingBobbins(false);
    }
  };

  const getRawMaterialsDatas = async () => {
    try {
      setLoadingOther(true);
      setOther([]);
      const result = await post(
        "system-management/resources/getResources",
        getAllRawMaterialsRequestParams(),
        undefined,
        SERVICE_TYPE.serviceType
      );
      setOther(result?.data?.list);
      setOtherTotal(result?.data?.total);
    } catch (error: any) {
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
      setLoadingOther(false);
    } finally {
      setLoadingOther(false);
    }
  };

  const getLabourDatas = async () => {
    try {
      setLoadingLabour(true);
      setLabour([]);
      const result = await post(
        "system-management/resources/getResources",
        getAllLabourRequestParams(),
        undefined,
        SERVICE_TYPE.serviceType
      );
      setLabour(result?.data?.list);
      setLabourTotal(result?.data?.total);
    } catch (error: any) {
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
      setLoadingLabour(false);
    } finally {
      setLoadingLabour(false);
    }
  };

  const onSearchBobbins = () => {
    setCurrentPageBobbins(1);
    setSearchNetworkValueBobbin(searchValueBobbin);
  };

  // ############### FETCHING RAW MATERIALS DETAILS ##################

  const onSearchRawMaterials = () => {
    setCurrentPageOther(1);
    setSearchNetworkValueOther(searchValueOther);
  };

  // ############### FETCHING LABOUR DETAILS ##################

  const onSearchLabour = () => {
    setCurrentPageLabour(1);
    setSearchNetworkValueLabour(searchValueLabour);
  };

  useEffect(() => {
    getContainerDatas();
  }, [
    pageSizeBobbins,
    currentPageBobbins,
    searchNetworkValueBobbin,
    sortFieldBobbin,
    sortOrderBobbin,
  ]);

  useEffect(() => {
    if (searchNetworkValueOther === "") {
      // setPageSizeOther(5);
      setSearchValueOther("");
      // setCurrentPageOther(1);
      getRawMaterialsDatas();
    } else {
      getRawMaterialsDatas();
    }
  }, [
    pageSizeOther,
    currentPageOther,
    searchNetworkValueOther,
    sortOrderRaw,
    sortFieldRaw,
  ]);

  useEffect(() => {
    getLabourDatas();
  }, [
    pageSizeLabour,
    currentPageLabour,
    searchNetworkValueLabour,
    sortOrderLab,
    sortFieldLab,
  ]);

  return (
    <MainLayout
      type="primary"
      // title="Resources"
      OpenKey={["device"]}
      selectedKey={["resources"]}
    >
      <BobbinManagement
        bobbins={bobbins}
        loading={loadingBobbins}
        setLoading={setLoadingBobbins}
        onUpdateData={() => {
          setLoadingBobbins(true);
          getContainerDatas();
        }}
        title={`${Container.NAME}s`}
        total={bobbinsTotal}
        currentPage={currentPageBobbins}
        pageSize={pageSizeBobbins}
        handleChange={handleBobbinTableChanges}
        setSearchValue={setSearchValueBobbin}
        setNetworkSearchValue={setSearchNetworkValueBobbin}
        onSearch={onSearchBobbins}
        searchValue={searchValueBobbin}
      />
      <RawMaterialManagement
        data={other}
        loading={loadingOther}
        setLoading={setLoadingOther}
        onUpdateData={() => {
          setLoadingOther(true);
          getRawMaterialsDatas();
        }}
        title="Raw Material"
        total={otherTotal}
        currentPage={currentPageOther}
        pageSize={pageSizeOther}
        handleChange={handleRawMaterialsTableChanges}
        setSearchValue={setSearchValueOther}
        onSearch={onSearchRawMaterials}
        setNetworkSearchValue={setSearchNetworkValueOther}
        searchValue={searchValueOther}
      />
      <LabourManagement
        data={labour}
        loading={loadingLabour}
        setLoading={setLoadingLabour}
        onUpdateData={() => {
          setLoadingLabour(true);
          getLabourDatas();
        }}
        title="Labour"
        total={labourTotal}
        currentPage={currentPageLabour}
        pageSize={pageSizeLabour}
        handleChange={handleLabourTableChanges}
        setSearchValue={setSearchValueLabour}
        onSearch={onSearchLabour}
        setNetworkSearchValue={setSearchNetworkValueLabour}
        searchValue={searchValueLabour}
      />
      <>
        <AuditLogTable type="Resource" />
      </>
    </MainLayout>
  );
};
export default ResourcesManagement;
