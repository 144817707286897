/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-nested-ternary */
import moment from "moment";
import { DateTime } from "luxon";
import {
  Button,
  DatePicker,
  Form,
  Select,
  Skeleton,
  message,
  Table,
  Space,
  Col,
} from "antd";
import { TablePaginationConfig } from "antd/es/table/Table";
import React, { useState, useEffect, FC, useCallback } from "react";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { CompassOutline } from "react-ionicons";
import { Row } from "antd/es/grid";
import { useConnection } from "../../Services/ApplicationContext";
import "./archive-styles.scss";
import "../tableStyles.scss";
import "../pagination.scss";
import { SERVICE_TYPE } from "../../constants/constants";

const { Option } = Select;
const { RangePicker } = DatePicker;

export enum ArchReportType {
  UTILIZATION_REPORT = "Utilization",
  IDLE_REPORT = "Idle",
}

export enum SourceType {
  DEVICE = "DEVICE",
  MACHINE = "MACHINE",
}

export interface SourceDetails {
  labels: any[];
  details: any;
}

export interface DataSource {
  id: number;
  attr: string;
  type: string;
}

export declare type PanelMode =
  | "time"
  | "date"
  | "week"
  | "month"
  | "quarter"
  | "year"
  | undefined;
// Response
export interface ArchivedReport {
  id: string;
  start: number;
  duration: string;
  end: number;
  zone: string;
  type: ArchReportType;
  srcList: DataSource[];
  name: string;
  url: string;
  ext: string;
}

// interface Pagination {
//   currentPage: number;
//   pageSize: number;
// }

const durName = (s: string) => {
  switch (s) {
    case "P1D":
      return "Daily";
    case "P1W":
      return "Weekly";
    case "P1M":
      return "Monthly";
    case "P1Y":
      return "Yearly";
    default:
      return "";
  }
};

const ArchivedReportsTable: FC = () => {
  const { get, post } = useConnection();
  const [skeletonLoading, setSkeletonLoading] = useState(true);
  const [reports, setReports] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  // const [searchLoading, setSearchLoading] = useState(false);
  const [sourceDetails, setSourceDetails] = useState<SourceDetails>({
    labels: [],
    details: {},
  });
  const [enableSearch, setEnableSearch] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [searchTerms, setSearchTerms] = useState<any>({
    order: { field: "start", order: "desc" },
    size: 10,
    page: 0,
  });
  const [searchValues, setSearchValues] = useState<any>({
    size: 10,
    page: 0,
  });
  const [sortOrder, setSortOrder] = useState<string>();
  const [sortField, setSortField] = useState<string>();
  // const initialValue = {
  //   currentPage: 1,
  //   pageSize: 10,
  // };
  // const [pagination, setPagination] = useState<Pagination>(initialValue);
  const [PickedValue, setPickedValue] = useState<PanelMode>("date");
  const [form] = Form.useForm();
  const { resetFields, getFieldValue } = form;
  const [pickedType, setPickedType] = useState<string>("Idle");
  const [reportValues, setReportValues] = useState<any[]>([]);

  function disabledDate(current: any) {
    return current && current > moment().endOf("day");
  }

  const calendarChange = (val: string | undefined) => {
    if (val === "P1W") {
      setPickedValue("week");
    } else if (val === "P1M") {
      setPickedValue("month");
    } else {
      setPickedValue("date");
    }
  };

  // const findSourceName = useCallback(
  //   (id: number, type: string, srcDetails: any) => {
  //     // console.log(id, "ooooooooooo", type);
  //     // console.log("yyyyyyyyyyyyy", srcDetails, "yyyyyyyyyyyyyyyyy");
  //     if (type === SourceType.MACHINE) {
  //       const val = srcDetails[`${id}-${type}`].machineName;
  //       return val;
  //     }
  //     if (type === SourceType.DEVICE)
  //       return srcDetails[`${id}-${type}`]?.deviceName;
  //     return "";
  //   },
  //   [],
  // );

  // const genReportSourceName = useCallback(
  //   (type: ArchReportType, sources: DataSource[], srcDetails: any) => {
  //     if (sources.length > 1) {
  //       return `Multiple Sources (${findSourceName(
  //         sources[0].id,
  //         sources[0].type,
  //         srcDetails,
  //       )}, ... )`;
  //     }
  //     return findSourceName(sources[0].id, sources[0].type, srcDetails);
  //   },
  //   [],
  // );

  const processReports = useCallback(
    (reps: any) => {
      if (reps.length === 0) {
        message.open({
          type: "error",
          content: "Error: No Reports generated for this machine!",
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      }
      return reps.data.list.map((vl: any) => {
        console.log(vl.attributes.start, "llllllllllllllll");
        const sDate = DateTime.fromMillis(vl.attributes.start);
        const eDate = DateTime.fromMillis(vl.attributes.end);
        const cDate = DateTime.fromMillis(vl.attributes.CreatedAt);
        const sDateStr = `${sDate.toFormat("yyyy LLLL dd")}`;
        const eDateStr = `${eDate.toFormat("yyyy LLLL dd")}`;
        const cDateStr = `${cDate.toFormat("yyyy LLLL dd")}`;
        const sMonth = `${sDate.toFormat("LLLL")}`;
        let fDate;
        if (vl.attributes.duration === "P1W") {
          fDate = `${sDateStr} - ${eDateStr}`;
        } else if (vl.attributes.duration === "P1M") {
          fDate = sMonth;
        } else {
          fDate = sDateStr;
        }
        return {
          id: vl.attributes.id,
          sourceName:
            vl.attributes.type === "Idle"
              ? `Idle report - ${sDateStr}`
              : `${vl.attributes.machineName} - ${fDate}`,
          date: cDateStr,
          type: vl.attributes.type,
          duration: durName(vl.attributes.duration),
          download: vl,
        };
      });
    },
    [searchValues],
  );

  // const getRawReports = useCallback(async () => {
  //   const repProm = post("report/getArchivedReports", searchTerms);
  //   return repProm.then((reps) => {
  //     if (reps.statusText === "SUCCESS") {
  //       setTotal(reps.data.total);
  //       return reps.data.items;
  //     }
  //     return [];
  //   });
  // }, [searchTerms]);

  const getSources = useCallback(async () => {
    setLoading(true);
    const mProm = post(
      "system-management/machine/getAllMachines",
      {
        page: 0,
        size: 10000,
      },
      undefined,
      SERVICE_TYPE.serviceType
    );
    return Promise.all([mProm]).then(([ms]) => {
      const labels: any = [];
      const details: any = {};
      ms?.data?.list?.forEach((m: any) => {
        const id = `${m?.machineID.toString()}-${SourceType.MACHINE}`;
        labels.push({
          label: m?.machineName,
          value: id,
          attachedToDevice: m.attachedToDevice,
        });
        details[id] = m;
      });
      const data = { labels, details };
      // console.log(details, "pppppppppppppppppppppppppppp");
      setSourceDetails(data);
      setLoading(false);
      return data;
    });
  }, [searchValues]);

  // useEffect(() => {
  //   console.log(searchTerms);
  //   if (sourceDetails.labels.length === 0) {
  //     Promise.all([getRawReports(), getSources()])
  //       .then(([reps, sources]) => {
  //         setReports(processReports(reps, sources.details));
  //         setSkeletonLoading(false);
  //         setLoading(false);
  //       })
  //       .catch((error) => {
  //         setLoading(false);
  //         message.open({
  //           type: "error",
  //           content: error.message,
  //           duration: 2,
  //           style: { textAlign: "right", marginRight: 15, marginTop: 10 },
  //         });
  //       });
  //   } else {
  //     getRawReports().then((reps) => {
  //       setReports(processReports(reps, sourceDetails.details));
  //       setSkeletonLoading(false);
  //       setLoading(false);
  //     });
  //   }
  // }, [searchTerms]);

  const getArchivedReports = () => {
    setLoading(true);
    const repProm = post(
      "report/getIdleReports",
      {
        ...searchValues,
        order: sortOrder,
        orderBy: sortField,
      },
      undefined,
      SERVICE_TYPE.serviceType
    );
    return repProm.then((reps) => {
      console.log(reps, "pppp", searchValues);
      if (reps.statusText === "SUCCESS") {
        setTotal(reps.data.total);
        setReports(processReports(reps));
        // setReportValues(reps.data.list);
        setLoading(false);
        setSkeletonLoading(false);
      }
      return [];
    });
  };

  useEffect(() => {
    getArchivedReports();
    getSources();
  }, [searchValues, sortField, sortOrder, pickedType]);

  async function download(data: any) {
    try {
      console.log("download details", data);
      const param = {
        name: data.attributes.url.split("/")[1].split(".")[0],
        ext: data.attributes.ext,
        url: data.attributes.url,
        id: data.attributes.id,
      };
      await post(
        "report/downArchivedReport",
        param,
        undefined,
        SERVICE_TYPE.serviceType
      ).then((resp) => {
        console.log('download-arch',resp);
        const link = document.createElement("a");
        link.href = resp.data;
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      console.log("Download Fail", error);
    }
  }

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any,
  ) => {
    console.log(sorter, "--------------------");
    setLoading(true);
    // let field;
    // switch (sorter.field) {
    //   case "date":
    //     field = "start";
    //     break;
    //   default:
    //     field = sorter.field;
    //     break;
    // }
    // const sortVal: any = {
    //   field: field ?? "start",
    //   order: sorter.order === "ascend" ? "asc" : "desc",
    // };

    // setSearchTerms((val: any) => {
    //   return {
    //     ...val,
    //     size: pagination.pageSize as number,
    //     page: (pagination.current as number) - 1,
    //     order: sortVal,
    //   };
    // });

    if (sorter.order === "ascend") {
      setSortOrder("asc");
    } else if (sorter.order === "descend") {
      setSortOrder("desc");
    } else if (sorter.order === undefined) {
      setSortOrder(undefined);
    }
    if (sorter.columnKey !== undefined) {
      setSortField(sorter.columnKey);
    } else {
      setSortField(undefined);
    }

    setSearchValues((val: any) => {
      return {
        ...val,
        size: pagination.pageSize as number,
        page: (pagination.current as number) - 1,
      };
    });
    setLoading(false);
  };

  const onSearch = (val: any) => {
    setEnableSearch(false);
    setSkeletonLoading(true);
    // setSearchTerms((sTerm: any) => {
    //   const params: any = {};
    //   if (val.sources && val.sources.length > 0) {
    //     params.srcList = val.sources.map((m: any) => {
    //       const idType = m.split("-");
    //       return {
    //         id: idType[0],
    //         attr: "OnOff",
    //         type: idType[1],
    //       };
    //     });
    //   } else {
    //     params.srcList = [];
    //   }

    //   if (val.type) {
    //     params.type = val.type;
    //   }

    //   if (val.duration) {
    //     params.duration = val.duration;
    //   }

    //   if (val.interval && val.interval.length > 0) {
    //     params.period = {};
    //     if (PickedValue === "date") {
    //       if (val.interval[0])
    //         params.period.from = val.interval[0].startOf("day").valueOf();
    //       if (val.interval[1])
    //         params.period.to = val.interval[1].endOf("day").valueOf();
    //     } else if (PickedValue === "month") {
    //       if (val.interval[0])
    //         params.period.from = val.interval[0].startOf("month").valueOf();
    //       if (val.interval[1])
    //         params.period.to = val.interval[1].endOf("month").valueOf();
    //     } else if (PickedValue === "week") {
    //       if (val.interval[0])
    //         params.period.from = val.interval[0].startOf("week").valueOf();
    //       if (val.interval[1])
    //         params.period.to = val.interval[1].endOf("week").valueOf();
    //     }
    //   }
    //   return {
    //     ...sTerm,
    //     size: 10,
    //     page: 0,
    //     ...params,
    //   };
    // });
    if (val.type === undefined || val.type === "Idle") {
      // console.log(val.interval[0], val.interval[1]);
      setSearchValues((v: any) => {
        return {
          ...v,
          srcList: undefined,
          duration: undefined,
          start:
            val.interval !== undefined
              ? val.interval === null
                ? undefined
                : val.interval[0] === null
                ? undefined
                : val.interval[0].startOf("day").valueOf()
              : undefined,
          end:
            val.interval !== undefined
              ? val.interval === null
                ? undefined
                : val.interval[1] === null
                ? undefined
                : val.interval[1].startOf("day").valueOf()
              : undefined,
          type: "Idle",
        };
      });
    } else {
      if (val.duration === undefined) {
        val.duration = "P1D";
      }
      if (val.duration === "P1D") {
        setSearchValues((v: any) => {
          return {
            ...v,
            type: val.type,
            srcList: val.sources,
            duration: val.duration,
            start:
              val.interval !== undefined
                ? val.interval === null
                  ? undefined
                  : val.interval[0] === null
                  ? undefined
                  : val.interval[0].startOf("day").valueOf()
                : undefined,
            end:
              val.interval !== undefined
                ? val.interval === null
                  ? undefined
                  : val.interval[1] === null
                  ? undefined
                  : val.interval[1].startOf("day").valueOf()
                : undefined,
          };
        });
      }
      if (val.duration === "P1W") {
        setSearchValues((v: any) => {
          return {
            ...v,
            type: val.type,
            srcList: val.sources,
            duration: val.duration,
            start:
              val.interval !== undefined
                ? val.interval === null
                  ? undefined
                  : val.interval[0] === null
                  ? undefined
                  : val.interval[0].startOf("day").valueOf()
                : undefined,
            end:
              val.interval !== undefined
                ? val.interval === null
                  ? undefined
                  : val.interval[1] === null
                  ? undefined
                  : val.interval[1].startOf("day").valueOf()
                : undefined,
          };
        });
      }
      if (val.duration === "P1M") {
        setSearchValues((v: any) => {
          return {
            ...v,
            type: val.type,
            srcList: val.sources,
            duration: val.duration,
            start:
              val.interval !== undefined
                ? val.interval === null
                  ? undefined
                  : val.interval[0] === null
                  ? undefined
                  : val.interval[0].startOf("day").valueOf()
                : undefined,
            end:
              val.interval !== undefined
                ? val.interval === null
                  ? undefined
                  : val.interval[1] === null
                  ? undefined
                  : val.interval[1].startOf("day").valueOf()
                : undefined,
          };
        });
      }
    }
  };

  const changeDateFormat = (val: string) => {
    const tempVal = val.replace(".", ":").replace(".", ":");
    const arr = tempVal.split(":");
    return `${arr[2]}:${arr[1]}:${arr[0]}`;
  };

  const columns: any = [
    {
      title: "Source Name",
      dataIndex: "sourceName",
      key: "sourceName",
      // width: 150,
      render: (text: any) => {
        return <div style={{ textAlign: "left" }}>{text}</div>;
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      // width: 100,
      sorter: false,
    },
    {
      title: "Created At",
      dataIndex: "date",
      key: "CreatedAt",
      // width: 100,
      sorter: true,
      // render: (text: any, record: any) => {
      //   return <Space size="middle">{changeDateFormat(record.date)}</Space>;
      // },
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      // width: 100,
      font: 20,
      sorter: false,
    },
    {
      title: "Download",
      dataIndex: "download",
      key: "download",
      render: (text: any) => (
        <a onClick={() => download(text)} target="_blank">
          <CloudDownloadOutlined style={{ fontSize: "22px" }} />
        </a>
      ),
      // width: 100,
    },
  ];

  if (skeletonLoading) {
    return <Skeleton active paragraph={{ rows: 10 }} round />;
  }
  return (
    <div className="inner-dark-section general-pagination">
      <div
        className="search-section"
        style={{ position: "sticky", top: "0", zIndex: 2 }}
      >
        <Form
          className="rep-search-container"
          layout="inline"
          form={form}
          name="reports"
          hideRequiredMark
          scrollToFirstError
          autoComplete="off"
          onFinish={onSearch}
        >
          <div>
            {pickedType === "Idle" ? (
              <>
                <Row>
                  <Col>
                    <div className="form-field-archive">
                      <Form.Item label="Type" name="type">
                        <Select
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentElement
                          }
                          showSearch
                          defaultValue="Idle"
                          optionFilterProp="children"
                          filterOption={(input, option: any) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder="Select a Report Type"
                          style={{ width: "250px", marginLeft: "10%" }}
                          onChange={(value: string) => {
                            setEnableSearch(true);
                            setPickedType(value);
                            setSearchValues({
                              type: "Utilization",
                              duration: "P1D",
                              size: 10,
                              page: 0,
                            });
                            setSortOrder(undefined);
                            setSortField("0");
                            resetFields(["interval"]);
                          }}
                        >
                          <Option value={ArchReportType.IDLE_REPORT}>
                            {ArchReportType.IDLE_REPORT}
                          </Option>
                          <Option value={ArchReportType.UTILIZATION_REPORT}>
                            {ArchReportType.UTILIZATION_REPORT}
                          </Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col>
                    <div
                      className="form-field-archive-interval"
                      style={{ marginLeft: "7%" }}
                    >
                      <Form.Item
                        name="interval"
                        label="Interval"
                        labelAlign="left"
                      >
                        <RangePicker
                          allowEmpty={[true, true]}
                          picker={pickedType === "Idle" ? "date" : PickedValue}
                          disabledDate={disabledDate}
                          placeholder={["Start Date", "End Date"]}
                          style={{ marginLeft: "2%", width: "300px" }}
                          onChange={(values, formatString) => {
                            setEnableSearch(true);
                          }}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col>
                    <div
                      className="form-field-archive-interval"
                      style={{ marginLeft: "80%" }}
                    >
                      <Form.Item>
                        <Button
                          className="form-button"
                          type="primary"
                          htmlType="submit"
                          disabled={!enableSearch}
                        >
                          Search
                        </Button>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row style={{ width: "110%" }}>
                  <Col span={8}>
                    <div
                      className="form-field-archive-multiple form-field-plant-multiple form-field-archive"
                      style={{
                        display: pickedType === "Idle" ? "none" : "block",
                      }}
                    >
                      <Form.Item
                        name="sources"
                        label="Source"
                        labelAlign="left"
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentElement
                          }
                          mode="multiple"
                          maxTagCount={3}
                          disabled={pickedType === "Idle"}
                          placeholder="Select a Source"
                          maxTagTextLength={10}
                          style={{ width: "350px", marginLeft: "2%" }}
                          onChange={(value: any) => {
                            setEnableSearch(true);
                          }}
                          onDeselect={() => {
                            setEnableSearch(true);
                          }}
                          filterOption={(input, option) => {
                            return (
                              typeof option?.props.children === "string" &&
                              option?.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {sourceDetails.labels.map((s: any) => (
                            <Option
                              key={s.value}
                              value={s.value.split("-")[0]}
                              className={
                                s.attachedToDevice ? "" : "not-attached"
                              }
                            >
                              {s.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={8}>
                    <div
                      className="form-field-archive"
                      style={{
                        display: pickedType === "Idle" ? "none" : "block",
                        marginLeft: "20%",
                      }}
                    >
                      <Form.Item label="Report" name="duration">
                        <Select
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentElement
                          }
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option: any) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder="Select a Duration"
                          defaultValue="P1D"
                          style={{ width: "220px", marginLeft: "2%" }}
                          onChange={(value: any) => {
                            calendarChange(value.toString());
                            setEnableSearch(true);
                            resetFields(["interval"]);
                          }}
                        >
                          <Option value="P1D">Daily</Option>
                          <Option value="P1W">Weekly</Option>
                          <Option value="P1M">Monthly</Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={8}>
                    <div
                      className="form-field-archive"
                      style={{
                        display: pickedType === "Idle" ? "none" : "block",
                      }}
                    >
                      <Form.Item label="Type" name="type">
                        <Select
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentElement
                          }
                          showSearch
                          defaultValue="Idle"
                          optionFilterProp="children"
                          filterOption={(input, option: any) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder="Select a Report Type"
                          style={{ width: "220px", marginLeft: "2%" }}
                          onChange={(value: string) => {
                            setPickedType(value);
                            setEnableSearch(true);
                            setSearchValues({
                              size: 10,
                              page: 0,
                            });
                            setSortOrder(undefined);
                            setSortField("0");
                            resetFields(["interval", "duration", "sources"]);
                            setPickedValue("date");
                          }}
                        >
                          <Option value={ArchReportType.IDLE_REPORT}>
                            {ArchReportType.IDLE_REPORT}
                          </Option>
                          <Option value={ArchReportType.UTILIZATION_REPORT}>
                            {ArchReportType.UTILIZATION_REPORT}
                          </Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="form-field-archive-interval">
                      <Form.Item
                        name="interval"
                        label="Interval"
                        labelAlign="left"
                      >
                        <RangePicker
                          allowEmpty={[true, true]}
                          picker={pickedType === "Idle" ? "date" : PickedValue}
                          disabledDate={disabledDate}
                          placeholder={
                            PickedValue === "date"
                              ? ["Start Date", "End Date"]
                              : PickedValue === "month"
                              ? ["Start Month", "End Month"]
                              : ["Start Week", "End Week"]
                          }
                          style={{ marginLeft: "2%", width: "300px" }}
                          onChange={(values, formatString) => {
                            setEnableSearch(true);
                          }}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col>
                    <div
                      className="form-field-archive-interval"
                      style={{ marginLeft: "40%" }}
                    >
                      <Form.Item>
                        <Button
                          className="form-button"
                          type="primary"
                          htmlType="submit"
                          disabled={!enableSearch}
                        >
                          Search
                        </Button>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </Form>

        {/* <Button onClick={() => console.log(sourceDetails)} title="check">
          title
        </Button> */}
      </div>
      <Table
        className="general-table"
        columns={columns}
        dataSource={reports}
        loading={loading || skeletonLoading}
        rowKey={(record) => record.id}
        pagination={{
          current: Number(searchValues.page + 1),
          pageSize: searchValues.pageSize,
          total,
          showQuickJumper: true,
          showSizeChanger: true,
        }}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default ArchivedReportsTable;
