/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { FC, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Dropdown,
  Empty,
  Input,
  Menu,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Skeleton,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import {
  PlusOutlined,
  MinusOutlined,
  SearchOutlined,
  FilterOutlined,
} from "@ant-design/icons/lib";
import {
  IoCreateOutline,
  IoSettingsOutline,
  IoTrashSharp,
  IoWarning,
} from "react-icons/io5";
import moment from "moment";
import { useConnection } from "../../Services/ApplicationContext";
import RawMaterialModal from "./RawMaterialModal";
import EditRawMaterialAvailableModal from "./EditRawMaterialAvailableModal";
import {
  ResourceDetails,
  AuditLogDetails,
} from "../../definetions/ProjectModal";
import "../tableStyles.scss";
import "../pagination.scss";
import "./rawMaterial.scss";
import { SERVICE_TYPE } from "../../constants/constants";

const { RangePicker } = DatePicker;

export interface IAuditLogTableProps {
  type: string;
}

const AuditLogTable: FC<IAuditLogTableProps> = (props: IAuditLogTableProps) => {
  const { type } = props;
  const { delete: del, post } = useConnection();

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalDetails, setModalDetails] = useState<any>();

  const [loadingAuditLog, setLoadingAuditLog] = useState<boolean>(false);

  const [
    auditLogResourcesCurrentPage,
    setAuditLogResourcesCurrentPage,
  ] = useState<number>(1);
  const [
    auditLogResourcesPageSize,
    setAuditLogResourcesPageSize,
  ] = useState<number>(5);

  const [tableDataResources, setTableDataResources] = useState<any>([]);
  const [totalResources, setTotalResources] = useState<number>(0);

  const [searchByTermResources, setSearchByTermResources] = useState<string>(
    "projectName",
  );
  const [filterByTermResources, setFilterByTermResources] = useState<any>([]);
  const [searchValueResources, setSearchValueResources] = useState<string>("");
  const [networkSearchResources, setNetworkSearchResources] = useState<string>(
    "",
  );
  const [startDateResources, setStartDateResources] = useState<any>("");
  const [endDateResources, setEndDateResources] = useState<any>("");
  const [
    filterVisibleUserProfileResources,
    setFilterVisibleUserProfileResources,
  ] = useState<any>();

  const [sortField, setSortField] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");

  const actionStatusBackGroundColour = (status: string) => {
    switch (status.toLocaleLowerCase()) {
      case "allocated":
        return "#99F399";
      case "updated":
        return "#5E76AA";
      case "used":
        return "#EAF4EA";
      case "added":
        return "#FFE99A";
      case "removed":
        return "#FDBEBE";
      case "deleted":
        return "#EAF4EA";
      default:
        return "#EAF4EA";
    }
  };

  const actionStatusTextColour = (status: string) => {
    switch (status.toLocaleLowerCase()) {
      case "allocated":
        return "#18923A";
      case "updated":
        return "#314DA2";
      case "used":
        return "#636663";
      case "added":
        return "#C29800";
      case "removed":
        return "#f24f22";
      case "deleted":
        return "#636663";
      default:
        return "#636663";
    }
  };

  const getAllAuditLogRequestParams = () => {
    return {
      page: auditLogResourcesCurrentPage - 1,
      size: auditLogResourcesPageSize,
      type,
      searchType:
        networkSearchResources !== "" ? searchByTermResources : undefined,
      searchTerm:
        networkSearchResources !== "" ? networkSearchResources : undefined,
      startTime:
        startDateResources !== ""
          ? parseInt(startDateResources) / 1000
          : undefined,
      endTime:
        endDateResources !== "" ? parseInt(endDateResources) / 1000 : undefined,
      order: sortOrder !== "" ? sortOrder : "desc",
      sortby: sortField !== "" ? sortField : "timeCreate",
      status:
        filterByTermResources.length > 0 ? filterByTermResources : undefined,
    };
  };

  const getAuditLogDetails = async (sort?: boolean) => {
    setLoadingAuditLog(true);
    try {
      const result = await post(
        "notification/getAllAuditLogDetails",
        getAllAuditLogRequestParams(),
        undefined,
        SERVICE_TYPE.serviceType,
      );
      console.log("audit log - data", result, "\n\n\n\n\nygygfftyft");
      setTableDataResources(result?.data?.projectList);
      setTotalResources(result?.data?.total);
      setLoadingAuditLog(false);
    } catch (error: any) {
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
      setLoadingAuditLog(false);
    }
  };

  const handleChange = (val: any, sorter: any) => {
    console.log(" ------ sort ----------", sorter);
    setAuditLogResourcesCurrentPage(val?.current);
    setAuditLogResourcesPageSize(val?.pageSize);

    // sorter?.order === "ascend" && setSortOrder("asc");
    // sorter?.order === "descend" && setSortOrder("desc");
    // sorter?.order === undefined && setSortOrder("");
    // sorter?.field !== "" && setSortField(sorter?.field);

    if (sorter.order === "ascend") {
      setSortOrder("asc");
    } else if (sorter.order === "descend") {
      setSortOrder("desc");
    } else if (sorter.order === "") {
      setSortOrder("");
    }
    if (sorter.columnKey !== "") {
      setSortField(sorter.columnKey);
    } else {
      setSortField("");
    }
  };

  document.addEventListener("mousedown", (event: any) => {
    const userProfileArea1 = document.querySelector(
      ".search_menu_payment_icon",
    );
    const userProfileArea2 = document.querySelector(".visibleArea");

    if (userProfileArea1 !== null && userProfileArea2 !== null) {
      if (
        userProfileArea1.contains(event.target) ||
        userProfileArea2.contains(event.target)
      ) {
        setFilterVisibleUserProfileResources(true);
      } else {
        setFilterVisibleUserProfileResources(false);
      }
    }
  });

  const handleUserProfileVisibleChange = () => {
    setFilterVisibleUserProfileResources(true);
  };

  const searchByTermHandler = (event: any) => {
    // console.log(
    //   " ------ search by term change ---------",
    //   event?.target?.value,
    // );
    console.log(event, "ppp");
    setSearchByTermResources(event?.target?.value);
  };

  const onFilterChangeActionStatus = (checkedValues: any) => {
    console.log("checked = ", checkedValues);
    setAuditLogResourcesCurrentPage(1);
    setFilterByTermResources(checkedValues);
  };

  // useEffect(() => {
  //   getAuditLogDetails();
  // }, []);

  // useEffect(() => {
  //   getAuditLogDetails();
  // }, []);

  useEffect(() => {
    getAuditLogDetails();
  }, [
    auditLogResourcesCurrentPage,
    auditLogResourcesPageSize,
    networkSearchResources,
    startDateResources,
    endDateResources,
    filterByTermResources,
    sortField,
    sortOrder,
  ]);

  const menuResources = (
    <Menu className="visibleArea">
      <Menu.ItemGroup title="Search by">
        <Menu.Item className="bg_col">
          <Radio.Group
            onChange={searchByTermHandler}
            value={searchByTermResources}
          >
            <Space direction="vertical">
              <Radio value="performedBy">Performer</Radio>
              <Radio value="projectId">Project ID</Radio>
              <Radio value="projectName">Project Name</Radio>
              <Radio value="resourceName">Resource Name</Radio>
            </Space>
          </Radio.Group>
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup title="Search by Status">
        <Menu.Item className="bg_col">
          <Checkbox.Group
            onChange={onFilterChangeActionStatus}
            value={filterByTermResources}
          >
            <Space direction="vertical">
              <Checkbox value="Added">Added</Checkbox>
              <Checkbox value="Allocated">Allocated</Checkbox>
              <Checkbox value="Deleted">Deleted</Checkbox>
              <Checkbox value="Removed">Removed</Checkbox>
              <Checkbox value="Used">Used</Checkbox>
              <Checkbox value="Updated">Updated</Checkbox>
            </Space>
          </Checkbox.Group>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  const columns: any[] = [
    {
      title: "Time",
      dataIndex: "timeCreate",
      key: "timeCreate",
      sorter: true,
      width: "200px",
      render: (_: any, record: any) => {
        const timeCreatedLocal = new Date(
          moment(record?.timeCreate).year(),
          moment(record?.timeCreate).month(),
          moment(record?.timeCreate).date(),
          moment(record?.timeCreate).hour(),
          moment(record?.timeCreate).minute(),
        ).valueOf();
        return (
          <div>
            {moment.unix(record?.timeCreate).format("YYYY-MM-DD h:mm A")}
          </div>
        );
      },
    },
    {
      title: "Project Name",
      dataIndex: "projectName",
      key: "projectName",
      // sorter: true,
      render: (_: any, record: any) => {
        // console.log(" --------- audit log -----------", record);
        return <div>{record?.projectName ? record?.projectName : "-"}</div>;
      },
    },
    {
      title: "Project ID",
      dataIndex: "projectID",
      key: "projectID",
      render: (_: any, record: any) => {
        return <div>{record?.projectID ? record?.projectID : "-"}</div>;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (_: any, record: any) => {
        return (
          <span onClick={visibleModal}>
            {record?.description?.length > 10
              ? `${record?.description?.slice(0, 10)}...`
              : record?.description?.trim().length > 0
              ? record?.description
              : "-"}
          </span>
        );
      },
    },
    {
      title: "Resource Name",
      dataIndex: "resourceName",
      key: "resourceName",
      sorter: true,
    },
    {
      title: "Action",
      dataIndex: "actionStatus",
      key: "actionStatus",
      render: (_: any, record: any) => {
        return (
          <Space size="middle">
            {record?.actionStatus ? (
              <Tag
                color={actionStatusTextColour(record?.actionStatus)}
                className="table-status"
                style={{
                  backgroundColor: actionStatusBackGroundColour(
                    record?.actionStatus,
                  ),
                  color: actionStatusTextColour(record?.actionStatus),
                }}
              >
                <div className="row">
                  <div>{"\u2B24"}</div>
                  <div className="status_text">
                    {record?.actionStatus.toUpperCase()}
                  </div>
                </div>
              </Tag>
            ) : null}
          </Space>
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (_: any, record: any) => {
        let quantityStr = record?.quantity
          ? record?.unitOfMeasurement !== ""
            ? record?.quantity + record?.unitOfMeasurement
            : record?.quantity
          : "-";

        const isLong = quantityStr.toString().length > 5;
        const truncatedValue = isLong
          ? `${quantityStr.toString().slice(0, 4)}...`
          : quantityStr;

        return isLong ? (
          <Tooltip title={quantityStr}>
            <span>{truncatedValue}</span>
          </Tooltip>
        ) : (
          <span>{quantityStr}</span>
        );
      },
    },
    {
      title: "Performed By",
      dataIndex: "performedBy",
      key: "performedBy",
      width: "160px",
      sorter: true,
    },
  ];

  const onChangeRange = (dateMoment: any, dateString: any) => {
    console.log(dateMoment);
    setAuditLogResourcesCurrentPage(1);
    if (dateMoment !== null && dateMoment[1] !== null) {
      setStartDateResources(
        Date.parse(String(moment(dateMoment[0]?._d).startOf("day"))),
      );
      setEndDateResources(
        Date.parse(String(moment(dateMoment[1]?._d).endOf("day"))),
      );
    } else {
      setStartDateResources("");
      setEndDateResources("");
    }
  };

  const onSearch = () => {
    setAuditLogResourcesCurrentPage(1);
    setNetworkSearchResources(searchValueResources);
  };

  const visibleModal = (val: any) => {
    console.log(" ------ Click description ---------", val);
    setModalVisible(true);
  };
  return (
    <div className="audit-log">
      <Modal
        style={{ top: 300 }}
        bodyStyle={{ backgroundColor: "#1B1B1B", borderRadius: "5px" }}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <p
          style={{
            color: "#ffffff",
            fontWeight: "bold",
            fontSize: 18,
            fontFamily: "Poppins",
          }}
        >
          Description
        </p>
        <p style={{ color: "#ffffff", fontFamily: "Poppins" }}>
          {modalDetails?.description?.trim().length > 0
            ? modalDetails?.description
            : "No description"}
        </p>
      </Modal>

      <div className="raw-subHeading-container">
        <p className="raw-sub-text">Audit Log</p>
      </div>
      <div className="finner-dark-section audit-logs">
        <div className="search-section" style={{ marginBottom: "40px" }}>
          <Row gutter={[15, 30]} style={{ justifyContent: "flex-end" }}>
            <Col lg={8} md={10} sm={12} xs={24}>
              <div className="row center close-icn">
                <div className="search_filter_lables">Interval: </div>
                <RangePicker
                  format="DD:MM:YYYY"
                  allowEmpty={[true, true]}
                  style={{ width: "300px", marginLeft: "2%" }}
                  onChange={onChangeRange}
                />
              </div>
            </Col>
            <Col
              lg={10}
              md={20}
              sm={24}
              xs={24}
              style={{ justifyContent: "flex-start" }}
            >
              <div className="row end">
                <div className="search_menu_payment_icon">
                  <Dropdown
                    overlay={menuResources}
                    onVisibleChange={handleUserProfileVisibleChange}
                    visible={filterVisibleUserProfileResources}
                  >
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => console.log(e)}
                    >
                      <FilterOutlined
                        style={{
                          color: "#073D74",
                          fontSize: "20px",
                        }}
                      />
                      Filter
                    </a>
                  </Dropdown>
                </div>
                <div
                  className="search_filter_lables"
                  style={{ marginRight: "7px" }}
                >
                  Search:
                </div>
                <Input
                  onPressEnter={onSearch}
                  onChange={(e) => setSearchValueResources(e.target.value)}
                  placeholder={`${
                    searchByTermResources === "performedBy"
                      ? "Search by Performer Name"
                      : searchByTermResources === "projectId"
                      ? "Search by Project ID"
                      : searchByTermResources === "projectName"
                      ? "Search by Project Name"
                      : searchByTermResources === "resourceName"
                      ? "Search by Resource Name"
                      : "Search"
                  }`}
                  width="50px"
                  suffix={
                    <Button
                      type="primary"
                      onClick={onSearch}
                      style={{
                        borderRadius: "0 5px 5px 0",
                        marginRight: "-4px",
                      }}
                    >
                      <SearchOutlined />
                    </Button>
                  }
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div>
        <div className="general-table general-pagination">
          <Table
            className="components-table-demo-nested"
            columns={columns}
            dataSource={tableDataResources}
            loading={loadingAuditLog}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  setModalDetails(record);
                },
              };
            }}
            pagination={{
              current: auditLogResourcesCurrentPage,
              pageSize: auditLogResourcesPageSize,
              total: totalResources,
              showQuickJumper: true,
              showSizeChanger: true,
            }}
            onChange={(val: any, filter: any, sorter: any) =>
              handleChange(val, sorter)
            }
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No Audit Trail Data"
                />
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default AuditLogTable;
