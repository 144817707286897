/* eslint-disable import/order */
/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-return-assign */
/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-const */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import React, { FC, useEffect, useState, useRef, useContext } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  message,
  Divider,
  Row,
  Col,
  Tooltip,
  Skeleton,
  Checkbox,
} from "antd";
import {
  PlusOutlined,
  MinusCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { useConnection } from "../../Services/ApplicationContext";
import { useProcess } from "./NodeDatasAndFunctionsContext";
import { IMachineDetails } from "../../definetions/MachineModel";
import {
  getInputs,
  selectInput,
  extractInputs,
  changeDisableInNodeInputs,
  deselect,
  getDeletedInputs,
  InputMap,
  getInputMapState,
  restoreInputMap,
} from "./inputOutputMap";

import "../modalPopUp.scss";
import type { ButtonProps } from "antd/lib/button";
import "./nodeData.scss";
import { Container, SERVICE_TYPE } from "../../constants/constants";

export interface NodeDataModalProps {
  visible: boolean;
  update: boolean;
  disable: boolean;
  nodeData: any;
  onClose: (val?: any) => void;
  previousTaskMachineIds?: string[];
  setInputItemsSelected?: (val: any[]) => void;
  inputItemsSelected?: any[];
  setVisible?: (val: boolean) => void;
  processData?: any[];
  notTemplateCreation?: boolean;
  rawMaterialId?: string;
  nodes?: any;
}

export interface MachineDetails {
  machineId: number;
  types: any;
  index: number;
}

export interface MachineOne {
  machineId: number;
  types: any;
}
export interface ExtraMachines {
  machineId: number;
  types: any;
  index: number;
}

const NodeDataModalProject: FC<NodeDataModalProps> = (
  props: NodeDataModalProps,
) => {
  const {
    visible,
    setVisible,
    onClose,
    nodeData,
    update,
    disable,
    previousTaskMachineIds: prevMachineIds,
    setInputItemsSelected,
    inputItemsSelected,
    processData,
    notTemplateCreation,
    rawMaterialId,
    nodes,
  } = props;

  // const { errorsFromNodeModal, setErrorsFromNodeModal } = useProcess();

  const [form] = Form.useForm();
  const { get, post } = useConnection();
  const { Option } = Select;
  const [machineData, setMachineData] = useState<any[]>([]);
  const [rawMaterials, setRawMaterials] = useState<any>([]);
  const [inputs, setInputs] = useState<any[]>([]);
  // const [tempTaskInputs, setTempTaskInputs] = useState<string[]>([""]);
  const [prevInputMap, setPrevInputMap] = useState<InputMap>();
  const [prevOutputContainers, setPrevOutputContainers] = useState(
    new Array<any>(),
  );
  const [currentInputContainers, setCurrentInputContainers] = useState<{
    [id: number]: string[];
  }>({});
  const [rawMaterialById, setRawMaterialById] = useState<string>("");
  const [
    rawMaterialByIdFromNode,
    setRawMaterialByIdFromNode,
  ] = useState<string>("");
  const [taskGroups, setTaskGroup] = useState<any>([]);
  const [selectedTaskGroup, setSelectedTaskGroup] = useState<any>(undefined);

  const [isAnnealing, setIsAnnealing] = useState<boolean>(false);

  const [erros, setErrors] = useState<any>([]);
  // const [inputNodeTaskRatios, setinputNodeTaskRatios] = useState<any>([]);

  const [cancelBtnDisable, setCancelBtnDisable] = useState<boolean>(false);

  const [inputItemName, setInputItemName] = useState<string>("");
  const [productTypes, setProductTypes] = useState<MachineDetails[]>([]);
  const [inputType, setInputType] = useState("");
  const [outputType, setOutputType] = useState("");

  const [machineDetails, setMachineDetails] = useState<any[]>([]);
  const [extraMachinesPT, setExtraMachinesPT] = useState<ExtraMachines[]>([]);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [groupedInputs, setGroupedInputs] = useState<any>({});
  const [taskNameVal, setTaskNameVal] = useState("");
  const [parallelInputs, setParallelInputs] = useState<any[]>([]);
  const [conInputLoading, setConInputLoading] = useState(false);

  const checkContainerValidation = (): boolean => {
    console.log(prevOutputContainers, "prevOutputContainers");
    const fieldValues = form.getFieldsValue();
    if (fieldValues.machines !== undefined && fieldValues.machines.length > 0) {
      const noOfMachinesInPrevTask = prevOutputContainers?.length;
      const validity: boolean[] = [...Array(noOfMachinesInPrevTask)].map(
        (x) => false,
      );
      const tempInputContainers = new Array<string>();

      Object.keys(currentInputContainers).forEach((key: any) => {
        if (currentInputContainers[key]) {
          currentInputContainers[key].forEach((value: string) => {
            tempInputContainers.push(value);
          });
        }
      });

      if (noOfMachinesInPrevTask) {
        for (let i = 0; i < noOfMachinesInPrevTask; i += 1) {
          if (prevOutputContainers?.length) {
            const containers = prevOutputContainers[i];
            containers.forEach((container: any) => {
              if (tempInputContainers.includes(container)) {
                validity[i] = true;
              }
            });
          }
        }
      }

      const validitySet = new Set(validity);
      console.log(validitySet, "validitySet", validity);
      if (validitySet.has(false)) {
        message.open({
          type: "error",
          content: `Input ${Container.NAME} doesn't support the machine in the previous task!`,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
        return false;
      }
      return true;
    }
    return false;
  };

  const getPreviousTaskMachineOutputContainers = async () => {
    console.log(prevMachineIds, "prevMachineIds");
    let tempOutputContainers: any[] = [];
    if (prevMachineIds) {
      tempOutputContainers = await Promise.all(
        prevMachineIds.map(async (machineId: string) => {
          try {
            const data = await get(
              `system-management/machine/getMachineByID/${machineId}`,
              undefined,
              SERVICE_TYPE.serviceType
            );
            const outputContainer = [];
            if (data.data?.attributes?.outputContainer) {
              outputContainer.push(...data.data.attributes.outputContainer);
            }
            return outputContainer;
          } catch (error) {
            console.log(error);
            return [];
          }
        }),
      );
    }

    const outputContainers: any[] = [];
    tempOutputContainers.forEach((outputContainer: any) => {
      outputContainers.push(outputContainer);
    });
    console.log(outputContainers, "outputContainers");
    setPrevOutputContainers(outputContainers);
  };

  // set the currentInputContainers on refresh or reopening the model
  const handleCurrentInputContainers = async () => {
    const machineIDs: string[] = new Array<string>();
    const tempCurrentInputContainers: { [id: number]: string[] } = {};
    machineIDs.push(nodeData.data.props.machineID);
    if (nodeData.data?.props?.machines) {
      nodeData.data.props.machines.forEach((machine: any) => {
        machineIDs.push(machine.machineID);
      });
    }

    for (let i = 0; i < machineIDs.length; i += 1) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const data = await get(
          `system-management/machine/getMachineByID/${machineIDs[i]}`,
          undefined,
          SERVICE_TYPE.serviceType
        );
        tempCurrentInputContainers[i] = data.data?.attributes?.inputContainer;
      } catch (error) {
        console.log(error);
      }
    }
    console.log(tempCurrentInputContainers, "tempCurrentInputContainers");
    setCurrentInputContainers(tempCurrentInputContainers);
    // console.log(currentInputContainers);
  };

  useEffect(() => {
    console.log("node data ------- ", nodeData);
    if (nodeData?.data?.props) {
      setRawMaterialByIdFromNode(nodeData?.data?.props?.inputItemId);
      setInputType(nodeData?.data?.props?.taskType);
      setOutputType(nodeData?.data?.props?.taskSubType);
    } else if (rawMaterialId) {
      setRawMaterialByIdFromNode(rawMaterialId);
    }
    if (nodeData?.data?.type === "Task") {
      if (
        nodeData?.data?.props.machines !== undefined &&
        nodeData?.data?.props.machines.length > 0
      ) {
        handleCurrentInputContainers();
      }
      setSelectedTaskGroup(nodeData?.data?.props.taskGroup);
      setInputType(nodeData?.data?.props.taskType);
      getPreviousTaskMachineOutputContainers();
    }
    setPrevInputMap(getInputMapState());
    getAllTaskGroup();
  }, [nodeData]);

  useEffect(() => {
    console.log("prev input map state - ", prevInputMap);
  }, [prevInputMap]);

  const getAllTaskGroup = async () => {
    try {
      const results = await get(
        "scheduler/processScheduling/taskGroup",
        undefined,
        SERVICE_TYPE.serviceType
      );
      setTaskGroup(results.data);
      console.log("task groups   --- > ", taskGroups);
    } catch (error: any) {
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
    }
  };

  // const getMachineList = async () => {
  //   try {
  //     const { data } = await post("machine/getMachines", {
  //       page: 0,
  //       size: 0,
  //     });
  //     const { data: machines } = await post("machine/getMachines", {
  //       page: 0,
  //       size: data.total,
  //     });

  //     const machineNames = machines.list.map((machine: any) => ({
  //       machineID: machine.machineID,
  //       machineName: machine.machineName,
  //       inputContainer: machine?.inputContainer,
  //       productType: machine?.productType,
  //     }));
  //     setMachineData(machineNames);
  //   } catch (error: any) {
  //     message.open({
  //       type: "error",
  //       content: error.message,
  //       duration: 2,
  //     });
  //   }
  // };

  // const getRawMaterials = async () => {
  //   try {
  //     const { data } = await get("production/getResources");
  //     const tempData = data
  //       .filter((item: any) => item.materialType === "other")
  //       .map((item: any) => {
  //         return {
  //           resourceName: item.resourceName,
  //           unitOfMeasurement: item.unitOfMeasurement,
  //           id: item._key,
  //         };
  //       });
  //     setRawMaterials(tempData);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // function to email validation with regex
  useEffect(() => {
    console.log(nodes, "lklklklklklklklklklklklklklklklk");
    console.log("initial node data => ", nodes);
    setLoading(true);
    setInitialValues({});
    setGroupedInputs({});
    setParallelInputs([]);
    setExtraMachinesPT([]);
    // setMachineOne(undefined);
    if (visible) {
      setLoading(false);
    }
  }, [visible]);

  useEffect(() => {
    if (nodeData.data !== undefined) {
      if (nodeData?.data?.type === "Task") {
        setInputType(nodeData?.data?.props?.taskType);
        form.setFieldsValue({
          taskName: nodeData.data.props.taskName,
          taskGroup: nodeData.data.props.taskGroup,
          taskDescription: nodeData.data.props.taskDescription,
          taskType: nodeData.data.props.taskType,
          lengthFactors: nodeData.data.props.lengthFactors.map(
            (val: any, i: any) => {
              return {
                inputValues: val.inputValue,
                lengthFactor: val.lengthFactor,
              };
            },
          ),
          machines: nodeData.data.props.machines.map((val: any, i: any) => {
            return {
              machineName: val.machineName,
              concurrentOutput: val.concurrentOutput,
              speed: val.speed,
              numberOfOperators: val.numberOfOperators,
              numberOfHelpers: val.numberOfHelpers,
              setupTime: val.setupTime,
            };
          }),
        });
        setMachineDetails(nodeData.data.props.machines);
      } else {
        form.setFieldsValue({
          ...nodeData?.data?.props,
        });
      }
    }
  }, [visible]);

  const handleRawMaterialChange = (val: any, values: any) => {
    setRawMaterialById(values.key);
    const fieldValues = form.getFieldsValue();
    form.setFieldsValue({
      ...fieldValues,
      unitOfMeasurement: values.unit,
    });
  };

  // const handleMachineChange = (val: any, values: any, index: any) => {
  //   console.log(index, "handleMachineChange");
  //   const fieldValues = form.getFieldsValue();
  //   const tempCurrentInputContainers = currentInputContainers;
  //   if (values.inputContainer) {
  //     tempCurrentInputContainers[index] = values.inputContainer;
  //   } else {
  //     tempCurrentInputContainers[index] = [];
  //   }
  //   setCurrentInputContainers(tempCurrentInputContainers);
  //   fieldValues.machines[index].machineID = values?.key;
  //   form.setFieldsValue({
  //     ...fieldValues,
  //   });
  // };

  const checkForDuplicateMachines = (values: any) => {
    const machineIdList = [];
    // const machineIdList = [new Array<string>(values.machineID)];
    if (values.machines && values.machines.length > 0) {
      machineIdList.push(
        ...values.machines.map((machine: any) => machine.machineID),
      );
    }

    if (machineIdList.length > 1) {
      const machineSet = new Set(machineIdList);

      if (machineSet.size === machineIdList.length) {
        return false;
      }
      return true;
    }
    return false;
  };

  // const onSubmit = async (values: any): Promise<void> => {
  //   console.log(values, "tttttttttttttttttt", getInputs(nodeData?.id));

  //   if (rawMaterialById === "" || undefined || null) {
  //     nodes?.map((node: any) => {
  //       if (node?.data?.type === "Input") {
  //         console.log("pppppppppppppppffffffffffffjjjjjjjjjjjnnnnnnnnnnnnnn");
  //         values.inputItemId = node?.data?.props?.inputItemId;
  //         setRawMaterialById(node?.data?.props?.inputItemId);
  //       }
  //     });
  //   } else {
  //     values.inputItemId = rawMaterialById;
  //   }

  //   let nameChanged = false;
  //   if (nodeData?.data?.type === "Task") {
  //     if (getInputs(nodeData?.id).length !== 0) {
  //       if (values.machines !== undefined && values.machines.length > 0) {
  //         if (checkContainerValidation()) {
  //           if (values.taskInputs !== undefined) {
  //             delete values.taskInputs;
  //           }
  //           console.log(
  //             values.lengthFactors,
  //             "values.concurrentInputParallel",
  //             inputs,
  //           );
  //           if (values.lengthFactors !== undefined) {
  //             for (let i = 0; i < values.lengthFactors.length; i++) {
  //               console.log(inputs[i], "inputs[i]inputs[i]inputs[i]");
  //               if (inputs[i] !== undefined) {
  //                 values.lengthFactors[i].inputValuesId = inputs[i].inputId;
  //                 values.lengthFactors[i].inputValue = inputs[i].value;
  //               }
  //             }
  //           }

  //           if (
  //             inputType === "series" &&
  //             outputType === "matchingOutput" &&
  //             values.inputOutputMap !== undefined
  //           ) {
  //             const arr: any[] = [];
  //             values.inputOutputMap.map((val: any, idx: any) => {
  //               return (val.inputOutputMap = `${values.taskName}-${
  //                 val.inputValues
  //               }-Output ${idx + 1}`);
  //             });
  //             values.inputOutputMap.map((val: any, i: any) => {
  //               console.log(inputs, "kkkkkkmmmbbbbchhfhfhfhfhfh");
  //               return (val.inputItemId = inputs[i].inputId);
  //             });
  //             values.inputOutputMap.map((v: any) => {
  //               arr.push({
  //                 ...v,
  //                 id: nodeData?.id,
  //                 name: `${v.inputValues} Output`,
  //               });
  //             });
  //             values.taskOutputs = arr;
  //           }

  //           values.taskName = values.taskName.trim();

  //           if (
  //             values.taskGroup !== "Cure" ||
  //             values.taskGroup !== "Annealing"
  //           ) {
  //             if (values?.machines?.length > 0) {
  //               if (inputType === "parallel") {
  //                 // values.speed = values.speed.trim();
  //                 values.machines.map((machine: any) => {
  //                   {
  //                     machine.speed = machine.speed.trim();
  //                   }
  //                 });
  //               }
  //               if (inputType === "series") {
  //                 // values.processTime = values.processTime.trim();
  //                 values.machines.map((machine: any) => {
  //                   {
  //                     machine.processTime = machine.processTime.trim();
  //                   }
  //                 });
  //               }
  //             }
  //           }

  //           if (
  //             inputType === "parallel" ||
  //             (inputType === "series" && outputType === "totalOutput")
  //           ) {
  //             const arr = [];
  //             const tName = `${values.taskName} Output`;
  //             arr.push({
  //               id: nodeData?.id,
  //               name: tName,
  //             });
  //             values.taskOutputs = arr;
  //           }

  //           if (inputType === "parallel" && values.machines !== undefined) {
  //             for (let i = 0; i < values.machines.length; i++) {
  //               values.machines[i].parallelConcurrentInput.map(
  //                 (val: any, idx: any) => {
  //                   if (inputs[idx] !== undefined)
  //                     return (val.inputItemId = inputs[idx].inputId);
  //                 },
  //               );
  //               values.machines[i].parallelConcurrentInput.map(
  //                 (val: any, idx: any) => {
  //                   if (inputs[idx] !== undefined)
  //                     return (val.inputValues = inputs[idx].value);
  //                 },
  //               );
  //             }
  //           }
  //           if (checkForDuplicateMachines(values)) {
  //             setCancelBtnDisable(true);
  //             message.open({
  //               type: "error",
  //               content: "Please remove the duplicate machines!",
  //               duration: 2,
  //               style: { textAlign: "right", marginRight: 15, marginTop: 10 },
  //             });
  //           } else {
  //             const previousName = nodeData?.data?.props?.taskName;
  //             let currentName = "";
  //             if (
  //               nodeData?.data?.props?.taskName &&
  //               previousName !== values?.taskName
  //             ) {
  //               nameChanged = true;
  //               currentName = values?.taskName;
  //               console.log("name changed", nameChanged);
  //             }

  //             if (values.machines) {
  //               values.machines = values.machines.map((machine: any) => ({
  //                 ...machine,
  //                 taskGroup: values.taskGroup,
  //               }));
  //               console.log(
  //                 { ...values, nameChanged, previousName, currentName },
  //                 "---------------11-------",
  //               );
  //               onClose({ ...values, nameChanged, previousName, currentName });
  //               form.resetFields();
  //             } else {
  //               console.log(
  //                 { ...values, nameChanged, previousName, currentName },
  //                 "---------------22-------",
  //               );
  //               onClose({ ...values, nameChanged, previousName, currentName });
  //               form.resetFields();
  //             }
  //           }
  //         }
  //       } else {
  //         message.open({
  //           type: "error",
  //           content: "One machine should be selected",
  //           duration: 4,
  //         });
  //       }
  //     } else {
  //       message.open({
  //         type: "error",
  //         content: "No inputs are connected",
  //         duration: 4,
  //       });
  //     }
  //   } else if (nodeData?.data?.type === "Input") {
  //     let arr = [];
  //     arr = [values?.inputItem];
  //     const valuesArr = Object.entries(values);

  //     const previousName = nodeData?.data?.props?.inputItem;
  //     let currentName = "";
  //     if (
  //       nodeData?.data?.props?.inputItem &&
  //       values?.inputItem !== previousName
  //     ) {
  //       nameChanged = true;
  //       currentName = values?.inputItem;
  //     }
  //     const tempArr1: any[] = [];
  //     // const tempArr2: any[] = [];
  //     let sendingValues: any;
  //     valuesArr?.map((item: any[]) => {
  //       tempArr1.push(item);
  //     });
  //     sendingValues = Object.fromEntries(tempArr1);
  //     // sendingValues.taskRatios = tempArr2;
  //     sendingValues.lengthToWeightRatio = sendingValues.lengthToWeightRatio.trim();
  //     if (sendingValues.inputDescription) {
  //       sendingValues.inputDescription = sendingValues?.inputDescription?.trim();
  //     }
  //     console.log(
  //       nodes,
  //       " ...... sending values for input node ------- ",
  //       sendingValues,
  //     );
  //     onClose({ ...sendingValues, nameChanged, previousName, currentName });
  //     form.resetFields();
  //   } else {
  //     onClose(values);
  //     form.resetFields();
  //   }
  // };

  return (
    <Modal
      className={
        nodeData?.data?.type === "Input hide-x-scroll"
          ? "general-modal-popup nodeData-input-modal hide-x-scroll"
          : "general-modal-popup "
      }
      // className="general-modal-popup"
      centered
      width="95%"
      title={`${nodeData?.data?.type} Properties`}
      visible={visible}
      onCancel={() => {
        // restoreInputMap(prevInputMap);
        onClose(false);
        // setGroupedInputs({});
        // form.resetFields();
      }}
      closable={!cancelBtnDisable}
      maskClosable={!cancelBtnDisable}
      cancelButtonProps={{ disabled: !!cancelBtnDisable }}
      onOk={(values: any) => {
        // form.validateFields();
        // form.submit();
      }}
      afterClose={() => {
        // form.resetFields();
        setLoading(true);
      }}
    >
      <div
        style={{
          maxHeight: "70vh",
          overflowY: "auto",
          overflowX: "hidden",
          padding: 5,
        }}
      >
        {!loading && (
          <Form
            form={form}
            layout="vertical"
            name="task"
            hideRequiredMark={disable}
            onFinish={(values: any) => {
              // form.validateFields();
              // onSubmit(values);
            }}
            scrollToFirstError
            autoComplete="off"
          >
            {nodeData?.data?.type === "Task" ? (
              <>
                {/* TaskName */}
                <Form.Item
                  label="Task Name"
                  name="taskName"
                  rules={[
                    {
                      required: true,
                      message: "Required field!",
                    },
                    {
                      whitespace: true,
                      message: "Required field!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Task Name"
                    disabled={disable}
                    // onChange={handleTaskNameChange}
                  />
                </Form.Item>
                {/* TaskGroup */}
                <Form.Item
                  label="Task Group"
                  name="taskGroup"
                  rules={[
                    {
                      required: true,
                      message: "Required field!",
                    },
                    // {
                    //   pattern: /^(?!\s*$).+/,
                    //   message: "Task Name is invalid",
                    // },
                  ]}
                >
                  {/* <Select
                    disabled={disable}
                    placeholder="Select the Task Group"
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(val) => handleTaskGroupChange(val)}
                  >
                    {taskGroups.map((group: any) => (
                      <Option key={group} value={group}>
                        {group}
                      </Option>
                    ))}
                  </Select> */}
                  <Input
                    placeholder="Task Group"
                    disabled={disable}
                    // onChange={handleTaskNameChange}
                  />
                </Form.Item>
                {/* TaskDescription */}
                <Form.Item
                  label="Task Description"
                  name="taskDescription"
                  rules={[
                    {
                      pattern: /^(?!\s*$).+/,
                      message: "Required field!",
                    },
                    {
                      max: 255,
                      message:
                        "The maximum length of 255 characters is reached!",
                    },
                  ]}
                >
                  <Input.TextArea
                    rows={1}
                    disabled={disable}
                    placeholder="Task Description"
                  />
                </Form.Item>
                {/* InputType */}
                <Form.Item
                  label="Input Type"
                  name="taskType"
                  rules={[
                    {
                      required: true,
                      message: "Required field!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Task Type"
                    disabled={disable}
                    // onChange={handleTaskNameChange}
                  />
                </Form.Item>
                {/* OutputType */}
                {inputType === "series" ? (
                  <Form.Item
                    label="Output Type"
                    name="taskSubType"
                    rules={[
                      {
                        required: true,
                        message: "Required field!",
                      },
                    ]}
                  >
                    {/* <Select
                      placeholder="Select the Output Type"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentElement
                      }
                      onChange={(val: any) => {
                        setOutputType(val);
                      }}
                      disabled={disable}
                    >
                      <Option key="parallel" value="matchingOutput">
                        Matching Output
                      </Option>
                      <Option key="series" value="totalOutput">
                        Total Output
                      </Option>
                    </Select> */}
                    <Input
                      placeholder="Task Sub Type"
                      disabled={disable}
                      // onChange={handleTaskNameChange}
                    />
                  </Form.Item>
                ) : null}
                {/* lengthFactors */}
                {inputType === "parallel" ? (
                  <div>
                    <div
                      style={{
                        justifyContent: "space-between",
                      }}
                    >
                      <Divider
                        plain
                        type="horizontal"
                        style={{
                          display: "flex",
                          flex: "1",
                        }}
                      >
                        Enter Length Factor for Inputs
                      </Divider>
                    </div>
                    {/* <Row
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "20px",
                      }}
                    >
                      <Col span={24}> */}
                    <Form.List name="lengthFactors">
                      {(fields, { add, remove }) => {
                        return (
                          <div>
                            {fields.map((field, index) => (
                              <Row>
                                <Col span={11}>
                                  <Form.Item
                                    label={`Input`}
                                    name={[index, "inputValues"]}
                                  >
                                    <Input
                                      disabled
                                      defaultValue="{val.value}"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={11} offset={2}>
                                  <Form.Item
                                    label={`Length Factor`}
                                    name={[index, "lengthFactor"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required field!",
                                      },
                                      {
                                        whitespace: true,
                                        message: "Required field!",
                                      },
                                      ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                          if (!value) {
                                            return Promise.resolve();
                                          }
                                          // eslint-disable-next-line no-restricted-globals
                                          if (isNaN(value)) {
                                            return Promise.reject(
                                              new Error(
                                                "Length factor should be a number.",
                                              ),
                                            );
                                          }

                                          return Promise.resolve();
                                        },
                                      }),
                                    ]}
                                  >
                                    <Input
                                      disabled={disable}
                                      placeholder={`Length Factor for val.value`}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            ))}
                          </div>
                        );
                      }}
                    </Form.List>
                    {/* </Col>
                    </Row> */}
                  </div>
                ) : null}
                {/* InputOutputMap */}
                {inputType === "series" && outputType === "matchingOutput" ? (
                  <div>
                    {inputs.length > 0 ? (
                      <div
                        style={{
                          justifyContent: "space-between",
                        }}
                      >
                        <Divider
                          plain
                          type="horizontal"
                          style={{
                            display: "flex",
                            flex: "1",
                          }}
                        >
                          Enter Output mappings for Input
                        </Divider>
                      </div>
                    ) : null}
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "20px",
                      }}
                    >
                      <Col span={24}>
                        <Form.List name="inputOutputMap">
                          {(fields, { add, remove }) => {
                            return (
                              <div>
                                {Object.keys(groupedInputs).length ===
                                inputs.length
                                  ? inputs.map((val, index) => (
                                      <Row>
                                        <Col span={11}>
                                          <Form.Item
                                            label={`Input ${index + 1}`}
                                            name={[index, "inputValues"]}
                                          >
                                            <Input
                                              disabled
                                              defaultValue={val.value}
                                            />
                                          </Form.Item>
                                        </Col>
                                        <Col span={11} offset={2}>
                                          <Form.Item
                                            label={`Output mapping for ${val.value}`}
                                            name={[index, "inputOutputMap"]}
                                          >
                                            <Input
                                              disabled
                                              placeholder={`Output mapping for ${val.value}`}
                                            />
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                    ))
                                  : Object.keys(groupedInputs).map(
                                      (val: any, index) => (
                                        <Row>
                                          <Col span={11}>
                                            <Form.Item
                                              label={`Input ${index + 1}`}
                                              name={[index, "inputValues"]}
                                            >
                                              {groupedInputs[val].length !==
                                                0 &&
                                              groupedInputs[val].length > 1 ? (
                                                <Select
                                                  getPopupContainer={(
                                                    triggerNode,
                                                  ) =>
                                                    triggerNode.parentElement
                                                  }
                                                  showSearch
                                                  optionFilterProp="children"
                                                  filterOption={(
                                                    input,
                                                    option: any,
                                                  ) =>
                                                    option.children
                                                      .toLowerCase()
                                                      .indexOf(
                                                        input.toLowerCase(),
                                                      ) >= 0
                                                  }
                                                  placeholder="Select Input"
                                                  style={{
                                                    width: "220px",
                                                    marginLeft: "2%",
                                                  }}
                                                >
                                                  {groupedInputs[val].map(
                                                    (v: any, id: any) => (
                                                      <Option value={v.value}>
                                                        {v.value}
                                                      </Option>
                                                    ),
                                                  )}
                                                </Select>
                                              ) : (
                                                <Input
                                                  disabled
                                                  placeholder={`Input ${
                                                    index + 1
                                                  }`}
                                                />
                                              )}
                                            </Form.Item>
                                          </Col>
                                          <Col span={11} offset={2}>
                                            <Form.Item
                                              label={`Output mapping for input ${
                                                index + 1
                                              }`}
                                              name={[index, "inputOutputMap"]}
                                            >
                                              <Input
                                                disabled
                                                placeholder={`Output mapping for input ${
                                                  index + 1
                                                }`}
                                              />
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                      ),
                                    )}
                              </div>
                            );
                          }}
                        </Form.List>
                      </Col>
                    </Row>
                  </div>
                ) : null}
                <Form.List name="machines">
                  {(fields, { add, remove }) => {
                    return (
                      <div>
                        {fields.map((field, index) => (
                          <div key={field.key}>
                            <div
                              style={{
                                justifyContent: "space-between",
                              }}
                            >
                              <Divider
                                plain
                                type="horizontal"
                                style={{
                                  display: "flex",
                                  flex: "1",
                                }}
                              >
                                <div
                                  style={{
                                    marginRight: "30px",
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  Machine {index + 1}
                                </div>
                                {fields.length > 0 && !disable ? (
                                  <Button
                                    danger
                                    type="primary"
                                    className="dynamic-delete-button"
                                    onClick={() => remove(field.name)}
                                    icon={<MinusCircleOutlined />}
                                  />
                                ) : null}
                              </Divider>
                            </div>
                            {/* MachineName */}
                            <Form.Item
                              label="Machine Name"
                              name={[index, "machineName"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Required field!",
                                },
                              ]}
                            >
                              {/* <Select
                                disabled={disable}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder="Select the Machine"
                                getPopupContainer={(triggerNode) =>
                                  triggerNode.parentElement
                                }
                                onSelect={(val, values) =>
                                  handleMachineChange(val, values, index)
                                }
                              >
                                {machineData?.map((machine) => (
                                  <Option
                                    key={machine.machineID}
                                    value={machine.machineName}
                                    inputContainer={machine.inputContainer}
                                    machineId={machine.machineID}
                                    productType={machine?.productType}
                                  >
                                    {machine.machineName}
                                  </Option>
                                ))}
                              </Select>
                               */}
                              <Input
                                placeholder="Machine Name"
                                disabled={disable}
                              />
                            </Form.Item>
                            {/* MachineID */}
                            <Form.Item
                              style={{ display: "none" }}
                              label="Machine ID"
                              name={[index, "machineID"]}
                            >
                              <Input placeholder="Machine ID" disabled />
                            </Form.Item>
                            {/* ConcurrentOutput */}
                            <Form.Item
                              label="Concurrent Output"
                              name={[index, "concurrentOutput"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Required field!",
                                },
                                {
                                  whitespace: true,
                                  message: "Required field!",
                                },
                                ({ getFieldValue }) => ({
                                  validator(rule, value) {
                                    if (!value) {
                                      return Promise.resolve();
                                    }
                                    // eslint-disable-next-line no-restricted-globals
                                    if (isNaN(value)) {
                                      return Promise.reject(
                                        new Error(
                                          "Concurrent Output should be a number.",
                                        ),
                                      );
                                    }

                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                            >
                              <Input
                                placeholder="Enter Concurrent Output"
                                disabled={disable}
                              />
                            </Form.Item>
                            {/* Checkboxes */}
                            {inputType === "series" ? (
                              <Row className="form-row">
                                <Col span={11}>
                                  <Form.Item
                                    name={[index, "satisfyingConcurrentInput"]}
                                  >
                                    <Checkbox disabled={disable}>
                                      Satisfying ConcurrentInput
                                    </Checkbox>
                                  </Form.Item>
                                </Col>
                                <Col span={11}>
                                  <Form.Item
                                    name={[index, "satisfyingConcurrentOutput"]}
                                  >
                                    <Checkbox disabled={disable}>
                                      Satisfying Concurrent Output
                                    </Checkbox>
                                  </Form.Item>
                                </Col>
                              </Row>
                            ) : null}
                            {/* ConcurrentInput */}
                            {inputType === "series" ? (
                              <Form.Item
                                label="Concurrent Input"
                                name={[index, "seriesConcurrentInput"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Required field!",
                                  },
                                  {
                                    whitespace: true,
                                    message: "Required field!",
                                  },
                                  ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                      if (!value) {
                                        return Promise.resolve();
                                      }
                                      // eslint-disable-next-line no-restricted-globals
                                      if (isNaN(value)) {
                                        return Promise.reject(
                                          new Error(
                                            "Concurrent Input should be a number.",
                                          ),
                                        );
                                      }

                                      return Promise.resolve();
                                    },
                                  }),
                                ]}
                              >
                                <Input
                                  placeholder="Enter Concurrent Input"
                                  disabled={disable}
                                />
                              </Form.Item>
                            ) : null}
                            {/* Speed-PT */}
                            <Row className="form-row">
                              <Col span={11}>
                                <Form.Item
                                  label={
                                    inputType === "parallel"
                                      ? "speed"
                                      : inputType === "series"
                                      ? "processTime"
                                      : "speed"
                                  }
                                  name={
                                    inputType === "parallel"
                                      ? [index, "speed"]
                                      : inputType === "series"
                                      ? [index, "processTime"]
                                      : [index, "speed"]
                                  }
                                  tooltip={{
                                    title: "Relative to the output",
                                    icon: (
                                      <InfoCircleOutlined
                                        style={{ color: "white" }}
                                      />
                                    ),
                                    placement: "topLeft",
                                  }}
                                  rules={[
                                    {
                                      required: true,
                                      message: "",
                                    },
                                    {
                                      validator: async (rule, value) => {
                                        if (
                                          String(value).trim() === "" ||
                                          value === null ||
                                          value === undefined
                                        ) {
                                          throw new Error("Required field!");
                                        } else if (
                                          String(
                                            Number(String(value).trim()),
                                          ) === "NaN"
                                        ) {
                                          throw new Error(
                                            "Speed should be a double value",
                                          );
                                        } else if (value < 0) {
                                          throw new Error("Invalid value!");
                                        } else {
                                          const val = value.trim();
                                          const reg = /^\(?\d+\.\d+\)?/g;
                                          const matches = val.match(reg)
                                            ? val.match(reg)
                                            : [];
                                          if (matches.length === 0) {
                                            throw new Error(
                                              "Speed should be a double value",
                                            );
                                          }
                                        }
                                      },
                                    },
                                  ]}
                                >
                                  <Input
                                    disabled={disable}
                                    addonAfter={
                                      inputType === "parallel"
                                        ? "m/s"
                                        : inputType === "series"
                                        ? "process time"
                                        : null
                                    }
                                    placeholder={
                                      inputType === "parallel"
                                        ? "speed"
                                        : inputType === "series"
                                        ? "processTime"
                                        : "speed"
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={11}>
                                <Form.Item
                                  label="Setup Time"
                                  name={[index, "setupTime"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required Field!",
                                    },
                                    ({ getFieldValue }) => ({
                                      validator(rule, value) {
                                        if (
                                          value !== undefined &&
                                          String(value).trim() === "" &&
                                          !value
                                            .trim()
                                            .match(
                                              /^([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/gm,
                                            )
                                        ) {
                                          return Promise.reject(
                                            "Setup Time should be 'HH:MM:SS' format",
                                          );
                                        }
                                        if (
                                          String(value).trim() === "" ||
                                          value === null ||
                                          value === undefined
                                        ) {
                                          return Promise.reject(
                                            "Required Field!",
                                          );
                                        }
                                        return Promise.resolve();
                                      },
                                    }),
                                  ]}
                                >
                                  <Input disabled placeholder="HH:MM:SS" />
                                </Form.Item>
                              </Col>
                            </Row>
                            {/* No. of Operators */}
                            <Row className="form-row">
                              <Col span={11}>
                                <Form.Item
                                  name={[index, "numberOfOperators"]}
                                  label="Number of  Operators"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required field!",
                                    },
                                    {
                                      pattern: /^[0-9]\d*$/g,
                                      message:
                                        "Number of  Operators should be integer",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Number of Operators"
                                    type="number"
                                    disabled={disable}
                                    min={0}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={11}>
                                <Form.Item
                                  label="Number of  Helpers"
                                  name={[index, "numberOfHelpers"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required field!",
                                    },
                                    {
                                      pattern: /^[0-9]\d*$/g,
                                      message:
                                        "Number of  Helpers should be integer",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Number of Helpers"
                                    type="number"
                                    disabled={disable}
                                    min={0}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            {/* parallelConcurrentInput */}
                            {inputType === "parallel" ? (
                              <div>
                                <div
                                  style={{
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Divider
                                    plain
                                    type="horizontal"
                                    style={{
                                      display: "flex",
                                      flex: "1",
                                    }}
                                  >
                                    Enter Concurrent Inputs
                                  </Divider>
                                </div>
                                <Row
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "20px",
                                  }}
                                >
                                  <Col span={24}>
                                    <Form.List
                                      name={[index, "parallelConcurrentInput"]}
                                    >
                                      {(_fields, _) => {
                                        return (
                                          <div>
                                            {machineDetails[
                                              index
                                            ].parallelConcurrentInput.map(
                                              (v: any, _idx: any) => {
                                                return (
                                                  <div>
                                                    <Row>
                                                      <Col span={11}>
                                                        <Form.Item
                                                          label={`Input`}
                                                          name={[
                                                            _idx,
                                                            "inputValues",
                                                          ]}
                                                        >
                                                          <Input
                                                            disabled
                                                            defaultValue={`${v.inputValues}`}
                                                          />
                                                        </Form.Item>
                                                      </Col>
                                                      <Col span={11} offset={2}>
                                                        <Form.Item
                                                          label={`Concurrent Input`}
                                                          name={[
                                                            _idx,
                                                            "parallelConcurrentInput",
                                                          ]}
                                                        >
                                                          <Input
                                                            disabled={disable}
                                                            defaultValue={`${v.parallelConcurrentInput}`}
                                                            placeholder={`Concurrent Input for value.value`}
                                                          />
                                                        </Form.Item>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                );
                                              },
                                            )}
                                          </div>
                                        );
                                      }}
                                    </Form.List>
                                  </Col>
                                </Row>
                              </div>
                            ) : null}
                          </div>
                        ))}
                        {!disable && (
                          <>
                            {/* <Divider /> */}
                            <Form.Item>
                              <Button
                                block
                                type="dashed"
                                onClick={() => {
                                  add();
                                  console.log(machineData, "ooooppppkkkklll");
                                }}
                                style={{ width: "100%", marginTop: "20px" }}
                              >
                                <PlusOutlined /> Add Machine
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </div>
                    );
                  }}
                </Form.List>
              </>
            ) : (
              <>
                {nodeData?.data?.type === "Input" ? (
                  <>
                    <Row style={{ justifyContent: "space-between" }}>
                      <Col span={11}>
                        <Form.Item
                          label="Input Item"
                          name="inputItem"
                          rules={[
                            {
                              required: true,
                              message: "Required field!",
                            },
                            {
                              pattern: /^(?!\s*$).+/,
                              message: "Input Item is invalid",
                            },
                          ]}
                        >
                          <Select
                            disabled={disable}
                            placeholder="Select the Input Item"
                            onSelect={handleRawMaterialChange}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            // onChange={(value: any) => {
                            //   console.log("changing values --- > ", value);
                            //   value !== " " && setInputItemName(value);
                            // }}
                          >
                            {rawMaterials.map((input: any, index: any) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <Option
                                value={input.resourceName}
                                key={input?.id}
                                unit={input?.unitOfMeasurement}
                              >
                                {input.resourceName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={11}>
                        <Form.Item
                          label="Unit of Measurement"
                          name="unitOfMeasurement"
                          rules={[
                            {
                              required: true,
                              message: "Required field!",
                            },
                          ]}
                        >
                          <Input disabled placeholder="Select the Input Item" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      label="Length to Weight Ratio"
                      name="lengthToWeightRatio"
                      rules={[
                        {
                          required: true,
                          message: "Required field!",
                        },
                        {
                          whitespace: true,
                          message: "Required field!",
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value) {
                              return Promise.resolve();
                            }

                            // eslint-disable-next-line no-restricted-globals
                            if (isNaN(value)) {
                              return Promise.reject(
                                new Error("Ration should be a number."),
                              );
                            }

                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input
                        style={{ width: "100%" }}
                        placeholder="Enter Length to Weight Ratio"
                      />
                    </Form.Item>
                    <Form.Item
                      label="Description"
                      name="inputDescription"
                      rules={[
                        {
                          pattern: /^(?!\s*$).+/,
                          message: "Description is invalid",
                        },
                        {
                          max: 255,
                          message:
                            "The maximum length of 255 characters is reached! ",
                        },
                      ]}
                    >
                      <Input.TextArea
                        rows={2}
                        placeholder="Description"
                        disabled={disable}
                      />
                    </Form.Item>
                    {/* <Button
                      onClick={() => {
                        console.log("pppppppppp", nodes);
                      }}
                    >
                      check
                    </Button> */}
                    {/* {notTemplateCreation ? (
                    inputNodeTaskRatios?.length ? (
                      <div>
                        <Divider className="ratio_divider" plain>
                          <div className="tast_ratio">
                            Task Input Quantity Ratio
                          </div>
                        </Divider>
                        <Row style={{ justifyContent: "space-between" }}>
                          {inputNodeTaskRatios?.map(
                            (data: { name: any; ratio: any }, index: any) => {
                              return (
                                <Col span={11}>
                                  <Form.Item
                                    label={
                                      <div>
                                        {data?.name}{" "}
                                        <Tooltip title="Summation of the ratios should be 1">
                                          <InfoCircleOutlined />
                                        </Tooltip>
                                      </div>
                                    }
                                    name={data?.name}
                                  >
                                    <Input
                                      disabled
                                      defaultValue={data?.ratio}
                                    />
                                  </Form.Item>
                                </Col>
                              );
                            },
                          )}
                        </Row>
                      </div>
                    ) : null
                  ) : null}

                  {processData?.length ? (
                    <div>
                      <Divider className="ratio_divider" plain>
                        <div className="tast_ratio">
                          Task Input Quantity Ratio
                        </div>
                      </Divider>
                      <Row style={{ justifyContent: "space-between" }}>
                        {processData?.map((data: any, index: any) => {
                          return (
                            <Col span={11}>
                              <Form.Item
                                label={
                                  <div>
                                    {`${inputItemName}` +
                                      " Output " +
                                      `${index + 1}`}{" "}
                                    <Tooltip title="Summation of the ratios should be 1">
                                      <InfoCircleOutlined />
                                    </Tooltip>
                                  </div>
                                }
                                name={
                                  `${inputItemName}` +
                                  " Output " +
                                  `${index + 1}`
                                }
                                rules={[
                                  {
                                    required: true,
                                    message: "",
                                  },
                                  {
                                    validator: async (rule, value) => {
                                      if (
                                        String(value).trim() === "" ||
                                        String(value).trim() === undefined ||
                                        value === null ||
                                        value === undefined
                                      ) {
                                        throw new Error("Required field!");
                                      } else if (isNaN(value)) {
                                        throw new Error(
                                          "Ratio should be a number",
                                        );
                                      } else if (value > 1) {
                                        throw new Error(
                                          "Ratio can't be greater than one",
                                        );
                                      } else if (value <= 0) {
                                        throw new Error(
                                          "Ratio can't be less than or equal to zero",
                                        );
                                      }
                                    },
                                  },
                                ]}
                              >
                                <Input placeholder="Ex: 0.1 or 1" />
                              </Form.Item>
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                  ) : null} */}
                  </>
                ) : (
                  <div>
                    <Form.Item
                      label="Output Item"
                      name="output"
                      rules={[
                        {
                          required: true,
                          message: "Required field!",
                        },
                      ]}
                    >
                      <Input
                        disabled={disable}
                        placeholder="Enter the Output Item"
                      />
                    </Form.Item>
                  </div>
                )}
              </>
            )}
          </Form>
        )}
      </div>
    </Modal>
  );
};
export default NodeDataModalProject;
