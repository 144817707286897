import React, { FC } from "react";
import CableProductionFlowChart from "./CableProductionFlowChart";
import { CableFlowNodeContextWrapper } from "./CableFlowNodeContextWrapper";
import "./Styles.scss";
import MainLayout from "../../components/MainLayout";

const NewCableProcess: FC = (props) => {
  return (
    <MainLayout
      type="primary"
      // title="Create New Cable Process"
      OpenKey={["scheduling"]}
      selectedKey={["productionPlans"]}
    >
      <div className="cable-section">
        <CableFlowNodeContextWrapper newProcess edit={false} />
      </div>
    </MainLayout>
  );
};

export default NewCableProcess;
