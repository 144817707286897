import React, { FC } from "react";
import { Steps } from "antd";
import { ProjectOutlined } from "@ant-design/icons";
import {
  IoHourglassOutline,
  IoNewspaperOutline,
  IoTodayOutline,
} from "react-icons/io5";
import { useProject } from "./NewProjextContext";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import "./newProject.scss";
import EditProject from "./EditProject";
import { Container } from "../../constants/constants";

export const NewProjectOutlineNew: FC<{ id?: string }> = (props: { id?: string }) => {
  const { current } = useProject();
  const { Step } = Steps;
  const steps = [
    {
      title: "Project Outline",
      content: <StepOne id={props?.id} />,
      icon: <ProjectOutlined style={{ fontSize: "25px" }} />,
    },
    {
      title: `Machines and ${Container.NAME}s Selection`,
      content: <StepTwo edit height={450} />,
      icon: <IoHourglassOutline size={25} />,
    },
    {
      title: "Tentative Timelines",
      content: <StepThree />,
      icon: <IoTodayOutline size={25} />,
    },
    {
      title: "Project Schedule Overview",
      content: <EditProject type="stepFour" />,
      icon: <IoNewspaperOutline size={25} />,
    },
  ];

  return (
    <div className="inner-dark-section">
      <div
        className="newProject-title"
        style={{
          marginBottom: "30px",
          zIndex: 10,
        }}
      >
        <Steps current={current}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} icon={item.icon} />
          ))}
        </Steps>
      </div>
      <div className="steps-section">{steps[current].content}</div>
    </div>
  );
};
