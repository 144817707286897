import React, { FC } from "react";
import { AccessControlContextProvider } from "./AccessControlContext";
import AuthorizationDashboard from "./AuthorizationDashboard";

const AuthorizationDashboardWrapper: FC = () => {
  return (
    <AccessControlContextProvider>
      <AuthorizationDashboard />
    </AccessControlContextProvider>
  );
};
export default AuthorizationDashboardWrapper;
