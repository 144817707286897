/* eslint-disable no-param-reassign */
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useProject } from "./NewProjextContext";
import "./stepOne.scss";
import moment from "moment";
import { useConnection } from "../../Services/ApplicationContext";
import ConfirmationModal from "./ConfirmModal";
import { SERVICE_TYPE } from "../../constants/constants";

const priorityArray = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
];

const StepOne: React.FC<{ id?: string }> = (props: { id?: string }) => {
  const { get, post, delete: del } = useConnection();
  const [formOne] = Form.useForm();
  const {
    nextOne,
    current,
    setCurrent,
    setCurrentFinishedStep,
    setConfirmationModal,
    next,
    processTemplate,
    formData,
    setTemplateData,
    setContainerArray,
    setInputCutLengthArray,
    startTime,
    setStartTime,
    templateData,
    currentFormOneData,
    setCurrentFormOneData,
    previousFromStepTwo,
    setPreviousFromStepTwo,
    setRescheduleProjectData,
  } = useProject();

  const [projectToContinue, setProjectToContinue] = useState<boolean>(false);
  const [skeletonLoading, setSkeletonLoading] = useState(false);
  const [formDataOne, setFormDataOne] = useState<any>({
    alter: formData?.alter ? formData?.alter : null,
    deadline: formData?.deadline
      ? moment(formData?.deadline, "YYYY-MM-DD h:mm a")
      : null,
    minimumOrderQuantity: formData?.minimumOrderQuantity
      ? formData?.minimumOrderQuantity
      : null,
    priority: formData?.priority ? formData?.priority : null,
    prioritySchedules: formData?.prioritySchedules
      ? formData?.prioritySchedules
      : null,
    processTemplate: formData?.processTemplate
      ? formData?.processTemplate
      : null,
    projectIdentifier: formData?.projectIdentifier
      ? formData?.projectIdentifier
      : null,
    projectName: formData?.projectName ? formData?.projectName : null,
    quantity: formData?.quantity ? formData?.quantity : null,
    startTime: formData?.startTime
      ? moment(formData?.startTime, "YYYY-MM-DD h:mm a")
      : null,
  });
  const [pauseStatus, setPauseStatus] = useState<boolean>(false);
  const [processTemplateId, setProcessTemplateId] = useState<any>();
  const [stepOneLoading, setStepOneLoading] = useState<boolean>(false);
  const [
    confirmationModalStepOne,
    setConfirmationModalStepOne,
  ] = useState<boolean>(false);
  const [
    confirmationModalStepOneValue,
    setConfirmationModalStepOneValue,
  ] = useState<boolean>();
  const [stepOneFieldsOnChange, setStepOneFieldsOnChange] = useState<any>({});
  const [datainLocalStorage, setDatainLocalStorage] = useState<boolean>(false);
  const { Option } = Select;

  // let isProjectToContinue: boolean = false;
  const getProjectData = async () => {
    try {
      setSkeletonLoading(true);
      const results = await get(
        `scheduler/processScheduling/getProjectTemplate?id=${props?.id}`,
        undefined,
        SERVICE_TYPE.serviceType
      );
      formOne.setFieldsValue({
        projectName: results?.data.projectName,
        projectIdentifier: results?.data.projectIdentifier,
        minimumOrderQuantity: Object.values(
          results?.data.minimumOrderQuantity,
        )[0],
        quantity: Object.values(results?.data.quantities)[0],
      });

      setPauseStatus(
        results.data.pauseStatus ? results.data.pauseStatus : false,
      );
      setProcessTemplateId(results?.data?.processTemplate);
      setSkeletonLoading(false);
      console.log("container data next one", results.data.id.id);
      if (results.data.id.id) {
        const {
          containerArray,
          inputs,
          nodes,
          nodesCompleted,
          pausedTimes,
          projectsToReschedule,
          rawMaterial,
          rawMaterialCompleted,
          processFields,
          resourceDetails,
          resourceDetailsCompleted,
          resumedTimes,
          id,
          projectStatus,
          costs,
          machineDetails,
          costCalculation,
          userStartTime,
          saveAsRescheduled = false,
          displayName,
          endTime,
          completedCost,
          completedBobbins
        } = results?.data;
        setRescheduleProjectData({
          container: results.data.container,
          inputs,
          nodes,
          nodesCompleted,
          pausedTimes,
          projectsToReschedule,
          rawMaterial,
          rawMaterialCompleted,
          processFields,
          resourceDetails,
          resourceDetailsCompleted,
          resumedTimes,
          id,
          projectStatus,
          costs,
          machineDetails,
          costCalculation,
          userStartTime,
          saveAsRescheduled,
          displayName,
          endTime,
          completedCost,
          completedBobbins
        });
      }
    } catch (error: any) {
      console.log(error);
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
      setSkeletonLoading(false);
    }
  };

  const onTemplateSelect = (val: any) => {
    setInputCutLengthArray([]);
    setContainerArray([]);
    console.log(templateData, "processTemplateprocessTemplateprocessTemplate");
    let outputNode = "";

    for (const va in processTemplate.find((item: any) => item.processId === val)
      ?.minimumOrderQuantity) {
      outputNode = va;
    }

    formOne.setFieldsValue({
      minimumOrderQuantity: processTemplate.find(
        (item: any) => item.processId === val,
      )?.minimumOrderQuantity[outputNode],
    });
  };

  const getAllProductionTemplate = async (processId: any) => {
    try {
      const response = await get(
        `scheduler/productionPlans/getProcessTemplate?id=${processId}`,
        undefined,
        SERVICE_TYPE.serviceType
      );
      console.log(
        typeof response.data.processFields.processName,
        "response.data.processFields.processNameresponse.data.processFields.processName",
      );
      return response.data.processFields.processName;
    } catch (error: any) {
      console.log(error);
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
      return "";
    }
  };

  const handleStartDateChange = (val: any) => {
    console.log(
      moment(
        moment(formOne.getFieldsValue().deadline, "YYYY-MM-DD"),
        "YYYY-MM-DD",
      ) < moment(formOne.getFieldsValue().startTime, "YYYY-MM-DD"),
      moment(formOne.getFieldsValue().deadline, "YYYY-MM-DD"),
      "YYYY-MM-DD",
      "---change---------",
    );

    if (
      moment(
        moment(formOne.getFieldsValue().deadline, "YYYY-MM-DD"),
        "YYYY-MM-DD",
      ) < moment(formOne.getFieldsValue().startTime, "YYYY-MM-DD")
    ) {
      formOne.setFieldsValue({
        ...formOne.getFieldsValue(),
        deadline: undefined,
      });
    }
  };
  const stepOneValidation = async (values: any) => {
    const pName = values.projectName;
    const fPName = pName
      .split(" ")
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    values.projectName = fPName.trim();
    values.pauseStatus = pauseStatus;
    if (props?.id) {
      values.projectId = props?.id;
      console.log("container data next one", values);
    }
    // values.processTemplate = processTemplateId;
    setStepOneLoading(true);
    setCurrentFinishedStep(1);
    setCurrentFormOneData(values);
    try {
      const results = await post(
        `scheduler/processScheduling/checkProjectId`,
        {
          projectIdentifier: values.projectIdentifier,
        },
        undefined,
        SERVICE_TYPE.serviceType
      );
      if (results?.data?.unique) {
        nextOne(values);
      } else {
        nextOne(values);
        message.open({
          type: "error",
          content: "Error! The project identifier already exists",
          duration: 2,
        });
      }
    } catch (error: any) {
      console.log(error);
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
    } finally {
      setStepOneLoading(false);
    }
  };

  useEffect(() => {
    if (props.id !== undefined) {
      getProjectData();
    }
  }, [props.id]);

  useEffect(() => {
    let scheduleProjectProcessDetails 
    if(!props.id) {
      scheduleProjectProcessDetails = localStorage.getItem(
        "scheduleProjectProcessDetails",
      );
    }
    localStorage.removeItem("rescheduleProjectProcessDetails");
    if (scheduleProjectProcessDetails) {
      const contextData = JSON.parse(scheduleProjectProcessDetails);
      setDatainLocalStorage(true);
      if (previousFromStepTwo === true) {
        setProjectToContinue(true);
      }
      if (previousFromStepTwo !== true) {
        if (contextData?.current !== 0) {
          setConfirmationModalStepOne(true);
        }
      }
    }
  }, []);

  const closeConfirmationModal = (val: boolean) => {
    if (val === true) {
      console.log(val);
    } else if (val === false) {
      formOne.resetFields();
      setDatainLocalStorage(false);
      localStorage.removeItem("scheduleProjectProcessDetails");
      localStorage.removeItem("rescheduleProjectProcessDetails");
      setCurrent(0);
      setConfirmationModalStepOne(false);
      setProjectToContinue(false);
    }
  };
  return (
    <div style={{ width: "90%" }} className="stepOne-scheduling">
      <ConfirmationModal
        visible={confirmationModalStepOne}
        onClose={(val: boolean) => {
          closeConfirmationModal(val);
          setConfirmationModal(val);
        }}
      />
      <Form
        initialValues={formDataOne}
        layout="vertical"
        form={formOne}
        name="task"
        onFinish={(values: any) => {
          stepOneValidation(values);
        }}
        scrollToFirstError
        autoComplete="off"
      >
        <Divider orientation="center" style={{ marginTop: 0 }}>
          Project Outline
        </Divider>
        <Row className="form-row">
          <Col span={11}>
            <Form.Item
              label="Project Name"
              required
              name="projectName"
              rules={[
                {
                  required: true,
                  message: "Required field!",
                },
                {
                  pattern: /^(?!\s*$).+/,
                  message: "Required field!",
                },
              ]}
            >
              <Input name="projectName" placeholder="Enter Project Name" />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Project Identifier"
              name="projectIdentifier"
              rules={[
                {
                  required: true,
                  message: "Required field!",
                },
                {
                  pattern: /^(?!\s*$).+/,
                  message: "Required field!",
                },
              ]}
            >
              <Input placeholder="Enter ID" />
            </Form.Item>
          </Col>
        </Row>
        <Row className="form-row">
          {props.id ? (
            <Col span={11}>
              <Form.Item
                label="Production Template"
                name="processTemplate"
                rules={[
                  {
                    required: true,
                    message: "Required field!",
                  },
                ]}
              >
                <Select
                  placeholder="Select the Template"
                  onChange={onTemplateSelect}
                  showSearch
                  disabled
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {processTemplate
                    ?.filter(
                      (template: { processId: any }) =>
                        template?.processId === processTemplateId,
                    )
                    .map((template: any) => {
                      // formOne.setFields()
                      formOne.setFieldsValue({
                        processTemplate: processTemplateId,
                      });
                      return (
                        <Option
                          key={template?.processId}
                          value={template?.processId}
                        >
                          {template?.processName}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
          ) : (
            <Col span={11}>
              <Form.Item
                label="Production Template"
                name="processTemplate"
                rules={[
                  {
                    required: true,
                    message: "Required field!",
                  },
                ]}
              >
                <Select
                  placeholder="Select the Template"
                  onChange={onTemplateSelect}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {processTemplate?.map((template: any) => (
                    <Option
                      key={template?.processId}
                      value={template?.processId}
                    >
                      {template?.processName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}

          <Col span={11}>
            <Form.Item
              label="Minimum Order Length"
              name="minimumOrderQuantity"
              className="minimum-length"
              rules={[
                {
                  required: true,
                  message: "Required field!",
                },
              ]}
            >
              <Input
                type="number"
                addonAfter="m"
                placeholder="Select a template"
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="form-row">
          <Col span={11}>
            <Form.Item
              shouldUpdate
              label="Start Date"
              name="startTime"
              rules={[
                {
                  required: true,
                  message: "Required field!",
                },
              ]}
            >
              <DatePicker
                use12Hours
                format="YYYY-MM-DD h:mm a"
                onChange={(val) => {
                  handleStartDateChange(val);
                  setStartTime(moment(val, "YYYY-MM-DD h:mm a"));
                }}
                disabledDate={(current) => {
                  return current && current < moment(moment(), "YYYY-MM-DD ");
                }}
                style={{ width: "100%" }}
                placeholder="Select the Start Date"
                showTime
                showNow={false}
                showToday={false}
              />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Deadline"
              name="deadline"
              rules={[
                {
                  required: true,
                  message: "Required field!",
                },
              ]}
            >
              <DatePicker
                use12Hours
                format="YYYY-MM-DD h:mm a"
                disabled={!datainLocalStorage ? !startTime && true : false}
                disabledDate={(current) => {
                  return (
                    current &&
                    current <
                      moment(formOne.getFieldsValue().startTime, "YYYY-MM-DD")
                  );
                }}
                style={{ width: "100%" }}
                placeholder="Select the Deadline"
                showTime
                showNow={false}
                showToday={false}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="form-row">
          <Col span={11}>
            <Form.Item
              label={
                <div>
                  Priority{" "}
                  <Tooltip title="1 Indicates the highest priority">
                    <InfoCircleOutlined />
                  </Tooltip>
                </div>
              }
              name="priority"
              rules={[
                {
                  required: true,
                  message: "Required field!",
                },
              ]}
            >
              <Select placeholder="Select the Priority">
                {priorityArray?.map(
                  (priority: { value: number; label: number }) => (
                    <Option key={priority.value} value={priority.value}>
                      {priority.label}
                    </Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Col>
          {pauseStatus ? (
            <Col span={11}>
              <Form.Item
                label={
                  <div>
                    Quantity{" "}
                    <Tooltip title="Quantity cannot be less than minimum cut length!">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                }
                className="quantity"
                name="quantity"
              >
                <Input disabled />
              </Form.Item>
            </Col>
          ) : (
            <Col span={11}>
              <Form.Item
                label={
                  <div>
                    Quantity{" "}
                    <Tooltip title="Quantity cannot be less than minimum cut length!">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                }
                className="quantity"
                name="quantity"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        value === null ||
                        value === " " ||
                        value === undefined
                      ) {
                        return Promise.reject(new Error("Required field!"));
                      }
                      if (
                        (value !== null ||
                          value !== "" ||
                          value !== undefined) &&
                        Number(getFieldValue("minimumOrderQuantity")) >
                          Number(value)
                      ) {
                        return Promise.reject(new Error("Invalid quantity!"));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  type="number"
                  addonAfter="m"
                  name="quantity"
                  placeholder="Enter the Quantity"
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item name="alter" valuePropName="checked">
              <Checkbox>Enable altering current schedule </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="center" style={{ color: "white", marginTop: 25 }}>
          Current Schedules
        </Divider>
        <Row>
          <Col span={24}>
            <Form.Item
              name="prioritySchedules"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Radio.Group>
                <Radio value="lowerPrioritySchedules">
                  Alter lower priority schedules
                </Radio>
                <Radio value="equalAndLowerPrioritySchedules">
                  Alter equal and lower priority schedules
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Button
          type="primary"
          onClick={() => formOne.submit()}
          loading={stepOneLoading}
        >
          Next
        </Button>
      </Form>
    </div>
  );
};

export default StepOne;
