import React, { FC } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { useAuthZ } from "./Services/AuthorizationContext";
import LoadingPage from "./pages/LoginPage/LoadingPage";
import UnAuthorized from "./pages/LoginPage/UnAuthorized";

export interface IPrivateRoute extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  feature?: string;
}
const PrivateRoute: FC<IPrivateRoute> = (props: IPrivateRoute) => {
  const { component: Component, feature, ...rest } = props;
  // const RProps = { ...rest };
  // delete RProps.feature;
  const { isAuthenticated, canEdit, permissions } = useAuthZ();
  if (feature) {
    if (isAuthenticated()) {
      return (
        <Route
          {...rest}
          render={(routeProps) =>
            // eslint-disable-next-line no-nested-ternary
            permissions ? (
              permissions?.features[`${feature}`] ? (
                <Component {...routeProps} />
              ) : (
                <UnAuthorized />
              )
            ) : (
              <LoadingPage />
            )
          }
        />
      );
    }
    return (
      <Route
        {...rest}
        render={(routeProps) => (
          <Redirect
            to={{
              pathname: "/",
              state: { from: routeProps.location },
            }}
          />
        )}
      />
    );
  }
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        isAuthenticated() ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
