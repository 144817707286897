import React, { FC } from "react";
import "./NotificationsTable.scss";
import NotificationsTable from "./NotificationsTable";
import MainLayout from "../../components/MainLayout";

const Machines: FC = () => {
  return (
    <MainLayout
      type="primary"
      // title="Manage Machines"
      // OpenKey={["settings"]}
      // selectedKey={["notifications"]}
    >
      <div className="addMachines-form-container">
        <NotificationsTable />
      </div>
    </MainLayout>
  );
};

export default Machines;
