import React, { FC, useEffect, useRef, useState } from "react";
import "./Styles.scss";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import {
  Canvas,
  CanvasRef,
  EdgeData,
  Label,
  Node,
  NodeData,
  removeAndUpsertNodes,
  UndoRedoEvent,
  useUndo,
} from "reaflow";
import { Button, message, Modal, Tooltip } from "antd";
import {
  MoreOutlined,
  PlusOutlined,
  MinusOutlined,
  ExpandOutlined,
  UndoOutlined,
  DragOutlined,
  RedoOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  MinusCircleFilled,
  ExclamationCircleFilled,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import classNames from "classnames";
import MainLayout from "../../components/MainLayout";
import { useConnection } from "../../Services/ApplicationContext";
import NodeDataModal from "./NodeDataModal";
import PopupIcon from "../../assets/popupIcon.png";
import { Col, Row } from "antd/es/grid";
import NodeDataModalProject from "./NodeDataModalProject";
import { SERVICE_TYPE } from "../../constants/constants";

const LoadCableProcess: FC = () => {
  let id: any = {};
  id = useParams();
  const { get } = useConnection();
  const history = useHistory();

  const [processData, setProcessData] = useState<any>();
  const [nodes, setNodes] = useState<any>();
  const [edges, setEdges] = useState<any>();
  const [selectedNode, setSelectedNode] = useState<any>({});
  const [selectedNodeType, setSelectedNodeType] = useState<string>("Task");
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [dummyState, setDummyState] = useState<string>("");
  const canvasRef = useRef<CanvasRef | any>(null);

  const getProcessData = async () => {
    console.log(id);
    try {
      const results = await get(
        `scheduler/productionPlans/getProcessTemplate?id=${id.id}`,
        undefined,
        SERVICE_TYPE.serviceType
      );
      console.log(results.data);
      setEdges(results.data?.edges);
      // const nodesTemp = extractNodeData(results.data);
      setNodes(results.data?.nodes);
      setProcessData(results.data.processFields);
    } catch (error: any) {
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
    }
  };

  useEffect(() => {
    if (id?.id) {
      getProcessData();
    }
  }, [id]);

  const nodeDataSelect = (nodeData: NodeData) => {
    console.log(nodeData, "nodeDatanodeDatanodeDatanodeData");
    if (nodeData?.data?.props) {
      setSelectedNode(nodeData);
      setModalVisible(true);
    } else {
      message.open({
        type: "error",
        content: "No Properties",
        duration: 2,
      });
    }
  };

  const changeTextField = (field: string) => {
    const text = field?.replace(/([a-z])([A-Z])/g, "$1 $2").trim();
    const firstLetter = text.substr(0, 1);
    return firstLetter.toUpperCase() + text.substr(1);
  };

  const nodeColour = (node: any) => {
    switch (node?.properties?.data?.type) {
      case "Task":
        return "#352f44";
      case "Input":
        return "#3a4750";
      case "Output":
        return "#414141";
      case "Block":
        return "#252A38";
      default:
        return "#252A38";
    }
  };

  return (
    <MainLayout
      type="primary"
      OpenKey={["scheduling"]}
      selectedKey={["productionPlans"]}
    >
      <div className="inner-dark-section">
        <Row>
          <Col span={5}>
            <div className="back-btn">
              <Button
                className="add-button"
                type="primary"
                style={{
                  // width: 150,
                  height: "45px",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                }}
                icon={<ArrowLeftOutlined />}
                onClick={() => history.push("/scheduling/table")}
              >
                Back
              </Button>
            </div>
          </Col>
          <Col span={10}>
            <div
              style={{
                backgroundColor: "#242424",
                justifyContent: "center",
                display: "flex",
                padding: "9px",
                borderRadius: "5px",
              }}
            >
              <span
                style={{
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  color: "rgba(255, 255, 255, 0.5)",
                }}
              >
                {processData?.processName}
              </span>
            </div>
          </Col>
        </Row>

        <div className="cable-section">
          {/* <Modal */}
          {/*  title={`${selectedNodeType} Properties`} */}
          {/*  visible={modalVisible} */}
          {/*  footer={[ */}
          {/*    <Button onClick={() => setModalVisible(false)}>Close</Button>, */}
          {/*  ]} */}
          {/*  onCancel={() => setModalVisible(false)} */}
          {/* > */}
          {/*  {Object.keys(selectedNode).map((keyName, i) => ( */}
          {/*    <li className="travelcompany-input" key={i}> */}
          {/*      <span className="input-label"> */}
          {/*        {changeTextField(keyName)} : {selectedNode[keyName]}{" "} */}
          {/*      </span> */}
          {/*    </li> */}
          {/*  ))} */}
          {/* </Modal> */}
          {/* <NodeDataModal
            visible={modalVisible}
            update={false}
            disable
            nodeData={selectedNode}
            onClose={() => setModalVisible(false)}
            notTemplateCreation={true}
          /> */}
          <NodeDataModalProject
            update={false}
            visible={modalVisible}
            disable
            nodeData={selectedNode}
            onClose={() => setModalVisible(false)}
            notTemplateCreation={true}
          />
          <div
            className="load-right"
            style={{
              backgroundColor: "#242424",
              marginTop: "25px",
              borderRadius: "10px",
            }}
          >
            <div
              style={{
                zIndex: 9,
                position: "absolute",
                right: 55,
                background: "rgba(0, 0, 0, .5)",
                padding: 10,
                color: "white",
              }}
            >
              <Tooltip title="Zoom In" placement="left">
                <PlusOutlined
                  style={{ display: "block", width: "100%", margin: "5px 0" }}
                  onClick={() => canvasRef.current.zoomIn()}
                />
              </Tooltip>
              <Tooltip title="Zoom Out" placement="left">
                <MinusOutlined
                  style={{ display: "block", width: "100%", margin: "5px 50" }}
                  onClick={() => canvasRef.current.zoomOut()}
                />
              </Tooltip>
              <Tooltip title="Fit" placement="left">
                <ExpandOutlined
                  style={{ display: "block", width: "100%", margin: "5px 0" }}
                  onClick={() => canvasRef.current.fitCanvas()}
                />
              </Tooltip>
            </div>
            <Canvas
              fit
              disabled
              center
              direction="RIGHT"
              // width={1200} // Use small canvas dimensions to make sure the nodes appear on screen immediately
              maxHeight={600}
              ref={canvasRef}
              nodes={nodes}
              edges={edges}
              node={(n) => (
                <Node
                  {...n}
                  style={{
                    borderRadius: 30,
                    fill: nodeColour(n),
                  }}
                  label={
                    <Label
                      style={{
                        visibility: "hidden",
                      }}
                    />
                  }
                >
                  {(event) => (
                    <foreignObject
                      height={event.height - 20}
                      width={event.width - 20}
                      x={15}
                      y={5}
                    >
                      <div
                        style={{
                          flexDirection: "row",
                          justifyContent: "center",
                          alignSelf: "center",
                          display: "flex",
                          cursor: "pointer",
                          // backgroundColor: "red",
                        }}
                      >
                        <div style={{ padding: 1, alignSelf: "flex-start" }}>
                          {event?.node?.data?.type !== "Block" && (
                            <div className="node-custom">
                              <img
                                src={PopupIcon}
                                className="popup-img"
                                onClick={() => {
                                  nodeDataSelect(event.node);
                                }}
                              />
                              {event?.node?.data?.props
                                ? event?.node?.data?.props?.taskName ||
                                  event?.node?.data?.props?.inputItem ||
                                  event?.node?.data?.props?.output
                                : event?.node?.data?.type}
                              {/* <div className="validate-icon"> */}
                              {/*  {event?.node?.data?.props ? ( */}
                              {/*    <CheckCircleFilled */}
                              {/*      style={{ color: "#79d70f" }} */}
                              {/*    /> */}
                              {/*  ) : ( */}
                              {/*    <MinusCircleFilled */}
                              {/*      style={{ color: "#ec4646" }} */}
                              {/*    /> */}
                              {/*  )} */}
                              {/* </div> */}
                            </div>
                          )}
                        </div>
                      </div>
                    </foreignObject>
                  )}
                </Node>
              )}
            />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default LoadCableProcess;
