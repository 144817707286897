/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable radix */
import React, { FC, useEffect } from "react";
import { Modal, Button, Form, Input, InputNumber, Col, Row } from "antd";
import InfoCircleOutlined from "@ant-design/icons/InfoCircleOutlined";
import "../modalPopUp.scss";

export interface CableProcessModalProps {
  visible: boolean;
  update: boolean;
  processData: any;
  onClose: (val?: any) => void;
  loading: boolean;
  copy?: boolean;
}

const CableProcessModal: FC<CableProcessModalProps> = (
  props: CableProcessModalProps,
) => {
  const { visible, onClose, processData, update, loading, copy } = props;
  const [form] = Form.useForm();

  const onSubmit = async (values: any): Promise<void> => {
    const pName = values.processName;
    const fPName = pName
      .split(" ")
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    values.processName = fPName.trim();
    // console.log(values, fPName);
    onClose(values);
    form.resetFields();
  };

  useEffect(() => {
    console.log(processData,"=======================",copy);
    if (copy) {
      form.resetFields();
    } else {
      processData && form.setFieldsValue({
        // ...processData,
        processName: processData.processName,
        minimumOrderQuantity: processData && Object.values(processData?.minimumOrderQuantity)[0]
      });
    }
  }, [processData,visible]);

  return (
    <Modal
      centered
      title="Process Properties"
      visible={visible}
      className="general-modal-popup"
      width="60%"
      onCancel={() => {
        onClose(false);
        form.resetFields();
      }}
      footer={[
        <div className="modal-field-button">
          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={loading}
          >
            {update ? (copy ? "Save" : "Update") : "Save"}
          </Button>
          <Button
            type="primary"
            danger
            onClick={() => {
              onClose(false);
              form.resetFields();
            }}
          >
            Close
          </Button>
        </div>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        name="process"
        hideRequiredMark={false}
        onFinish={onSubmit}
        scrollToFirstError
        // initialValues={copy ? null : {processName: processData.processName, minimumOrderQuantity: Object.values(processData?.minimumOrderQuantity)[0] }}
        autoComplete="off"
        className="display"
      >
        <Form.Item
          label="Process Name"
          name="processName"
          rules={[
            {
              required: true,
              message: "Required field!",
            },
            {
              whitespace: true,
              message: "Required field!",
            },
          ]}
        >
          <Input placeholder="Enter the Process Name" />
        </Form.Item>
        <Row style={{ alignItems: "center", display: "flex" }}>
          <Col span={22}>
            <Form.Item
              label="Minimum Order Length"
              name="minimumOrderQuantity"
              tooltip={{
                title: "Minimum length of the final product to be produced",
                icon: <InfoCircleOutlined style={{ color: "white" }} />,
                placement: "topLeft",
              }}
              rules={[
                {
                  required: true,
                  message: "Required field!",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value) {
                      return Promise.resolve();
                    }

                    if (!Number.isInteger(Number(value)) || value < 0) {
                      return Promise.reject(new Error("Invalid value!"));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                type="number"
                placeholder="Enter the Minimum Order Length"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <span
              style={{
                color: "#e4e4e4",
                padding: "6px 10px 0px",
                fontFamily: "Poppins",
                fontSize: "22px",
                top: 10,
                position: "relative",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              m
            </span>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
export default CableProcessModal;
