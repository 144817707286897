/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
import React, { useState, useEffect, FC } from "react";
import {
  Table,
  Button,
  Popconfirm,
  Form,
  Space,
  Empty,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  Modal,
  Tag,
  message,
  Radio,
  Switch,
} from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import {
  IoCreateOutline,
  IoSearch,
  IoTrashSharp,
  IoWarning,
} from "react-icons/io5";
import { DateTime } from "luxon";
import { useConnection } from "../../Services/ApplicationContext";
import { Actions, Authorizer } from "../../Services/AuthorizationContext";
import {
  ILocation,
  IMachine,
  IMachineDetails,
  IMachineRow,
} from "../../definetions/MachineModel";
import "./NotificationsTable.scss";
import "../tableStyles.scss";
import "../pagination.scss";
import "../Projects/projects.scss";
import { NotificationItemProps } from "../../components/NotificationItem/NotificationItem";
import moment from "moment";
import { useUser } from "../../Services/UserContext";
import { useNotification } from "../../Services/NotificationContext";
import { SERVICE_TYPE } from "../../constants/constants";

const { Option } = Select;
const { RangePicker } = DatePicker;

const NotificationsTable: FC = () => {
  const [form] = Form.useForm();
  const { userInfo, removeUserInfo } = useUser();
  const {
    readToggleClickedCount,
    setReadToggleClickedCount,
    markAllRead,
    getUnreadNotificationsCount,
  } = useNotification();
  const { get, delete: del, post, put } = useConnection();
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedRow, setSelectedRow] = useState<IMachineRow>();
  const [total, setTotal] = useState<number>(0);
  const [
    addMachinesModalVisible,
    setAddMachinesModalVisible,
  ] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [pageSize, setPageSize] = useState<any>(10);
  const [sortOrder, setSortOrder] = useState<string>("desc");
  const [sortField, setSortField] = useState<string>("createdTime");
  const [notificationType, setNotificationType] = useState<string>("all");
  const [startDate, setStartDate] = useState<any>("");
  const [endDate, setEndDate] = useState<any>("");

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalDetails, setModalDetails] = useState<any>();
  const [
    modalDetailsExtraContent,
    setModalDetailsExtraContent,
  ] = useState<any>();
  const [notificationsData, setNotificationsData] = useState<any[]>([]);

  const getAllNotificationsRequestParams = () => {
    return {
      page: currentPage - 1,
      size: pageSize,
      notificationType:
        notificationType !== undefined ||
        notificationType !== "" ||
        notificationType !== "all"
          ? notificationType
          : undefined,
      startTime: startDate !== "" ? parseInt(startDate) / 1000 : undefined,
      endTime: endDate !== "" ? parseInt(endDate) / 1000 : undefined,
      orderBy: sortField !== "" ? sortField : undefined,
      order: sortOrder !== "" ? sortOrder : undefined,
    };
  };

  const getNotificationsData = async () => {
    setLoading(true);
    try {
      const allNotificationsResult = await post(
        "notification/getAllNotifications",
        getAllNotificationsRequestParams(),
        undefined,
        SERVICE_TYPE.serviceType,
      );
      setTotal(allNotificationsResult?.data?.total); 
      setNotificationsData(allNotificationsResult?.data?.notificationsList);
    } catch (error: any) {
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
    } finally {
      setLoading(false);
    }
  };

  const visibleModal = (val: any) => {
    setModalVisible(true);
  };

  const projectStatusTextColour = (status: string) => {
    switch (status) {
      case "Medium":
        return "#cce6ff";
      case "Low":
        return "#eafaea";
      case "High":
        return "#ffd6cc";
      default:
        return "#cce6ff";
    }
  };

  const projectStatusBackGroundColour = (status: string) => {
    switch (status) {
      case "Medium":
        return "#4d94ff";
      case "Low":
        return "#85e085";
      case "High":
        return "#ff6666";
      default:
        return "#4d94ff";
    }
  };

  const updateReadStatus = async (val: boolean, id: any, key: any) => {
    setLoading(true);
    let result;
    const updateReadStatusParams = () => {
      return {
        id: id,
        key: key,
        read: val,
      };
    };
    try {
      result = await put(
        "notification/updateNotificationReadStatus",
        updateReadStatusParams(),
        undefined,
        SERVICE_TYPE.serviceType,
      );
      if (result.status === 200) {
        console.log(result.message);
      } else {
        message.open({
          type: "error",
          content: result.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      }
    } catch (e: any) {
      message.open({
        type: "error",
        content: e.message,
        duration: 2,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
    } finally {
      getUnreadNotificationsCount();
      getNotificationsData();
      setLoading(false);
    }
  };

  const columns: any = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "content",
      key: "content",
      render: (_: any, record: any) => {
        return (
          <span onClick={visibleModal}>
            {record?.content?.length > 31
              ? record?.content?.slice(0, 30) + "..."
              : record?.content?.trim().length > 0
              ? record?.content
              : "-"}
          </span>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text: any, record: any) => {
        // console.log(record);
        return (
          <Space size="middle">
            {record?.type ? (
              <Tag
                color={projectStatusTextColour(record?.attentionLevel)}
                className="table-status"
                style={{
                  backgroundColor: projectStatusBackGroundColour(
                    record?.attentionLevel,
                  ),
                  color: projectStatusTextColour(record?.attentionLevel),
                  width: record?.type === "Upcoming Maintenance" ? "180px" : "110px"
                }}
              >
                <div className="row">
                  <div>{"\u2B24"}</div>
                  <div className="status_text">
                    {record?.type.toUpperCase()}
                  </div>
                </div>
              </Tag>
            ) : null}
          </Space>
        );
      },
    },
    {
      title: "Created Time",
      dataIndex: "createdTime",
      key: "createdTime",
      sorter: true,
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend", null],
      render: (text: any, record: any) => {
        return (
          <Space size="middle">
            {moment.unix(record.createdTime).format("DD:MM:YYYY, hh:mm:ss a")}
          </Space>
        );
      },
    },
    {
      title: "Mark as read/unread",
      dataIndex: "operation",
      fixed: "right",
      render: (_: any, record: any) => {
        return (
          <Space size="middle">
            <Switch
              size="small"
              defaultChecked={record?.read}
              onChange={(val: boolean) => {
                updateReadStatus(val, record?.id, record?.key);
                console.log("read toggle - > ", readToggleClickedCount + 1);
                setReadToggleClickedCount(readToggleClickedCount + 1);
              }}
            />
          </Space>
        );
      },
    },
  ];

  const handleChange = (val: any, sorter: any) => {
    console.log(
      " ------ val table machine --------",
      val,
      "------sorter------",
      sorter,
    );
    if (sorter.order === "ascend") {
      setSortOrder("asc");
    } else if (sorter.order === "descend") {
      setSortOrder("desc");
    } else if (sorter.order === undefined) {
      setSortOrder("");
    }
    if (sorter.columnKey !== undefined) {
      setSortField(sorter.columnKey);
    } else {
      setSortField("");
    }
    val?.current && setCurrentPage(val?.current);
    val?.pageSize && setPageSize(val?.pageSize);
  };

  useEffect(() => {
    if (addMachinesModalVisible === true || selectedRow !== undefined) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [addMachinesModalVisible, selectedRow]);

  useEffect(() => {
    getNotificationsData();
  }, [
    currentPage,
    pageSize,
    notificationType,
    startDate,
    endDate,
    sortField,
    sortOrder,
    readToggleClickedCount,
    markAllRead,
  ]);

  const onChangeRange = (dateMoment: any, dateString: any) => {
    console.log(dateMoment);
    setCurrentPage(1);
    if (dateMoment !== null && dateMoment[1] !== null) {
      setStartDate(
        Date.parse(String(moment(dateMoment[0]?._d).startOf("day"))),
      );
      setEndDate(Date.parse(String(moment(dateMoment[1]?._d).endOf("day"))));
    } else {
      setStartDate("");
      setEndDate("");
    }
  };

  return (
    <div>
      <Modal
        style={{ top: 150 }}
        bodyStyle={{ backgroundColor: "#1B1B1B", borderRadius: "5px" }}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <p
          style={{
            color: "#ffffff",
            fontWeight: "bold",
            fontSize: 18,
            fontFamily: "Poppins",
          }}
        >
          {modalDetails?.title}
        </p>
        <p style={{ color: "#ffffff", fontFamily: "Poppins" }}>
          {modalDetails?.content?.trim().length > 0
            ? modalDetails?.content
            : "No description"}
        </p>
        {modalDetails?.extraContent && (
          <div dangerouslySetInnerHTML={{ __html: modalDetailsExtraContent }} />
        )}
        {modalDetails?.attachedUrl && modalDetails?.fileName && (
          <div>
            <a href={modalDetails?.attachedUrl}>{modalDetails?.fileName}</a>
          </div>
        )}
      </Modal>
      <div>
        <div
          className="inner-dark-section"
          style={{
            display: "flex",
            flexDirection: "row",
            // justifyContent: "space-between",
            // alignItems: "center",
            width: "100%",
            marginBottom: "30px",
            position: "sticky",
            top: "0",
            backgroundColor: "#1b1b1b",
            zIndex: 20,
            paddingTop: "30px",
            paddingBottom: "30px",
          }}
        >
          <div className="search-section">
            <Row gutter={[15, 30]}>
              <Col lg={10} md={12} sm={12} xs={24}>
                <div className="row">
                  <div className="search_filter_lables">
                    Notification Type:{" "}
                  </div>
                  <Select
                    defaultValue={notificationType}
                    style={{ width: "250px", marginLeft: "2%" }}
                    onChange={(value: string) => {
                      setNotificationType(value);
                    }}
                  >
                    <Option value="all">All</Option>
                    <Option value="alert">Alert</Option>
                    <Option value="Audit Report">Audit Report</Option>
                    <Option value="info">Info</Option>
                    <Option value="Idle Report">Idle Report</Option>
                    <Option value="reminders">Reminders</Option>
                    <Option value="success">Success</Option>
                    <Option value="Upcoming Maintenance">Upcoming Maintenance</Option>
                  </Select>
                </div>
              </Col>
              <Col lg={12} md={12} sm={12} xs={24}>
                <div className="clear-circle row center">
                  <div className="search_filter_lables">Created Time: </div>
                  <RangePicker
                    format="DD:MM:YYYY"
                    allowEmpty={[true, true]}
                    style={{ width: "300px", marginLeft: "2%" }}
                    onChange={onChangeRange}
                  />
                </div>
              </Col>
              <Col
                lg={0}
                md={12}
                sm={12}
                xs={24}
                style={{ justifyContent: "flex-end" }}
              ></Col>
            </Row>
          </div>
        </div>
        <Form form={form} component={false}>
          <div
            className="general-table general-pagination"
            style={{ marginTop: "20px" }}
          >
            <Table
              // className="components-table-demo-nested"
              columns={columns}
              dataSource={notificationsData}
              loading={loading}
              pagination={{
                current: currentPage,
                pageSize,
                total,
                showQuickJumper: true,
                showSizeChanger: true,
              }}
              onChange={(val: any, filter: any, sorter: any) => {
                handleChange(val, sorter);
              }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    setModalDetails(record);
                    let Str = record?.extraContent;
                    if (Str && Str !== "") {
                      Str = Str?.replace("\\", "");
                      let count =
                        (Str.match(/tr/g) || []).length +
                        (Str.match(/th/g) || []).length +
                        (Str.match(/td/g) || []).length +
                        (Str.match(/p/g) || []).length;
                      for (let index = 0; index < count; index++) {
                        let includes = Str.includes("\\");
                        if (includes) {
                          Str = Str.replace("\\", "");
                        }
                      }
                      setModalDetailsExtraContent(Str);
                    }
                  },
                };
              }}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                      total === 0 && notificationsData.length === 0
                        ? `No Notifications available`
                        : "No data"
                    }
                  />
                ),
              }}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default NotificationsTable;
