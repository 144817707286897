import React, { FC } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ConfigProvider } from "antd";
import "./App.less";
import "./pages/NewProject/styles.css";
import en_GB from "antd/lib/locale-provider/en_GB";
import moment from "moment";
import axios from "axios";
import PrivateRoute from "./PrivateRoute";
import LoginIPS from "./pages/LoginPage/LoginIPS";
import UserManagement from "./pages/UserManagement/UserManagement";
import UpdateUser from "./pages/UserManagement/UpdateUser";
import UtilizationReport from "./pages/UtilizationReport/UtilizationReport";
import Locations from "./pages/Locations/Locations";
import Machines from "./pages/Machines/Machines";
import Notifications from "./pages/AllNotifications/Notifications";
import Devices from "./pages/Devices/Devices";
import { ApplicationContextProvider } from "./Services/ApplicationContext";
import { UserContextProvider } from "./Services/UserContext";
import { NotificationContextProvider } from "./Services/NotificationContext";
import { AuthorizationContextProvider } from "./Services/AuthorizationContext";
import IoTStatus from "./pages/IoTStatus/IoTStatus";
import ArchivedReports from "./pages/ArchivedReports/ArchivedReports";
import UnAuthorized from "./pages/LoginPage/UnAuthorized";
import CableProcess from "./pages/CableProduction/CableProcess";
import NewCableProcess from "./pages/CableProduction/CableProduction";
import LoadCableProcess from "./pages/CableProduction/LoadCableProcess";
import UpdateCableProcess from "./pages/CableProduction/UpdateCableProcess";
import LoadingPage from "./pages/LoginPage/LoadingPage";
import OverallReport from "./pages/OverallReport/OverallReport";
import RecoverThroughEmail from "./pages/RecoverThroughEmail/RecoverThroughEmail";
import RecoverThroughMobile from "./pages/RecoverThroughMobile/RecoverThroughMobile";
import EnterOTP from "./pages/EnterOTP/EnterOTP";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import RecoverPwd from "./pages/Recover/RecoverAcc";

import "moment/locale/en-nz";
import "moment/locale/en-gb";
import AuthorizationDashboardWrapper from "./pages/Authorization/AuthorizationDashboardWrapper";
import ConfigureAlert from "./pages/AlertConfigure/AlertConfigure";
import NewProject from "./pages/NewProject/NewProject";
import TentativeProjectTimelines from "./pages/NewProject/TentativeProjectTimelines";
import BaseSchedulePage from "./pages/NewProject/BaseSchedulePage";
import ResourcesManagement from "./pages/RawMaterialManagement/ResourcesManagement";
import Projects from "./pages/Projects/Projects";
import Calender from "./pages/Calender/Calender";
import { NewProjectWrapper } from "./pages/Projects/NewProjectWrapper";
import ViewProject from "./pages/Projects/ViewProject";
import { ViewProjectWrapper } from "./pages/Projects/ViewProjectWrapper";
import { EditProjectWrapper } from "./pages/Projects/EditProjectWrapper";
import CopyCableProcess from "./pages/CableProduction/CopyCableProcess";
import Maintenance from "./pages/Maintenance/Maintenance";
import ViewMachineWrapper from "./pages/Maintenance/ViewMachineWrapper";

moment.locale("en-nz");
const App: FC = () => {
  return (
    <ApplicationContextProvider
      serverlessSideUrl={process.env.REACT_APP_SERVICES!}
      serverSideUrl={process.env.REACT_APP_BACKEND!}
      serverlessLoadBalancer={process.env.REACT_APP_LOAD_BALANCER_SERVICES!}
    >
      <AuthorizationContextProvider>
        <ConfigProvider locale={en_GB}>
          <UserContextProvider>
            {/* <NotificationContextProvider> */}
              <Router>
                <PrivateRoute
                  path="/iot-status"
                  component={IoTStatus}
                  feature="2"
                />
                <PrivateRoute
                  path="/settings/user-management"
                  component={UserManagement}
                  feature="29"
                />
                <PrivateRoute
                  path="/settings/authorization"
                  component={AuthorizationDashboardWrapper}
                  feature="26"
                />
                <PrivateRoute
                  path="/settings/notifications"
                  component={Notifications}
                  feature="85"
                />
                {/* <PrivateRoute path="/createuser" component={CreateUserLayout}/> */}
                <PrivateRoute
                  path="/report/utilization"
                  component={UtilizationReport}
                  feature="8"
                />
                <PrivateRoute
                  path="/report/archived-reports"
                  component={ArchivedReports}
                  feature="27"
                />
                <PrivateRoute
                  path="/report/overall-report"
                  component={OverallReport}
                  feature="28"
                />
                <PrivateRoute
                  path="/scheduling/new"
                  component={NewCableProcess}
                />
                <PrivateRoute
                  path="/scheduling/table"
                  component={CableProcess}
                />
                <PrivateRoute
                  path="/scheduling/process/:id"
                  component={LoadCableProcess}
                />
                <PrivateRoute
                  path="/scheduling/update/:id"
                  component={UpdateCableProcess}
                />
                <PrivateRoute
                  path="/scheduling/copy/:id"
                  component={CopyCableProcess}
                />
                <PrivateRoute
                  path="/device/resources"
                  component={ResourcesManagement}
                />
                <PrivateRoute path="/device/calender" component={Calender} />
                <PrivateRoute
                  path="/project/new-project"
                  component={NewProject}
                />
                <PrivateRoute
                  path="/project/tentative-timelines/:id"
                  component={TentativeProjectTimelines}
                />
                <PrivateRoute
                  path="/project/schedule/:id"
                  component={BaseSchedulePage}
                />

                <PrivateRoute path="/update-user" component={UpdateUser} />
                <PrivateRoute
                  path="/alert-configure"
                  component={ConfigureAlert}
                />
                <PrivateRoute path="/device/machines" component={Machines} />
                <PrivateRoute path="/device/devices" component={Devices} />
                <PrivateRoute
                  path="/device/locations"
                  component={Locations}
                  feature="32"
                />

                <PrivateRoute
                  path="/scheduling/projects"
                  component={Projects}
                />
                <PrivateRoute
                  path="/scheduling/project/:id"
                  component={ViewProjectWrapper}
                />
                <PrivateRoute
                  path="/scheduling/projectEdit/:id"
                  component={EditProjectWrapper}
                />

                <PrivateRoute
                  path="/scheduling/newProject"
                  component={NewProjectWrapper}
                />

                 <PrivateRoute
                  path="/scheduling/rescheduleProject/:id"
                  component={NewProjectWrapper}
                />
                <Route path="/unauthorized" component={UnAuthorized} />
                <Route path="/loading" component={LoadingPage} />
                {/* <PrivateRoute path="/test" component={Machineadd} /> */}
                <Route exact path="/" component={LoginIPS} />
                <Route exact path="/sh" component={BaseSchedulePage} />
                <Route
                  exact
                  path="/RecoverAcc/email"
                  component={RecoverThroughEmail}
                />
                <Route
                  exact
                  path="/RecoverAcc/mobile"
                  component={RecoverThroughMobile}
                />
                <Route exact path="/EnterOTP" component={EnterOTP} />
                <Route exact path="/RecoverAcc" component={RecoverPwd} />
                <Route
                  exact
                  path="/ChangePassword"
                  component={ChangePassword}
                />

                <PrivateRoute
                  path="/maintenance/sensors"
                  component={Maintenance}
                />
                <PrivateRoute
                  path="/maintenance/sensor/:id"
                  component={ViewMachineWrapper}
                />
              </Router>
            {/* </NotificationContextProvider> */}
          </UserContextProvider>
        </ConfigProvider>
      </AuthorizationContextProvider>
    </ApplicationContextProvider>
  );
};

export default App;
