/* eslint-disable no-param-reassign */
import React, { FC, useEffect, useMemo, useState, useRef } from "react";
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Col,
  Select,
  Spin,
  Skeleton,
} from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import { useConnection } from "../../Services/ApplicationContext";
import {
  MachineMap,
  UpdateDeviceMachineMapRequest,
} from "../../definetions/DeviceModel";
import { ILocation, IMachine } from "../../definetions/MachineModel";
import { Plant, Plants } from "../../definetions/Constatnts";
import "./styles1.scss";
import "../modalPopUp.scss";
import { SERVICE_TYPE } from "../../constants/constants";

const { Option } = Select;
export interface AddDeviceModalProps {
  visible: boolean;
  onClose: (val?: UpdateDeviceMachineMapRequest) => void;
  locations: ILocation[];
}
const AddDeviceModal: FC<AddDeviceModalProps> = (
  props: AddDeviceModalProps,
) => {
  const { locations, visible, onClose } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [numSlotFields, setNumSlotFields] = useState<number>(0);
  const [unattachedMachines, setUnattachedMachines] = useState<IMachine[]>([]);
  const [maxSlotCount, setMaxSlotCount] = useState<number>(8);

  const [selectedLocationId, setSelectedLocationId] = useState<number>();
  const [
    unattachedMachinesForLocationID,
    setUnattachedMachinesForLocationID,
  ] = useState<IMachine[]>([]);
  const [deviceTypeVal, setDeviceTypeVal] = useState<boolean>(false);
  const { get, post } = useConnection();
  const [form] = Form.useForm();
  const deviceTypeDetails = [{ deviceType: "WISE-4051", slots: 8 }];

  const slotCount = useMemo(() => {
    if (unattachedMachines.length > 0 && unattachedMachines.length <= 8) {
      if (
        numSlotFields !== undefined &&
        numSlotFields <= unattachedMachines.length
      ) {
        return numSlotFields;
      }
      return unattachedMachines.length;
    }
    return 8;
  }, [unattachedMachines, numSlotFields]);

  const checkForDuplicates = (array: any[]) => {
    return new Set(array).size !== array.length;
  };

  const onSubmit = async (values: any): Promise<void> => {
    console.log(values);
    const dName = values.deviceName;
    const fDName = dName
      .split(" ")
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    values.deviceName = fDName.trim();
    console.log(values, fDName);

    const entry = { ...values, numSlots: numSlotFields };

    entry.maps = entry.maps.filter(
      (item: any) => item.machineID !== null && item.machineID !== undefined,
    );

    setLoading(true);

    try {
      entry.numAttributes = 4;
      // To fill small gaps in raw data stream, IoT device's periodic time is multiplied by this value
      // if periodic time is 60000ms, effective duration of one datapoint is 60000 * alpha
      if (
        !checkForDuplicates(entry.maps.map((val: MachineMap) => val.machineID))
      ) {
        const result:any = await post(
          "system-management/device/addMachineMap",
          entry,
          undefined,
          SERVICE_TYPE.serviceType
        );
        console.log("result-add-machine", result);
        if (result.status === 201) {
          message.open({
            type: "success",
            content: result.message,
            duration: 2,
            style: { textAlign: "right", marginRight: 15, marginTop: 10 },
          });
          onClose(result?.dataList);
          setDeviceTypeVal(false);
        } else {
          message.open({
            type: "error",
            content: result.message,
            duration: 2,
            style: { textAlign: "right", marginRight: 15, marginTop: 10 },
          });
        }
      } else {
        message.open({
          type: "error",
          content: "Please remove the duplicate machines!",
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      }

      setLoading(false);
    } catch (e: any) {
      message.open({
        type: "error",
        content: e.message,
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
      setLoading(false);
    }
  };

  const addMachineFields = (numSlots: number) => {
    form.setFieldsValue({ maps: [] });
    const machines = [];
    for (let i = 0; i < numSlots; i += 1) {
      machines.push({
        slotIndex: i,
        machineID: null,
        attributeIndex: 3,
        attributeName: "OnOff",
      });
    }
    return machines;
  };

  const getUnattachedMachineList = async () => {
    setLoading(true);
    const params = () => {
      return {
        locationID: 1,
        deviceID: 5651,
      };
    };
    try {
      const tempUnattachedMachinesForLocationID = [];
      const result = await get(
        "system-management/machine/unattached",
        undefined,
        SERVICE_TYPE.serviceType
      );
      if (result.status === 200) {
        setMaxSlotCount(result.data.length > 8 ? 8 : result.data.length);
        setUnattachedMachines(result.data);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log("exeption get unattached machines ", e);
    }
  };

  const onNumSlotsChange = (value: any) => {
    const maps = addMachineFields(value);
    form.setFieldsValue({ maps });
    setNumSlotFields(value);
  };

  useEffect(() => {
    getUnattachedMachineList();
    // console.log("locations add device : ", locations);
  }, []);

  useEffect(() => {
    if (numSlotFields === undefined && unattachedMachines.length > 0) {
      onNumSlotsChange(slotCount);
    }
    form.setFieldsValue({ numSlots: slotCount });
  }, [slotCount]);

  const checkSlotValidation = (_: any, value: number) => {
    if (value > 0 && value <= unattachedMachines.length) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error(
        `Slot count must be between 0 and ${
          maxSlotCount
          // unattachedMachines.length > 8 ? 8 : unattachedMachines.length
        }!`,
      ),
    );
  };

  const plantChangeHandler = (val: any) => {
    console.log("selecting plant => ", val);
    setSelectedLocationId(val);
    const temp = [];
    for (let index = 0; index < unattachedMachines?.length; index++) {
      if (unattachedMachines[index]?.locationID === val) {
        temp.push(unattachedMachines[index]);
      }
    }
    console.log("all unattached machines - > ", unattachedMachines);
    setUnattachedMachinesForLocationID([...temp]);
  };

  const handleSelectChange = (value: number) => {
    // console.log(value);
    onNumSlotsChange(deviceTypeDetails[value].slots);
    setDeviceTypeVal(true);
  };

  return (
    <Modal
      title="Add Device"
      centered
      visible={visible}
      onCancel={() => onClose()}
      width="80%"
      // style={{ minHeight: "90vh" }}
      style={{ marginTop: "50px" }}
      footer={null}
      className="general-modal-popup"
    >
      <Form
        form={form}
        layout="vertical"
        name="add-device"
        hideRequiredMark={false}
        onFinish={onSubmit}
        scrollToFirstError
        autoComplete="off"
        initialValues={{ numSlots: slotCount }}
      >
        <Form.Item
          name="deviceName"
          label="Device Name"
          className="display"
          rules={[
            {
              required: true,
              message: "Required field! ",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (getFieldValue("deviceName")?.match(/^[ ]{1,}$/gm)) {
                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise.reject("Required field! ");
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input placeholder="Device Name" />
        </Form.Item>
        <Form.Item
          name="uid"
          label="Universal Device ID"
          className="display"
          rules={[
            {
              required: true,
              message: "Required field! ",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (getFieldValue("uid")?.match(/^[ ]{1,}$/gm)) {
                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise.reject("Required field! ");
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input placeholder="Universal Device ID" />
        </Form.Item>
        <Form.Item
          name="locationID"
          label={Plants}
          rules={[
            {
              required: true,
              message: "Required field!",
            },
          ]}
        >
          <Select
            placeholder={`Select the ${Plant}`}
            allowClear
            showSearch
            optionFilterProp="children"
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            onChange={plantChangeHandler}
          >
            {locations.map((item) => (
              <Option key={item.locationID} value={item.locationID}>
                {item.locationName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="deviceType"
          label="Device Type"
          rules={[
            {
              required: true,
              message: "Required field! ",
            },
          ]}
        >
          <Select
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Select Device Type"
            onChange={handleSelectChange}
            allowClear
          >
            {deviceTypeDetails.map((item, index) => (
              <Option key={item.deviceType} value={index}>
                {item.deviceType}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="mac"
          label="MAC Address"
          className="display"
          rules={[
            {
              required: true,
              message: "Required field! ",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (getFieldValue("mac")?.match(/^[ ]{1,}$/gm)) {
                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise.reject("Required field! ");
                }
                return Promise.resolve();
              },
            }),
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (
                  getFieldValue("mac")?.trim() &&
                  !getFieldValue("mac")
                    ?.trim()
                    .match(/^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/i)
                ) {
                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise.reject("Please input a valid MAC Address!");
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input placeholder="Ex: 00:1B:44:11:3A:B7" />
        </Form.Item>
        <Col span={4}>
          <Form.Item
            name="periodInMilliSecs"
            label="Data Fetch Interval"
            className="display"
            style={{ width: "150%" }}
            normalize={(value, prevVal, prevVals) => value.trim()}
            rules={[
              {
                required: true,
                message: "Required field!",
              },
              {
                validator: (_, value) =>
                  !`${value}`.includes(" ")
                    ? Promise.resolve()
                    : Promise.reject(new Error("Required field!")),
              },
              {
                pattern: /[0-9.]/g,
                message: "Invalid value!",
              },
            ]}
          >
            <Input addonAfter="ms" placeholder="Ex: 50" />
          </Form.Item>
        </Col>

        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            color: "#ffffff",
          }}
        >
          <h3 className="slot-details">Slot Details</h3>
          {numSlotFields === 0 ? (
            <span className="slot-fields">
              Select device to get slot fields
            </span>
          ) : (
            <div>
              <Button
                type="primary"
                style={{
                  margin: "10px 0px",
                  fontFamily: "Poppins",
                  fontSize: "15",
                  borderRadius: "5px",
                  height: "45px",
                }}
                onClick={() => {
                  // change in numSlotFields state will reset the slot fields
                  const tempNumSlotFields = numSlotFields;
                  onNumSlotsChange(tempNumSlotFields);
                }}
              >
                Reset
              </Button>
            </div>
          )}
        </div>
        {slotCount > 0 && deviceTypeVal ? (
          <div className="slot-container" style={{ borderRadius: "10px" }}>
            <Form.List name="maps">
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.map((field, index) => (
                      <React.Fragment key={field.key}>
                        <Row
                          style={{
                            display: "flex",
                            alignItems: "base-line",
                            minHeight: index === 0 ? "90px" : "60px",
                          }}
                        >
                          <Form.Item
                            style={{ marginRight: "10px" }}
                            name={[field.name, "slotIndex"]}
                            label={index === 0 ? "Slot Index" : ""}
                          >
                            <Input
                              style={{ width: "200px" }}
                              disabled
                              value={field.key}
                            />
                          </Form.Item>
                          <Form.Item
                            name={[field.name, "machineID"]}
                            label={index === 0 ? "Machine Name" : ""}
                            rules={[
                              {
                                required: false,
                                message: "Required field! ",
                              },
                            ]}
                          >
                            <Select
                              getPopupContainer={(triggerNode) =>
                                triggerNode.parentElement
                              }
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option: any) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              placeholder="Select a Machine"
                              style={{ width: "200px" }}
                              allowClear
                            >
                              {unattachedMachinesForLocationID.map(
                                (value, indx) => (
                                  <Option
                                    key={indx.toString()}
                                    value={value.machineID}
                                  >
                                    {value.machineName}
                                  </Option>
                                ),
                              )}
                            </Select>
                          </Form.Item>
                        </Row>
                      </React.Fragment>
                    ))}
                  </>
                );
              }}
            </Form.List>
          </div>
        ) : (
          <div className="slot-container-disable" />
        )}

        <Form.Item className="modal-field-button">
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            style={{ marginRight: "15px" }}
          >
            Add Device
          </Button>
          <Button
            loading={loading}
            danger
            type="primary"
            onClick={() => onClose()}
          >
            Cancel
          </Button>
        </Form.Item>
        <Divider type="horizontal" />
      </Form>
    </Modal>
  );
};
export default AddDeviceModal;
