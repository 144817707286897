import { IPlantDataType } from "../IoTStatus";

export const aluminiumData: IPlantDataType = {
  main_canvas_details: { width_ratio: 0.5, height_ratio: 0.24 },
  rectangle_array: [
    {
      x_ratio: 0.52,
      y_ratio: 0.04,
      width_ratio: 0.45,
      height_ratio: 0.1,
      text: "Inter Mediate - 4",
    },
    {
      x_ratio: 0.02,
      y_ratio: 0.2,
      width_ratio: 0.3,
      height_ratio: 0.1,
      text: "Rod Breaker Al - I",
    },
    {
      x_ratio: 0.02,
      y_ratio: 0.38,
      width_ratio: 0.3,
      height_ratio: 0.1,
      text: "Rod Breaker Al - 2",
    },
    {
      x_ratio: 0.4,
      y_ratio: 0.2,
      width_ratio: 0.57,
      height_ratio: 0.1,
      text: "Skip Strander",
    },
    {
      x_ratio: 0.32,
      y_ratio: 0.54,
      width_ratio: 0.65,
      height_ratio: 0.1,
      text: "7 Tubular Strander",
    },
    {
      x_ratio: 0.02,
      y_ratio: 0.73,
      width_ratio: 0.95,
      height_ratio: 0.1,
      text: "12 Tubular Strander",
    },
    {
      x_ratio: 0.32,
      y_ratio: 0.89,
      width_ratio: 0.4,
      height_ratio: 0.07,
      text: "Bow Strander",
    },
  ],
};

export const copperPlantData: IPlantDataType = {
  main_canvas_details: {
    width_ratio: 0.7,
    height_ratio: 0.36,
  },
  rectangle_array: [
    {
      x_ratio: 0.005,
      y_ratio: 0.01,
      width_ratio: 0.2,
      height_ratio: 0.08,
      text: "Mini Armouring",
      font_size: "10",
    },
    {
      x_ratio: 0.225,
      y_ratio: 0.01,
      width_ratio: 0.15,
      height_ratio: 0.06,
      text: "Realing Winder",
      font_size: "10",
    },
    {
      x_ratio: 0.375,
      y_ratio: 0.01,
      width_ratio: 0.1,
      height_ratio: 0.04,
      text: "Pannel Board",
      font_size: "8",
    },
    {
      x_ratio: 0.62,
      y_ratio: 0.01,
      width_ratio: 0.1,
      height_ratio: 0.04,
      text: "Pannel Board",
      font_size: "8",
    },
    {
      x_ratio: 0.85,
      y_ratio: 0.02,
      width_ratio: 0.04,
      height_ratio: 0.05,
      text: "",
      font_size: "10",
    },
    {
      x_ratio: 0.22,
      y_ratio: 0.1,
      width_ratio: 0.68,
      height_ratio: 0.1,
      text: "Drum Twister",
      font_size: "10",
    },
    {
      x_ratio: 0.105,
      y_ratio: 0.25,
      width_ratio: 0.55,
      height_ratio: 0.07,
      text: "Extruder (100mm)",
      font_size: "10",
    },
    {
      x_ratio: 0.125,
      y_ratio: 0.35,
      width_ratio: 0.68,
      height_ratio: 0.06,
      text: "Extruder (120mm)",
      font_size: "10",
    },
    {
      x_ratio: 0.34,
      y_ratio: 0.43,
      width_ratio: 0.48,
      height_ratio: 0.06,
      text: "Extruder (60mm)",
      font_size: "10",
    },
    {
      x_ratio: 0.105,
      y_ratio: 0.55,
      width_ratio: 0.06,
      height_ratio: 0.04,
      text: "",
      font_size: "10",
    },
    {
      x_ratio: 0.21,
      y_ratio: 0.46,
      width_ratio: 0.07,
      height_ratio: 0.03,
      text: "Pannel Board",
      font_size: "8",
    },
    {
      x_ratio: 0.18,
      y_ratio: 0.55,
      width_ratio: 0.07,
      height_ratio: 0.05,
      text: "MWD",
      font_size: "10",
    },
    {
      x_ratio: 0.27,
      y_ratio: 0.51,
      width_ratio: 0.5,
      height_ratio: 0.06,
      text: "Extruder (90mm)",
      font_size: "10",
    },
    {
      x_ratio: 0.39,
      y_ratio: 0.59,
      width_ratio: 0.43,
      height_ratio: 0.06,
      text: "Extruder (80mm)",
      font_size: "10",
    },
    {
      x_ratio: 0.945,
      y_ratio: 0.27,
      width_ratio: 0.04,
      height_ratio: 0.03,
      text: "",
      font_size: "10",
    },
    {
      x_ratio: 0.895,
      y_ratio: 0.27,
      width_ratio: 0.04,
      height_ratio: 0.03,
      text: "",
      font_size: "10",
    },
    {
      x_ratio: 0.97,
      y_ratio: 0.35,
      width_ratio: 0.015,
      height_ratio: 0.24,
      text: "",
      font_size: "10",
    },
    {
      x_ratio: 0.945,
      y_ratio: 0.35,
      width_ratio: 0.015,
      height_ratio: 0.24,
      text: "Auto Coiler - 2",
      font_size: "10",
    },
    {
      x_ratio: 0.9,
      y_ratio: 0.35,
      width_ratio: 0.015,
      height_ratio: 0.24,
      text: "",
      font_size: "10",
    },
    {
      x_ratio: 0.875,
      y_ratio: 0.35,
      width_ratio: 0.015,
      height_ratio: 0.24,
      text: "",
      font_size: "10",
    },
    {
      x_ratio: 0.105,
      y_ratio: 0.68,
      width_ratio: 0.08,
      height_ratio: 0.04,
      text: "Inter mediate - 5",
      font_size: "10",
    },
    {
      x_ratio: 0.105,
      y_ratio: 0.725,
      width_ratio: 0.08,
      height_ratio: 0.037,
      text: "1250 Buncher",
      font_size: "10",
    },
    {
      x_ratio: 0.195,
      y_ratio: 0.66,
      width_ratio: 0.07,
      height_ratio: 0.06,
      text: "Breading Machine",
      font_size: "10",
    },
    {
      x_ratio: 0.3,
      y_ratio: 0.675,
      width_ratio: 0.06,
      height_ratio: 0.029,
      text: "Settic 1",
      font_size: "10",
    },

    {
      x_ratio: 0.3,
      y_ratio: 0.71,
      width_ratio: 0.06,
      height_ratio: 0.029,
      text: "Settic 2",
      font_size: "10",
    },
    {
      x_ratio: 0.42,
      y_ratio: 0.675,
      width_ratio: 0.1,
      height_ratio: 0.029,
      text: "Strander 6",
      font_size: "10",
    },
    {
      x_ratio: 0.42,
      y_ratio: 0.71,
      width_ratio: 0.1,
      height_ratio: 0.029,
      text: "Strander 7",
      font_size: "10",
    },
    {
      x_ratio: 0.595,
      y_ratio: 0.7,
      width_ratio: 0.1,
      height_ratio: 0.04,
      text: "Strander 8",
      font_size: "10",
    },
    {
      x_ratio: 0.715,
      y_ratio: 0.7,
      width_ratio: 0.12,
      height_ratio: 0.04,
      text: "Mica Taping",
      font_size: "10",
    },
    {
      x_ratio: 0.835,
      y_ratio: 0.7,
      width_ratio: 0.03,
      height_ratio: 0.02,
      text: "",
      font_size: "10",
    },
    {
      x_ratio: 0.115,
      y_ratio: 0.79,
      width_ratio: 0.04,
      height_ratio: 0.04,
      text: "",
      font_size: "8",
    },
    {
      x_ratio: 0.185,
      y_ratio: 0.77,
      width_ratio: 0.05,
      height_ratio: 0.04,
      text: "Fine-03",
      font_size: "10",
    },
    {
      x_ratio: 0.255,
      y_ratio: 0.77,
      width_ratio: 0.65,
      height_ratio: 0.06,
      text: "61 Strander",
      font_size: "10",
    },
    {
      x_ratio: 0.115,
      y_ratio: 0.884,
      width_ratio: 0.15,
      height_ratio: 0.029,
      text: "Rod Breaker Cu",
      font_size: "10",
    },
    {
      x_ratio: 0.265,
      y_ratio: 0.86,
      width_ratio: 0.035,
      height_ratio: 0.08,
      text: "",
      font_size: "10",
    },
    {
      x_ratio: 0.33,
      y_ratio: 0.88,
      width_ratio: 0.04,
      height_ratio: 0.035,
      text: "Loader 1",
      font_size: "8",
    },
    {
      x_ratio: 0.41,
      y_ratio: 0.88,
      width_ratio: 0.045,
      height_ratio: 0.035,
      text: "Loader 2",
      font_size: "8",
    },
    {
      x_ratio: 0.5,
      y_ratio: 0.88,
      width_ratio: 0.053,
      height_ratio: 0.035,
      text: "Loader 3",
      font_size: "8",
    },
    {
      x_ratio: 0.6,
      y_ratio: 0.88,
      width_ratio: 0.06,
      height_ratio: 0.035,
      text: "Loader 4",
      font_size: "8",
    },
    {
      x_ratio: 0.67,
      y_ratio: 0.97,
      width_ratio: 0.06,
      height_ratio: 0.03,
      text: "Pannel Room",
      font_size: "8",
    },
    {
      x_ratio: 0.74,
      y_ratio: 0.95,
      width_ratio: 0.06,
      height_ratio: 0.05,
      text: "Die Shop",
      font_size: "10",
    },
    {
      x_ratio: 0.8,
      y_ratio: 0.95,
      width_ratio: 0.06,
      height_ratio: 0.05,
      text: "Pro Office",
      font_size: "10",
    },
    {
      x_ratio: 0.86,
      y_ratio: 0.952,
      width_ratio: 0.06,
      height_ratio: 0.02,
      text: "Stair Case",
      font_size: "8",
    },
    {
      x_ratio: 0.86,
      y_ratio: 0.972,
      width_ratio: 0.02,
      height_ratio: 0.028,
      text: "",
      font_size: "10",
    },
  ],
  random_texts_array: [
    {
      x_ratio: 0.5,
      y_ratio: 0.02,
      text: "Boundary wall",
      font_size: "10",
    },
    {
      x_ratio: 0.5,
      y_ratio: 0.99,
      text: "Boundary wall",
      font_size: "10",
    },
    {
      x_ratio: 0.9,
      y_ratio: 0.33,
      text: "Cutting Section",
      font_size: "10",
    },
    {
      x_ratio: 0.125,
      y_ratio: 0.808,
      text: "DST",
      font_size: "8",
    },
    {
      x_ratio: 0.125,
      y_ratio: 0.83,
      text: "MWD",
      font_size: "8",
    },
  ],
  vertical_texts: [
    {
      x_ratio: 0.942,
      y_ratio: 0.35,
      text: "Auto Coiler - 2",
    },
    {
      x_ratio: 0.873,
      y_ratio: 0.35,
      text: "Cutting Winder 01",
    },
    {
      x_ratio: 0.898,
      y_ratio: 0.35,
      text: "Cutting Winder 02",
    },
    {
      x_ratio: 0.968,
      y_ratio: 0.35,
      text: "Cutting Winder 03",
    },
    {
      x_ratio: 0.005,
      y_ratio: 0.55,
      text: "Boundary Wall",
    },
    {
      x_ratio: 0.985,
      y_ratio: 0.55,
      text: "Boundary Wall",
    },
  ],
  clear_recs_array: [
    {
      x_ratio: 0.861,
      y_ratio: 0.968,
      width_ratio: 0.018,
      height_ratio: 0.01,
    },
  ],
};
