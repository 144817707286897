/* eslint-disable no-nested-ternary */
import React, { useState, useRef, useEffect, FC } from "react";
import { Button, message, Tooltip, Card, Divider, Skeleton } from "antd";
import "./flowChart.scss";
import {
  Canvas,
  Node,
  CanvasRef,
  NodeData,
  EdgeData,
  Edge,
  addNodeAndEdge,
  useProximity,
  removeAndUpsertNodes,
  Label,
  useUndo,
  UndoRedoEvent,
  useSelection,
} from "reaflow";
import { Portal } from "rdk";
import { motion, useDragControls } from "framer-motion";
import classNames from "classnames";
import {
  PlusOutlined,
  MinusOutlined,
  ExpandOutlined,
  UndoOutlined,
  DragOutlined,
  RedoOutlined,
  CheckCircleFilled,
  MinusCircleFilled,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { Col, Row } from "antd/es/grid";
import { useConnection } from "../../Services/ApplicationContext";
import NodeDataModal from "./NodeDataModal";
import CableProcessModal from "./CableProcessModal";
import PopupIcon from "../../assets/popupIcon.png";
import ConfirmationModal from "./ConfirmationModal";
import {
  cableState,
  populateCableState,
  populateCableStateHistory,
  populateCableStateFuture,
  cableStateHistory,
  cableStateFuture,
  addToCableHistory,
  addToCableFuture,
  addToCableStates,
  removeFromCableStates,
  checkEdgeValidations,
  getCableStateLeaves,
  getCableStateSeeds,
  getCableStateConnections,
  getCableStateConnectionsTo,
  deleteFromAllCableStates,
  undoCableStates,
  redoCableStates,
} from "./cableFlowChartValidation";

import {
  addToMap,
  removeFromMap,
  removeToNodes,
  addToNodes,
  addInput,
  getNumberOfInputs,
  removeSingleInputNode,
  getAllNodeOutputStatus,
  populateInputMapOnUpdate,
  getInputMapState,
  restoreInputMap,
  getInputsUsingFromId,
  addOutputs,
  clearInputMap,
  initializeInputMaps,
  // populateInputMap,
  populateInputMapOnUndoRedo,
  addOutputsOnCloseModal,
  inputMap,
  changeToNodeTasksInputs,
  removeTaskInputsOnEdgeRemove,
  removeInputsOnNoProps,
  getInputs,
} from "./inputOutputMap";
import { getPreviousTaskMachineIds } from "./containerValidation";
import { useProcess } from "./NodeDatasAndFunctionsContext";
import { DateTime } from "luxon";
import { SERVICE_TYPE } from "../../constants/constants";

export interface CableProductionFlowChartProps {
  newProcess: boolean;
  edit: boolean;
  processId?: any;
  copy?: boolean;
}

interface IInputSelected {
  disable: boolean;
  value: string;
}

const CableProductionFlowChart = (props:any) => {
  const { newProcess, edit, processId, copy } = props;

  // const {prevOutputContainers, setPrevOutputContainers, currentInputContainers, setCurrentInputContainers, checkContainerValidation} = useProcess();

  const dragControls = useDragControls();
  const { post, get, put } = useConnection();
  const [enteredNode, setEnteredNode] = useState<NodeData | any>(null);
  const [intersectNode, setIntersectNode] = useState<NodeData | any>(null);
  const [activeDrag, setActiveDrag] = useState<string | null>(null);
  const [droppable, setDroppable] = useState<boolean>(true);
  const [selections, setSelections] = useState<string[]>([]);
  const [selectedNode, setSelectedNode] = useState<any>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  const [processModalVisible, setProcessModalVisible] = useState<boolean>(
    false,
  );
  const [processData, setProcessData] = useState<any>();
  const [nodeModalProcessData, setNodeModalProcessData] = useState<any[]>([]);
  const [processObject, setProcessObject] = useState<any>();
  const [zoom, setZoom] = useState<number>(0.7);
  const [cookies, setCookie, removeCookie] = useCookies(["process"]);
  const [draft, setDraft] = useState<boolean>(false);
  const [nodeIds, setNodeIds] = useState<string[]>(["1", "2", "3"]);
  const [prevNode, setPrevNode] = useState<string>("");

  const [previousTaskMachineIds, setPreviousTaskMachineIds] = useState<
    string[]
  >(new Array<string>());

  const [processLoading, setProcessLoading] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  // const [prevOutputContainers, setPrevOutputContainers] = useState(
  //   new Array<any>(),
  // );
  // const [currentInputContainers, setCurrentInputContainers] = useState<{
  //   [id: number]: string[];
  // }>({});

  const [errorsList, setErrorsList] = useState<string>("");
  // const [generateId, setGenerateId] = useState<number>(4);
  const [selectedInputItems, setSelectedInputItems] = useState<any>([]);
  const [rawMaterialById, setRawMaterialById] = useState<string>("");

  const history = useHistory();

  const initialEdges = [
    {
      id: "1-2",
      from: "1",
      to: "2",
    },
    {
      id: "2-3",
      from: "2",
      to: "3",
    },
  ];

  const initialNodes = [
    {
      id: "1",
      text: "Input ",
      data: {
        type: "Input",
        status: false,
      },
      height: 50,
      width: 150,
    },
    {
      id: "2",
      text: "Task",
      data: {
        type: "Task",
        status: false,
      },
      height: 50,
      width: 150,
    },
    {
      id: "3",
      text: "Output",
      data: {
        type: "Output",
        status: false,
      },
      height: 50,
      width: 150,
    },
  ];

  const [nodes, setNodes] = useState<NodeData[]>([]);
  const [edges, setEdges] = useState<EdgeData[]>([]);
  const [processNameEdit, setProcessNameEdit] = useState<string>("");
  const [
    inputNodesWithMoreOutputsLinksDetails,
    setInputNodesWithMoreOutputsLinksDetails,
  ] = useState<any[]>([]);

  const canvasRef = useRef<CanvasRef | any>(null);

  // cableState.print();
  const {
    onDragStart: onProximityDragStart,
    onDrag: onProximityDrag,
    onDragEnd: onProximityDragEnd,
  } = useProximity({
    canvasRef,
    onDistanceChange: (distance: number | null) => {
      // console.info("Distance Changed", distance);
    },
    onIntersects: (match: string | null) => {
      // console.info("Node Intersected", match, activeDrag);

      let matchNode: NodeData | undefined;
      if (match) {
        matchNode = nodes.find((n) => n.id === match);
      }
      if (matchNode?.data?.type === "Block") {
        setDroppable(matchNode !== null);
      } else {
        setDroppable(false);
      }
      setIntersectNode(matchNode);
    },
    onMatchChange: (match: string | null) => {
      // console.info("Match Changed!", match);
      let matchNode: NodeData | undefined;
      if (match) {
        matchNode = nodes.find((n) => n.id === match);
      }
      setEnteredNode(matchNode);
      setDroppable(matchNode !== null);
    },
  });

  const storeStatesInLocalStorage = () => {
    sessionStorage.setItem("process", JSON.stringify({ nodes, edges }));
    sessionStorage.setItem(
      "cableState",
      JSON.stringify({
        leaves: getCableStateLeaves(),
        seeds: getCableStateSeeds(),
        connections: getCableStateConnections(),
        connections_to: getCableStateConnectionsTo(),
      }),
    );
    sessionStorage.setItem(
      "cableStateHistory",
      JSON.stringify({ cableStateHistory }),
    );
    sessionStorage.setItem(
      "cableStateFuture",
      JSON.stringify({ cableStateFuture }),
    );
    sessionStorage.setItem("inputMap", JSON.stringify(getInputMapState()));
  };

  const getStatesInLocalStorage = () => {
    const tempProcess = JSON.parse(sessionStorage.getItem("process") ?? "{}");
    const tempCableState = JSON.parse(
      sessionStorage.getItem("cableState") ?? "{}",
    );
    const tempCableStateHistory = JSON.parse(
      sessionStorage.getItem("cableStateHistory") ?? "{}",
    );
    const tempCableStateFuture = JSON.parse(
      sessionStorage.getItem("cableStateFuture") ?? "{}",
    );
    const tempInputMap = JSON.parse(sessionStorage.getItem("inputMap") ?? "{}");

    return {
      process: tempProcess,
      cableState: tempCableState,
      cableStateHistory: tempCableStateHistory,
      cableStateFuture: tempCableStateFuture,
      inputMap: tempInputMap,
    };
  };

  const removeStatesFromLocalStorage = () => {
    sessionStorage.removeItem("process");
    sessionStorage.removeItem("cableState");
    sessionStorage.removeItem("cableStateHistory");
    sessionStorage.removeItem("cableStateFuture");
    sessionStorage.removeItem("inputMap");
  };

  const generateNodeId = (type: string | null) => {
    console.log("node ids -------- ", nodeIds);
    const tempNodeIds: number[] = nodeIds.map((nodeId: string) => {
      return parseInt(nodeId, 10);
    });
    let counter = 0;
    if (tempNodeIds.length) {
      counter = Math.max(...tempNodeIds);
    }
    const id = `${counter + 1}`;
    setNodeIds([...nodeIds, id]);
    console.log("id", id);
    return id;
  };

  const removeNodeId = (nodeId: string) => {
    let tempNodeIds = nodeIds;
    tempNodeIds = tempNodeIds.filter((id: string) => id !== nodeId);
    setNodeIds(tempNodeIds);
  };

  // useEffect(() => {

  // }, [nodeIds])

  const onDragStart = (event: any, data: any) => {
    // console.info("Start of Dragging", event, data);
    onProximityDragStart(event);
    setActiveDrag(data);
    dragControls.start(event, { snapToCursor: true });
  };

  const onDrag = (event: any) => {
    onProximityDrag(event);
  };

  const onDragEnd = (event: any) => {
    onProximityDragEnd(event);
    if (droppable) {
      // const id = `${activeDrag}-${
      //   Math.floor(Math.random() * (100 - 1 + 1)) + 1
      // }`;
      const id = generateNodeId(activeDrag);
      addToMap(id);
      if (intersectNode?.id) {
        setNodes([
          ...nodes,
          {
            id,
            text: id,
            parent: intersectNode?.id,
            data: {
              type: activeDrag,
            },
            height: 50,
            width: 150,
          },
        ]);
      } else {
        const edgeValidation = checkEdgeValidations(
          enteredNode?.data?.type,
          activeDrag!,
        );
        if (edgeValidation === "OK") {
          if (enteredNode !== null && enteredNode !== undefined) {
            addToCableHistory();
            addToCableStates(enteredNode.id, id);
            addToMap(id);
            addInput(id, enteredNode?.id);
          }
          const result = addNodeAndEdge(
            nodes,
            edges,
            {
              id,
              text: id,
              parent: enteredNode?.parent,
              data: {
                type: activeDrag,
              },
              height: 50,
              width: 150,
            },
            enteredNode,
          );
          setNodes(result.nodes);
          setEdges(result.edges);
        } else {
          message.open({
            type: "error",
            content: `${edgeValidation}`,
            duration: 2,
            style: { textAlign: "right", marginRight: 15, marginTop: 10 },
          });
        }
      }
    }

    setDroppable(true);
    setActiveDrag(null);
    setEnteredNode(null);
  };

  // //funtions to validate the graph
  // same function is used to check whether a node is unlinked.
  const checkNodeEligibilityToDelete = (nodeId: string) => {
    const edgesConnectedToNode = edges.filter(
      (edge: any) => edge.to === nodeId || edge.from === nodeId,
    );

    if (edgesConnectedToNode.length > 0) {
      return false;
    }
    return true;
  };

  const checkForUnlinkedNodes = () => {
    const unlinkedNodes: string[] = [];
    nodes.forEach((node: any) => {
      if (checkNodeEligibilityToDelete(node.id)) {
        unlinkedNodes.push(node);
      }
    });

    if (unlinkedNodes.length > 0) {
      return true;
    }
    return false;
  };

  const getSeedTypes = () => {
    const seedNodes = getCableStateSeeds();
    const seedTypes = new Set();

    seedNodes.forEach((node: string) => {
      const temp = nodes.find((item: any) => item.id === node);
      seedTypes.add(temp?.data?.type);
    });

    return seedTypes;
  };

  const getLeafTypes = () => {
    const leafNodes = getCableStateLeaves();
    const leafTypes = new Set();

    leafNodes.forEach((node: string) => {
      const temp = nodes.find((item: any) => item.id === node);
      leafTypes.add(temp?.data?.type);
    });

    return leafTypes;
  };

  const checkForInputs = () => {
    const inputs = getSeedTypes();
    if (inputs.has("Input") && inputs.size === 1) {
      return true;
    }
    return false;
  };

  const checkForOutputs = () => {
    const outputs = getLeafTypes();

    if (outputs.has("Output") && outputs.size === 1) {
      return true;
    }
    return false;
  };

  const checkForMismatches = () => {
    const leafTypes = getLeafTypes();
    const seedTypes = getSeedTypes();

    if (
      leafTypes.has("Output") &&
      leafTypes.size === 1 &&
      seedTypes.has("Input") &&
      seedTypes.size === 1
    ) {
      return false;
    }

    return true;
  };

  const createTaskInputs = (numberOfInputs: number) => {
    const tempInputs: any[] = [];
    if (numberOfInputs) {
      // console.log(numberOfInputs, "numberOfInputs");
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < numberOfInputs; i++) {
        tempInputs.push({ name: undefined });
      }
    } else {
      tempInputs.push({ name: undefined });
    }
    return tempInputs;
  };

  const nodeDataSelect = (nodeData: NodeData) => {
    // deep copy nodeData to tempNodeData
    console.log(nodeData, "nodeDatanodeDatanodeDatanodeData");
    const tempArr: any[] = [];
    const tempNodeData = JSON.parse(JSON.stringify(nodeData));
    if (nodeData.data.type === "Task") {
      if (!tempNodeData?.data?.props?.taskInputs) {
        console.log(getNumberOfInputs(nodeData?.id));
        const taskInputs = createTaskInputs(getNumberOfInputs(nodeData?.id));
        tempNodeData.data.props = { ...tempNodeData.data.props, taskInputs };
      }
    }
    if (nodeData.data.type === "Input") {
      if (inputNodesWithMoreOutputsLinksDetails?.length > 0) {
        inputNodesWithMoreOutputsLinksDetails?.map((item: any, index: any) => {
          if (nodeData?.id === item?.item) {
            item?.tArrForConnectedNodes?.map((id: any, idIndex: any) => {
              nodes?.map((node: any, nodeIndex: any) => {
                if (id === node?.id) {
                  tempArr.push([
                    id,
                    node?.data?.props?.taskName
                      ? node?.data?.props?.taskName
                      : node?.text,
                  ]);
                }
              });
            });
          }
        });
      }
      setNodeModalProcessData([...tempArr]);
    }

    const machineIds = getPreviousTaskMachineIds(nodeData?.id, nodes, edges);
    setPreviousTaskMachineIds(machineIds);
    console.log(tempNodeData,"tempNodeDatatempNodeDatatempNodeDatatempNodeData")
    setSelectedNode(tempNodeData);
    setModalVisible(true);
  };

  const closeModal = (data: any) => {
    console.log(
      data,
      "bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbnnnnn",
      nodes,
      "lklklllllllooooooo",
      selectedNode,
    );
    if (data) {
      const { nameChanged, previousName, currentName, ...values } = data;
      const type = selectedNode?.data?.type;
      const index = nodes.findIndex((n) => n.id === selectedNode.id);
      nodes[index].data.props = values;
      nodes[index].data.status = true;
      console.log(
        nameChanged,
        "bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb",
        values,
        "lklklklklklk",
        nodes,
      );
      const toNodes = edges.filter(
        (edge: any) => edge.from === selectedNode.id,
      );
      console.log(toNodes, "nnnnnnnnnnnnnn", index, "kkkkkkkkkkkkkkkkkk");
      if (type === "Input") {
        const { inputItem, inputItemId } = values;
        let tempInput: any[] = [];
        tempInput = [
          {
            inputItem,
            inputId: selectedNode.id,
          },
        ];
        addOutputsOnCloseModal(selectedNode.id, tempInput);
        toNodes.forEach((node: any) => {
          addInput(node.to, selectedNode.id);
          if (nameChanged) {
            const nodeIndex = nodes.findIndex((n) => n.id === node.to);
            console.log(nodeIndex, "nameChanged", nodes[nodeIndex].data);
            if (nodes[nodeIndex]?.data?.props?.taskName) {
              const tempData = changeToNodeTasksInputs(
                nodes[nodeIndex].data,
                previousName,
                currentName,
                "Input",
              );
              console.log("changedData", tempData);
              nodes[nodeIndex].data = tempData;
            }
          }
        });
      } else if (type === "Task") {
        // console.log(values, "hhhhhhhhhhhhhhhhhhh");
        const inputs = values.taskOutputs.map((output: any) => {
          return {
            inputItem: output.name,
            inputId: output.id,
          };
        });
        addOutputsOnCloseModal(selectedNode.id, inputs);
        toNodes.forEach((node: any) => {
          addInput(node.to, selectedNode.id);
          if (nameChanged) {
            console.log("nameChanged");
            const nodeIndex = nodes.findIndex((n) => n.id === node.to);
            if (nodes[nodeIndex]?.data?.props?.taskName) {
              const tempData = changeToNodeTasksInputs(
                nodes[nodeIndex].data,
                previousName,
                currentName,
                "Task",
              );
              console.log("changedDatass", tempData);
              nodes[nodeIndex].data = tempData;
            }
          }
        });
      }
      console.log(nodes, "nnnoooooodddeeeessssss8888888888888");
      setNodes([...nodes]);
      setModalVisible(false);
    } else {
      setModalVisible(false);
    }
    const tempInputMap = getInputMapState();
    console.log(tempInputMap, "nnnoooooodddeeeessssss");
    // console.log("input map state  ", tempInputMap);
    sessionStorage.setItem("inputMap", JSON.stringify(tempInputMap));
  };

  const getNodesWithOutputsNotMapped = () => {
    const nodeOutputStatus = getAllNodeOutputStatus();
    console.log("nodes ot puts ------- ", nodeOutputStatus);
    const nodesWithOutputsNotMapped: string[] = new Array<string>();

    Object.keys(nodeOutputStatus).forEach((key: string) => {
      const node = nodes.find((item: NodeData) => item.id === key);
      const toNodes = edges
        .filter((edge: EdgeData) => edge.from === node?.id)
        .map((item: EdgeData) => item.to);

      console.log("nodes ot toNodes ------- ", toNodes);
      let outputNodeCount = 0;
      toNodes.forEach((toNode: any) => {
        const temp = nodes.find((item: NodeData) => item.id === toNode);
        console.log(temp, " nodeOutputStatus");
        if (temp?.data?.type === "Output") {
          outputNodeCount += 1;
        }
      });
      console.log(
        nodeOutputStatus[key],
        " nodeOutputStatus[key]nodeOutputStatus[key]",
      );
      if (outputNodeCount !== nodeOutputStatus[key]) {
        nodesWithOutputsNotMapped.push(
          node?.data?.props?.taskName || node?.data?.props?.inputItem,
        );
      }
    });

    console.log(nodesWithOutputsNotMapped, "not mapped");
    return nodesWithOutputsNotMapped;
  };

  const checkForOutputMapping = () => {
    const nodesWithOutputsNotMapped = getNodesWithOutputsNotMapped();

    if (nodesWithOutputsNotMapped.length === 0) {
      return false;
    }
    nodesWithOutputsNotMapped.forEach((node: string) => {
      message.open({
        type: "error",
        content: `Error in task-${node} Please map the outputs to the tasks correctly!`,
        duration: 30,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
    });

    console.log("map input", inputMap);
    return true;
  };

  const hasDuplicates = (arr: any[]) => {
    return new Set(arr).size !== arr.length;
  };

  const checkForDuplicatedInputsforATask = () => {
    const inputItemsArray: any[] = [];
    nodes?.map((node: any, index: any) => {
      if (node?.data?.type === "Input") {
        if (node?.data?.props?.inputItem) {
          inputItemsArray.push(node?.data?.props?.inputItem);
        }
      }
    });

    console.log("inputItems before save process -> ", inputItemsArray);
    console.log("nodes before save process -> ", nodes);
    const result = hasDuplicates(inputItemsArray);
    return result;
  };

  const getInputRatioIssueNode = () => {
    const result: string[] = [];
    nodes?.map((node: any, index: any) => {
      let inputRatioSum = 0;
      if (node?.data?.type === "Task") {
        node?.data?.props?.taskInputs?.map((input: any, index: any) => {
          inputRatioSum += parseFloat(input?.ratio);
        });
        if (inputRatioSum !== 1) {
          if (node?.data?.props?.taskName !== undefined) {
            result.push(node?.data?.props?.taskName);
          }
        }
      }
    });
    return result;
  };

  const checkInputRatiosError = () => {
    let result = false;
    nodes?.map((node: any, index: any) => {
      let inputRatioSum = 0;
      if (node?.data?.type === "Task") {
        node?.data?.props?.taskInputs?.map((input: any, index: any) => {
          inputRatioSum += parseFloat(input?.ratio);
        });
        if (inputRatioSum !== 1) {
          result = true;
        }
      }
    });
    return result;
  };

  const getOutputRatioIssueNode = () => {
    const result: string[] = [];
    nodes?.map((node: any, index: any) => {
      let outPutRatioSum = 0;
      if (node?.data?.type === "Task") {
        node?.data?.props?.taskOutputs?.map((output: any, index: any) => {
          outPutRatioSum += parseFloat(output?.ratio);
        });
        if (outPutRatioSum !== 1) {
          if (node?.data?.props?.taskName !== undefined) {
            result.push(node?.data?.props?.taskName);
          }
        }
      }
    });
    return result;
  };

  const checkOutPutRatiosError = () => {
    let result = false;
    nodes?.map((node: any, index: any) => {
      let outPutRatioSum = 0;
      if (node?.data?.type === "Task") {
        node?.data?.props?.taskOutputs?.map((output: any, index: any) => {
          outPutRatioSum += parseFloat(output?.ratio);
        });
        if (outPutRatioSum !== 1) {
          result = true;
        }
      }
    });
    return result;
  };

  const saveProcess = async (processFields: any, copyProcess: boolean) => {
    let statusError = 0;
    for (let i = 0; i < nodes.length; i++) {
      if(nodes[i].data.status === false || nodes[i].data.status === undefined) {
        statusError += 1
      }
    }
    if(statusError === 0) {
      const tempInputMap = getInputMapState();
      const inputsSelected: any = [];

      const tempInputMapValues = Object.values(tempInputMap);
      setDraft(processFields?.draft);
      console.log(nodes, "nodes[i].datanodes[i].data");

      let minOrdQt: any = {};
      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].data.type === "Task") {
          nodes[i].data.taskName = nodes[i].data.props.taskName;
          nodes[i].data.taskType = nodes[i].data.props.taskType;
          if (nodes[i].data.props.taskSubType === undefined)
            nodes[i].data.taskSubType = nodes[i].data.props.taskSubType;
        }
        if (nodes[i].data.type === "Output") {
          if (minOrdQt[nodes[i].id] === undefined) {
            minOrdQt = {
              [nodes[i].id]: processFields.minimumOrderQuantity,
            };
          }
        }
      }
      processFields.minimumOrderQuantity = minOrdQt;
       /// copy---------------------------
      const imValues = getInputMapState();
      const nodesList = copyProcess ? updateInputValuesIds(nodes,imValues) : nodes;
      // -----------------------------
      const processDataTemp = {
        processFields,
        nodes:nodesList,
        edges,
      };
      // if (checkOutPutRatiosError()) {
      //   let taskName = getOutputRatioIssueNode();
      //   console.log("task names from ratio issue ---- > ", taskName);
      //   message.open({
      //     type: "error",
      //     content: `Error in task-${taskName}. Incorrect Output quantity ratios`,
      //     duration: 2,
      //     style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      //   });
      // }
      console.log(
        "processDataTempprocessDataTempprocessDataTempprocessDataTemp",
        processDataTemp,
      );
      if (checkForDuplicatedInputsforATask()) {
        message.open({
          type: "error",
          content: "Error! Please remove duplicated input nodes",
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      } else if (checkForUnlinkedNodes()) {
        message.open({
          type: "error",
          content:
            "Error! Please resolve the mismatches before saving the template",
          duration: 20,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      } else if (!checkForInputs()) {
        message.open({
          type: "error",
          content: "Error! Start of the production plan should be an Input",
          duration: 20,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      } else if (!checkForOutputs()) {
        message.open({
          type: "error",
          content: "Error! End of the production plan should be an Output!",
          duration: 20,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      } else if (!checkForMismatches()) {
        // if (!checkForOutputMapping()) {
        try {
          const results = await post(
            "scheduler/productionPlans/addProcessTemplate",
            processDataTemp,
            undefined,
            SERVICE_TYPE.serviceType
          );
          message.open({
            type: "success",
            content: "Process Added",
            duration: 2,
            style: { textAlign: "right", marginRight: 15, marginTop: 10 },
          });
          console.log(
            "processDataTempprocessDataTempprocessDataTempprocessDataTemp",
            results,
          );
          clearInputMap();
          removeStatesFromLocalStorage();
          history.push("/scheduling/table");
        } catch (error: any) {
          console.log(
            "processDataTempprocessDataTempprocessDataTempprocessDataTemp",
            error,
          );
          message.open({
            type: "error",
            content: error.message,
            duration: 2,
            style: { textAlign: "right", marginRight: 15, marginTop: 10 },
          });
        }
        // }
      } else {
        message.open({
          type: "error",
          content:
            "Error! Please resolve the mismatches before saving the template ",
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      }
    } else {
      message.open({
        type: "error",
        content:
          "Error! Please complete the remaining nodes ",
        duration: 2,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
    }
  };

  const updateProcess = async (processFields: any) => {
    let statusError = 0;
    for (let i = 0; i < nodes.length; i++) {
      if(nodes[i].data.status === undefined) {
        statusError += 1
      }
    }
  if(statusError === 0) {
    const tempInputMap = getInputMapState();
    const inputsSelected: any = [];

    const tempInputMapValues = Object.values(tempInputMap);
    setDraft(processFields?.draft);
    console.log("processFields ========> ", processFields);

    let minOrdQt: any = {};
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].data.type === "Task") {
        nodes[i].data.taskName = nodes[i].data.props.taskName;
        nodes[i].data.taskType = nodes[i].data.props.taskType;
        if (nodes[i].data.props.taskSubType === undefined)
          nodes[i].data.taskSubType = nodes[i].data.props.taskSubType;
      }
      if (nodes[i].data.type === "Output") {
        if (minOrdQt[nodes[i].id] === undefined) {
          minOrdQt = {
            [nodes[i].id]: processFields.minimumOrderQuantity,
          };
        }
      }
    }
    processFields.minimumOrderQuantity = minOrdQt;
    
    const imValues = getInputMapState();
    const nodesList = updateInputValuesIds(nodes,imValues);

    // console.log("<<<<<<<<<=================",getInputMapState(),"==============>>>>>>>>>>>>>>>>>>>>")

    const processDataTemp = {
      processFields,
      nodes: nodesList,
      edges,
      id: { id: processId },
      updateTime: DateTime.now().valueOf(),
    };
    // if (checkOutPutRatiosError()) {
    //   const taskName = getOutputRatioIssueNode();
    //   console.log("task names from ratio issue ---- > ", taskName);
    //   message.open({
    //     type: "error",
    //     content: `Error in task-${taskName[0]}. Incorrect quantity ratios`,
    //     duration: 2,
    //     style: { textAlign: "right", marginRight: 15, marginTop: 10 },
    //   });
    // } else
    if (checkForDuplicatedInputsforATask()) {
      message.open({
        type: "error",
        content: "Error! Please remove duplicated input nodes",
        duration: 2,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
    } else if (checkForUnlinkedNodes()) {
      message.open({
        type: "error",
        content:
          "Error! Please resolve the mismatches before save the template",
        duration: 2,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
    } else if (!checkForInputs()) {
      message.open({
        type: "error",
        content: "Error! Start of the production plan should be an Input",
        duration: 2,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
    } else if (!checkForOutputs()) {
      message.open({
        type: "error",
        content: "Error! End of the production plan should be an Output!",
        duration: 2,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
    } else if (!checkForMismatches()) {
      // if (!checkForOutputMapping()) {
      try {
        const results = await post(
          "scheduler/productionPlans/updateProcessTemplate",
          processDataTemp,
          undefined,
          SERVICE_TYPE.serviceType
        );
        message.open({
          type: "success",
          content: "Process Updated",
          duration: 3,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
        clearInputMap();
        removeStatesFromLocalStorage();
        history.push("/scheduling/table");
      } catch (error: any) {
        message.open({
          type: "error",
          content: error.message,
          duration: 3,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      }
      // }
    } else {
      message.open({
        type: "error",
        content:
          "Error! Please resolve the mismatches before save the template",
        duration: 2,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
    }
  } else {
    message.open({
      type: "error",
      content:
        "Error! Please complete the remaining nodes ",
      duration: 2,
      style: { textAlign: "right", marginRight: 15, marginTop: 10 },
    });
  }
  };
  
  const updateInputValuesIds = (nodes:any, imValues?:any) => { 
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].data.type === "Task") {
        console.log("<<<<<<<<<=================",getInputMapState(),"==============>>>>>>>>>>>>>>>>>>>>")
        
        for (let j = 0; j < nodes[i].data.props.lengthFactors.length; j++) {
          if(nodes[i].data.props.lengthFactors[j].inputValuesId === undefined){
            for (const val in imValues) {
              if(JSON.stringify(imValues[val].inputs) !== "{}"){
                for (const iv in imValues[val].inputs) {
                  console.log(iv,",,,,,,,,,,,,,,,,,,,,,,,",imValues[val].inputs[iv][0])
                  if(nodes[i].data.props.lengthFactors[j].inputValue === imValues[val].inputs[iv][0].value) {
                    nodes[i].data.props.lengthFactors[j].inputValuesId = iv.toString()
                  }
                }
              }
            }
          }
        }

        for (let k = 0; k < nodes[i].data.props.machines.length; k++) {
          for (let m = 0; m < nodes[i].data.props.machines[k].parallelConcurrentInput.length; m++) {
            if(nodes[i].data.props.machines[k].parallelConcurrentInput[m].inputItemId === undefined){
              for (const val in imValues) {
                if(JSON.stringify(imValues[val].inputs) !== "{}"){
                  for (const ivp in imValues[val].inputs) {
                    console.log(ivp,",,,,,,,,,,,,,,,,,,,,,,,",imValues[val].inputs[ivp][0])
                    if(nodes[i].data.props.machines[k].parallelConcurrentInput[m].inputValues === imValues[val].inputs[ivp][0].value) {
                      nodes[i].data.props.machines[k].parallelConcurrentInput[m].inputItemId = ivp.toString()
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return nodes
  }

  const closeProcessModal = (val: any) => {
    if (val) {
      setProcessLoading(true);
      setProcessData(val);
      setProcessModalVisible(false);
      if (processId && edit && copy) {
        saveProcess(val, true).then(() => {
          setProcessLoading(false);
        });
      } else if (processId && edit) {
        updateProcess(val).then(() => {
          setProcessLoading(false);
        });
      } else {
        saveProcess(val, false).then(() => {
          setProcessLoading(false);
        });
      }
    } else {
      setProcessModalVisible(false);
    }
  };

  const getProcessData = async () => {
    setDataLoading(true);
    try {
      const response = await get(
        `scheduler/productionPlans/getProcessTemplate?id=${processId}`,
        undefined,
        SERVICE_TYPE.serviceType
      );
      console.log(response, "----------------------------responseresponseresponse");
      setProcessNameEdit(response.data.processFields.processName);
      if (edit) {
        for (let i = 0; i < response.data.nodes.length; i++) {
          response.data.nodes[i].data.status = true;
        }
      }
      cableState.clear();
      setNodes(response.data.nodes);
      const tempEdges = response.data?.edges;
      setEdges(response.data.edges);
      tempEdges.forEach((edge: any) => {
        addToCableStates(edge.from, edge.to);
      });
      populateInputMapOnUpdate(response.data.nodes, response.data.edges);
      setProcessObject(response.data?.nodes);
      console.log("nodes", response.data?.nodes);
      setProcessData(response.data.processFields);
      const tempNodeIds = response.data?.nodes?.map(
        (node: NodeData) => node.id,
      );
      setNodeIds(tempNodeIds);
      setDataLoading(false);
    } catch (error: any) {
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
      setDataLoading(false);
    }
  };

  useEffect(() => {
    if (processId) {
      setNodes([]);
      getProcessData();
    } else {
      cableState.initiate();
      initializeInputMaps();
    }
  }, [processId]);

  const { undo, redo, canUndo, canRedo } = useUndo({
    nodes,
    edges,
    onUndoRedo: (state: UndoRedoEvent) => {
      // console.log("Undo / Redo", state);
      if (state.type === "undo") {
        addToCableFuture();
        undoCableStates();
      } else if (state.type === "redo") {
        addToCableHistory();
        redoCableStates();
      }
      if (state.edges && state.nodes) {
        setEdges(state.edges);
        setNodes(state.nodes);
        console.log(state.nodes);
        populateInputMapOnUndoRedo(state.nodes, state.edges);
      }
    },
  });

  useEffect(() => {
    if (!edit) {
      if (
        nodes.length !== 0 &&
        edges.length !== 0 &&
        (JSON.stringify(edges) !== JSON.stringify(initialEdges) ||
          JSON.stringify(nodes) !== JSON.stringify(initialNodes))
      ) {
        storeStatesInLocalStorage();
      }
    } else if (processObject !== initialNodes) {
      storeStatesInLocalStorage();
    }
  }, [edges, nodes]);

  useEffect(() => {
    const stateData = getStatesInLocalStorage();
    if (Object.keys(stateData.process).length !== 0 && !edit) {
      setConfirmationModal(true);
    } else if (!processId) {
      setNodes(initialNodes);
      setEdges(initialEdges);
    }
  }, []);

  const closeConfirmationModal = (val: boolean) => {
    if (!edit) {
      if (val) {
        const tempNodeIds: any[] = [];
        const stateData = getStatesInLocalStorage();
        setEdges(stateData?.process?.edges);
        setNodes(stateData?.process?.nodes);
        stateData.process?.nodes?.map((node: any, indexNode: any) => {
          tempNodeIds.push(node?.id);
        });
        setNodeIds([...tempNodeIds]);
        const {
          leaves,
          seeds,
          connections,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          connections_to,
        } = stateData?.cableState;
        populateCableState(leaves, seeds, connections, connections_to);
        console.log(stateData?.inputMap);
        restoreInputMap(stateData?.inputMap);
        populateCableStateFuture(stateData?.cableStateFuture);
        populateCableStateHistory(stateData?.cableStateHistory);
        setConfirmationModal(false);
        console.log(stateData, "stateData");
      } else {
        setNodes(initialNodes);
        setEdges(initialEdges);
        setConfirmationModal(false);
        removeStatesFromLocalStorage();
        initializeInputMaps();
      }
    } else if (val) {
      const tempNodeIds: any[] = [];
      const stateData = getStatesInLocalStorage();
      setEdges(stateData.process?.edges);
      setNodes(stateData.process?.nodes);
      stateData.process?.nodes?.map((node: any, indexNode: any) => {
        tempNodeIds.push(node?.id);
      });
      setNodeIds([...tempNodeIds]);
      const {
        leaves,
        seeds,
        connections,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        connections_to,
      } = stateData.cableState;
      populateCableState(leaves, seeds, connections, connections_to);
      restoreInputMap(stateData.inputMap);
      populateCableStateFuture(stateData.cableStateFuture);
      populateCableStateHistory(stateData.cableStateHistory);
      console.log(stateData, "stateData");
      setConfirmationModal(false);
    } else {
      setConfirmationModal(false);
      removeStatesFromLocalStorage();
      initializeInputMaps();
    }
  };

  const getColorNode = (nodeId: any) => {
    let nodeColor: any = "#252A38";
    // console.log(nodes, " ------------ 2--------- -");
    nodes?.map((node: any, index: any) => {
      if (nodeId === node?.id) {
        node?.data?.type === "Task"
          ? (nodeColor = "#352f44")
          : node?.data?.type === "Input"
          ? (nodeColor = "#3a4750")
          : node?.data?.type === "Output"
          ? (nodeColor = "#414141")
          : node?.data?.type === "Block"
          ? (nodeColor = "#252A38")
          : (nodeColor = "#252A38");

        // console.log(
        //   nodeId,
        //   node?.data?.type,
        //   nodeColor,
        //   " ---------- 000000000000 ------------",
        // );
        // switch (node?.data?.type) {
        //   case "Task":
        //     return "#352f44";
        //   case "Input":
        //     return "#3a4750";
        //   case "Output":
        //     return "#414141";
        //   case "Block":
        //     return "#252A38";
        //   default:
        //     return "#252A38";
        // }
      }
    });
    return nodeColor;
  };

  const nodeColour = (nodeType: any) => {
    console.log(nodeType, " ---------- 000000000000 ------------");
    switch (nodeType) {
      case "Task":
        return "#352f44";
      case "Input":
        return "#3a4750";
      case "Output":
        return "#414141";
      case "Block":
        return "#252A38";
      default:
        return "#252A38";
    }
  };

  const openProcessModal = async () => {
    if (nodes?.length > 0) {
      let validation = true;
      nodes.map((node) => {
        if (!node.data.props) {
          validation = false;
        }
      });
      setProcessModalVisible(true);
    } else {
      message.open({
        type: "error",
        content: "Empty Process",
        duration: 2,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
    }
  };

  const checkNodeValidation = () => {
    let validation = true;
    nodes?.map((node) => {
      if (node.data.type !== "Block") {
        if (!node.data.props) {
          validation = false;
        }
      }
    });
    return validation;
  };

  useEffect(() => {
    // const process = sessionStorage.getItem("process");
    const inputNodesWithMoreOutputsLinks: any[] = [];
    const taskNodesWithSameInputNodeLink: any[] = [];
    const inputNodesMoreOutputsLinksWithConnections: any[] = [];
    const connections: any = getCableStateConnections();
    const connectionsToArray: any = Object.entries(connections);

    connectionsToArray?.map((connectionTo: any[], connectionToTndex: any) => {
      if (connectionTo[1] > 1) {
        nodes?.map((node: any, nodeIndex: any) => {
          if (connectionTo[0] === node?.id && node?.data?.type === "Input") {
            inputNodesWithMoreOutputsLinks.push(connectionTo[0]);
          }
        });
      }
    });

    inputNodesWithMoreOutputsLinks?.map((item: any, index: any) => {
      const tArrForConnectedNodes: any[] = [];
      edges?.map((edge: any, edgeIndex: any) => {
        if (item === edge?.from) {
          nodes?.map((node: any, nodeIndex: any) => {
            if (edge?.to === node?.id && node?.data?.type === "Task") {
              tArrForConnectedNodes.push(node?.id);
            }
          });
        }
      });
      inputNodesMoreOutputsLinksWithConnections.push({
        item,
        tArrForConnectedNodes,
      });
    });

    console.log(" ------- inputNodesWithMoreOutputsLinks ------------ ", nodes);
    nodes?.map((node: any) => {
      if (node?.data?.type === "Input") {
        setRawMaterialById(node?.data?.props?.inputItemId);
      }
    });
    setInputNodesWithMoreOutputsLinksDetails(
      inputNodesMoreOutputsLinksWithConnections,
    );
  }, [edges, nodes]);

  // edges?.map((edge: any, edgeIndex: any) => {
  //   if (nodes) {
  //     let taskNodesWithSameInputNodeLinkT: any[] = [];
  //     nodes?.map((node: any, nodeIndex: any) => {
  //       if (edge?.from === node?.id && node?.data?.type === "Input") {
  //         if(inputNodesWithMoreOutputsLinks.includes('node?.id')) {

  //         }
  //         inputNodesWithMoreOutputsLinks.push(node?.id);
  //         taskNodesWithSameInputNodeLinkT.push(edge?.to)
  //       }
  //     });
  //   }
  // });

  return (
    <div className="cable-process">
      {!dataLoading ? 
      <>
        <NodeDataModal
          disable={false}
          update={edit}
          visible={modalVisible}
          setVisible={setModalVisible}
          nodeData={selectedNode}
          onClose={closeModal}
          previousTaskMachineIds={previousTaskMachineIds}
          inputItemsSelected={selectedInputItems}
          setInputItemsSelected={setSelectedInputItems}
          processData={nodeModalProcessData}
          rawMaterialId={rawMaterialById}
          nodes={nodes}
        />
        <CableProcessModal
          update={edit}
          visible={processModalVisible}
          processData={processData}
          onClose={closeProcessModal}
          loading={processLoading}
          copy={copy}
        />
        <ConfirmationModal
          edit={edit}
          visible={confirmationModal}
          onClose={(val: boolean) => closeConfirmationModal(val)}
        />
        <div
          style={{
            marginBottom: "15px",
            flexDirection: "row",
            display: "flex",
            flex: 1,
          }}
        >
          <div className="back-btn">
            <Button
              className="add-button"
              type="primary"
              style={{
                // width: 150,
                height: "45px",
                fontFamily: "Poppins",
                fontSize: "15px",
              }}
              icon={<ArrowLeftOutlined />}
              onClick={() => history.push("/scheduling/table")}
            >
              Back
            </Button>
          </div>
          {edit ? (
            <div
              style={{
                justifyContent: "flex-start",
                display: "flex",
                padding: "6px",
                marginLeft: "20px",
                borderRadius: "5px",
              }}
            >
              <span
                style={{
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  color: "rgba(255, 255, 255, 0.5)",
                }}
              >
                {processNameEdit}
              </span>
            </div>
          ) : null}
        </div>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            flex: 1,
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div className="task-container">
            <h1 className="pallet-title">Process Elements</h1>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <fieldset className="drag-fields">
                <motion.div
                  // draggable="true"
                  style={{ background: "#352f44" }}
                  className="block"
                  onMouseDown={(event) => onDragStart(event, "Task")}
                >
                  <DragOutlined size={5} className="drag-icon" />
                  Task
                </motion.div>
                {/* <motion.div
                style={{ background: "#252A38" }}
                className="block"
                onMouseDown={(event) => onDragStart(event, "Block")}
              >
                <DragOutlined size={5} className="drag-icon" />
                Block
              </motion.div> */}
                <motion.div
                  style={{ background: "#3a4750" }}
                  className="block"
                  onMouseDown={(event) => onDragStart(event, "Input")}
                >
                  <DragOutlined size={5} className="drag-icon" />
                  Input
                </motion.div>
                <motion.div
                  style={{ background: "#414141" }}
                  className="block"
                  onMouseDown={(event) => onDragStart(event, "Output")}
                >
                  <DragOutlined size={5} className="drag-icon" />
                  Output
                </motion.div>
              </fieldset>
            </div>
          </div>
          <div className="button-box">
            {processId ? (
              copy ? (
                <Button
                  className="button"
                  type="primary"
                  loading={processLoading}
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    // alignItems: "center",
                    // justifyContent: "center",
                  }}
                  onClick={() => openProcessModal()}
                >
                  Save
                </Button>
              ) : (
                <Button
                  className="button"
                  type="primary"
                  loading={processLoading}
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    // alignItems: "center",
                    // justifyContent: "center",
                  }}
                  onClick={() => openProcessModal()}
                >
                  Update
                </Button>
              )
            ) : (
              <Button
                type="primary"
                className="button"
                loading={processLoading}
                style={{
                  fontFamily: "Poppins",
                  fontSize: "15px",
                  // alignItems: "center",
                  // justifyContent: "center",
                }}
                onClick={() => openProcessModal()}
              >
                Save
              </Button>
            )}
          </div>
        </div>
        <div className="progress-convas">
          <div
            style={{
              zIndex: 9,
              position: "absolute",
              bottom: 10,
              right: 15,
              background: "rgba(0, 0, 0, .5)",
              padding: 10,
              color: "white",
            }}
          >
            <Tooltip title="Undo" placement="left">
              <UndoOutlined
                disabled={!canUndo}
                onClick={undo}
                style={{ display: "block", width: "100%", margin: "5px 0" }}
              />
            </Tooltip>
            <Tooltip title="Redo" placement="left">
              <RedoOutlined
                disabled={!canRedo}
                onClick={redo}
                style={{ display: "block", width: "100%", margin: "10px 0" }}
              />
            </Tooltip>
            <Tooltip title="Zoom In" placement="left">
              <PlusOutlined
                style={{ display: "block", width: "100%", margin: "5px 0" }}
                onClick={() => canvasRef.current.zoomIn()}
              />
            </Tooltip>
            <Tooltip title="Zoom Out" placement="left">
              <MinusOutlined
                style={{ display: "block", width: "100%", margin: "5px 50" }}
                onClick={() => canvasRef.current.zoomOut()}
              />
            </Tooltip>
            <Tooltip title="Fit" placement="left">
              <ExpandOutlined
                style={{ display: "block", width: "100%", margin: "5px 0" }}
                onClick={() => canvasRef.current.fitCanvas()}
              />
            </Tooltip>
          </div>
          <Canvas
            direction="RIGHT"
            center
            fit
            ref={canvasRef}
            nodes={nodes}
            edges={edges}
            zoom={zoom}
            selections={selections}
            onNodeLink={(from: NodeData, to: NodeData) => {
              const id = `${from.id}-${to.id}`;
              const edgeValidation = checkEdgeValidations(
                from.data.type,
                to.data.type,
              );
              const duplicateEdges = edges.filter(
                (edge: EdgeData) => edge.id === id,
              );

              if (edgeValidation === "OK") {
                if (from.id !== to.id && duplicateEdges.length === 0) {
                  addToCableHistory();
                  addToNodes(from.id); // inputOutputMap func
                  addToCableStates(from.id, to.id);
                  const fromNode = nodes.find((node: any) => node.id === from.id);
                  const toNode = nodes.find((node: any) => node.id === to.id);
                  if (toNode?.data?.props) toNode.data.status = false;
                  if (fromNode?.data?.type === "Input" && fromNode?.data?.props) {
                    const { inputItem } = fromNode?.data?.props;
                    const tempInput = [inputItem];
                    // addOutputs(fromNode.id, tempInput);
                    addInput(to.id, fromNode.id);
                  } else if (
                    fromNode?.data?.type === "Task" &&
                    fromNode?.data?.props
                  ) {
                    const inputs = from?.data?.props?.taskOutputs.map(
                      (output: any) => output.name,
                    );
                    // addOutputs(fromNode.id, inputs);
                    addInput(to.id, fromNode.id);
                  }

                  setEdges([
                    ...edges,
                    {
                      id,
                      from: from.id,
                      to: to.id,
                      parent: to.parent,
                    },
                  ]);
                } else {
                  message.open({
                    type: "error",
                    content: "Error! Mapping to the same node or duplicate edge",
                    duration: 2,
                    style: { textAlign: "right", marginRight: 15, marginTop: 10 },
                  });
                }
              } else {
                message.open({
                  type: "error",
                  content: edgeValidation,
                  duration: 2,
                  style: { textAlign: "right", marginRight: 15, marginTop: 10 },
                });
              }
            }}
            node={(n) => (
              <Node
                {...n}
                style={{
                  borderRadius: 30,
                  fill: getColorNode(n?.properties?.id),
                  // width:100,
                  strokeWidth: enteredNode?.id === n.id && droppable ? 50 : 1,
                }}
                label={
                  <Label
                    style={{
                      // fill: "blue",
                      // left: 0,
                      // top: 0,
                      visibility: "hidden",
                    }}
                  />
                }
                onClick={(event, node) => {
                  event.persist();
                  setSelections([node.id]);
                }}
                onRemove={(event, node) => {
                  if (checkNodeEligibilityToDelete(node.id)) {
                    addToCableHistory();
                    deleteFromAllCableStates(node.id);
                    removeFromMap(node.id); // inputOutputMap func
                    const result = removeAndUpsertNodes(nodes, edges, node);
                    setEdges(result.edges);
                    setNodes(result.nodes);
                    setSelections([]);
                    removeNodeId(node.id);
                  } else {
                    message.open({
                      type: "error",
                      content: "cannot delete a node with connections",
                      duration: 2,
                      style: {
                        textAlign: "right",
                        marginRight: 15,
                        marginTop: 10,
                      },
                    });
                  }
                }}
                className={classNames({ closest: enteredNode?.id === n.id })}
              >
                {(event) => (
                  <foreignObject
                    height={event.height - 15}
                    width={event.width - 20}
                    x={15}
                    y={5}
                    onClick={() => {
                      console.log(nodes, "node select ------- ", event);
                      setSelections([event?.node?.id]);
                    }}
                  >
                    <div
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        alignSelf: "center",
                        display: "flex",
                        cursor: "pointer",
                        // backgroundColor: "red",
                      }}
                    >
                      <div
                        className="node-label"
                        style={{
                          marginTop:
                            event?.node?.data?.type !== "Task" ? "5px" : "0",
                        }}
                      >
                        {event?.node?.data?.type !== "Block" && (
                          <div className="node-custom">
                            <div className="node_icon">
                              <img
                                src={PopupIcon}
                                className="popup-img"
                                onClick={() => {
                                  console.log(event.node, "oppppppooooooopppppp");
                                  nodeDataSelect(event.node);
                                }}
                              />
                            </div>
                            <div className="node_label_text">
                              <div className="main_text">
                                {event?.node?.data?.props
                                  ? event?.node?.data?.props?.taskName ||
                                    event?.node?.data?.props?.inputItem ||
                                    event?.node?.data?.props?.output
                                  : event?.node?.data?.type}
                              </div>
                              {event?.node?.data?.type === "Task" && (
                                <div className="task_id">
                                  {`Id - ${event?.node?.id}`}
                                </div>
                              )}
                            </div>
                            <div className="validate-icon">
                              {event?.node?.data?.status ? (
                                <CheckCircleFilled style={{ color: "#79d70f" }} />
                              ) : event?.node?.data?.status === undefined ? (<MinusCircleFilled style={{ color: "#ec4646" }} />) : (
                                <MinusCircleFilled style={{ color: edit ? "#79d70f" : "#ec4646" }} />
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </foreignObject>
                )}
              </Node>
            )}
            edge={
              <Edge
                className="edge"
                onClick={(event, edge) => {
                  setSelections([edge.id]);
                }}
                onRemove={(event, edge) => {
                  console.log(edge, "nodeeeeee", nodes);
                  addToCableHistory();
                  removeFromCableStates(String(edge.from), String(edge.to));
                  removeToNodes(String(edge.from));
                  setEdges(edges.filter((e) => e.id !== edge.id));
                  setSelections([]);
                  const toNode = nodes.find(
                    (node: any) => String(node.id) === String(edge.to),
                  );
                  if (toNode?.data?.props) {
                    if (toNode?.data?.type === "Task") {
                      console.log(toNode?.data?.props, "oooooooooooo");
                      let taskInputs;
                      if (toNode?.data?.props.taskType === "series") {
                        taskInputs = JSON.parse(
                          JSON.stringify(toNode?.data?.props?.inputOutputMap),
                        );
                      } else if (toNode?.data?.props.taskType === "parallel") {
                        taskInputs = JSON.parse(
                          JSON.stringify(toNode?.data?.props?.lengthFactors),
                        );
                      }
                      removeTaskInputsOnEdgeRemove(
                        String(edge.to),
                        String(edge.from),
                        toNode,
                      );
                      removeSingleInputNode(
                        String(edge.to),
                        String(edge.from),
                        taskInputs.map((input: any) => input.inputValues),
                      );
                      toNode.data.status = false;
                    } else if (toNode?.data?.type === "Input") {
                      removeSingleInputNode(
                        String(edge.to),
                        String(edge.from),
                        toNode?.data?.props?.inputItem,
                      );
                    } else {
                      removeSingleInputNode(String(edge.to), String(edge.from));
                    }
                  } else {
                    removeInputsOnNoProps(String(edge.to), String(edge.from));
                  }
                }}
              />
            }
            onCanvasClick={(event) => {
              //  setSelections([]);
            }}
          />
        </div>
        <div style={{ marginTop: "200px" }}>
          <Portal>
            <motion.div
              drag
              dragControls={dragControls}
              className="dragger"
              onDrag={onDrag}
              onDragEnd={onDragEnd}
            >
              {activeDrag && <div className="dragInner">{activeDrag}</div>}
            </motion.div>
          </Portal>
        </div>
      </> : <Skeleton active paragraph={{ rows: 10 }} round />}
    </div>
  );
};
export default CableProductionFlowChart;
