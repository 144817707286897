import React, { FC } from "react";
import CalenderTable from "./CalenderTable";
import MainLayout from "../../components/MainLayout";

const Machines: FC = () => {
  return (
    <MainLayout
      type="primary"
      // title="Manage Machines"
      OpenKey={["device"]}
      selectedKey={["calender"]}
    >
      <div className="addMachines-form-container">
        <CalenderTable />
      </div>
    </MainLayout>
  );
};

export default Machines;
