/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState, useEffect } from "react";
import { Empty, Skeleton, Pagination } from "antd";
import { DateTime, Duration } from "luxon";
import MainLayout from "../../components/MainLayout";
import SearchBar from "./SearchBar";
import { useConnection } from "../../Services/ApplicationContext";
import { DataSource } from "../UtilizationReport/RepSearch";
import HeatMapChart from "../UtilizationReport/HeatMapChart";
import StatTable from "./StatTable";
import "../pagination.scss";
import "./heatMap.scss";
import HeatMapUtilizationReportChart from "../UtilizationReport/HeatMapUtilizationReportChart";
import { SERVICE_TYPE } from "../../constants/constants";

export interface Query {
  src: DataSource;
  start: number;
  duration: string;
  page: number;
  status: boolean;
}

interface IHeatMapPagination {
  minValue: number;
  maxValue: number;
}

const heatMapPaginationDefaultValue: IHeatMapPagination = {
  minValue: 0,
  maxValue: 1,
};

const OverallReport: FC = (props: any) => {
  const { location } = props;
  const { get, post } = useConnection();
  const currQuery = location.state;
  const [noData, setNoData] = useState(true);
  const [noData2, setNoData2] = useState(true);
  const [loadRep, setLoadRep] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>([]);
  const [statTableData, setStatTableData] = useState<any>();
  const [
    overallUtilizationTableData,
    setOverallUtilizationTableData,
  ] = useState<any>();
  const [
    overallUtilizationHeatMapData,
    setOverallUtilizationHeatMapData,
  ] = useState<any>();

  const [
    activateHeatMapPagination,
    setActivateHeatMapPagination,
  ] = useState<boolean>(false);
  const [numberOfMachines, setNumberOfMachines] = useState<number>(0);
  const [numberOfMachinesTotal, setNumberOfMachinesTotal] = useState<number>(0);
  const [lastQuery, setLastQuery] = useState<any>({
    start: DateTime.now().startOf("day").valueOf(),
    duration: "P1D",
    page: 0,
    size: 5,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const [pageSizeHeatMap, setPageSizeHeatMap] = useState(5);

  const [heatMapCurrentPage, setHeatMapCurrentPage] = useState(1);

  const [
    heatMapPagination,
    setHeatMapPagination,
  ] = useState<IHeatMapPagination>(heatMapPaginationDefaultValue);

  const onPageChange = (value: any) => {
    console.log("heat map : ", value);
    setActivateHeatMapPagination(true);
    setHeatMapPagination({
      minValue: (value - 1) * pageSize,
      maxValue: value * pageSize,
    });
  };

  const getDailyUtilizationReportAllDetails = async (x: any) => {
    setActivateHeatMapPagination(false);
    const date = x.start;
    setLoading(true);
    setLastQuery(x);
    const renderHMFormat = (min: number) => {
      if (min === 0) {
        return "-";
      }
      const hours = Math.floor(min / 60);
      const minutes = (min % 60).toFixed(0);
      const result = `${hours}h` + ` ${minutes}m`;
      return result;
    };
    await post(
      "report/utilizationReportAll",
      {
        date,
        machines: null,
        type: x.duration,
      },
      undefined,
      SERVICE_TYPE.serviceType
    )
      .then((result) => {
        const dataSet: any = [];
        const hmDataSet = result?.data;

        for (let i = 0; i < result?.data?.length; i += 1) {
          dataSet.push({
            key: i + 1,
            name: result?.data[i]?.machineName,
            totRunning: renderHMFormat(result?.data[i]?.totalMin),
            officeTime: renderHMFormat(result?.data[i]?.officeMin),
            OutOfficeTime: renderHMFormat(result?.data[i]?.nonOfficeMin),
            idle: renderHMFormat(result?.data[i]?.idleMin),
            unknown: renderHMFormat(result?.data[i]?.unknowMin),
          });
        }
        setNumberOfMachinesTotal(result?.data?.length);
        setNumberOfMachinesTotal(result?.data?.length);
        setOverallUtilizationTableData(dataSet);
        setOverallUtilizationHeatMapData(hmDataSet);
        setNoData(false);
        setLoading(false);
      })
      .catch((e: any) => {
        console.log(e);
        setNoData(false);
        setLoading(false);
      });
  };

  // const processQuery = async (x: Query) => {
  //   setLoadRep(true);
  //   console.log(x);
  //   setLastQuery(x);
  //   await post("report/machineUtilTable", x).then((statTable) => {
  //     setNoData2(false);
  //     const dataSet: any = [];
  //     console.log("stat table data", statTable.data);

  //     for (let i = 0; i < statTable.data.length; i += 1) {
  //       dataSet.push({
  //         key: i + 1,
  //         name: statTable.data[i].src.name,
  //         totRunning: statTable.data[i].data.none.RUNNING_TIME.none,
  //         officeTime:
  //           statTable.data[i].data.none.RUNNING_TIME_OFFICE_HOURS.none,
  //         OutOfficeTime:
  //           statTable.data[i].data.none.RUNNING_TIME_NON_OFFICE_HOURS.none,
  //         idle: statTable.data[i].data.none.IDLE_TIME.none,
  //         unknown: statTable.data[i].data.none.UNKNOWN_TIME.none,
  //       });
  //     }
  //     console.log(statTable.data.length, "nnnnnnnnn");
  //     setStatTableData(dataSet);
  //   });

  //   await post("report/machineUtilHeatmap", x).then((heats) => {
  //     console.log("heats.data ", heats.data);

  //     setData(heats.data);
  //     setNoData(false);
  //     setLoadRep(false);
  //   });
  // };

  useEffect(() => {
    console.log("curr query : ", location);
    getDailyUtilizationReportAllDetails(lastQuery);
  }, []);

  useEffect(() => {
    getDailyUtilizationReportAllDetails(lastQuery);
    // );
  }, [lastQuery]);

  // useEffect(() => {
  //   console.log("over all heatmap data -> ", overallUtilizationHeatMapData)
  //   // );
  // }, [overallUtilizationHeatMapData]);

  // const showOutput = () => {
  //   if (loadRep) return <Skeleton active paragraph={{ rows: 10 }} round />;
  //   if (!noData) {
  //     return (
  //       <div>
  //         <div className="heatMap-container">
  //           {data.length !== undefined ? (
  //             data.map((object: any) => {
  //               return (
  //                 <div className="row-section-heatMap" key={object.src.id}>
  //                   <div className="machine-name-text">{object.src.name}</div>
  //                   <div className="heatmap">
  //                     <HeatMapChart hm={object} />
  //                   </div>
  //                 </div>
  //               );
  //             })
  //           ) : (
  //             <div style={{ color: "red" }}>Error</div>
  //           )}
  //         </div>
  //         <div>
  //           {overallUtilizationTableData.length !== 0 ? (
  //             <StatTable dataSet={overallUtilizationTableData} />
  //           ) : (
  //             <div style={{ color: "red" }}>Error</div>
  //           )}
  //         </div>
  //       </div>
  //     );
  //   }
  //   return (
  //     <Empty
  //       image={Empty.PRESENTED_IMAGE_SIMPLE}
  //       style={{ height: "40vh", marginTop: "25vh" }}
  //     />
  //   );
  // };

  const onStatTableOnChange = (val: any) => {
    console.log("stat table changes : ", val);
  };

  const paginationChanges = (val: any) => {
    // console.log("pagination changes overall page : ", val);
    setCurrentPage(val.current);
    setPageSize(val.pageSize);
  };

  const heatMapSizeChanger = (current: any, size: any) => {
    console.log("page size changes heatmap : ", size);
    setActivateHeatMapPagination(true);
    setPageSizeHeatMap(size);
  };

  const showOutput = () => {
    if (loading) return <Skeleton active paragraph={{ rows: 10 }} round />;
    if (!noData) {
      return (
        <div>
          <div className="heatMap-container">
            {activateHeatMapPagination ? (
              overallUtilizationHeatMapData?.length !== 0 ? (
                <>
                  {overallUtilizationHeatMapData
                    .slice(
                      heatMapPagination?.minValue,
                      heatMapPagination?.maxValue,
                    )
                    .map((object: any) => {
                      return (
                        <div
                          className="row-section-heatMap"
                          key={object.machineID}
                        >
                          <div className="machine-name-text">
                            {object.machineName}
                          </div>
                          <div className="heatmap">
                            <HeatMapUtilizationReportChart
                              hm={object}
                              query={lastQuery}
                            />
                          </div>
                        </div>
                      );
                    })}
                  <Pagination
                    responsive
                    style={{ textAlign: "end" }}
                    // showSizeChanger={true}
                    pageSizeOptions={["5", "10", "20", "30"]}
                    // onShowSizeChange={heatMapSizeChanger}
                    pageSize={pageSize}
                    defaultPageSize={5}
                    defaultCurrent={1}
                    total={overallUtilizationHeatMapData?.length}
                    onChange={onPageChange}
                  />
                  ,
                </>
              ) : (
                <div style={{ color: "red" }}>
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    style={{ height: "10vh", marginTop: "10vh" }}
                    description="Utilization Heat map data for machines in a given date not available"
                  />
                </div>
              )
            ) : overallUtilizationHeatMapData?.length !== 0 ? (
              <>
                {overallUtilizationHeatMapData
                  .slice(0, pageSize)
                  .map((object: any) => {
                    return (
                      <div
                        className="row-section-heatMap"
                        key={object.machineID}
                      >
                        <div className="machine-name-text">
                          {object.machineName}
                        </div>
                        <div className="heatmap">
                          <HeatMapUtilizationReportChart
                            hm={object}
                            query={lastQuery}
                          />
                        </div>
                      </div>
                    );
                  })}
                <Pagination
                  responsive
                  style={{ textAlign: "end" }}
                  // showSizeChanger={true}
                  pageSizeOptions={["5", "10", "20", "30"]}
                  // onShowSizeChange={heatMapSizeChanger}
                  pageSize={pageSize}
                  defaultPageSize={5}
                  defaultCurrent={1}
                  total={overallUtilizationHeatMapData?.length}
                  onChange={onPageChange}
                />
                ,
              </>
            ) : (
              <div>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  style={{ height: "10vh", marginTop: "10vh" }}
                  description="Utilization Heat map data for machines in a given date are not available"
                />
              </div>
            )}
          </div>
          <div>
            {overallUtilizationTableData &&
            overallUtilizationTableData.length !== 0 ? (
              <StatTable
                dataSet={overallUtilizationTableData}
                paginationDetails={{
                  defaultPageSize: 5,
                  pageSizeOptions: ["5", "10", "20", "30"],
                  pageSize,
                  showSizeChanger: true,
                }}
                onChange={paginationChanges}
              />
            ) : (
              <div className="heatMap-container">
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  style={{ height: "10vh", marginTop: "10vh" }}
                  description="Utilization table data for machines in a given date are not available"
                />
              </div>
            )}
          </div>
        </div>
      );
    }
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        style={{ height: "40vh", marginTop: "25vh" }}
      />
    );
  };

  return (
    <MainLayout
      type="primary"
      OpenKey={["report"]}
      selectedKey={["overallReport"]}
    >
      <div className="inner-dark-section">
        <div
          className="search-section"
          style={{
            position: "sticky",
            top: "0",
            zIndex: 5,
            paddingTop: "30px",
            paddingBottom: "30px",
          }}
        >
          <SearchBar
            queryProcessor={getDailyUtilizationReportAllDetails}
            currQuery={currQuery}
          />
        </div>
        {showOutput()}
      </div>
    </MainLayout>
  );
};

export default OverallReport;
