import React, { FC } from "react";
import { Layout } from "antd";
import HeaderIPS from "./HeaderIPS/HeaderIPS";
import SiderIPS from "./SiderIPS/SiderIPS";
import "./mainLayout.scss";

const { Content } = Layout;

export interface MainLayoutProps {
  type: "primary" | "minimal";
  title?: string;
  OpenKey?: string[];
  selectedKey?: string[];
}
const MainLayout: FC<MainLayoutProps> = (props) => {
  const { type, title, children, OpenKey, selectedKey } = props;

  const renderMinimal = () => {
    return (
      <Layout>
        <Content
          className="site-layout-background"
          style={{ padding: "0 24px 24px" }}
        >
          <div className="content-container">
            {title && (
              <div className="content-header-container">
                <h2 className="h2-user-management">{title}</h2>
              </div>
            )}
            <div>{children}</div>
          </div>
        </Content>
      </Layout>
    );
  };

  const renderPrimary = () => {
    return (
      <>
        {/* style={{ backgroundColor: "#1b1b1b" }} */}
        <Layout>
          <HeaderIPS />
          <Layout>
            <SiderIPS openKeys={OpenKey} selectedKey={selectedKey} />
            <Layout>
              <Content className="site-layout-background">
                <div className="content-container">
                  {title && (
                    <div className="content-header-container">
                      <h2 className="h2-user-management">{title}</h2>
                    </div>
                  )}
                  <div>{children}</div>
                </div>
              </Content>
            </Layout>
          </Layout>
        </Layout>
      </>
    );
  };

  switch (type) {
    case "minimal":
      return renderMinimal();
    case "primary":
      return renderPrimary();
    default:
      return renderPrimary();
  }
};

export default MainLayout;
