/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, FC, useCallback } from "react";
import {
  Table,
  Popconfirm,
  Space,
  Button,
  message,
  Skeleton,
  Dropdown,
  Row,
  Col,
  Input,
  Menu,
  Radio,
  Empty,
  Tooltip,
} from "antd";
import {
  EditFilled,
  PlusOutlined,
  DeleteFilled,
  FilterOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import "./Styles.scss";
import "../tableStyles.scss";
import { useHistory } from "react-router-dom";
import {
  IoCreateOutline,
  IoPersonRemove,
  IoSettingsOutline,
  IoTrashSharp,
  IoWarning,
  IoCopyOutline,
} from "react-icons/io5";
import { DateTime } from "luxon";
import { useConnection } from "../../Services/ApplicationContext";
import { SERVICE_TYPE } from "../../constants/constants";

interface Pagination {
  currentPage: number;
  pageSize: number;
}

const CableProcessTable: FC = () => {
  const { get, delete: del, post } = useConnection();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState<number>(5);
  const [total, setTotal] = useState<number>(0);
  const [sortOrder, setSortOrder] = useState<string>("");
  const [sortField, setSortField] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [networkSearchValue, setNetworkSearchValue] = useState<string>("");

  const [searchByTermResources, setSearchByTermResources] = useState<any>(
    "name",
  );

  const [
    filterVisibleUserProfileResources,
    setFilterVisibleUserProfileResources,
  ] = useState<any>();

  const initialValue = {
    currentPage: 1,
    pageSize: 10,
  };
  const [pagination, setPagination] = useState<Pagination>(initialValue);

  const deleteProcess = async (record: any) => {
    console.log(record, "scheduler/productionPlans");
    setLoading(true);
    try {
      const results = await del(
        `scheduler/productionPlans/deleteProcessTemplate?id=${record.id}`,
        undefined,
        SERVICE_TYPE.serviceType
      );
      message.open({
        type: "success",
        content: "Process Deleted",
        duration: 2,
      });
      const temp = [...data];
      const index = temp.findIndex((value: any) => value.id === record.id);
      temp.splice(index, 1);
      setData(temp);
      setLoading(false);
    } catch (error: any) {
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
      setLoading(false);
    }
  };

  const changeDateAndTimeFormat = (val: string) => {
    const values = val.split(" ");
    const changedDateFormat = values[0].replace(".", ":").replace(".", ":");
    const timeFormat = values[2].split(":");
    let finalFormat: string;
    if (parseInt(timeFormat[0]) < 12) {
      finalFormat = changedDateFormat + " " + values[2] + "AM";
      return finalFormat;
    } else if (parseInt(timeFormat[0]) >= 12) {
      finalFormat = changedDateFormat + " " + values[2] + "PM";
      return finalFormat;
    }
    // const changedTimeFormat = timeFormat[1] + " " + timeFormat[2].toUpperCase();
  };

  const columns = [
    {
      title: "Process Name",
      dataIndex: "processName",
      key: "processName",
      sorter: true,
      width: "30%",
    },
    {
      title: "Process ID",
      dataIndex: "id",
      key: "id",
      width: "20%",
    },
    {
      title: "Last Updated On",
      sorter: true,
      key: "CreatedAt",
      width: "30%",
      render: (_: any, record: any) => {
        return DateTime.fromMillis(record.CreatedAt * 1000).toFormat(
          "dd:MM:yyyy,  hh:mm:ss a",
        );
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      width: "20%",
      render: (text: any, record: any) => (
        <Space size="large">
          <a onClick={() => history.push(`/scheduling/copy/${record.id}`)}>
            <IoCopyOutline size={22} color="#487199" />
          </a>
          {record.isDisable ? (
            <Tooltip title="Template has been used in a project">
              <span style={{ color: "gray", cursor: "not-allowed" }}>
                <IoCreateOutline size={22} color="#487199" />
              </span>
            </Tooltip>
          ) : (
            <a onClick={() => history.push(`/scheduling/update/${record.id}`)}>
              <IoCreateOutline size={22} color="#487199" />
            </a>
          )}

          {record.isDisable ? (
            <Tooltip title="Template has been used in a project">
              <span style={{ color: "gray", cursor: "not-allowed" }}>
                <IoTrashSharp size={22} color="#f18b8b" />
              </span>
            </Tooltip>
          ) : (
            <Popconfirm
              title={`Are you sure to delete process - ${record.processName}?`}
              placement="topLeft"
              onConfirm={() => deleteProcess(record)}
              icon={<IoWarning size={30} color="#eec335" />}
              okText="Yes"
              okButtonProps={{ danger: true }}
              cancelText="No"
              cancelButtonProps={{ type: "primary" }}
            >
              <a>
                <IoTrashSharp size={20} color="#f18b8b" />
              </a>
            </Popconfirm>
          )}

          <a
            href={`/scheduling/process/${record.id}`}
            target="_blank"
            style={{ color: "rgba(255, 255, 255, 0.5)" }}
            rel="noreferrer"
          >
            View
          </a>
        </Space>
      ),
    },
  ];

  const getProcessDataParams = () => {
    return {
      page: pagination.currentPage - 1,
      size: pagination.pageSize,
      searchTerm:
        searchByTermResources === "name" && networkSearchValue !== ""
          ? networkSearchValue
          : undefined,
      processId:
        searchByTermResources === "taskId" && networkSearchValue !== ""
          ? networkSearchValue
          : undefined,
      orderBy: sortField !== "" ? sortField : undefined,
      order: sortOrder !== "" ? sortOrder : undefined,
    };
  };

  const getProcessData = async () => {
    try {
      setLoading(true);
      const results = await post(
        "scheduler/productionPlans/getAllTemplates",
        {
          ...getProcessDataParams(),
        },
        undefined,
        SERVICE_TYPE.serviceType
      );
      console.log("resresresresres", results);
      const res = results?.data?.list.map((item: any) => {
        // console.log(item.createdAt);
        // const value = new Date(item.createdAt);
        const value = DateTime.fromMillis(item.lastUpdatedTime * 1000).toFormat(
          "dd:MM:yyyy,  hh:mm:ss a",
        );
        // console.log(value);
        return {
          id: item.processId,
          processName: item.processName,
          CreatedAt: item.lastUpdatedTime,
          isDisable: item.isDisable,
        };
      });
      // const data = extractProcessTableData(results.data);
      setTotal(results.data.total);
      setData(res);
      setLoading(false);
    } catch (error: any) {
      message.open({
        type: "error",
        content: error.message,
        duration: 2,
      });
      setLoading(false);
    }
  };

  const handleTableChange = (nav: any, sorter: any) => {
    if (sorter.order === "ascend") {
      setSortOrder("asc");
    } else if (sorter.order === "descend") {
      setSortOrder("desc");
    } else if (sorter.order === undefined) {
      setSortOrder("");
    }
    if (sorter.columnKey !== undefined) {
      setSortField(sorter.columnKey);
    } else {
      setSortField("");
    }
    setPagination({ currentPage: nav.current, pageSize: nav.pageSize });
  };

  useEffect(() => {
    getProcessData();
  }, [
    pagination,
    networkSearchValue,
    searchByTermResources,
    sortOrder,
    sortField,
  ]);

  const onSearch = () => {
    setNetworkSearchValue(searchValue);
    setPagination(initialValue);
  };

  const searchByTermHandler = (event: any) => {
    setSearchByTermResources(event?.target?.value);
  };

  const handleUserProfileVisibleChange = () => {
    setFilterVisibleUserProfileResources(true);
  };

  document.addEventListener("mousedown", (event: any) => {
    const userProfileArea1 = document.querySelector(
      ".search_menu_payment_icon",
    );
    const userProfileArea2 = document.querySelector(".visibleArea");

    if (userProfileArea1 !== null && userProfileArea2 !== null) {
      if (
        userProfileArea1.contains(event.target) ||
        userProfileArea2.contains(event.target)
      ) {
        setFilterVisibleUserProfileResources(true);
      } else {
        setFilterVisibleUserProfileResources(false);
      }
    }
  });

  const menuResources = (
    <Menu className="visibleArea">
      <Menu.ItemGroup title="Search by">
        <Menu.Item className="bg_col">
          <Radio.Group
            onChange={searchByTermHandler}
            value={searchByTermResources}
          >
            <Space direction="vertical">
              <Radio value="name">Name</Radio>
              <Radio value="taskId">Process Id</Radio>
            </Space>
          </Radio.Group>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          paddingTop: "20px",
          position: "sticky",
          top: "0",
          backgroundColor: "#1b1b1b",
          zIndex: 10,
        }}
      >
        <div className="row-space-between">
          <div className="width-70per" style={{ marginBottom: "30px" }}>
            <Button
              className="add-button"
              type="primary"
              style={{
                // width: 150,
                height: "45px",
                fontFamily: "Poppins",
                fontSize: "15px",
              }}
              icon={<PlusOutlined />}
              onClick={() => history.push("/scheduling/new")}
            >
              Add New Process
            </Button>
          </div>
          <div
            className="width-30per row-space-between"
            style={{ marginBottom: "30px", justifyContent: "flex-end" }}
          >
            <div>
              <div className="row end">
                <div className="search_menu_payment_icon">
                  <Dropdown overlay={menuResources}>
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => console.log(e)}
                    >
                      <FilterOutlined
                        style={{
                          color: "#073D74",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />
                    </a>
                  </Dropdown>
                </div>
                <div
                  className="search_filter_lables"
                  style={{ marginRight: "7px" }}
                >
                  Search:
                </div>
                <Input
                  onPressEnter={onSearch}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                  placeholder={`${
                    searchByTermResources === "name"
                      ? "Search by Process Name"
                      : searchByTermResources === "taskId"
                      ? "Search by Process Id"
                      : null
                  }`}
                  suffix={
                    <Button
                      type="primary"
                      onClick={onSearch}
                      style={{
                        borderRadius: "0 5px 5px 0",
                        marginRight: "-4px",
                      }}
                    >
                      <SearchOutlined />
                    </Button>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="general-table" style={{ marginTop: "30px" }}>
        <Table
          columns={columns}
          loading={loading}
          dataSource={data}
          pagination={{
            current: pagination.currentPage,
            pageSize: pagination.pageSize,
            total,
            showSizeChanger: true,
            showQuickJumper: true,
          }}
          onChange={(val: any, filter: any, sorter: any) => {
            console.log(sorter, "kkkk");
            handleTableChange(val, sorter);
          }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No Production Templates available"
              />
            ),
          }}
        />
      </div>
    </div>
  );
};
export default CableProcessTable;
