/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable prefer-promise-reject-errors */
import React, { FC, useEffect, useMemo, useState } from "react";
import {
  Avatar,
  Button,
  Form,
  Input,
  Layout,
  Select,
  Spin,
  Tooltip,
  Modal,
  message,
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import sha1 from "sha1";
import { SelectProps } from "antd/lib/select";
import { SiderIPS, HeaderIPS } from "../../components/index";
import { useConnection } from "../../Services/ApplicationContext";

// import "./userTable.scss";
import "./editProfile.scss";
import { useAuthZ } from "../../Services/AuthorizationContext";
import { useUser } from "../../Services/UserContext";
import { ClientDetails } from "../../definetions/UserModel";
import { ItemProps } from "../../definetions/common";
import { Plant } from "../../definetions/Constatnts";
import { ILocation } from "../../definetions/MachineModel";
import { SERVICE_TYPE } from "../../constants/constants";

const { Content } = Layout;
const { Option } = Select;

const options: ItemProps[] = [];

const UpdateUser: FC = () => {
  const { post, get } = useConnection();
  const [enableSearch, setEnableSearch] = useState(false);
  const [clientDetails, setClientDetails] = useState<ClientDetails>();
  const [loading, setLoading] = useState(false);
  const [changePassword, setchangePassword] = useState(false);
  const [locations, setLocations] = useState<ILocation[]>([]);
  const [form] = Form.useForm();

  const { setUserInfo } = useUser();
  const [visible, setvisible] = useState(false);
  const { roles } = useAuthZ();
  const fetchLocations = async () => {
    try {
      const results: any = await post(
        "system-management/location/getLocationsList",
        {
          size: 1000,
          page: 0,
        },
        undefined,
        SERVICE_TYPE.serviceType
      );
      console.log("location data", results);
      setLocations(results.data.list);
    } catch (e) {
      console.log("error getting locations ", e);
    }
  };
  const onConfirm = async (value: any) => {
    const email = localStorage.getItem("user");
    setLoading(true);
    if (email) {
      try {
        const password = sha1(`${email}${value.password}`);
        const result = await post(
          "settings/client/login",
          { email, password },
          undefined,
          SERVICE_TYPE.serviceType
        );
        console.log('result success', result)
        if (result.statusText === "SUCCESS") {
          setchangePassword(true);
          setvisible(false);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        message.open({
          type: "error",
          content: "Password Incorrect",
          duration: 3,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      }
    }
  };
  const handleCancel = () => {
    setvisible(false);
  };
  const fetchUser = async () => {
    setLoading(true);
    const id = localStorage.getItem("userId");
    if (id) {
      try {
        const result = await get(
          `settings/client/${id}`,
          undefined,
          SERVICE_TYPE.serviceType
        );
        if (result.status === 200) {
          setClientDetails(result.data);
        }
        setLoading(false);
      } catch (e: any) {
        message.open({
          type: "error",
          content: e.message,
          duration: 3,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
        setLoading(false);
      }
    }
  };
  const locationOptions: ItemProps[] | any[] = useMemo(() => {
    if (locations.length > 0) {
      return locations.map((val) => ({
        value: val.locationID,
        label: val.locationName,
      }));
    }
    return [];
  }, [locations]);
  useEffect(() => {
    fetchLocations();
    fetchUser();
  }, []);

  const onSubmit = async (values: any): Promise<void> => {
    setEnableSearch(false);
    setLoading(true);
    const entry = { ...values };
    try {
      if (values.password && clientDetails) {
        // TODO change if email change feature implemented
        entry.password = sha1(`${clientDetails.email}${values.password}`);
        delete entry.confirm;
      }
      const body = { ...clientDetails, ...entry };
      const result = await post(
        "settings/client/edit/clientDetails",
        body,
        undefined,
        SERVICE_TYPE.serviceType
      );
      if (result.statusText === "SUCCESS") {
        setUserInfo({
          email: String(body.email),
          fullName: String(body.fullName),
          userType: String(body.clientProfileType),
        });
        // localStorage.setItem("user", body.email);
        // localStorage.setItem("fullName", body.fullName);
        // localStorage.setItem("userType", body.clientProfileType);
        message.open({
          type: "success",
          content: result.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      }
      setLoading(false);
      setchangePassword(false);
    } catch (e: any) {
      message.open({
        type: "error",
        content: e.message,
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
      setLoading(false);
    }
  };
  const selectProps: SelectProps<any> = {
    mode: "multiple" as const,
    style: { width: "100%" },
    options: locationOptions,
    placeholder: "Select Item...",
    // maxTagCount: "responsive" as const,
  };

  return (
    <Layout>
      <HeaderIPS />
      <Layout>
        <SiderIPS selectedKey={["26"]} openKeys={["sub1"]} />
        <Content className="site-layout-background">
          <Spin spinning={loading && !clientDetails}>
            <div className="content-edit-profile">
              <div
                style={{
                  backgroundColor: "#242424",
                  width: "60%",
                  padding: "25px",
                  borderRadius: "10px",
                }}
              >
                <div className="content-header-container">
                  <h2 className="h2-user-management">Edit Profile</h2>
                </div>
                <div className="add-user-form-container">
                  {clientDetails && (
                    <Form
                      form={form}
                      layout="vertical"
                      name="update"
                      hideRequiredMark={false}
                      onFinish={onSubmit}
                      initialValues={clientDetails}
                      scrollToFirstError
                      autoComplete="off"
                    >
                      <Form.Item
                        name="fullName"
                        label={
                          <span>
                            Full Name&nbsp;
                            <Tooltip title="This name will be displayed">
                              <QuestionCircleOutlined />
                            </Tooltip>
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Please input your Full Name!",
                            whitespace: true,
                          },
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (
                                getFieldValue("fullName").trim() &&
                                !getFieldValue("fullName")
                                  ?.trim()
                                  .match(/^[a-zA-Z][a-zA-Z\. ]*$/)
                              ) {
                                return Promise.reject(
                                  "Full name should contain letters only!",
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input
                          onChange={() => {
                            setEnableSearch(true);
                          }}
                          spellCheck="false"
                        />
                      </Form.Item>
                      <Form.Item
                        name="contactNumber"
                        // initialValue={prevPhone}
                        label="Contact Number"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Contact Number!",
                          },
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (
                                getFieldValue("contactNumber") &&
                                getFieldValue("contactNumber").match(/^[ ]*$/)
                              ) {
                                return Promise.reject(
                                  "Please input your Contact Number!",
                                );
                              }
                              if (
                                getFieldValue("contactNumber") &&
                                getFieldValue("contactNumber")
                                  ?.trim()
                                  .match(/[\D]/)
                              ) {
                                return Promise.reject(
                                  "Invalid contact number!",
                                );
                              }
                              if (
                                getFieldValue("contactNumber") &&
                                !getFieldValue("contactNumber")
                                  ?.trim()
                                  .match(/^\d{10}$/)
                              ) {
                                return Promise.reject(
                                  "Contact number should consist of 10 digits!",
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input
                          // addonBefore={prefixSelector}
                          onChange={() => {
                            setEnableSearch(true);
                          }}
                          style={{
                            width: "100%",
                          }}
                          spellCheck="false"
                          disabled
                        />
                      </Form.Item>
                      <Form.Item label={`User ${Plant}`} name="locationIDs">
                        <Select
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentElement
                          }
                          onChange={() => {
                            setEnableSearch(true);
                          }}
                          {...selectProps}
                          disabled
                        />
                      </Form.Item>
                      <Form.Item
                        label="Access Level"
                        // initialValue={prevAccessLevel}
                        name="clientProfileType"
                        rules={[
                          {
                            required: true,
                            message: "Please select your access level!",
                          },
                        ]}
                      >
                        <Select
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentElement
                          }
                          onChange={() => {
                            setEnableSearch(true);
                          }}
                          disabled
                        >
                          {roles.map((value, index) => (
                            <Option key={index.toString()} value={value}>
                              {value}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (
                                getFieldValue("email") &&
                                getFieldValue("email").match(/^[ ]*$/)
                              ) {
                                return Promise.reject(
                                  "Please input your E-mail!",
                                );
                              }
                              if (
                                getFieldValue("email") &&
                                !getFieldValue("email")
                                  ?.trim()
                                  .match(
                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                  )
                              ) {
                                // eslint-disable-next-line prefer-promise-reject-errors
                                return Promise.reject(
                                  "Please input a valid E-mail!",
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                          {
                            required: true,
                            message: "Please input your E-mail!",
                          },
                        ]}
                      >
                        <Input
                          onChange={() => {
                            setEnableSearch(true);
                          }}
                          spellCheck="false"
                          disabled
                        />
                      </Form.Item>
                      <a
                        onClick={() => setvisible(true)}
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "15px",
                          color: "rgba(255, 255, 255, 0.6)",
                        }}
                      >
                        Change Password
                      </a>
                      {changePassword ? (
                        <div
                          style={{ marginTop: "25px" }}
                          className="change-pwd"
                        >
                          <Form.Item
                            label="New Password"
                            name="password"
                            rules={[
                              {
                                required: true,
                                message: "Please input your New Password!",
                              },
                            ]}
                            hasFeedback
                          >
                            <Input.Password
                              onChange={() => {
                                setEnableSearch(true);
                              }}
                              spellCheck="false"
                            />
                          </Form.Item>

                          <Form.Item
                            name="confirm"
                            label="Confirm Password"
                            dependencies={["password"]}
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: "Please confirm your New Password!",
                              },
                              ({ getFieldValue }) => ({
                                validator(rule, value) {
                                  if (
                                    !value ||
                                    getFieldValue("password") === value
                                  ) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    "Passwords do not match!",
                                  );
                                },
                              }),
                            ]}
                          >
                            <Input.Password
                              onChange={() => {
                                setEnableSearch(true);
                              }}
                              spellCheck="false"
                            />
                          </Form.Item>
                        </div>
                      ) : null}
                      <Form.Item>
                        <Button
                          loading={loading}
                          disabled={!enableSearch}
                          type="primary"
                          htmlType="submit"
                          style={{
                            marginTop: "15px",
                            display: "block",
                            height: "45px",
                            border: "none",
                            backgroundColor: "#073d74",
                            borderRadius: "5px",
                            fontFamily: "Poppins",
                            fontSize: "15px",
                            color: "rgba(255, 255, 255, 0.6)",
                          }}
                        >
                          Update
                        </Button>
                      </Form.Item>
                    </Form>
                  )}
                </div>
              </div>
            </div>
            <div className="checkPasswordModal">
              <Modal
                title="Confirm Password"
                visible={visible}
                footer={null}
                onCancel={handleCancel}
                className="confirm-pwd-modal"
              >
                <p className="title-edit-profile">
                  Please confirm your password to continue
                </p>
                <Form
                  // {...layout}
                  layout="horizontal"
                  name="basic"
                  initialValues={{
                    remember: true,
                  }}
                  className="confirm-pwd-modal"
                  hideRequiredMark={false}
                  onFinish={onConfirm}
                  // onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Current Password!",
                      },
                    ]}
                  >
                    <Input.Password placeholder="Password" spellCheck="false" />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      loading={loading}
                      type="primary"
                      htmlType="submit"
                      className="edit-profile-btn"
                      style={{
                        position: "absolute",
                        right: "0",
                      }}
                    >
                      Confirm Password
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>
            </div>
          </Spin>
        </Content>
      </Layout>
    </Layout>
  );
};

export default UpdateUser;
