import React, { useState, FC, useContext } from "react";
import { Form, Input, Button, Checkbox, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

import sha1 from "sha1";
import { NavLink, Redirect } from "react-router-dom";
import sierraLogo from "./sierralogo.png";
import "./LoginStyles.scss";
import { useConnection } from "../../Services/ApplicationContext";
import { useAuthZ } from "../../Services/AuthorizationContext";
import { useUser } from "../../Services/UserContext";
import { SERVICE_TYPE } from "../../constants/constants";

const LoginIPS: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { post, get, updateToken } = useConnection();
  const { setRole, isAuthenticated } = useAuthZ();

  const { setUserInfo } = useUser();
  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      const entry = { ...values };
      entry.email = values.email.trim();
      entry.password = sha1(
        `${values.email.trim().toLowerCase()}${values.password}`,
      );
      const result = await post(
        "settings/client/login",
        entry,
        undefined,
        SERVICE_TYPE.serviceType,
      );
      if (result.statusText !== "SUCCESS") {
        message.open({
          type: "warning",
          content: result.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      } else {
        const { email, id, token, clientProfileType, fullName } = result.data;
        updateToken(token);
        setRole(clientProfileType);
        setUserInfo({ email, fullName, userType: clientProfileType, id });
        // const urls = [
        //   "settings/client/",
        //   "system-management/resources/",
        //   "report/",
        //   "predictive-maintenance/",
        //   "scheduler/productionPlans/",
        //   "notification/",
        // ];

        // const fetchData = async (url: string) => {
        //   try {
        //     const response = await get(
        //       url,
        //       undefined,
        //       SERVICE_TYPE.serviceType,
        //     );

        //     if (response.status !== 200) {
        //       return null;
        //     }

        //     return response.data;
        //   } catch (error) {
        //     return null;
        //   }
        // };
        // const [data1, data2, data3, data4, data5, data6] = await Promise.all(
        //   urls.map(fetchData),
        // );
        // console.log(
        //   "data1, data2, data3, data4 data5 data6",
        //   data1,
        //   data2,
        //   data3,
        //   data4,
        //   data5,
        //   data6,
        // );
      }
      setLoading(false);
    } catch (e: any) {
      message.open({
        type: "error",
        content: e.message,
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
      setLoading(false);
    }
  };

  if (isAuthenticated()) {
    return <Redirect to="/iot-status" />;
  }
  return (
    <div className="main-container-login">
      <div className="form-container">
        <div className="login-form-header">
          <div className="header-container-login">
            <div className="detail-container">
              <h2 className="h2-login">Sierra IPS</h2>
              <h4 className="h4-login">Sign in to continue to dashboard</h4>
            </div>
            <div className="img-container">
              <img src={sierraLogo} className="img-login" alt="login-ico" />
            </div>
          </div>
        </div>
        <div className="form-footer">
          <div className="form-footer-container">
            <Form
              layout="vertical"
              name="basic"
              initialValues={{
                remember: true,
              }}
              hideRequiredMark
              onFinish={onSubmit}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (
                        getFieldValue("email") &&
                        !getFieldValue("email")
                          ?.trim()
                          .match(
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          )
                      ) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise.reject("Please input a valid E-mail!");
                      }
                      return Promise.resolve();
                    },
                  }),
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input
                  prefix={
                    <UserOutlined style={{ fontSize: 12, color: "#808080" }} />
                  }
                  placeholder="Email"
                  spellCheck="false"
                  type="email"
                />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Enter your password!",
                  },
                ]}
              >
                <Input.Password
                  prefix={
                    <LockOutlined style={{ fontSize: 12, color: "#808080" }} />
                  }
                  placeholder="Password"
                  spellCheck="false"
                  type="password"
                />
              </Form.Item>

              <Form.Item
                name="remember"
                valuePropName="checked"
                className="valuesCheck"
              >
                <Checkbox className="checkbox-inner">Remember me</Checkbox>
                <NavLink to="/RecoverAcc" className="navigation">
                  Forgot Your Password ?
                </NavLink>
              </Form.Item>

              <Form.Item>
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  className="login-submit-btn"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Log In
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
      <div>
        <h5 className="powered-by">Powered by Xeptagon</h5>
      </div>
    </div>
  );
};

export default LoginIPS;
